import styled from "styled-components";
import { ButtonPositioner, ReturnButton, LeftTitle } from "../wind-components/project-windData";
import ResizableLeftContainer from "../../common/resizableLeftContainer";
import ResizableRightContainer from "../../common/resizableRightContainer";
import SavingButton from "../../common/saving-button";
import LoadingButton from "../../common/loading-button";
import { ReturnIcon, AdvanceIcon } from "../../project-components/wind-components/project-windData";
import { useState, useContext } from "react";
import { MenuOption } from "../../mast-components/module-components/mast-moduleData";
import { ProjectContext } from "../../../contexts/ProjectContext";
import ProjectMemorialForm from "./project-memorialForm";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useUpdateProject from "../../../hooks/api/useUpdateProject";
import UserContext from "../../../contexts/UserContext";
import black_printer_icon from "../../../assets/images/black-printer-icon.svg";
import white_printer_icon from "../../../assets/images/white-printer-icon.svg";
import CreateMemorialDialog from "./project-memorialCreationDialog";

export default function ProjectMemorialPage() {
  const { setLeftMenuOptionSelected, mastSelected, projectSelected, setProjectSelected } = useContext(ProjectContext);
  const { userData } = useContext(UserContext);
  const { updateProject, updateProjectLoading } = useUpdateProject();
  const [projectSelectedObj, setProjectSelectedObj] = useState({ ...projectSelected, props: JSON.parse(projectSelected.props) });
  const [memorialMenuFirstOptionClicked, setMemorialMenuFirstOptionClicked] = useState(true);
  const [memorialMenuSecondOptionClicked, setLeftMenuSecondOptionClicked] = useState(false);
  const [generateMemorialButtonClicked, setGenerateMemorialButtonClicked] = useState(false);
  const [memorialMenuSelected, setMemorialMenuSelected] = useState(1);
  const [printableStructures, setPrintableStructures] = useState({ identificador: [] });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
  });
  async function saveProjectSelected() {
    if (memorialMenuSelected !== 1) return;
    try {
      const projectSelectedJSON = { ...projectSelectedObj };
      delete projectSelectedJSON.props;
      projectSelectedJSON.props = JSON.stringify(projectSelectedObj.props);
      delete projectSelectedJSON.Mastro;
      delete projectSelectedJSON.id;
      await updateProject(projectSelectedJSON, projectSelectedJSON.nomeProjeto, userData.token);
      toast.success(`${projectSelectedJSON.nomeProjeto} salvo com sucesso!`);
    } catch (error) {
      console.log(error);
      if(error?.response?.data?.message === "Erro: Authentication failed") {
        toast.error("Erro: refaça seu login");
        return;
      }
      toast.error("Erro ao atualizar o mastro");
    }
  }
  return(
    <>
      <ResizableLeftContainer>
        <LeftTitle>Memorial</LeftTitle>
        <ReturnIcon onClick={() => setLeftMenuOptionSelected("")}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ReturnIcon>
        <AdvanceIcon onClick={() => {setLeftMenuOptionSelected("");}}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </AdvanceIcon>
        <MenuOption>
          <h2>Selecione os itens que deseja imprimir</h2>
        </MenuOption>
        <MemorialMenuOption isClicked={memorialMenuFirstOptionClicked} onClick={() => {
          setMemorialMenuFirstOptionClicked(true);
          setLeftMenuSecondOptionClicked(false);
          setGenerateMemorialButtonClicked(false); 
          setMemorialMenuSelected(1);
        }}>
          <div>Dados do Site e Edificação</div>
        </MemorialMenuOption>
        <MemorialMenuOption isClicked={memorialMenuSecondOptionClicked} onClick={() => {
          setLeftMenuSecondOptionClicked(true);
          setMemorialMenuFirstOptionClicked(false);
          setGenerateMemorialButtonClicked(false); 
          setMemorialMenuSelected(2);
        }}>
          <div>Estruturas no site</div>
        </MemorialMenuOption>
        <GenerateMemorialButton isClicked={generateMemorialButtonClicked} onClick={() => {
          setGenerateMemorialButtonClicked(true); 
          setMemorialMenuFirstOptionClicked(false);
          setLeftMenuSecondOptionClicked(false);
          setConfirmDialog({ ...confirmDialog, isOpen: true });
        }}>
          GERAR MEMORIAL
          <img src={generateMemorialButtonClicked ? white_printer_icon : black_printer_icon} alt="Black printer img" />
        </GenerateMemorialButton>
        <CreateMemorialDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} printableStructures={printableStructures}/>
        <ButtonPositioner >
          <ReturnButton onClick={() => {
            if (1 === 2) {
              if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("");
            }
            else setLeftMenuOptionSelected("");
          }} >{" Voltar "}</ReturnButton>
          {updateProjectLoading ?           
            <LoadingButton>{ "Carregando" }</LoadingButton> :
            <SavingButton onClick={() => { if(!updateProjectLoading) saveProjectSelected();} }>{ "Salvar" }</SavingButton>} 
        </ButtonPositioner>
      </ResizableLeftContainer>
      <ResizableRightContainer>
        <ProjectMemorialForm memorialMenuSelected={memorialMenuSelected}
          setProjectSelected={setProjectSelected} printableStructures={printableStructures} 
          setPrintableStructures={setPrintableStructures} projectSelectedObj={projectSelectedObj} 
          setProjectSelectedObj={setProjectSelectedObj}/>
      </ResizableRightContainer>
    </>
  );
}

const MemorialMenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1vh 0;
  position: relative;
  cursor: pointer;

  h2 {
    font-weight: 300;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 0.8vh;
  }

  div {
    width: 100%;
    background-color: #D0CECE;
    height: 4.5vh;
    border-radius: 8px;
    /*font-family: "Lexend Deca", sans-serif*/
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    background-color: ${(props) => (props.isClicked ? "#FE8F03" : "#D0CECE")};
    color: ${(props) => (props.isClicked ? "#ffffff" : "#000000")};;
  }
`;

const GenerateMemorialButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4.5vh;
  margin-top: 10px;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  background-color: ${(props) => (props.isClicked ? "#FE8F03" : "#D0CECE")};
  color: ${(props) => (props.isClicked ? "#fff" : "#000")};
  img {
    margin-left: 10px;
    width: 22px;
  }
`;

const ProjectInformationContainer = styled.div`
  width: 90%;
  height: 90%;
  background-color: #D9D9D9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;
