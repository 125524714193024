import api from "../apiTower";

const makeHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export async function getSections(towerId, token) {
  const response = await api
    .get(`/tower/sections/${encodeURIComponent(towerId)}`, makeHeaders(token));
  return response.data;
}

export async function postSections(body, towerId, token) {
  const parsedBody = JSON.parse(body);
  const response = await api
    .post(`/tower/sections/${encodeURIComponent(towerId)}`, parsedBody, makeHeaders(token));
  return response.data;
}
  
export async function updateSections(body, towerId, token) {
  const parsedBody = JSON.parse(body);
  const response = await api
    .put(`/tower/sections/${encodeURIComponent(towerId)}`, parsedBody, makeHeaders(token));
  return response.data;
}
