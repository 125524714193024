import MF_1_1 from "./1MOD/MF-1-1.jpg";
import MF_1_2 from "./1MOD/MF-1-2.jpg";
import MF_1_3 from "./1MOD/MF-1-3.jpg";
import MF_1_4 from "./1MOD/MF-1-4.jpg";
import MF_1_5 from "./1MOD/MF-1-5.jpg";
import MF_1_6 from "./1MOD/MF-1-6.jpg";
import MF_1_7 from "./1MOD/MF-1-7.jpg";
import MF_1_8 from "./1MOD/MF-1-8.jpg";
import MF_1_9 from "./1MOD/MF-1-9.jpg";
import MF_1_10 from "./1MOD/MF-1-10.jpg";

import MF_2_2 from "./2MOD/MF-2-2.jpg";
import MF_2_3 from "./2MOD/MF-2-3.jpg";
import MF_2_4 from "./2MOD/MF-2-4.jpg";
import MF_2_5 from "./2MOD/MF-2-5.jpg";
import MF_2_6 from "./2MOD/MF-2-6.jpg";
import MF_2_7 from "./2MOD/MF-2-7.jpg";
import MF_2_8 from "./2MOD/MF-2-8.jpg";
import MF_2_9 from "./2MOD/MF-2-9.jpg";
import MF_2_10 from "./2MOD/MF-2-10.jpg";

import MF_3_3 from "./3MOD/MF-3-3.jpg";
import MF_3_4 from "./3MOD/MF-3-4.jpg";
import MF_3_5 from "./3MOD/MF-3-5.jpg";
import MF_3_6 from "./3MOD/MF-3-6.jpg";
import MF_3_7 from "./3MOD/MF-3-7.jpg";
import MF_3_8 from "./3MOD/MF-3-8.jpg";
import MF_3_9 from "./3MOD/MF-3-9.jpg";
import MF_3_10 from "./3MOD/MF-3-10.jpg";

import MF_4_4 from "./4MOD/MF-4-4.jpg";
import MF_4_5 from "./4MOD/MF-4-5.jpg";
import MF_4_6 from "./4MOD/MF-4-6.jpg";
import MF_4_7 from "./4MOD/MF-4-7.jpg";
import MF_4_8 from "./4MOD/MF-4-8.jpg";
import MF_4_9 from "./4MOD/MF-4-9.jpg";
import MF_4_10 from "./4MOD/MF-4-10.jpg";

import MF_5_5 from "./5MOD/MF-5-5.jpg";
import MF_5_6 from "./5MOD/MF-5-6.jpg";
import MF_5_7 from "./5MOD/MF-5-7.jpg";
import MF_5_8 from "./5MOD/MF-5-8.jpg";
import MF_5_9 from "./5MOD/MF-5-9.jpg";
import MF_5_10 from "./5MOD/MF-5-10.jpg";

import MF_6_6 from "./6MOD/MF-6-6.jpg";
import MF_6_7 from "./6MOD/MF-6-7.jpg";
import MF_6_8 from "./6MOD/MF-6-8.jpg";
import MF_6_9 from "./6MOD/MF-6-9.jpg";
import MF_6_10 from "./6MOD/MF-6-10.jpg";

import MF_7_7 from "./7MOD/MF-7-7.jpg";
import MF_7_8 from "./7MOD/MF-7-8.jpg";
import MF_7_9 from "./7MOD/MF-7-9.jpg";
import MF_7_10 from "./7MOD/MF-7-10.jpg";

import MF_8_8 from "./8MOD/MF-8-8.jpg";
import MF_8_9 from "./8MOD/MF-8-9.jpg";
import MF_8_10 from "./8MOD/MF-8-10.jpg";

import MF_9_9 from "./9MOD/MF-9-9.jpg";
import MF_9_10 from "./9MOD/MF-9-10.jpg";

import MF_10_10 from "./10MOD/MF-10-10.jpg";

const MFImg = { MF_1_1, MF_1_2, MF_1_3, MF_1_4, MF_1_5, MF_1_6, MF_1_7, MF_1_8, MF_1_9, MF_1_10, MF_2_2, MF_2_3, MF_2_4, MF_2_5, MF_2_6, MF_2_7, MF_2_8, MF_2_9, MF_2_10, MF_3_3, MF_3_4, MF_3_5, MF_3_6, MF_3_7, MF_3_8, MF_3_9, MF_3_10, MF_4_4, MF_4_5, MF_4_6, MF_4_7, MF_4_8, MF_4_9, MF_4_10, MF_5_5, MF_5_6, MF_5_7, MF_5_8, MF_5_9, MF_5_10, MF_6_6, MF_6_7, MF_6_8, MF_6_9, MF_6_10, MF_7_7, MF_7_8, MF_7_9, MF_7_10, MF_8_8, MF_8_9, MF_8_10, MF_9_9, MF_9_10, MF_10_10 };

export default MFImg;
