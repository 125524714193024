import styled from "styled-components";
import {
  Dialog,
  DialogActions,
} from "@mui/material";
import { useContext, useState, useEffect } from "react";
import UserContext from "../../contexts/UserContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProjectContext } from "../../contexts/ProjectContext";
import useMasts from "../../hooks/api/useMasts";
import usePostMast from "../../hooks/api/usePostMast";
import useUpdateMast from "../../hooks/api/useUpdateMast";
import useDeleteMast from "../../hooks/api/useDeleteMast";
import { postMastAccessHistory } from "../../services/mastApi";
import { DialogContainer, BlackConfirmDialogButton, SpacingLine } from "../project-components/dashboardPage-components/project-openingDialog";

export default function SelectMastDialog({
  confirmDialog,
  setConfirmDialog,
}) {
  const { userData } = useContext(UserContext);
  const [inputValues, setInputValues] = useState({});
  const { setReloadProject, reloadProject, setMastSelected, projectSelected } = useContext(ProjectContext);
  const { getMasts } = useMasts();
  const [ projectMasts, setProjectMasts ] = useState([]);
  const { postMast } = usePostMast();
  const { updateMast } = useUpdateMast();
  const { deleteMast } = useDeleteMast();
  const [copyExistingMast, setCopyExistingMast] = useState(false);
  
  async function selectMast() {
    setMastSelected(projectMasts.filter((mast) => mast.identificador === confirmDialog.mast.identificador)[0]);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    toast.success(`Mastro ${confirmDialog.mast.identificador} selecionado com sucesso`);
    try {
      const mastBeingSelected = projectMasts.filter((mast) => mast.identificador === confirmDialog.mast.identificador[0]);
      await postMastAccessHistory(mastBeingSelected.id, userData.token);
      setReloadProject(!reloadProject);
    } catch (error) {
      console.log(error);
    }
  }

  async function addNewMast() {
    if(!inputValues.mast) return toast.error("Insira um nome válido para o mastro.");
    let newMastProps = {};
    let mastToBeCopiedProps = {};
    if (inputValues.mastToBeCopied && inputValues.mastToBeCopied !== "nao") {
      const mastToBeCopiedSelected = projectMasts.filter((mast) => mast.identificador === inputValues.mastToBeCopied)[0];
      mastToBeCopiedProps = JSON.parse(mastToBeCopiedSelected?.props);
      mastToBeCopiedProps.userId = userData.id;
      delete mastToBeCopiedProps.qtdTotalAntenas;
      delete mastToBeCopiedProps.strapMessage;
      delete mastToBeCopiedProps.esteira;
      for (const key in mastToBeCopiedProps) {
        if (key.startsWith("antena")) {
          delete mastToBeCopiedProps[key];
        }
      }
      newMastProps = mastToBeCopiedProps;
    }
    newMastProps.projectId = projectSelected.id;
    newMastProps.identificador = inputValues.mast;
    const props = { userId: userData.id, projectId: projectSelected.id, identificador: inputValues.mast, tipo: mastToBeCopiedProps.tipoMastro || "MA", props: `${JSON.stringify(newMastProps)}` };
    try {
      const newMast = await postMast(props, userData.token);
      setInputValues({});
      setMastSelected(newMast);
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      setReloadProject(!reloadProject);
      toast.success(`Mastro ${newMast.identificador} adicionado com sucesso`);
    } catch (error) {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
    }
  }

  async function updateMastIdentificator() {
    const mast = { ...confirmDialog.mast };
    const mastProps = JSON.parse(mast?.props);
    mastProps.identificador = inputValues.mast;
    mast.props = JSON.stringify(mastProps);
    let updatedMast = { ...mast, identificador: inputValues.mast, updatedAt: new Date() };
    try {
      updatedMast = await updateMast(updatedMast, userData.token);
      setInputValues({});
      setMastSelected(updatedMast);
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      toast.success(`Mastro ${updatedMast.identificador} alterado com sucesso`);
      setReloadProject(!reloadProject);
    } catch (error) {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
    }
  }

  async function postDeleteMast() {
    const mast = confirmDialog.mast;
    try {
      if (!window.confirm(`Tem certeza de que deseja deletar o mastro: ${mast.identificador}?`)) return setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      await deleteMast(mast.id, userData.token);
      setMastSelected({});
      setInputValues({});
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      setReloadProject(!reloadProject);
      toast.success(`Mastro ${mast.identificador} deletado com sucesso`);
    } catch (error) {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
    }
  }

  useEffect(() => {
    async function fetchAllMastsNames() {
      const masts = await getMasts(projectSelected.id, userData.token);
      setProjectMasts(masts);
    }
    if(projectSelected.id) fetchAllMastsNames();
  }, [confirmDialog, projectSelected, reloadProject]);

  return (
    <Dialog open={confirmDialog.isOpen} fullWidth={true} maxWidth="sm">
      <DialogContainer dialogType={confirmDialog.type}>
        {confirmDialog.type === "select" && <>
          <h1>Editar mastro</h1>
          <SpacingLine />
          <MastActionContainer boxHeight={"170px"}>
            <h2>Você confirma a edição do mastro ?</h2>
            <h3>{confirmDialog.mast.identificador}</h3>
            <BlackConfirmDialogButton onClick={selectMast}>Editar Mastro</BlackConfirmDialogButton>
          </MastActionContainer>
          <DialogActions>
            {" "}
          </DialogActions>
        </>}
        {confirmDialog.type === "new" && <>
          <h1>{!copyExistingMast ? "Criar Novo Mastro" : "Importar geometria de um mastro existente?"}</h1>
          <SpacingLine />
          <MastActionContainer boxHeight={"130px"}>
            {!copyExistingMast &&
            <input autoFocus type="text" placeholder="Nome do novo mastro" onChange={(e) => setInputValues({ ...inputValues, mast: e.target.value, }) }></input>
            }
            {inputValues && inputValues.mast && projectMasts.length !== 0 && copyExistingMast &&
              <select value={inputValues.mastToBeCopied || ""} onChange={(e) => setInputValues({ ...inputValues, mastToBeCopied: e.target.value, })}>
                <option value="" disabled={true}>Selecione o mastro a ser copiado</option>
                <option value="nao">Não copiar</option>
                {projectMasts.length > 0 ?
                  projectMasts.sort((a, b) => a.identificador.localeCompare(b.identificador)).map((option, index) => (
                    <option key={index} value={option.identificador}> {option.identificador} </option>
                  ))
                  : ""}
              </select>}
            {!copyExistingMast ? <BlackConfirmDialogButton onClick={() => {
              if (projectSelected?.Mastro?.length >= 1) {
                return setCopyExistingMast(true);
              }
              else {
                addNewMast();
              }
            }}>Criar Novo Mastro</BlackConfirmDialogButton> :
              <BlackConfirmDialogButton onClick={addNewMast}>Criar Novo Mastro</BlackConfirmDialogButton>}
          </MastActionContainer>
          <DialogActions>
            {" "}
          </DialogActions>
        </>}
        {confirmDialog.type === "update" && <>
          <h1>Alterar nome do mastro</h1>
          <SpacingLine />
          <MastActionContainer boxHeight={"170px"}>
            <h2>Digite o novo nome do mastro {confirmDialog.mast.identificador}</h2>
            <input autoFocus type="text" placeholder={confirmDialog.mast.identificador} onChange={(e) => setInputValues({ ...inputValues, mast: e.target.value })}></input>
            <BlackConfirmDialogButton onClick={updateMastIdentificator}>Alterar nome</BlackConfirmDialogButton>
          </MastActionContainer>
          <DialogActions>
            {" "}
          </DialogActions>
        </>}
        {confirmDialog.type === "delete" && <>
          <h1>Deletar mastro</h1>
          <SpacingLine />
          <MastActionContainer boxHeight={"170px"}>
            <h2>Você confirma a exclusão do mastro ?</h2>
            <h3>{confirmDialog.mast.identificador}</h3>
            <BlackConfirmDialogButton onClick={postDeleteMast}>Deletar</BlackConfirmDialogButton>
          </MastActionContainer>
          <DialogActions>
            {" "}
          </DialogActions>
        </>}
        <ion-icon onClick={() => {setConfirmDialog({ ...confirmDialog, isOpen: false }); }} name="close"></ion-icon>
      </DialogContainer>
    </Dialog>
  );
}

const MastActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: -20px;
  background-color: #ffffff;
  height: ${(props) => (props.boxHeight)};
  width: 540px;

  h2 {
    text-align: center;
    color: #666666;
    font-size: 16px;
    width: 100%;
    margin-top: 10px;
  }

  h3 {
    text-align: center;
    color: #000000;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 10px;
  }

  input,
  select {
    height: 4.5vh;
    width: 106%;
    color: #000000 !important;
    background-color: #fff;
    border: 1px solid #C5C5C5;
    font-size: 16px;
    text-align: left;
    padding-left: 10px;
    border-radius: 4px;
  }
  input::placeholder{
  }
`;
