import * as projectApi from "../../services/projectApi";
import useAsync from "../useAsync";

export default function usePostProject() {
  const {
    data: project,
    loading: postProjectLoading,
    error: postProjectError,
    act: postProject,
  } = useAsync((props, token, projectType) => projectApi.postProject(props, token, projectType), false);

  return {
    project,
    postProjectLoading,
    postProjectError,
    postProject
  };
}
