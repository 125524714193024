import useAsync from "../useAsync";
import * as projectApi from "../../services/projectApi";

export default function useDeleteProject() {
  const {
    data: deletedProject,
    loading: deleteProjectLoading,
    error: deleteProjectError,
    act: deleteProject,
  } = useAsync((projectName, token) => projectApi.deleteProject(projectName, token), false);

  return {
    deletedProject,
    deleteProjectLoading,
    deleteProjectError,
    deleteProject
  };
}
