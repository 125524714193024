import MC_1 from "./1MOD/MC-1.jpg";

import MC_2_1_1 from "./2MOD/MC-2-1-1.jpg";
import MC_2_2_1 from "./2MOD/MC-2-2-1.jpg";
import MC_2_2_2 from "./2MOD/MC-2-2-2.jpg";

import MC_3_1_1 from "./3MOD/MC-3-1-1.jpg";
import MC_3_2_1 from "./3MOD/MC-3-2-1.jpg";
import MC_3_2_2 from "./3MOD/MC-3-2-2.jpg";
import MC_3_3_1 from "./3MOD/MC-3-3-1.jpg";
import MC_3_3_2 from "./3MOD/MC-3-3-2.jpg";
import MC_3_3_3 from "./3MOD/MC-3-3-3.jpg";

import MC_4_1_1 from "./4MOD/MC-4-1-1.jpg";
import MC_4_2_1 from "./4MOD/MC-4-2-1.jpg";
import MC_4_2_2 from "./4MOD/MC-4-2-2.jpg";
import MC_4_3_1 from "./4MOD/MC-4-3-1.jpg";
import MC_4_3_2 from "./4MOD/MC-4-3-2.jpg";
import MC_4_3_3 from "./4MOD/MC-4-3-3.jpg";
import MC_4_4_1 from "./4MOD/MC-4-4-1.jpg";
import MC_4_4_2 from "./4MOD/MC-4-4-2.jpg";
import MC_4_4_3 from "./4MOD/MC-4-4-3.jpg";
import MC_4_4_4 from "./4MOD/MC-4-4-4.jpg";

import MC_5_1_1 from "./5MOD/MC-5-1-1.jpg";
import MC_5_2_1 from "./5MOD/MC-5-2-1.jpg";
import MC_5_2_2 from "./5MOD/MC-5-2-2.jpg";
import MC_5_3_1 from "./5MOD/MC-5-3-1.jpg";
import MC_5_3_2 from "./5MOD/MC-5-3-2.jpg";
import MC_5_3_3 from "./5MOD/MC-5-3-3.jpg";
import MC_5_4_1 from "./5MOD/MC-5-4-1.jpg";
import MC_5_4_2 from "./5MOD/MC-5-4-2.jpg";
import MC_5_4_3 from "./5MOD/MC-5-4-3.jpg";
import MC_5_4_4 from "./5MOD/MC-5-4-4.jpg";
import MC_5_5_1 from "./5MOD/MC-5-5-1.jpg";
import MC_5_5_2 from "./5MOD/MC-5-5-2.jpg";
import MC_5_5_3 from "./5MOD/MC-5-5-3.jpg";
import MC_5_5_4 from "./5MOD/MC-5-5-4.jpg";
import MC_5_5_5 from "./5MOD/MC-5-5-5.jpg";

import MC_6_1_1 from "./6MOD/MC-6-1-1.jpg";
import MC_6_2_1 from "./6MOD/MC-6-2-1.jpg";
import MC_6_2_2 from "./6MOD/MC-6-2-2.jpg";
import MC_6_3_1 from "./6MOD/MC-6-3-1.jpg";
import MC_6_3_2 from "./6MOD/MC-6-3-2.jpg";
import MC_6_3_3 from "./6MOD/MC-6-3-3.jpg";
import MC_6_4_1 from "./6MOD/MC-6-4-1.jpg";
import MC_6_4_2 from "./6MOD/MC-6-4-2.jpg";
import MC_6_4_3 from "./6MOD/MC-6-4-3.jpg";
import MC_6_4_4 from "./6MOD/MC-6-4-4.jpg";
import MC_6_5_1 from "./6MOD/MC-6-5-1.jpg";
import MC_6_5_2 from "./6MOD/MC-6-5-2.jpg";
import MC_6_5_3 from "./6MOD/MC-6-5-3.jpg";
import MC_6_5_4 from "./6MOD/MC-6-5-4.jpg";
import MC_6_5_5 from "./6MOD/MC-6-5-5.jpg";
import MC_6_6_1 from "./6MOD/MC-6-6-1.jpg";
import MC_6_6_2 from "./6MOD/MC-6-6-2.jpg";
import MC_6_6_3 from "./6MOD/MC-6-6-3.jpg";
import MC_6_6_4 from "./6MOD/MC-6-6-4.jpg";
import MC_6_6_5 from "./6MOD/MC-6-6-5.jpg";
import MC_6_6_6 from "./6MOD/MC-6-6-6.jpg";

import MC_7_1_1 from "./7MOD/MC-7-1-1.jpg";
import MC_7_2_1 from "./7MOD/MC-7-2-1.jpg";
import MC_7_2_2 from "./7MOD/MC-7-2-2.jpg";
import MC_7_3_1 from "./7MOD/MC-7-3-1.jpg";
import MC_7_3_2 from "./7MOD/MC-7-3-2.jpg";
import MC_7_3_3 from "./7MOD/MC-7-3-3.jpg";
import MC_7_4_1 from "./7MOD/MC-7-4-1.jpg";
import MC_7_4_2 from "./7MOD/MC-7-4-2.jpg";
import MC_7_4_3 from "./7MOD/MC-7-4-3.jpg";
import MC_7_4_4 from "./7MOD/MC-7-4-4.jpg";
import MC_7_5_1 from "./7MOD/MC-7-5-1.jpg";
import MC_7_5_2 from "./7MOD/MC-7-5-2.jpg";
import MC_7_5_3 from "./7MOD/MC-7-5-3.jpg";
import MC_7_5_4 from "./7MOD/MC-7-5-4.jpg";
import MC_7_5_5 from "./7MOD/MC-7-5-5.jpg";
import MC_7_6_1 from "./7MOD/MC-7-6-1.jpg";
import MC_7_6_2 from "./7MOD/MC-7-6-2.jpg";
import MC_7_6_3 from "./7MOD/MC-7-6-3.jpg";
import MC_7_6_4 from "./7MOD/MC-7-6-4.jpg";
import MC_7_6_5 from "./7MOD/MC-7-6-5.jpg";
import MC_7_6_6 from "./7MOD/MC-7-6-6.jpg";
import MC_7_7_1 from "./7MOD/MC-7-7-1.jpg";
import MC_7_7_2 from "./7MOD/MC-7-7-2.jpg";
import MC_7_7_3 from "./7MOD/MC-7-7-3.jpg";
import MC_7_7_4 from "./7MOD/MC-7-7-4.jpg";
import MC_7_7_5 from "./7MOD/MC-7-7-5.jpg";
import MC_7_7_6 from "./7MOD/MC-7-7-6.jpg";
import MC_7_7_7 from "./7MOD/MC-7-7-7.jpg";

import MC_8_1_1 from "./8MOD/MC-8-1-1.jpg";
import MC_8_2_1 from "./8MOD/MC-8-2-1.jpg";
import MC_8_2_2 from "./8MOD/MC-8-2-2.jpg";
import MC_8_3_1 from "./8MOD/MC-8-3-1.jpg";
import MC_8_3_2 from "./8MOD/MC-8-3-2.jpg";
import MC_8_3_3 from "./8MOD/MC-8-3-3.jpg";
import MC_8_4_1 from "./8MOD/MC-8-4-1.jpg";
import MC_8_4_2 from "./8MOD/MC-8-4-2.jpg";
import MC_8_4_3 from "./8MOD/MC-8-4-3.jpg";
import MC_8_4_4 from "./8MOD/MC-8-4-4.jpg";
import MC_8_5_1 from "./8MOD/MC-8-5-1.jpg";
import MC_8_5_2 from "./8MOD/MC-8-5-2.jpg";
import MC_8_5_3 from "./8MOD/MC-8-5-3.jpg";
import MC_8_5_4 from "./8MOD/MC-8-5-4.jpg";
import MC_8_5_5 from "./8MOD/MC-8-5-5.jpg";
import MC_8_6_1 from "./8MOD/MC-8-6-1.jpg";
import MC_8_6_2 from "./8MOD/MC-8-6-2.jpg";
import MC_8_6_3 from "./8MOD/MC-8-6-3.jpg";
import MC_8_6_4 from "./8MOD/MC-8-6-4.jpg";
import MC_8_6_5 from "./8MOD/MC-8-6-5.jpg";
import MC_8_6_6 from "./8MOD/MC-8-6-6.jpg";
import MC_8_7_1 from "./8MOD/MC-8-7-1.jpg";
import MC_8_7_2 from "./8MOD/MC-8-7-2.jpg";
import MC_8_7_3 from "./8MOD/MC-8-7-3.jpg";
import MC_8_7_4 from "./8MOD/MC-8-7-4.jpg";
import MC_8_7_5 from "./8MOD/MC-8-7-5.jpg";
import MC_8_7_6 from "./8MOD/MC-8-7-6.jpg";
import MC_8_7_7 from "./8MOD/MC-8-7-7.jpg";
import MC_8_8_1 from "./8MOD/MC-8-8-1.jpg";
import MC_8_8_2 from "./8MOD/MC-8-8-2.jpg";
import MC_8_8_3 from "./8MOD/MC-8-8-3.jpg";
import MC_8_8_4 from "./8MOD/MC-8-8-4.jpg";
import MC_8_8_5 from "./8MOD/MC-8-8-5.jpg";
import MC_8_8_6 from "./8MOD/MC-8-8-6.jpg";
import MC_8_8_7 from "./8MOD/MC-8-8-7.jpg";
import MC_8_8_8 from "./8MOD/MC-8-8-8.jpg";

import MC_9_1_1 from "./9MOD/MC-9-1-1.jpg";
import MC_9_2_1 from "./9MOD/MC-9-2-1.jpg";
import MC_9_2_2 from "./9MOD/MC-9-2-2.jpg";
import MC_9_3_1 from "./9MOD/MC-9-3-1.jpg";
import MC_9_3_2 from "./9MOD/MC-9-3-2.jpg";
import MC_9_3_3 from "./9MOD/MC-9-3-3.jpg";
import MC_9_4_1 from "./9MOD/MC-9-4-1.jpg";
import MC_9_4_2 from "./9MOD/MC-9-4-2.jpg";
import MC_9_4_3 from "./9MOD/MC-9-4-3.jpg";
import MC_9_4_4 from "./9MOD/MC-9-4-4.jpg";
import MC_9_5_1 from "./9MOD/MC-9-5-1.jpg";
import MC_9_5_2 from "./9MOD/MC-9-5-2.jpg";
import MC_9_5_3 from "./9MOD/MC-9-5-3.jpg";
import MC_9_5_4 from "./9MOD/MC-9-5-4.jpg";
import MC_9_5_5 from "./9MOD/MC-9-5-5.jpg";
import MC_9_6_1 from "./9MOD/MC-9-6-1.jpg";
import MC_9_6_2 from "./9MOD/MC-9-6-2.jpg";
import MC_9_6_3 from "./9MOD/MC-9-6-3.jpg";
import MC_9_6_4 from "./9MOD/MC-9-6-4.jpg";
import MC_9_6_5 from "./9MOD/MC-9-6-5.jpg";
import MC_9_6_6 from "./9MOD/MC-9-6-6.jpg";
import MC_9_7_1 from "./9MOD/MC-9-7-1.jpg";
import MC_9_7_2 from "./9MOD/MC-9-7-2.jpg";
import MC_9_7_3 from "./9MOD/MC-9-7-3.jpg";
import MC_9_7_4 from "./9MOD/MC-9-7-4.jpg";
import MC_9_7_5 from "./9MOD/MC-9-7-5.jpg";
import MC_9_7_6 from "./9MOD/MC-9-7-6.jpg";
import MC_9_7_7 from "./9MOD/MC-9-7-7.jpg";
import MC_9_8_1 from "./9MOD/MC-9-8-1.jpg";
import MC_9_8_2 from "./9MOD/MC-9-8-2.jpg";
import MC_9_8_3 from "./9MOD/MC-9-8-3.jpg";
import MC_9_8_4 from "./9MOD/MC-9-8-4.jpg";
import MC_9_8_5 from "./9MOD/MC-9-8-5.jpg";
import MC_9_8_6 from "./9MOD/MC-9-8-6.jpg";
import MC_9_8_7 from "./9MOD/MC-9-8-7.jpg";
import MC_9_8_8 from "./9MOD/MC-9-8-8.jpg";
import MC_9_9_1 from "./9MOD/MC-9-9-1.jpg";
import MC_9_9_2 from "./9MOD/MC-9-9-2.jpg";
import MC_9_9_3 from "./9MOD/MC-9-9-3.jpg";
import MC_9_9_4 from "./9MOD/MC-9-9-4.jpg";
import MC_9_9_5 from "./9MOD/MC-9-9-5.jpg";
import MC_9_9_6 from "./9MOD/MC-9-9-6.jpg";
import MC_9_9_7 from "./9MOD/MC-9-9-7.jpg";
import MC_9_9_8 from "./9MOD/MC-9-9-8.jpg";
import MC_9_9_9 from "./9MOD/MC-9-9-9.jpg";

import MC_10_1_1 from "./10MOD/MC-10-1-1.jpg";
import MC_10_2_1 from "./10MOD/MC-10-2-1.jpg";
import MC_10_2_2 from "./10MOD/MC-10-2-2.jpg";
import MC_10_3_1 from "./10MOD/MC-10-3-1.jpg";
import MC_10_3_2 from "./10MOD/MC-10-3-2.jpg";
import MC_10_3_3 from "./10MOD/MC-10-3-3.jpg";
import MC_10_4_1 from "./10MOD/MC-10-4-1.jpg";
import MC_10_4_2 from "./10MOD/MC-10-4-2.jpg";
import MC_10_4_3 from "./10MOD/MC-10-4-3.jpg";
import MC_10_4_4 from "./10MOD/MC-10-4-4.jpg";
import MC_10_5_1 from "./10MOD/MC-10-5-1.jpg";
import MC_10_5_2 from "./10MOD/MC-10-5-2.jpg";
import MC_10_5_3 from "./10MOD/MC-10-5-3.jpg";
import MC_10_5_4 from "./10MOD/MC-10-5-4.jpg";
import MC_10_5_5 from "./10MOD/MC-10-5-5.jpg";
import MC_10_6_1 from "./10MOD/MC-10-6-1.jpg";
import MC_10_6_2 from "./10MOD/MC-10-6-2.jpg";
import MC_10_6_3 from "./10MOD/MC-10-6-3.jpg";
import MC_10_6_4 from "./10MOD/MC-10-6-4.jpg";
import MC_10_6_5 from "./10MOD/MC-10-6-5.jpg";
import MC_10_6_6 from "./10MOD/MC-10-6-6.jpg";
import MC_10_7_1 from "./10MOD/MC-10-7-1.jpg";
import MC_10_7_2 from "./10MOD/MC-10-7-2.jpg";
import MC_10_7_3 from "./10MOD/MC-10-7-3.jpg";
import MC_10_7_4 from "./10MOD/MC-10-7-4.jpg";
import MC_10_7_5 from "./10MOD/MC-10-7-5.jpg";
import MC_10_7_6 from "./10MOD/MC-10-7-6.jpg";
import MC_10_7_7 from "./10MOD/MC-10-7-7.jpg";
import MC_10_8_1 from "./10MOD/MC-10-8-1.jpg";
import MC_10_8_2 from "./10MOD/MC-10-8-2.jpg";
import MC_10_8_3 from "./10MOD/MC-10-8-3.jpg";
import MC_10_8_4 from "./10MOD/MC-10-8-4.jpg";
import MC_10_8_5 from "./10MOD/MC-10-8-5.jpg";
import MC_10_8_6 from "./10MOD/MC-10-8-6.jpg";
import MC_10_8_7 from "./10MOD/MC-10-8-7.jpg";
import MC_10_8_8 from "./10MOD/MC-10-8-8.jpg";
import MC_10_9_1 from "./10MOD/MC-10-9-1.jpg";
import MC_10_9_2 from "./10MOD/MC-10-9-2.jpg";
import MC_10_9_3 from "./10MOD/MC-10-9-3.jpg";
import MC_10_9_4 from "./10MOD/MC-10-9-4.jpg";
import MC_10_9_5 from "./10MOD/MC-10-9-5.jpg";
import MC_10_9_6 from "./10MOD/MC-10-9-6.jpg";
import MC_10_9_7 from "./10MOD/MC-10-9-7.jpg";
import MC_10_9_8 from "./10MOD/MC-10-9-8.jpg";
import MC_10_9_9 from "./10MOD/MC-10-9-9.jpg";
import MC_10_10_1 from "./10MOD/MC-10-10-1.jpg";
import MC_10_10_2 from "./10MOD/MC-10-10-2.jpg";
import MC_10_10_3 from "./10MOD/MC-10-10-3.jpg";
import MC_10_10_4 from "./10MOD/MC-10-10-4.jpg";
import MC_10_10_5 from "./10MOD/MC-10-10-5.jpg";
import MC_10_10_6 from "./10MOD/MC-10-10-6.jpg";
import MC_10_10_7 from "./10MOD/MC-10-10-7.jpg";
import MC_10_10_8 from "./10MOD/MC-10-10-8.jpg";
import MC_10_10_9 from "./10MOD/MC-10-10-9.jpg";
import MC_10_10_10 from "./10MOD/MC-10-10-10.jpg";

const MCImg = { MC_1,
  MC_2_1_1,
  MC_2_2_1,
  MC_2_2_2,
  MC_3_1_1,
  MC_3_2_1,
  MC_3_2_2,
  MC_3_3_1,
  MC_3_3_2,
  MC_3_3_3,
  MC_4_1_1,
  MC_4_2_1,
  MC_4_2_2,
  MC_4_3_1,
  MC_4_3_2,
  MC_4_3_3,
  MC_4_4_1,
  MC_4_4_2,
  MC_4_4_3,
  MC_4_4_4,
  MC_5_1_1,
  MC_5_2_1,
  MC_5_2_2,
  MC_5_3_1,
  MC_5_3_2,
  MC_5_3_3,
  MC_5_4_1,
  MC_5_4_2,
  MC_5_4_3,
  MC_5_4_4,
  MC_5_5_1,
  MC_5_5_2,
  MC_5_5_3,
  MC_5_5_4,
  MC_5_5_5,
  MC_6_1_1,
  MC_6_2_1,
  MC_6_2_2,
  MC_6_3_1,
  MC_6_3_2,
  MC_6_3_3,
  MC_6_4_1,
  MC_6_4_2,
  MC_6_4_3,
  MC_6_4_4,
  MC_6_5_1,
  MC_6_5_2,
  MC_6_5_3,
  MC_6_5_4,
  MC_6_5_5,
  MC_6_6_1,
  MC_6_6_2,
  MC_6_6_3,
  MC_6_6_4,
  MC_6_6_5,
  MC_6_6_6,
  MC_7_1_1,
  MC_7_2_1,
  MC_7_2_2,
  MC_7_3_1,
  MC_7_3_2,
  MC_7_3_3,
  MC_7_4_1,
  MC_7_4_2,
  MC_7_4_3,
  MC_7_4_4,
  MC_7_5_1,
  MC_7_5_2,
  MC_7_5_3,
  MC_7_5_4,
  MC_7_5_5,
  MC_7_6_1,
  MC_7_6_2,
  MC_7_6_3,
  MC_7_6_4,
  MC_7_6_5,
  MC_7_6_6,
  MC_7_7_1,
  MC_7_7_2,
  MC_7_7_3,
  MC_7_7_4,
  MC_7_7_5,
  MC_7_7_6,
  MC_7_7_7,
  MC_8_1_1,
  MC_8_2_1,
  MC_8_2_2,
  MC_8_3_1,
  MC_8_3_2,
  MC_8_3_3,
  MC_8_4_1,
  MC_8_4_2,
  MC_8_4_3,
  MC_8_4_4,
  MC_8_5_1,
  MC_8_5_2,
  MC_8_5_3,
  MC_8_5_4,
  MC_8_5_5,
  MC_8_6_1,
  MC_8_6_2,
  MC_8_6_3,
  MC_8_6_4,
  MC_8_6_5,
  MC_8_6_6,
  MC_8_7_1,
  MC_8_7_2,
  MC_8_7_3,
  MC_8_7_4,
  MC_8_7_5,
  MC_8_7_6,
  MC_8_7_7,
  MC_8_8_1,
  MC_8_8_2,
  MC_8_8_3,
  MC_8_8_4,
  MC_8_8_5,
  MC_8_8_6,
  MC_8_8_7,
  MC_8_8_8,
  MC_9_1_1,
  MC_9_2_1,
  MC_9_2_2,
  MC_9_3_1,
  MC_9_3_2,
  MC_9_3_3,
  MC_9_4_1,
  MC_9_4_2,
  MC_9_4_3,
  MC_9_4_4,
  MC_9_5_1,
  MC_9_5_2,
  MC_9_5_3,
  MC_9_5_4,
  MC_9_5_5,
  MC_9_6_1,
  MC_9_6_2,
  MC_9_6_3,
  MC_9_6_4,
  MC_9_6_5,
  MC_9_6_6,
  MC_9_7_1,
  MC_9_7_2,
  MC_9_7_3,
  MC_9_7_4,
  MC_9_7_5,
  MC_9_7_6,
  MC_9_7_7,
  MC_9_8_1,
  MC_9_8_2,
  MC_9_8_3,
  MC_9_8_4,
  MC_9_8_5,
  MC_9_8_6,
  MC_9_8_7,
  MC_9_8_8,
  MC_9_9_1,
  MC_9_9_2,
  MC_9_9_3,
  MC_9_9_4,
  MC_9_9_5,
  MC_9_9_6,
  MC_9_9_7,
  MC_9_9_8,
  MC_9_9_9,
  MC_10_1_1,
  MC_10_2_1,
  MC_10_2_2,
  MC_10_3_1,
  MC_10_3_2,
  MC_10_3_3,
  MC_10_4_1,
  MC_10_4_2,
  MC_10_4_3,
  MC_10_4_4,
  MC_10_5_1,
  MC_10_5_2,
  MC_10_5_3,
  MC_10_5_4,
  MC_10_5_5,
  MC_10_6_1,
  MC_10_6_2,
  MC_10_6_3,
  MC_10_6_4,
  MC_10_6_5,
  MC_10_6_6,
  MC_10_7_1,
  MC_10_7_2,
  MC_10_7_3,
  MC_10_7_4,
  MC_10_7_5,
  MC_10_7_6,
  MC_10_7_7,
  MC_10_8_1,
  MC_10_8_2,
  MC_10_8_3,
  MC_10_8_4,
  MC_10_8_5,
  MC_10_8_6,
  MC_10_8_7,
  MC_10_8_8,
  MC_10_9_1,
  MC_10_9_2,
  MC_10_9_3,
  MC_10_9_4,
  MC_10_9_5,
  MC_10_9_6,
  MC_10_9_7,
  MC_10_9_8,
  MC_10_9_9,
  MC_10_10_1,
  MC_10_10_2,
  MC_10_10_3,
  MC_10_10_4,
  MC_10_10_5,
  MC_10_10_6,
  MC_10_10_7,
  MC_10_10_8,
  MC_10_10_9,
  MC_10_10_10, };
export default MCImg;
