export function errorUserVerification(error, setErrorMessage) {
  if(error?.response?.data?.message === "Erro: \"email\" is required") return setErrorMessage("Erro: preencha o campo email");
  if(error?.response?.data?.message === "Erro: \"email\" is not allowed to be empty") return setErrorMessage("Erro: preencha o campo email");
  if(error?.response?.data?.message === "Erro: \"email\" must be a valid email") return setErrorMessage("Erro: email inválido");
  if(error?.response?.data?.message === "Erro: \"password\" is not allowed to be empty" || error?.response?.data?.message === "Erro: \"password\" is required") return setErrorMessage("Erro: preencha o campo senha");
  if(error?.response?.data?.message === "Erro: \"password\" length must be at least 6 characters long") return setErrorMessage("Erro: senha deve ter no mínimo 6 caracteres");
  if(error?.response?.data?.message === "Erro: \"name\" length must be at least 6 characters long") return setErrorMessage("Erro: nome deve ter no mínimo 6 caracteres");
  if(error?.response?.data?.message === "Erro: \"name\" is not allowed to be empty") return setErrorMessage("Erro: preencha o campo nome");
  if(error?.response?.data?.message === "Erro: Email já cadastrado!") return setErrorMessage("Erro: email já cadastrado!");
  if(error?.response?.data?.message === "Erro: Email já está em uso") return setErrorMessage("Erro: email já cadastrado!");
  if(error?.response?.data?.message === "Email de confirmação não enviado.") return setErrorMessage("Erro: email de confirmação não enviado, tente mais tarde.");
  if(error?.response?.data?.message === "Erro: Código de verificação inválido") return setErrorMessage("Erro: código de verificação inválido!");
  if(error?.response?.data?.message?.indexOf("Erro: \"email\" with value") >= 0) return setErrorMessage("Erro: email deve ser do dominio: \"kk.eng.br\"");
  if(error.message === "Senhas não conferem") return setErrorMessage("Senhas não conferem");
  if(error.message === "Erro: Senhas não conferem") return setErrorMessage("Erro: Senhas não conferem");
  if(error?.response?.data?.message === "Erro: Senha ou email incorretos") return setErrorMessage("Erro: senha ou email incorretos");
  if(error.message === "Erro: Último código enviado há menos de 10 minutos.") return setErrorMessage("Erro: Último código enviado há menos de 10 minutos. Verifique seu e-mail ou tente novamente em alguns minutos.");
  if(error?.response?.data?.message === "Erro: Último código enviado há menos de 10 minutos.") return setErrorMessage("Erro: Último código enviado há menos de 10 minutos. Verifique seu e-mail ou tente novamente em alguns minutos.");
  return setErrorMessage("Erro: informações inválidas.");
}
