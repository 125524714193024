import api from "../apiTower";

const makeHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getTrusses = async(towerId, token) => {
  const response = await api
    .get(`/tower/trusses/${encodeURIComponent(towerId)}`, makeHeaders(token));
  return response.data;
};

export const postTrusses = async(body, towerId, token) => {
  const parsedBody = JSON.parse(body);
  const response = await api
    .post(`/tower/trusses/${encodeURIComponent(towerId)}`, parsedBody, makeHeaders(token));
  return response.data;
};

export const updateTrusses = async(body, towerId, token) => {
  const parsedBody = JSON.parse(body);
  const response = await api
    .put(`/tower/trusses/${encodeURIComponent(towerId)}`, parsedBody, makeHeaders(token));
  return response.data;
};
