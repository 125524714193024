import useAsync from "../../useAsync";
import { getTower } from "../../../services/tower-services/towerApi";

export const useTower = (action) => {
  const execute = {
    get: getTower,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
