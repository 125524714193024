import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";

class AntennaGroupModel {
  constructor(
    group= [],
    groupName = "",
    qtdAntennas = "",
    fixingHeight = "",
    operator = "",
    condition = "",
    type = "",
    dragCoef = "",
    model = "",
    diameterDimension = "",
    heightDimension = "",
    widthDimension = "",
    area = "",
    areaAndDragCoef = "",
    load = "",
    aev = "",
    loadPerM2 = "",
    frontalLoad = "",
    diagonalLoad = ""
  ) {
    this.group = group;
    this.groupName = groupName;
    this.qtdAntennas = qtdAntennas;
    this.fixingHeight = fixingHeight;
    this.operator = operator;
    this.condition = condition;
    this.type = type;
    this.dragCoef = dragCoef;
    this.model = model;
    this.diameterDimension = diameterDimension;
    this.heightDimension = heightDimension;
    this.widthDimension = widthDimension;
    this.area = area;
    this.areaAndDragCoef = areaAndDragCoef;
    this.load = load;
    this.aev = aev;
    this.loadPerM2 = loadPerM2;
    this.frontalLoad = frontalLoad;
    this.diagonalLoad = diagonalLoad;
  }

  updateField(field, value, antennaInfo, windSelected) {
    if (value === this[field] || this[field] === undefined) return;

    if (field === "fixingHeight") {
      this[field] = value;
      this.diameterDimension = antennaInfo?.diametro;
      this.widthDimension = antennaInfo?.largura;
      this.heightDimension = antennaInfo?.altura;
      this.area = this.calculateArea() || "";
      this.areaAndDragCoef = this.area * this.dragCoef || "";
      this.aev = "";
      this.loadPerM2 = this.calculateQWind(windSelected);
      this.load = this.areaAndDragCoef * this.loadPerM2 || "";
      this.frontalLoad = this.load;
      this.diagonalLoad = this.load * Math.sin(0.785) || "";
      return;
    }

    if (field === "type") {
      this[field] = value;
      this.model = "";
      this.diameterDimension = "";
      this.widthDimension = "";
      this.heightDimension = "";
      this.area = "";
      this.areaAndDragCoef = "";
      this.aev = "";
      this.load = "";
      this.loadPerM2 = "";
      this.frontalLoad = "";
      this.diagonalLoad = "";

      if (value === "MW VAZADA" || value === "MW CHEIA") {
        this.dragCoef = 1.6;
      } else if (value === "RESERVA") {
        this.dragCoef = 1.0;
      } else if (value === "") {
        this.dragCoef = "";
      } else {
        this.dragCoef = 1.2;
      }
      return;
    }

    if (field === "model") {
      this[field] = value;
      if (value === "-" || value === "") {
        this.diameterDimension = "";
        this.widthDimension = "";
        this.heightDimension = "";
        this.area = "";
        this.areaAndDragCoef = "";
        this.aev = "";
        this.load = "";
        this.loadPerM2 = "";
        this.frontalLoad = "";
        this.diagonalLoad = "";
      } else {
        this.diameterDimension = antennaInfo?.diametro;
        this.widthDimension = antennaInfo?.largura;
        this.heightDimension = antennaInfo?.altura;
        this.area = this.calculateArea() || "";
        this.areaAndDragCoef = this.area * this.dragCoef || "";
        this.aev = "";
        this.loadPerM2 = this.calculateQWind(windSelected);
        this.load = this.areaAndDragCoef * this.loadPerM2 || "";
        this.frontalLoad = this.load;
        this.diagonalLoad = this.load * Math.sin(0.785) || "";
      }
      return;
    }

    if (field === "dragCoef") {
      this[field] = value;
      this.areaAndDragCoef = this.area * this.dragCoef || "";
      this.aev = "";
      this.loadPerM2 = this.calculateQWind(windSelected);
      this.load = this.areaAndDragCoef * this.loadPerM2 || "";
      this.frontalLoad = this.load || "";
      this.diagonalLoad = this.load * Math.sin(0.785) || "";
      return;
    }

    if (field === "diameterDimension" || field === "widthDimension" || field === "heightDimension") {
      this[field] = value || "";
      this.area = this.calculateArea() || "";
      this.areaAndDragCoef = this.area * this.dragCoef || "";
      this.aev = "";
      this.loadPerM2 = this.calculateQWind(windSelected);
      this.load = this.areaAndDragCoef * this.loadPerM2 || "";
      this.frontalLoad = this.load || "";
      this.diagonalLoad = this.load * Math.sin(0.785) || "";
      return;
    }

    this[field] = value;
  }

  calculateArea() {
    if (!this.type || !this.qtdAntennas) return;
    if (!this.diameterDimension && !this.widthDimension && !this.heightDimension) return;

    const calculations = {
      "MW CHEIA": () => (Number(this.qtdAntennas) * Math.PI * Math.pow(Number(this.diameterDimension), 2) / 4) / 1e6,
      "ODU": () => (Number(this.qtdAntennas) * Math.PI * Math.pow(Number(this.diameterDimension), 2) / 4) / 1e6,
      "MW VAZADA": () => (Number(this.qtdAntennas) * (Math.PI * Math.pow(Number(this.diameterDimension), 2) / 8)) / 1e6,
      "HELICOIDAL 1": () => Number(this.qtdAntennas) * (Number(this.diameterDimension) === 600 ? 0.38 : 0.82),
      "HELICOIDAL 2": () => Number(this.qtdAntennas) * (Number(this.diameterDimension) === 600 ? 0.81 : 1.68),
      "HELICOIDAL 4": () => Number(this.qtdAntennas) * (Number(this.diameterDimension) === 600 ? 1.67 : 3.42),
      "HELICOIDAL 7": () => Number(this.qtdAntennas) * 2.77,
      "OMNI": () => this.widthDimension
        ? Number(this.qtdAntennas) * Number(this.diameterDimension) * Number(this.widthDimension) / 1e6
        : Number(this.qtdAntennas) * Number(this.diameterDimension) * Number(this.heightDimension) / 1e6,
      "YAGI 1": () => Number(this.qtdAntennas) * 0.31,
      "YAGI 2": () => Number(this.qtdAntennas) * 0.62,
      "YAGI 4": () => Number(this.qtdAntennas) * 1.03,
      "YAGI 7": () => Number(this.qtdAntennas) * 1.61,
      "YAGI 8": () => Number(this.qtdAntennas) * 1.8,
      "PAINEL VAZADO": () => Number(this.diameterDimension) === 0
        ? (Number(this.qtdAntennas) * Number(this.heightDimension) * Number(this.widthDimension) * 0.5) / 1e6
        : (Number(this.qtdAntennas) * Math.PI * Math.pow(Number(this.diameterDimension), 2) / 8) / 1e6,
    };

    return calculations[this.type]?.() ?? (Number(this.qtdAntennas) * Number(this.heightDimension) * Number(this.widthDimension) / 1e6);
  }

  calculateQWind(windSelected) {
    if (!this.fixingHeight) return "";
    if (!windSelected.v0 || !windSelected.s1 || !windSelected.s2 || !windSelected.s3) return "";

    const [s2Category, windClass] = windSelected.s2.split("-");

    const s2Factor = this._calculateS2Factor(s2Category, windClass, this.fixingHeight);
    const vk = Number(windSelected.v0) * Number(windSelected.s1) * s2Factor * Number(windSelected.s3);
    const qWind = Math.pow(vk, 2) / 16;
    return qWind ?? "";
  }

  _calculateS2Factor(s2Category, windClass, fixingHeight) {
    const s2Values = {
      "I": { "A": { b: 1.10, fr: 1.00, p: 0.06 }, "B": { b: 1.11, fr: 0.98, p: 0.065 }, "C": { b: 1.12, fr: 0.95, p: 0.07 } },
      "II": { "A": { b: 1.00, fr: 1.00, p: 0.085 }, "B": { b: 1.00, fr: 0.98, p: 0.09 }, "C": { b: 1.00, fr: 0.95, p: 0.10 } },
      "III": { "A": { b: 0.94, fr: 1.00, p: 0.10 }, "B": { b: 0.94, fr: 0.98, p: 0.105 }, "C": { b: 0.93, fr: 0.95, p: 0.115 } },
      "IV": { "A": { b: 0.86, fr: 1.00, p: 0.12 }, "B": { b: 0.85, fr: 0.98, p: 0.125 }, "C": { b: 0.84, fr: 0.95, p: 0.135 } },
      "V": { "A": { b: 0.74, fr: 1.00, p: 0.15 }, "B": { b: 0.73, fr: 0.98, p: 0.16 }, "C": { b: 0.71, fr: 0.95, p: 0.175 } },
    };
    const values = s2Values[s2Category]?.[windClass] ?? { b: 1, fr: 1, p: 1 };
    return values.b * values.fr * Math.pow(Number(fixingHeight) / 10, values.p);
  }
}

class AntennasDataModel {
  constructor(
    cableTrayLength = "",
    antennas = []
  ) {
    this.cableTrayLength = cableTrayLength;
    this.antennas = antennas.map(antennaGroup => new AntennaGroupModel(
      antennaGroup.group,
      antennaGroup.groupName,
      antennaGroup.qtdAntennas,
      antennaGroup.fixingHeight,
      antennaGroup.operator,
      antennaGroup.condition,
      antennaGroup.type,
      antennaGroup.dragCoef,
      antennaGroup.model,
      antennaGroup.diameterDimension,
      antennaGroup.heightDimension,
      antennaGroup.widthDimension,
      antennaGroup.area,
      antennaGroup.areaAndDragCoef,
      antennaGroup.load,
      antennaGroup.aev,
      antennaGroup.loadPerM2,
      antennaGroup.frontalLoad,
      antennaGroup.diagonalLoad,
    ));
  }

  updateAntennaGroup(field, value, groupName, antennaInfo, windSelected) {
    const antennaGroup = this.antennas.find(group => group.groupName === groupName);
    if (antennaGroup) antennaGroup.updateField(field, value, antennaInfo, windSelected);
    if (field === "qtdAntennas") this.renameAntennaGroups();
  }

  addAntennaGroup() {
    let newGroupNumber = 1;
    if (this.antennas.length > 0) {
      const lastGroup = this.antennas[this.antennas.length - 1];
      newGroupNumber = Number(lastGroup.group[lastGroup.group.length - 1]) + 1;
    }

    const newGroup = new AntennaGroupModel();
    newGroup.group = [newGroupNumber];
    newGroup.groupName = newGroupNumber.toString();
    this.antennas.push(newGroup);
  }

  deleteAntennaGroup(groupName) {
    if (this.antennas.length === 0) return;
    this.antennas = this.antennas.filter((antennaGroup) => antennaGroup.groupName !== groupName);

    this.renameAntennaGroups();
  }

  renameAntennaGroups() {
    if (this.antennas.length === 0) return;

    let currentNumber = 1;
    this.antennas.forEach((antennaGroup) => {
      const groupLength = antennaGroup.qtdAntennas || 1;
      const newGroup = [];

      for (let i = 0; i < groupLength; i++) {
        newGroup.push(currentNumber);
        currentNumber++;
      }

      antennaGroup.group = [...newGroup];
      if (newGroup.length === 1) {
        antennaGroup.groupName = newGroup[0].toString();
      } else if (newGroup.length === 2) {
        antennaGroup.groupName = `${newGroup[0]} e ${newGroup[1]}`;
      } else if (newGroup.length > 2) {
        antennaGroup.groupName = `${newGroup[0]} a ${newGroup[newGroup.length - 1]}`;
      }
    });
  }
}

class AntennasModel {
  constructor(data = {}) {
    this.antennasData = new AntennasDataModel(
      data.cableTrayLength,
      data.antennas,
    );
  }

  updateCableTrayLength(value) {
    this.antennasData.cableTrayLength = value;
  }

  updateAntenna(field, value, groupName, antennaInfo, windSelected) {
    this.antennasData.updateAntennaGroup(field, value, groupName, antennaInfo, windSelected);
  }

  addAntenna() {
    this.antennasData.addAntennaGroup();
  }

  deleteAntenna(groupName) {
    this.antennasData.deleteAntennaGroup(groupName);
  }

  validate(totalTowerHeight) {
    let errors = [];
    
    if (!this.antennasData.cableTrayLength) errors.push("Comprimento da esteira de cabos");

    this.antennasData.antennas.forEach((antenna, index) => {
      if (!antenna.qtdAntennas) errors.push(`Número de antenas no grupo ${antenna.groupName}`);
      if (!antenna.fixingHeight) errors.push(`Altura de fixação das antenas no grupo ${antenna.groupName}`);
      if (!antenna.operator) errors.push(`Operadora das antenas no grupo ${antenna.groupName}`);
      if (!antenna.condition) errors.push(`Condição das antenas no grupo ${antenna.groupName}`);
      if (!antenna.dragCoef) errors.push(`Coeficiente de arrasto das antenas no grupo ${antenna.groupName}`);
      if (!antenna.model) errors.push(`Modelo das antenas no grupo ${antenna.groupName}`);
      if (!antenna.diameterDimension && !antenna.widthDimension && !antenna.heightDimension) 
        errors.push(`Dimensões das antenas no grupo ${antenna.groupName}`);
      if (antenna.fixingHeight > totalTowerHeight)
        errors.push(`A altura de fixação das antenas no grupo ${antenna.groupName} não deve ser maior que a altura da torre`);
    });

    if (errors.length > 0) {
      const errorMessage = customToastError(errors,
        "Preencha todos os valores corretamente para salvar as informações de antenas. Falta(m):");
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  toJSON() {
    return JSON.stringify(this.antennasData);
  }

  static fromJSON(json) {
    const antennas = JSON.parse(json);
    return new AntennasModel(antennas);
  }
}

export default AntennasModel;
