import useAsync from "../../useAsync";
import { getSegments } from "../../../services/tower-services/towerSegmentsApi";

export const useTowerSegments = (action) => {
  const execute = {
    get: getSegments,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
