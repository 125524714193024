import api from "./api";
import apiTower from "./apiTower";

export async function postProject(project, token, projectType) {
  if (projectType === "Torre") {
    const response = await apiTower.post("/projects/insert", project, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  } else if (projectType === "Rooftop") {
    const response = await api.post("/projects/insert", project, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  }
}

export async function updateProject(project, nomeProjeto, token) {
  const encodedProjectName = encodeURIComponent(nomeProjeto);
  const response = await api.put(`/projects/update/${encodedProjectName}`, project, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
  return response.data;
}

export async function getProject(nomeProjeto, token) {
  const encodedProjectName = encodeURIComponent(nomeProjeto);
  const response = await api.get(`/projects/getOne/${encodedProjectName}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
  return response.data;
}

export async function getAllProjectsNames(token) {
  const response = await api.get("/projects/getAll", {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
  return response.data;
}

export async function getAllProjectsNamesWithDate(token, projectType) {
  if (projectType === "Torre") {
    const response = await apiTower.get("/projects/getAll?withDate=true", {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  } else if (projectType === "Rooftop") {
    const response = await api.get("/projects/getAll?withDate=true", {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  }
}

export async function deleteProject(projectName, token) {
  const encodedProjectName = encodeURIComponent(projectName);
  const response = await api.delete(`/projects/delete/${encodedProjectName}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
  return response.data;
}

export async function postProjectAccessHistory(projectName, token, projectType) {
  if (projectType === "Torre") {
    const response = await apiTower.post("/projects/accessHistory", { projectName }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  } else if (projectType === "Rooftop") {
    const response = await api.post("/projects/accessHistory", { projectName }, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  }
}

export async function getUserProjectAccessHistory(token, projectType) {
  if (projectType === "Torre") {
    const response = await apiTower.get("/projects/accessedProjects", {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  }

  if (projectType === "Rooftop") {
    const response = await api.get("/projects/accessedProjects", {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response.data;
  }
}

export async function postMemorial(nomeProjeto, printableStructures, documentType, token) {
  const response = await api.post("/projects/downloadMemorial", { nomeProjeto, printableStructures, documentType }, {
    headers: {
      Authorization: `Bearer ${token}`,
    }, responseType: "blob"
  });
  return response.data;
}

export async function updateProjectName(projectNameObj, token) {
  const response = await api.put("/projects/updateProjectName", projectNameObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
  return response.data;
}

export async function copyTowerProject(projectId, newProjectName, token) {
  const response = await apiTower.post(`/projects/copy/${projectId}`, newProjectName, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });
  return response.data;
}
