import { useCallback, useContext, useEffect, useState } from "react";
import { IoDocumentTextOutline } from "react-icons/io5";
import styled from "styled-components";
import copying_project_icon from "../../../assets/images/copying-project-icon.svg";
import opening_project_icon from "../../../assets/images/opening-project-icon.svg";
import { ProjectContext } from "../../../contexts/ProjectContext";
import UserContext from "../../../contexts/UserContext";
import useGetAllProjectsNamesWithDates from "../../../hooks/api/useGetAllProjectsNamesWithDate";
import { TopBar } from "../../topMenu-components/top-bar";
import ClassificatingProjectBox from "./project-classificationBox";
import ProjectFilteringBox from "./project-filteringBox";
import ProjectMovingLeftMenu from "./project-movingLeftMenu";
import NewProjectDialogue from "./project-openingDialog";
import RecentProjects from "./project-recentProjects";
import SearchingProjectBox from "./project-searchingBox";
import useGetUserProjectAccessHistory from "../../../hooks/api/useGetUserProjectAccessHistory";

export default function ProjectOpeningOptions({ setOpacity }) {
  const { userData } = useContext(UserContext);
  const { projectType } = useContext(ProjectContext);
  const { getAllProjectsNamesWithDates } = useGetAllProjectsNamesWithDates();
  const { getUserProjectAccessHistory } = useGetUserProjectAccessHistory();
  const [confirmDialog, setConfirmDialog] = useState({
    type: "",
    isOpen: false,
  });
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false);
  const [projectNamesList, setProjectNamesList] = useState([{}]);
  const [isCopyingProject, setIsCopyingProject] = useState(false);
  const [projectSearchingTerm, setProjectSearchingTerm] = useState("");
  const [projectFilterType, setProjectFilterType] = useState("name");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [projectClassificationType, setProjectClassificationType] = useState("");
  const [userRecentProjects, setUserRecentProjects] = useState([]);

  const handleOptionIconClick = async(actionType, projectName, projectId) => {
    setConfirmDialog({
      ...confirmDialog,
      type: actionType,
      isOpen: true,
      projectName,
      projectId
    });
    setOpacity("0.95");
    setIsCopyingProject(false);
  };

  useEffect(() => {
    if (projectType) {
      async function fetchAllProjectNames() {
        const projects = await getAllProjectsNamesWithDates(userData.token, projectType);
        setProjectNamesList(projects);
        setFilteredProjects(projects);
      }
      fetchAllProjectNames();
    }
  }, [confirmDialog]);

  /* eslint-disable */
  function sortProjects(projects, projectClassificationType) {
    const compareFunction = (a, b) => {
      switch (projectClassificationType) {
        case "dateDesc":
          return new Date(b?.createdAt)?.getTime() - new Date(a?.createdAt)?.getTime();
        case "dateAsc":
          return new Date(a?.createdAt)?.getTime() - new Date(b?.createdAt)?.getTime();
        case "nameAsc":
          return a?.nomeProjeto?.localeCompare(b?.nomeProjeto);
        case "nameDesc":
          return b?.nomeProjeto?.localeCompare(a?.nomeProjeto);
        default:
          return 0;
      }
    };
    return [...projects]?.sort(compareFunction);
  }
  /* eslint-enable */

  useEffect(() => {
    let firstFilteredProjects = projectNamesList;
    if (projectClassificationType) {
      firstFilteredProjects = sortProjects(projectNamesList, projectClassificationType);
    }
    if (projectFilterType === "name") {
      setFilteredProjects(firstFilteredProjects?.filter((project) =>
        project?.nomeProjeto?.toLowerCase()?.includes(projectSearchingTerm?.toLowerCase())));
    }
    else if (projectFilterType === "date") {
      const [day, month] = projectSearchingTerm.split("/").map(Number);
      const currentYear = new Date().getFullYear();
      const filterDate = new Date(currentYear, month - 1, day);
      if (isNaN(filterDate.getTime())) {
        return setFilteredProjects(firstFilteredProjects);
      }
      setFilteredProjects(firstFilteredProjects?.filter((project) => {
        const projectDate = new Date(project?.createdAt);
        if (projectSearchingTerm.length > 4) {
          return (
            projectDate.getDate() === filterDate.getDate() &&
            projectDate.getMonth() === filterDate.getMonth()
          );
        }
        else return (
          projectDate.getDate() === filterDate.getDate() ||
          projectDate.getMonth() === filterDate.getMonth()
        );
      }));
    }
    else if (projectFilterType === "user") {
      setFilteredProjects(firstFilteredProjects?.filter((project) =>
        project?.userName?.toLowerCase()?.includes(projectSearchingTerm?.toLowerCase())));
    }
    else return setFilteredProjects(firstFilteredProjects);
  }, [projectSearchingTerm, projectClassificationType, projectFilterType]);

  const getUserProjectsAccessed = useCallback(async() => {
    try {
      const userHistory = await getUserProjectAccessHistory(userData.token, projectType);
      setUserRecentProjects(userHistory);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getUserProjectsAccessed();
  }, [confirmDialog]);

  return (
    <TopBar projectType={projectType} ionIconName={""} isInProjectOpeningPage={true} setIsLeftMenuOpen={setIsLeftMenuOpen} isLeftMenuOpen={isLeftMenuOpen}>
      <Container>
        <ProjectMovingLeftMenu isLeftMenuOpen={isLeftMenuOpen} />
        <ProjectOptionsContainer isLeftMenuOpen={isLeftMenuOpen}>
          <NewProjectButton onClick={() => handleOptionIconClick("new")}>
            <div><IoDocumentTextOutline /></div>
            Novo Projeto
          </NewProjectButton>
          <RecentProjects
            handleOptionIconClick={handleOptionIconClick}
            userRecentProjects={userRecentProjects}
          />
          <ProjectsListContainer>
            <ProjectFilteringBox setProjectFilterType={setProjectFilterType} setProjectSearchingTerm={setProjectSearchingTerm} />
            <ClassificatingProjectBox setProjectClassificationType={setProjectClassificationType} />
            <SearchingProjectBox projectSearchingTerm={projectSearchingTerm} setProjectSearchingTerm={setProjectSearchingTerm} projectFilterType={projectFilterType} />
            <ProjectTitle>Projetos</ProjectTitle>
            <ProjectsListColumnsContainer isLeftMenuOpen={isLeftMenuOpen} projectType={projectType}>
              <h3>NOME DO PROJETO</h3>
              <h3>USUÁRIO</h3>
              <h3>DATA DE CRIAÇÃO</h3>
              <h3>NOME DO CLIENTE</h3>
              <h3>SITE ID</h3>
              {projectType === "Torre" ? (
                <>
                  <h3>SEÇÃO DA TORRE</h3>
                  <h3>ALTURA DA TORRE</h3>
                </>
              ) : null}
            </ProjectsListColumnsContainer>
            <ProjectsTableContainer>
              {filteredProjects.length ?
                filteredProjects.map((project) =>
                  <ProjectInLine
                    key={project.nomeProjeto}
                    handleOptionIconClick={handleOptionIconClick}
                    projectName={project.nomeProjeto}
                    projectId={project.projectId}
                    project={project}
                    isLeftMenuOpen={isLeftMenuOpen}
                    projectType={projectType}>
                  </ProjectInLine>
                )
                :
                ""
              }
            </ProjectsTableContainer>
          </ProjectsListContainer>
        </ProjectOptionsContainer>
        <NewProjectDialogue
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
          projectNamesList={projectNamesList}
          setProjectNamesList={setProjectNamesList}
          isCopyingProject={isCopyingProject}
          setIsCopyingProject={setIsCopyingProject}
        />
      </Container>
    </TopBar>
  );
}

function ProjectInLine({ handleOptionIconClick, isLeftMenuOpen, project, projectType }) {
  function formatarData(dataString) {
    const data = new Date(dataString);
    const dia = String(data.getDate()).padStart(2, "0");
    const mes = String(data.getMonth() + 1).padStart(2, "0");
    const ano = data.getFullYear();
    return `${dia}/${mes}/${ano}`;
  }
  return (
    <ProjectInLineContainer isLeftMenuOpen={isLeftMenuOpen} projectType={projectType}>
      <img src={opening_project_icon} onClick={() => handleOptionIconClick("open", project?.nomeProjeto, project?.projectId)} alt="Opening Icon" />
      <img src={copying_project_icon} onClick={() => handleOptionIconClick("copy", project?.nomeProjeto, project?.projectId)} alt="Copying Icon" />
      <ion-icon onClick={() => handleOptionIconClick("delete", project?.nomeProjeto, project?.projectId)} name="trash-outline"></ion-icon>
      <ion-icon onClick={() => handleOptionIconClick("edit", project?.nomeProjeto, project?.projectId)} name="pencil"></ion-icon>
      <h1>{project?.nomeProjeto}</h1>
      <h2>{project?.userName || "-"}</h2>
      <h3>{formatarData(project?.createdAt) || "-"}</h3>
      <h4>{project?.client || "-"}</h4>
      <h5>{project?.siteId || "-"}</h5>
      {projectType === "Torre" ? (
        <>
          <h6>{project?.towerSection || "-"}</h6>
          <h6 className="smaller">{project?.towerHeight || "-"}</h6>
        </>
      ) : null}
    </ProjectInLineContainer>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #fff;
  width: 100vw;
  height: calc(100vh - 50px);
`;

const ProjectOptionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  width: ${(props => props.isLeftMenuOpen ? "calc(100vw - 250px)" : "100vw")};
  height: calc(100vh - 50px);
  margin-left: ${(props => props.isLeftMenuOpen ? "250px" : "0")};
  flex-wrap: wrap;
  z-index: 5;
  border: 30px solid #B0B0B0;
  border-top: 70px solid #B0B0B0;
`;

const ProjectTitle = styled.h1`
  font-size: 21px;
  color: #000000;
  margin: 22px 94.5% 30px 0%;
  text-align: left;
  @media (max-height: 720px) {
    margin: 20px 94.5% 18px 0%;
  }
`;

const NewProjectButton = styled.div`
  position: absolute;
  top: -53px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 38px;
  background-color: #FE8F03;
  border-radius: 6px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  div {
    padding-right: 10px;
    font-size: 22px;
  }
  &:active {
    background: linear-gradient(#FE8F03,#F16E00);
    transition: background-color 0.3s ease; 
  }
`;

const ProjectsListContainer = styled.div`
  width: 93%;
  max-height: 70%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 3.5%;
  flex-direction: column !important;
  position: relative;
    @media (max-height: 720px) {
      max-height: 60%;
  }
`;

const ProjectInLineContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #D4D4D4;
  padding-bottom: 10px;
  margin-bottom: 16px;
  h1, h2, h3, h4, h5, h6 {
    color: #888888;
    font-size: 16px;
    position: sticky !important;
    left: 8vw;
    max-width: 12vw;
    word-wrap: break-word;
  }
  h2 {
    left: ${(props) => (props.isLeftMenuOpen && (props.projectType === "Rooftop") ? "23vw" : props.isLeftMenuOpen ? "18vw" : (props.projectType === "Rooftop") ? "25vw" : "20vw")};
  }
  h3 {
    left: ${(props) => (props.isLeftMenuOpen && (props.projectType === "Rooftop") ? "35vw" : props.isLeftMenuOpen ? "25vw" : (props.projectType === "Rooftop") ? "39vw" : "28vw")};
  }
  h4 {
    left: ${(props) => (props.isLeftMenuOpen && (props.projectType === "Rooftop") ? "51vw" : props.isLeftMenuOpen ? "35vw" : (props.projectType === "Rooftop") ? "56vw" : "40vw")};
  }
  h5 {
    left: ${(props) => (props.isLeftMenuOpen && (props.projectType === "Rooftop") ? "66vw" : props.isLeftMenuOpen ? "48vw" : (props.projectType === "Rooftop") ? "74vw" : "52vw")};
  }
  h6 {
    left: ${(props) => (props.isLeftMenuOpen ? "55vw" : "60vw")};
  }
  .smaller {
    left: ${(props) => (props.isLeftMenuOpen ? "65vw" : "72vw")};
  }
  
  img {
    cursor: pointer;
    padding-right: 14px;  
    width: 37px;
  }
  ion-icon {
    font-size: 22px;
    padding-right: 14px;
    cursor: pointer;
  }
  ion-icon:nth-child(1) {
    color: #467A9F;
  }
  ion-icon:nth-child(2) {
    color: #626262;
  }
  ion-icon:nth-child(3) {
    color: #CA2121;
  }
`;

const ProjectsTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 76%;
  overflow-y: scroll;
  @media (max-height: 720px) {
    max-height: 66%;
  }
  ::-webkit-scrollbar {
    width: 6px; 
    margin-left: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #d9d9d9; 
    border-radius: 5px; 
    margin-left: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  scrollbar {
    width: 6px;
    margin-left: 5px;
  }
  scrollbar-thumb {
    background-color: #d9d9d9; 
    border-radius: 5px;
    margin-left: 5px;
  }
  scrollbar-thumb:hover {
    background-color: #d9d9d9; 
  }
`;

const ProjectsListColumnsContainer = styled.div`
  display: flex;
  height: 50px;
  width: 100%;
  background-color: #d9d9d9;
  border-radius: 6px 6px 0px 0px;
  position: relative;
  margin-bottom: 20px;
  z-index: -1;
  h3 {
    font-weight: 500;
    font-size: 15px;
    color: #333333;
    position: absolute;
    top: 17px;
  }
  
  h3:nth-child(1){
    left: 8vw;
  }
  h3:nth-child(2){
    left: ${(props) => (props.isLeftMenuOpen && (props.projectType === "Rooftop") ? "23vw" : props.isLeftMenuOpen ? "18vw" : (props.projectType === "Rooftop") ? "25vw" : "20vw")};
  }
  h3:nth-child(3){
    left: ${(props) => (props.isLeftMenuOpen && (props.projectType === "Rooftop") ? "35vw" : props.isLeftMenuOpen ? "25vw" : (props.projectType === "Rooftop") ? "39vw" : "28vw")};
  }
  h3:nth-child(4){
    left: ${(props) => (props.isLeftMenuOpen && (props.projectType === "Rooftop") ? "51vw" : props.isLeftMenuOpen ? "35vw" : (props.projectType === "Rooftop") ? "56vw" : "40vw")};
  }
  h3:nth-child(5){
    left: ${(props) => (props.isLeftMenuOpen && (props.projectType === "Rooftop") ? "66vw" : props.isLeftMenuOpen ? "48vw" : (props.projectType === "Rooftop") ? "74vw" : "52vw")};
  }

  h3:nth-child(6){
    left: ${(props) => (props.isLeftMenuOpen ? "55vw" : "60vw")};
  }
  h3:nth-child(7){
    left: ${(props) => (props.isLeftMenuOpen ? "65vw" : "72vw")};
  }
`;
