import MD_1_1 from "./1MOD/MD-1-1.jpg";
import MD_1_2 from "./1MOD/MD-1-2.jpg";
import MD_1_3 from "./1MOD/MD-1-3.jpg";
import MD_1_4 from "./1MOD/MD-1-4.jpg";
import MD_1_5 from "./1MOD/MD-1-5.jpg";
import MD_1_6 from "./1MOD/MD-1-6.jpg";
import MD_1_7 from "./1MOD/MD-1-7.jpg";
import MD_1_8 from "./1MOD/MD-1-8.jpg";
import MD_1_9 from "./1MOD/MD-1-9.jpg";
import MD_1_10 from "./1MOD/MD-1-10.jpg";

import MD_2_2 from "./2MOD/MD-2-2.jpg";
import MD_2_3 from "./2MOD/MD-2-3.jpg";
import MD_2_4 from "./2MOD/MD-2-4.jpg";
import MD_2_5 from "./2MOD/MD-2-5.jpg";
import MD_2_6 from "./2MOD/MD-2-6.jpg";
import MD_2_7 from "./2MOD/MD-2-7.jpg";
import MD_2_8 from "./2MOD/MD-2-8.jpg";
import MD_2_9 from "./2MOD/MD-2-9.jpg";
import MD_2_10 from "./2MOD/MD-2-10.jpg";

import MD_3_3 from "./3MOD/MD-3-3.jpg";
import MD_3_4 from "./3MOD/MD-3-4.jpg";
import MD_3_5 from "./3MOD/MD-3-5.jpg";
import MD_3_6 from "./3MOD/MD-3-6.jpg";
import MD_3_7 from "./3MOD/MD-3-7.jpg";
import MD_3_8 from "./3MOD/MD-3-8.jpg";
import MD_3_9 from "./3MOD/MD-3-9.jpg";
import MD_3_10 from "./3MOD/MD-3-10.jpg";

import MD_4_4 from "./4MOD/MD-4-4.jpg";
import MD_4_5 from "./4MOD/MD-4-5.jpg";
import MD_4_6 from "./4MOD/MD-4-6.jpg";
import MD_4_7 from "./4MOD/MD-4-7.jpg";
import MD_4_8 from "./4MOD/MD-4-8.jpg";
import MD_4_9 from "./4MOD/MD-4-9.jpg";
import MD_4_10 from "./4MOD/MD-4-10.jpg";

import MD_5_5 from "./5MOD/MD-5-5.jpg";
import MD_5_6 from "./5MOD/MD-5-6.jpg";
import MD_5_7 from "./5MOD/MD-5-7.jpg";
import MD_5_8 from "./5MOD/MD-5-8.jpg";
import MD_5_9 from "./5MOD/MD-5-9.jpg";
import MD_5_10 from "./5MOD/MD-5-10.jpg";

import MD_6_6 from "./6MOD/MD-6-6.jpg";
import MD_6_7 from "./6MOD/MD-6-7.jpg";
import MD_6_8 from "./6MOD/MD-6-8.jpg";
import MD_6_9 from "./6MOD/MD-6-9.jpg";
import MD_6_10 from "./6MOD/MD-6-10.jpg";

import MD_7_7 from "./7MOD/MD-7-7.jpg";
import MD_7_8 from "./7MOD/MD-7-8.jpg";
import MD_7_9 from "./7MOD/MD-7-9.jpg";
import MD_7_10 from "./7MOD/MD-7-10.jpg";

import MD_8_8 from "./8MOD/MD-8-8.jpg";
import MD_8_9 from "./8MOD/MD-8-9.jpg";
import MD_8_10 from "./8MOD/MD-8-10.jpg";

import MD_9_9 from "./9MOD/MD-9-9.jpg";
import MD_9_10 from "./9MOD/MD-9-10.jpg";

import MD_10_10 from "./10MOD/MD-10-10.jpg";

const MDImg = { MD_1_1, MD_1_2, MD_1_3, MD_1_4, MD_1_5, MD_1_6, MD_1_7, MD_1_8, MD_1_9, MD_1_10, MD_2_2, MD_2_3, MD_2_4, MD_2_5, MD_2_6, MD_2_7, MD_2_8, MD_2_9, MD_2_10, MD_3_3, MD_3_4, MD_3_5, MD_3_6, MD_3_7, MD_3_8, MD_3_9, MD_3_10, MD_4_4, MD_4_5, MD_4_6, MD_4_7, MD_4_8, MD_4_9, MD_4_10, MD_5_5, MD_5_6, MD_5_7, MD_5_8, MD_5_9, MD_5_10, MD_6_6, MD_6_7, MD_6_8, MD_6_9, MD_6_10, MD_7_7, MD_7_8, MD_7_9, MD_7_10, MD_8_8, MD_8_9, MD_8_10, MD_9_9, MD_9_10, MD_10_10 };

export default MDImg;
