import whiteBackgroungBuilding from "../../../assets/images/buildingImages/FUNDO BRANCO";
import transparentBackgroungBuilding from "../../../assets/images/buildingImages/FUNDO TRANSPARENTE";

export default function MastBuildingImg({ type }) {
  return (
    <>
      <img src={whiteBackgroungBuilding[type]} alt={""} loading="lazy"/>
    </>
  );
};
