import { useContext } from "react";
import styled from "styled-components";
import rooftop_big_icon from "../../../assets/images/rooftop-big-icon.png";
import tower_big_icon from "../../../assets/images/torre-big-icon.png";
import { ProjectContext } from "../../../contexts/ProjectContext";

export default function RecentProjects({ handleOptionIconClick, userRecentProjects }) {
  const { projectType } = useContext(ProjectContext);

  return (
    userRecentProjects?.length ?
      <RecentProjectsContainer>
        <ProjectTitle>Seus Projetos Recentes</ProjectTitle>
        <RecentProjectsCardsContainer>
          {userRecentProjects.map((project, index) =>
            <RecentProjectCard key={index} onClick={() => handleOptionIconClick("open", project.nomeProjeto, project?.projectId)}>
              {projectType === "Rooftop"
                ? <img src={rooftop_big_icon} alt="Type Project Icon" />
                : <img src={tower_big_icon} alt="Type Project Icon" />}
              <h1>{project.nomeProjeto}</h1>
              <h2>{projectType}</h2>
            </RecentProjectCard>
          )}
        </RecentProjectsCardsContainer>
      </RecentProjectsContainer>
      :
      ""
  );
};

const RecentProjectsContainer = styled.div`
  width: 100%;
  height: 230px;
  align-items: flex-start;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-bottom: 12px solid #B0B0B0;
  padding-left: 30px;
  @media (max-height: 720px) {
    height: 210px !important;
  }
`;

const ProjectTitle = styled.h1`
  font-size: 21px;
  color: #000000;
`;

const RecentProjectsCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 150px;
  padding-top: 16px;
`;

const RecentProjectCard = styled.div`
  width: 150px;
  height: 130px;
  border-radius: 6px;
  background-color: #F2F2F2;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  :hover {
    background-color: #d9d9d9;
  }
  h1 {
    margin: 20px 0 8px 0;
    color: #000;
    font-size: 14px;
    width: 90%;
  }
  h2 {
    color: #666666;
    font-size: 14px;
  }
  img {
    width: 26px;
    height: 46px;
    margin: 0px 0 -8px 0;
  }
`;
