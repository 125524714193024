import useAsync from "../useAsync";
import * as bugReportApi from "../../services/bugReportApi";

export default function usePostBugReport() {
  const {
    data: bugReport,
    loading: postBugReportLoading,
    error: postBugReportError,
    act: postBugReport,
  } = useAsync((body, token) => bugReportApi.postBugReport(body, token), false);

  return {
    bugReport,
    postBugReportLoading,
    postBugReportError,
    postBugReport
  };
}
