import styled from "styled-components";
import {
  Dialog,
  DialogActions,
} from "@mui/material";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import white_printer_icon from "../../../assets/images/white-printer-icon.svg";
import UserContext from "../../../contexts/UserContext";
import usePostDownloadMemorial from "../../../hooks/api/usePostDownloadMemorial";
import { ProjectContext } from "../../../contexts/ProjectContext";

export default function CreateMemorialDialog({ confirmDialog, setConfirmDialog, printableStructures }) {
  const { userData } = useContext(UserContext);
  const { projectSelected } = useContext(ProjectContext);
  const { postMemorial, postMemorialLoading } = usePostDownloadMemorial();
  const [inputValues, setInputValues] = useState({
    documentType: "docx",
    fileName: "",
    fileDestination: "",
  });
  
  async function downloadMemorial() {
    try {
      const memorial = await postMemorial(projectSelected.nomeProjeto, printableStructures, inputValues.documentType, userData.token);
      await blobToDownload(memorial, inputValues.documentType);
      return toast.success("Memorial gerado e baixado com sucesso");
    } catch (error) {
      toast.error("Não foi possivel gerar memorial, tente novamente mais tarde...");
      console.log(error);
    }
  }
    
  async function blobToDownload(memorial, documentType) {
    const file = memorial;
    const fileUrl = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = `${inputValues.fileName || "memorial"}.${documentType}`;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(fileUrl);
    toast.success("Download realizado com sucesso");
  }

  return (
    <Dialog open={confirmDialog.isOpen} fullWidth={true} maxWidth="sm">
      <DiolagContainer dialogType={confirmDialog.type}>
        <h1>Gerar Memorial</h1>
        <SpacingLine />
        <ProjectActionContainer>
          <h2>Tipo do documento</h2>
          <select onChange={(e) => setInputValues({ ...inputValues, documentType: e.target.value })} placeholder="tipo do documento">
            <option value={"docx"}>Documento do Word (*.docx)</option>
            <option value={"pdf"}>Documento pdf (*.pdf)</option>
          </select>
          <h2>Nome do arquivo</h2>
          <input type="text"onChange={(e) => setInputValues({ ...inputValues, fileName: e.target.value })} placeholder="nome do arquivo"></input>
          {postMemorialLoading ? 
            <GreenConfirmDialogButton><p>Carregando...</p></GreenConfirmDialogButton> :
            <GreenConfirmDialogButton onClick={downloadMemorial}>
              <p>GERAR MEMORIAL</p>
              <img src={white_printer_icon} alt="White printer icon" />
            </GreenConfirmDialogButton>}
        </ProjectActionContainer>
        <DialogActions>
          {" "}
        </DialogActions>
        <ion-icon onClick={() => {setConfirmDialog({ ...confirmDialog, isOpen: false }); }} name="close"></ion-icon>
      </DiolagContainer>
    </Dialog>
  );
}

const DiolagContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
  width: 100%;
  background-color: #ffffff;

  h1 {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    width: 92%;

    span {
      font-weight: 500;
    }
  }

  ion-icon {
    position: absolute;
    top: 10px;
    right: 16px;
    font-size: 28px;
    color: #AEAEAE;
    cursor: pointer;

    :active {
      color: #000;
    }
  };
`;

export const ProjectActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  padding: 0 34px 0 10px;
  background-color: #ffffff;
  height: 200px;
  width: 540px;

  h2 {
    color: #666666;
    font-size: 16px;
    margin-bottom: 6px;
  }

  h3 {
    color: #000000;
    font-weight: 500;
    font-size: 20px;
  }

  input,
  select {
    height: 4vh;
    width: 106%;
    color: #000000 !important;
    background-color: #fff;
    border: 1px solid #C5C5C5;
    font-size: 16px;
    text-align: left;
    padding-left: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  input::placeholder{
  }
`;

const SpacingLine = styled.div`
  display: flex;
  width: 100%;
  height: 1.35px;
  margin: 12px 0 18px 0;
  background-color: #DDDDDD;
`;

const GreenConfirmDialogButton = styled.div `
  font-size: 15px;
  width: 106%;
  height: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #5FA037;
  border-radius: 4px;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  &:active {
    background: linear-gradient(#333, #333);
  }
  img {
    margin-left: 12px;
  }
`;
