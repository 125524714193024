import api from "../apiTower";

const makeHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
  responseType: "blob",
});

export async function postTowerCalculateStrap(body, token) {
  const response = await api.post(
    "/tower/calculateByStrap", body, makeHeaders(token)
  );
  return response.data;
}
