import styled from "styled-components";
import { MenuOption, LeftTitle, ButtonPositioner, ReturnButton } from "../../project-components/wind-components/project-windData";
import { useContext, useState, useEffect } from "react";
import { ProjectContext } from "../../../contexts/ProjectContext";
import UserContext from "../../../contexts/UserContext";
import { MenuContext } from "../../../contexts/MenuContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useUpdateMast from "../../../hooks/api/useUpdateMast";
import LoadingButton from "../../common/loading-button";
import SavingButton from "../../common/saving-button";
import CreatableSelect from "react-select/creatable";
import useUnsavedChangesAlert from "../../../hooks/useUnsavedChangesAlert";
import ResizableLeftContainer from "../../common/resizableLeftContainer";
import ResizableRightContainer from "../../common/resizableRightContainer";
import { ReturnIcon, AdvanceIcon } from "../../project-components/wind-components/project-windData";
import MastGeometryRightContainer from "./mast-geometryRightContainer";

export default function MastGeometryPage() {
  const { setLeftMenuOptionSelected, projectSelected, mastSelected, reloadProject, setReloadProject, setMastSelected, setIsUpdatedWithoutCalculation, setProjectNameSelected, setProjectSelected } = useContext(ProjectContext);
  const { userData } = useContext(UserContext);
  const { leftMenuWidth } = useContext(MenuContext);
  let mastProps = JSON.parse(mastSelected.props);
  const { updateMast, updateMastLoading } = useUpdateMast();
  const tiposMastro = ["MA", "MB", "MC", "MD", "ME", "MF", "MG", "MH", "MI"];
  const estaisByModules = { MA: 0, MB: 2, MC: 4, MD: 4, ME: 5, MF: 7, M7: 2, MH: 1, MI: 2 };
  const [inputValues, setInputValues] = useState({
    tipoMastro: mastProps?.tipoMastro || "",
    qtdModulos: mastProps?.qtdModulos|| "",
    alturaMastro: mastProps?.alturaMastro || "",
    operadoraMastro: mastProps?.operadoraMastro || "",
    alturaEdificacao: mastProps?.alturaEdificacao || "",
  });
  const [estaiPositions, setEstaiPositions] = useState({ 
    estai1ModuloAcoplado: mastProps.estai1ModuloAcoplado || 1,
    estai2ModuloAcoplado: mastProps.estai2ModuloAcoplado || 1,
    estai3ModuloAcoplado: mastProps.estai3ModuloAcoplado || 1,
    estai4ModuloAcoplado: mastProps.estai4ModuloAcoplado || 1,
  });

  const fieldsToCheck = ["tipoMastro", "alturaEdificacao", "qtdModulos", "alturaMastro"];
  const [updatedFieldsToCheck, setUpdatedFieldsToCheck] = useState(fieldsToCheck);
  const isInputModified = useUnsavedChangesAlert({ ...inputValues, ...estaiPositions }, mastProps, reloadProject, updatedFieldsToCheck);

  useEffect(() => {
    if (inputValues.tipoMastro === "MA") {
      setEstaiPositions({ });
      setUpdatedFieldsToCheck(fieldsToCheck);
    }
    else {
      let newEstaiPositions = {};
      for (let i = 1; i <= estaisByModules[inputValues.tipoMastro]; i ++) {
        newEstaiPositions[`estai${i}ModuloAcoplado`] = mastProps[`estai${i}ModuloAcoplado`] || 1;
      }
      setEstaiPositions(newEstaiPositions);
      const newFieldsToCheck = fieldsToCheck;
      if (inputValues.tipoMastro === "MC" || inputValues.tipoMastro === "MI") {
        newFieldsToCheck.push("estai1ModuloAcoplado");
        newFieldsToCheck.push("estai3ModuloAcoplado");
        setUpdatedFieldsToCheck(newFieldsToCheck);
      }
      else {
        newFieldsToCheck.push("estai1ModuloAcoplado");
        setUpdatedFieldsToCheck(newFieldsToCheck);
      }
    };
  }, [inputValues.tipoMastro, inputValues.qtdModulos]);

  async function updateMastGeometryData() {
    if(inputValues.alturaMastro > 100 || inputValues.alturaMastro < 0 ) return toast.error("O valor da altura do mastro deve estar contido entre 0 e 100");
    for (let i = 1; i <= estaisByModules[inputValues.tipoMastro]; i++) {
      if (Number(inputValues.qtdModulos) && (Number(estaiPositions[`estai${i}ModuloAcoplado`]) <= 0 || Number(estaiPositions[`estai${i}ModuloAcoplado`]) > Number(inputValues.qtdModulos) || Number(estaiPositions[`estai${i}ModuloAcoplado`]) % 1 !== 0)) {
        return toast.error(`O estai ${i} não está posicionado em um módulo existente!`);
      }
    }
    if (mastProps?.tipoMastro && mastProps?.tipoMastro !== inputValues.tipoMastro) {
      if (window.confirm("Tem certeza de que deseja mudar o tipo do mastro? Todos os dados de geometria salvos serão perdidos.")) {
        mastProps = deleteMastPropertiesWhenMastTypeIsUpdated(mastProps);
      }
      else return;
    }  
    let whatIsMissingMessage = "";
    if (!inputValues.tipoMastro || !inputValues.qtdModulos || !inputValues.alturaMastro || !inputValues.alturaEdificacao) {
      if (!inputValues.tipoMastro) whatIsMissingMessage += "Tipo do Mastro; ";
      if (!inputValues.alturaMastro) whatIsMissingMessage += "Altura do Mastro; ";
      if (!inputValues.qtdModulos) whatIsMissingMessage += "Quantidade de módulos; ";
      if (!inputValues.alturaEdificacao) whatIsMissingMessage += "Nível da laje; ";
      return toast.error(`Preencha todos os valores para salvar os dados. Falta(m) ${whatIsMissingMessage}`);
    }
    try {
      const updatedMastProps = { ...mastProps, ...estaiPositions };
      const updatedProps = JSON.stringify({ ...updatedMastProps, tipoMastro: inputValues.tipoMastro, qtdModulos: inputValues.qtdModulos, alturaMastro: inputValues.alturaMastro, alturaEdificacao: inputValues.alturaEdificacao, ...estaiPositions });
      const toBeUpdatedMast = { ...mastSelected, updatedAt: new Date(), props: updatedProps, tipo: inputValues.tipoMastro };
      const updatedMast = await updateMast(toBeUpdatedMast, userData.token);
      setMastSelected(updatedMast);
      setReloadProject(!reloadProject);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações atualizadas com sucesso");
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
      if (error?.message) return toast.error(error.message);
    }
  }
  function deleteMastPropertiesWhenMastTypeIsUpdated(mastProps) {
    const cleanedMastProps = {
      projectId: mastProps.projectId, 
      identificador: mastProps.identificador, 
      tipoMastro: mastProps.tipoMastro, 
      qtdModulos: mastProps.qtdModulos, 
      alturaMastro: mastProps.alturaMastro, 
      alturaEdificacao: mastProps.alturaEdificacao, 
      perfisLaminados: mastProps.perfisLaminados, 
      tubos: mastProps.tubos, 
      chapas: mastProps.chapas, 
      chumbadores: mastProps.chumbadores, 
      parafusos: mastProps.parafusos, 
      concreto: mastProps.concreto, 
      aco: mastProps.aco, 
      local: mastProps.local, 
      v0: mastProps.v0, 
      s1: mastProps.s1, 
      s2: mastProps.s2, 
      s3: mastProps.s3, 
      coordenadasLocal: mastProps.coordenadasLocal, 
      enderecoRua: mastProps.enderecoRua, 
      enderecoNumero: mastProps.enderecoNumero, 
      enderecoBairro: mastProps.enderecoBairro, 
      enderecoUF: mastProps.enderecoUF, 
      enderecoCidade: mastProps.enderecoCidade, 
      edificacaoAltura: mastProps.edificacaoAltura, 
      edificacaoAndares: mastProps.edificacaoAndares, 
      edificacaoUso: mastProps.edificacaoUso, 
      empresaNome: mastProps.empresaNome, 
      empresaCodigoSite: mastProps.empresaCodigoSite, 
      empresaResponsavel: mastProps.empresaResponsavel, 
      clienteNome: mastProps.clienteNome, 
      clienteIDSite: mastProps.clienteIDSite, 
      clienteOperatora: mastProps.clienteOperatora, 
      esteira: mastProps.esteira,
      qtdTotalAntenas: mastProps.qtdTotalAntenas,
      ...Object.keys(mastProps)
        .filter(key => key.startsWith("modulo") || key.startsWith("antena") || key.startsWith("ancoragemEstaiCentral"))
        .reduce((obj, key) => {
          obj[key] = mastProps[key];
          return obj;
        }, {})
    };
    return cleanedMastProps;
  }

  function handleMenuClick(menuType, menuName) {
    if (menuType === "project") {
      setProjectSelected({ });
      setProjectNameSelected("");
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else if (menuType === "projectName") {
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else {
      setLeftMenuOptionSelected("");
    }
  }

  return (
    <>
      <ResizableLeftContainer width={leftMenuWidth}>
        <LeftTitle>Geometria</LeftTitle>
        <ReturnIcon onClick={() => {
          if (isInputModified) {
            if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("materiais");
          }
          else setLeftMenuOptionSelected("vento");
        }}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ReturnIcon>
        <AdvanceIcon disabled={isInputModified} onClick={() => {
          if(!mastProps.qtdModulos) return toast.error("É necessário definir o número de módulos do mastro em Geometria");
          if (isInputModified) {
            if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja avançar?")) setLeftMenuOptionSelected("modulos");
          }
          else setLeftMenuOptionSelected("modulos");
        }}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </AdvanceIcon>
        <MenuOption>
          <h2>Tipo de Mastro</h2>
          <select onChange={(e) => setInputValues({ ...inputValues, tipoMastro: e.target.value })}>
            {mastProps?.tipoMastro ? <option value={mastProps.tipoMastro} >{mastProps.tipoMastro}</option> :             <option value={""} >Selecione o tipo de mastro</option>}
            {tiposMastro.map((tipo) => (mastProps?.tipoMastro !== tipo && <option value={tipo} key={tipo}>{tipo}</option>))}
          </select>
        </MenuOption>
        <MenuOption>
          <h2>Altura do Mastro (m)</h2>
          <input autoFocus max="100" min="0" type="number" placeholder={mastProps?.alturaMastro ? mastProps.alturaMastro : "Informe a altura do mastro"} onChange={(e) => setInputValues({ ...inputValues, alturaMastro: e.target.value })}>
          </input>
        </MenuOption>
        <MenuOption>
          <h2>Quantidade de Módulos</h2>
          <select onChange={(e) => setInputValues({ ...inputValues, qtdModulos: e.target.value })}>
            {inputValues.qtdModulos ? <option value={inputValues.qtdModulos} >{inputValues.qtdModulos}</option> :             <option value={""} >Selecione a quantidade de módulos</option>}
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
              i !== Number(inputValues.qtdModulos) && !(inputValues.tipoMastro === "MI" && i > 5) && <option value={i} key={i}>{i}</option>
            ))}
          </select>
        </MenuOption>
        {inputValues.tipoMastro && inputValues.tipoMastro !== "MA" &&
          <MenuDoubleOptionEstaiPosition>
            <div>
              <div>
                <h2>Estai (s)</h2>
              </div>
              <div>
                <h2>Módulo Acoplado</h2>
              </div>
            </div>
            {
              (inputValues.tipoMastro === "MB" || inputValues.tipoMastro === "MD" || inputValues.tipoMastro === "ME" || inputValues.tipoMastro === "MF" || inputValues.tipoMastro === "MG" || inputValues.tipoMastro === "MI") && 
                <div>
                  <div>
                    <input disabled={true} placeholder={"Estais 1 e 2"} value={"Estais 1 e 2"}   style={{ backgroundColor: "#d0ceceae", color: "#000", paddingLeft: "10px", cursor: "not-allowed" }}></input>
                  </div>
                  <div>
                    <input value={estaiPositions["estai1ModuloAcoplado"] || 1} placeholder={mastProps["estai1ModuloAcoplado"] || "Número do Módulo"} type="number" min={1} max={Number(inputValues.qtdModulos)} onChange={(e) => setEstaiPositions({ ...estaiPositions,  ["estai1ModuloAcoplado"]: e.target.value, ["estai2ModuloAcoplado"]: e.target.value })}></input>
                  </div>
                </div>
            }
            {
              (inputValues.tipoMastro === "MC") && 
                <>
                  <div>
                    <div>
                      <input disabled={true} placeholder={"Estais 1 e 2"} value={"Estais 1 e 2"}   style={{ backgroundColor: "#d0ceceae", color: "#000", paddingLeft: "10px", cursor: "not-allowed" }}></input>
                    </div>
                    <div>
                      <input value={estaiPositions["estai1ModuloAcoplado"] || 1} placeholder={mastProps["estai1ModuloAcoplado"] || "Número do Módulo"} type="number" min={1} max={Number(inputValues.qtdModulos)} onChange={(e) => setEstaiPositions({ ...estaiPositions,  ["estai1ModuloAcoplado"]: e.target.value, ["estai2ModuloAcoplado"]: e.target.value })}></input>
                    </div>
                  </div>
                  <div>
                    <div>
                      <input disabled={true} placeholder={"Estais 3 e 4"} value={"Estais 3 e 4"}   style={{ backgroundColor: "#d0ceceae", color: "#000", paddingLeft: "10px", cursor: "not-allowed" }}></input>
                    </div>
                    <div>
                      <input value={estaiPositions["estai3ModuloAcoplado"] || 1} placeholder={mastProps["estai3ModuloAcoplado"] || "Número do Módulo"} type="number" min={1} max={Number(inputValues.qtdModulos)} onChange={(e) => setEstaiPositions({ ...estaiPositions,  ["estai3ModuloAcoplado"]: e.target.value, ["estai4ModuloAcoplado"]: e.target.value })}></input>
                    </div>
                  </div>
                </>
            }
            {
              (inputValues.tipoMastro === "MH") && 
                <div>
                  <div>
                    <input disabled={true} placeholder={"Estai 1"} value={"Estai 1"}   style={{ backgroundColor: "#d0ceceae", color: "#000", paddingLeft: "10px", cursor: "not-allowed" }}></input>
                  </div>
                  <div>
                    <input value={estaiPositions["estai1ModuloAcoplado"] || 1} placeholder={mastProps["estai1ModuloAcoplado"] || "Número do Módulo"} type="number" min={1} max={Number(inputValues.qtdModulos)} onChange={(e) => setEstaiPositions({ ...estaiPositions,  ["estai1ModuloAcoplado"]: e.target.value })}></input>
                  </div>
                </div>
            }
          </MenuDoubleOptionEstaiPosition>
        }
        <MenuOption>
          <h2>Nível da Laje (m)</h2>
          <input type="number" min={1} value={inputValues.alturaEdificacao} placeholder={inputValues.alturaEdificacao ? inputValues.alturaEdificacao : "Digite o nível da laje"} onChange={(e) => setInputValues({ ...inputValues, alturaEdificacao: e.target.value })}>
          </input>
        </MenuOption>
        <ButtonPositioner >
          <ReturnButton onClick={() => {
            if (isInputModified) {
              if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("");
            }
            else setLeftMenuOptionSelected("");
          }} >{" Voltar "}</ReturnButton>
          {updateMastLoading ?           
            <LoadingButton>{ "Carregando" }</LoadingButton> :
            <SavingButton disabled={!isInputModified} onClick={() => { if((!updateMastLoading && isInputModified) || !estaiPositions ) updateMastGeometryData();} }>{ "Salvar" }</SavingButton>}
        </ButtonPositioner>
      </ResizableLeftContainer>
      <ResizableRightContainer>
        <RightGeometryPageContainer>
          <ProjectNameWrapper>
            <h2><span onClick={() => handleMenuClick("project")}>Projetos</span> / <span onClick={() => handleMenuClick("projectName")}>{projectSelected?.nomeProjeto}</span> <span onClick={() => handleMenuClick("")}>{mastSelected?.identificador ? ` / ${mastSelected?.identificador}` : ""}</span></h2>
            <h1><span onClick={() => handleMenuClick("")}>{mastSelected?.identificador ? `${mastSelected?.identificador}` : projectSelected?.nomeProjeto}</span></h1>
          </ProjectNameWrapper>
          <MastGeometryRightContainer inputValues={inputValues} estaiPositions={estaiPositions} mastProps={mastProps}/>
        </RightGeometryPageContainer>
      </ResizableRightContainer>
    </>
  );
}

export function TelecomOperatorSelect({ inputValues, setInputValues, size }) {
  const [smallPlaceholder, setSmallPlaceholder] = useState(inputValues.operadora || "Operadora");
  const [bigPlaceholder, setBigPlaceholder] = useState(inputValues.operadoraMastro || "Selecione ou digite a operadora");

  useEffect(() => {
    const defaultSmallPlaceholder = inputValues.operadora ? inputValues.operadora : "Operadora";
    const defaultBigPlaceholder = inputValues.operadoraMastro
      ? inputValues.operadoraMastro
      : "Selecione ou digite a operadora";

    setSmallPlaceholder(defaultSmallPlaceholder);
    setBigPlaceholder(defaultBigPlaceholder);
  }, [inputValues, inputValues.operadora]);

  const [operadorasMastroOpcoes] = useState([{ value: "OI", label: "OI" },
    { value: "TIM", label: "TIM" },
    { value: "VIVO", label: "VIVO" },
    { value: "CLARO", label: "CLARO" },
    { value: "COMPARTILHADO", label: "COMPARTILHADO" }]);
  const handleOperatorChange = (selectedOption) => {
    if(selectedOption && size !== "small") {
      if (!operadorasMastroOpcoes.find((el) => el.label === selectedOption.label)) {
        const newOption = { value: selectedOption.value, label: selectedOption.label };
        operadorasMastroOpcoes.push(newOption);
      }
      setInputValues({ ...inputValues, operadoraMastro: selectedOption.value });
    }
    else if(selectedOption && size === "small") {
      if (!operadorasMastroOpcoes.find((el) => el.label === selectedOption.label)) {
        const newOption = { value: selectedOption.value, label: selectedOption.label };
        operadorasMastroOpcoes.push(newOption);
      }
      setInputValues({ ...inputValues, operadora: selectedOption.value });
    }
    else if (size !== "small") setInputValues({ ...inputValues, operadoraMastro: "" });
    else setInputValues({ ...inputValues, operadora: "" });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      backgroundColor: "#D0CECE",
      height: "5vh",
      borderRadius: "8px",
      fontFamily: "Lexend Deca, sans-serif",
      fontSize: "16px",
      color: "#000"
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
      position: "absolute",
      right: "-10px",
      fontSize: "0.2em",
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
    }),
  };

  const customStylesSmall = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      backgroundColor: "#ffffff",    
      border: "1px solid #C8C8C8",
      height: "4.5vh",
      borderRadius: "8px",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      color: "#000",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      width: "100%"
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
      position: "absolute",
      right: "-10px",
      width: "0.1 em"
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    input: (provided) => ({
      ...provided,
      width: "100%",
      color: "#000",
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none"
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
  };
  const placeholder = size !== "small" ? bigPlaceholder : smallPlaceholder;
  return (
    <StyledSelect size={size}
      options={operadorasMastroOpcoes}
      styles={size !== "small" ? customStyles : customStylesSmall}
      value={ size === "small" ? inputValues.operadoraMastro : inputValues.operadora}
      onChange={handleOperatorChange}
      placeholder={placeholder}
      isClearable
      isSearchable
    />
  );
}

const StyledSelect = styled(CreatableSelect)`
  .react-select__control {
    width: 100%;
    background-color: #d0cece;
    height: 4vh;
    border-radius: 8px;
    /*font-family: "Lexend Deca", sans-serif*/
  font-family: 'Roboto', sans-serif;;
    font-size: 26px;
    color: #000;
  }

  .react-select__placeholder {
    color: #000;
  }

  .react-select__dropdown-indicator {
    color: #000;
  }

  .react-select__option {
    color: #000;
  }
`;

const MenuDoubleOptionEstaiPosition = styled.div `
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0.8vh 0;
  position: relative;

  h2 {
    font-weight: 300;
    font-size: 16.5px;
    color: #313131;
    margin-bottom: 0.8vh;
  }

  input,select {
    width: 100%;
    background-color: #D0CECE;
    height: 4.5vh;
    padding: 6px;
    border-radius: 8px;
    /*font-family: "Lexend Deca", sans-serif*/
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
    color: #000;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }

  >div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 6px;
  }
  >div>div{
    width: 49.2%;
  }
`;

export const ProjectNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;

  h2 {
    color: #666666;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  h1 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
  }
    span {
    transition: 0.0001s all;
    cursor: pointer;
    :hover {
      font-weight: 600 !important;
    }
  }
`;

export const RightGeometryPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 4vh);
  margin-top: 4vh;
`;
