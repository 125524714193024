import { createContext, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

const UserContext = createContext();
export default UserContext;

export function UserProvider({ children }) {
  const [userData, setUserData, deleteUserData] = useLocalStorage("userData", {});
  const [userInfo, setUserInfo] = useState({ });
  return <UserContext.Provider value={{ userData, setUserData, deleteUserData, userInfo, setUserInfo }}>{children}</UserContext.Provider>;
}
