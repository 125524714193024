import { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import styled from "styled-components";

export function DeflectionTable({ mastProps, mastSelected }) {
  const props = JSON.parse(mastSelected.props) || mastProps;
  const [deflectionValues, setDeflectionValues] = useState(props?.strapMessage[3] ? props?.strapMessage[3]?.deflections[props?.strapMessage[3]?.deflections?.length - 1] : []);

  const strapMessage = props.strapMessage || [];

  useEffect(() => {
    if(strapMessage?.length > 2 && strapMessage[3].deflections) {
      setDeflectionValues(strapMessage[3]?.deflections[props?.strapMessage[3]?.deflections?.length - 1]);
    }; 
  }, []);

  const data = [
    {
      numeroNo: deflectionValues?.numeroNoUm,
      cota: deflectionValues?.cotaNoUm,
      deslocamento: deflectionValues?.deslocamentoNoUm,
    },
    {
      numeroNo: deflectionValues?.numeroNoDois,
      cota: deflectionValues?.cotaNoDois,
      deslocamento: deflectionValues?.deslocamentoNoDois,
    },
  ]; 

  const columns = useMemo(
    () => [
      {
        Header: "Nº NÓ",
        accessor: "numeroNo",
      },
      {
        Header: "COTA (m)",
        accessor: "cota",
      },
      {
        Header: "DESLOCAMENTO (cm)",
        accessor: "deslocamento",
      },
    ],
    [deflectionValues]
  );

  const tableInstance = useTable({ columns, data });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <TableContainer>
      <TableTitle>Deslocamentos</TableTitle>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{
                border: "2.75px solid #F2F0F4",
                backgroundColor: headerGroupIndex === 0 ? "#DADADA" : "#fff",
                color: headerGroupIndex === 0 ? "#000" : "#565656",
                fontWeight: 500,
                fontSize: "14px"
              }}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    border: "2.75px solid #F2F0F4",
                    padding: "10px",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}
                style={{
                  backgroundColor: "#ffffff",
                  fontSize: "14px"
                }}
              >
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {deflectionValues ?
        <DeflectionValuesContainer emConformidade={deflectionValues?.conformidade}>
          <h1>Rotação no topo</h1>
          <h2>{deflectionValues?.rotacaoTopo?.split(": ")[1]}</h2>
          <h3>{deflectionValues?.conformidade}</h3>
        </DeflectionValuesContainer> : ""}
    </TableContainer>
  );
};

const TableContainer = styled.div`
  position: relative;
  width: 32%;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  border-radius: 8px;
`;

const Table = styled.table`
  width: 100%;
  height: 63%;
  border-collapse: collapse;
  position: relative;
  td {
    border: 2.75px solid #F2F0F4;
    color: #565656;
  }
`;

const DeflectionValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: #E7E7E7;
  width: 100%;
  font-size: 15px;
  h1 {
    font-weight: 500;
    margin-top: 10px;
    color: #313131;
  }
  h3 {
    color: ${(props) => (props.emConformidade === "EM CONFORMIDADE" ? "green" : "#DD3232")};
  }
  h1, h2, h3{
    margin-bottom: 10px;
    width: 100%;
  }
`;

const TableTitle = styled.div`
  width: 91.5%;
  height: 40px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 18px;
`;
