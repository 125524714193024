import styled from "styled-components";
import { CustomInput } from "../../common/custom-input";
import CustomRadioButton from "../../common/custom-radio-buttons";
import { boltsDiameter } from "../../../constants/tower-constants";
import colors from "../../../constants/color-constants";
import triangularSectionImg from "../../../assets/images/towerImages/tower-section-triagular.svg";
import squareSectionImg from "../../../assets/images/towerImages/tower-section-square.svg";
import base_anchor from "../../../assets/images/towerImages/rectangle_archors.svg";

export default function TowerAnchorsRightContainer({ inputValues, anchorTypes, handleInputChange }) {
  return (
    <Container>
      <AnchorTypesContainer>
        <h2>Tipo de Ancoragem</h2>
        <div>
          <SectionImg
            selected={inputValues.anchorType === anchorTypes[0]}
            onClick={() => handleInputChange({ target: { name: "anchorType", value: anchorTypes[0] } })}
          >
            <CustomRadioButton
              value={anchorTypes[0]}
              checked={inputValues.anchorType === anchorTypes[0]}
              onClick={() => handleInputChange({ target: { name: "anchorType", value: anchorTypes[0] } })}
            />
            <img src={squareSectionImg} width={"90px"} alt={anchorTypes[0]} />
          </SectionImg>
          <SectionImg
            selected={inputValues.anchorType === anchorTypes[1]}
            onClick={() => handleInputChange({ target: { name: "anchorType", value: anchorTypes[1] } })}
          >
            <CustomRadioButton
              value={anchorTypes[1]}
              checked={inputValues.anchorType === anchorTypes[1]}
              onClick={() => handleInputChange({ target: { name: "anchorType", value: anchorTypes[1] } })}
            />
            <img src={triangularSectionImg} width={"100px"} alt={anchorTypes[1]} />
          </SectionImg>
        </div>
      </AnchorTypesContainer>

      <AnchorImgContainer>
        <h2>Placa e Base e Chumbadores</h2>
        <PositionalContainer>
          <AnchorImg>
            <img src={base_anchor} alt={""} />
          </AnchorImg>

          <AnchorInputsContainer top="0px" left="115px">
            <CustomInput 
              placeholder="Largura 1"
              value={inputValues?.basePlateL1 ?? ""}
              onChange={(newValue) => handleInputChange({ target: { name: "basePlateL1", value: newValue } })}
              unit="mm" rawNumber={true} 
            />
          </AnchorInputsContainer>
          <AnchorInputsContainer top="100px" left="20px" orientation="vertical">
            <CustomInput 
              placeholder="Largura 2"
              value={inputValues?.basePlateL2 ?? ""}
              onChange={(newValue) => handleInputChange({ target: { name: "basePlateL2", value: newValue } })}
              unit="mm" rawNumber={true} 
            />
          </AnchorInputsContainer>
          <AnchorInputsContainer top="234px" left="115px">
            <CustomInput 
              placeholder="Distância 1"
              value={inputValues?.basePlateDistance1 ?? ""}
              onChange={(newValue) => handleInputChange({ target: { name: "basePlateDistance1", value: newValue } })}
              unit="mm" rawNumber={true} 
            />
          </AnchorInputsContainer>
          <AnchorInputsContainer top="100px" left="252px" orientation="vertical">
            <CustomInput 
              placeholder="Distância 2"
              value={inputValues?.basePlateDistance2 ?? ""}
              onChange={(newValue) => handleInputChange({ target: { name: "basePlateDistance2", value: newValue } })}
              unit="mm" rawNumber={true} 
            />
          </AnchorInputsContainer>

          <InputsContainer>
            <CustomInput
              placeholder="Quantidade"
              value={inputValues?.anchorBoltsNumber ?? ""}
              onChange={(newValue) => handleInputChange({ target: { name: "anchorBoltsNumber", value: newValue } })}
              unit="" rawNumber={true} 
            />
            <select 
              placeholder={"Diâmetro"}
              value={inputValues?.anchorBoltsDiameter ?? ""}
              onChange={(newValue) => handleInputChange({ target: { name: "anchorBoltsDiameter", value: newValue } })}>
              <option value={""} key={"anchorBoltsDiameter-empty"}>Diâmetro</option>
              {boltsDiameter.map((type) => (
                <option value={type} key={type}>{type}</option>
              ))}
            </select>
          </InputsContainer>
        </PositionalContainer>
      </AnchorImgContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;

  h2 {
    color: ${colors.kcTextColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }
`; 

const AnchorTypesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: ${colors.kcLightGray};
  border-radius: 6px;
  width: min-content;
  height: min-content;
  margin-bottom: 20px;

  h2 {
    margin-bottom: 16px;
  }

  >div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 20px;
  }
`;

const SectionImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 150px;
  min-height: 150px;
  padding: 15px;
  background-color: ${colors.kcWhiteColor};
  border-radius: 8px;
  border: ${(props) => props.selected ? `1px solid ${colors.kcOrange}` : "none"};
  box-sizing: border-box;
  cursor: pointer;

  >img {
    width: ${(props) => props.width};
  }
`;

const AnchorImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: min-content;
  height: 360px;
  padding: 20px;
  border-radius: 6px;
  background-color: ${colors.kcLightGray};

  h2 {
    margin-bottom: 20px;
  }
`;

const PositionalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;
`;

const AnchorImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  img {
    width: 180px;
    object-fit: contain;
    margin: 0 40px;
  }
`;

const AnchorInputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  background-color: ${colors.kcWhiteColor};

  input {
    position: absolute;
    padding: 3px;
    width: ${(props) => props.orientation === "vertical" ? "40px" : "80px"};
    height: ${(props) => props.orientation === "vertical" ? "80px" : "40px"};
    writing-mode: ${(props) => props.orientation === "vertical" ? "sideways-lr" : ""};
    border-radius: 8px;
    border: 1px solid ${colors.kcBorderGray};
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: ${colors.kcBlackColor};
    text-align: center;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 100%;
  gap: 12px;

  input, select {
    width: 100%;
    height: 40px;
    border-radius: 6px;
    border: 1px solid ${colors.kcBorderGray};
    padding: 5px 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: ${colors.kcBlackColor};
    background-color: ${colors.kcWhiteColor};
  }
`;
