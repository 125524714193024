import React from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { CustomInput } from "../../common/custom-input";
import CustomRadioButton from "../../common/custom-radio-buttons";
import TowerSilhouette from "../../common/tower-silhouette";
import trussImages from "../../../assets/images/towerImages/trussTypes/trusses-images";
import colors from "../../../constants/color-constants";

export default function TowerTrussesRightContainer({
  inputValues,
  segmentSelectedInMenu,
  handleInputChange,
  trussesTypes,
  trussesInfo,
  setTrussesImages,
}) {
  const currentSegment = inputValues[segmentSelectedInMenu - 1];

  useEffect(() => {
    if (segmentSelectedInMenu === "") return;
    if (currentSegment && currentSegment?.trussType) {
      renderMountDivisionTypes();
      if (currentSegment.mountDivisions) {
        renderBracingTypes();
      }
    }
    setTrussesImages();
  }, [segmentSelectedInMenu, inputValues]);

  function renderTrussesTypes() {
    return trussesTypes.map((value, index) => (
      <SectionImg
        key={index}
        selected={currentSegment?.trussType === value}
        onClick={() => handleInputChange("trussType", value)}
      >
        <CustomRadioButton
          value={value}
          disabled={segmentSelectedInMenu === ""}
          checked={currentSegment?.trussType === value}
          onClick={() => handleInputChange("trussType", value)}
        />
        <img
          src={trussImages[value][`${value.charAt(8)}1A`]}
          width={"105px"}
          alt={value}
        />
      </SectionImg>
    ));
  }

  function renderMountDivisionTypes() {
    if (!currentSegment?.trussType) return;
    const mountDivisionsOptions = Object.keys(trussesInfo[currentSegment.trussType]);
    return mountDivisionsOptions.map((value, index) => (
      <MountDivisionOption
        key={index}
        selected={Number(currentSegment?.mountDivisions) === Number(value)}
        onClick={() => handleInputChange("mountDivisions", Number(value))}
      >
        <CustomRadioButton
          value={value}
          label={`${value} ${value > 1 ? "Partes" : "Parte"}`}
          disabled={segmentSelectedInMenu===""}
          checked={Number(currentSegment?.mountDivisions) === Number(value)}
          onClick={() => handleInputChange("mountDivisions", Number(value))}
        />
      </MountDivisionOption>
    ));
  }

  function renderBracingTypes() {
    const currentSegment = inputValues[segmentSelectedInMenu - 1];
    if (!currentSegment?.trussType || !currentSegment?.mountDivisions) return;

    const bracingOptions = trussesInfo[currentSegment?.trussType][currentSegment?.mountDivisions]
      .map(type => type.bracingType);

    return bracingOptions.map((value, index) => (
      <SectionImg
        key={index}
        selected={currentSegment?.bracingType === value}
        onClick={() => handleInputChange("bracingType", value)}
      >
        <CustomRadioButton
          value={`Tipo ${value}`}
          disabled={segmentSelectedInMenu===""}
          checked={currentSegment?.bracingType === value}
          onClick={() => handleInputChange("bracingType", value)}
        />
        <img
          src={trussImages[currentSegment.trussType]
            [`${currentSegment.trussType.charAt(8)}${currentSegment.mountDivisions}${value}`]}
          width={"105px"}
          alt={value}
        />
      </SectionImg>
    ));
  }

  return(
    <Container>
      <OptionsContainer>
        <h2>Tipo de Treliça</h2>
        <div className={"internal-container"}>
          {segmentSelectedInMenu !== "" &&
            <>
              {renderTrussesTypes()}

              <InputsContainer>
                <h2>Altura do trecho</h2>
                <CustomInput
                  disabled={segmentSelectedInMenu===""}
                  name={"totalHeight"}
                  value={inputValues[segmentSelectedInMenu - 1]?.totalHeight || ""}
                  onChange={newValue => handleInputChange("totalHeight", newValue)}
                  isNumber={true}
                  unit="mm"
                />

                <h2>Horizontal Superior?</h2>
                <div>
                  <HorizontalSuperiorButton
                    disabled={segmentSelectedInMenu === ""}
                    selected={inputValues[segmentSelectedInMenu - 1]?.hasSuperiorHorizontal === "Sim"}
                    onClick={(inputValues[segmentSelectedInMenu - 2]?.trussType === "Treliça V"
                      || inputValues[segmentSelectedInMenu - 1]?.trussType === "Treliça K"
                      || (inputValues[segmentSelectedInMenu - 1]?.trussType === "Treliça H"
                        && inputValues[segmentSelectedInMenu - 1]?.bracingType === "B"))
                      ? () => {}
                      : () => handleInputChange("hasSuperiorHorizontal", "Sim")}
                  >
                    <CustomRadioButton
                      value={"Sim"}
                      disabled={segmentSelectedInMenu === ""}
                      checked={inputValues[segmentSelectedInMenu - 1]?.hasSuperiorHorizontal === "Sim"}
                      onClick={(inputValues[segmentSelectedInMenu - 2]?.trussType === "Treliça V"
                        || inputValues[segmentSelectedInMenu - 1]?.trussType === "Treliça K"
                        || (inputValues[segmentSelectedInMenu - 1]?.trussType === "Treliça H"
                          && inputValues[segmentSelectedInMenu - 1]?.bracingType === "B"))
                        ? () => {}
                        : () => handleInputChange("hasSuperiorHorizontal", "Sim")}
                    />
                  </HorizontalSuperiorButton>
                  <HorizontalSuperiorButton
                    disabled={segmentSelectedInMenu === ""}
                    selected={inputValues[segmentSelectedInMenu - 1]?.hasSuperiorHorizontal === "Não"}
                    onClick={(inputValues[segmentSelectedInMenu - 2]?.trussType === "Treliça V"
                      || inputValues[segmentSelectedInMenu - 1]?.trussType === "Treliça K"
                      || (inputValues[segmentSelectedInMenu - 1]?.trussType === "Treliça H"
                        && inputValues[segmentSelectedInMenu - 1]?.bracingType === "B"))
                      ? () => {}
                      : () => handleInputChange("hasSuperiorHorizontal", "Não")}
                  >
                    <CustomRadioButton
                      value={"Não"}
                      disabled={segmentSelectedInMenu === ""}
                      checked={inputValues[segmentSelectedInMenu - 1]?.hasSuperiorHorizontal === "Não"}
                      onClick={(inputValues[segmentSelectedInMenu - 2]?.trussType === "Treliça V"
                        || inputValues[segmentSelectedInMenu - 1]?.trussType === "Treliça K"
                        || (inputValues[segmentSelectedInMenu - 1]?.trussType === "Treliça H"
                          && inputValues[segmentSelectedInMenu - 1]?.bracingType === "B"))
                        ? () => {}
                        : () => handleInputChange("hasSuperiorHorizontal", "Não")}
                    />
                  </HorizontalSuperiorButton>
                </div>
                {inputValues[segmentSelectedInMenu - 1]?.trussType === "Treliça K" &&
                  <p>Trechos com Treliça K possuem Horizontal Superior.</p>}
                {(inputValues[segmentSelectedInMenu - 2]?.trussType === "Treliça V"
                    && inputValues[segmentSelectedInMenu - 1]?.trussType !== "Treliça K") &&
                  <p>Este trecho deve possuir Horizontal Superior pois o trecho acima possui Treliça V.</p>}
                {(inputValues[segmentSelectedInMenu - 1]?.trussType === "Treliça H"
                    && inputValues[segmentSelectedInMenu - 1]?.bracingType === "B") &&
                  <p>Trechos com Treliça H e tipo de contraventamento B possuem Horizontal Superior.</p>}
              </InputsContainer>
            </>
          }
        </div>
      </OptionsContainer>

      <MountDivisionsContainer>
        <h2>Divisões do Montante</h2>
        <div className={"internal-container"}>
          {segmentSelectedInMenu !== "" && renderMountDivisionTypes()}
        </div>
      </MountDivisionsContainer>

      <OptionsContainer>
        <h2>Tipo de Contraventamento</h2>
        <div className={"internal-container"}>
          {segmentSelectedInMenu !== "" && renderBracingTypes()}
        </div>
      </OptionsContainer>

      <OptionsContainer>
        <h2>Silhueta</h2>
        <TowerSilhouette images={setTrussesImages()} />
      </OptionsContainer>
    </Container>
  );
}

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
`;

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    background-color: ${colors.kcLightGray};
    border-radius: 6px;
    max-height: calc(100vh - 245px);
    gap: 20px;
    box-sizing: border-box;
    position: relative;

    h2 {
        color: ${colors.kcTextColor};
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        font-weight: 500;
    }

    .internal-container {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        width: 350px;
        gap: 20px;
    }
`;

const MountDivisionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    min-width: 180px;
    padding: 20px;
    background-color: ${colors.kcLightGray};
    border-radius: 6px;
    height: min-content;
    box-sizing: border-box;
    gap: 20px;

    h2 {
        font-weight: 500;
        font-size: 14px;
        color: ${colors.kcTextColor};
        font-family: 'Roboto', sans-serif;
    }

    >div {
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        gap: 10px;
    }
`;

const MountDivisionOption = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    height: 45px;
    padding: 15px;
    background-color: ${colors.kcWhiteColor};
    border-radius: 8px;
    border: ${(props) => props.selected ? `2px solid ${colors.kcOrange}` : "none"};
    box-sizing: border-box;
`;

const SectionImg = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 165px;
    height: 165px;
    padding: 15px;
    background-color: ${colors.kcWhiteColor};
    border-radius: 8px;
    border: ${(props) => props.selected ? `2px solid ${colors.kcOrange}` : "none"};
    box-sizing: border-box;

    img {
        width: ${(props) => props.width};
    }
`;

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    width: 165px;
    min-height: 165px;
    border: ${(props) => props.selected ? `2px solid ${colors.kcOrange}` : "none"};
    box-sizing: border-box;

    >div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 10px;
    }

    h2 {
        font-size: 14px;
        font-weight: 500;
        color: ${colors.kcTextColor};
        margin-bottom: 6px;
        font-family: 'Roboto', sans-serif;
    }

    input {
        width: 100%;
        border: 1px solid ${colors.kcBorderGray};
        background-color: ${colors.kcWhiteColor};
        height: 40px;
        padding: 6px;
        border-radius: 8px;
        font-size: 12px;
        text-align: center;
        margin-bottom: 15px;
        font-family: 'Roboto', sans-serif;
    }

    p {
        font-size: 12px;
        font-weight: 400;
        color: ${colors.kcTextColor};
        line-height: 15px;
        margin-top: 6px;
        font-family: 'Roboto', sans-serif;
    }
`;

const HorizontalSuperiorButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 2px 10px;
    background-color: ${colors.kcWhiteColor};
    border-radius: 8px;
    border: ${(props) => !props.selected || props.disabled ? `1px solid ${colors.kcBorderGray}` : `2px solid ${colors.kcOrange}`};
    width: 100%;
    height: 40px;
`;
