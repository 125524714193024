import MA from "../../../assets/images/mastGeometryImages/MA";
import MB from "../../../assets/images/mastGeometryImages/MB";
import MC from "../../../assets/images/mastGeometryImages/MC";
import MD from "../../../assets/images/mastGeometryImages/MD";
import ME from "../../../assets/images/mastGeometryImages/ME";
import MF from "../../../assets/images/mastGeometryImages/MF";
import MG from "../../../assets/images/mastGeometryImages/MG";
import MH from "../../../assets/images/mastGeometryImages/MH";
import MI from "../../../assets/images/mastGeometryImages/MI";

export default function MastGeometryImg({ type, imgId, modulesNum }) {
  if (!imgId) {
    return (<>
      {type === "MA" && <img src={MA[`MA_${modulesNum}`]} alt={""} loading="lazy"/>}
      {type === "MB" && <img src={MB[`MB_1_${modulesNum}`]} alt={""} loading="lazy"/>}
      {type === "MC" && <img src={MC[`MC_${modulesNum}_1_1`]} alt={""} loading="lazy"/>}
      {type === "MD" && <img src={MD[`MD_1_${modulesNum}`]} alt={""} loading="lazy"/>}
      {type === "ME" && <img src={ME[`ME_1_${modulesNum}`]} alt={""} loading="lazy"/>}
      {type === "MF" && <img src={MF[`MF_1_${modulesNum}`]} alt={""} loading="lazy"/>}
      {type === "MG" && <img src={MG[`MG_1_${modulesNum}`]} alt={""} loading="lazy"/>}
      {type === "MH" && <img src={MH[`MH_1_${modulesNum}`]} alt={""} loading="lazy"/>}
      {type === "MI" && <img src={MI[`MI_1_1_${modulesNum}`]} alt={""} loading="lazy"/>}
    </>
    );
  }
  return (<>
    {type === "MA" && <img src={MA[imgId]} alt={""} loading="lazy"/>}
    {type === "MB" && <img src={MB[imgId]} alt={""} loading="lazy"/>}
    {type === "MC" && <img src={MC[imgId]} alt={""} loading="lazy"/>}
    {type === "MD" && <img src={MD[imgId]} alt={""} loading="lazy"/>}
    {type === "ME" && <img src={ME[imgId]} alt={""} loading="lazy"/>}
    {type === "MF" && <img src={MF[imgId]} alt={""} loading="lazy"/>}
    {type === "MG" && <img src={MG[imgId]} alt={""} loading="lazy"/>}
    {type === "MH" && <img src={MH[imgId]} alt={""} loading="lazy"/>}
    {type === "MI" && <img src={MI[imgId]} alt={""} loading="lazy"/>}
  </>
  );
}
