import api from "../apiTower";

const makeHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getSegments = async(towerId, token) => {
  const response = await api
    .get(`/tower/segments/ids/${encodeURIComponent(towerId)}`, makeHeaders(token));
  return response.data;
};
