import useAsync from "../../useAsync";
import { getAnchors, postAnchors, updateAnchors } from "../../../services/tower-services/towerAnchorsApi";

export const useTowerAnchors = (action) => {
  const execute = {
    get: getAnchors,
    post: postAnchors,
    update: updateAnchors,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
