import { useState } from "react";
import styled from "styled-components";

export const CustomInput = ({ 
  value,
  name,
  onChange, 
  onClick,
  label,
  placeholder = "",
  toFixed = 2,
  unit = "",
  rawNumber = false,
  disabled = false,
  readOnly = false,
  isNumber = false,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const getDisplayValue = () => {
    if (disabled) return "";
    if (isEditing) return value;
    if (value === "" || value === null) return "";

    if (isNumber) {
      let parsedValue = parseFloat(value);
      if (isNaN(parsedValue)) return "";
      if (rawNumber) return `${value} ${unit}`;
      return `${parsedValue.toFixed(toFixed)} ${unit}`;
    }

    return `${value} ${unit}`;
  };

  const handleChange = (e) => {
    let inputValue = e.target.value;

    if (isNumber) {
      const numericValue = inputValue.replace(/[^0-9.]/g, "");

      const dotCount = (numericValue.match(/\./g) || []).length;
      if (dotCount > 1) return;

      onChange(numericValue === "" ? "" : numericValue);
    } else {
      onChange(inputValue);
    }
  };

  const handleFocus = () => setIsEditing(true);

  const handleBlur = (e) => {
    if (isNumber) {
      const inputValue = e.target.value.trim();
      const isLastCharDot = e.target.value.endsWith(".");

      if (isLastCharDot) {
        const newValue = inputValue.slice(0, -1);
        onChange(newValue === "" ? "" : parseFloat(newValue));
      } else {
        const parsedValue = parseFloat(inputValue);
        onChange(isNaN(parsedValue) ? "" : parsedValue);
      }
    }
    setIsEditing(false);
  };

  return (
    <div>
      {label && <StyledLabel>{label}</StyledLabel>}
      <input
        readOnly={readOnly}
        type="text"
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        value={getDisplayValue()}
        onChange={handleChange}
        onClick={onClick}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    </div>
  );
};

const StyledLabel = styled.label`
    color: #313131;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;
