import useAsync from "../useAsync";

import * as authApi from "../../services/userApi";

export default function useUpdatePassword() {
  const {
    data: hashedUpdatedPassword,
    loading: updatePasswordLoading,
    error: updatePasswordError,
    act: updatePassword,
  } = useAsync(
    ({ email, password, confirmPassword, validationNumber }) =>
      authApi.updatePassword({
        email,
        password,
        confirmPassword,
        validationNumber,
      }),
    false
  );

  return {
    hashedUpdatedPassword,
    updatePasswordLoading,
    updatePasswordError,
    updatePassword,
  };
}
