import { useContext, useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import UserContext from "../../../contexts/UserContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { TowerContext } from "../../../contexts/TowerContext";
import { useTowerGeometry } from "../../../hooks/api/tower-api/useTowerGeometry";
import useUnsavedChangesAlert from "../../../hooks/useUnsavedChangesAlert";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import TowerGeometryLeftMenu from "./tower-geometryLeftMenu";
import TowerGeometryRightContainer from "./tower-geometryRightContainer";
import GeometryModel from "../../../models/GeometryModel";

export default function TowerGeometryPage() {
  const { userData } = useContext(UserContext);
  const { towerId, geometrySelected, setGeometrySelected } = useContext(TowerContext);
  const { reloadProject, setIsUpdatedWithoutCalculation, isLoading, setIsLoading } = useContext(ProjectContext);

  const { act: fetchGeometry } = useTowerGeometry("get");
  const { act: saveGeometry } = useTowerGeometry("post");

  const sectionTypes = ["Quadrada", "Triangular"];

  const [inputValues, setInputValues] = useState(new GeometryModel());

  useEffect(() => {
    if (towerId && userData.token) {
      loadGeometryData().then(response => {
        if (response) {
          setGeometrySelected(GeometryModel.fromJSON(response));
          setInputValues(GeometryModel.fromJSON(response));
        }
      });
    }
  }, [towerId && userData.token]);

  const loadGeometryData = useCallback(async() => {
    setIsLoading(true);
    try {
      return await fetchGeometry(towerId, userData.token);
    } catch (error) {
      toast.error("Erro ao buscar dados de geometria.");
    } finally {
      setIsLoading(false);
    }
  }, [towerId, userData.token]);

  const fieldsToCheck = ["towerSection", "totalHeight", "topWidth", "baseWidth", "nSegments", "elevation"];
  const isInputModified = useUnsavedChangesAlert(inputValues, geometrySelected, reloadProject, fieldsToCheck);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setInputValues((prevData) => {
      const updatedModel = new GeometryModel();
      updatedModel.assignData(prevData);
      updatedModel.updateField(name, value);
      return updatedModel;
    });
  }, [inputValues]);

  async function updateGeometryData() {
    if (!inputValues.validate()) return false;

    setIsLoading(true);
    try {
      const updatedData = await saveGeometry(inputValues.toJSON(), towerId, userData.token);
      setGeometrySelected(GeometryModel.fromJSON(updatedData));
      setInputValues(GeometryModel.fromJSON(updatedData));
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações da geometria atualizadas com sucesso");
      return true;
    } catch (error) {
      toast.error("Erro ao salvar dados de geometria.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Geometria"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updateGeometryData}
      >
        <TowerGeometryLeftMenu
          sectionTypes={sectionTypes}
          inputValues={inputValues}
          handleInputChange={handleInputChange}
        />
      </LeftMenu>
      <RightContainer>
        <TowerGeometryRightContainer
          sectionTypes={sectionTypes}
          inputValues={inputValues}
          handleInputChange={handleInputChange}
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
