import styled from "styled-components";
import { ButtonPositioner, ReturnButton, LeftTitle, ReturnIcon, AdvanceIcon } from "../../project-components/wind-components/project-windData";
import SavingButton from "../../common/saving-button";
import LoadingButton from "../../common/loading-button";
import { useState, useContext, useEffect } from "react";
import UserContext from "../../../contexts/UserContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { MenuDoubleOption, MenuOption } from "../module-components/mast-moduleData";
import { MenuTripleOption } from "../estai-components/mast-estaiData";
import ResizableLeftContainer from "../../common/resizableLeftContainer";
import ResizableRightContainer from "../../common/resizableRightContainer";
import CreatableSelect from "react-select/creatable";
import useUnsavedChangesAlert from "../../../hooks/useUnsavedChangesAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import bloco_estai from "./bloco_estai.jpg";
import bloco_mastro from "./bloco_mastro.jpg";
import useUpdateMast from "../../../hooks/api/useUpdateMast";

export default function MastAnchoragePage() {
  const { userData } = useContext(UserContext);
  const { setMastSelected, setLeftMenuOptionSelected, mastSelected, projectSelected, reloadProject, setReloadProject, setIsUpdatedWithoutCalculation, setProjectNameSelected, setProjectSelected } = useContext(ProjectContext);
  const projectProps = JSON.parse(projectSelected.props);
  const mastProps = JSON.parse(mastSelected.props);
  const { updateMast, updateMastLoading } = useUpdateMast();
  const [estaiSelectedInMenu, setEstaiSelectedInMenu] = useState(mastProps.tipoMastro === "MI" ? "1" : "Central");
  const estaisByModules = { MA: 0, MB: 2, MC: 4, MD: 2, ME: 2, MF: 2, MG: 2, MH: 1, MI: 2 };
  const [existingBlock, setExistingBlock] = useState(true);
  const [anchorageSelectedBackgroundColor, setAnchorageSelectedBackgroundColor] = useState("#F16E00");
  const [inputValues, setInputValues] = useState(mastProps.tipoMastro === "MI" ? {
    estaiSelected: estaiSelectedInMenu || "Central",
    ancoragemEstaiCentralTemBloco: mastProps?.ancoragemEstaiCentralTemBloco || true,
    ancoragemEstaiCentralL1: mastProps?.ancoragemEstaiCentralL1 || "",
    ancoragemEstaiCentralL2: mastProps?.ancoragemEstaiCentralL2 || "",
    ancoragemEstaiCentralH: mastProps?.ancoragemEstaiCentralH || "",
    ancoragemEstaiCentralConcreto: mastProps?.ancoragemEstaiCentralConcreto || "", 
    ancoragemEstaiCentralChumbadoresQtd: mastProps?.ancoragemEstaiCentralChumbadoresQtd || "",
    ancoragemEstaiCentralChumbadoresDiametro: mastProps?.ancoragemEstaiCentralChumbadoresDiametro || "",
    ancoragemEstaiCentralChumbadoresAncoragem: mastProps?.ancoragemEstaiCentralChumbadoresAncoragem || "",
    ancoragemEstaiCentralChumbadoresAco: mastProps?.ancoragemEstaiCentralChumbadoresAco || "",
    ancoragemEstaiCentralDistanciaFuros: mastProps?.ancoragemEstaiCentralDistanciaFuros || "",
    ancoragemEstaiCentralFerrosQtd: mastProps?.ancoragemEstaiCentralFerrosQtd || "",
    ancoragemEstaiCentralFerrosDiametro: mastProps?.ancoragemEstaiCentralFerrosDiametro || "",
    ancoragemEstaiCentralFerrosAncoragem: mastProps?.ancoragemEstaiCentralFerrosAncoragem || "",
  } : { estaiSelected: "1" });
  // eslint-disable-next-line quotes
  const parafusosAncoragem = ['3/8" - 9.5mm', '1/2" - 12.7mm', '5/8" - 15.9mm', '3/4" - 19.05mm', '7/8" - 22.2mm', '1" - 25.4mm', '1 1/4" - 31.8mm', '1 5/16" - 33.3mm', '1 3/8" - 34.9mm', '1 1/2" - 38.1mm', '1 5/8" - 41.3mm', '1 3/4" - 44.45mm', '1 7/8" - 47.6mm', '2" - 50.8mm', '2 1/4" - 57.2mm', '2 5/16" - 58.7mm', '2 3/8" - 60.3mm', '2 1/2" - 63.5mm', '2 5/8" - 66.7mm', '2 3/4" - 69.85mm', '2 7/8" - 73mm'];
  const tiposDeAçoChumbadores = [
    "ASTM A36",
    "SAE 1008",
    "SAE 1010",
    "SAE 1020",
    "SAE 1045 Laminado",
    "SAE 1045 Trefilado",
    "ASTM A307",
    "ASTM A394",
    "ASTM A325",
    "ASTM A490",
    "DIN 8.8",
    "DYWIDAG (ST-85/105)",
  ];
  const tiposDeConcretoFck = ["C20", "C25", "C30", "C35", "C40", "C45", "C50"];
  async function updateMastEstaiData() {
    try {
      let whatIsMissingMessage = "";
      if (!inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresQtd`]) whatIsMissingMessage += "Quantidade de chumbadores; ";
      if (!inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresDiametro`]) whatIsMissingMessage += "Diâmetro dos chumbadores; ";
      if (!inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAncoragem`]) whatIsMissingMessage += "Ancoragem dos chumbadores; ";
      if (!inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAco`]) whatIsMissingMessage += "Aço dos chumbadores; ";
      if (!inputValues[`ancoragemEstai${inputValues.estaiSelected}DistanciaFuros`] && mastProps.tipoMastro === "MA") whatIsMissingMessage += "Distância dos furos; ";
      if (!inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosQtd`] && existingBlock) whatIsMissingMessage += "Quantidade de ferros; ";
      if (!inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosAncoragem`] && existingBlock) whatIsMissingMessage += "Ancoragem dos ferros; ";
      if (!inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosDiametro`] && existingBlock) whatIsMissingMessage += "Diâmetro dos ferros; ";
      if (!inputValues[`ancoragemEstai${inputValues.estaiSelected}Concreto`] && existingBlock) whatIsMissingMessage += "Concreto (fck); ";
      if (!inputValues[`ancoragemEstai${inputValues.estaiSelected}L1`] && existingBlock) whatIsMissingMessage += "Dimensão L1; ";
      if (!inputValues[`ancoragemEstai${inputValues.estaiSelected}L2`] && existingBlock) whatIsMissingMessage += "Dimensão L2; ";
      if (!inputValues[`ancoragemEstai${inputValues.estaiSelected}H`] && existingBlock) whatIsMissingMessage += "Dimensão H; ";
      if (whatIsMissingMessage) return toast.error(`Preencha todos os valores para salvar o estai. Falta(m): ${whatIsMissingMessage}`);
      if (existingBlock && Number(inputValues[`ancoragemEstai${inputValues.estaiSelected}H`]) < Number(inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAncoragem`])) {
        return toast.error("A Ancoragem não pode ser maior que a altura do bloco!");
      }
      if (!inputValues[`ancoragemEstai${inputValues.estaiSelected}TemBloco`] && inputValues[`ancoragemEstai${inputValues.estaiSelected}TemBloco`] !== "0") {
        inputValues[`ancoragemEstai${inputValues.estaiSelected}TemBloco`] = "0";
      };
      const updatedProps = JSON.stringify({ ...mastProps,
        ...(inputValues[`ancoragemEstai${inputValues.estaiSelected}TemBloco`] !== "" && {
          [`ancoragemEstai${inputValues.estaiSelected}TemBloco`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}TemBloco`]
        }),
        ...(inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresQtd`] !== "" && {
          [`ancoragemEstai${inputValues.estaiSelected}ChumbadoresQtd`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresQtd`]
        }),
        ...(inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresDiametro`] !== "" && {
          [`ancoragemEstai${inputValues.estaiSelected}ChumbadoresDiametro`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresDiametro`]
        }),
        ...(inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAncoragem`] !== "" && {
          [`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAncoragem`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAncoragem`]
        }),
        ...(inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAco`] !== "" && {
          [`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAco`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAco`]
        }),
        ...(inputValues[`ancoragemEstai${inputValues.estaiSelected}DistanciaFuros`] !== "" && {
          [`ancoragemEstai${inputValues.estaiSelected}DistanciaFuros`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}DistanciaFuros`]
        }),
        ...(inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosQtd`] !== "" && {
          [`ancoragemEstai${inputValues.estaiSelected}FerrosQtd`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosQtd`]
        }),
        ...(inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosAncoragem`] !== "" && {
          [`ancoragemEstai${inputValues.estaiSelected}FerrosAncoragem`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosAncoragem`]
        }),
        ...(inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosDiametro`] !== "" && {
          [`ancoragemEstai${inputValues.estaiSelected}FerrosDiametro`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosDiametro`]
        }),
        ...(inputValues[`ancoragemEstai${inputValues.estaiSelected}Concreto`] !== "" && {
          [`ancoragemEstai${inputValues.estaiSelected}Concreto`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}Concreto`]
        }),
        ...(inputValues[`ancoragemEstai${inputValues.estaiSelected}L1`] !== "" && {
          [`ancoragemEstai${inputValues.estaiSelected}L1`]: Number(inputValues[`ancoragemEstai${inputValues.estaiSelected}L1`])
        }),
        ...(inputValues[`ancoragemEstai${inputValues.estaiSelected}L2`] !== "" && {
          [`ancoragemEstai${inputValues.estaiSelected}L2`]: Number(inputValues[`ancoragemEstai${inputValues.estaiSelected}L2`])
        }),
        ...(inputValues[`ancoragemEstai${inputValues.estaiSelected}H`] !== "" && {
          [`ancoragemEstai${inputValues.estaiSelected}H`]: Number(inputValues[`ancoragemEstai${inputValues.estaiSelected}H`])
        }),
        ...(inputValues.estaiSelected &&
          isAnchorageEqualTo.reduce((acc, item) => {
            if (item[inputValues.estaiSelected]) {
              const anchorPrefix = `ancoragemEstai${item[inputValues.estaiSelected]}`;
              return {
                ...acc,
                [`${anchorPrefix}TemBloco`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}TemBloco`],
                [`${anchorPrefix}ChumbadoresQtd`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresQtd`],
                [`${anchorPrefix}ChumbadoresDiametro`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresDiametro`],
                [`${anchorPrefix}ChumbadoresAncoragem`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAncoragem`],
                [`${anchorPrefix}ChumbadoresAco`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAco`],
                [`${anchorPrefix}DistanciaFuros`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}DistanciaFuros`],
                [`${anchorPrefix}FerrosQtd`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosQtd`],
                [`${anchorPrefix}FerrosAncoragem`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosAncoragem`],
                [`${anchorPrefix}FerrosDiametro`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosDiametro`],
                [`${anchorPrefix}Concreto`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}Concreto`],
                [`${anchorPrefix}L1`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}L1`],
                [`${anchorPrefix}L2`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}L2`],
                [`${anchorPrefix}H`]: inputValues[`ancoragemEstai${inputValues.estaiSelected}H`],
                [`${anchorPrefix}CopiaDe`]: `ancoragemEstai${inputValues.estaiSelected}`,
              };
            }
            return acc;
          }, {})
        ),
      });
      const toBeUpdatedMast = { ...mastSelected, updatedAt: new Date(), props: updatedProps };
      const updatedMast = await updateMast(toBeUpdatedMast, userData.token);
      setMastSelected(updatedMast);
      setReloadProject(!reloadProject);
      if (Number(estaiSelectedInMenu) !== estaisByModules[mastProps.tipoMastro] && mastProps.tipoMastro !== "MA") {
        setEstaiSelectedInMenu(estaiSelectedInMenu === "Central" ? 1 : estaiSelectedInMenu + 1);
      }
      setAnchorageSelectedBackgroundColor("#D0CECE");
      setTimeout(() => setAnchorageSelectedBackgroundColor("#F16E00"), 750);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações atualizadas com sucesso");
    } catch (error) {
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
      if (error?.message) return toast.error(error.message);
    }
  }

  useEffect(() => {
    setInputValues( {
      estaiSelected: estaiSelectedInMenu || 1,
      [`ancoragemEstai${estaiSelectedInMenu}TemBloco`]: mastProps?.[`ancoragemEstai${estaiSelectedInMenu}TemBloco`] || true,
      [`ancoragemEstai${estaiSelectedInMenu}L1`]: mastProps?.[`ancoragemEstai${estaiSelectedInMenu}L1`] || "",
      [`ancoragemEstai${estaiSelectedInMenu}L2`]: mastProps?.[`ancoragemEstai${estaiSelectedInMenu}L2`] || "",
      [`ancoragemEstai${estaiSelectedInMenu}H`]: mastProps?.[`ancoragemEstai${estaiSelectedInMenu}H`] || "",
      [`ancoragemEstai${estaiSelectedInMenu}ChumbadoresQtd`]: mastProps?.[`ancoragemEstai${estaiSelectedInMenu}ChumbadoresQtd`] || "",
      [`ancoragemEstai${estaiSelectedInMenu}ChumbadoresDiametro`]: mastProps?.[`ancoragemEstai${estaiSelectedInMenu}ChumbadoresDiametro`] || "",
      [`ancoragemEstai${estaiSelectedInMenu}ChumbadoresAncoragem`]: mastProps?.[`ancoragemEstai${estaiSelectedInMenu}ChumbadoresAncoragem`] || "",
      [`ancoragemEstai${estaiSelectedInMenu}Concreto`]: mastProps?.[`ancoragemEstai${estaiSelectedInMenu}Concreto`] || mastProps?.ancoragemEstaiCentralConcreto || "",
      [`ancoragemEstai${estaiSelectedInMenu}ChumbadoresAco`]: mastProps?.[`ancoragemEstai${estaiSelectedInMenu}ChumbadoresAco`] || "",
      [`ancoragemEstai${estaiSelectedInMenu}DistanciaFuros`]: mastProps?.[`ancoragemEstai${estaiSelectedInMenu}DistanciaFuros`] || "",
      [`ancoragemEstai${estaiSelectedInMenu}FerrosQtd`]: mastProps?.[`ancoragemEstai${estaiSelectedInMenu}FerrosQtd`] || "",
      [`ancoragemEstai${estaiSelectedInMenu}FerrosDiametro`]: mastProps?.[`ancoragemEstai${estaiSelectedInMenu}FerrosDiametro`] || "",
      [`ancoragemEstai${estaiSelectedInMenu}FerrosAncoragem`]: mastProps?.[`ancoragemEstai${estaiSelectedInMenu}FerrosAncoragem`] || "",
    } );
    setExistingBlock(mastProps?.[`ancoragemEstai${estaiSelectedInMenu}TemBloco`] || true);
  }, [inputValues.estaiSelected, estaiSelectedInMenu]);

  const fieldsToCheck = [[`ancoragemEstai${estaiSelectedInMenu}L1`], [`ancoragemEstai${estaiSelectedInMenu}L2`], [`ancoragemEstai${estaiSelectedInMenu}Espessura`], [`ancoragemEstai${estaiSelectedInMenu}H`], [`ancoragemEstai${estaiSelectedInMenu}ChumbadoresQtd`], [`eancoragemEstaistai${estaiSelectedInMenu}ChumbadoresDiametro`], [`ancoragemEstai${estaiSelectedInMenu}ChumbadoresAncoragem`], [`ancoragemEstai${estaiSelectedInMenu}ChumbadoresAco`], [`ancoragemEstai${estaiSelectedInMenu}Concreto`], [`ancoragemEstai${estaiSelectedInMenu}DistanciaFuros`], [`ancoragemEstai${estaiSelectedInMenu}FerrosQtd`], [`estai${estaiSelectedInMenu}FerrosDiametro`], [`ancoragemEstai${estaiSelectedInMenu}FerrosAncoragem`], [`ancoragemEstai${estaiSelectedInMenu}TemBloco`]];
  const isInputModified = useUnsavedChangesAlert(inputValues, mastProps, reloadProject, fieldsToCheck);
  const [possibleEqualAnchorageList, setPossibleEqualAnchorageList] = useState([]);
  useEffect(() => {
    const newList = [];
    let maxEstais = 2;
    if (mastProps.tipoMastro === "MA") maxEstais = 0;
    else if (mastProps.tipoMastro === "MC") maxEstais = 4;
    else if (mastProps.tipoMastro === "MH") maxEstais = 1;
    if (Number(estaiSelectedInMenu) === estaisByModules[mastProps.tipoMastro]) return setPossibleEqualAnchorageList(newList);
    for (let i = 1; i <= maxEstais; i++) {
      if (i !== Number(estaiSelectedInMenu) && (i > Number(estaiSelectedInMenu) || estaiSelectedInMenu === "Central")) newList.push(i);
    }
    setPossibleEqualAnchorageList(newList);
  }, [estaiSelectedInMenu]);

  function handleMenuClick(menuType, menuName) {
    if (menuType === "project") {
      setProjectSelected({ });
      setProjectNameSelected("");
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else if (menuType === "projectName") {
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else {
      setLeftMenuOptionSelected("");
    }
  }
  const [isAnchorageEqualTo, setIsAnchorageEqualTo] = useState([]);
  return (
    <>
      <ResizableLeftContainer>
        <LeftTitle>Chumbadores</LeftTitle>
        <ReturnIcon onClick={() => {
          if (isInputModified) {
            if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("estais");
          }
          else setLeftMenuOptionSelected("estais");
        }}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ReturnIcon>
        <AdvanceIcon disabled={isInputModified} onClick={() => {
          if(!projectProps.s1 || !projectProps.s2 || !projectProps.s3 || !projectProps.v0) return toast.error("É necessário definir os valores de vento para adicionar antenas");
          if (isInputModified) {
            if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja avançar?")) setLeftMenuOptionSelected("antenas");
          }
          else setLeftMenuOptionSelected("antenas");
        }}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </AdvanceIcon>
        <AnchorageMenuOption backgroundColor={anchorageSelectedBackgroundColor}>
          <h2>Selecione a Ancoragem</h2>
          <select value={estaiSelectedInMenu} onChange={(e) => {
            setEstaiSelectedInMenu(e.target.value);
            setInputValues({ ...inputValues, estaiSelected: e.target.value });
          }}>
            { mastProps.tipoMastro !== "MI" ? <option value={"Central"}>Ancoragem Central</option>: ""}
            {[...Array(estaisByModules[mastProps.tipoMastro]).keys()].map((i) => (
              <option value={i + 1} key={i + 1}>
                Ancoragem Estai {i + 1}
              </option>
            ))}
          </select>
        </AnchorageMenuOption>
        <MenuTripleOptionWithCreatableSelect>
          <h2>Chumbadores</h2>
          <div>
            <div>
              <h3>Quantidade</h3>
              <input value={inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresQtd`]} className="nonCreatableInput" type="number" placeholder={inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresQtd`] ? inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresQtd`] : "Quantidade"} onChange={(e) => setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}ChumbadoresQtd`]: e.target.value })} ></input>
            </div>
            <div>
              <h3>Diâmetro ø (mm)</h3>
              <ScrewDiameterSelect inputValues={inputValues} setInputValues={setInputValues} diametersList={parafusosAncoragem} screwType={"Chumbadores"} />
            </div>
            <div>
              <>
                <h3>Ancoragem (cm)</h3>
                <input value={inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAncoragem`]} className="nonCreatableInput" type="number" placeholder={inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAncoragem`] ? inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAncoragem`] : "(cm)"} onChange={(e) => setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAncoragem`]: e.target.value })}></input>
              </>
            </div>
          </div>
        </MenuTripleOptionWithCreatableSelect>
        {mastProps.tipoMastro === "MA" && <MenuDoubleOption>
          <h2>Distância entre furos (mm)</h2>
          <div>
            <div>
              <input value={inputValues[`ancoragemEstai${inputValues.estaiSelected}DistanciaFuros`]} type="number" placeholder={inputValues[`ancoragemEstai${inputValues.estaiSelected}DistanciaFuros`] ? [`ancoragemEstai${inputValues.estaiSelected}DistanciaFuros`] : "Distância em mm"} onChange={(e) => setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}DistanciaFuros`]: e.target.value })}></input>
            </div>
          </div>
        </MenuDoubleOption>}
        <MenuOption>
          <h2>Aço Chumbadores</h2>
          <select onChange={(e) => setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAco`]: e.target.value })}>
            {inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAco`] ? <option value={inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAco`]} >{inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAco`]}</option> :             <option value={""} >Selecionar</option>}
            {tiposDeAçoChumbadores.map((tipo) => (inputValues[`ancoragemEstai${inputValues.estaiSelected}ChumbadoresAco`] !== tipo && <option value={tipo} key={tipo}>{tipo}</option>))}
          </select>
        </MenuOption>
        <MenuDoubleOption>
          <div>
            <div>
              <h2>Possui bloco?</h2>
              <select onChange={(e) => {
                setExistingBlock(e.target.value);
                setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}TemBloco`]: e.target.value });
              }} value={existingBlock}>
                <option value={true}>Sim</option>
                <option value={"0"}>Não</option>
              </select>
            </div>
            <div>
              {existingBlock && existingBlock !== "0" && <><h2>Concreto (fck)</h2>
                <select onChange={(e) => setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}Concreto`]: e.target.value })}>
                  {inputValues[`ancoragemEstai${inputValues.estaiSelected}Concreto`] ? <option value={inputValues[`ancoragemEstai${inputValues.estaiSelected}Concreto`]} >C{inputValues[`ancoragemEstai${inputValues.estaiSelected}Concreto`]}</option> : <option value={""}  >Selecionar</option>}
                  {tiposDeConcretoFck.map((tipo) => (inputValues[`ancoragemEstai${inputValues.estaiSelected}Concreto`] !== tipo.slice(-2) && <option value={tipo.slice(-2)} key={tipo}>{tipo}</option>))}
                </select></>}
            </div>
          </div>
        </MenuDoubleOption>
        {existingBlock && existingBlock !== "0" &&
          <MenuTripleOption>
            <h2>Dimensões do bloco</h2>
            <div>
              <div>
                <h3>L1 (cm)</h3>
                <input value={inputValues[`ancoragemEstai${inputValues.estaiSelected}L1`]} type="number" placeholder={inputValues[`ancoragemEstai${inputValues.estaiSelected}L1`] ? inputValues[`ancoragemEstai${inputValues.estaiSelected}L1`] : "L1 (cm)"} onChange={(e) => setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}L1`]: e.target.value })} ></input>
              </div>
              <div>
                <h3>L2 (cm)</h3>
                <input value={inputValues[`ancoragemEstai${inputValues.estaiSelected}L2`]} type="number" placeholder={inputValues[`ancoragemEstai${inputValues.estaiSelected}L2`] ? inputValues[`ancoragemEstai${inputValues.estaiSelected}L2`] : "L2 (cm)"} onChange={(e) => setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}L2`]: e.target.value })}></input>
              </div>
              <div>
                <h3>H (cm)</h3>
                <input value={inputValues[`ancoragemEstai${inputValues.estaiSelected}H`]} type="number" placeholder={inputValues[`ancoragemEstai${inputValues.estaiSelected}H`] ? inputValues[`ancoragemEstai${inputValues.estaiSelected}H`] : "H (cm)"} onChange={(e) => setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}H`]: e.target.value })}></input>
              </div>
            </div>
          </MenuTripleOption>
        }
        {!!existingBlock && existingBlock !== "0" &&
          <MenuTripleOptionWithCreatableSelect>
            <h2>Ancoragem do bloco na laje</h2>
            <div>
              <div>
                <h3>Quantidade Ferros</h3>
                <input value={inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosQtd`]} className="nonCreatableInput" type="number" placeholder={inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosQtd`] ? inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosQtd`] : "Qtd"} onChange={(e) => setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}FerrosQtd`]: e.target.value })} ></input>
              </div>
              <div>
                <h3>Diâmetro ø (mm)</h3>
                <IronScrewDiameterSelect inputValues={inputValues} setInputValues={setInputValues} screwType={"Ferros"} />
              </div>
              <div>
                <h3>Ancoragem (cm)</h3>
                <input value={inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosAncoragem`]} className="nonCreatableInput" type="number" placeholder={inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosAncoragem`] ? inputValues[`ancoragemEstai${inputValues.estaiSelected}FerrosAncoragem`] : "(cm)"} onChange={(e) => setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}FerrosAncoragem`]: e.target.value })}></input>
              </div>
            </div>
          </MenuTripleOptionWithCreatableSelect>
        }
        { existingBlock && existingBlock !== "0" && mastProps.tipoMastro !== "MA" && (Number(estaiSelectedInMenu) !== estaisByModules[mastProps.tipoMastro] || estaiSelectedInMenu === "Central") &&
          <EstaiAnchorageSelectionContainer>
            <h2>Identificar Ancoragens Iguais</h2>
            <div>
              {possibleEqualAnchorageList.map((item, index) => <EqualAnchorageIdentificator key={index} name={item} estaiSelectedInMenu={estaiSelectedInMenu} isAnchorageEqualTo={isAnchorageEqualTo} setIsAnchorageEqualTo={setIsAnchorageEqualTo} mastSelected={mastSelected}></EqualAnchorageIdentificator>)}
            </div>
          </EstaiAnchorageSelectionContainer>
        }
        <ButtonPositioner >
          <ReturnButton onClick={() => {
            if (isInputModified) {
              if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("");
            }
            else setLeftMenuOptionSelected("");
          }} >{" Voltar "}</ReturnButton>
          {updateMastLoading ?           
            <LoadingButton>{ "Carregando" }</LoadingButton> :
            <SavingButton disabled={!isInputModified} onClick={() => { if(isInputModified) { updateMastEstaiData(); }; }}>{ "Salvar" }</SavingButton>}
        </ButtonPositioner>
      </ResizableLeftContainer>
      <ResizableRightContainer>
        <RightAnchoragePageContainer>
          <ProjectNameWrapper>
            <h2><span onClick={() => handleMenuClick("project")}>Projetos</span> / <span onClick={() => handleMenuClick("projectName")}>{projectSelected?.nomeProjeto}</span> <span onClick={() => handleMenuClick("")}>{mastSelected?.identificador ? ` / ${mastSelected?.identificador}` : ""}</span></h2>
            <h1><span onClick={() => handleMenuClick("")}>{mastSelected?.identificador ? `${mastSelected?.identificador}` : projectSelected?.nomeProjeto}</span></h1>
          </ProjectNameWrapper>
          <AnchoragePicturesContainer>
            <AnchoragePictureDescription>{estaiSelectedInMenu === "Central" ? "Bloco Mastro" : `Bloco Estai ${inputValues.estaiSelected}`}</AnchoragePictureDescription>
            <img alt="" src={estaiSelectedInMenu === "Central" ? bloco_mastro : bloco_estai}/>
          </AnchoragePicturesContainer>
        </RightAnchoragePageContainer>
      </ResizableRightContainer>
    </>
  );
}

function IronScrewDiameterSelect({ inputValues, setInputValues, screwType }) {
  const diametersList = ["6.3mm", "8mm", "10mm", "12.5mm", "16mm", "20mm", "25mm"];
  const handleDiameterChange = (selectedOption) => {
    if (selectedOption) {
      const selectedDiameter = diametersList.find((diameter) => diameter === selectedOption.value);
      if (selectedDiameter) {
        setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`]: selectedDiameter });
      }
      else setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`]: selectedOption.value });
    } else {
      setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`]: "" });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      backgroundColor: "#ffffff",
      border: "1px solid #C8C8C8",
      minHeight: "4.5vh",
      height: "4.5vh",
      borderRadius: "8px",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      color: "#000",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
      position: "absolute",
      right: "-10px",
      size: "2px"
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
      fontSize: "14px"
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    input: (provided) => ({
      ...provided,
      width: "100%",
      color: "#000",
    }),
    clearIndicator: (provided) => ({
      display: "none",
      color: "#000",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
  };

  return (
    <StyledSelect
      options={diametersList.map((diameter) => ({ value: diameter, label: diameter }))}
      styles={customStyles}
      value={inputValues[`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`] ? { value: inputValues[`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`], label: inputValues[`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`] } : ""}
      onChange={handleDiameterChange}
      placeholder={inputValues[`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`] ? inputValues[`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`] : "Diâmetro"}
      isClearable
      isSearchable
      menuPlacement="auto"
    />
  );
}

function ScrewDiameterSelect({ inputValues, setInputValues, diametersList, screwType }) {
  const handleDiameterChange = (selectedOption) => {
    if (selectedOption) {
      const selectedDiameter = diametersList.find((diameter) => diameter === selectedOption.value);
      if (selectedDiameter) {
        setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`]: selectedDiameter });
      }
      else setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`]: selectedOption.value });
    } else {
      setInputValues({ ...inputValues, [`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`]: "" });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      backgroundColor: "#ffffff",
      border: "1px solid #C8C8C8",
      minHeight: "4.5vh",
      height: "4.5vh",
      borderRadius: "8px",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      color: "#000",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
      position: "absolute",
      right: "-10px",
      size: "2px"
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
      fontSize: "14px"
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
      color: "#000",
    }),
    input: (provided) => ({
      ...provided,
      width: "100%",
      color: "#000",
    }),
    clearIndicator: (provided) => ({
      display: "none",
      color: "#000",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
  };

  return (
    <StyledSelect
      options={diametersList.map((diameter) => ({ value: diameter, label: diameter }))}
      styles={customStyles}
      value={inputValues[`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`] ? { value: inputValues[`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`], label: inputValues[`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`] } : ""}
      onChange={handleDiameterChange}
      placeholder={inputValues[`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`] ? inputValues[`ancoragemEstai${inputValues.estaiSelected}${screwType}Diametro`] : "Diâmetro"}
      isClearable
      isSearchable
      menuPlacement="auto"
    />
  );
}

function EqualAnchorageIdentificator({ name, estaiSelectedInMenu, isAnchorageEqualTo, setIsAnchorageEqualTo, mastSelected }) {
  const mastProps = JSON.parse(mastSelected.props);
  const [isSelected, setIsSelected] = useState(mastProps[`ancoragemEstai${name}CopiaDe`] === `ancoragemEstai${estaiSelectedInMenu}` || mastProps[`ancoragemEstai${estaiSelectedInMenu}CopiaDe`] === `ancoragemEstai${name}`);
  useEffect(() => {
    setIsSelected(mastProps[`ancoragemEstai${name}CopiaDe`] === `ancoragemEstai${estaiSelectedInMenu}` || mastProps[`ancoragemEstai${estaiSelectedInMenu}CopiaDe`] === `ancoragemEstai${name}`);
  }, [estaiSelectedInMenu]);

  return (
    <EqualAnchorageIdentificatorContainer isSelected={isSelected} onClick={() => {
      const updatedArray = [...isAnchorageEqualTo];
      if (!isSelected) {
        updatedArray.push({ [estaiSelectedInMenu]: name });
      } else {
        const indexToRemove = updatedArray.findIndex(item => item[estaiSelectedInMenu] === name);
        if (indexToRemove !== -1) {
          updatedArray.splice(indexToRemove, 1);
        }
      }
      setIsAnchorageEqualTo(updatedArray);
      setIsSelected(!isSelected);
    }}>
      <div>
        <ion-icon name="close-outline"></ion-icon>
      </div>
      <h6>Anc Estai {name}</h6>
    </EqualAnchorageIdentificatorContainer>
  );
}

const StyledSelect = styled(CreatableSelect)`
  .react-select__control {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4.5vh;
    border-radius: 8px;
    /*font-family: "Lexend Deca", sans-serif*/
    font-family: 'Roboto', sans-serif;;
    font-size: 26px;
    color: #000;
  }
  .react-select__placeholder {
    color: #000;
  }

  .react-select__dropdown-indicator {
    color: #000;
  }

  .react-select__option {
    color: #000;
  }
`;

const AnchorageMenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1vh 0;
  position: relative;

  h2 {
    font-weight: 300;
    font-size: 16px;
    color: #313131;
    margin-bottom: 0.8vh;
  }

  input,select {
    width: 100%;
    background-color: ${(props) => (props.backgroundColor)};
    height: 4.5vh;
    padding: 6px;
    border-radius: 8px;
    /*font-family: "Lexend Deca", sans-serif*/
  font-family: 'Roboto', sans-serif;;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }
`;

const AnchoragePicturesContainer = styled.div`
  display: flex;
  width: 38vw;
  height: 54vh;
  align-items: center;
  justify-content: center;
  border: 1px solid #C8C8C8;
  border-radius: 8px;
  position: relative;
  img {
    margin: 30px 20px 20px 20px;
    width: 500px;
  };
`;

const AnchoragePictureDescription = styled.div`
  position: absolute;
  top: -1px;
  width: 100.2%;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  border-radius: 8px;
  border-top: 1px solid #C8C8C8;
  border-right: 1px solid #C8C8C8;
  border-left: 1px solid #C8C8C8;
  font-weight: 600;
  font-size: 17px;
`;

const MenuTripleOptionWithCreatableSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1vh 0;
  position: relative;

  h2 {
    font-weight: 300;
    font-size: 16px;
    color: #313131;
    margin-bottom: 1vh;
  }

  .nonCreatableInput {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4.5vh;
    padding: 6px;
    border-radius: 8px;
    /*font-family: "Lexend Deca", sans-serif*/
  font-family: 'Roboto', sans-serif;;
    font-size: 16px;
    color: #000;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }
  
  >div {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  >div>div{
    width: 29%;
    h3 {
      font-size: 15px;
      color: #313131;
      font-weight: 300;
      margin: 0 0 6px 0;
    }
  }
`;

const EstaiAnchorageSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width:  100%;
  margin: 1vh 0;
  position: relative;

  h2 {
    font-weight: 300;
    font-size: 16px;
    color: #313131;
    margin-bottom: 1vh;
  }

  >div {
    display: grid;
    width: 57.5%;
    grid-template-columns: 100% 100%;
  }
`;

const EqualAnchorageIdentificatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 4vh;

  div {
    width: 30px;
    height: 30px;
    background-color: ${(props) => (props.isSelected ? "#F16E00" : "#fff") };
    border: 2px solid #FE8F03;
    margin-right: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h6 {
    color: #313131;
  }

  ion-icon {
    font-size: 24px;
    color: #fff;
    display: ${(props) => (props.isSelected ? "inherit" : "none") };
  }
`;

const RightAnchoragePageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 75%;
  justify-content: center;
  align-items: center;
  height: calc(100% - 4vh);
  margin-top: 4vh;

`;

const ProjectNameWrapper = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  left: 2.7vw;
  top: 0;

  h2 {
    color: #666666;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  h1 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
  }
    span {
    transition: 0.0001s all;
    cursor: pointer;
    :hover {
      font-weight: 600 !important;
    }
  }
`;
