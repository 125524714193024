import MH_1_1 from "./1MOD/MH-1-1.jpg";
import MH_1_2 from "./1MOD/MH-1-2.jpg";
import MH_1_3 from "./1MOD/MH-1-3.jpg";
import MH_1_4 from "./1MOD/MH-1-4.jpg";
import MH_1_5 from "./1MOD/MH-1-5.jpg";
import MH_1_6 from "./1MOD/MH-1-6.jpg";
import MH_1_7 from "./1MOD/MH-1-7.jpg";
import MH_1_8 from "./1MOD/MH-1-8.jpg";
import MH_1_9 from "./1MOD/MH-1-9.jpg";
import MH_1_10 from "./1MOD/MH-1-10.jpg";

import MH_2_2 from "./2MOD/MH-2-2.jpg";
import MH_2_3 from "./2MOD/MH-2-3.jpg";
import MH_2_4 from "./2MOD/MH-2-4.jpg";
import MH_2_5 from "./2MOD/MH-2-5.jpg";
import MH_2_6 from "./2MOD/MH-2-6.jpg";
import MH_2_7 from "./2MOD/MH-2-7.jpg";
import MH_2_8 from "./2MOD/MH-2-8.jpg";
import MH_2_9 from "./2MOD/MH-2-9.jpg";
import MH_2_10 from "./2MOD/MH-2-10.jpg";

import MH_3_3 from "./3MOD/MH-3-3.jpg";
import MH_3_4 from "./3MOD/MH-3-4.jpg";
import MH_3_5 from "./3MOD/MH-3-5.jpg";
import MH_3_6 from "./3MOD/MH-3-6.jpg";
import MH_3_7 from "./3MOD/MH-3-7.jpg";
import MH_3_8 from "./3MOD/MH-3-8.jpg";
import MH_3_9 from "./3MOD/MH-3-9.jpg";
import MH_3_10 from "./3MOD/MH-3-10.jpg";

import MH_4_4 from "./4MOD/MH-4-4.jpg";
import MH_4_5 from "./4MOD/MH-4-5.jpg";
import MH_4_6 from "./4MOD/MH-4-6.jpg";
import MH_4_7 from "./4MOD/MH-4-7.jpg";
import MH_4_8 from "./4MOD/MH-4-8.jpg";
import MH_4_9 from "./4MOD/MH-4-9.jpg";
import MH_4_10 from "./4MOD/MH-4-10.jpg";

import MH_5_5 from "./5MOD/MH-5-5.jpg";
import MH_5_6 from "./5MOD/MH-5-6.jpg";
import MH_5_7 from "./5MOD/MH-5-7.jpg";
import MH_5_8 from "./5MOD/MH-5-8.jpg";
import MH_5_9 from "./5MOD/MH-5-9.jpg";
import MH_5_10 from "./5MOD/MH-5-10.jpg";

import MH_6_6 from "./6MOD/MH-6-6.jpg";
import MH_6_7 from "./6MOD/MH-6-7.jpg";
import MH_6_8 from "./6MOD/MH-6-8.jpg";
import MH_6_9 from "./6MOD/MH-6-9.jpg";
import MH_6_10 from "./6MOD/MH-6-10.jpg";

import MH_7_7 from "./7MOD/MH-7-7.jpg";
import MH_7_8 from "./7MOD/MH-7-8.jpg";
import MH_7_9 from "./7MOD/MH-7-9.jpg";
import MH_7_10 from "./7MOD/MH-7-10.jpg";

import MH_8_8 from "./8MOD/MH-8-8.jpg";
import MH_8_9 from "./8MOD/MH-8-9.jpg";
import MH_8_10 from "./8MOD/MH-8-10.jpg";

import MH_9_9 from "./9MOD/MH-9-9.jpg";
import MH_9_10 from "./9MOD/MH-9-10.jpg";

import MH_10_10 from "./10MOD/MH-10-10.jpg";

const MHImg = { MH_1_1, MH_1_2, MH_1_3, MH_1_4, MH_1_5, MH_1_6, MH_1_7, MH_1_8, MH_1_9, MH_1_10, MH_2_2, MH_2_3, MH_2_4, MH_2_5, MH_2_6, MH_2_7, MH_2_8, MH_2_9, MH_2_10, MH_3_3, MH_3_4, MH_3_5, MH_3_6, MH_3_7, MH_3_8, MH_3_9, MH_3_10, MH_4_4, MH_4_5, MH_4_6, MH_4_7, MH_4_8, MH_4_9, MH_4_10, MH_5_5, MH_5_6, MH_5_7, MH_5_8, MH_5_9, MH_5_10, MH_6_6, MH_6_7, MH_6_8, MH_6_9, MH_6_10, MH_7_7, MH_7_8, MH_7_9, MH_7_10, MH_8_8, MH_8_9, MH_8_10, MH_9_9, MH_9_10, MH_10_10 };

export default MHImg;
