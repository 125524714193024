import styled from "styled-components";

export const CustomSelect = ({
  children,
  value,
  name,
  onChange,
  label = "",
  placeholder = "",
  disabled = false,
}) => {
  const handleChange = (e) => {
    const numValue = e.target.value;
    onChange(numValue);
  };

  return (
    <div>
      {label && <StyledLabel>{label}</StyledLabel>}
      <select
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
      >
        {children}
      </select>
    </div>
  );
};

const StyledLabel = styled.label`
    color: #313131;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;
