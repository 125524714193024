import useAsync from "../useAsync";
import * as mastApi from "../../services/mastApi";

export default function useDeleteMast() {
  const {
    data: deletedMast,
    loading: deleteMastLoading,
    error: deleteMastError,
    act: deleteMast,
  } = useAsync((mastId, token) => mastApi.deleteMast(mastId, token), false);

  return {
    deletedMast,
    deleteMastLoading,
    deleteMastError,
    deleteMast
  };
}
