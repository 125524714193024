import useAsync from "../../useAsync";
import { getRedundants, postRedundants, updateRedundants } from "../../../services/tower-services/towerRedundantsApi";

export const useTowerRedundants = (action) => {
  const execute = {
    get: getRedundants,
    post: postRedundants,
    update: updateRedundants,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
