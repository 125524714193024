import styled from "styled-components";

const SavingButtonContainer = styled.div`
  width: 48%;
  height: 3.5vh;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? "#c9c9c9" : "#FE8F03")};
  color: ${(props) => (props.disabled ? "#626262" : "#ffffff")};
  cursor: pointer;
  font-weight: 400;
  transition: background-color 1250ms ease-in-out;
  letter-spacing: 0.7px;
`;

export default SavingButtonContainer;
