import letterX from "./x.png";
import letterY from "./y.png";
import letterZ from "./z.png";

const imagesCharacteres = {
  x: letterX,
  y: letterY,
  z: letterZ,
};

export default imagesCharacteres;
