import { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import UserContext from "../../contexts/UserContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import useGetGoogleMapsApi from "../../hooks/api/useGetGoogleMapsApi";
import MenuInfoOptions from "./info-topMenu-options";
import ResultsTopMenuOptions from "./results-topMenu-options";
import UserPicAndInfo from "./userPic";
import zeo_logo_topbar from "../../assets/images/zeo-logo-topbar.png";
import left_menu_icon from "../../assets/images/left-menu-icon.svg";
import fundacao_big_icon from "../../assets/images/fundacao-big-icon.png";
import poste_big_icon from "../../assets/images/poste-big-icon.png";
import rooftop_big_icon from "../../assets/images/rooftop-big-icon.png";
import torre_big_icon from "../../assets/images/torre-big-icon.png";

export function TopBar({ children, opacity, projectType, isInProjectOpeningPage, isLeftMenuOpen, setIsLeftMenuOpen }) {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { getGoogleMapsApi } = useGetGoogleMapsApi();
  const { projectSelected, setProjectType, setProjectSelected, setProjectNameSelected, setMastSelected, setLeftMenuOptionSelected, setGoogleMapsApiKey } =
    useContext(ProjectContext);
  const [shouldDisplaySoftwareOptions, setShouldDisplaySoftwareOptions] = useState(false);

  function toggleLeftMenu() {
    setIsLeftMenuOpen(!isLeftMenuOpen);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const apiKey = await getGoogleMapsApi(userData.token);
        setGoogleMapsApiKey(apiKey);
      } catch (error) {
        return error;
      }
    }
    fetchData();
  }, [projectSelected]);
  function navigateToHomeAndResetParameters() {
    setProjectType("");
    setProjectSelected({});
    setMastSelected({});
    setProjectNameSelected("");
    setLeftMenuOptionSelected("");
    navigate("/");
    window.localStorage.removeItem("pageProps");
    window.localStorage.removeItem("userData");
  }
  function navigateToRooftopPageAndResetParameters() {
    setProjectSelected({});
    setMastSelected({});
    setProjectNameSelected("");
    setProjectType("Rooftop");
    navigate("/rooftop");
    setLeftMenuOptionSelected("");
    window.localStorage.removeItem("pageProps");
    window.localStorage.removeItem("userData");
  }
  function navigateToTowerPageAndResetParameters() {
    setProjectSelected({});
    setMastSelected({});
    setProjectNameSelected("");
    setProjectType("Torre");
    navigate("/torre");
    setLeftMenuOptionSelected("");
    window.localStorage.removeItem("pageProps");
    window.localStorage.removeItem("userData");
  }
  return (
    !isInProjectOpeningPage ?
      <Container opacity={opacity}>
        <Top>
          {location.pathname === "/rooftop" || location.pathname === "/torre" ? (
            <ProjectTitle>
              <LogoTopBar src={zeo_logo_topbar} alt="zeo logo" onClick={navigateToHomeAndResetParameters} />
              <SoftwareOptionsSuspendedMenu onMouseEnter={() => setShouldDisplaySoftwareOptions(true)} onMouseLeave={() => setShouldDisplaySoftwareOptions(false)} isMouseOn={shouldDisplaySoftwareOptions}>
                <h1>Softwares</h1>
                <SoftwareSuspendedMenuOption onClick={navigateToRooftopPageAndResetParameters}>
                  <img src={rooftop_big_icon} alt="Rooftop ícone" />
                  Rooftop
                </SoftwareSuspendedMenuOption>
                <SoftwareSuspendedMenuOption >
                  <img src={poste_big_icon} alt="Poste ícone" />
                  Poste
                </SoftwareSuspendedMenuOption>
                <SoftwareSuspendedMenuOption onClick={navigateToTowerPageAndResetParameters}>
                  <img src={torre_big_icon} alt="Torre ícone" />
                  Torre
                </SoftwareSuspendedMenuOption>
                <SoftwareSuspendedMenuOption>
                  <img src={fundacao_big_icon} alt="Fundação ícone" />
                  Fundação
                </SoftwareSuspendedMenuOption>
              </SoftwareOptionsSuspendedMenu>
              <SoftwareOptionsSuspendedMenuWrapper onMouseEnter={() => setShouldDisplaySoftwareOptions(true)}
                onMouseLeave={() => setShouldDisplaySoftwareOptions(false)}>
                <img src={left_menu_icon} alt="left menu opening icon" />
              </SoftwareOptionsSuspendedMenuWrapper>
              <VerticalSpacingLine margin={"0 20px"} />
              {location.pathname === "/rooftop" && <RooftopIcon src={rooftop_big_icon} alt="Rooftop Icon" />}
              {location.pathname === "/torre" && <RooftopIcon src={torre_big_icon} alt="Torre Icon" />}
              <p>{projectType}</p>
            </ProjectTitle>
          ) : (
            <ProjectTitle>
              <LogoTopBar src={zeo_logo_topbar} alt="zeo logo" onClick={navigateToHomeAndResetParameters} />
            </ProjectTitle>
          )}
          <RightTopMenuOptionsContainer>
            {projectSelected?.id && location.pathname !== "/account/edit" && <>
              <ResultsTopMenuOptions />
              <VerticalSpacingLine margin={"0 4px"} />
            </>}
            <MenuInfoOptions />
            <VerticalSpacingLine margin={"0 4px"} />
            <UserPicAndInfo />
          </RightTopMenuOptionsContainer>
        </Top>
        {children}
      </Container>
      :
      <Container opacity={opacity}>
        <Top>
          <ProjectTitle>
            <LogoTopBar src={zeo_logo_topbar} alt="zeo logo" onClick={navigateToHomeAndResetParameters} />
            <img src={left_menu_icon} alt="left menu opening icon" onClick={toggleLeftMenu} />
            <VerticalSpacingLine margin={"0 20px"} />
            <p>{projectType}</p>
          </ProjectTitle>
          <RightTopMenuOptionsContainer>
            {projectSelected?.id && <>
              <ResultsTopMenuOptions />
              <VerticalSpacingLine margin={"0 4px"} />
            </>}
            <MenuInfoOptions />
            <VerticalSpacingLine margin={"0 4px"} />
            <UserPicAndInfo />
          </RightTopMenuOptionsContainer>
        </Top>
        {children}
      </Container>
  );
}

const Container = styled.div`
  width: 100%;
  opacity: ${(props) => props.opacity};
`;

const Top = styled.div`
  position: relative;
  background-color: #ffffff;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-left: 20px;
  z-index: 6;
  box-shadow: 0px 1px 1px 0px #0000001A;
`;

const ProjectTitle = styled.div`
  display: flex;
  position: absolute;
  width: auto;
  background-color: ${(props) => (props.isClicked ? "#d0cece !important" : "#ffffff !important")};
  left: -10px;
  top: 12px;
  height: 60%;
  font-size: 24px;
  font-weight: 600;
  color: #000;
  display: flex;
  align-items: center;
  padding: 0 18px;

  span {
    margin: 0 20px;
  }

  img {
    cursor: pointer;
  }
  p {
    font-size: 20px;
    color: #333333;
  }
`;

const RooftopIcon = styled.img`
  cursor: initial !important;
  width: 18px;
  height: 30px;
  margin-right: 12px;
`;

const SoftwareOptionsSuspendedMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
`;

const LogoTopBar = styled.img`
  width: 100px;
  margin: 0 20px;
`;

const VerticalSpacingLine = styled.div`
  width: 1.2px;
  height: 30px;
  margin: ${(props) => props.margin};
  background-color: #DADADA;
`;

const SoftwareOptionsSuspendedMenu = styled.div`
  position: absolute;
  left: 100px;
  top: 38px;
  width: 220px;
  height: 260px;
  border-radius: 0px 0px 6px 6px;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  display: ${(props) => (props.isMouseOn ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-around;
  h1 {
    color: rgba(0, 0, 0, 1);
    font-size: 19px;
    margin: 16px 0 6px 20px;
  }
`;

const SoftwareSuspendedMenuOption = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  padding: 13px 0;
  cursor: not-allowed;
  :nth-child(2), :nth-child(4) {
    cursor: pointer !important;
  }
  :nth-last-child(1) {
    border-radius: 0px 0px 6px 6px;
  }
  :hover {
    background-color: #d9d9d9;
  }
  >img {
    width: 14px;
    height: 28px;
    margin: 0 20px;
  }
`;

const RightTopMenuOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  justify-content: space-around;
`;
