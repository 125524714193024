import { useEffect, useState } from "react";

function useUnsavedChangesAlertForObjects(inputValues, props, reloadProject, fieldsToCheck) {
  const [inputModified, setInputModified] = useState(false);

  useEffect(() => {
    if (props?.length === 0 && inputValues?.every(obj => isObjectWithEmptyFieldsOnly(obj, fieldsToCheck))) {
      setInputModified(false);
    } else {
      const isModified = Object.keys(inputValues).length > 0 && inputValues?.some((inputValue, index) => {
        if (inputValues.length !== props.length) return true;
        const correspondingProp = props[index] || [];
        return !deepEqual(inputValue, correspondingProp, fieldsToCheck);
      });

      setInputModified(isModified);
    }
  }, [inputValues, props, reloadProject, fieldsToCheck]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (inputModified) {
        event.preventDefault();
        event.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [inputModified]);

  return inputModified;
}

function isEmptyValue(value) {
  return value === "" || value === undefined || value === null || (Array.isArray(value) && value.length === 0);
}

function isObjectWithEmptyFieldsOnly(obj, fieldsToCheck) {
  return fieldsToCheck.every(field => {
    const fieldParts = field.split(".");
    let currentObj = obj;
    for (const part of fieldParts) {
      if (Array.isArray(currentObj)) {
        return currentObj.every(item => isEmptyValue(item[part]));
      } else {
        currentObj = currentObj[part];
        if (currentObj === undefined) return true;
      }
    }
    return isEmptyValue(currentObj);
  });
}

function deepEqual(obj1, obj2, fieldsToCheck) {
  if (obj1 === obj2) return true;

  if ((!Array.isArray(obj1) && Array.isArray(obj2))
    || (Array.isArray(obj1) && !Array.isArray(obj2))
    || (typeof obj1 === "object" && typeof obj2 !== "object")
    || (typeof obj1 !== "object" && typeof obj2 === "object")
    || (obj1 == null && obj2 != null)
    || (obj1 != null && obj2 == null)) {
    return false;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;
    for (let i = 0; i < obj1.length; i++) {
      if (!deepEqual(obj1[i], obj2[i], fieldsToCheck)) return false;
    }
    return true;
  }

  for (const field of fieldsToCheck) {
    const parts = field.split(".");
    const result = compareFields(obj1, obj2, parts);
    if (!result) return false;
  }

  return true;
}

function compareFields(obj1, obj2, fieldParts) {
  let current1 = obj1, current2 = obj2;

  for (const part of fieldParts) {
    if (Array.isArray(current1) && Array.isArray(current2)) {
      if (current1.length !== current2.length) return false;
      for (let i = 0; i < current1.length; i++) {
        if (!compareFields(current1[i], current2[i], [part])) return false;
      }
      return true;
    } else {
      current1 = current1 ? current1[part] : undefined;
      current2 = current2 ? current2[part] : undefined;
    }
  }

  return current1 === current2;
}

export default useUnsavedChangesAlertForObjects;
