import api from "../apiTower";

const makeHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getRedundants = async(towerId, token) => {
  const response = await api
    .get(`/tower/redundants/${encodeURIComponent(towerId)}`, makeHeaders(token));
  return response.data;
};

export const postRedundants = async(body, towerId, token) => {
  const parsedBody = JSON.parse(body);
  const response = await api
    .post(`/tower/redundants/${encodeURIComponent(towerId)}`, parsedBody, makeHeaders(token));
  return response.data;
};

export const updateRedundants = async(body, towerId, token) => {
  const parsedBody = JSON.parse(body);
  const response = await api
    .put(`/tower/redundants/${encodeURIComponent(towerId)}`, parsedBody, makeHeaders(token));
  return response.data;
};
