import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";

class TrussModel {
  constructor(
    segmentId = "",
    trussType = "",
    totalHeight = "",
    hasSuperiorHorizontal = "",
    mountDivisions = "",
    bracingType = ""
  ) {
    this.segmentId = segmentId;
    this.trussType = trussType;
    this.totalHeight = totalHeight;
    this.hasSuperiorHorizontal = hasSuperiorHorizontal;
    this.mountDivisions = mountDivisions;
    this.bracingType = bracingType;
  }

  updateField(field, value) {
    if (this[field] === value) return;

    if (field === "trussType") {
      this.mountDivisions = "";
      this.bracingType = "";
    }

    if (field === "mountDivisions") {
      this.bracingType = "";
    }

    this[field] = value;
  }
}

class TrussesModel {
  constructor(trusses = []) {
    this.trusses = trusses?.map(truss => new TrussModel(
      truss.segmentId,
      truss.trussType,
      truss.totalHeight,
      truss.hasSuperiorHorizontal,
      truss.mountDivisions,
      truss.bracingType
    ));
  }

  updateTruss(segmentId, field, value) {
    const trussIndex = this.trusses.findIndex(truss => truss.segmentId === segmentId);
    const truss = this.trusses[trussIndex];

    truss.updateField(field, value);

    if (field ==="trussType" && value === "Treliça K") {
      truss.updateField("hasSuperiorHorizontal", "Sim");
    }

    if (field ==="trussType" && value === "Treliça V") {
      const nextTruss = trussIndex === this.trusses.length - 1
        ? null : this.trusses[trussIndex + 1];
      nextTruss && nextTruss.updateField("hasSuperiorHorizontal", "Sim");
    }

    if (truss?.trussType === "Treliça H" && field === "bracingType" && value === "B") {
      truss.updateField("hasSuperiorHorizontal", "Sim");
    }
  }

  validate(totalTowerHeight) {
    let errors = [];
    let segmentHeights = 0;

    this.trusses.forEach((segment, index) => {
      segmentHeights += Number(segment.totalHeight);
      if (!segment.trussType)
        errors.push(`Tipo de Treliça no trecho ${index + 1}`);
      if (!segment.totalHeight)
        errors.push(`Altura Total no trecho ${index + 1}`);
      if (segment.hasSuperiorHorizontal === "" && segment.trussType !== "Treliça K")
        errors.push(`Tem Horizontal Superior no trecho ${index + 1}`);
      if (!segment.mountDivisions)
        errors.push(`Divisões do Montante no trecho ${index + 1}`);
      if (!segment.bracingType)
        errors.push(`Tipo de Contraventamento no trecho ${index + 1}`);
      if (segment.trussType === "Treliça V" && this.trusses[index + 1]?.hasSuperiorHorizontal === "Não")
        errors.push(`Trecho ${index + 2} deve ter Horizontal Superior pois trecho ${index + 1} possui Treliça V`);
    });

    if (Number(segmentHeights) !== Number(totalTowerHeight) * 1000)
      errors.push("A soma das alturas de cada trecho deve ser igual a da altura total da torre");

    if (errors.length > 0) {
      const errorMessage = customToastError(errors,
        "Preencha todos os valores para salvar as informações das treliças. Falta(m):");
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  toJSON() {
    return JSON.stringify(this.trusses.map(truss => ({
      segmentId: truss.segmentId,
      trussType: truss.trussType,
      totalHeight: truss.totalHeight,
      hasSuperiorHorizontal: truss.hasSuperiorHorizontal,
      mountDivisions: truss.mountDivisions,
      bracingType: truss.bracingType
    })));
  }

  static fromJSON(jsonArray) {
    const parsedArray = JSON.parse(jsonArray);
    return new TrussesModel(parsedArray.map(json => new TrussModel(
      json.segmentId,
      json.trussType,
      json.totalHeight,
      json.hasSuperiorHorizontal,
      json.mountDivisions,
      json.bracingType
    )));
  }
}

export default TrussesModel;
