import styled from "styled-components";
import useGetUserInfo from "../../hooks/api/useGetUserInfo";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../contexts/UserContext";
import UserTopMenuOptions from "./user-topMenu-options";

export default function UserPicAndInfo() {
  const [isMenuOpen, setIsMenuOpen] = useState("");
  const handleMouseEnter = (menu) => {
    setIsMenuOpen(menu);
  };

  const handleMouseLeave = () => {
    setIsMenuOpen(false);
  };
  return (
    <MenuOptionSelectorContainer
      onMouseEnter={() => handleMouseEnter("logout")}
      onMouseLeave={() => handleMouseLeave()}
      isLast={true}
      isClicked={isMenuOpen === "logout"}
    >
      <UserPic isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </MenuOptionSelectorContainer>
  );
}

function UserPic({ isMenuOpen, setIsMenuOpen }) {
  const { getUserInfo } = useGetUserInfo();
  const [userProfilePicUrl, setUserProfilePicUrl] = useState("");
  const { setUserInfo, userData, userInfo } = useContext(UserContext);

  useEffect(() => {
    async function fetchData() {
      try {
        const userInformation = await getUserInfo(userData.token);
        setUserInfo(userInformation);
        if(userInformation.profilePic) setUserProfilePicUrl(userInformation.profilePic);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  return (
    !userProfilePicUrl ? 
      <UserInfoWrapper width={userInfo.name ? "150px" : "60px"}>
        <UserNameCircle>
          {userInfo.name && userInfo.lastName ? `${userInfo.name[0].toUpperCase()}${userInfo.lastName[0].toUpperCase()}` :  userData.name?.split(" ").length > 1 ?           
            `${userData.name?.split(" ")[0][0]}${userData.name?.split(" ")[userData?.name.split(" ").length - 1][0]}` 
            : 
            userData.name?.split(" ")[0][0]}
          {isMenuOpen === "logout" && <UserTopMenuOptions type="logout" isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />}
        </UserNameCircle>
        <UserNameWrapper >
          <h1>{userInfo.name} {userInfo.lastName}</h1>
          <h2>{userInfo.company}</h2>
        </UserNameWrapper>
      </UserInfoWrapper>
      :
      <UserInfoWrapper width={userInfo.name ? "150px" : "60px"}>
        <UserNameCircle>
          <img src={userProfilePicUrl} alt=""/>
          {isMenuOpen === "logout" && <UserTopMenuOptions type="logout" isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />}
        </UserNameCircle>
        <UserNameWrapper width={userInfo.name ? "150px" : "60px"}>
          <h1>{userInfo.name} {userInfo.lastName}</h1>
          <h2>{userInfo.company}</h2>
        </UserNameWrapper>
      </UserInfoWrapper>
  );
}

const UserNameCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #D9D9D9;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #000;
  position: relative;

  img {
    object-fit: cover;
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
`;

const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => (props.width)};
  margin-left: 20px;
`;
const UserNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
  margin-left: 10px;
  height: 40px;
  justify-content: space-evenly;
  h1 {
    color: #333333;
    font-size: 14px;
    word-wrap: break-word;
    word-break: break-all;
    max-width: 100px;
  }
  h2 {
    color: #888888;
    font-size: 13px;
    word-wrap: break-word;
    word-break: break-all;
    max-width: 100px;
  }
`;

const MenuOptionSelectorContainer = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isClicked ? "#d0cece" : "#ffffff")};
`;
