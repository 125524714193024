import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";
import { diagonalLockingsInfo } from "../constants/tower-trusses-data";

class DiagonalLockingBarModel {
  constructor(
    barNumber = null,
    isMissingBar = false,
    profileSteel = "",
    profileType = "",
    profileDimensions = "",
    profileDiameter = "",
    profileThickness = "",
    profileFlange = "",
    profileWeb = "",
    boltsSteel = "",
    numConnectionBolts = "",
    diameterConnectionBolts = ""
  ) {
    this.barNumber = barNumber;
    this.isMissingBar = isMissingBar;
    this.profileSteel = profileSteel;
    this.profileType = profileType;
    this.profileDimensions = profileDimensions;
    this.profileDiameter = profileDiameter;
    this.profileThickness = profileThickness;
    this.profileFlange = profileFlange;
    this.profileWeb = profileWeb;
    this.boltsSteel = boltsSteel;
    this.numConnectionBolts = numConnectionBolts;
    this.diameterConnectionBolts = diameterConnectionBolts;
  }

  updateField(field, value) {
    if (value === this[field] || this[field] === undefined) return;

    if (field === "isMissingBar") {
      this[field] = value;
      if (value === true) {
        this.profileType = "";
        this.profileSteel = "";
        this.profileDimensions = "";
        this.profileDiameter = "";
        this.profileThickness = "";
        this.profileFlange = "";
        this.profileWeb = "";
        this.boltsSteel = "";
        this.numConnectionBolts = "";
        this.diameterConnectionBolts = "";
      }
      return;
    }

    if (field === "profileType") {
      this[field] = value;
      this.profileSteel = "";
      this.profileDimensions = "";
      this.profileDiameter = "";
      this.profileThickness = "";
      this.profileFlange = "";
      this.profileWeb = "";
      return;
    }

    if (this.profileType === "T - Tubo Vazado") {
      if (field === "profileSteel") {
        this[field] = value;
        this.profileDimensions = "";
        this.profileDiameter = "";
        this.profileThickness = "";
        this.profileFlange = "";
        this.profileWeb = "";
        return;
      }

      if (field === "profileDiameter") {
        this[field] = value;
        this.profileThickness = "";
        return;
      }
    }

    this[field] = value;
  }
}

class DiagonalLockingDataModel {
  constructor(
    hasDiagonalLocking = "",
    lockingPoints = "",
    lockingType = "",
    bars = []
  ) {
    this.hasDiagonalLocking = hasDiagonalLocking;
    this.lockingPoints = lockingPoints;
    this.lockingType = lockingType;
    this.bars = bars.map((bar, index) => new DiagonalLockingBarModel(
      bar.barNumber = index + 1,
      bar.isMissingBar,
      bar.profileSteel,
      bar.profileType,
      bar.profileDimensions,
      bar.profileDiameter,
      bar.profileThickness,
      bar.profileFlange,
      bar.profileWeb,
      bar.boltsSteel,
      bar.numConnectionBolts,
      bar.diameterConnectionBolts
    )) || [];
  }

  updateDiagonal(diagonalType, field, value) {
    if (value === this[field] || this[field] === undefined) return;

    const numberOfBars = this.findNumberOfBars();

    if (field === "hasDiagonalLocking") {
      this[field] = value;
      if (value !== "Sim") {
        this.lockingPoints = "";
        this.lockingType = "";
        this.bars = numberOfBars > 0
          ? Array.from({ length: numberOfBars }, (bar, i) => new DiagonalLockingBarModel(
            i + 1,
            false,
            "", "", "", "", "", "", "", "", "", ""
          )) : [];
      }
      return;
    }

    if (field === "lockingPoints") {
      this[field] = value;
      this.lockingType = "";
      this.bars = numberOfBars > 0
        ? Array.from({ length: numberOfBars }, (bar, i) => new DiagonalLockingBarModel(
          i + 1,
          false,
          "", "", "", "", "", "", "", "", "", ""
        )) : [];
      return;
    }

    if (field === "lockingType") {
      this[field] = value;
      const newNumberOfBars = this.findNumberOfBars();
      this.bars = newNumberOfBars > 0
        ? Array.from({ length: newNumberOfBars }, (bar, i) => new DiagonalLockingBarModel(
          i + 1,
          false,
          "", "", "", "", "", "", "", "", "", ""
        )) : [];
      return;
    }

    this[field] = value;
  }

  updateBar(barIndex, field, value) {
    if (this.bars[barIndex]) {
      this.bars[barIndex].updateField(field, value);
    }
  }

  findNumberOfBars() {
    if (this.lockingPoints === "" || this.lockingType === "") return 0;
    return diagonalLockingsInfo[this.lockingPoints].find(type => type.lockingType === this.lockingType)?.bars;
  }
}

class DiagonalLockingSegmentModel {
  constructor(
    segmentId = "",
    trussType = "",
    superiorDiagonalData = {},
    inferiorDiagonalData = {}
  ) {
    this.segmentId = segmentId;
    this.trussType = trussType;
    this.superiorDiagonalData = this.trussType !== "Treliça H"
      ? new DiagonalLockingDataModel(
        superiorDiagonalData.hasDiagonalLocking,
        superiorDiagonalData.lockingPoints,
        superiorDiagonalData.lockingType,
        superiorDiagonalData.bars
      ) : {};
    this.inferiorDiagonalData = this.trussType === "Treliça X"
      ? new DiagonalLockingDataModel(
        inferiorDiagonalData.hasDiagonalLocking,
        inferiorDiagonalData.lockingPoints,
        inferiorDiagonalData.lockingType,
        inferiorDiagonalData.bars
      ) : {};
  }

  updateDiagonalData(diagonalType, field, value) {
    if (diagonalType === "superiorDiagonalData") {
      this.superiorDiagonalData.updateDiagonal(diagonalType, field, value);
    } else if (diagonalType === "inferiorDiagonalData") {
      this.inferiorDiagonalData.updateDiagonal(diagonalType, field, value);
    }
  }

  updateBarData(diagonalType, barIndex, field, value) {
    if (diagonalType === "superiorDiagonalData") {
      this.superiorDiagonalData.updateBar(barIndex, field, value);
    } else if (diagonalType === "inferiorDiagonalData") {
      this.inferiorDiagonalData.updateBar(barIndex, field, value);
    }
  }
}

class DiagonalLockingsModel {
  constructor(diagonalLockings = []) {
    this.diagonalLockings = diagonalLockings.map(segment => new DiagonalLockingSegmentModel(
      segment.segmentId,
      segment.trussType,
      segment.superiorDiagonalData || {},
      segment.inferiorDiagonalData || {}
    ));
  }

  updateDiagonalLocking(segmentId, diagonalType, field, value) {
    const segment = this.diagonalLockings.find(segment => segment.segmentId === segmentId);
    if (segment) {
      segment.updateDiagonalData(diagonalType, field, value);
    }
  }

  updateDiagonalLockingBar(segmentId, diagonalType, barIndex, field, value) {
    const segment = this.diagonalLockings.find(segment => segment.segmentId === segmentId);
    if (segment) {
      segment.updateBarData(diagonalType, barIndex, field, value);
    }
  }

  validate() {
    let errors = [];

    this.diagonalLockings.forEach((segment, index) => {
      if (segment.trussType !== "Treliça H") {
        if (segment.superiorDiagonalData.hasDiagonalLocking === "")
          errors.push(`Se a existe travamento na diagonal superior no trecho ${index + 1}`);

        if (segment.superiorDiagonalData.hasDiagonalLocking === "Sim") {
          if (!segment.superiorDiagonalData.lockingPoints)
            errors.push(`Pontos de travamento na diagonal superior no trecho ${index + 1}`);
          if (!segment.superiorDiagonalData.lockingType)
            errors.push(`Tipo de travamento na diagonal superior no trecho ${index + 1}`);

          segment.superiorDiagonalData.bars.forEach((bar, idx) => {
            if (bar.isMissingBar !== true) {
              if (!bar.profileType)
                errors.push(`Tipo de Perfil do travamento na barra ${idx + 1} na diagonal superior no trecho ${index + 1}`);
              if (bar.profileSteel === "")
                errors.push(`Aço do Perfil do travamento na barra ${idx + 1} na diagonal superior no trecho ${index + 1}`);
              if (!bar.profileDimensions && !bar.profileDiameter && !bar.profileThickness &&
                !bar.profileFlange && !bar.profileWeb) {
                errors.push(`Dimensões do Perfil do travamento na barra ${idx + 1} na diagonal superior no trecho ${index + 1}`);
              } else {
                if (bar.profileType === "T - Tubo Vazado" && (!bar.profileDiameter || !bar.profileThickness))
                  errors.push(`Dimensões do Perfil do travamento na barra ${idx + 1} na diagonal superior no trecho ${index + 1}`);
                if (bar.profileType === "U - Perfil U" && (!bar.profileFlange || !bar.profileWeb || !bar.profileThickness))
                  errors.push(`Dimensões do Perfil do travamento na barra ${idx + 1} na diagonal superior no trecho ${index + 1}`);
              }
              if (!bar.boltsSteel)
                errors.push(`Aço dos Parafusos do travamento na barra ${idx + 1} na diagonal superior no trecho ${index + 1}`);
              if (!bar.numConnectionBolts)
                errors.push(`Quantidade de parafusos de conexão do travamento na barra ${idx + 1} na diagonal superior no trecho ${index + 1}`);
              if (!bar.diameterConnectionBolts)
                errors.push(`Diametro de parafusos de conexão do travamento na barra ${idx + 1} na diagonal superior no trecho ${index + 1}`);
            }
          });
        }

        if (segment.trussType === "Treliça X") {
          if (segment.inferiorDiagonalData.hasDiagonalLocking === "")
            errors.push(`Se a existe travamento na diagonal inferior no trecho ${index + 1}`);

          if (segment.inferiorDiagonalData.hasDiagonalLocking === "Sim") {
            if (!segment.inferiorDiagonalData.lockingPoints)
              errors.push(`Pontos de travamento na diagonal inferior no trecho ${index + 1}`);
            if (!segment.inferiorDiagonalData.lockingType)
              errors.push(`Tipo de travamento na diagonal inferior no trecho ${index + 1}`);

            segment.inferiorDiagonalData.bars.forEach((bar, idx) => {
              if (bar.isMissingBar !== true) {
                if (!bar.profileType)
                  errors.push(`Tipo de Perfil do travamento na barra ${idx + 1} na diagonal inferior no trecho ${index + 1}`);
                if (bar.profileSteel === "")
                  errors.push(`Aço do Perfil do travamento na barra ${idx + 1} na diagonal inferior no trecho ${index + 1}`);
                if (!bar.profileDimensions && !bar.profileDiameter && !bar.profileThickness &&
                  !bar.profileFlange && !bar.profileWeb) {
                  errors.push(`Dimensões do Perfil do travamento na barra ${idx + 1} na diagonal inferior no trecho ${index + 1}`);
                } else {
                  if (bar.profileType === "T - Tubo Vazado" && (!bar.profileDiameter || !bar.profileThickness))
                    errors.push(`Dimensões do Perfil do travamento na barra ${idx + 1} na diagonal inferior no trecho ${index + 1}`);
                  if (bar.profileType === "U - Perfil U" && (!bar.profileFlange || !bar.profileWeb || !bar.profileThickness))
                    errors.push(`Dimensões do Perfil do travamento na barra ${idx + 1} na diagonal inferior no trecho ${index + 1}`);
                }
                if (!bar.boltsSteel)
                  errors.push(`Aço dos Parafusos do travamento na barra ${idx + 1} na diagonal inferior no trecho ${index + 1}`);
                if (!bar.numConnectionBolts)
                  errors.push(`Quantidade de parafusos de conexão do travamento na barra ${idx + 1} na diagonal inferior no trecho ${index + 1}`);
                if (!bar.diameterConnectionBolts)
                  errors.push(`Diametro de parafusos de conexão do travamento na barra ${idx + 1} na diagonal inferior no trecho ${index + 1}`);
              }
            });
          }
        }
      }
    });

    if (errors.length > 0) {
      const errorMessage = customToastError(
        errors,
        "Preencha todos os valores corretamente para salvar as informações dos travamentos diagonais. Falta(m):"
      );
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  toJSON() {
    return JSON.stringify(this.diagonalLockings);
  }

  static fromJSON(json) {
    const diagonalLockingsArray = JSON.parse(json);
    return new DiagonalLockingsModel(diagonalLockingsArray);
  }
}

export default DiagonalLockingsModel;
