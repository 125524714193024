import useAsync from "../useAsync";
import * as mastsApi from "../../services/mastApi";

export default function usePostCalculateStrap() {
  const {
    data: strap,
    loading: postCalculateStrapLoading,
    error: postCalculateStrapError,
    act: postCalculateStrap,
  } = useAsync((props, action, token) => mastsApi.postCalculateStrap(props, action, token), false);

  return {
    strap,
    postCalculateStrapLoading,
    postCalculateStrapError,
    postCalculateStrap
  };
}
