import { TopBar } from "../components/topMenu-components/top-bar";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { ProjectContext } from "../contexts/ProjectContext";
import fundacao_big_icon from "../assets/images/fundacao-big-icon.png";
import poste_big_icon from "../assets/images/poste-big-icon.png";
import rooftop_big_icon from "../assets/images/rooftop-big-icon.png";
import torre_big_icon from "../assets/images/torre-big-icon.png";

export function HomePage() {
  const navigate = useNavigate();
  const { setProjectType } = useContext(ProjectContext);
  const projectTypes = [
    { type: "Rooftop", description: "Para estruturas metálicas (mastros) locadas sobre edificações", img: rooftop_big_icon, imgSize: { width: "60px", height: "160px" } },
    { type: "Torre", description: "Utilize para análises e projetos de reforço estruturais de torres metálicas para telecomunicações", img: torre_big_icon, imgSize: { width: "58px", height: "154px" } },
    { type: "Poste", description: "Análises e projetos de reforço para postes metálicos", img: poste_big_icon, imgSize: { width: "60px", height: "156px" } },
    { type: "Fundação", description: "Projetos e análises estruturais para fundações de torres e postes", img: fundacao_big_icon, imgSize: { width: "110px", height: "140px" } },
  ];
  function moveToSelectedPage(projectTypeName) {
    if (projectTypeName === "Rooftop") {
      navigate("/rooftop");
      setProjectType(projectTypeName);
    }
    else if (projectTypeName === "Torre") {
      navigate("/torre");
      setProjectType(projectTypeName);
    }
  }
  useEffect(() => {
    window.localStorage.removeItem("pageProps");
  }, []);

  return (
    <TopBar>
      <Container>
        <Title>SOFTWARES</Title>
        <Subtitle>ESCOLHA COM QUAL SOFTWARE DESEJA TRABALHAR</Subtitle>
        <SubtitleDescription>Encontre aqui todos os softwares de cálculo estrutural que temos em nosso sistema</SubtitleDescription>
        <ProjectTypesContainer>
          {projectTypes.map((projectType) => (
            <ProjectTypeOptionBox
              projectTypeName={projectType.type}
              projectTypeDescription={projectType.description}
              key={projectType.type}
              onClick={() => moveToSelectedPage(projectType.type)}
              imgWidth={projectType.imgSize.width}
              imgHeigth={projectType.imgSize.height}
            >
              <img src={projectType.img} alt=""/>
              <h1>{projectType.type}</h1>
              <h2>{projectType.description}</h2>
            </ProjectTypeOptionBox>
          ))}
        </ProjectTypesContainer>
      </Container>
    </TopBar>
  );
}

export const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 50px);
  background-color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 20px;
  font-family: 'Roboto', sans-serif;
`;

export const Title = styled.div`
  font-size: 28px;
  padding-top: 8px;
  font-weight: 500;
  text-align: center;
  color: #F16E00;
  margin-top: 30px;
`;

const Subtitle = styled.h2`
  font-size: 26px;
  font-weight: 500;
  color: #fff;
  padding-top: 8px;
`;

const SubtitleDescription = styled.h3`
  font-size: 18px;
  font-weight: 400;
  color: #9D9D9D;
  padding-top: 8px;
  margin-bottom: 36px;
`;

const ProjectTypesContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  flex-wrap: wrap;
  margin: 10px 30px;
  @media (min-width: 1600px) {
    width: 76%;
  }
`;

const ProjectTypeOptionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 290px;
  height: 370px;
  border-radius: 16px;
  font-size: 25px;
  font-weight: 400;
  text-align: center;
  color: #000;
  background-color: #fff;
  border: solid 1px #000;
  margin: 6px;
  position: relative;
  transition-duration: 0.1s;
  box-shadow: 0px 3.5px 0px 0px #F16E00;
  cursor: ${(props) =>
    (props.projectTypeName === "Rooftop" || props.projectTypeName === "Torre") ? "pointer" : "not-allowed"};

  :hover {
    transition-duration: 0.1s;
    border: solid 1px #F16E00;
    background-color: #F16E00;
    color: #fff;
  }

  h1 {
    font-size: 26px;
    position: absolute;
    top: 64%;
  }
  h2 {
    font-size: 16px;
    width: 90%;
    line-height: 22px;    
    position: absolute;
    top: 74%;
  }
  img {
    width: ${(props => props.imgWidth)};
    height: ${(props => props.imgHeigth)};
    margin-bottom: 100px;
  }
`;

export const MidTitle = styled.div`
  font-size: 17px;
  width: 100%;
  font-weight: 700;
  text-align: center;
`;

export const Form = styled.form`
  display: grid;
  grid-template-columns: repeat(auto-fill, 165px);
  grid-template-rows: repeat(auto-fill, 80px);
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 80%;
`;

export const Espacing = styled.div`
  margin: 8px;
`;

export const EspacingDiv = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Text = styled.div`
  font-size: 13px;
  width: auto;
  min-height: 14px;
  max-height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-align: start;
`;

export const Input = styled.input`
  width: 252px;
  height: 45px;
  border-radius: 5px;
  border: 1px solid #B3B3B3;
  border-radius: 10px;
`;

export const MiddleInput = styled.input`
  width: 80px;
  height: 45px;
  border-radius: 5px;
  color: ${(props) => props.color};
`;

export const InputCoordinates = styled.input`
  width: 50px;
  height: 45px;
  border-radius: 5px;
  color: ${(props) => props.color};
`;

export const Select = styled.select`
  width: 165px;
  height: 45px;
  border-radius: 5px;
`;

export const MiddleSelect = styled.select`
  width: 85px;
  height: 45px;
  border-radius: 5px;
  color: ${(props) => props.color};
`;

export const SmallSelect = styled.select`
  width: 50px;
  height: 45px;
  border-radius: 5px;
`;

export const LongSelect = styled.select`
  min-width: 180px;
  height: 45px;
  border-radius: 5px;
`;

export const Button = styled.button`
  min-width: 120px;
  font-size: 14px;
  min-height: 45px;
  background: linear-gradient(#F16E00, #F16E00);
  margin: 2px;
  border-radius: 3px;
  padding: 5px;
  color: white;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  &:hover {
    background: linear-gradient( #F16E00,#F92E00);
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
  }
  &:active {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
    background: linear-gradient(#4040ff, #2e2ed2, #4040ff);
  }
`;

export const BlackButton = styled.button `
  width: 252px;
  height: 45px;
  border-radius: 5px;
  background-color: #000;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  &:hover {
    background: linear-gradient(#444,#333);
  }
  &:active {
    background: linear-gradient(#333, #333);
  }
`;

export const ButtonGreen = styled.button`
  min-width: 120px;
  font-size: 14px;
  min-height: 45px;
  background: linear-gradient(#00b300, #00fc00);
  margin: 2px;
  border-radius: 3px;
  padding: 5px;
  color: white;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  &:hover {
    background: linear-gradient(#00fc00, #00b300);
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 1);
  }
  &:active {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
    background: #00b300;
  }
`;

export const ButtonSelected = styled.button`
  min-width: 120px;
  font-size: 14px;
  height: 45px;
  background: linear-gradient(#4040ff, #1e90ff);
  margin: 5px;
  border-radius: 3px;
  padding: 5px;
  color: white;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
`;

export const Response = styled.div`
  font-size: 14px;
  margin: 7px;
  width: 85%;
  color: ${(props) => props.color};
  font-weight: ${(props) => props.weight};
`;

export const Error = styled.div`
  color: #a51b0b;
  font-weight: 700;
  font-size: 16px;
  padding-bottom: 25px;
  margin-top: 10px;
  text-align: center;
`;

export const ResponseContainer = styled.div`
  width: 100%;
  padding-left: 20px;
`;

export const DivImage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rebeccapurple;
`;
