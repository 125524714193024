import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import UserContext from "../contexts/UserContext";
import { errorUserVerification } from "../errors/errorsUser";
import useLogin from "../hooks/api/useSignIn";
import { Input, Espacing, EspacingDiv, Error, BlackButton } from "./home-page";
import zeo_logo from "../assets/images/zeo-logo-blackfont.png";
import fundacao_big_icon from "../assets/images/fundacao-big-icon.png";
import poste_big_icon from "../assets/images/poste-big-icon.png";
import rooftop_big_icon from "../assets/images/rooftop-big-icon.png";
import torre_big_icon from "../assets/images/torre-big-icon.png";

export function LoginPage() {
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState({ password: "", email: "" });
  const { signInLoading, signIn } = useLogin();
  const [errorMessage, setErrorMessage] = useState([]);
  const { setUserData } = useContext(UserContext);
  const [isPasswordShown, setIsPasswordShown] = useState(false);

  function preventAtt(event) {
    event.preventDefault();
  }

  async function submitLogin() {
    try {
      const userInfo = await signIn(loginInfo);
      setUserData(userInfo);
      navigate("/");
    } catch (error) {
      return errorUserVerification(error, setErrorMessage);
    }
  }

  function toggleShowPassword() {
    setIsPasswordShown(!isPasswordShown);
  }

  return (
    <FullPageContainer>
      <BlackLeftContainer>
        <h1>Software online para análises e projetos estruturais para infraestrutura de telecomunicações</h1>
        <h2>Uma ferramenta poderosa com uma interface intuitiva que possibilita análises precisas e eficientes.
          Reunimos softwares para todos os tipos de estruturas metálicas e de concreto para o segmento, simplificando o processo de cálculo e promovendo estruturas sólidas e confiáveis.</h2>
        <FourBallsDesignContainer>
          <ImgCircularContainer width="40%" height="70%">
            <img src={rooftop_big_icon} alt="Rooftop Icon" />
          </ImgCircularContainer>
          <ImgCircularContainer width="40%" height="70%">
            <img src={torre_big_icon} alt="Torre Icon" />
          </ImgCircularContainer>
          <ImgCircularContainer width="40%" height="70%">
            <img src={poste_big_icon} alt="Poste Icon" />
          </ImgCircularContainer>
          <ImgCircularContainer width="64%" height="59%">
            <img src={fundacao_big_icon} alt="Fundação Icon" />
          </ImgCircularContainer>
        </FourBallsDesignContainer>
      </BlackLeftContainer>
      <WhiteRightContainer>
        <Form onSubmit={preventAtt}>
          <a href="http://www.kk.eng.br/" target="_blank">
            <Img src={zeo_logo} />
          </a>
          <Espacing><Input type="email" placeholder="E-mail" onChange={(event) => { setLoginInfo({ ...loginInfo, email: event.target.value }); }} /></Espacing>
          <Espacing>
            <PasswordInputContainer>
              <Input type={!isPasswordShown ? "password" : "text"} placeholder="Senha" onChange={(event) => { setLoginInfo({ ...loginInfo, password: event.target.value }); }}>
              </Input>
              <ion-icon onClick={toggleShowPassword} name={isPasswordShown ? "eye-outline" : "eye-off-outline"}></ion-icon>
            </PasswordInputContainer>
          </Espacing>
          <RightAlignedDiv onClick={() => { navigate("/changePassword"); }}>esqueci minha senha</RightAlignedDiv>
          <EspacingDiv>
            {signInLoading ? <BlackButton>Carregando...</BlackButton>
              : <BlackButton onClick={submitLogin} disabled={signInLoading}>Entrar</BlackButton>}
          </EspacingDiv>
          <Error>{errorMessage}</Error>
          <CentralAlignedDiv onClick={() => { navigate("/logup"); }}>Não tem conta? <span>Registre-se</span></CentralAlignedDiv>
        </Form>
      </WhiteRightContainer>
    </FullPageContainer>
  );
}

export const FullPageContainer = styled.div` 
  display: flex;
  width: 100vw;
  height: 100vh;
`;

export const BlackLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 63%;
  height: 100%;
  background-color: #212121;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: 'Roboto', sans-serif;

  h1 {
    width: 60%;
    color: #fff;
    font-size: 34px;
    line-height: 40px;
    margin-bottom: 40px;
  }
  h2 {
    width: 70%;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
  }
`;

export const FourBallsDesignContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 70%;
  margin-top: 40px;
`;

export const ImgCircularContainer = styled.div`
  display: flex;
  width: 176px;
  height: 176px;
  background-color: #292929;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
    width: ${(props) => props.width};
    height: ${(props) => props.height};
  }
`;

export const WhiteRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 37%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: rgb(0,0,0);
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${props => props.img});
  background-size: cover;
  color: white;
`;

export const PasswordInputContainer = styled.div`
display: flex;
position: relative;

ion-icon {
  position: absolute;
  right: 10px;
  top: 1.1vh;
  font-size: 24px;
  z-index: 2;
  color: #999;
  cursor: pointer;
}
`;

export const Img = styled.img`
  height: 55px;
  margin: 15px;
  border-radius: 5px;
`;

export const LeftContainer = styled.div`
  width: 60%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RightContainer = styled.div`
  width: 40%;
  min-height: 100vh;
  background-color: rgb(105,176,230);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 400px;
  min-height: 380px;
  padding: 10px;
  /* background-color: rgba(0,0,0,0.8); */
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-family: 'Roboto' !important;

  input {
    padding-left: 8px;
  }
`;

export const CentralAlignedDiv = styled.div`
  /*font-family: "Lexend Deca", sans-serif*/
  font-family: 'Roboto', sans-serif;;
  padding: 5px;
  cursor: pointer;
  font-weight: 300;
  color: #888888;
  margin-top: -10px;
  span {
    font-weight: 600;
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const RightAlignedDiv = styled.div`
  /*font-family: "Lexend Deca", sans-serif*/
  font-family: 'Roboto', sans-serif;;
  cursor: pointer;
  font-weight: 300;
  text-align: right;
  width: 252px;
  color: #888888;
  font-size: 14px;
  padding-top: 4px;
  &:hover {
    text-decoration: underline;
  }
`;
