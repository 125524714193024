import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { defaultError } from "../errors/default-error";
import { errorUserVerification } from "../errors/errorsUser";
import useConfirmEmail from "../hooks/api/useConfirmEmail";
import useUpdatePassword from "../hooks/api/useUpdatePassword";
import {
  Input,
  Espacing,
  EspacingDiv,
  Error,
  BlackButton,
} from "./home-page";
import { CentralAlignedDiv, Img, Form, FullPageContainer, BlackLeftContainer, FourBallsDesignContainer, WhiteRightContainer, PasswordInputContainer, ImgCircularContainer } from "./login-page";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import zeo_logo from "../assets/images/zeo-logo-blackfont.png";
import fundacao_big_icon from "../assets/images/fundacao-big-icon.png";
import poste_big_icon from "../assets/images/poste-big-icon.png";
import rooftop_big_icon from "../assets/images/rooftop-big-icon.png";
import torre_big_icon from "../assets/images/torre-big-icon.png";

export function UpdatePasswordPage() {
  const navigate = useNavigate();
  const [updatePasswordInfo, setUpdatePasswordInfo] = useState({
    confirmationType: "forgottenPassword",
    password: "",
    confirmPassword: "",
  });
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isRepeatedPasswordShown, setIsRepeatedPasswordShown] = useState(false);
  const { confirmEmailLoading, confirmEmail } = useConfirmEmail();
  const { updatePasswordLoading, updatePassword } = useUpdatePassword();
  const [errorMessage, setErrorMessage] = useState([]);
  const [verificationSent, setVerificationSent] = useState(false);

  function preventAtt(event) {
    event.preventDefault();
  }

  async function submitConfirmationEmail(event) {
    event.preventDefault();
    setErrorMessage([]);
    try {
      setUpdatePasswordInfo({ ...updatePasswordInfo });
      await confirmEmail(
        updatePasswordInfo.email,
        updatePasswordInfo.confirmationType
      );
      setVerificationSent(true);
    } catch (error) {
      console.log(error);
      setVerificationSent(false);
      return errorUserVerification(error, setErrorMessage);
    }
  }

  async function submitUpdatePassword(event) {
    event.preventDefault();
    try {
      if (updatePasswordInfo.confirmPassword !== updatePasswordInfo.password)
        throw defaultError("Senhas não conferem");
      await updatePassword(updatePasswordInfo);
      toast.success("Senha atualizada com sucesso!");
      navigate("/login");
    } catch (error) {
      if (
        error.message &&
        error.request &&
        Number(error.request.status) !== 400
      )
        toast.error(error.message);
      return errorUserVerification(error, setErrorMessage);
    }
  }

  function toggleShowPassword() {
    setIsPasswordShown(!isPasswordShown);
  }

  function toggleShowRepeatedPassword() {
    setIsRepeatedPasswordShown(!isRepeatedPasswordShown);
  }

  return (
    <FullPageContainer >
      <BlackLeftContainer>
        <h1>Software online para análises e projetos estruturais para infraestrutura de telecomunicações</h1>
        <h2>Uma ferramenta poderosa com uma interface intuitiva que possibilita análises precisas e eficientes.
Reunimos softwares para todos os tipos de estruturas metálicas e de concreto para o segmento, simplificando o processo de cálculo e promovendo estruturas sólidas e confiáveis.</h2>
        <FourBallsDesignContainer>
          <ImgCircularContainer width="40%" height="70%">
            <img src={rooftop_big_icon} alt="Rooftop Icon"/>
          </ImgCircularContainer>
          <ImgCircularContainer width="40%" height="70%">
            <img src={torre_big_icon} alt="Torre Icon"/>
          </ImgCircularContainer>
          <ImgCircularContainer width="40%" height="70%">
            <img src={poste_big_icon} alt="Poste Icon"/>
          </ImgCircularContainer>
          <ImgCircularContainer width="64%" height="59%">
            <img src={fundacao_big_icon} alt="Fundação Icon"/>
          </ImgCircularContainer>
        </FourBallsDesignContainer>
      </BlackLeftContainer>
      <WhiteRightContainer>
        <Form onSubmit={preventAtt}>
          <a href="http://www.kk.eng.br/" target="_blank">
            <Img src={zeo_logo}/>
          </a>
          <Title>
            Digite o email cadastrado para receber seu código de verificação:{" "}
          </Title>
          <Espacing>
            <Input type="text" placeholder="E-mail" onChange={(event) => { setUpdatePasswordInfo({ ...updatePasswordInfo,           email: event.target.value, }); }}/>
          </Espacing>
          <ButtonEspacingDiv>
            {confirmEmailLoading ? (
              <BlackButton>Carregando...</BlackButton>
            ) : (
              <BlackButton  onClick={submitConfirmationEmail} disabled={confirmEmailLoading} >
                Enviar verificação
              </BlackButton>
            )}
          </ButtonEspacingDiv>
          {verificationSent ? (
            <>
              <Espacing>
                <Input type="text" required={true} placeholder="Código de verificação" onChange={(event) => { setUpdatePasswordInfo({ ...updatePasswordInfo, validationNumber: event.target.value, }); }} />
              </Espacing>
              <>
                <Espacing>
                  <PasswordInputContainer>
                    <Input type={!isPasswordShown ? "password" : "text"} placeholder="Nova Senha" onChange={(event) => {setUpdatePasswordInfo({ ...updatePasswordInfo, password: event.target.value });}}>
                    </Input>
                    <ion-icon onClick={toggleShowPassword} name={isPasswordShown ? "eye-outline" : "eye-off-outline"}></ion-icon>
                  </PasswordInputContainer>
                </Espacing>
                <Espacing>
                  <PasswordInputContainer>
                    <Input type={!isRepeatedPasswordShown ? "password" : "text"} placeholder="Repita a nova senha" onChange={(event) => {setUpdatePasswordInfo({ ...updatePasswordInfo, confirmPassword: event.target.value });}}>
                    </Input>
                    <ion-icon onClick={toggleShowRepeatedPassword} name={isRepeatedPasswordShown ? "eye-outline" : "eye-off-outline"}> </ion-icon>
                  </PasswordInputContainer>
                </Espacing>
              </>
              <EspacingDiv>
                {confirmEmailLoading ? (
                  <BlackButton>Carregando...</BlackButton>
                ) : (
                  <BlackButton onClick={submitUpdatePassword} disabled={updatePasswordLoading}>
                    Atualizar senha
                  </BlackButton>
                )}
              </EspacingDiv>
            </>
          ) : (
            ""
          )}

          <Error>{errorMessage}</Error>
          <CentralAlignedDiv
            onClick={() => {
              navigate("/login");
            }}
          >
            Voltar à página de login
          </CentralAlignedDiv>
        </Form>
      </WhiteRightContainer>
    </FullPageContainer>
  );
}

const ButtonEspacingDiv = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Title = styled.div `
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin: 14px;
  width: 96%;
  text-align: center;
  color: #F16E00;
`;
