import useAsync from "../useAsync";
import * as mastApi from "../../services/mastApi";

export default function useUpdateMast() {
  const {
    data: mast,
    loading: updateMastLoading,
    error: updateMastError,
    act: updateMast,
  } = useAsync((props, token) => mastApi.updateMast(props, token), false);

  return {
    mast,
    updateMastLoading,
    updateMastError,
    updateMast
  };
}
