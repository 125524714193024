import api from "../apiTower";

const makeHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const deleteTowerProject = async(projectId, token) => {
  const response = await api
    .delete(`/projects/delete/${encodeURIComponent(projectId)}`, makeHeaders(token));
  return response.data;
};

export const editTowerProjectName = async(newProjectName, projectId, token) => {
  const parsedBody = JSON.parse(JSON.stringify({ newName: newProjectName }));

  const response = await api
    .put(`/projects/updateName/${encodeURIComponent(projectId)}`, parsedBody, makeHeaders(token));
  return response.data;
};
