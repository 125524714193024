import useAsync from "../../useAsync";
import { getHorizontals, postHorizontals, updateHorizontals } from "../../../services/tower-services/towerHorizontalsApi";

export const useTowerHorizontals= (action) => {
  const execute = {
    get: getHorizontals,
    post: postHorizontals,
    update: updateHorizontals,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
