import { useState } from "react";
import styled from "styled-components";
import ReportingBugDialog from "../common/bugReportingDialog";

export default function MenuInfoOptions() {
  const [isMenuOpen, setIsMenuOpen] = useState("");
  const handleMouseEnter = (menu) => {
    setIsMenuOpen(menu);
  };

  const handleMouseLeave = () => {
    setIsMenuOpen(false);
  };

  const [confirmDialog, setConfirmDialog] = useState({
    type: "",
    isOpen: false,
  });

  return (
    <MenuOptionSelectorContainer isClicked={isMenuOpen} onMouseEnter={() => handleMouseEnter("menu-options")}
      onMouseLeave={() => handleMouseLeave("menu-options")}>
      <QuestionMarkIconContainer>
        <ion-icon name="help-circle"></ion-icon>
      </QuestionMarkIconContainer>
      {isMenuOpen === "menu-options" && <InfoTopMenuOptions type="menu-options" isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />}
      <ReportingBugDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        setIsMenuOpen={setIsMenuOpen}
      />
    </MenuOptionSelectorContainer>
  );
}

function InfoTopMenuOptions({ setIsMenuOpen, type, confirmDialog, setConfirmDialog }) {
  const handleMouseEnter = () => {
    setIsMenuOpen(type);
  };

  return (
    <MenuContainer
      onMouseEnter={handleMouseEnter}
    >
      <MenuOption title={"Documentação"} iconName={"document-text-outline"} cursor={"inherit"} />
      <MenuOption title={"Reportar um bug"} iconName={"bug-outline"} cursor={"pointer"} onClickFunction={() => setConfirmDialog({ ...confirmDialog, isOpen: true })} />
    </MenuContainer>
  );
}

function MenuOption({ title, iconName, onClickFunction, cursor }) {
  const [isMouseOn, setIsMouseOn] = useState(false);
  const handleMouseEnter = () => {
    setIsMouseOn(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOn(false);
  };
  return (
    <MenuOptionContainer cursor={cursor} onClick={onClickFunction} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} isMouseOn={isMouseOn}>
      <ion-icon name={iconName}></ion-icon>
      <div>{title}</div>
    </MenuOptionContainer>
  );
}

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  right: 0;
  height: auto;
  width: 230px;
  z-index: 5;
  background-color: #d0cece;
  border-bottom-left-radius: 10px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
`;

const MenuOptionContainer = styled.div`
  display: flex;
  padding: 0 16px;
  align-items: center;
  justify-content: flex-start;
  height: 46px;
  margin-right: 20px;
  background-color: ${(props) => (props.isMouseOn ? "#F16E00" : "#d0cece")};
  :nth-last-child(1) {
    border-bottom-left-radius: 10px;
  }
  width: 100%;
  cursor: ${(props) => props.cursor};

  div, ion-icon {
    font-family: "Open-sans", sans-serif;
    font-size: 17px;
    color: #000;
    font-weight: 300;
    margin-left: 6px;
  }
  ion-icon {
    font-size: 19px;
    margin-right: 12px;
  }
`;

const MenuOptionSelectorContainer = styled.div`
  height: 50px;
  width: ${(props) => (props.isLast ? "68px" : "62px")};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isClicked ? "#d0cece" : "#ffffff")};
`;

const QuestionMarkIconContainer = styled.div`
  ion-icon {
    font-size: 38px;
    color: #000000;
  }
`;
