import styled from "styled-components";
import { LeftTitle, ButtonPositioner, ReturnButton, InfoDiv, InformationIconContainer, ReturnIcon, AdvanceIcon } from "../../project-components/wind-components/project-windData";
import { MenuOption, MenuDoubleOption } from "../module-components/mast-moduleData";
import { useContext, useState, useEffect } from "react";
import UserContext from "../../../contexts/UserContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import SavingButton from "../../common/saving-button";
import LoadingButton from "../../common/loading-button";
import useUpdateMast from "../../../hooks/api/useUpdateMast";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useUnsavedChangesAlert from "../../../hooks/useUnsavedChangesAlert";
import ResizableLeftContainer from "../../common/resizableLeftContainer";
import ResizableRightContainer from "../../common/resizableRightContainer";
import CreatableSelect from "react-select/creatable";
import EstaiRightContainer from "./mast-estaiRightContainer";
import { ProjectNameWrapper } from "../geometry-components/mast-geometryData";

export default function MastEstaiPage() {
  const { setLeftMenuOptionSelected, mastSelected, projectSelected, setMastSelected, reloadProject, setReloadProject, setIsUpdatedWithoutCalculation, setProjectNameSelected, setProjectSelected } = useContext(ProjectContext);
  const { userData } = useContext(UserContext);
  const { updateMast, updateMastLoading } = useUpdateMast();
  const mastProps = JSON.parse(mastSelected.props);
  const projectProps = JSON.parse(projectSelected.props);
  const [showInfo, setShowInfo] = useState({ isShown: false, infoType: "" });
  const [estaiSelectedInMenu, setEstaiSelectedInMenu] = useState(1);
  const [inputValues, setInputValues] = useState({
    estaiSelected: 1,
    estai1AlturaFixacaoMastro: mastProps?.estai1AlturaFixacaoMastro || "",
    estai1Perfil: mastProps?.estai1Perfil || "",
    estai1TipoPerfilCantoneira: mastProps?.estai1TipoPerfilCantoneira || "",
    estai1TipoPerfilU: mastProps?.estai1TipoPerfilU || "",
    estai1Aba: mastProps?.estai1Aba || "",
    estai1Espessura: mastProps?.estai1Espessura || "",
    estai1ParafusosDiametro: mastProps?.estai1ParafusosDiametro || "",
    estai1ParafusosNumero: mastProps?.estai1ParafusosNumero || "",
    estai1ParafusosAco: mastProps?.estai1ParafusosAco || "",
    estai1PerfisLaminados: mastProps?.estai1PerfisLaminados || "",
    estai1LocacaoX1: mastProps?.estai1LocacaoX1 || "",
    estai1LocacaoX2: mastProps?.estai1LocacaoX2 || "",
    estai1LocacaoX3: mastProps?.estai1LocacaoX3 || "",
  });
  const fieldsToCheck = [[`estai${estaiSelectedInMenu}AlturaFixacaoMastro`], [`estai${estaiSelectedInMenu}Diametro`], [`estai${estaiSelectedInMenu}Espessura`], [`estai${estaiSelectedInMenu}Perfil`], [`estai${estaiSelectedInMenu}TipoPerfilCantoneira`], [`estai${estaiSelectedInMenu}TipoPerfilU`], [`estai${estaiSelectedInMenu}ParafusosDiametro`], [`estai${estaiSelectedInMenu}ParafusosAco`], [`estai${estaiSelectedInMenu}PerfisLaminados`], [`estai${estaiSelectedInMenu}ParafusosNumero`], [`estai${estaiSelectedInMenu}Aba`], [`estai${estaiSelectedInMenu}LocacaoX1`], [`estai${estaiSelectedInMenu}LocacaoX2`], [`estai${estaiSelectedInMenu}LocacaoX3`]];
  if(mastProps.tipoMastro === "MI") {
    fieldsToCheck.push(...[["estaiMItipoAncoragem"], ["espessuraParedeMI"], [`estai${estaiSelectedInMenu}Alma`]]);
  }
  const isInputModified = useUnsavedChangesAlert(inputValues, mastProps, reloadProject, fieldsToCheck);
  const estaisByModules = { MA: 0, MB: 2, MC: 4, MD: 4, ME: 5, MF: 6, MG: 7, MH: 1, MI: 2 };
  const estaisPerfis = ["Cantoneira Simples (L)", "Cantoneira Dupla (2L)", "Tubo (T)"];
  const dimensoesTubos = [  {
    key: "ASTM A36", values: [
      { "10.3": ["1.7", "2.4"] },
      { "13.7": ["2.2", "3"] },
      { "17.1": ["2.3", "3.2"] },
      { "21.3": ["2.8", "3.7", "4.8", "7.5"] },
      { "26.7": ["2.9", "3.9", "5.6", "7.8"] },
      { "33.4": ["3.4", "4.5", "6.4", "9.1"] },
      { "42.2": ["3.6", "4.8", "6.4", "9.7"] },
      { "48.3": ["3.7", "5.1", "7.1", "10.2"] },
      { "60.3": ["3.9", "5.5", "8.7", "11.1"] },
      { "73": ["5.2", "7", "9.5", "14"] },
      { "88.9": ["3.2", "4", "4.8", "5.5", "6.4", "7.1", "7.6", "11.1", "15.2"] },
      { "101.6": ["8.1", "3.2", "4", "4.8", "5.7", "6.4", "7.1"] },
      { "114.3": ["3.2", "4", "4.8", "5.6", "6", "6.4", "7.1", "7.9", "8.6", "11.1", "13.5", "17.1"] },
      { "141.3": ["4", "4.8", "5.6", "6.6", "7.1", "7.9", "8.7", "9.5", "12.7", "15.9", "19"] },
      { "168.3": ["4.8", "5.6", "6.4", "7.1", "7.9", "8.7", "9.5", "11", "14.3", "18.3", "21.9"] },
      { "219.1": ["4.8", "5.2", "5.6", "6.4", "7", "7.9", "8.2", "8.7", "9.5", "10.3", "11.1", "12.7", "15.1", "18.3", "20.6", "22.2", "23", "26.3", "29.5", "32.6", "33.3"] },
      { "273": ["4.8", "5.2", "5.6", "6.4", "7.1", "7.8", "8.7", "9.3", "11.1", "12.7", "15.1", "18.3", "21.4", "25.4", "28.6"] },
      { "323.8": ["5.2", "5.6", "6.4", "7.1", "7.9", "8.4", "8.7", "9.5", "11.1", "12.7", "14.3", "15.9", "17.5", "19", "20.6", "22.2", "23.8", "26.2", "29.4", "32.5", "33.3"] },
      { "355.6": ["5.3", "5.6", "6.4", "7.1", "7.9", "8.7", "9.5", "11.1", "11.9", "12.7", "15.1", "19", "23.8", "27.8", "31.8", "35.7", "50.8", "54", "55.9", "63.5"] }
    ] },
  {    key: "DIN 2440", values: [
    { "13.5": ["2.35"] },
    { "17.2": ["2.35"] },
    { "21.3": ["2.25", "2,65", "3"] },
    { "26.9": ["2.25", "2.65", "3"] },
    { "33.7": ["2.65", "3.35", "3.75"] },
    { "42.4": ["2.65", "3.35", "3.75"] },
    { "48.3": ["3", "3.35", "3.75"] },
    { "60.3": ["3", "3.75", "4.5"] },
    { "76.1": ["3.35", "3.75", "4.5"] },
    { "88.9": ["3.35", "4", "4.5"] },
    { "101.6": ["3.75", "4.25", "5"] },
    { "114.3": ["3.75", "4.5", "5.6"] },
    { "139.7": ["4.75", "5.6"] },
    { "165.1": ["5", "5.6"] },
    { "219.1": ["6.3"] },
  ]
  },
  {
    key: "SCHEDULE 40", values: [
      { "21.3": ["2.77"] },
      { "26.7": ["2.87"] },
      { "33.4": ["3.38"] },
      { "42.2": ["3.56"] },
      { "48.3": ["3.68"] },
      { "60.3": ["3.91"] },
      { "73": ["5.16"] },
      { "88.9": ["5.49"] },
      { "101.6": ["5.74"] },
      { "114.3": ["6.02"] },
      { "141.3": ["6.55"] },
      { "168.3": ["7.11"] },
      { "219.1": ["8.18"] },
      { "273.05": ["9.27"] },
      { "323.84": ["10.31"] }
    ]
  },
  {
    key: "SCHEDULE 80", values: [
      { "21.3": ["2.77"] },
      { "26.7": ["2.87"] },
      { "33.4": ["3.38"] },
      { "42.2": ["3.56"] },
      { "48.3": ["3.68"] },
      { "60.3": ["3.91"] },
      { "73": ["5.16"] },
      { "88.9": ["5.49"] },
      { "101.6": ["5.74"] },
      { "114.3": ["6.02"] },
      { "141.3": ["6.55"] },
      { "168.3": ["7.11"] },
      { "219.1": ["8.18"] },
      { "273.05": ["9.27"] },
      { "323.84": ["10.31"] }
    ]
  },
  ];
  const [abasEEspessurasProjeto, setAbasEEspessurasProjeto] = useState({ abas: [], espessuras: [] });
  const abasEstaisLou2L = ["25,4", "31,8", "38,1", "44,5", "50,8", "63,5", "76,2", "101,6", "127", "152,4"];
  const espessurasEstaisLou2L = ["3.2", "4.8", "6.4", "7.9", "9.5", "12.7", "15.9", "19"];
  const perfisCantoneiraLe2L = ["L 25.4x3.2mm", "L 25.4x4.8mm", "L 25.4x6.4mm", "L 31.8x3.2mm", "L 31.8x4.8mm", "L 31.8x6.4mm", "L 38.1x3.2mm", "L 38.1x4.8mm", "L 38.1x6.4mm", "L 44.5x3.2mm", "L 44.5x4.8mm", "L 44.5x6.4mm", "L 50.8x3.2mm", "L 50.8x4.8mm", "L 50.8x6.4mm", "L 50.8x7.9mm", "L 50.8x9.5mm", "L 63.5x4.8mm", "L 63.5x6.4mm", "L 63.5x7.9mm", "L 63.5x9.5mm", "L 76.2x4.8mm", "L 76.2x6.4mm", "L 76.2x7.9mm", "L 76.2x9.5mm", "L 76.2x12.7mm", "L 88.9x6.4mm", "L 88.9x7.9mm", "L 88.9x9.5mm", "L 101.6x6.4mm", "L 101.6x7.9mm", "L 101.6x9.5mm", "L 101.6x12.7mm", "L 101.6x15.9mm", "L 127x6.4mm", "L 127x7.9mm", "L 127x9.5mm", "L 127x12.7mm", "L 127x15.9mm", "L 127x19mm", "L 152.4x7.9mm", "L 152.4x9.5mm", "L 152.4x12.7mm", "L 152.4x15.9mm", "L 152.4x19mm"];
  const perfisU = ["U 76 x 6,1mm", "U 76 x 7,4mm", "U 102 x 8,0mm", "U 102 x 9,3mm", "U 102 x 10,8mm", "U 152 x 12,2mm", "U 152 x 15,6mm", "U 152 x 19,4mm", "U 203 x 17,1mm", "U 203 x 20,5mm", "U 254 x 22,8mm", "U 254 x 29,8mm", "U 305 x 30,8mm", "U 305 x 37,0mm"];
  // eslint-disable-next-line quotes
  const parafusosEstai = ['3/8" - 9.5mm', '1/2" - 12.7mm', '5/8" - 15.9mm', '3/4" - 19.05mm', '7/8" - 22.2mm', '1" - 25.4mm', '1 1/4" - 31.8mm', '1 5/16" - 33.3mm', '1 3/8" - 34.9mm', '1 1/2" - 38.1mm', '1 5/8" - 41.3mm', '1 3/4" - 44.45mm', '1 7/8" - 47.6mm', '2" - 50.8mm', '2 1/4" - 57.2mm', '2 5/16" - 58.7mm', '2 3/8" - 60.3mm', '2 1/2" - 63.5mm', '2 5/8" - 66.7mm', '2 3/4" - 69.85mm', '2 7/8" - 73mm'];
  const tiposDeAçoPerfisEChapas = [
    "ASTM A36",
    "ASTM A570 G36",
    "ASTM A572 G42",
    "ASTM A572 G50",
    "ASTM A572 G60",
    "ASTM A588",
    "COS AR COR 40",
    "COS AR COR 50",
    "COS AR COR 400",
    "COS AR COR 420",
    "COS AR COR 500",
    "DIN 2440",
    "SCHEDULE 40",
    "SCHEDULE 80",
    "USI SAC 300",
    "USI SAC 350",
  ];
  const tiposDeAçoParafusos = [
    "ASTM A307",
    "ASTM A394",
    "ASTM A325",
    "ASTM A490",
    "DIN 8.8",
  ];
  const [estaiSelectedBackgroundColor, setEstaiSelectedBackgroundColor] = useState("#F16E00");

  useEffect(() => {
    if (inputValues[`estai${inputValues.estaiSelected}Perfil`] === "Tubo (T)") {
      if(inputValues[`estai${inputValues.estaiSelected}LocacaoX1`] === undefined) {
        inputValues[`estai${inputValues.estaiSelected}LocacaoX1`] = 0;
      }
      if(inputValues[`estai${inputValues.estaiSelected}LocacaoX2`] === undefined) {
        inputValues[`estai${inputValues.estaiSelected}LocacaoX2`] = 0;
      }
      if(inputValues[`estai${inputValues.estaiSelected}LocacaoX3`] === undefined) {
        inputValues[`estai${inputValues.estaiSelected}LocacaoX3`] = 0;
      }
      const tuboSelecionado = projectProps.tubos || mastProps.modulo1Tubos || inputValues[`estai${estaiSelectedInMenu}PerfisLaminados`] || "";
      const objetoTubo = dimensoesTubos.find(item => item.key === tuboSelecionado);
      const novasAbas = [];
      objetoTubo?.values.forEach(item => {
        novasAbas.push(Object.keys(item)[0]);
      });
      setAbasEEspessurasProjeto( { espessuras: [], abas: novasAbas });
      if (inputValues[`estai${inputValues.estaiSelected}Aba`]) {
        let novasEspessuras = [];
        objetoTubo.values.forEach(item => {
          if (Object.keys(item)[0] === inputValues[`estai${inputValues.estaiSelected}Aba`]) {
            Object.values(item)[0].forEach(el => {
              novasEspessuras.push(el);
            });
          }
        });
        setAbasEEspessurasProjeto( { abas: novasAbas, espessuras: novasEspessuras } );
      }
    } else {
      setAbasEEspessurasProjeto( { espessuras: [], abas: abasEstaisLou2L } );
      if (inputValues[`estai${inputValues.estaiSelected}Aba`]) {
        setAbasEEspessurasProjeto( { abas: abasEstaisLou2L, espessuras: espessurasEstaisLou2L } );
      }
    }
  }, [inputValues[`estai${inputValues.estaiSelected}Perfil`], inputValues[`estai${inputValues.estaiSelected}Aba`], estaiSelectedInMenu]);
  
  function correctEstaiPerfilByGettingItemBetweenParentheses(str) {
    const regex = /\(([^)]+)\)/;
    const matches = regex.exec(str); 
    if (matches && matches.length >= 2) {
      return matches[1]; 
    }
    return str; 
  }

  useEffect(() => {
    zeroEstaiLocations();
  }, [inputValues.estaiSelected]);

  function zeroEstaiLocations() {
    let [x1, x2, x3] = ["0", "0", "0"];
    if(!inputValues[`estai${inputValues.estaiSelected}LocacaoX1`] || inputValues[`estai${inputValues.estaiSelected}LocacaoX1`] === "undefined") {
      x1 = "0";
    } else {
      x1 = inputValues[`estai${inputValues.estaiSelected}LocacaoX1`];
    }
    if(!inputValues[`estai${inputValues.estaiSelected}LocacaoX2`] || inputValues[`estai${inputValues.estaiSelected}LocacaoX2`] === "undefined") {
      x2 = "0";
    } else {
      x2 = inputValues[`estai${inputValues.estaiSelected}LocacaoX2`];
    }
    if(!inputValues[`estai${inputValues.estaiSelected}LocacaoX3`] || inputValues[`estai${inputValues.estaiSelected}LocacaoX3`] === "undefined") {
      x3 = "0";
    } else {
      x3 = inputValues[`estai${inputValues.estaiSelected}LocacaoX3`];
    }
    setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}LocacaoX1`]: x1, [`estai${inputValues.estaiSelected}LocacaoX2`]: x2, [`estai${inputValues.estaiSelected}LocacaoX3`]: x3 });
  }

  function setEstaiLocations(value, xNumber) {
    inputValues[`estai${inputValues.estaiSelected}Locacao${xNumber}`] = value;
    setInputValues({ ...inputValues });
  }

  useEffect(() => {
    const newInputValues = {
      estaiSelected: estaiSelectedInMenu,
      [`estai${estaiSelectedInMenu}AlturaFixacaoMastro`]: mastProps?.[`estai${estaiSelectedInMenu}AlturaFixacaoMastro`] || "",
      [`estai${estaiSelectedInMenu}Alma`]: mastProps?.[`estai${estaiSelectedInMenu}Alma`] || "",
      [`estai${estaiSelectedInMenu}Perfil`]: mastProps?.[`estai${estaiSelectedInMenu}Perfil`] || mastProps?.[`estai${estaiSelectedInMenu-1}Perfil`] || mastProps?.["estai1Perfil"] || "",
      [`estai${estaiSelectedInMenu}TipoPerfilCantoneira`]: mastProps?.[`estai${estaiSelectedInMenu}TipoPerfilCantoneira`] || mastProps?.[`estai${estaiSelectedInMenu - 1}TipoPerfilCantoneira`] || mastProps?.["estai1TipoPerfilCantoneira"] || "",
      [`estai${estaiSelectedInMenu}TipoPerfilU`]: mastProps?.[`estai${estaiSelectedInMenu}TipoPerfilU`] || mastProps?.["estai1TipoPerfilU"] || "",
      [`estai${estaiSelectedInMenu}Aba`]: mastProps?.[`estai${estaiSelectedInMenu}Aba`] || mastProps?.[`estai${estaiSelectedInMenu - 1}Aba`] || mastProps?.["estai1Aba"] || "",
      [`estai${estaiSelectedInMenu}Espessura`]: mastProps?.[`estai${estaiSelectedInMenu}Espessura`] || mastProps?.[`estai${estaiSelectedInMenu - 1}Espessura`] || mastProps?.["estai1Espessura"] || "",
      [`estai${estaiSelectedInMenu}ParafusosDiametro`]: mastProps?.[`estai${estaiSelectedInMenu}ParafusosDiametro`] || mastProps?.[`estai${estaiSelectedInMenu - 1}ParafusosDiametro`] || mastProps?.["estai1ParafusosDiametro"] || "",
      [`estai${estaiSelectedInMenu}ParafusosNumero`]: mastProps?.[`estai${estaiSelectedInMenu}ParafusosNumero`] || mastProps?.["estai1ParafusosNumero"] || "",
      [`estai${estaiSelectedInMenu}ParafusosAco`]: mastProps?.[`estai${estaiSelectedInMenu}ParafusosAco`] || mastProps?.[`estai${estaiSelectedInMenu - 1}ParafusosAco`] || mastProps?.["estai1ParafusosAco"] || "",
      [`estai${estaiSelectedInMenu}PerfisLaminados`]: mastProps?.[`estai${estaiSelectedInMenu}PerfisLaminados`] || mastProps?.["estai1PerfisLaminados"] || "",
      [`estai${estaiSelectedInMenu}LocacaoX1`]: mastProps?.[`estai${estaiSelectedInMenu}LocacaoX1`] || mastProps?.[`estai${estaiSelectedInMenu - 1}LocacaoX1`] || mastProps?.[`estai${estaiSelectedInMenu}LocacaoX1`] === 0 ? String(mastProps?.[`estai${estaiSelectedInMenu}LocacaoX1`]) : "0",
      [`estai${estaiSelectedInMenu}LocacaoX2`]: mastProps?.[`estai${estaiSelectedInMenu}LocacaoX2`] || mastProps?.[`estai${estaiSelectedInMenu - 1}LocacaoX2`] || mastProps?.[`estai${estaiSelectedInMenu}LocacaoX2`] === 0 ? String(mastProps?.[`estai${estaiSelectedInMenu}LocacaoX2`]) : "0",
      [`estai${estaiSelectedInMenu}LocacaoX3`]: mastProps?.[`estai${estaiSelectedInMenu}LocacaoX3`] || mastProps?.[`estai${estaiSelectedInMenu -1}LocacaoX3`] || mastProps?.[`estai${estaiSelectedInMenu}LocacaoX3`] === 0 ? String(mastProps?.[`estai${estaiSelectedInMenu}LocacaoX3`]) : "0",
    };
    setInputValues(newInputValues);
  }, [estaiSelectedInMenu]);

  async function updateMastEstaiData() {
    try {
      let whatIsMissingMessage = "";
      const locacaoEstaiX1 = inputValues[`estai${inputValues.estaiSelected}LocacaoX1`];
      const locacaoEstaiX2 = inputValues[`estai${inputValues.estaiSelected}LocacaoX2`];
      const locacaoEstaiX3 = inputValues[`estai${inputValues.estaiSelected}LocacaoX3`];
      if (!inputValues[`estai${inputValues.estaiSelected}AlturaFixacaoMastro`]) whatIsMissingMessage += "Altura de fixação no mastro; ";
      if (!inputValues[`estai${inputValues.estaiSelected}Perfil`]) whatIsMissingMessage += "Perfil do estai; ";
      if ((inputValues[`estai${inputValues.estaiSelected}Perfil`] !== "Tubo (T)") && !inputValues[`estai${inputValues.estaiSelected}TipoPerfilCantoneira`]) whatIsMissingMessage += "Tipo do perfil; ";
      if (!inputValues[`estai${inputValues.estaiSelected}PerfisLaminados`]) whatIsMissingMessage += "Aço perfis laminados; ";
      if (!locacaoEstaiX1 && mastProps.tipoMastro !== "MH") whatIsMissingMessage += "Localização do X1; ";
      if (!locacaoEstaiX2 && mastProps.tipoMastro !== "MI") whatIsMissingMessage += "Localização do X2; ";
      if (!locacaoEstaiX3 && mastProps.tipoMastro !== "MH" && mastProps.tipoMastro !== "MI") whatIsMissingMessage += "Localização do X3; ";
      if (whatIsMissingMessage) return toast.error(`Preencha todos os valores para salvar o estai. Falta(m): ${whatIsMissingMessage}`);
      if(inputValues[`estai${inputValues.estaiSelected}AlturaFixacaoMastro`] > Number(mastProps.alturaMastro)) return toast.error("A altura de fixação é maior que a altura do mastro!");
      const updatedProps = JSON.stringify({ ...mastProps,
        ...(inputValues[`estai${inputValues.estaiSelected}AlturaFixacaoMastro`] !== "" && {
          [`estai${inputValues.estaiSelected}AlturaFixacaoMastro`]: inputValues[`estai${inputValues.estaiSelected}AlturaFixacaoMastro`]
        }),
        ...(inputValues["estaiMItipoAncoragem"] !== "" && {
          "estaiMItipoAncoragem": inputValues["estaiMItipoAncoragem"]
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}Perfil`] !== "" && {
          [`estai${inputValues.estaiSelected}Perfil`]: correctEstaiPerfilByGettingItemBetweenParentheses(inputValues[`estai${inputValues.estaiSelected}Perfil`])
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}TipoPerfilCantoneira`] !== "" && {
          [`estai${inputValues.estaiSelected}TipoPerfilCantoneira`]: inputValues[`estai${inputValues.estaiSelected}TipoPerfilCantoneira`]
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}TipoPerfilU`] !== "" && {
          [`estai${inputValues.estaiSelected}TipoPerfilU`]: inputValues[`estai${inputValues.estaiSelected}TipoPerfilU`]
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}Aba`] !== "" && {
          [`estai${inputValues.estaiSelected}Aba`]: inputValues[`estai${inputValues.estaiSelected}Aba`]
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}Espessura`] !== "" && {
          [`estai${inputValues.estaiSelected}Espessura`]: inputValues[`estai${inputValues.estaiSelected}Espessura`]
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}ParafusosDiametro`] !== "" && {
          [`estai${inputValues.estaiSelected}ParafusosDiametro`]: inputValues[`estai${inputValues.estaiSelected}ParafusosDiametro`]
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}ParafusosNumero`] !== "" && {
          [`estai${inputValues.estaiSelected}ParafusosNumero`]: inputValues[`estai${inputValues.estaiSelected}ParafusosNumero`]
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}ParafusosAco`] !== "" && {
          [`estai${inputValues.estaiSelected}ParafusosAco`]: inputValues[`estai${inputValues.estaiSelected}ParafusosAco`]
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}PerfisLaminados`] !== "" && {
          [`estai${inputValues.estaiSelected}PerfisLaminados`]: inputValues[`estai${inputValues.estaiSelected}PerfisLaminados`]
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}LocacaoX1`] !== "" && {
          [`estai${inputValues.estaiSelected}LocacaoX1`]: Number(inputValues[`estai${inputValues.estaiSelected}LocacaoX1`])
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}LocacaoX2`] !== "" && {
          [`estai${inputValues.estaiSelected}LocacaoX2`]: Number(inputValues[`estai${inputValues.estaiSelected}LocacaoX2`])
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}LocacaoX3`] !== "" && {
          [`estai${inputValues.estaiSelected}LocacaoX3`]: Number(inputValues[`estai${inputValues.estaiSelected}LocacaoX3`])
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}Alma`] !== "" && {
          [`estai${inputValues.estaiSelected}Alma`]: inputValues[`estai${inputValues.estaiSelected}Alma`]
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}AlmaEspessura`] !== "" && {
          [`estai${inputValues.estaiSelected}AlmaEspessura`]: inputValues[`estai${inputValues.estaiSelected}AlmaEspessura`]
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}Mesa`] !== "" && {
          [`estai${inputValues.estaiSelected}Mesa`]: inputValues[`estai${inputValues.estaiSelected}Mesa`]
        }),
        ...(inputValues[`estai${inputValues.estaiSelected}MesaEspessura`] !== "" && {
          [`estai${inputValues.estaiSelected}MesaEspessura`]: inputValues[`estai${inputValues.estaiSelected}MesaEspessura`]
        })
      });
      const toBeUpdatedMast = { ...mastSelected, updatedAt: new Date(), props: updatedProps };
      const updatedMast = await updateMast(toBeUpdatedMast, userData.token);
      setMastSelected(updatedMast);
      setReloadProject(!reloadProject);
      setIsUpdatedWithoutCalculation(true);
      if (Number(estaiSelectedInMenu) !== estaisByModules[mastProps.tipoMastro]) {
        setInputValues({ ...inputValues, estaiSelected: Number(estaiSelectedInMenu) + 1 });
        setEstaiSelectedInMenu(Number(estaiSelectedInMenu) + 1);
      }
      toast.success("Informações atualizadas com sucesso");
      setEstaiSelectedBackgroundColor("#D0CECE");
      setTimeout(() => setEstaiSelectedBackgroundColor("#F16E00"), 750);
      zeroEstaiLocations();
    } catch (error) {
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
      if (error?.message) return toast.error(error.message);
    }
  }

  function handleInfoHover(event, infoType) {
    if(event.type === "mouseleave") setShowInfo({ ...showInfo, infoType: "" });
    else setShowInfo({ infoType: infoType, isShown: event.type === "mouseenter" });
  };
  function handleMenuClick(menuType) {
    if (menuType === "project") {
      setProjectSelected({ });
      setProjectNameSelected("");
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else if (menuType === "projectName") {
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else {
      setLeftMenuOptionSelected("");
    }
  }

  function isEstaiCoordsVisible() {
    if(mastProps.tipoMastro === "MB" || mastProps.tipoMastro === "MC") {
      return [true, true, true];
    };
    if( mastProps.tipoMastro === "MH") {
      return [true, false, false];
    }
    if(mastProps.tipoMastro === "MI") {
      return [true, false, false];
    }
    if(Number(inputValues.estaiSelected) === 1 || Number(inputValues.estaiSelected) === 2) return [true, true, true];
    return [false, false, false];
  }

  function setAlturaFixacaoMastro(value) {
    if(mastProps.tipoMastro === "MI") return setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}LocacaoX3`]: value, [`estai${inputValues.estaiSelected}AlturaFixacaoMastro`]: value });
    setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}AlturaFixacaoMastro`]: value });
  }

  if (mastSelected?.tipo === "MA") {
    return (
      <>
        <ResizableLeftContainer>
          <LeftTitle>Estais</LeftTitle>
          <ReturnIcon onClick={() => {
            if (isInputModified) {
              if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("modulos");
            }
            else setLeftMenuOptionSelected("modulos");
          }}>
            <ion-icon name="chevron-back-outline"></ion-icon>
          </ReturnIcon>
          <AdvanceIcon disabled={isInputModified} onClick={() => {
            if (isInputModified) {
              if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja avançar?")) setLeftMenuOptionSelected("chumbadores");
            }
            else setLeftMenuOptionSelected("chumbadores");
          }}>
            <ion-icon name="chevron-back-outline"></ion-icon>
          </AdvanceIcon>
          <MenuOption>
            <h2>O mastro do tipo MA não possui estais</h2>
          </MenuOption>
          <ButtonPositioner >
            <ReturnButton onClick={() => {
              if (isInputModified) {
                if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("");
              }
              else setLeftMenuOptionSelected("");
            }} >{" Voltar "}</ReturnButton>
            {updateMastLoading ?           
              <LoadingButton>{ "Carregando" }</LoadingButton> :
              <SavingButton disabled={!isInputModified} onClick={() => { if(isInputModified) { updateMastEstaiData(); }; }}>{ "Salvar" }</SavingButton>}
          </ButtonPositioner>
        </ ResizableLeftContainer>
        <ResizableRightContainer></ResizableRightContainer>
      </>
    );
  };

  return (
    <>
      <ResizableLeftContainer>
        <LeftTitle>Estais</LeftTitle>
        <ReturnIcon onClick={() => {
          if (isInputModified) {
            if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("modulos");
          }
          else setLeftMenuOptionSelected("modulos");
        }}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ReturnIcon>
        <AdvanceIcon disabled={isInputModified} onClick={() => {
          if (isInputModified) {
            if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja avançar?")) setLeftMenuOptionSelected("chumbadores");
          }
          else setLeftMenuOptionSelected("chumbadores");
        }}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </AdvanceIcon>
        {mastProps.tipoMastro === "MI" ? <MenuOption>
          <h2>Selecione o tipo de conexão estaio-parede</h2>
          <select onChange={(e) => setInputValues({ ...inputValues, "estaiMItipoAncoragem": e.target.value })}>
            <option value={"Ancorado"}>Ancorado</option>
            <option value={"Passante"}>Passante</option>
          </select>
        </MenuOption> : ""}
        {mastProps.tipoMastro === "MI" ? <MenuOption>
          <h3>Digite a espessura da parede (mm)</h3>
          <input onChange={(e) => setInputValues({ ...inputValues, "espessuraParedeMI": e.target.value })}></input>
        </MenuOption> : ""}
        <EstaiMenuOption backgroundColor={estaiSelectedBackgroundColor} >
          <h2>Selecione o Estai</h2>
          <select value={estaiSelectedInMenu} onChange={(e) => setEstaiSelectedInMenu(e.target.value)}>
            {[...Array(estaisByModules[mastProps.tipoMastro]).keys()].map((i) => (
              <option value={i + 1} key={i + 1}>
                Estai {i + 1} (E{i + 1})
              </option>
            ))}
          </select>
        </EstaiMenuOption>
        <MenuOption>
          <h2>Altura fixação no mastro (m)</h2>
          <input autoFocus min={0} value={inputValues[`estai${inputValues.estaiSelected}AlturaFixacaoMastro`]} type="number" placeholder={inputValues[`estai${inputValues.estaiSelected}AlturaFixacaoMastro`] ? inputValues[`estai${inputValues.estaiSelected}AlturaFixacaoMastro`] : "Informe a altura de fixação do mastro"} onChange={(e) => setAlturaFixacaoMastro(e.target.value)}>
          </input>
        </MenuOption>
        <MenuOption>
          <h2></h2>
          <select onChange={(e) => { 
            setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}Perfil`]: e.target.value });
          }} value={inputValues[`estai${inputValues.estaiSelected}Perfil`]}>
            {inputValues[`estai${inputValues.estaiSelected}Perfil`] ? <option value={inputValues[`estai${inputValues.estaiSelected}Perfil`]} >{inputValues[`estai${inputValues.estaiSelected}Perfil`]}</option> :             <option value={""} disabled={true}>Selecione o perfil do estai</option>}
            {estaisPerfis.map((i) => (
              i !== inputValues[`estai${inputValues.estaiSelected}Perfil`] && <option value={i} key={i}>{i}</option>
            ))}
            {mastProps.tipoMastro === "MI" && 
            <option value={"U"}>Seção U</option>}
          </select>
        </MenuOption>
        {((inputValues[`estai${estaiSelectedInMenu}Perfil`] !== "Tubo (T)" && inputValues[`estai${estaiSelectedInMenu}Perfil`] !== "T") && inputValues[`estai${estaiSelectedInMenu}Perfil`] !== "U") &&
        <MenuOption>
          <h2>Tipo do perfil</h2>
          {mastProps.tipoMastro === "MI" && inputValues[`estai${inputValues.estaiSelected}Perfil`] === "U" ? 
            <select onChange={(e) => { setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}TipoPerfilU`]: e.target.value }); }} value={inputValues[`estai${inputValues.estaiSelected}TipoPerfilU`]}> 
              {inputValues[`estai${inputValues.estaiSelected}TipoPerfilU`] ? <option value={inputValues[`estai${inputValues.estaiSelected}TipoPerfilU`]} >{inputValues[`estai${inputValues.estaiSelected}TipoPerfilU`]}</option> : <option value={""} disabled={true}>Selecione o tipo de perfil U</option>}
              {perfisU.map((i) => (
                i !== inputValues[`estai${inputValues.estaiSelected}TipoPerfilU`] && <option value={i} key={i}>{i}</option>
              ))}
            </select>
            :
            <select onChange={(e) => {
              const regex = /L (\d+(\.\d+)?)x(\d+(\.\d+)?)mm/;
              const matches = e.target.value.match(regex);
              let firstNumber = 0;
              let secondNumber = 0;
              if (matches && matches.length >= 5) {
                firstNumber = parseFloat(matches[1]);
                secondNumber = parseFloat(matches[3]);
              };
              setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}Aba`]: firstNumber, [`estai${inputValues.estaiSelected}Espessura`]: secondNumber, [`estai${inputValues.estaiSelected}TipoPerfilCantoneira`]: e.target.value });
            }} value={inputValues[`estai${inputValues.estaiSelected}TipoPerfilCantoneira`]}> 
              {inputValues[`estai${inputValues.estaiSelected}TipoPerfilCantoneira`] ? <option value={inputValues[`estai${inputValues.estaiSelected}TipoPerfilCantoneira`]} >{inputValues[`estai${inputValues.estaiSelected}TipoPerfilCantoneira`]}</option> : <option value={""} disabled={true}>Selecione o tipo de perfil</option>}
              {perfisCantoneiraLe2L.map((i) => (
                i !== inputValues[`estai${inputValues.estaiSelected}TipoPerfilCantoneira`] && <option value={i} key={i}>{i}</option>
              ))}
            </select>
          }
        </MenuOption>}
        {((inputValues[`estai${estaiSelectedInMenu}Perfil`] === "Tubo (T)" || inputValues[`estai${estaiSelectedInMenu}Perfil`] === "T")) &&
        <MenuDoubleOption>
          <div>
            <div>
              <h2>Aba/ø (mm)</h2>
              <EstaiDiameterSelect inputValues={inputValues} setInputValues={setInputValues} abasList={abasEEspessurasProjeto.abas}/>
            </div>
            <div>
              <h2>Espessura (mm)</h2>
              <EstaiThicknessSelect inputValues={inputValues} setInputValues={setInputValues} thicknessList={abasEEspessurasProjeto.espessuras}/>
            </div>
          </div>
        </MenuDoubleOption>}
        {(inputValues[`estai${estaiSelectedInMenu}Perfil`] === "U" || inputValues[`estai${estaiSelectedInMenu}Perfil`] === "Seção U" ) &&
        <>
          <MenuDoubleOption>
            <div>
              <div>
                <h3>Alma (mm)</h3>
                <input value={inputValues[`estai${inputValues.estaiSelected}Alma`]} type="number" placeholder={inputValues[`estai${inputValues.estaiSelected}Alma`]} onChange={(e) => setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}Alma`]: e.target.value.replace(",", ".") })}/>
              </div>
            </div>
          </MenuDoubleOption>
          <MenuDoubleOption>
            <div>
              <div>
                <h2>Aba/ø (mm)</h2>
                <EstaiDiameterSelect inputValues={inputValues} setInputValues={setInputValues} abasList={abasEEspessurasProjeto.abas}/>
              </div>
              <div>
                <h2>Espessura (mm)</h2>
                <EstaiThicknessSelect inputValues={inputValues} setInputValues={setInputValues} thicknessList={abasEEspessurasProjeto.espessuras}/>
              </div>
            </div>
          </MenuDoubleOption>
        </>}
        <MenuOption>
          <h2>{(inputValues[`estai${estaiSelectedInMenu}Perfil`] === "Tubo (T)" || inputValues[`estai${estaiSelectedInMenu}Perfil`] === "T") ? "Aço Tubos" : "Aço Perfis Laminados"}</h2>
          <select onChange={(e) => setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}PerfisLaminados`]: e.target.value })}>
            {inputValues[`estai${inputValues.estaiSelected}PerfisLaminados`] ? <option value={inputValues[`estai${inputValues.estaiSelected}PerfisLaminados`]} >{inputValues[`estai${inputValues.estaiSelected}PerfisLaminados`]}</option> :             <option value={""} >Selecione uma opção...</option>}
            {tiposDeAçoPerfisEChapas.map((tipo) => (inputValues[`estai${inputValues.estaiSelected}PerfisLaminados`] !== tipo && <option value={tipo} key={tipo}>{tipo}</option>))}
          </select>
        </MenuOption>
        {isEstaiCoordsVisible().find(value => value === true) ? <MenuTripleOption>
          <h2>Coordenadas na base</h2>
          <InformationIconContainer onMouseEnter={(e) => handleInfoHover(e, "coordinates")} onMouseLeave={handleInfoHover}>
            <ion-icon name="information-circle"></ion-icon>
          </InformationIconContainer>
          {showInfo.isShown && showInfo.infoType === "coordinates" && (
            <InfoDiv>
              Cota relativa à altura de fixação da ancoragem do estai
            </InfoDiv>
          )}
          <div>
            {isEstaiCoordsVisible()[0] ? <div>
              <h3>X1 (m)</h3>
              <input type="number" value={inputValues[`estai${inputValues.estaiSelected}LocacaoX1`]} placeholder={inputValues[`estai${inputValues.estaiSelected}LocacaoX1`] ? inputValues[`estai${inputValues.estaiSelected}LocacaoX1`] : "Informe X1"} onChange={(e) => setEstaiLocations(e.target.value, "X1")} ></input>
            </div> : ""}
            {isEstaiCoordsVisible()[1] ? <div> 
              <h3>X2 (m)</h3>
              <input type="number" value={inputValues[`estai${inputValues.estaiSelected}LocacaoX2`]} placeholder={inputValues[`estai${inputValues.estaiSelected}LocacaoX2`] ? inputValues[`estai${inputValues.estaiSelected}LocacaoX2`] : "Informe X2"} onChange={(e) => setEstaiLocations(e.target.value, "X2")}></input>
            </div> : ""}
            {isEstaiCoordsVisible()[2] ? <div>
              <h3>X3 (m)</h3>
              <input type="number" value={inputValues[`estai${inputValues.estaiSelected}LocacaoX3`]} placeholder={inputValues[`estai${inputValues.estaiSelected}LocacaoX3`] ? inputValues[`estai${inputValues.estaiSelected}LocacaoX3`] : "Informe X3"} onChange={(e) => setEstaiLocations(e.target.value, "X3")}></input>
            </div> : ""}
          </div>
        </MenuTripleOption> : ""}
        {mastProps.tipoMastro !== "MI" ? 
          <>
            <MenuDoubleOptionWithCreatableSelect>
              <h2>Parafusos Estai</h2>
              <div>
                <div>
                  <input className="qtdInput" type="number" min="1" placeholder={inputValues[`estai${inputValues.estaiSelected}ParafusosNumero`] || "Quantidade"} value={inputValues[`estai${inputValues.estaiSelected}ParafusosNumero`]} onChange={(e) => setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}ParafusosNumero`]: e.target.value })}>          
                  </input>
                </div>
                <ScrewDiameterSelect inputValues={inputValues} setInputValues={setInputValues} diametersList={parafusosEstai} />
              </div>
            </MenuDoubleOptionWithCreatableSelect>
            <MenuOption>
              <h2>Aço Parafusos</h2>
              <select onChange={(e) => setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}ParafusosAco`]: e.target.value })}>
                {inputValues[`estai${inputValues.estaiSelected}ParafusosAco`] ? <option value={inputValues[`estai${inputValues.estaiSelected}ParafusosAco`]} >{inputValues[`estai${inputValues.estaiSelected}ParafusosAco`]}</option> :             <option value={""} >Selecione uma opção...</option>}
                {tiposDeAçoParafusos.map((tipo) => (inputValues[`estai${inputValues.estaiSelected}ParafusosAco`] !== tipo && <option value={tipo} key={tipo}>{tipo}</option>))}
              </select>
            </MenuOption>
          </> : ""}
        <ButtonPositioner >
          <ReturnButton onClick={() => {
            if (isInputModified) {
              if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("");
            }
            else setLeftMenuOptionSelected("");
          }} >{" Voltar "}</ReturnButton>
          {updateMastLoading ?           
            <LoadingButton>{ "Carregando" }</LoadingButton> :
            <SavingButton disabled={!isInputModified} onClick={() => { if(isInputModified) { updateMastEstaiData(); }; }}>{ "Salvar" }</SavingButton>}
        </ButtonPositioner>
      </ResizableLeftContainer>
      <ResizableRightContainer>
        <RightEstaiPageContainer>
          <ProjectNameWrapper>
            <h2><span onClick={() => handleMenuClick("project")}>Projetos</span> / <span onClick={() => handleMenuClick("projectName")}>{projectSelected?.nomeProjeto}</span> <span onClick={() => handleMenuClick("")}>{mastSelected?.identificador ? ` / ${mastSelected?.identificador}` : ""}</span></h2>
            <h1><span onClick={() => handleMenuClick("")}>{mastSelected?.identificador ? `${mastSelected?.identificador}` : projectSelected?.nomeProjeto}</span></h1>
          </ProjectNameWrapper>
          <EstaiRightContainer inputValues={inputValues} mastProps={mastProps}/>
        </RightEstaiPageContainer>
      </ResizableRightContainer>
    </>
  );
}

function EstaiDiameterSelect({ inputValues, setInputValues, abasList }) {
  const handleDiameterChange = (selectedOption) => {
    if (selectedOption) {
      const selectedDiameter = abasList.find((aba) => aba === selectedOption.value);
      if (selectedDiameter) {
        setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}Aba`]: selectedDiameter?.replace(",", ".") });
      }
      else setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}Aba`]: selectedOption.value?.replace(",", ".") });
    } else {
      setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}Aba`]: "" });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      border: "1px solid #C8C8C8",
      height: "4.5vh",
      minHeight: "4.5vh",
      borderRadius: "8px",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      color: "#000",
      marginBottom: "-10px"
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      marginBottom: "5px",
      width: "100%",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
      marginBottom: "5px",
      position: "absolute",
      right: "-10px",
      size: 2
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
      paddingBottom: "0.5vh", 
    }),
    input: (provided) => ({
      ...provided,
      marginTop: "-0.5vh", 
      width: "100%",
      color: "#000",
    }),
    clearIndicator: (provided) => ({
      display: "none"
    }),
    singleValue: (provided) => ({
      ...provided,
      paddingBottom: "0.5vh", 
      color: "#000",
    }),
  };

  return (
    <StyledSelect
      options={abasList.map((diameter) => ({ value: diameter, label: diameter }))}
      styles={customStyles}
      value={inputValues[`estai${inputValues.estaiSelected}Aba`] ? { value: inputValues[`estai${inputValues.estaiSelected}Aba`], label: inputValues[`estai${inputValues.estaiSelected}Aba`] } : ""}
      onChange={handleDiameterChange}
      placeholder={inputValues[`estai${inputValues.estaiSelected}Aba`] ? inputValues[`estai${inputValues.estaiSelected}Aba`] : "Aba"}
      isClearable
      isSearchable
      menuPlacement="auto"
    />
  );
}

function EstaiThicknessSelect({ inputValues, setInputValues, thicknessList }) {
  const updatedThicknessList = thicknessList.length ? thicknessList : ["3.2", "4.8", "6.4", "7.9", "9.5", "12.7", "15.9", "19"];
  const handleThicknessChange = (selectedOption) => {
    if (selectedOption) {
      const selectedThickness = updatedThicknessList.find((thicknes) => thicknes === selectedOption.value);
      if (selectedThickness) {
        setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}Espessura`]: selectedThickness?.replace(",", ".") });
      }
      else setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}Espessura`]: selectedOption.value?.replace(",", ".") });
    } else {
      setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}Espessura`]: "" });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      backgroundColor: "#ffffff",
      border: "1px solid #C8C8C8",
      height: "4.5vh",
      minHeight: "4.5vh",
      borderRadius: "8px",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      color: "#000",
      marginBottom: "-10px"
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      marginBottom: "5px",
      width: "100%",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
      marginBottom: "5px",
      position: "absolute",
      right: "-10px",
      size: 2
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
      paddingBottom: "0.5vh", 
    }),
    input: (provided) => ({
      ...provided,
      marginTop: "-0.5vh", 
      width: "100%",
      color: "#000",
    }),
    clearIndicator: (provided) => ({
      display: "none"
    }),
    singleValue: (provided) => ({
      ...provided,
      paddingBottom: "0.5vh", 
      color: "#000",
    }),
  };

  return (
    <StyledSelect
      options={updatedThicknessList.map((thickness) => ({ value: thickness, label: thickness }))}
      styles={customStyles}
      value={inputValues[`estai${inputValues.estaiSelected}Espessura`] ? { value: inputValues[`estai${inputValues.estaiSelected}Espessura`], label: inputValues[`estai${inputValues.estaiSelected}Espessura`] } : ""}
      onChange={handleThicknessChange}
      placeholder={inputValues[`estai${inputValues.estaiSelected}Espessura`] ? inputValues[`estai${inputValues.estaiSelected}Espessura`] : "Espessura"}
      isClearable
      isSearchable
      menuPlacement="auto"
    />
  );
}

function ScrewDiameterSelect({ inputValues, setInputValues, diametersList }) {
  const handleDiameterChange = (selectedOption) => {
    if (selectedOption) {
      const selectedDiameter = diametersList.find((diameter) => diameter === selectedOption.value);
      if (selectedDiameter) {
        setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}ParafusosDiametro`]: selectedDiameter });
      }
      else setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}ParafusosDiametro`]: selectedOption.value });
    } else {
      setInputValues({ ...inputValues, [`estai${inputValues.estaiSelected}ParafusosDiametro`]: "" });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      border: "1px solid #C8C8C8",
      backgroundColor: "#ffffff",
      height: "4.5vh",
      minHeight: "4.5vh",
      borderRadius: "8px",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      color: "#000",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      width: "100%",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
      position: "absolute",
      right: "-10px",
      size: 2
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    input: (provided) => ({
      ...provided,
      width: "100%",
      color: "#000",
    }),
    clearIndicator: (provided) => ({
      display: "none"
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
    }),
  };

  return (
    <StyledSelect
      options={diametersList.map((diameter) => ({ value: diameter, label: diameter }))}
      styles={customStyles}
      value={inputValues[`estai${inputValues.estaiSelected}ParafusosDiametro`] ? { value: inputValues[`estai${inputValues.estaiSelected}ParafusosDiametro`], label: inputValues[`estai${inputValues.estaiSelected}ParafusosDiametro`] } : ""}
      onChange={handleDiameterChange}
      placeholder={inputValues[`estai${inputValues.estaiSelected}ParafusosDiametro`] ? inputValues[`estai${inputValues.estaiSelected}ParafusosDiametro`] : "Diâmetro"}
      isClearable
      isSearchable
      menuPlacement="auto"
    />
  );
}

const StyledSelect = styled(CreatableSelect)`
  .react-select__control {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4.5vh;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 26px;
    color: #000;
  }
  .react-select__placeholder {
    color: #000;
  }

  .react-select__dropdown-indicator {
    color: #000;
  }

  .react-select__option {
    color: #000;
  }
`;

export const MenuTripleOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1vh 0;
  position: relative;

  h2 {
    font-weight: 300;
    font-size: 16px;
    color: #313131;
    margin-bottom: 1vh;
  }

  input,select {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }

  ion-icon {
    position: absolute;
    top: 0;
    right: 0;
    color: #F16E00;
  }

  >div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  >div>div{
    width: 29%;
    h3 {
      font-size: 15px;
      color: #313131;
      font-weight: 300;
      margin: 0 0 6px 4px;
    }
  }
`;

const EstaiMenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1vh 0;
  position: relative;

  h2 {
    font-weight: 300;
    font-size: 17px;
    color: #313131;
    margin-bottom: 0.8vh;
  }

  input,select {
    width: 100%;
    background-color: ${(props) => (props.backgroundColor)};
    height: 4.5vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }
`;

export const MenuDoubleOptionWithCreatableSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1vh 0;
  position: relative;

  h2 {
    font-weight: 300;
    font-size: 16px;
    color: #313131;
    margin-bottom: 0.8vh;
  }

  .qtdInput {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4.5vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }

  >div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  >div>div{
    width: 46%;
  }
`;

export const RightEstaiPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 4vh);
  align-items: flex-start;
  margin-top: 4vh;
  max-width: calc(75% - 60px);
`;
