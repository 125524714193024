import React from "react";
import styled from "styled-components";

export default function CustomCheckbox({ id, label, checked, onChange, disabled }) {
  return (
    <CheckboxContainer>
      <CheckboxItem>
        <input 
          disabled={disabled}
          type="checkbox" 
          id={id} 
          checked={checked} 
          onChange={onChange} 
        />
        <StyledCheckbox checked={checked}></StyledCheckbox>
        <div>
          {checked && <ion-icon name="checkmark-sharp"></ion-icon>}
        </div>
      </CheckboxItem>
      <Label htmlFor={id}>{label}</Label>
    </CheckboxContainer>
  );
}

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxItem = styled.div`
  display: inline-block;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    opacity: 0;
    z-index: 2;
  }

  >div {
    display: flex;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;

    ion-icon {
      font-size: 16px;
      color: #FFFFFF;
      transform: rotate(0deg);
    }
  }
`;

const StyledCheckbox = styled.span`
  display: inline-block;
  width: 18px;
  height: 18px;
  background: ${(props) => (props.checked ? "#FE8F03" : "#FFFFFF")};
  border: 1px solid #969696;
  border-radius: 1px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    background: ${(props) => (props.checked ? "#FE8F03" : "transparent")};
    border-radius: 3px;
  }
`;

const Label = styled.label`
  margin-left: 10px;
  font-size: 12px;
  color: #313131;
  user-select: none;
`;
