import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";

class InferiorDiagonalDataModel {
  constructor(
    profileType = "",
    profileSteel = "",
    profileDimensions = "",
    profileDiameter = "",
    profileThickness = "",
    profileFlange = "",
    profileWeb = "",
    boltsSteel = "",
    numConnectionBolts = "",
    diameterConnectionBolts = "",
    reinforcementDimensions = "",
    reinforcementDiameter = "",
    reinforcementThickness = "",
    reinforcementFlange = "",
    reinforcementWeb = ""
  ) {
    this.profileType = profileType;
    this.profileSteel = profileSteel;
    this.profileDimensions = profileDimensions;
    this.profileDiameter = profileDiameter;
    this.profileThickness = profileThickness;
    this.profileFlange = profileFlange;
    this.profileWeb = profileWeb;
    this.boltsSteel = boltsSteel;
    this.numConnectionBolts = numConnectionBolts;
    this.diameterConnectionBolts = diameterConnectionBolts;
    this.reinforcementDimensions = reinforcementDimensions;
    this.reinforcementDiameter = reinforcementDiameter;
    this.reinforcementThickness = reinforcementThickness;
    this.reinforcementFlange = reinforcementFlange;
    this.reinforcementWeb = reinforcementWeb;
  }

  updateField(field, value) {
    if (value === this[field] || this[field] === undefined) return;

    if (field === "profileType") {
      this[field] = value;
      this.profileSteel = "";
      this.profileDimensions = "";
      this.profileDiameter = "";
      this.profileThickness = "";
      this.profileFlange = "";
      this.profileWeb = "";
      this.reinforcementDimensions = "";
      this.reinforcementDiameter = "";
      this.reinforcementThickness = "";
      this.reinforcementFlange = "";
      this.reinforcementWeb = "";
      return;
    }

    if (this.profileType === "T - Tubo Vazado") {
      if (field === "profileSteel") {
        this[field] = value;
        this.profileDimensions = "";
        this.profileDiameter = "";
        this.profileThickness = "";
        this.profileFlange = "";
        this.profileWeb = "";
        this.reinforcementDimensions = "";
        this.reinforcementDiameter = "";
        this.reinforcementThickness = "";
        this.reinforcementFlange = "";
        this.reinforcementWeb = "";
        return;
      }

      if (field === "profileDiameter") {
        this[field] = value;
        this.profileThickness = "";
        return;
      }

      if ((field === "reinforcementDiameter" || field === "reinforcementThickness") && value === "Não Há Reforço") {
        this.reinforcementDiameter = "Não Há Reforço";
        this.reinforcementThickness = "Não Há Reforço";
        return;
      }

      if (field === "reinforcementDiameter") {
        this[field] = value;
        this.reinforcementThickness = "";
        return;
      }
    }

    if (this.profileType === "U - Perfil U") {
      if (field === "reinforcementThickness") {
        if (value === "Não Há Reforço") {
          this.reinforcementFlange = "Não Há Reforço";
          this.reinforcementWeb = "Não Há Reforço";
          this.reinforcementThickness = "Não Há Reforço";
        } else {
          if (this.reinforcementThickness === "Não Há Reforço") {
            this.reinforcementFlange = "";
            this.reinforcementWeb = "";
            this.reinforcementThickness = value;
          } else {
            this.reinforcementThickness = value;
          }
        }
        return;
      }
    }

    this[field] = value;
  }
}

class DiagonalModel {
  constructor(
    segmentId = "",
    trussType = "",
    profileType = "",
    profileSteel = "",
    profileDimensions = "",
    profileDiameter = "",
    profileThickness = "",
    profileFlange = "",
    profileWeb = "",
    boltsSteel = "",
    numConnectionBolts = "",
    diameterConnectionBolts = "",
    reinforcementDimensions = "",
    reinforcementDiameter = "",
    reinforcementThickness = "",
    reinforcementFlange = "",
    reinforcementWeb = "",
    isInferiorDiagonalDifferent = "",
    inferiorDiagonalData = {}
  ) {
    this.segmentId = segmentId;
    this.trussType = trussType;

    if (trussType !== "Treliça H") {
      this.profileType = profileType;
      this.profileSteel = profileSteel;
      this.profileDimensions = profileDimensions;
      this.profileDiameter = profileDiameter;
      this.profileThickness = profileThickness;
      this.profileFlange = profileFlange;
      this.profileWeb = profileWeb;
      this.boltsSteel = boltsSteel;
      this.numConnectionBolts = numConnectionBolts;
      this.diameterConnectionBolts = diameterConnectionBolts;
      this.reinforcementDimensions = reinforcementDimensions;
      this.reinforcementDiameter = reinforcementDiameter;
      this.reinforcementThickness = reinforcementThickness;
      this.reinforcementFlange = reinforcementFlange;
      this.reinforcementWeb = reinforcementWeb;
      this.isInferiorDiagonalDifferent = isInferiorDiagonalDifferent;
      this.inferiorDiagonalData = trussType === "Treliça X" ? new InferiorDiagonalDataModel(
        inferiorDiagonalData.profileType,
        inferiorDiagonalData.profileSteel,
        inferiorDiagonalData.profileDimensions,
        inferiorDiagonalData.profileDiameter,
        inferiorDiagonalData.profileThickness,
        inferiorDiagonalData.profileFlange,
        inferiorDiagonalData.profileWeb,
        inferiorDiagonalData.boltsSteel,
        inferiorDiagonalData.numConnectionBolts,
        inferiorDiagonalData.diameterConnectionBolts,
        inferiorDiagonalData.reinforcementDimensions,
        inferiorDiagonalData.reinforcementDiameter,
        inferiorDiagonalData.reinforcementThickness,
        inferiorDiagonalData.reinforcementFlange,
        inferiorDiagonalData.reinforcementWeb,
      ) : {};
    }
  }

  updateDiagonalField(field, value) {
    if (value === this[field] || this[field] === undefined) return;

    if (field === "inferiorDiagonalData" && value === "empty") {
      this.inferiorDiagonalData = {};
      return;
    }

    if (field === "profileType") {
      this[field] = value;
      this.profileSteel = "";
      this.profileDimensions = "";
      this.profileDiameter = "";
      this.profileThickness = "";
      this.profileFlange = "";
      this.profileWeb = "";
      this.reinforcementDimensions = "";
      this.reinforcementDiameter = "";
      this.reinforcementThickness = "";
      this.reinforcementFlange = "";
      this.reinforcementWeb = "";
      return;
    }

    if (this.profileType === "T - Tubo Vazado") {
      if (field === "profileSteel") {
        this[field] = value;
        this.profileDimensions = "";
        this.profileDiameter = "";
        this.profileThickness = "";
        this.profileFlange = "";
        this.profileWeb = "";
        this.reinforcementDimensions = "";
        this.reinforcementDiameter = "";
        this.reinforcementThickness = "";
        this.reinforcementFlange = "";
        this.reinforcementWeb = "";
        return;
      }

      if (field === "profileDiameter") {
        this[field] = value;
        this.profileThickness = "";
        return;
      }

      if ((field === "reinforcementDiameter" || field === "reinforcementThickness") && value === "Não Há Reforço") {
        this.reinforcementDiameter = "Não Há Reforço";
        this.reinforcementThickness = "Não Há Reforço";
        return;
      }

      if (field === "reinforcementDiameter") {
        this[field] = value;
        this.reinforcementThickness = "";
        return;
      }
    }

    if (this.profileType === "U - Perfil U") {
      if (field === "reinforcementThickness") {
        if (value === "Não Há Reforço") {
          this.reinforcementFlange = "Não Há Reforço";
          this.reinforcementWeb = "Não Há Reforço";
          this.reinforcementThickness = "Não Há Reforço";
        } else {
          if (this.reinforcementThickness === "Não Há Reforço") {
            this.reinforcementFlange = "";
            this.reinforcementWeb = "";
            this.reinforcementThickness = value;
          } else {
            this.reinforcementThickness = value;
          }
        }
        return;
      }
    }

    if (field === "isInferiorDiagonalDifferent") {
      this[field] = value;
      if (value === "Sim") {
        this.inferiorDiagonalData = new InferiorDiagonalDataModel();
      } else if (value === "") {
        this.inferiorDiagonalData = {};
      }
      return;
    }

    this[field] = value;
  }

  updateInferiorDiagonalField(field, value) {
    this.inferiorDiagonalData.updateField(field, value);
  }
}

class DiagonalsModel {
  constructor(diagonals = []) {
    this.diagonals = diagonals.map(diagonal => new DiagonalModel(
      diagonal.segmentId,
      diagonal.trussType,
      diagonal.profileType,
      diagonal.profileSteel,
      diagonal.profileDimensions,
      diagonal.profileDiameter,
      diagonal.profileThickness,
      diagonal.profileFlange,
      diagonal.profileWeb,
      diagonal.boltsSteel,
      diagonal.numConnectionBolts,
      diagonal.diameterConnectionBolts,
      diagonal.reinforcementDimensions,
      diagonal.reinforcementDiameter,
      diagonal.reinforcementThickness,
      diagonal.reinforcementFlange,
      diagonal.reinforcementWeb,
      diagonal.isInferiorDiagonalDifferent,
      diagonal.inferiorDiagonalData
    ));
  }

  updateDiagonal(segmentId, field, value) {
    const diagonal = this.diagonals.find(diagonal => diagonal.segmentId === segmentId);

    if (diagonal) {
      diagonal.updateDiagonalField(field, value);
    }

    if (field === "isInferiorDiagonalDifferent") {
      this.setInferiorDiagonalDataEqual();
    }
  }

  updateInferiorDiagonal(segmentId, field, value) {
    const diagonal = this.diagonals.find(diagonal => diagonal.segmentId === segmentId);
    if (diagonal && diagonal.trussType === "Treliça X") {
      diagonal.updateInferiorDiagonalField(field, value);
    }
  }

  setInferiorDiagonalDataEqual() {
    for (const diagonal of this.diagonals) {
      if (diagonal.isInferiorDiagonalDifferent !== "Não") continue;

      diagonal.updateInferiorDiagonalField("profileType", diagonal.profileType);
      diagonal.updateInferiorDiagonalField("profileSteel", diagonal.profileSteel);
      diagonal.updateInferiorDiagonalField("profileDimensions", diagonal.profileDimensions);
      diagonal.updateInferiorDiagonalField("profileDiameter", diagonal.profileDiameter);
      diagonal.updateInferiorDiagonalField("profileThickness", diagonal.profileThickness);
      diagonal.updateInferiorDiagonalField("profileFlange", diagonal.profileFlange);
      diagonal.updateInferiorDiagonalField("profileWeb", diagonal.profileWeb);
      diagonal.updateInferiorDiagonalField("boltsSteel", diagonal.boltsSteel);
      diagonal.updateInferiorDiagonalField("numConnectionBolts", diagonal.numConnectionBolts);
      diagonal.updateInferiorDiagonalField("diameterConnectionBolts", diagonal.diameterConnectionBolts);
      diagonal.updateInferiorDiagonalField("reinforcementDimensions", diagonal.reinforcementDimensions);
      diagonal.updateInferiorDiagonalField("reinforcementDiameter", diagonal.reinforcementDiameter);
      diagonal.updateInferiorDiagonalField("reinforcementThickness", diagonal.reinforcementThickness);
      diagonal.updateInferiorDiagonalField("reinforcementFlange", diagonal.reinforcementFlange);
      diagonal.updateInferiorDiagonalField("reinforcementWeb", diagonal.reinforcementWeb);
    }
  }

  validate() {
    let errors = [];

    this.diagonals.forEach((segment, index) => {
      if (segment.trussType !== "Treliça H") {
        if (!segment.profileType) errors.push(`Tipo de Perfil no trecho ${index + 1}`);
        if (segment.profileSteel === "") errors.push(`Aço do Perfil no trecho ${index + 1}`);
        if (!segment.profileDimensions && !segment.profileDiameter && !segment.profileThickness &&
          !segment.profileFlange && !segment.profileWeb) {
          errors.push(`Dimensões do Perfil no trecho ${index + 1}`);
        } else {
          if (segment.profileType === "T - Tubo Vazado" && (!segment.profileDiameter || !segment.profileThickness))
            errors.push(`Dimensões do Perfil no trecho ${index + 1}`);
          if (segment.profileType === "U - Perfil U" &&
            (!segment.profileFlange || !segment.profileWeb || !segment.profileThickness))
            errors.push(`Dimensões do Perfil no trecho ${index + 1}`);
        }
        if (!segment.boltsSteel) errors.push(`Aço dos Parafusos no trecho ${index + 1}`);
        if (!segment.numConnectionBolts) errors.push(`Quantidade de parafusos de conexão no trecho ${index + 1}`);
        if (!segment.diameterConnectionBolts) errors.push(`Diâmetro de parafusos de conexão no trecho ${index + 1}`);
        if (!segment.reinforcementDimensions && !segment.reinforcementDiameter && !segment.reinforcementThickness &&
          !segment.reinforcementFlange && !segment.reinforcementWeb) {
          errors.push(`Dimensões do Perfil Reforço no trecho ${index + 1}`);
        } else {
          if (segment.profileType === "T - Tubo Vazado" && (!segment.reinforcementDiameter || !segment.reinforcementThickness))
            errors.push(`Dimensões do Perfil Reforço no trecho ${index + 1}`);
          if (segment.profileType === "U - Perfil U" &&
            (!segment.reinforcementFlange || !segment.reinforcementWeb || !segment.reinforcementThickness))
            errors.push(`Dimensões do Perfil Reforço no trecho ${index + 1}`);
        }
        if (!segment.isInferiorDiagonalDifferent && segment.trussType === "Treliça X")
          errors.push(`Se a diagonal inferior é diferente no trecho ${index + 1}`);

        if (segment.isInferiorDiagonalDifferent === "Sim") {
          if (!segment.inferiorDiagonalData.profileType)
            errors.push(`Tipo de Perfil da diagonal inferior no trecho ${index + 1}`);
          if (!segment.inferiorDiagonalData.profileDimensions && !segment.inferiorDiagonalData.profileDiameter &&
            !segment.inferiorDiagonalData.profileThickness && !segment.inferiorDiagonalData.profileFlange &&
            !segment.inferiorDiagonalData.profileWeb) {
            errors.push(`Dimensões do Perfil da diagonal inferior no trecho ${index + 1}`);
          } else {
            if (segment.inferiorDiagonalData.profileType === "T - Tubo Vazado" &&
              (!segment.inferiorDiagonalData.profileDiameter || !segment.inferiorDiagonalData.profileThickness))
              errors.push(`Dimensões do Perfil da diagonal inferior no trecho ${index + 1}`);
            if (segment.inferiorDiagonalData.profileType === "U - Perfil U" && (!segment.inferiorDiagonalData.profileFlange ||
              !segment.inferiorDiagonalData.profileWeb || !segment.inferiorDiagonalData.profileThickness))
              errors.push(`Dimensões do Perfil da diagonal inferior no trecho ${index + 1}`);
          }
          if (segment.inferiorDiagonalData.profileSteel === "")
            errors.push(`Aço do Perfil da diagonal inferior no trecho ${index + 1}`);
          if (!segment.inferiorDiagonalData.numConnectionBolts)
            errors.push(`Quantidade de parafusos de conexão da diagonal inferior no trecho ${index + 1}`);
          if (!segment.inferiorDiagonalData.diameterConnectionBolts)
            errors.push(`Diametro de parafusos de conexão da diagonal inferior no trecho ${index + 1}`);
          if (!segment.inferiorDiagonalData.boltsSteel)
            errors.push(`Aço dos Parafusos da diagonal inferior no trecho ${index + 1}`);
          if (!segment.inferiorDiagonalData.reinforcementDimensions && !segment.inferiorDiagonalData.reinforcementDiameter &&
            !segment.inferiorDiagonalData.reinforcementThickness && !segment.inferiorDiagonalData.reinforcementFlange &&
            !segment.inferiorDiagonalData.reinforcementWeb) {
            errors.push(`Dimensões do Perfil reforço da diagonal inferior no trecho ${index + 1}`);
          } else {
            if (segment.inferiorDiagonalData.profileType === "T - Tubo Vazado" &&
              (!segment.inferiorDiagonalData.reinforcementDiameter || !segment.inferiorDiagonalData.reinforcementThickness))
              errors.push(`Dimensões do Perfil reforço da diagonal inferior no trecho ${index + 1}`);
            if (segment.inferiorDiagonalData.profileType === "U - Perfil U" && (!segment.inferiorDiagonalData.reinforcementFlange ||
              !segment.inferiorDiagonalData.reinforcementWeb || !segment.inferiorDiagonalData.reinforcementThickness))
              errors.push(`Dimensões do Perfil reforço da diagonal inferior no trecho ${index + 1}`);
          }
        }
      }
    });

    if (errors.length > 0) {
      const errorMessage = customToastError(
        errors,
        "Preencha todos os valores corretamente para salvar as informações das diagonais. Falta(m):"
      );
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  toJSON() {
    return JSON.stringify(this.diagonals);
  }

  static fromJSON(json) {
    const diagonalsArray = JSON.parse(json);
    return new DiagonalsModel(diagonalsArray);
  }
}

export default DiagonalsModel;
