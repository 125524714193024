import styled from "styled-components";
import { CustomInput } from "../../common/custom-input";
import CustomRadioButton from "../../common/custom-radio-buttons";
import triangularSectionImg from "../../../assets/images/towerImages/tower-section-triagular.svg";
import squareSectionImg from "../../../assets/images/towerImages/tower-section-square.svg";
import towerImg from "../../../assets/images/towerImages/tower-geometry.svg";
import colors from "../../../constants/color-constants";

export default function TowerGeometryRightContainer({ sectionTypes, inputValues, handleInputChange }) {
  const inputConfig = [
    { label: "Altura Total", name: "totalHeight", top: "210px", left: "0px", unit: "m" },
    { label: "Largura Topo", name: "topWidth", top: "0px", left: "200px", unit: "mm" },
    { label: "Largura Base", name: "baseWidth", top: "430px", left: "250px", unit: "mm" },
    { label: "Nº de Trechos", name: "nSegments", top: "0px", left: "0px", unit: "Trechos", toFixed: 0 },
    { label: "Elevação", name: "elevation", top: "430px", left: "0px", unit: "m" },
  ];

  return (
    <Container>
      <TowerSectionContainer>
        <h2>Seção da Torre</h2>
        <div>
          <SectionImg 
            selected={inputValues.towerSection === sectionTypes[0]}
            onClick={() => handleInputChange({ target: { name: "towerSection", value: sectionTypes[0] } })}
          >
            <CustomRadioButton
              name="towerSection"
              value={sectionTypes[0]}
              checked={inputValues.towerSection === sectionTypes[0]}
              onClick={() => handleInputChange({ target: { name: "towerSection", value: sectionTypes[0] } })}
            />
            <img src={squareSectionImg} width={"90px"} alt={sectionTypes[0]} />
          </SectionImg>

          <SectionImg
            selected={inputValues.towerSection === sectionTypes[1]}
            onClick={() => handleInputChange({ target: { name: "towerSection", value: sectionTypes[1] } })}
          >
            <CustomRadioButton
              name="towerSection"
              value={sectionTypes[1]}
              checked={inputValues.towerSection === sectionTypes[1]}
              onClick={() => handleInputChange({ target: { name: "towerSection", value: sectionTypes[1] } })}
            />
            <img src={triangularSectionImg} width={"100px"} alt={sectionTypes[1]} />
          </SectionImg>
        </div>
      </TowerSectionContainer>

      <TowerGeometryImgContainer>
        <h2>Dados Gerais</h2>
        <PositionalContainer>
          <img src={towerImg} alt={""} />

          {inputConfig.map(({ label, name, top, left, unit, toFixed }) => (
            <InputImageContainer top={top} left={left} key={name}>
              <h3>{label}</h3>
              <CustomInput
                name={name}
                value={inputValues[name]}
                placeholder={unit}
                onChange={newValue => handleInputChange({ target: { name, value: newValue } })}
                toFixed={toFixed}
                isNumber={true}
                unit={unit}
              />
            </InputImageContainer>
          ))}
        </PositionalContainer>
      </TowerGeometryImgContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;

  h2 {
    color: ${colors.kcTextColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }
`; 

const TowerSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: ${colors.kcLightGray};
  border-radius: 6px;
  width: min-content;
  height: min-content;
  margin-bottom: 20px;

  h2 {
    margin-bottom: 16px;
    color: ${colors.kcTextColor};
  }

  >div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    gap: 20px;
  }
`;

const SectionImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 150px;
  min-height: 150px;
  padding: 15px;
  background-color: ${colors.kcWhiteColor};
  border-radius: 8px;
  border: ${(props) => props.selected ? `1px solid ${colors.kcOrange}` : "none"};
  box-sizing: border-box;
  cursor: pointer;

  >img {
    width: ${(props) => props.width};
  }
`;

const PositionalContainer = styled.div`
  position: relative;

  img {
    margin: 30px 45px 10px;
    object-fit: contain;
  }
`;

const TowerGeometryImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: calc(100vh - 160px);
  max-width: calc(100vw - 25%);
  padding: 20px;
  background-color: ${colors.kcLightGray};
  border-radius: 6px;

  h2 {
    margin-bottom: 20px;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const InputImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  box-sizing: border-box;
  gap: 2px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: ${colors.kcBlackColor};
    
  h3 {
    text-align: center;
  }

  input {
    padding: 3px;
    width: 90px;
    height: 30px;
    border-radius: 8px;
    border: 1px solid ${colors.kcBorderGray};
    text-align: center;
  }
`;
