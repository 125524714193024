import useAsync from "../useAsync";
import * as mastApi from "../../services/mastApi";

export default function usePostMast() {
  const {
    data: mast,
    loading: postMastLoading,
    error: postMastError,
    act: postMast,
  } = useAsync((props, token) => mastApi.postNewMast(props, token), false);

  return {
    mast,
    postMastLoading,
    postMastError,
    postMast
  };
}
