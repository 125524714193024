import styled from "styled-components";
import { useState, useContext } from "react";
import { ProjectContext } from "../contexts/ProjectContext";
import { TopBar } from "../components/topMenu-components/top-bar";
import ProjectActionsPage from "../components/project-components/dashboardPage-components/project-actionsPage";
import ProjectLeftMenuTower from "../components/project-components/project-lefMenu-tower";
import TowerInitialRightContainer from "../components/tower-components/tower-initialRightContainer";
import TowerGeometryPage from "../components/tower-components/geometry-components/tower-geometry";
import TowerTrussesPage from "../components/tower-components/trusses-components/tower-trusses";
import TowerSectionsPage from "../components/tower-components/section-components/tower-section";
import TowerPlatformPage from "../components/tower-components/platform-components/tower-platform";
import TowerAntennasPage from "../components/tower-components/antennas-components/tower_antennas";
import TowerMountPage from "../components/tower-components/mount-components/tower_mount";
import TowerDiagonalPage from "../components/tower-components/diagonal-components/tower_diagonal";
import TowerRedundantPage from "../components/tower-components/redundant-components/tower_redundant";
import TowerHorizontalPage from "../components/tower-components/horizontal-components/tower_horizontal";
import TowerDiagonalLockingPage from "../components/tower-components/diagonalLocking-components/tower_diagonalLocking";
import TowerHorizontalLockingPage from "../components/tower-components/horizontalLocking-components/tower_horizontalLocking";
import TowerArchorsPage from "../components/tower-components/archors-components/tower_anchors";
import TowerWindPage from "../components/tower-components/wind-components/tower_wind";
import TowerCalculationPage from "../components/tower-components/calculation-components/tower_calculation";

export function TowerPage() {
  const {
    projectSelected,
    leftMenuOptionSelected,
    projectType,
  } = useContext(ProjectContext);

  const [opacity, setOpacity] = useState("1");

  return (
    !projectSelected.nomeProjeto ? (
      <>
        {" "}
        <NoProjectContainer>
          {projectSelected && projectSelected.nomeProjeto ? (
            ""
          ) : (
            <ProjectActionsPage setOpacity={setOpacity} projectType />
          )}
        </NoProjectContainer>
      </>
    ) : (
      <TopBar opacity={opacity}>
        <ProjectPageContainer>
          {(leftMenuOptionSelected === "" && projectType === "Torre") &&
            <>
              <ProjectLeftMenuTower />
              <TowerInitialRightContainer />
            </>}
          {leftMenuOptionSelected === "geometria" && <TowerGeometryPage />}
          {leftMenuOptionSelected === "trelicamento" && <TowerTrussesPage />}
          {leftMenuOptionSelected === "secoes" && <TowerSectionsPage />}
          {leftMenuOptionSelected === "plataformas" && <TowerPlatformPage />}
          {leftMenuOptionSelected === "vento" && <TowerWindPage />}
          {leftMenuOptionSelected === "antenas" && <TowerAntennasPage />}
          {/* {leftMenuOptionSelected === "esteira" && <MastAnchorageCalculationPage />} */}
          {leftMenuOptionSelected === "montantes" && <TowerMountPage />}
          {leftMenuOptionSelected === "diagonais" && <TowerDiagonalPage />}
          {leftMenuOptionSelected === "horizontais" && <TowerHorizontalPage />}
          {leftMenuOptionSelected === "redundantes" && <TowerRedundantPage />}
          {leftMenuOptionSelected === "travamentos-diagonais" && <TowerDiagonalLockingPage />}
          {leftMenuOptionSelected === "travamentos-horizontais" && <TowerHorizontalLockingPage />}
          {leftMenuOptionSelected === "ancoragem" && <TowerArchorsPage />}
          {leftMenuOptionSelected === "calcular" && <TowerCalculationPage />}
          {/* {leftMenuOptionSelected === "metalica" && <StrapResultsPage />} */}
          {/* {leftMenuOptionSelected === "chumbadores" && <MastAnchoragePage />} */}
          {/* {leftMenuOptionSelected === "memorial" && <ProjectMemorialPage />} */}
          {/* {leftMenuOptionSelected === "relatorio" && <ProjectReportPage />} */}
        </ProjectPageContainer>
      </TopBar>
    ));
}

const NoProjectContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 50px);
  background-color: #C9C9C9;
`;

const ProjectPageContainer = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 50px);
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: #C9C9C9;
`;
