import useAsync from "../../useAsync";
import { getGeometry, postGeometry, updateGeometry } from "../../../services/tower-services/towerGeometryApi";

export const useTowerGeometry = (action) => {
  const execute = {
    get: getGeometry,
    post: postGeometry,
    update: updateGeometry,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
