import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { diagonalLockingsInfo } from "../../../constants/tower-trusses-data";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { TowerContext } from "../../../contexts/TowerContext";
import UserContext from "../../../contexts/UserContext";
import { useTowerDiagonalLockings } from "../../../hooks/api/tower-api/useTowerDiagonalLockings";
import useUnsavedChangesAlertForObjects from "../../../hooks/useUnsavedChangesAlertForObjects";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import TowerDiagonalLockingLeftMenu from "./tower_diagonalLockingLeftMenu";
import TowerDiagonalLockingContainer from "./tower_diagonalLockingRightContainer";
import DiagonalLockingsModel from "../../../models/DiagonalLockingModel";

export default function TowerDiagonalLockingPage() {
  const { userData } = useContext(UserContext);
  const { towerId, towerImages, segmentsSelected, geometrySelected, trussesSelected,
    diagonalLockingSelected, setDiagonalLockingSelected } = useContext(TowerContext);
  const { reloadProject, setIsUpdatedWithoutCalculation, isLoading, setIsLoading } = useContext(ProjectContext);

  const { act: fetchDiagonalLockings } = useTowerDiagonalLockings("get");
  const { act: saveDiagonalLockings } = useTowerDiagonalLockings("post");

  const nSegments = Number(geometrySelected.nSegments) || 0;
  const trussesTypes = trussesSelected.map(truss => truss.trussType);

  const [segmentSelectedInMenu, setSegmentSelectedInMenu] = useState(nSegments > 0 ? 1 : "");
  const [diagonalSelectedInMenu, setDiagonalSelectedInMenu] = useState(
    trussesTypes[segmentSelectedInMenu - 1] !== "Treliça H" ? "superiorDiagonalData" : "");
  const [barSelectedInMenu, setBarSelectedInMenu] = useState("");
  const [nBars, setNBars] = useState({
    superiorDiagonalData: [0],
    inferiorDiagonalData: [0],
  });
  const [inputValues, setInputValues] = useState(new DiagonalLockingsModel(diagonalLockingSelected).diagonalLockings);

  useEffect(() => {
    loadDiagonalLockingsData().then(response => {
      if (response.length === 0) {
        setInitialEmptyValues();
      } else {
        const diagonalLockingsModel = new DiagonalLockingsModel(response);
        setDiagonalLockingSelected(diagonalLockingsModel.diagonalLockings);
        setInputValues(diagonalLockingsModel.diagonalLockings);
      }
    });
  }, [towerId, userData.token]);

  const loadDiagonalLockingsData = useCallback(async() => {
    setIsLoading(true);
    try {
      return await fetchDiagonalLockings(towerId, userData.token);
    } catch (error) {
      toast.error("Erro ao buscar dados dos travamentos diagonais.");
    } finally {
      setIsLoading(false);
    }
  }, [fetchDiagonalLockings, towerId, userData.token]);

  useEffect(() => {
    if (segmentSelectedInMenu !== "") {
      setBarSelectedInMenu("");
      setDiagonalSelectedInMenu(trussesTypes[segmentSelectedInMenu - 1] !== "Treliça H" ? "superiorDiagonalData" : "");
    } else {
      setBarSelectedInMenu("");
      setDiagonalSelectedInMenu("");
    }
    setNBars(countBars());
  }, [segmentSelectedInMenu]);

  useEffect(() => {
    setNBars(countBars());
  }, [inputValues]);

  function setInitialEmptyValues() {
    let initialData = [];
    for (let i = 0; i < nSegments; i++) {
      initialData[i] = {
        segmentId: segmentsSelected[i].segmentId || "",
        trussType: trussesTypes[i],
        superiorDiagonalData: trussesTypes[i] === "Treliça H" ? {} : {
          hasDiagonalLocking: "",
          lockingPoints: "",
          lockingType: "",
          bars: [],
        },
        inferiorDiagonalData: trussesTypes[i] !== "Treliça X" ? {} : {
          hasDiagonalLocking: "",
          lockingPoints: "",
          lockingType: "",
          bars: [],
        },
      };

      if (trussesTypes[i] !== "Treliça H" && diagonalLockingSelected[i]?.superiorDiagonalData) {
        const superiorDiagonalInfo = diagonalLockingSelected[i].superiorDiagonalData;
        const superiorLockingPoints = diagonalLockingsInfo[superiorDiagonalInfo.lockingPoints];

        initialData[i].superiorDiagonalData.hasDiagonalLocking = superiorDiagonalInfo.hasDiagonalLocking || "";
        initialData[i].superiorDiagonalData.lockingPoints = superiorDiagonalInfo.lockingPoints || "";
        initialData[i].superiorDiagonalData.lockingType = superiorDiagonalInfo.lockingType || "";

        if (superiorLockingPoints) {
          let nSuperiorBars = superiorLockingPoints.find(type => type.lockingType === superiorDiagonalInfo.lockingType)?.bars || 0;
          initialData[i].superiorDiagonalData.bars = Array.from({ length: nSuperiorBars }, (_, j) => ({
            barNumber: j + 1,
            isMissingBar: superiorDiagonalInfo.bars[j]?.isMissingBar || false,
            profileSteel: superiorDiagonalInfo.bars[j]?.profileSteel || "",
            profileType: superiorDiagonalInfo.bars[j]?.profileType || "",
            profileDimensions: superiorDiagonalInfo.bars[j]?.profileDimensions || "",
            profileDiameter: superiorDiagonalInfo.bars[j]?.profileDiameter || "",
            profileThickness: superiorDiagonalInfo.bars[j]?.profileThickness || "",
            profileFlange: superiorDiagonalInfo.bars[j]?.profileFlange || "",
            profileWeb: superiorDiagonalInfo.bars[j]?.profileWeb || "",
            boltsSteel: superiorDiagonalInfo.bars[j]?.boltsSteel || "",
            numConnectionBolts: superiorDiagonalInfo.bars[j]?.numConnectionBolts || "",
            diameterConnectionBolts: superiorDiagonalInfo.bars[j]?.diameterConnectionBolts || "",
          }));
        }
      }

      if (trussesTypes[i] === "Treliça X" && diagonalLockingSelected[i]?.inferiorDiagonalData) {
        const inferiorDiagonalInfo = diagonalLockingSelected[i].inferiorDiagonalData;
        const inferiorLockings = diagonalLockingsInfo[inferiorDiagonalInfo.lockingPoints];

        initialData[i].inferiorDiagonalData.hasDiagonalLocking = inferiorDiagonalInfo.hasDiagonalLocking || "";
        initialData[i].inferiorDiagonalData.lockingPoints = inferiorDiagonalInfo.lockingPoints || "";
        initialData[i].inferiorDiagonalData.lockingType = inferiorDiagonalInfo.lockingType || "";

        if (inferiorLockings) {
          let nInferiorBars = inferiorLockings.find(type => type.lockingType === inferiorDiagonalInfo.lockingType)?.bars || 0;
          initialData[i].inferiorDiagonalData.bars = Array.from({ length: nInferiorBars }, (_, j) => ({
            barNumber: j + 1,
            isMissingBar: inferiorDiagonalInfo.bars[j]?.isMissingBar || false,
            profileSteel: inferiorDiagonalInfo.bars[j]?.profileSteel || "",
            profileType: inferiorDiagonalInfo.bars[j]?.profileType || "",
            profileDimensions: inferiorDiagonalInfo.bars[j]?.profileDimensions || "",
            profileDiameter: inferiorDiagonalInfo.bars[j]?.profileDiameter || "",
            profileThickness: inferiorDiagonalInfo.bars[j]?.profileThickness || "",
            profileFlange: inferiorDiagonalInfo.bars[j]?.profileFlange || "",
            profileWeb: inferiorDiagonalInfo.bars[j]?.profileWeb || "",
            boltsSteel: inferiorDiagonalInfo.bars[j]?.boltsSteel || "",
            numConnectionBolts: inferiorDiagonalInfo.bars[j]?.numConnectionBolts || "",
            diameterConnectionBolts: inferiorDiagonalInfo.bars[j]?.diameterConnectionBolts || "",
          }));
        }
      }
    }
    const model = new DiagonalLockingsModel(initialData);
    setInputValues(model.diagonalLockings);
  }

  function countBars() {
    let updatedBars = {
      superiorDiagonalData: [],
      inferiorDiagonalData: [],
    };

    if (segmentSelectedInMenu === "" || diagonalSelectedInMenu === "" || inputValues.length === 0) return updatedBars;

    let nSuperiorDiagonalBars;
    let nInferiorDiagonalBars;

    for (let i = 0; i < nSegments; i++) {
      nSuperiorDiagonalBars = 0;
      nInferiorDiagonalBars = 0;

      if (trussesTypes[i] !== "Treliça H") {
        if (inputValues[i].superiorDiagonalData?.lockingType !== "") {
          nSuperiorDiagonalBars = diagonalLockingsInfo[inputValues[i].superiorDiagonalData?.lockingPoints]?.filter(
            type => type?.lockingType === inputValues[i].superiorDiagonalData?.lockingType
          )[0]?.bars;
        }
        nSuperiorDiagonalBars
          ? updatedBars.superiorDiagonalData.push(nSuperiorDiagonalBars)
          : updatedBars.superiorDiagonalData.push(0);
      } else {
        updatedBars.superiorDiagonalData.push(0);
      }

      if (trussesTypes[i] === "Treliça X") {
        if (inputValues[i]?.inferiorDiagonalData?.lockingType !== "") {
          nInferiorDiagonalBars = diagonalLockingsInfo[inputValues[i]?.inferiorDiagonalData?.lockingPoints]?.filter(
            type => type?.lockingType === inputValues[i]?.inferiorDiagonalData?.lockingType
          )[0]?.bars;
        }
        nInferiorDiagonalBars
          ? updatedBars.inferiorDiagonalData.push(nInferiorDiagonalBars)
          : updatedBars.inferiorDiagonalData.push(0);
      } else {
        updatedBars.inferiorDiagonalData.push(0);
      }
    }

    return updatedBars;
  }

  const fieldsToCheck = ["superiorDiagonalData.hasDiagonalLocking", "superiorDiagonalData.lockingPoints",
    "superiorDiagonalData.lockingType", "superiorDiagonalData.bars.isMissingBar",
    "superiorDiagonalData.bars.profileType", "superiorDiagonalData.bars.profileSteel",
    "superiorDiagonalData.bars.profileDimensions", "superiorDiagonalData.bars.profileDiameter",
    "superiorDiagonalData.bars.profileThickness", "superiorDiagonalData.bars.profileFlange",
    "superiorDiagonalData.bars.profileWeb", "superiorDiagonalData.bars.boltsSteel",
    "superiorDiagonalData.bars.numConnectionBolts", "superiorDiagonalData.bars.diameterConnectionBolts",
    "inferiorDiagonalData.hasDiagonalLocking", "inferiorDiagonalData.lockingPoints",
    "inferiorDiagonalData.lockingType", "inferiorDiagonalData.bars.isMissingBar",
    "inferiorDiagonalData.bars.profileType", "inferiorDiagonalData.bars.profileSteel",
    "inferiorDiagonalData.bars.profileDimensions", "inferiorDiagonalData.bars.profileDiameter",
    "inferiorDiagonalData.bars.profileThickness", "inferiorDiagonalData.bars.profileFlange",
    "inferiorDiagonalData.bars.profileWeb", "inferiorDiagonalData.bars.boltsSteel",
    "inferiorDiagonalData.bars.numConnectionBolts", "inferiorDiagonalData.bars.diameterConnectionBolts",
  ];
  const isInputModified = useUnsavedChangesAlertForObjects(
    [...inputValues], diagonalLockingSelected, reloadProject, fieldsToCheck);

  const handleInputChange = useCallback((field, value) => {
    if (segmentSelectedInMenu === "" || diagonalSelectedInMenu === "" || field === "") return;
    const currentValues = new DiagonalLockingsModel(inputValues);
    const segmentIndex = segmentSelectedInMenu - 1;

    const fieldsToUpdate = ["isMissingBar", "profileType", "profileSteel", "profileDimensions", "profileDiameter",
      "profileThickness", "profileFlange", "profileWeb", "boltsSteel", "numConnectionBolts", "diameterConnectionBolts"];

    if (field === "hasDiagonalLocking" || field === "lockingPoints" || field === "lockingType") {
      setBarSelectedInMenu(inputValues[segmentIndex][diagonalSelectedInMenu]?.bars.length === 0 ? 1 : "");
      currentValues.updateDiagonalLocking(inputValues[segmentIndex].segmentId,
        diagonalSelectedInMenu, field, value);
    } else if (field === "isMissingBar") {
      const missingBar = inputValues[segmentIndex][diagonalSelectedInMenu].bars[barSelectedInMenu - 1]?.isMissingBar;
      missingBar === true
        ? currentValues.updateDiagonalLockingBar(inputValues[segmentIndex].segmentId,
          diagonalSelectedInMenu, barSelectedInMenu - 1, field, false)
        : currentValues.updateDiagonalLockingBar(inputValues[segmentIndex].segmentId,
          diagonalSelectedInMenu, barSelectedInMenu - 1, field, true);
    } else {
      currentValues.updateDiagonalLockingBar(inputValues[segmentIndex].segmentId,
        diagonalSelectedInMenu, barSelectedInMenu - 1, field, value);
    }

    if (field === "hasDiagonalLocking" && value === "Sim" && segmentSelectedInMenu > 1
      && inputValues[segmentIndex - 1][diagonalSelectedInMenu].hasDiagonalLocking === "Sim") {
      currentValues.updateDiagonalLocking(inputValues[segmentIndex].segmentId, diagonalSelectedInMenu,
        "lockingPoints", inputValues[segmentIndex - 1][diagonalSelectedInMenu].lockingPoints);
      currentValues.updateDiagonalLocking(inputValues[segmentIndex].segmentId, diagonalSelectedInMenu,
        "lockingType", inputValues[segmentIndex - 1][diagonalSelectedInMenu].lockingType);
      currentValues.diagonalLockings[segmentIndex][diagonalSelectedInMenu]?.bars?.map(
        (bar, i) => fieldsToUpdate.forEach(f => {
          if (currentValues.diagonalLockings[segmentIndex - 1][diagonalSelectedInMenu]?.bars[i]) {
            currentValues.updateDiagonalLockingBar(
              currentValues.diagonalLockings[segmentIndex].segmentId, diagonalSelectedInMenu, i, f,
              currentValues.diagonalLockings[segmentIndex - 1][diagonalSelectedInMenu]?.bars[i][f]);
          }
        })
      );
    }

    setInputValues(currentValues.diagonalLockings);
  }, [inputValues, setInputValues, segmentSelectedInMenu, diagonalSelectedInMenu, barSelectedInMenu, setBarSelectedInMenu]);

  function isFieldEnabled() {
    if (segmentSelectedInMenu === "" || diagonalSelectedInMenu === "" || Object.keys(inputValues).length === 0) {
      return false;
    }
    return inputValues[segmentSelectedInMenu - 1][diagonalSelectedInMenu]?.hasDiagonalLocking === "Sim";
  }

  async function updateDiagonalLockingsData() {
    const diagonalLockingsModel = new DiagonalLockingsModel(inputValues);
    if (!diagonalLockingsModel.validate()) return false;

    setIsLoading(true);
    try {
      const updatedData = await saveDiagonalLockings(JSON.stringify(inputValues), towerId, userData.token);
      setDiagonalLockingSelected(updatedData);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações dos travamentos diagonais atualizadas com sucesso");
      return true;
    } catch (error) {
      toast.error("Erro ao salvar dados dos travamentos diagonais.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Travamentos Diagonais"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updateDiagonalLockingsData}
      >
        <TowerDiagonalLockingLeftMenu
          nSegments={nSegments}
          nBars={nBars}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          setSegmentSelectedInMenu={setSegmentSelectedInMenu}
          diagonalSelectedInMenu={diagonalSelectedInMenu}
          setDiagonalSelectedInMenu={setDiagonalSelectedInMenu}
          barSelectedInMenu={barSelectedInMenu}
          setBarSelectedInMenu={setBarSelectedInMenu}
          handleInputChange={(field, value) => handleInputChange(field, value)}
          isFieldEnabled={() => isFieldEnabled()}
          trussesTypes={trussesTypes}
        />
      </LeftMenu>
      <RightContainer>
        <TowerDiagonalLockingContainer
          nSegments={nSegments}
          nBars={nBars}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          diagonalSelectedInMenu={diagonalSelectedInMenu}
          handleInputChange={(field, value) => handleInputChange(field, value)}
          isFieldEnabled={() => isFieldEnabled()}
          towerImages={towerImages}
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
