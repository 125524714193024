import styled from "styled-components";
import { useContext } from "react";
import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";
import { CustomSelect } from "../../common/custom-select";
import AutocompleteInput from "../../common/customAutoCompleteInput";
import { MenuContext } from "../../../contexts/MenuContext";
import { mountProfileTypes, tubeProfileData, mountConnectionType,
  boltsDiameter, boltsSteel } from "../../../constants/tower-constants";
import { handleProfileSteelTypes, handleProfileDimensions } from "../../../utils/tower_handleProfilesData";
import colors from "../../../constants/color-constants";

export default function TowerMountReforceContainer({
  segmentSelectedInMenu,
  inputValues,
  handleReinforcementChange,
}) {
  const { leftMenuWidth } = useContext(MenuContext);
  const currentMountReforce = inputValues[segmentSelectedInMenu - 1]?.reinforcementData;

  return (
    <>
      {
        inputValues[segmentSelectedInMenu - 1]?.hasReinforcement !== "Sim" ?
          <></> :
          <Container width={leftMenuWidth}>
            <h2 className="title">Montante Reforço</h2>
            <InputMenu>
              <h2>Tipo de Perfil</h2>
              <select
                value={inputValues[segmentSelectedInMenu - 1]?.reinforcementData?.profileType ?? ""}
                onChange={(e) => handleReinforcementChange("profileType", e.target.value)}
              >
                <option value="" key="profileType-empty">Selecione o tipo de perfil</option>
                {mountProfileTypes.map((type) => (
                  <option value={type} key={`profileType-${type}`}>{type}</option>
                ))}
              </select>
            </InputMenu>

            <InputMenu>
              <h2>Aço do Perfil</h2>
              <select
                value={currentMountReforce?.profileSteel ?? ""}
                onChange={(e) => handleReinforcementChange("profileSteel", e.target.value)}
              >
                <option value="" key="profileSteel-empty">Selecione o aço do perfil</option>
                {currentMountReforce?.profileType &&
                  handleProfileSteelTypes(currentMountReforce?.profileType)?.map((type) => (
                    <option value={type} key={`profileSteel-${type}`}>{type}</option>
                  ))}
              </select>
            </InputMenu>

            {(currentMountReforce?.profileType && currentMountReforce?.profileSteel) && 
              <InputMenu>
                <h2>Dimensões do Perfil</h2>
                {
                  currentMountReforce?.profileType === "L - Cantoneira" &&
                    <AutocompleteInput
                      inputValue={currentMountReforce?.profileDimensions || ""}
                      handleInputChange={(field, value) => handleReinforcementChange(field, value)}
                      list={handleProfileDimensions(currentMountReforce?.profileType) || []}
                      field={"profileDimensions"}
                      placeholder={"Selecione as dimensões do perfil"}
                    />
                }
                {
                  currentMountReforce?.profileType === "T - Tubo Vazado" && 
                  <div>
                    <AutocompleteInput
                      inputValue={currentMountReforce?.profileDiameter || ""}
                      handleInputChange={(field, value) => handleReinforcementChange(field, value)}
                      list={currentMountReforce.profileSteel
                        ? handleProfileDimensions(currentMountReforce?.profileType, currentMountReforce?.profileSteel)
                        : []}
                      field={"profileDiameter"}
                      placeholder={"Diâmetro"}
                      label={"Diâmetro"}
                    />

                    <AutocompleteInput
                      inputValue={currentMountReforce?.profileThickness || ""}
                      handleInputChange={(field, value) => handleReinforcementChange(field, value)}
                      list={currentMountReforce?.profileDiameter
                        ? tubeProfileData[currentMountReforce?.profileSteel][currentMountReforce?.profileDiameter] : []}
                      field={"profileThickness"}
                      placeholder={"Espessura"}
                      label={"Espessura"}
                    />
                  </div>
                }
                {
                  currentMountReforce?.profileType === "BR - Barra Maciça" &&
                    <AutocompleteInput
                      inputValue={currentMountReforce?.profileDiameter || ""}
                      handleInputChange={(field, value) => handleReinforcementChange(field, value)}
                      list={currentMountReforce.profileSteel
                        ? handleProfileDimensions(currentMountReforce?.profileType) : []}
                      field={"profileDiameter"}
                      placeholder={"Selecione o diâmetro do perfil"}
                    />
                }
                {
                  currentMountReforce?.profileType === "O - Ômega" &&
                    <>
                      <div>
                        <CustomInput
                          placeholder={"Aba (mm)"}
                          label={"Aba"}
                          value={currentMountReforce?.profileFlange ?? ""}
                          onChange={(newValue) => handleReinforcementChange("profileFlange", newValue)}
                          rawNumber={true}
                          unit={"mm"}
                        />

                        <CustomInput
                          placeholder={"Alma (mm)"}
                          label={"Alma"}
                          value={currentMountReforce?.profileWeb ?? ""}
                          onChange={(newValue) => handleReinforcementChange("profileWeb", newValue)}
                          rawNumber={true}
                          unit={"mm"}
                        />
                      </div>
                      <div>
                        <AutocompleteInput
                          inputValue={currentMountReforce?.profileThickness || ""}
                          handleInputChange={(field, value) => handleReinforcementChange(field, value)}
                          list={handleProfileDimensions(currentMountReforce.profileType) || []}
                          field={"profileThickness"}
                          placeholder={"Selecione a espessura do perfil"}
                          label={"Espessura"}
                        />
                      </div>
                    </>
                }
              </InputMenu>
            }

            <InputMenu>
              <h2>Conexão no Montante</h2>
              <select
                disabled={segmentSelectedInMenu === ""}
                value={currentMountReforce?.mountConnection ?? ""}
                onChange={(e) => handleReinforcementChange("mountConnection", e.target.value)}
              >
                <option value="" key="mountConnection-empty">Selecione a conexão no Montante</option>
                {mountConnectionType.map((type) => (
                  <option value={type} key={`mountConnection-${type}`}>{type}</option>
                ))}
              </select>
            </InputMenu>

            <InputMenu>
              <h2>Aço dos Parafusos</h2>
              <select
                disabled={segmentSelectedInMenu === "" || currentMountReforce?.mountConnection === "Não há"}
                value={currentMountReforce?.mountConnection === "Não há" ? "" : currentMountReforce?.boltsSteel ?? ""}
                onChange={(e) => handleReinforcementChange("boltsSteel", e.target.value)}
              >
                <option value="" key="boltsSteel-empty">Selecione o aço dos parafusos</option>
                {boltsSteel.map((type) => (
                  <option value={type} key={`boltsSteel-${type}`}>{type}</option>
                ))}
              </select>
            </InputMenu>

            <InputMenu>
              <h2>Parafusos de Conexão</h2>
              <div>
                <CustomInput
                  disabled={segmentSelectedInMenu === "" || currentMountReforce?.mountConnection === "Não há"}
                  placeholder={"Quantidade"}
                  label={"Quantidade"}
                  value={currentMountReforce?.mountConnection === "Não há"
                    ? "" : currentMountReforce?.numConnectionBolts ?? ""}
                  onChange={(newValue) => handleReinforcementChange("numConnectionBolts", newValue)}
                  toFixed={0}
                />
                  
                <CustomSelect
                  disabled={segmentSelectedInMenu === ""
                    || inputValues[segmentSelectedInMenu - 1]?.reinforcementData?.mountConnection === "Não há"}
                  label={"Diâmetro"}
                  value={inputValues[segmentSelectedInMenu - 1]?.reinforcementData?.mountConnection === "Não há"
                    ? "" : currentMountReforce?.diameterConnectionBolts ?? ""}
                  onChange={(e) => handleReinforcementChange("diameterConnectionBolts", e)}
                >
                  <option value="" key={"diameterConnectionBolts-empty"}>Diâmetro</option>
                  {boltsDiameter.map((type) => (
                    <option value={type} key={`diameterConnectionBolts-${type}`}>{type}</option>
                  ))}
                </CustomSelect>
              </div>
            </InputMenu>
          </Container>
      }
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${(props) => props.leftMenuWidth};
  min-width: 320px;
  max-width: 380px;
  height: min-content;
  padding: 18px 30px 18px;
  background-color: ${colors.kcLightGray};
  color: ${colors.kcTextColor};
  font-family: 'Roboto', sans-serif;
  

  h2 {
    color: ${colors.kcTextColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }

  .title {
    margin-bottom: 12px;
  }
`;
