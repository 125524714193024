import styled from "styled-components";
import { useContext, useRef } from "react";
import { ProjectContext } from "../../contexts/ProjectContext";
import "react-toastify/dist/ReactToastify.css";
import LoadingAnimation from "../common/loading-animation";

export default function ProjectLeftMenuTower({ isDonwloadingStrap }) {
  const { leftMenuOptionSelected, setLeftMenuOptionSelected } = useContext(ProjectContext);
  const containerRef = useRef(null);

  return (
    <StyledLeftMenu
      isDonwloadingStrap={isDonwloadingStrap}
      ref={containerRef}
    >
      {(leftMenuOptionSelected.length === 0 || leftMenuOptionSelected === "calcular") ? (
        <>
          <SessionTitle>
            <h1>Dados Gerais</h1>
            <GeneralDataContainer>
              <GeneralDataSingleBigContainer onClick={() => setLeftMenuOptionSelected("geometria")}>
                GEOMETRIA
              </GeneralDataSingleBigContainer>
              <GeneralDataSingleBigContainer onClick={() => setLeftMenuOptionSelected("trelicamento")}>
                TRELIÇAMENTO
              </GeneralDataSingleBigContainer>
            </GeneralDataContainer>
            <GeneralDataContainer>
              <GeneralDataSingleBigContainer onClick={() => setLeftMenuOptionSelected("secoes")}>
                SEÇÕES
              </GeneralDataSingleBigContainer>
              <GeneralDataSingleBigContainer onClick={() => setLeftMenuOptionSelected("plataformas")}>
                PLATAFORMAS
              </GeneralDataSingleBigContainer>
            </GeneralDataContainer>
          </SessionTitle>
          <SessionTitle>
            <h1>Carregamento</h1>
            <GeneralDataContainer>
              <GeneralDataSingleMediumContainer onClick={() => setLeftMenuOptionSelected("vento")}>
                VENTO
              </GeneralDataSingleMediumContainer>
              <GeneralDataSingleMediumContainer onClick={() => setLeftMenuOptionSelected("antenas")}>
                ANTENAS
              </GeneralDataSingleMediumContainer>
            </GeneralDataContainer>
            <GeneralDataContainer>
              <GeneralDataSingleBigContainer onClick={() => setLeftMenuOptionSelected("esteira")}>
                ACESSÓRIOS E ESTEIRA
              </GeneralDataSingleBigContainer>
            </GeneralDataContainer>
          </SessionTitle>
          <SessionTitle>
            <h1>Perfis e Fixações</h1>
            <GeneralDataContainer>
              <GeneralDataSingleMediumContainer onClick={() => setLeftMenuOptionSelected("montantes")}>
                MONTANTES
              </GeneralDataSingleMediumContainer>
              <GeneralDataSingleMediumContainer onClick={() => setLeftMenuOptionSelected("diagonais")}>
                DIAGONAIS
              </GeneralDataSingleMediumContainer>
            </GeneralDataContainer>
            <GeneralDataContainer>
              <GeneralDataSingleMediumContainer onClick={() => setLeftMenuOptionSelected("horizontais")}>
                HORIZONTAIS
              </GeneralDataSingleMediumContainer>
              <GeneralDataSingleMediumContainer onClick={() => setLeftMenuOptionSelected("redundantes")}>
                REDUNDANTES
              </GeneralDataSingleMediumContainer>
            </GeneralDataContainer>
            <GeneralDataContainer>
              <GeneralDataSingleMediumContainer onClick={() => setLeftMenuOptionSelected("travamentos-diagonais")}>
                TRAVAMENTOS DIAGONAIS
              </GeneralDataSingleMediumContainer>
              <GeneralDataSingleMediumContainer onClick={() => setLeftMenuOptionSelected("travamentos-horizontais")}>
                TRAVAMENTOS HORIZONTAIS
              </GeneralDataSingleMediumContainer>
            </GeneralDataContainer>
            <GeneralDataContainer>
              <GeneralDataSingleBigContainer onClick={() => setLeftMenuOptionSelected("ancoragem")}>
                ANCORAGEM
              </GeneralDataSingleBigContainer>
            </GeneralDataContainer>
          </SessionTitle>
          <SessionTitle>
            <h1>Dimensionamento</h1>
            <GeneralDataContainer>
              <GeneralDataSingleBigContainer onClick={() => setLeftMenuOptionSelected("calcular")}>
                CALCULAR
              </GeneralDataSingleBigContainer>
            </GeneralDataContainer>
            <GeneralDataContainer>
              <GeneralDataSingleMediumContainer onClick={() => setLeftMenuOptionSelected("metalica")}>
                METÁLICA
              </GeneralDataSingleMediumContainer>
              <GeneralDataSingleMediumContainer onClick={() => setLeftMenuOptionSelected("chumbadores")}>
                CHUMBADORES
              </GeneralDataSingleMediumContainer>
            </GeneralDataContainer>
          </SessionTitle>
        </>
      ) : <LoadingAnimation />}
    </StyledLeftMenu>
  );
}

const StyledLeftMenu = styled.div`
  display: flex;
  width: 30%;
  min-width: 300px;
  max-width: 400px;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
  border-right: 1px solid #ffffff;
  background-color: #F2F2F2;
  transition: width 0.2s ease-in-out;
  pointer-events: ${(props) => (props.isDonwloadingStrap ? "none" : "inherit")};
`;

export const SessionTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  opacity: ${(props) => props.opacity};
  h1 {
    font-weight: 500;
    color: #000000;
    font-size: 20px;
    padding-bottom: 8px;
    padding-left: 5px;
  }
`;

export const BottomBorderTitle = styled.div`
  width: 100%;
  height: 1px;
  background-color: #808080;
  margin-bottom: 10px;
`;

export const GeneralDataContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    color: #010101;
    border: solid 1px #C8C8C8;
    margin: 5px;
    transition-duration: 100ms;
    transition-property: border, color;
    background-color: #fff;
    cursor: pointer;
    :hover {
      border: none;
      color: #FFFFFF;
      background-color: #000;
      box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3);
    }
  }
`;

const GeneralDataSingleBigContainer = styled.div`
  width: 100%;
  height: 5vh;
  background-color: #fff;
`;

export const GeneralDataSingleMediumContainer = styled.div`
  width: 47%;
  height: 5vh;
  display: flex;
  line-height: 17px;
  position: relative;
  overflow: hidden;
  align-items: ${(props) => (Number(props.textSize) > 30 ? "flex-start !important" : "center")};

  span {
    color: #7f7f7f;
  }

  :hover {
    ion-icon,
    span {
      color: #F16E00;
      display: inherit;
    }
  }

  ion-icon {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 21px;
    display: none;
    z-index: 2;
  }
`;
