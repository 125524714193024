import styled from "styled-components";
import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";
import { CustomSelect } from "../../common/custom-select";
import { anchorsSteel, boltsSteel, boltsDiameter } from "../../../constants/tower-constants";
import colors from "../../../constants/color-constants";

export default function TowerAnchorsLeftMenu({ inputValues, anchorTypes, handleInputChange }) {
  return (
    <>
      <InputMenu>
        <h2>Tipo de Ancoragem</h2>
        <select
          className={inputValues.anchorType === "" ? null : "highlightColor"}
          name={"anchorType"}
          value={inputValues.anchorType}
          onChange={handleInputChange}
        >
          <option value={""} key={"anchorType-empty"}>Selecione o tipo de ancoragem</option>
          {anchorTypes.map((type) => (
            <option value={type} key={type}>{type}</option>
          ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>Tipo de Aço da Placa de Base</h2>
        <select
          name="basePlateSteelType"
          value={inputValues.basePlateSteelType}
          onChange={handleInputChange}>
          <option value={""} key={"basePlateSteelType-empty"}>Selecione o tipo de aço da placa base</option>
          {anchorsSteel.map((type) => (
            <option value={type} key={type}>{type}</option>
          ))}
        </select>
      </InputMenu>

      <DimensionsTitle>Dimensões da Placa de Base</DimensionsTitle>
      <InputMenu>
        <div>
          <div>
            <CustomInput
              placeholder={"Largura 1"}
              label={"Largura 1"}
              name="basePlateL1"
              value={inputValues?.basePlateL1 ?? ""}
              onChange={(newValue) => handleInputChange({ target: { name: "basePlateL1", value: newValue } })}
              rawNumber={true}
              isNumber={true}
              unit={"mm"}
            />
          </div>
          <div>
            <CustomInput
              placeholder={"Largura 2"}
              label={"Largura 2"}
              name="basePlateL2"
              value={inputValues?.basePlateL2 ?? ""}
              onChange={(newValue) => handleInputChange({ target: { name: "basePlateL2", value: newValue } })}
              rawNumber={true}
              isNumber={true}
              unit={"mm"}
            />
          </div>
          <div>
            <CustomSelect
              placeholder={"Espessura"}
              label={"Espessura"}
              name="basePlateThickness"
              value={inputValues?.basePlateThickness ?? ""}
              onChange={(newValue) => handleInputChange({ target: { name: "basePlateThickness", value: newValue } })}
            >
              <option value={""} key={"basePlateThickness-empty"}>Espessura</option>
              {boltsDiameter.map((type) => (
                <option value={type} key={type}>{type}</option>
              ))}
            </CustomSelect>
          </div>
        </div>
      </InputMenu>

      <InputMenu>
        <div>
          <div>
            <CustomInput
              placeholder={"Distância 1"}
              label={"Distância 1"}
              name="basePlateDistance1"
              value={inputValues?.basePlateDistance1 ?? ""}
              onChange={(newValue) => handleInputChange({ target: { name: "basePlateDistance1", value: newValue } })}
              rawNumber={true}
              isNumber={true}
              unit={"mm"}
            />
          </div>
          <div>
            <CustomInput
              placeholder={"Distância 2"}
              label={"Distância 2"}
              name="basePlateDistance2"
              value={inputValues?.basePlateDistance2 ?? ""}
              onChange={(newValue) => handleInputChange({ target: { name: "basePlateDistance2", value: newValue } })}
              rawNumber={true}
              isNumber={true}
              unit={"mm"}
            />
          </div>
        </div>
      </InputMenu>

      <InputMenu>
        <h2>Tipo de Aço do Chumbador</h2>
        <select
          value={inputValues.anchorSteelType ?? ""}
          name="anchorSteelType"
          onChange={handleInputChange}>
          <option value={""} key={"anchorSteelType-empty"}>Selecione o tipo de aço do chumbador</option>
          {boltsSteel.map((type) => (
            <option value={type} key={type}>{type}</option>
          ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>Chumbadores</h2>
        <div>
          <CustomInput
            placeholder={"Quantidade"}
            label={"Quantidade"}
            name="anchorBoltsNumber"
            value={inputValues?.anchorBoltsNumber ?? ""}
            onChange={(newValue) => handleInputChange({ target: { name: "anchorBoltsNumber", value: newValue } })}
            isNumber={true}
            toFixed={0}
          />

          <CustomSelect
            placeholder={"Diâmetro"}
            label={"Diâmetro"}
            name="anchorBoltsDiameter"
            value={inputValues?.anchorBoltsDiameter ?? ""}
            onChange={(newValue) => handleInputChange({ target: { name: "anchorBoltsDiameter", value: newValue } })}
          >
            <option value={""} key={"anchorBoltsDiameter-empty"}>Diâmetro</option>
            {boltsDiameter.map((type) => (
              <option value={type} key={type}>{type}</option>
            ))}
          </CustomSelect>
        </div>
      </InputMenu>
    </>
  );
};

const DimensionsTitle = styled.h2`
  color:${colors.kcTextColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 4px;
`;
