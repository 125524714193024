import trussImages from "../assets/images/towerImages/trussTypes/trusses-images";

const getTowerImages = (trussesSelected) => {
  if (!trussesSelected || trussesSelected?.length === 0) return null;

  return trussesSelected?.map((value) => {
    if (!value?.trussType || !value?.mountDivisions || !value?.bracingType) return null;
    const imageName = `${value.trussType.charAt(value.trussType.length - 1)}${value.mountDivisions}${value.bracingType}`;
    return trussImages[value.trussType][imageName];
  });
};

export default getTowerImages;
