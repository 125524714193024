import styled from "styled-components";
import { CustomInput } from "../../common/custom-input";
import CustomRadioButton from "../../common/custom-radio-buttons";
import TowerSilhouettePlatforms from "./tower-silhouette-platforms";
import platformsImages from "../../../assets/images/towerImages/platformsTypes/platforms-images";
import colors from "../../../constants/color-constants";

export default function TowerPlatformRightContainer({
  inputValues,
  platformSelectedInMenu, 
  setPlatformSelectedInMenu,
  handleInputChange, 
  addPlatform,
  towerSection,
  geometryTotalHeight,
  towerImages,
  setTowerImages,
  trussesSelected,
}) {
  function renderPlatformsTypes() {
    return (
      <div>
        <SectionImg
          selected={platformSelectedInMenu === "" ? false :
            inputValues[platformSelectedInMenu - 1]?.platformType === "Interna"}
          onClick={() => handleInputChange("platformType", "Interna")}
        >
          <CustomRadioButton
            name="platformType"
            value="Interna"
            disabled={platformSelectedInMenu === ""}
            checked={inputValues[platformSelectedInMenu - 1]?.platformType === "Interna"}
            onClick={() => handleInputChange("platformType", "Interna")}
          />
          <img src={towerSection === "Quadrada"
            ? platformsImages.platformInternalSquared
            : platformsImages.platformInternalTriangular
          } width={"115px"} alt="Interna"/>
        </SectionImg>

        <SectionImg
          selected={platformSelectedInMenu === "" ? false :
            inputValues[platformSelectedInMenu - 1]?.platformType === "Externa"}
          onClick={() => handleInputChange("platformType", "Externa")}
        >
          <CustomRadioButton
            name="platformType"
            value="Externa"
            disabled={platformSelectedInMenu === ""}
            checked={inputValues[platformSelectedInMenu - 1]?.platformType === "Externa"}
            onClick={() => handleInputChange("platformType", "Externa")}
          />
          <img src={towerSection === "Quadrada"
            ? platformsImages.platformExternalSquared
            : platformsImages.platformExternalTriangular
          } width={"115px"} alt="Externa"/>
        </SectionImg>
      </div>
    );
  }

  return (
    <Container>
      <OptionsContainer>
        <h2>Tipo de Plataforma</h2>

        {renderPlatformsTypes()}

        <div>
          <ElevationContainer>
            <h2>Elevação da<br/>Plataforma (m)</h2>
            <CustomInput
              disabled={platformSelectedInMenu===""}
              value={inputValues[platformSelectedInMenu - 1]?.platformElevation}
              onChange={newValue => handleInputChange("platformElevation", newValue)}
              isNumber={true}
              unit={"m"}
            />
          </ElevationContainer>
        </div>
      </OptionsContainer>

      <TowerGeometryImgContainer>
        <h2>Plataformas na Torre</h2>

        <TowerSilhouettePlatforms
          images={towerImages}
          inputValues={inputValues}
          geometryTotalHeight={geometryTotalHeight}
          platformSelectedInMenu={platformSelectedInMenu}
          setPlatformSelectedInMenu={setPlatformSelectedInMenu}
          handleInputChange={(field, value, platform) => handleInputChange(field, value, platform)}
          trussesSelected={trussesSelected}
          setTowerImages={setTowerImages}
          addPlatform={() => addPlatform()}
        />
      </TowerGeometryImgContainer>
    </Container>
  );  
}

const Container = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: flex-start;
`;

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 165px;
    max-height: calc(100vh - 245px);
    padding: 20px;
    background-color: ${colors.kcLightGray};
    border-radius: 6px;
    min-width: 250px;
    width: min-content;
    height: min-content;
    box-sizing: border-box;
    gap: 20px;

    h2 {
        color: ${colors.kcTextColor};
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: 500;
    }
  
    >div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        gap: 20px;
    }
`;

const SectionImg = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 165px;
    height: 165px;
    padding: 15px;
    background-color: ${colors.kcWhiteColor};
    border-radius: 8px;
    border: ${(props) => props.selected ? `2px solid ${colors.kcOrange}` : "none"};
    box-sizing: border-box;
  
    img {
        width: ${(props) => props.width};
    }
`;

const ElevationContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    width: 150px;
    min-height: min-content;
    border: ${(props) => props.selected ? `2px solid ${colors.kcOrange}` : "none"};
    box-sizing: border-box;
    
    h2 {
        font-size: 14px;
        font-weight: 500;
        color: ${colors.kcTextColor};
        line-height: 1.2;
        letter-spacing: 0.15px;
        margin-bottom: 6px;
        font-family: 'Roboto', sans-serif;
    }
    
    input {
        width: 100%;
        border: 1px solid ${colors.kcBorderGray};
        background-color: ${colors.kcWhiteColor};
        height: 40px;
        padding: 6px;
        border-radius: 8px;
        font-size: 12px;
        text-align: center;
        font-family: 'Roboto', sans-serif;
    }
`;

const TowerGeometryImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 165px;
    max-height: calc(100vh - 245px);
    padding: 20px;
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    background-color: ${colors.kcLightGray};
    font-family: 'Roboto', sans-serif;
    gap: 20px;

    h2 {
        font-size: 14px;
        font-weight: 500;
        color: ${colors.kcBlackColor};
    }
`;
