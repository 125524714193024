import styled from "styled-components";
import { useEffect, useState } from "react";
import MastGeometryImg from "../geometry-components/mast-geometryImg";

export default function ModuleRightContainer({ inputValues, mastProps }) {
  const [mastImgId, setMastImgId] = useState("MA_1");
  const [mastImgProps, setMastImgProps] = useState({ paddingTop: "100px", imageSize: "50vh" });
  const qtdModulos = Number(mastProps.qtdModulos);
  const qtdFlanges = qtdModulos - 1;
  const modulesArray = Array.from({ length: qtdModulos });
  const flangesArray = Array.from({ length: qtdFlanges });

  const positionCorrections = 
  // eslint-disable-next-line object-curly-spacing
  { MA: {flangeAux: 3, 1: {module: (1 - (qtdModulos / 17)), flange: (1 - (qtdModulos / 17))}, 2: {module: (1 + (qtdModulos / 10)), flange: (1 + (qtdModulos / 8))}, 3: {module: (1 - (qtdModulos / 24)), flange: (1 - (qtdModulos / 22))}, 4: {module: (1 - (qtdModulos / 17)), flange: (1 - (qtdModulos / 12))}, 5: {module: (1 - (qtdModulos / 15)), flange: (1 - (qtdModulos / 10.8))}, 6: {module: (1 - (qtdModulos / 14.3)), flange: (1 - (qtdModulos / 10.8))}, 7: {module: (1 - (qtdModulos / 14.3)), flange: (1 - (qtdModulos / 11.6))}, 8: {module: (1 - (qtdModulos / 15)), flange: (1 - (qtdModulos / 12.2))}, 9: {module: (1 - (qtdModulos / 15.3)), flange: (1 - (qtdModulos / 13.1))}, 10: {module: (1 - (qtdModulos / 16)), flange: (1 - (qtdModulos / 13.88))}}, 
    // eslint-disable-next-line object-curly-spacing 
    MB: {flangeAux: 3.5, 1: {module: 1.5, flange: 1}, 2: {module: 1.2, flange: 1.1}, 3: {module: (1 - (qtdModulos / 17.5)), flange: 0.79}, 4: {module: (1 - (qtdModulos / 15)), flange: (1 - (qtdModulos / 10.8))}, 5: {module: (1 - (qtdModulos / 14)), flange: (1 - (qtdModulos / 10))}, 6: {module: (1 - (qtdModulos / 13.5)), flange: (1 - (qtdModulos / 10.6))}, 7: {module: (1 - (qtdModulos / 13.9)), flange: (1 - (qtdModulos / 11.3))}, 8: {module: (1 - (qtdModulos / 14)), flange: (1 - (qtdModulos / 11.9))}, 9: {module: (1 - (qtdModulos / 15.1)), flange: (1 - (qtdModulos / 12.9))}, 10: {module: (1 - (qtdModulos / 15.7)), flange: (1 - (qtdModulos / 13.65))}},   
    // eslint-disable-next-line object-curly-spacing
    MC: {flangeAux: 0, 1: {module: 1, flange: 1}, 2: {module: 1, flange: 1}, 3: {module: (1 - (qtdModulos / 17)), flange: (1 - (qtdModulos / 14.5))}, 4: {module: (1 - (qtdModulos / 13)), flange: (1 - (qtdModulos / 10.5))}, 5: {module: (1 - (qtdModulos / 12.5)), flange: (1 - (qtdModulos / 10.5))}, 6: {module: (1 - (qtdModulos / 12.2)), flange: (1 - (qtdModulos / 10.6))}, 7: {module: (1 - (qtdModulos / 13)), flange: (1 - (qtdModulos / 11.4))}, 8: {module: (1 - (qtdModulos / 14)), flange: (1 - (qtdModulos / 12.4))}, 9: {module: (1 - (qtdModulos / 14.8)), flange: (1 - (qtdModulos / 13))}, 10: {module: (1 - (qtdModulos / 15.2)), flange: (1 - (qtdModulos / 13.95))}},   
    // eslint-disable-next-line object-curly-spacing
    MD: {flangeAux: 0, 1: {module: 1.5, flange: 1}, 2: {module: 1.2, flange: 1.2}, 3: {module: (1 - (qtdModulos / 19)), flange: 0.86}, 4: {module: (1 - (qtdModulos / 15)), flange: (1 - (qtdModulos / 12))}, 5: {module: (1 - (qtdModulos / 14)), flange: (1 - (qtdModulos / 11))}, 6: {module: (1 - (qtdModulos / 13.5)), flange: (1 - (qtdModulos / 11.25))}, 7: {module: (1 - (qtdModulos / 13.5)), flange: (1 - (qtdModulos / 11.6))}, 8: {module: (1 - (qtdModulos / 14)), flange: (1 - (qtdModulos / 12.45))}, 9: {module: (1 - (qtdModulos / 14.8)), flange: (1 - (qtdModulos / 13.25))}, 10: {module: (1 - (qtdModulos / 15.7)), flange: (1 - (qtdModulos / 14.05))}},     
    // eslint-disable-next-line object-curly-spacing
    ME: {flangeAux: 3.5, 1: {module: 1.5, flange: 1}, 2: {module: 1.2, flange: 1.1}, 3: {module: (1 - (qtdModulos / 17.5)), flange: 0.79}, 4: {module: (1 - (qtdModulos / 15)), flange: (1 - (qtdModulos / 10.2))}, 5: {module: (1 - (qtdModulos / 14)), flange: (1 - (qtdModulos / 9.8))}, 6: {module: (1 - (qtdModulos / 13.2)), flange: (1 - (qtdModulos / 10.6))}, 7: {module: (1 - (qtdModulos / 13.9)), flange: (1 - (qtdModulos / 11))}, 8: {module: (1 - (qtdModulos / 13.3)), flange: (1 - (qtdModulos / 11.35))}, 9: {module: (1 - (qtdModulos / 14)), flange: (1 - (qtdModulos / 12.2))}, 10: {module: (1 - (qtdModulos / 14.8)), flange: (1 - (qtdModulos / 13.2))}},     
    // eslint-disable-next-line object-curly-spacing
    MF: {flangeAux: 3.5, 1: {module: 1.5, flange: 1}, 2: {module: 1.2, flange: 1.1}, 3: {module: (1 - (qtdModulos / 17.5)), flange: 0.79}, 4: {module: (1 - (qtdModulos / 15)), flange: (1 - (qtdModulos / 10.8))}, 5: {module: (1 - (qtdModulos / 14)), flange: (1 - (qtdModulos / 10))}, 6: {module: (1 - (qtdModulos / 13.5)), flange: (1 - (qtdModulos / 10.6))}, 7: {module: (1 - (qtdModulos / 13.9)), flange: (1 - (qtdModulos / 11.3))}, 8: {module: (1 - (qtdModulos / 14)), flange: (1 - (qtdModulos / 11.9))}, 9: {module: (1 - (qtdModulos / 15.1)), flange: (1 - (qtdModulos / 12.9))}, 10: {module: (1 - (qtdModulos / 15.7)), flange: (1 - (qtdModulos / 13.65))}},   
    // eslint-disable-next-line object-curly-spacing
    MG: {flangeAux: 3.5, 1: {module: 1.5, flange: 1}, 2: {module: 1.2, flange: 1.1}, 3: {module: (1 - (qtdModulos / 17.5)), flange: 0.79}, 4: {module: (1 - (qtdModulos / 15)), flange: (1 - (qtdModulos / 10.8))}, 5: {module: (1 - (qtdModulos / 14)), flange: (1 - (qtdModulos / 10))}, 6: {module: (1 - (qtdModulos / 13.5)), flange: (1 - (qtdModulos / 10.6))}, 7: {module: (1 - (qtdModulos / 13.9)), flange: (1 - (qtdModulos / 11.3))}, 8: {module: (1 - (qtdModulos / 14)), flange: (1 - (qtdModulos / 11.9))}, 9: {module: (1 - (qtdModulos / 15.1)), flange: (1 - (qtdModulos / 12.9))}, 10: {module: (1 - (qtdModulos / 15.7)), flange: (1 - (qtdModulos / 13.65))}},   
    // eslint-disable-next-line object-curly-spacing
    MH: {flangeAux: 2, 1: {module: 1.5, flange: (1 - (qtdModulos / 17))}, 2: {module: 1.25, flange: 1.13}, 3: {module: 0.95, flange: (1 - (qtdModulos / 16))}, 4: {module: (1 - (qtdModulos / 17)), flange: (1 - (qtdModulos / 11))}, 5: {module: (1 - (qtdModulos / 14)), flange: (1 - (qtdModulos / 10.5))}, 6: {module: (1 - (qtdModulos / 14)), flange: (1 - (qtdModulos / 10.8))}, 7: {module: (1 - (qtdModulos / 13.8)), flange: (1 - (qtdModulos / 11.3))}, 8: {module: (1 - (qtdModulos / 14.5)), flange: (1 - (qtdModulos / 12.1))}, 9: {module: (1 - (qtdModulos / 14.8)), flange: (1 - (qtdModulos / 12.9))}, 10: {module: (1 - (qtdModulos / 15.7)), flange: (1 - (qtdModulos / 13.85))}},   
    // eslint-disable-next-line object-curly-spacing
    MI: {flangeAux: 0, 1: {module: 2.3, flange: 1}, 2: {module: 1.4, flange: 1.45}, 3: {module: 1.05, flange: 0.99}, 4: {module: 0.84, flange: (1 - (qtdModulos / 15.8))}, 5: {module: (1 - (qtdModulos / 17)), flange: (1 - (qtdModulos / 12.5))}}};   

  useEffect(() => {
    const type = mastProps.tipoMastro;
    const modulesNum = Number(mastProps.qtdModulos);
    function defineMastImgId() {
      if (type === "MA") {
        setMastImgId(`${type}_${modulesNum}`);
      }
      else if (type === "MC") {
        if (modulesNum == 1) return setMastImgId(`${type}_1`);
        const positionEstai1 = (mastProps.estai1ModuloAcoplado && mastProps.estai1ModuloAcoplado <= modulesNum ) ? mastProps.estai1ModuloAcoplado : 1;
        const positionEstai3 = (mastProps.estai3ModuloAcoplado && mastProps.estai3ModuloAcoplado <= modulesNum ) ? mastProps.estai3ModuloAcoplado : 1;
        if (positionEstai1 > modulesNum || positionEstai3 > modulesNum || positionEstai3 > positionEstai1) {
          if (!mastImgId) setMastImgId(`${type}_1`);
          return;
        } 
        const imageId = `${type}_${modulesNum}_${positionEstai1}_${positionEstai3}` !== `${type}_1_1_1` ? `${type}_${modulesNum}_${positionEstai1}_${positionEstai3}` : `${type}_1`;
        setMastImgId(imageId);
      }
      else if (type === "MI") {
        if (modulesNum == 1) return setMastImgId(`${type}_1_1`);
        const positionEstai1 = (mastProps.estai1ModuloAcoplado && mastProps.estai1ModuloAcoplado <= modulesNum ) ? mastProps.estai1ModuloAcoplado : 1;
        const positionEstai3 = (mastProps.estai3ModuloAcoplado && mastProps.estai3ModuloAcoplado <= modulesNum ) ? mastProps.estai3ModuloAcoplado : 1;
        if (positionEstai1 > modulesNum || positionEstai3 > modulesNum || positionEstai3 > positionEstai1) {
          if (!mastImgId) setMastImgId(`${type}_1_1`);
          return;
        } 
        const imageId = `${type}_${modulesNum}_${positionEstai1}_${positionEstai3}` !== `${type}_1_1_1` ? `${type}_${modulesNum}_${positionEstai1}_${positionEstai3}` : `${type}_1_1`;
        setMastImgId(imageId);
      }
      else {
        const positionEstai1 = (mastProps.estai1ModuloAcoplado && mastProps.estai1ModuloAcoplado <= modulesNum ) ? mastProps.estai1ModuloAcoplado : 1;
        if (positionEstai1 > modulesNum) return;
        const imageId = `${type}_${positionEstai1}_${modulesNum}`;
        setMastImgId(imageId);
      }
    }
    function defineMastEstaiPositionImgId() {
      const previousMastImgProps = { ...mastImgProps };
      if (Number(mastProps.qtdModulos) === 1) {
        previousMastImgProps.paddingTop = "100px";
        previousMastImgProps.imageSize = "50vh";
        setMastImgProps(previousMastImgProps);
      }
      else if (Number(mastProps.qtdModulos) === 2) {
        previousMastImgProps.paddingTop = "40px";
        previousMastImgProps.imageSize = "55vh";
        setMastImgProps(previousMastImgProps);
      }
      else if (Number(mastProps.qtdModulos) < 5) {
        previousMastImgProps.paddingTop = "0px";
        previousMastImgProps.imageSize = "60vh";
        setMastImgProps(previousMastImgProps);
      }
      else if (Number(mastProps.qtdModulos) < 8) {
        previousMastImgProps.paddingTop = "0px";
        previousMastImgProps.imageSize = "65vh";
        setMastImgProps(previousMastImgProps);
      }
      else {
        previousMastImgProps.paddingTop = "0px";
        previousMastImgProps.imageSize = "70vh";
        setMastImgProps(previousMastImgProps);
      }
    }
    defineMastImgId();
    defineMastEstaiPositionImgId();
  }, []);

  return (
    <MastGeometryImgContainer imageObjectFit="cover" paddingTop={mastImgProps.paddingTop} imageSize={mastImgProps.imageSize}>
      <div>
        <DescriptionContainer>Definição dos Módulos</DescriptionContainer>
        <MastGeometryImgPositionalContainer>
          <MastGeometryImage>
            <MastGeometryImg type={mastProps.tipoMastro} imgId={mastImgId}/>
          </MastGeometryImage>
          {(mastProps.tipoMastro !== "MH" && mastProps.tipoMastro !== "MI") && flangesArray.map((_, index) => (
            <ScrewDescriptionContainer top={`${( 31 * (index + 1) * positionCorrections[mastProps.tipoMastro][qtdModulos].flange) + positionCorrections[mastProps.tipoMastro].flangeAux}%`} left={qtdModulos > 2 ? "15%" : "17%"} key={index} color={Number(inputValues.moduleSelected) === (flangesArray.length - index) ? "#F16E00" : "#D0CECE"}>
              <p>{`F${flangesArray.length - index}`} - Ø{inputValues[`modulo${flangesArray.length - index}FlangeDiametro`] || mastProps[`modulo${flangesArray.length - index}FlangeDiametro`]}x{inputValues[`modulo${flangesArray.length - index}FlangeEspessura`] || mastProps[`modulo${flangesArray.length - index}FlangeEspessura`]}mm </p>
              <p>{inputValues[`modulo${flangesArray.length - index}ParafusosFlangeNumero`] || mastProps[`modulo${flangesArray.length - index}ParafusosFlangeNumero`]} x {inputValues[`modulo${flangesArray.length - index}ParafusosFlangeDiametro`] || mastProps[`modulo${flangesArray.length - index}ParafusosFlangeDiametro`]}</p>
              <div></div>
            </ScrewDescriptionContainer>
          ))}
          {(mastProps.tipoMastro !== "MH" && mastProps.tipoMastro !== "MI") && modulesArray.map((_, index) => (
            <ModuleDescriptionContainer top={`${(23 * (index + 1) * positionCorrections[mastProps.tipoMastro][qtdModulos].module)}%`} right={qtdModulos > 2 ? "15%" : "16%"} key={index} color={Number(inputValues.moduleSelected) === (modulesArray.length - index) ? "#F16E00" : "#D0CECE"}>
              <p>{`M${modulesArray.length - index}`} - H = {inputValues[`modulo${modulesArray.length - index}Altura`] || mastProps[`modulo${modulesArray.length - index}Altura`]}m</p>
              <p>Ø {inputValues[`modulo${modulesArray.length - index}Diametro`] || mastProps[`modulo${modulesArray.length - index}Diametro`]}x{inputValues[`modulo${modulesArray.length - index}Espessura`] || mastProps[`modulo${modulesArray.length - index}Espessura`]}mm</p>
              <div></div>
            </ModuleDescriptionContainer>
          ))}
          {(mastProps.tipoMastro === "MH" || mastProps.tipoMastro === "MI") && flangesArray.map((_, index) => (
            <ScrewDescriptionContainer top={`${( 31 * (index + 1) * positionCorrections[mastProps.tipoMastro][qtdModulos].flange) + positionCorrections[mastProps.tipoMastro].flangeAux}%`} left={qtdModulos > 2 ? "10%" : "11%"} key={index} color={Number(inputValues.moduleSelected) === (flangesArray.length - index) ? "#F16E00" : "#D0CECE"}>
              <p>{`F${flangesArray.length - index}`} - Ø{inputValues[`modulo${flangesArray.length - index}FlangeDiametro`] || mastProps[`modulo${flangesArray.length - index}FlangeDiametro`]}x{inputValues[`modulo${flangesArray.length - index}FlangeEspessura`] || mastProps[`modulo${flangesArray.length - index}FlangeEspessura`]}mm </p>
              <p>{inputValues[`modulo${flangesArray.length - index}ParafusosFlangeNumero`] || mastProps[`modulo${flangesArray.length - index}ParafusosFlangeNumero`]} x {inputValues[`modulo${flangesArray.length - index}ParafusosFlangeDiametro`] || mastProps[`modulo${flangesArray.length - index}ParafusosFlangeDiametro`]}</p>
              <div></div>
            </ScrewDescriptionContainer>
          ))}
          {(mastProps.tipoMastro === "MH" || mastProps.tipoMastro === "MI") && modulesArray.map((_, index) => (
            <ModuleDescriptionContainer top={`${(23 * (index + 1) * positionCorrections[mastProps.tipoMastro][qtdModulos].module)}%`} right={qtdModulos > 2 ? "18%" : "20%"} key={index} color={Number(inputValues.moduleSelected) === (modulesArray.length - index) ? "#F16E00" : "#D0CECE"}>
              <p>{`M${modulesArray.length - index}`} - H = {inputValues[`modulo${modulesArray.length - index}Altura`] || mastProps[`modulo${modulesArray.length - index}Altura`]}m</p>
              <p>Ø {inputValues[`modulo${modulesArray.length - index}Diametro`] || mastProps[`modulo${modulesArray.length - index}Diametro`]}x{inputValues[`modulo${modulesArray.length - index}Espessura`] || mastProps[`modulo${modulesArray.length - index}Espessura`]}mm</p>
              <div></div>
            </ModuleDescriptionContainer>
          ))}
        </MastGeometryImgPositionalContainer>
      </div>
    </MastGeometryImgContainer>
  );
};

export const MastGeometryImgContainer = styled.div`
  display: flex;
  height: calc(100vh - 160px);
  width: calc(100vw - 25%);
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 120px;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

export const MastGeometryImage = styled.div`
  img {
    width: 40vw;
    border-radius: 16px;
    height: ${(props) => props.imageSize};
    margin-top: 20px;
    padding-top: ${(props) => (props.paddingTop)};
    object-fit: ${(props) => props.imageObjectFit};
    object-fit: contain;
    background-color: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
  }
`;

export const DescriptionContainer = styled.div`
    width: 40vw;
    height: 50px;
    background-color: #ffffff;
    color: #000000;
    font-size: 21px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
`;

const MastGeometryImgPositionalContainer = styled.div`
  position: relative;
`;

const ModuleDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 190px;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid;
  border-color: ${(props) => props.color};
  border-radius: 10px;
  right: ${(props) => props.right};
  top: ${(props) => props.top};
  div {
    position: absolute;
    top: 50%;
    left: -50px;
    width: 50px;
    height: 2px;
    background-color: ${(props) => props.color};
  }
  p {
    padding: 3px 0;
    max-width: 100%;
    color: #000000;
  }
`;

const ScrewDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 190px;
  height: 50px;
  background-color: #ffffff;
  border: 1px solid;
  border-color: ${(props) => props.color};
  border-radius: 10px;
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  div {
    position: absolute;
    top: 50%;
    right: -50px;
    width: 50px;
    height: 2px;
    background-color: ${(props) => props.color};
  }
  p {
    padding: 3px 0;
  }
`;
