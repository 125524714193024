import { createContext, useState } from "react";

const MenuContext = createContext();

const MenuStorage = ({ children }) => {
  const [leftMenuWidth, setLeftMenuWidth] = useState("25%");
  return (
    <MenuContext.Provider
      value={{ leftMenuWidth, setLeftMenuWidth
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

export { MenuContext, MenuStorage };
