import useAsync from "../../useAsync";
import { deleteTowerProject, editTowerProjectName } from "../../../services/tower-services/towerProjectApi";

export const useTowerProject = (action) => {
  const execute = {
    put: editTowerProjectName,
    delete: deleteTowerProject,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
