import styled from "styled-components";
import { LeftTitle, ButtonPositioner, ReturnButton } from "../../project-components/wind-components/project-windData";
import SavingButton from "../../common/saving-button";
import LoadingButton from "../../common/loading-button";
import CreatableSelect from "react-select/creatable";
import { useContext, useState, useEffect } from "react";
import { ProjectContext } from "../../../contexts/ProjectContext";
import UserContext from "../../../contexts/UserContext";
import AntennaTable from "./mast-antennaTable";
import AEVTable from "./mast-AEVTable";
import useGetAllAntennas from "../../../hooks/api/useGetAllAntennas";
import { TelecomOperatorSelect } from "../geometry-components/mast-geometryData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useUpdateMast from "../../../hooks/api/useUpdateMast";
import { calculateAntennaValues } from "./mast-antennaCalculation";
import ResizableLeftContainer from "../../common/resizableLeftContainer";
import ResizableRightContainer from "../../common/resizableRightContainer";
import { ReturnIcon, AdvanceIcon } from "../../project-components/wind-components/project-windData";
import antennas_images from "../../../assets/images/antennasImages/index";

export default function MastAntennaPage() {
  const { setLeftMenuOptionSelected, projectSelected, mastSelected, reloadProject, setReloadProject, setMastSelected, setIsUpdatedWithoutCalculation, setProjectNameSelected, setProjectSelected } = useContext(ProjectContext);
  const { userData } = useContext(UserContext);
  const projectProps = JSON.parse(projectSelected.props);
  const mastProps = JSON.parse(mastSelected.props);
  const [atualizedMastProps, setAtualizedMastProps] = useState({ ...mastProps });
  const { getAllAntennas } = useGetAllAntennas();
  const { updateMast } = useUpdateMast();
  const [antennasList, setAntennasList] = useState([]);
  const [ isModeloVisible, setIsModeloVisible ] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [resetInputValues, setResetInputValues] = useState(0);
  const [antennasDimensionsEditable, setAntennasDimensionsEditable] = useState(false);
  const [CAPlaceholder, setCAPlaceholder] = useState("Coef. de Arrasto");
  const [antennaGroupSelected, setAntennaGroupSelected] = useState("");
  const [antennaMenuAction, setAntennaMenuAction] = useState({ action: false, text: "Adicionar/Editar/Deletar", color: "#000", backgroundColor: "yellow" });
  const condicaoAntennas = ["Existente", "Retirar", "Instalar", "Reserva"];
  const [inputValues, setInputValues] = useState({ 
    qtdAntenas: 0,
    alturaMastro: antennaGroupSelected !== "" ? mastProps[`antena${antennaGroupSelected[0]}alturaMastro`] : "",
    operadora: "",
    condicao: "",
    modelo: "",
    tipo: "",
    CA: "",
    diametro: "0",
    altura: "0",
    largura: "0",
    esteira: mastProps.esteira || "", 
    groupSelected: "",
    antena: {
      altura: "",
      diametro: "",
      espessura: "",
      fabricante: "",
      largura: "",
      modelo: "",
      tipo: ""
    }
  });
  const antennasTipos = ["RF", "RRU", "TMA", "DIPLEXER", "TRIPLEXER", "QUADRIPLEXER", "MW CHEIA", "MW VAZADA", "PAINEL VAZADO", "ODU", "OMNI", "HELICOIDAL 1", "HELICOIDAL 2",
    "HELICOIDAL 4", "HELICOIDAL 7", "YAGI 1", "YAGI 2", "YAGI 4", "YAGI 7", "YAGI 8", "GPS", "ANTENAS", "RESERVA"].sort();

  let existingAntennaGroups = [];
  for (const key in mastProps) {
    if (key.startsWith("antena") && key.endsWith("Grupo")) {
      const group = mastProps[key];
      if (!existingAntennaGroups.includes(group)) {
        existingAntennaGroups.push(group);
      }
    }
  };

  useEffect(() => {
    async function fetchAntennasData() {
      const antennas = await getAllAntennas(userData.token);
      setAntennasList(antennas);
    }
    fetchAntennasData();
  }, []);
  useEffect(() => {
  }, [mastProps]);

  function organizeAntennas() {
    let qtdTotalAntenas = Number(mastProps.qtdTotalAntenas) || Number(0);
    let i = inputValues.qtdAntenas;
    const newAntennaValues = {};
    let antenasGrupo = "";
    if (Number(inputValues.qtdAntenas) === 1) antenasGrupo = `${qtdTotalAntenas + 1}`;
    if (Number(inputValues.qtdAntenas) === 2) antenasGrupo = `${qtdTotalAntenas + 1} e ${qtdTotalAntenas + 2}`;
    if (Number(inputValues.qtdAntenas) > 2) antenasGrupo = `${qtdTotalAntenas + 1} a ${qtdTotalAntenas + Number(inputValues.qtdAntenas)}`;
    while (i !== 0) {
      newAntennaValues[`antena${qtdTotalAntenas+1}AlturaMastro`] = inputValues.alturaMastro?.replace(",", "."); 
      newAntennaValues[`antena${qtdTotalAntenas+1}Operadora`] = inputValues.operadora; 
      newAntennaValues[`antena${qtdTotalAntenas+1}Condicao`] = inputValues.condicao; 
      newAntennaValues[`antena${qtdTotalAntenas+1}Modelo`] = inputValues.modelo; 
      newAntennaValues[`antena${qtdTotalAntenas+1}Tipo`] = inputValues.tipo; 
      newAntennaValues[`antena${qtdTotalAntenas+1}CA`] = inputValues.CA?.replace(",", "."); 
      newAntennaValues[`antena${qtdTotalAntenas+1}Grupo`] = antenasGrupo; 
      if (antennasDimensionsEditable) {
        newAntennaValues[`antena${qtdTotalAntenas+1}Dimensoes`] = `${inputValues.diametro} x ${inputValues.altura} x ${inputValues.largura}`;
      }
      else {
        newAntennaValues[`antena${qtdTotalAntenas+1}Dimensoes`] = Number(inputValues.diametro) !== 0 ? `ø ${inputValues.diametro}` : `${inputValues.altura} x ${inputValues.largura}`; 
      }
      newAntennaValues[`antena${qtdTotalAntenas+1}Diametro`] = inputValues.diametro;
      newAntennaValues[`antena${qtdTotalAntenas+1}Altura`] = inputValues.altura;
      newAntennaValues[`antena${qtdTotalAntenas+1}Largura`] = inputValues.largura;
      i--;
      qtdTotalAntenas++;
    }
    newAntennaValues.qtdTotalAntenas = qtdTotalAntenas;

    return newAntennaValues;
  }

  async function addAntenna() {
    if (inputValues.qtdAntenas < 0 || inputValues.qtdAntenas > 100 || !Number.isInteger(Number(inputValues.qtdAntenas))) {
      return toast.error("A quantidade de antenas digitada é inválida");
    }
    if (!inputValues.modelo) inputValues.modelo = "NI";
    if (!inputValues.CA) inputValues.CA = "1.2";
    if (!inputValues.diametro) inputValues.diametro = "0";
    if (!inputValues.altura) inputValues.altura = "0";
    if (!inputValues.largura) inputValues.largura = "0";
    if(inputValues.diametro === "0" && (inputValues.altura === "0" || inputValues.largura === "0")) toast.error("As dimensões das antenas não podem ser 0");
    let whatIsMissingMessage = "";
    if (!inputValues.qtdAntenas || !inputValues.alturaMastro || !inputValues.operadora || !inputValues.condicao || !inputValues.tipo || !inputValues.CA || (!inputValues.diametro && Number(inputValues.diametro !== 0)) || (!inputValues.altura && Number(inputValues.altura !== 0)) || (!inputValues.largura && Number(inputValues.largura !== 0))) {
      if (!inputValues.qtdAntenas) whatIsMissingMessage += "Quantidade de antenas; ";
      if (!inputValues.alturaMastro) whatIsMissingMessage += "Altura de fixação; ";
      if (!inputValues.operadora) whatIsMissingMessage += "Operadora; ";
      if (!inputValues.condicao) whatIsMissingMessage += "Condicao; ";
      if (!inputValues.tipo) whatIsMissingMessage += "Tipo; ";
      return toast.error(`Preencha todos os valores para adicionar a antena. Falta(m) ${whatIsMissingMessage}`);
    }
    if (Number(inputValues.alturaMastro) < 0 || Number(inputValues.alturaMastro) > Number(mastProps.alturaMastro)) {
      return toast.error("O valor da altura de fixação é inválido");
    }
    try {
      const organizedAntennas = organizeAntennas();
      const newMastProps = { ...mastProps, ...organizedAntennas };
      newMastProps.esteira = inputValues.esteira || "0";
      for (let i = 0; i < Number(newMastProps.qtdTotalAntenas); i ++) {
        calculateAntennaValues(projectProps, newMastProps, i);
      }
      const toBeUpdatedMast = { ...mastSelected, updatedAt: new Date(), props: JSON.stringify(newMastProps) };
      const updatedMast = await updateMast(toBeUpdatedMast, userData.token);
      setMastSelected(updatedMast);
      setAtualizedMastProps(updatedMast.props);
      setReloadProject(!reloadProject);
      setIsUpdated(true);
      setIsUpdatedWithoutCalculation(true);
      if (antennaMenuAction.action !== "edit") toast.success("Antena(s) adicionada(s) com sucesso");
      setResetInputValues(resetInputValues + 1);
      return updatedMast;
    } catch (error) {
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
      if (error?.message) return toast.error(error.message);
    }
  }
  async function deleteAntennas(groupToDelete, deleteAfterEditingOptionProps) {
    let numAntennasDeleted = 0;
    let lastAntennaDeleted = 0;
    if (groupToDelete.includes(" e ")) {
      // Formato: "número1 e número2"
      const [start, end] = groupToDelete.split(" e ");
      numAntennasDeleted = parseInt(end) - parseInt(start) + 1;
      lastAntennaDeleted = parseInt(end);
    } else if (groupToDelete.includes(" a ")) {
      // Formato: "número1 a número2"
      const [start, end] = groupToDelete.split(" a ");
      numAntennasDeleted = parseInt(end) - parseInt(start) + 1;
      lastAntennaDeleted = parseInt(end);
    } else {
      // Formato: número único
      numAntennasDeleted = 1;
      lastAntennaDeleted = parseInt(groupToDelete);
    }
    try {
      const updatedMastProps = deleteAfterEditingOptionProps !== undefined ? JSON.parse(deleteAfterEditingOptionProps) : JSON.parse(mastSelected.props);
      for (const key in updatedMastProps) {
        if (key.includes("antena")) {
          const antennaNumber = key.match(/\d+/)[0];
          const currentGroup = updatedMastProps[`antena${antennaNumber}Grupo`];
          if (currentGroup === groupToDelete) {
            delete updatedMastProps[key];
            delete updatedMastProps[`antena${antennaNumber}AlturaMastro`];
            delete updatedMastProps[`antena${antennaNumber}CA`];
            delete updatedMastProps[`antena${antennaNumber}Modelo`];
            delete updatedMastProps[`antena${antennaNumber}Operadora`];
            delete updatedMastProps[`antena${antennaNumber}Condicao`];
            delete updatedMastProps[`antena${antennaNumber}Tipo`];
            delete updatedMastProps[`antena${antennaNumber}Dimensoes`];
            delete updatedMastProps[`antena${antennaNumber}Area`];
            delete updatedMastProps[`antena${antennaNumber}Diametro`];
            delete updatedMastProps[`antena${antennaNumber}Altura`];
            delete updatedMastProps[`antena${antennaNumber}Largura`];
            delete updatedMastProps[`antena${antennaNumber}AEV`];
            delete updatedMastProps[`antena${antennaNumber}ArrastoArea`];
            delete updatedMastProps[`antena${antennaNumber}CarregFrontal`];
            delete updatedMastProps[`antena${antennaNumber}CarregDiagonal`];
          }
        }
      }
      // Atualizar nomes das antenas
      const antennaKeysToUpdate = Object.keys(updatedMastProps).filter(key => key.startsWith("antena"));
      if (lastAntennaDeleted !== updatedMastProps.qtdTotalAntenas) {
        const updatedGroupNumbers = {};
      
        for (const key of antennaKeysToUpdate) {
          const matches = key.match(/antena(\d+)(.*)/);
          const antennaNumber = matches[1];
          if (parseInt(antennaNumber) > lastAntennaDeleted) {
            const updatedAntennaNumber = parseInt(antennaNumber) - numAntennasDeleted;
            const updatedKey = key.replace(antennaNumber, updatedAntennaNumber.toString());
            const antennaProps = updatedMastProps[key];
            delete updatedMastProps[key];
            updatedMastProps[updatedKey] = antennaProps;
            if (updatedMastProps[`antena${antennaNumber}Grupo`] && !updatedGroupNumbers[antennaNumber]) {
              const oldGroups = updatedMastProps[`antena${antennaNumber}Grupo`];
              const newGroups = oldGroups?.split(" ")?.map((group) => {
                const groupNumber = parseInt(group);
                if (groupNumber > lastAntennaDeleted) {
                  return (groupNumber - numAntennasDeleted).toString();
                }
                return group;
              })?.join(" ");
              updatedMastProps[`antena${antennaNumber}Grupo`] = newGroups;
              updatedGroupNumbers[antennaNumber] = true; 
            }
          }
        }
      }
      if (antennaMenuAction.action === "edit" || window.confirm(`Tem certeza de que deseja deletar a(s) antena(s) ${groupToDelete} ?`)) {
        updatedMastProps.qtdTotalAntenas = Number(updatedMastProps.qtdTotalAntenas) - numAntennasDeleted;
        const newMastProps = { ...updatedMastProps };
        const toBeUpdatedMast = { ...mastSelected, updatedAt: new Date(), props: JSON.stringify(newMastProps) };
        const updatedMast = await updateMast(toBeUpdatedMast, userData.token);
        setMastSelected(updatedMast);
        setAtualizedMastProps(updatedMast.props);
        setReloadProject(!reloadProject);
        setIsUpdated(true);
        setResetInputValues(resetInputValues + 1);
        if (antennaMenuAction.action === "edit") return updatedMast;
        setIsUpdatedWithoutCalculation(true);
        toast.success(`Antena(s) ${groupToDelete} deletada(s) com sucesso`);
        setAntennaMenuAction({ action: false, text: "Adicionar/Editar/Deletar", color: "#000", backgroundColor: "#FE8F03" });
      }
    } catch (error) {
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
      if (error?.message) return toast.error(error.message);
    }
  }

  async function updateAntennas(groupToUpdate) {
    let numAntennasUpdated = 0;
    let lastAntennaUpdated = 0;

    if (groupToUpdate.includes(" e ")) {
      // Formato: "número1 e número2"
      const [start, end] = groupToUpdate.split(" e ");
      numAntennasUpdated = parseInt(end) - parseInt(start) + 1;
      lastAntennaUpdated = parseInt(end);
    } else if (groupToUpdate.includes(" a ")) {
      // Formato: "número1 a número2"
      const [start, end] = groupToUpdate.split(" a ");
      numAntennasUpdated = parseInt(end) - parseInt(start) + 1;
      lastAntennaUpdated = parseInt(end);
    } else {
      // Formato: número único
      numAntennasUpdated = 1;
      lastAntennaUpdated = parseInt(groupToUpdate);
    }

    try {
      const updatedMastProps = { ...mastProps };
      inputValues.alturaMastro = inputValues.alturaMastro || mastProps[`antena${lastAntennaUpdated}AlturaMastro`];
      inputValues.operadora = inputValues.operadora || mastProps[`antena${lastAntennaUpdated}Operadora`];
      inputValues.condicao = inputValues.condicao || mastProps[`antena${lastAntennaUpdated}Condicao`];
      inputValues.modelo = inputValues.modelo || mastProps[`antena${lastAntennaUpdated}Modelo`];
      inputValues.tipo = inputValues.tipo || mastProps[`antena${lastAntennaUpdated}Tipo`];
      inputValues.CA = inputValues.CA || mastProps[`antena${lastAntennaUpdated}CA`];
      const antenaDimensoes = antennasList.find((antenna) => antenna.modelo === inputValues.modelo);
      if(!antenaDimensoes && (!inputValues.diametro && Number(inputValues.diametro !== 0)) || (!inputValues.altura && Number(inputValues.altura !== 0)) || (!inputValues.largura && Number(inputValues.largura !== 0))) {
        return toast.error("Preencha os valores de dimensão da antena porque o modelo digitado é novo");
      };

      if (inputValues.qtdAntenas && Number(inputValues.qtdAntenas) !== numAntennasUpdated && window.confirm(`Tem certeza de que deseja editar a(s) antena(s) ${groupToUpdate} ?`)) {
        const atualizedMast = await addAntenna();
        for (let i = 0; i < 5; i ++) {
        };
        await deleteAntennas(groupToUpdate, atualizedMast.props);
        setReloadProject(!reloadProject);
        setIsUpdated(true);
        setResetInputValues(resetInputValues + 1);
        setIsUpdatedWithoutCalculation(true);
        return toast.success(`Antena(s) ${groupToUpdate} editada(s) com sucesso`);
      }

      for (const key in updatedMastProps) {
        if (key.includes("antena")) {
          const antennaNumber = key.match(/\d+/)[0];
          const currentGroup = updatedMastProps[`antena${antennaNumber}Grupo`];
  
          if (currentGroup === groupToUpdate) {
            updatedMastProps[`antena${antennaNumber}AlturaMastro`] = inputValues.alturaMastro;
            updatedMastProps[`antena${antennaNumber}Operadora`] = inputValues.operadora;
            updatedMastProps[`antena${antennaNumber}Condicao`] = inputValues.condicao;
            updatedMastProps[`antena${antennaNumber}Modelo`] = inputValues.modelo;
            updatedMastProps[`antena${antennaNumber}Tipo`] = inputValues.tipo;
            updatedMastProps[`antena${antennaNumber}CA`] = inputValues.CA;
            if (antenaDimensoes) {
              updatedMastProps[`antena${antennaNumber}Dimensoes`] = antenaDimensoes.diametro !== 0 ? `ø ${antenaDimensoes.diametro}` : `${antenaDimensoes.altura} x ${antenaDimensoes.largura}`;
            }
            else {
              updatedMastProps[`antena${antennaNumber}Dimensoes`] = `${inputValues.diametro} x ${inputValues.altura} x ${inputValues.largura}`;
            }
            updatedMastProps[`antena${antennaNumber}Diametro`] = inputValues.diametro;
            updatedMastProps[`antena${antennaNumber}Altura`] = inputValues.altura;
            updatedMastProps[`antena${antennaNumber}Largura`] = inputValues.largura;
          }
        }
      }
      if (window.confirm(`Tem certeza de que deseja editar a(s) antena(s) ${groupToUpdate} ?`)) {
        const newMastProps = { ...updatedMastProps };
        newMastProps.esteira = inputValues.esteira;
        for (let i = 0; i <= Number(newMastProps.qtdTotalAntenas); i ++) {
          calculateAntennaValues(projectProps, newMastProps, i);
        }
        const toBeUpdatedMast = { ...mastSelected, updatedAt: new Date(), props: JSON.stringify(newMastProps) };
        const updatedMast = await updateMast(toBeUpdatedMast, userData.token);
        setMastSelected(updatedMast);
        setAtualizedMastProps(updatedMast.props);
        setReloadProject(!reloadProject);
        setIsUpdated(true);
        toast.success(`Antena(s) ${groupToUpdate} editada(s) com sucesso`);
        setIsUpdatedWithoutCalculation(true);
        setResetInputValues(resetInputValues + 1);
        setAntennaMenuAction({ action: false, text: "Adicionar/Editar/Deletar", color: "#000", backgroundColor: "#FE8F03" });
      }
    } catch (error) {
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
      if (error?.message) return toast.error(error.message);
    }
  }

  useEffect(() => {
    for (let i = 0; i < Number(mastProps.qtdTotalAntenas); i ++) {
      calculateAntennaValues(projectProps, mastProps, i);
    }
  }, [mastSelected]);

  async function saveChanges() {
    let wasAlerted = false;
    if (isUpdated) {
      toast.success("Informações salvas com sucesso");
      wasAlerted = true;
      setIsUpdated(false);
      setResetInputValues(resetInputValues + 1);
      setIsUpdatedWithoutCalculation(true);
    }
    if (inputValues.esteira !== mastProps.esteira && inputValues.esteira) {
      mastProps.esteira = inputValues.esteira;
      const updatedMast = await updateMast({ ...mastSelected, updatedAt: new Date(), props: JSON.stringify(mastProps) }, userData.token);
      setMastSelected(updatedMast); 
      if (!wasAlerted) toast.success("Informações salvas com sucesso");
    }
  }

  useEffect(() => {
    if (!antennasList.find((el) => el.modelo === inputValues.modelo) && inputValues.modelo !== "") {
      setAntennasDimensionsEditable(true);
    }
  }, [inputValues.modelo]);

  useEffect(() => {
    setInputValues({ 
      qtdAntenas: 0,
      alturaMastro: "",
      operadora: "",
      condicao: "",
      modelo: "",
      tipo: "",
      CA: "",
      diametro: "",
      altura: "",
      largura: "",
      esteira: mastProps.esteira || "", 
      groupSelected: "",
      antena: {
        altura: "",
        diametro: "",
        espessura: "",
        fabricante: "",
        largura: "",
        modelo: "",
        tipo: ""
      }
    });
    setCAPlaceholder("Coef. de Arrasto");
  }, [resetInputValues]);

  useEffect(() => {
    function extrairPrimeiroNumero(string) {
      const numeros = string.match(/\d+/g); // Encontra todos os números na string
      if (numeros) {
        return parseInt(numeros[0], 10); // Converte o primeiro número para um valor inteiro
      }
      return null; // Retorna null se não houver números na string
    }
    let antennaSelected = 0;
    if (inputValues.groupSelected) antennaSelected = extrairPrimeiroNumero(inputValues.groupSelected);
    setInputValues({
      ...inputValues,
      alturaMastro: inputValues.groupSelected ? mastProps[`antena${antennaSelected}AlturaMastro`] : "",
      operadora: inputValues.groupSelected ? mastProps[`antena${antennaSelected}Operadora`] : "",
      condicao: inputValues.groupSelected ? mastProps[`antena${antennaSelected}Condicao`] : "",
      modelo: inputValues.groupSelected ? mastProps[`antena${antennaSelected}Modelo`] : "",
      tipo: inputValues.groupSelected ? mastProps[`antena${antennaSelected}Tipo`] : "",
      CA: inputValues.groupSelected ? mastProps[`antena${antennaSelected}CA`] : "",
      diametro: inputValues.groupSelected ? mastProps[`antena${antennaSelected}Diametro`] : "",
      altura: inputValues.groupSelected ? mastProps[`antena${antennaSelected}Altura`] : "",
      largura: inputValues.groupSelected ? mastProps[`antena${antennaSelected}Largura`] : ""
    });
  }, [antennaGroupSelected, inputValues.groupSelected]);

  function manageAntennaQtdPlaceholder() {
    const groupSplit = inputValues.groupSelected.split(" ");
    if (groupSplit.indexOf("a") !== -1) return 1 + Number(groupSplit[2]) - Number(groupSplit[0]);
    else if (groupSplit.indexOf("e") !== -1) return 2;
    else return 1;
  }
  function handleMenuClick(menuType, menuName) {
    if (menuType === "project") {
      setProjectSelected({ });
      setProjectNameSelected("");
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else if (menuType === "projectName") {
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else {
      setLeftMenuOptionSelected("");
    }
  }

  function getAntennaSelectedImage() {
    const image_src = inputValues.tipo.replace(" ", "_");
    if (!inputValues.tipo) return;
    else return antennas_images[image_src];
  }

  return (
    <>
      <ResizableLeftContainer>
        <LeftTitle>Antenas</LeftTitle>
        <ReturnIcon onClick={() => {
          if (isUpdated) {
            if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) {
              setLeftMenuOptionSelected("chumbadores");
            }
          }
          else setLeftMenuOptionSelected("chumbadores");
        }}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ReturnIcon>
        <AdvanceIcon disabled={isUpdated} onClick={() => {
          if (isUpdated) {
            if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja avançar?")) setLeftMenuOptionSelected("");
          }
          else setLeftMenuOptionSelected("");
        }}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </AdvanceIcon>
        <MenuTripleOption>
          <h2>Escolha o que deseja fazer</h2>
          <div>
            <div>
              <AddAndDeleteButton onClick={() => {
                setAntennaMenuAction({ ...antennaMenuAction, action: "add", color: "#fff", backgroundColor: " #FE8F03", text: "Adicionar" });
                setAntennaGroupSelected("");
                setInputValues({ ...inputValues, groupSelected: "" });
              }} backgroundColor={"#C9C9C9"} color="#626262">Adicionar</AddAndDeleteButton>
            </div>
            <div>
              <EditAndDeleteSelection backgroundColor={antennaMenuAction.action === "edit" ? "#FE8F03" : "#C9C9C9"} color={antennaMenuAction.action === "edit" ? "#fff" : "#626262"} value={ antennaMenuAction.action === "edit" ? inputValues.groupSelected : "" } onChange={(e) => {
                setAntennaMenuAction({ ...antennaMenuAction, action: "edit", color: "#fff", backgroundColor: "#FE8F03", text: "Editar" });
                setInputValues({ ...inputValues, groupSelected: e.target.value });
              }}>
                <option value={""} >Editar</option>
                {existingAntennaGroups.map((i) => (
                  <option value={i} key={i}>{i}</option>
                ))}
              </EditAndDeleteSelection>
            </div>
            <div>
              <EditAndDeleteSelection backgroundColor={antennaMenuAction.action === "delete" ? "#FE8F03" : "#C9C9C9"} color={antennaMenuAction.action === "delete" ? "#fff" : "#626262"} value={antennaMenuAction.action === "delete" ? inputValues.groupSelected : ""} onChange={(e) => {
                setAntennaMenuAction({ ...antennaMenuAction, action: "delete" });
                setInputValues({ ...inputValues, groupSelected: e.target.value });
                deleteAntennas(e.target.value);
              }}>
                <option value={""} >Deletar</option>
                {existingAntennaGroups.map((i) => (
                  <option value={i} key={i}>{i}</option>
                ))}
              </EditAndDeleteSelection>
            </div>
          </div>
        </MenuTripleOption>
        {(antennaMenuAction.action === "add" || antennaMenuAction.action === "edit") &&
          <>
            <MenuDoubleOption>
              <div>
                <div>
                  <h2>Qtd de antenas</h2>
                  <input placeholder={inputValues.groupSelected ? manageAntennaQtdPlaceholder() : "Quantidade"} value={inputValues.qtdAntenas || ""} onChange={(e) => setInputValues({ ...inputValues, qtdAntenas: e.target.value })} 
                  // disabled={inputValues.groupSelected} 
                    type="number" min={1}> 
                  </input>
                </div>
                <div>
                  <h2>Altura Fixação</h2>
                  <input type="number" placeholder="Digite a altura" value={inputValues.alturaMastro || ""} onChange={(e) => setInputValues({ ...inputValues, alturaMastro: e.target.value.replace(",", ".") })} min={1}></input>
                </div>
              </div>
            </MenuDoubleOption>
            <MenuDoubleOptionWithCreatableSelect>
              <div>
                <div>
                  <h2>Operadora</h2>
                  <TelecomOperatorSelect size={"small"} inputValues={inputValues} setInputValues={setInputValues} value={inputValues.operadora} />
                </div>
                <div>
                  <h2>Condição</h2>
                  <select className="condition" value={inputValues.condicao || ""} onChange={(e) => setInputValues({ ...inputValues, condicao: e.target.value })}>
                    <option value={""} >Condição</option>
                    {condicaoAntennas.map((i) => (
                      <option value={i} key={i}>{i}</option>
                    ))}
                  </select>
                </div>
              </div>
            </MenuDoubleOptionWithCreatableSelect>
            <MenuDoubleOption>
              <div>
                <div>
                  <h2>Tipo</h2>
                  <select value={inputValues.tipo || ""} onChange={(e) => {
                    setIsModeloVisible(true);
                    if (e.target.value === "MW CHEIA" || e.target.value === "MW VAZADA") {
                      setCAPlaceholder("1.6");
                      setInputValues({ ...inputValues, tipo: e.target.value, CA: "1.6" });
                    }
                    else if (e.target.value === "RESERVA") {
                      setIsModeloVisible(false);
                      setCAPlaceholder("1.0");
                      setInputValues({ ...inputValues, tipo: e.target.value, CA: "1.0" });
                    }
                    else if(e.target.value === "") {
                      setCAPlaceholder("Coef. de Arrasto");
                      setInputValues({ ...inputValues, tipo: e.target.value, CA: "" });
                    }
                    else {
                      setCAPlaceholder("1.2");
                      setInputValues({ ...inputValues, tipo: e.target.value, CA: "1.2" });
                      if(e.target.value === "ANTENAS") setIsModeloVisible(false);
                    }
                  }}>
                    <option value={""} >Tipo</option>
                    {antennasTipos.map((i) => (
                      <option value={i} key={i}>{i}</option>
                    ))}
                  </select>
                </div>
                <div>
                  <h2>C.A.</h2>
                  <input value={String(inputValues.CA).replace(",", ".") || ""} type="number" placeholder={CAPlaceholder.replace(",", ".")} onChange={(e) => setInputValues({ ...inputValues, CA: e.target.value })}></input>
                </div>
              </div>
            </MenuDoubleOption>
            { isModeloVisible ? <MenuOptionAntennaModel>
              <h2>Modelo</h2>
              <AntennaModelSelect value={inputValues.modelo} inputValues={inputValues} setInputValues={setInputValues} antennasList={inputValues.tipo ? antennasList.filter((antenna) => antenna.tipo === inputValues.tipo || antenna.tipo.slice(0, 2) === inputValues.tipo.slice(0, 2) || (antenna.tipo === "PV" && inputValues.tipo === "PAINEL VAZADO")) : []}></AntennaModelSelect>
            </MenuOptionAntennaModel> : ""}
            <MenuTripleOption>
              <h2>Dimensões (mm)</h2>
              <div>
                { isModeloVisible ? <div>
                  <h3>Diâmetro</h3>
                  <input placeholder={inputValues.diametro ? inputValues.diametro : "ø"} onChange={(e) => setInputValues({ ...inputValues, antena: { ...inputValues.antena, diametro: e.target.value }, diametro: e.target.value })} value={inputValues.antena?.diametro || ""}></input>
                </div> : ""}
                <div>
                  <h3>Altura</h3>
                  <input placeholder={inputValues.altura ? inputValues.altura : "-"} onChange={(e) => setInputValues({ ...inputValues, antena: { ...inputValues.antena, altura: e.target.value }, altura: e.target.value })} value={inputValues.antena?.altura || ""}></input>
                </div>
                <div>
                  <h3>Largura</h3>
                  <input placeholder={inputValues.largura ? inputValues.largura : "-"} onChange={(e) => setInputValues({ ...inputValues, antena: { ...inputValues.antena, largura: e.target.value }, largura: e.target.value })} value={inputValues.antena?.largura || ""}></input>
                </div>
              </div>
            </MenuTripleOption>
            <MenuOption>
              {antennaMenuAction.action && <AntennaActionButton color={antennaMenuAction.color} backgroundColor={antennaMenuAction.backgroundColor} onClick={() => {
                if (antennaMenuAction.action === "add") addAntenna();
                if (antennaMenuAction.action === "edit") updateAntennas(inputValues.groupSelected);
              }} >{antennaMenuAction.text}</AntennaActionButton>}
            </MenuOption>
          </>
        }
        <MenuDoubleOption>
          <VerticalSpacing />
          <h2>Esteira de cabos (mm)</h2>
          <div>
            <div>
              <input value={inputValues.esteira || ""} type="number" placeholder={mastProps.esteira || "Esteira"} onChange={(e) => { setInputValues({ ...inputValues, esteira: e.target.value.replace(",", ".") });
                // setIsUpdated(true);
              }}></input>
            </div>
            <div>
              { inputValues.esteira && inputValues.esteira !== mastProps.esteira && <SavingEsteiraButton onClick={saveChanges}>Salvar Esteira</SavingEsteiraButton>}
            </div>
          </div>
        </MenuDoubleOption>
        <ButtonPositioner >
          <ReturnButton onClick={() => {
            if (!isUpdated) setLeftMenuOptionSelected("");
            else if (isUpdated && window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("");
          }} >{" Voltar "}</ReturnButton>
          {1 === 3 ?           
            <LoadingButton>{ "Carregando" }</LoadingButton> :
            <SavingButton disabled={!isUpdated} onClick={saveChanges}>{ "Salvar" }</SavingButton>}
        </ButtonPositioner>
      </ResizableLeftContainer>
      <ResizableRightContainer>
        <RightContainer>
          <ProjectNameWrapper>
            <h2><span onClick={() => handleMenuClick("project")}>Projetos</span> / <span onClick={() => handleMenuClick("projectName")}>{projectSelected?.nomeProjeto}</span> <span onClick={() => handleMenuClick("")}>{mastSelected?.identificador ? ` / ${mastSelected?.identificador}` : ""}</span></h2>
            <h1>Antenas</h1>
            <div></div>
          </ProjectNameWrapper>
          <AntennaTableContainer>
            <AntennaTable inputValues={inputValues} reloadProject={reloadProject}></AntennaTable>
          </AntennaTableContainer>
          <TableAndEsteiraContainer isAntennaTypeSelected={getAntennaSelectedImage()}>
            {getAntennaSelectedImage() && (
              <AntennaPictureContainer>
                <AntennaPictureDescription>Antena {inputValues.tipo}</AntennaPictureDescription>
                <AntennaImg src={getAntennaSelectedImage()} alt="Antenna Img" />
              </AntennaPictureContainer> )}
            <AEVTable mastProps={atualizedMastProps}/>
          </TableAndEsteiraContainer>
        </RightContainer>
      </ResizableRightContainer>
    </>
  );
}

export function AntennaModelSelect({ inputValues, setInputValues, antennasList }) {
  const completeAntennasList = [];
  useEffect(() => {
    antennasList.forEach(antenna => {
      const object = { value: antenna.modelo, label: antenna.modelo };
      completeAntennasList.push(object);
    });
    setAntennasSelectionList(completeAntennasList);
  }, [antennasList]);

  const [antennasSelectionList, setAntennasSelectionList] = useState ([]);
  const handleOperatorChange = (selectedOption, actionMeta) => {
    if(selectedOption) {
      if (!antennasSelectionList.find((el) => el.label === selectedOption.label)) {
        const newOption = { value: selectedOption.value, label: selectedOption.label };
        antennasSelectionList.push(newOption);
        setInputValues({ ...inputValues, antena: {}, modelo: selectedOption.value, diametro: 0, altura: 0, largura: 0 });
        return;
      }
      const selectedAntenna = antennasList.find((antenna) => antenna.modelo === selectedOption.value);
      if (selectedAntenna) setInputValues({ ...inputValues, antena: selectedAntenna, modelo: selectedOption.value, diametro: selectedAntenna.diametro, altura: selectedAntenna.altura, largura: selectedAntenna.largura });
    }
    else setInputValues({ ...inputValues, antena: {}, modelo: "", diametro: "", altura: "", largura: "" });
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      backgroundColor: "#ffffff",
      border: "1px solid #C8C8C8",
      height: "4.5vh",
      borderRadius: "8px",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      color: "#000"
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
      position: "absolute",
      right: "-10px",
      size: 2
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
    }),
    input: (provided) => ({
      ...provided,
      color: "#000",
    })
  };

  return (
    <StyledSelect
      options={antennasSelectionList}
      styles={customStyles}
      value={inputValues.modelo ? antennasSelectionList.find((option) => option.value === inputValues.operadoraMastro) : ""}
      onChange={handleOperatorChange}
      placeholder={inputValues.modelo ? inputValues.modelo : "Selecione o modelo"}
      isClearable
      isSearchable
      menuPlacement="auto"
    />
  );
}

const StyledSelect = styled(CreatableSelect)`
  .react-select__control {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4.5vh;
    border-radius: 8px;
    /*font-family: "Lexend Deca", sans-serif*/
   font-family: 'Roboto', sans-serif;;
    font-size: 26px;
    color: #000;
  }
  .react-select__placeholder {
    color: #000;
  }

  .react-select__dropdown-indicator {
    color: #000;
  }

  .react-select__option {
    color: #000;
  }
`;

const RightContainer = styled.div`
  width: calc(75vw);
  height: calc(75vh);
`;

const AddAndDeleteButton = styled.div`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 4vh;
  font-weight: 500;
  font-size: 16.5px;
  cursor: pointer;
  :hover, :active {
    background-color: #FE8F03;
    color: #fff;
  }
`;

const EditAndDeleteSelection = styled.select`
  width: 100%;
  background-color: ${(props) => props.backgroundColor} !important;
  height: 4vh;
  padding: 0px !important;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;;
  font-size: 16px;
  text-align: center;
  color: ${(props) => props.color};;
  cursor: pointer;
  font-weight: 500 !important;
  ::placeholder {
    color: #626262;
  }
  :hover, option:hover, :active, option:active {
    background-color: #FE8F03 !important;
    color: #fff;
  }
`;

const MenuOptionAntennaModel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1vh 0;
  position: relative;
  select {
    background-color: #fff;
  }
  h2 {
    font-weight: 400;
    font-size: 17px;
    color: #111111;
    margin-bottom: 0.6vh;
  }
  > div {
    width: 100%;
  }
`;

const TableAndEsteiraContainer = styled.div`
  display: flex;
  width: 90%;
  margin-top: 30px;
  margin-left: 4vw;
  max-height: 40vh;
  justify-content: ${(props) => (props.isAntennaTypeSelected ? "space-between" : "right")};
  align-items: center;
`;

const AntennaActionButton = styled.div`
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 8px !important;
  height: 4vh !important;
  font-weight: 500 !important;
  font-size: 16.5px !important;
  cursor: pointer !important;
  text-align: center;
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
`;

const SavingEsteiraButton = styled.div`
  width: 100px;
  height: 4.5vh;
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F16E00;
  cursor: pointer;
  font-weight: 400;
`;

const MenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0.5vh 0;
  position: relative;

    h2 {
    font-weight: 400;
    font-size: 15.5px;
    color: #111111;
    margin-bottom: 0.6vh;
  }

  input,select {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4.5vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }

  ion-icon {
    position: absolute;
    top: 0;
    right: 0;
    color: #ffffff;
  }

  >div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  >div>div{
    width: 46%;
  }

  h3 {
    font-size: 15px;
    color: #111111;
    font-weight: 300;
    margin-bottom: 0.6vh;
  }
`;

const MenuDoubleOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0.5vh 0;
  position: relative;

    h2 {
    font-weight: 400;
    font-size: 15.5px;
    color: #111111;
    margin-bottom: 0.85vh;
  }

  input,select {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4.5vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }

  ion-icon {
    position: absolute;
    top: 0;
    right: 0;
    color: #ffffff;
  }

  >div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  >div>div{
    width: 46%;
  }
`;

const MenuTripleOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0.6vh 0;
  position: relative;

    h2 {
    font-weight: 400;
    font-size: 18px;
    color: #111111;
    margin-bottom: 1vh;
  }

  input,select {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }

  ion-icon {
    position: absolute;
    top: 0;
    right: 0;
    color: #ffffff;
  }

  >div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  >div>div{
    width: 29%;
    h3 {
      font-size: 15px;
      color: #111111;
      font-weight: 300;
      margin: 0 0 6px 4px;
    }
  }
`;

const MenuDoubleOptionWithCreatableSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0.6vh 0;
  position: relative;

    h2 {
    font-weight: 400;
    font-size: 16px;
    color: #111111;
    margin-bottom: 0.8vh;
  }

  .condition {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4.5vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }

  >div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  >div>div{
    width: 46%;
  }
`;

const ProjectNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: -4.65vh 0 -3vh 3.1vw;

  h2 {
    color: #666666;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  h1 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
  }
  >div {
    width: 95.5%;
    height: 1.25px;
    margin: -18px 0 15px 0;
    background-color: #D9D9D9;
  }
  span {
    transition: 0.0001s all;
    cursor: pointer;
    :hover {
      font-weight: 600 !important;
    }
  }
`;

const AntennaImg = styled.img`
  width: 230px;
`;

const AntennaTableContainer = styled.div`
  display: flex;
  width: 100%;
`;

const AntennaPictureContainer = styled.div`
  display: flex;
  width: 20vw;
  height: 31vh;
  align-items: center;
  justify-content: center;
  border: 1px solid #C8C8C8;
  border-radius: 8px;
  position: relative;
  img {
    margin-top: 20px;
  };
`;

const AntennaPictureDescription = styled.div`
  position: absolute;
  top: -1px;
  width: 100.5%;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F2F2F2;
  border-radius: 8px;
  border-top: 1px solid #C8C8C8;
  border-right: 1px solid #C8C8C8;
  border-left: 1px solid #C8C8C8;
  font-weight: 600;
  font-size: 16px;
`;

const VerticalSpacing = styled.div`
  margin-top: 12px;
`;
