import styled from "styled-components";
import colors from "../../../constants/color-constants";
import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { InputMenu } from "../../common/input-menu";

export default function TowerCalculationLeftMenu({ calculateTowerData, combinationType, setCombinationType }) {
  const [isLoading, setIsLoading] = useState(false);
  const combinationTypes = ["ASD", "LRDF", "NBR"];

  const handleCalculateClick = async() => {
    setIsLoading(true);
    await calculateTowerData();

    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  };

  return (
    <Container>
      <InputMenu>
        <h2>Combinações</h2>
        <select
          name={"combinationType"}
          value={combinationType}
          onChange={(e) => setCombinationType(e.target.value)}
        >
          <option value={""} key={"comninationType-empty"}>Selecione o tipo de combinação</option>
          {combinationTypes.map((type) => (
            <option value={type} key={type}>{type}</option>
          ))}
        </select>
      </InputMenu>
      <CalculateButton
        onClick={handleCalculateClick}
        disabled={isLoading}
      >
        {isLoading
          ? <ThreeDots
            height={20}
            radius={5}
            color={colors.kcOrange}
            ariaLabel={"three-dots-loading"}
          />
          : "Calcular"
        }
      </CalculateButton>
    </Container>
  );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const CalculateButton = styled.button`
    display: flex;
    width: 100%;
    height: 40px;
    margin: 10px 0;
    justify-content: center;
    align-items: center;
    border: 1px solid ${(props) => props.disabled ? colors.kcLightGray : colors.kcOrange};
    border-radius: 6px;
    background-color: ${(props) => props.disabled ? colors.kcLightGray : colors.kcOrange};
    color: ${colors.kcWhiteColor};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
    cursor: ${(props) => props.disabled ? "default" : "pointer"};
`;
