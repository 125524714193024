import React from "react";
import styled from "styled-components";
import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";
import AutocompleteInput from "../../common/customAutoCompleteInput";
import colors from "../../../constants/color-constants";

export default function TowerAntennaLeftMenu({
  antennaGroups,
  antennaGroupSelectedInMenu,
  setAntennaGroupSelectedInMenu,
  inputValues,
  handleInputChange,
  addAntenna,
  deleteAntenna,
  operatorOptions,
  conditionOptions,
  antennasTypes,
  antennasList,
}) {
  const currentAntennaGroup = inputValues.antennas?.find(group => group.groupName === antennaGroupSelectedInMenu);

  function getAntennaModelsList() {
    if (!currentAntennaGroup?.type) return [];
    if (!antennasList[currentAntennaGroup.type]) return (
      <option value={"-"} key={"no-model"}>{"-"}</option>
    );
    return antennasList[currentAntennaGroup.type]?.map((antenna, index) => (
      <option value={antenna.modelo} key={index}>{antenna.modelo}</option>
    ));
  }

  return (
    <>
      <InputMenu>
        <h2>Antenas selecionadas</h2>
        <select
          className="highlightColor"
          value={antennaGroupSelectedInMenu}
          onChange={(e) => setAntennaGroupSelectedInMenu(e.target.value)}
        >
          <option value={""}>Selecione</option>
          {antennaGroups?.map((i) => <option value={i} key={i}>{i}</option>)}
        </select>
      </InputMenu>

      {antennaGroupSelectedInMenu !== "" &&
        <>
          <InputMenu>
            <div>
              <div>
                <h2>{"Qtd de Antenas"}</h2>
                <CustomInput
                  disabled={antennaGroupSelectedInMenu === ""}
                  placeholder="-"
                  value={currentAntennaGroup?.qtdAntennas ?? ""}
                  onChange={newValue => handleInputChange("qtdAntennas", newValue)}
                  toFixed={0}
                  isNumber={true}
                />
              </div>

              <div>
                <h2>{"Altura Fixação (m)"}</h2>
                <CustomInput
                  disabled={antennaGroupSelectedInMenu === ""}
                  placeholder="-"
                  value={currentAntennaGroup?.fixingHeight ?? ""}
                  onChange={newValue => handleInputChange("fixingHeight", newValue)}
                  isNumber={true}
                  unit={"m"}
                />
              </div>
            </div>
          </InputMenu>

          <InputMenu>
            <div>
              <div>
                <h2>{"Operadora"}</h2>
                <AutocompleteInput
                  inputValue={currentAntennaGroup?.operator || ""}
                  handleInputChange={(field, value) => handleInputChange(field, value)}
                  list={operatorOptions || []}
                  field={"operator"}
                  placeholder={"Selecione"}
                  isCreatable={true}
                />
              </div>

              <div>
                <h2>{"Condição"}</h2>
                <select
                  name="condition"
                  disabled={antennaGroupSelectedInMenu === ""}
                  value={currentAntennaGroup?.condition ?? ""}
                  onChange={e => handleInputChange("condition", e.target.value)}
                >
                  <option value={""} key={"condition-empty"}>Selecione</option>
                  {conditionOptions.map((type) => (
                    <option value={type} key={type}>{type}</option>
                  ))}
                </select>
              </div>
            </div>
          </InputMenu>

          <InputMenu>
            <div>
              <div>
                <h2>{"Tipo"}</h2>
                <select
                  name="type"
                  disabled={antennaGroupSelectedInMenu === ""}
                  value={currentAntennaGroup?.type ?? ""}
                  onChange={e => handleInputChange("type", e.target.value)}
                >
                  <option value={""} key={"type-empty"}>Selecione</option>
                  {antennasTypes.map((type) => (
                    <option value={type} key={type}>{type}</option>
                  ))}
                </select>
              </div>

              <div>
                <h2>{"C.A."}</h2>
                <CustomInput
                  disabled={antennaGroupSelectedInMenu === ""}
                  placeholder="-"
                  value={currentAntennaGroup?.dragCoef ?? ""}
                  onChange={newValue => handleInputChange("dragCoef", newValue)}
                  toFixed={1}
                  isNumber={true}
                />
              </div>
            </div>
          </InputMenu>

          <InputMenu>
            <h2>{"Modelo"}</h2>
            <select
              name="model"
              disabled={antennaGroupSelectedInMenu === ""}
              value={currentAntennaGroup?.model ?? ""}
              onChange={e => handleInputChange("model", e.target.value)}
            >
              <option value={""} key={"model-empty"}>Modelo da antena</option>
              {getAntennaModelsList()}
            </select>
          </InputMenu>

          <InputMenu>
            <h2>{"Dimensões (mm)"}</h2>
            <div>
              <div>
                <h2>{"Diâmetro"}</h2>
                {
                  currentAntennaGroup?.type.slice(0, 10) === "HELICOIDAL"
                    ? <AutocompleteInput
                      disabled={antennaGroupSelectedInMenu === ""}
                      inputValue={currentAntennaGroup?.diameterDimension || ""}
                      handleInputChange={(field, value) => handleInputChange(field, value)}
                      list={[600, 1000]}
                      field={"diameterDimension"}
                      placeholder={"ø"}
                    />
                    : <CustomInput
                      disabled={antennaGroupSelectedInMenu === ""}
                      placeholder={"ø"}
                      name={"diameterDimension"}
                      value={currentAntennaGroup?.diameterDimension || ""}
                      onChange={newValue => handleInputChange("diameterDimension", newValue)}
                      rawNumber={true}
                      isNumber={true}
                      unit={"mm"}
                    />
                }
              </div>

              <div>
                <h2>{"Altura"}</h2>
                <CustomInput
                  disabled={antennaGroupSelectedInMenu === ""}
                  placeholder={"-"}
                  name={"heightDimension"}
                  value={currentAntennaGroup?.heightDimension || ""}
                  onChange={newValue => handleInputChange("heightDimension", newValue)}
                  rawNumber={true}
                  isNumber={true}
                  unit="mm"/>
              </div>

              <div>
                <h2>{"Largura"}</h2>
                <CustomInput
                  disabled={antennaGroupSelectedInMenu === ""}
                  placeholder={"-"}
                  name={"widthDimension"}
                  value={currentAntennaGroup?.widthDimension || ""}
                  onChange={newValue => handleInputChange("widthDimension", newValue)}
                  rawNumber={true}
                  isNumber={true}
                  unit="mm"/>
              </div>
            </div>
          </InputMenu>
        </>
      }

      <AddAndRemoveAntennaContainer>
        <AddAntennaButton onClick={addAntenna}>
          <p>{"Adicionar antenas"}</p>
          <div> + </div>
        </AddAntennaButton>

        <div onClick={antennaGroupSelectedInMenu === "" ? () => {} : deleteAntenna}>
          <ion-icon name="trash"></ion-icon>
        </div>
      </AddAndRemoveAntennaContainer>

      <Divider />

      <InputMenu>
        <h2>{"Esteira de cabos (mm)"}</h2>
        <CustomInput
          placeholder={"-"}
          value={inputValues.cableTrayLength ?? ""}
          onChange={newValue => handleInputChange("cableTrayLength", newValue)}
          isNumber={true}
          unit={"mm"}
        />
      </InputMenu>
    </>
  );
}

const AddAndRemoveAntennaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 16px;
  gap: 8px;
  
  >div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background-color: ${colors.kcButtonLightGray};
    cursor: pointer;
  }

  >div:hover {
    background-color: ${colors.kcRed};
    color: ${colors.kcWhiteColor};

    ion-icon {
      color: ${colors.kcWhiteColor};
    }
  }
  
  ion-icon {
    color: ${colors.kcBlackColor};
    font-size: 25px;
  }
`;

const AddAntennaButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 10px 20px;
  border-radius: 6px;
  background-color: ${colors.kcButtonLightGray};
  color: ${colors.kcBlackColor};
  cursor: pointer;

  :hover {
    background-color: ${colors.kcButtonGray};
  }
  
  p {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }
  
  div {
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    border-radius: 30px;
    background-color: ${colors.kcWhiteColor};
    font-size: 18px;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-bottom: 12px;
  box-sizing: border-box;
  background-color: ${colors.kcButtonGray};
`;
