import { useCallback, useContext, useEffect, useState } from "react";
import UserContext from "../../../contexts/UserContext";
import { TowerContext } from "../../../contexts/TowerContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import styled from "styled-components";
import LeftMenu from "../../common/left-menu";
import TowerWindLeftMenu from "./tower_windLeftMenu";
import TowerWindRightContainer from "./tower_windRightContainer";
import { useTowerWind } from "../../../hooks/api/tower-api/useTowerWind";
import { toast } from "react-toastify";
import WindModel from "../../../models/WindModel";
import useUnsavedChangesAlert from "../../../hooks/useUnsavedChangesAlert";

export default function TowerWindPage() {
  const { userData } = useContext(UserContext);
  const { towerId, windSelected, setWindSelected } = useContext(TowerContext);
  const { reloadProject, setIsUpdatedWithoutCalculation, isLoading, setIsLoading,
    googleMapsApiKey, towerLeftMenuWidth } = useContext(ProjectContext);

  const { act: fetchWind } = useTowerWind("get");
  const { act: saveWind } = useTowerWind("post");

  const [inputValues, setInputValues] = useState(new WindModel(windSelected).wind);

  useEffect(() => {
    loadWindData().then(response => {
      if (response) {
        const windModel = new WindModel(response);
        setWindSelected(windModel.wind);
        setInputValues(windModel.wind);
      }
    });
  }, [towerId, userData.token]);

  const loadWindData = useCallback(async() => {
    setIsLoading(true);
    try {
      return await fetchWind(towerId, userData.token);
    } catch (error) {
      if (error.response.status !== 400) {
        toast.error("Erro ao buscar dados de ventos.");
      }
    } finally {
      setIsLoading(false);
    }
  }, [fetchWind, towerId, userData.token]);

  const s2Types = ["I-A", "II-A", "III-A", "IV-A", "V-A", "I-B",
    "II-B", "III-B", "IV-B", "V-B", "I-C", "II-C", "III-C", "IV-C", "V-C"];
  const s3Types = ["0.83", "0.95", "1.00", "1.10"];

  const fieldsToCheck = ["location", "coordinates", "v0", "s1", "s2", "s3"];
  const isInputModified = useUnsavedChangesAlert(
    { ...inputValues }, windSelected, reloadProject, fieldsToCheck);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    const windModel = new WindModel(inputValues);
    windModel.updateWind(name, value);
    setInputValues(windModel.wind);
  }, [inputValues, setInputValues]);

  const handleLocationChange = useCallback((location, coordinates) => {
    const windModel = new WindModel(inputValues);
    const roadMapImageURL = `https://maps.googleapis.com/maps/api/staticmap?center=${coordinates?.replace(/\s/g, "")}&zoom=15&size=600x380&maptype=roadmap&markers=size:mid%7Ccolor:red%7C${coordinates?.replace(/\s/g, "")}&key=${googleMapsApiKey}`;
    const satelliteMapImageURL = `https://maps.googleapis.com/maps/api/staticmap?center=${coordinates?.replace(/\s/g, "")}&zoom=17&size=600x380&maptype=hybrid&markers=size:mid%7Ccolor:red%7C${coordinates?.replace(/\s/g, "")}&key=${googleMapsApiKey}`;

    windModel.updateWind("location", location);
    windModel.updateWind("coordinates", coordinates);
    windModel.updateWind("roadMapImageURL", roadMapImageURL);
    windModel.updateWind("satelliteMapImageURL", satelliteMapImageURL);

    setInputValues(windModel.wind);
  }, [inputValues, setInputValues]);

  async function updateWindData() {
    const windModel = new WindModel(inputValues);
    if (!windModel.validate()) return false;

    setIsLoading(true);
    try {
      const updatedData = await saveWind(windModel.toJSON(), towerId, userData.token);
      const updatedModel = new WindModel(updatedData);
      setWindSelected(updatedModel.wind);
      setInputValues(updatedModel.wind);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações de vento atualizadas com sucesso");
      return true;
    } catch (error) {
      console.error(error);
      toast.error("Erro ao salvar dados de ventos.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Vento"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updateWindData}
      >
        <TowerWindLeftMenu
          inputValues={inputValues}
          handleInputChange={(e) => handleInputChange(e)}
          s2Types={s2Types}
          s3Types={s3Types}
        />
      </LeftMenu>
      <TowerWindRightContainer
        inputValues={inputValues}
        handleInputChange={(e) => handleInputChange(e)}
        handleLocationChange={(location, coordinates) => handleLocationChange(location, coordinates)}
        googleMapsApiKey={googleMapsApiKey}
        towerLeftMenuWidth={towerLeftMenuWidth}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
