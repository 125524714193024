import styled from "styled-components";
import TowerSilhouette from "../../common/tower-silhouette";
import CustomTable from "../../common/customTable";
import CustomRadioButton from "../../common/custom-radio-buttons";
import { diagonalLockingsInfo } from "../../../constants/tower-trusses-data";
import colors from "../../../constants/color-constants";
import trechoMastro from "../../../assets/images/towerImages/trecho-mastro.svg";
import squareSectionImg from "../../../assets/images/towerImages/tower-section-square.svg";

export default function TowerDiagonalLockingContainer({
  nBars,
  inputValues, 
  segmentSelectedInMenu, 
  diagonalSelectedInMenu,
  handleInputChange,
  isFieldEnabled,
  towerImages,
}) {
  const currentSegmentIndex = segmentSelectedInMenu === "" ? segmentSelectedInMenu : Number(segmentSelectedInMenu - 1);
  const currentDiagonal = currentSegmentIndex !== "" && diagonalSelectedInMenu !== ""
    && inputValues[segmentSelectedInMenu - 1]
    ? inputValues[currentSegmentIndex][diagonalSelectedInMenu] : "";

  const tableColumnTitles = ["Barra", "Travamento", "Parafuso"];
  const tableLines = isFieldEnabled() ? 
    nBars[diagonalSelectedInMenu][segmentSelectedInMenu - 1] : 1;
  
  const generateTableContent = () => {
    if (segmentSelectedInMenu === "" || diagonalSelectedInMenu === "") return [];
    return Array.from({ length: nBars[diagonalSelectedInMenu][segmentSelectedInMenu - 1] ?? 0 },
      (_, index) => [
        `T${index + 1}`,
        formatProfileDimensions(index),
        currentDiagonal?.bars[index]?.diameterConnectionBolts ?? "",
      ]);
  };

  function formatProfileDimensions(index) {
    const selectedBar = inputValues[segmentSelectedInMenu - 1][diagonalSelectedInMenu].bars[index] || {};
    
    if (selectedBar.profileType === "L - Cantoneira") {
      return selectedBar?.profileDimensions ?? "";
    } else if (selectedBar.profileType === "T - Tubo Vazado") {
      return `${selectedBar?.profileDiameter ?? ""} 
        ${selectedBar?.profileThickness ? selectedBar?.profileThickness + "mm" : ""}`;
    } else if (selectedBar.profileType === "BR - Barra Maciça") {
      return selectedBar?.profileDiameter ?? "";
    } else if (selectedBar.profileType === "U - Perfil U") {
      return `${selectedBar?.profileFlange ? selectedBar?.profileFlange + "mm" : ""} 
        ${selectedBar?.profileWeb ? selectedBar?.profileWeb + "mm" : ""} 
        ${selectedBar?.profileThickness ?? ""}`;
    }
  }

  function renderLockingPoints() {
    if (currentDiagonal?.hasDiagonalLocking !== "Sim") return;

    const lockingPointsOptions = Object.keys(diagonalLockingsInfo);
    return lockingPointsOptions.map((points, index) => (
      <SectionImg
        key={index}
        selected={Number(currentDiagonal?.lockingPoints) === Number(points)}
        onClick={() => handleInputChange("lockingPoints", points)}
      >
        <CustomRadioButton
          value={points}
          label={`${points} ${points <= 1 ? "Parte" : "Partes"}`}
          disabled={!isFieldEnabled()}
          checked={Number(currentDiagonal?.lockingPoints) === Number(points)}
          onClick={() => handleInputChange("lockingPoints", points)}
        />
        <img src={squareSectionImg} width={"90px"} alt={`${points} ${points <= 1 ? "Parte" : "Partes"}`}/>
      </SectionImg>
    ));
  }

  function renderLockingTypes() {
    if (currentDiagonal.hasDiagonalLocking !== "Sim") return;
    if (currentDiagonal.lockingPoints === "") return;

    const lockingTypesOptions = diagonalLockingsInfo[currentDiagonal?.lockingPoints] || [];
    return lockingTypesOptions.map((type, index) => (
      <SectionImg
        key={index}
        selected={currentDiagonal?.lockingType === type.lockingType}
        onClick={() => handleInputChange("lockingType", type.lockingType)}
      >
        <CustomRadioButton
          value={type.lockingType}
          label={`Tipo ${type.lockingType}`}
          disabled={!isFieldEnabled()}
          checked={currentDiagonal?.lockingType === type.lockingType}
          onClick={() => handleInputChange("lockingType", type.lockingType)}
        />
        <img src={squareSectionImg} width={"90px"} alt={`Tipo ${type.lockingType}`}/>
      </SectionImg>
    ));
  }

  return (
    <Container>
      <OptionsContainer>
        <h2>Pontos de Travamento</h2>
        <div>
          {isFieldEnabled() && renderLockingPoints()}
        </div>
      </OptionsContainer>

      <OptionsContainer>
        <h2>Tipo de Travamento Diagonal</h2>
        <div>
          {isFieldEnabled() && renderLockingTypes()}
        </div>
      </OptionsContainer>

      <ImageTableContainer>
        <ImgContainer>
          <h2>Travamento {currentDiagonal?.lockingType ?? ""}</h2>
          <img src={trechoMastro} alt={""} />
        </ImgContainer>

        <TableContainer>
          <CustomTable 
            lines={tableLines} 
            columns={3} 
            columnTitles={tableColumnTitles} 
            content={generateTableContent()} 
          />
        </TableContainer>
      </ImageTableContainer>

      <TowerImgContainer>
        <h2>Silhueta</h2>
        <TowerSilhouette images={towerImages} />
      </TowerImgContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;

  h2 {
    color: ${colors.kcTextColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }
`; 

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-color: ${colors.kcLightGray};
  border-radius: 6px;
  max-height: calc(100vh - 245px);
  gap: 20px;
  box-sizing: border-box;

  >div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    width: 325px;
    gap: 20px;
    overflow-y: auto;
  }
`;

const SectionImg = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 150px;
  min-height: 150px;
  padding: 15px;
  background-color: ${colors.kcWhiteColor};
  border-radius: 8px;
  border: ${(props) => props.selected ? `2px solid ${colors.kcOrange}` : "none"};
  box-sizing: border-box;

  img {
    width: ${(props) => props.width};
  }
`;

const ImageTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  max-height: calc(100vh - 245px);
  gap: 30px;
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  padding: 18px 20px 8px;
  border-radius: 6px;
  background-color: ${colors.kcLightGray};

  img {
    width: 280px;
  }
`;

const TableContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 550px;
  height: min-content;
  border-radius: 6px;
  overflow-y: scroll;
`;

const TowerImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: calc(100vh - 245px);
    padding: 20px;
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    background-color: ${colors.kcLightGray};
`;
