import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";
import GeometryModel from "./GeometryModel";
import TrussesModel from "./TrussModel";
import SectionModel from "./SectionModel";
import PlatformModel from "./PlatformModel";
import WindModel from "./WindModel";
import AntennasModel from "./AntennaModel";
import MountsModel from "./MountModel";
import DiagonalsModel from "./DiagonalModel";
import RedundantsModel from "./RedundantModel";
import DiagonalLockingsModel from "./DiagonalLockingModel";
import HorizontalLockingsModel from "./HorizontalLockingModel";
import AnchorModel from "./AnchorModel";
import HorizontalsModel from "./HorizontalModel";

class TowerModel {
  constructor(
    projectId = "",
    projectName = "",
    geometry = new GeometryModel(),
    trusses = new TrussesModel(),
    sections = new SectionModel(),
    platforms = new PlatformModel(),
    winds = new WindModel(),
    antennas = new AntennasModel(),
    mounts = new MountsModel(),
    diagonals = new DiagonalsModel(),
    horizontals = new HorizontalsModel(),
    redundants = new RedundantsModel(),
    diagonalLockings = new DiagonalLockingsModel(),
    horizontalLockings = new HorizontalLockingsModel(),
    anchors = new AnchorModel(),
  ) {
    this.projectId = "";
    this.projectName = "";
    this.geometry = geometry;
    this.trusses = trusses;
    this.sections = sections;
    this.platforms = platforms;
    this.winds = winds;
    this.antennas = antennas;
    this.mounts = mounts;
    this.diagonals = diagonals;
    this.horizontals = diagonals;
    this.redundants = redundants;
    this.diagonalLockings = diagonalLockings;
    this.horizontalLockings = horizontalLockings;
    this.anchors = anchors;
  }

  updateField(field, value) {
    this[field] = value;
  }

  validate(trussesWithHorizontals) {
    let errors = [];
    const trussesHeights = this.trusses.trusses.map(truss => truss.totalHeight);

    if (!this.geometry.validate()) return false;
    if (!this.trusses.validate(this.geometry.totalHeight)) return false;
    if (!this.sections.validate(this.geometry.totalHeight, trussesHeights)) return false;
    if (!this.platforms.validate(this.geometry.totalHeight)) return false;
    if (!this.winds.validate()) return false;
    if (!this.antennas.validate(this.geometry.totalHeight)) return false;
    if (!this.mounts.validate()) return false;
    if (!this.diagonals.validate()) return false;
    if (!this.horizontals.validate(trussesWithHorizontals)) return false;
    if (!this.redundants.validate()) return false;
    if (!this.diagonalLockings.validate()) return false;
    if (!this.horizontalLockings.validate()) return false;
    if (!this.anchors.validate()) return false;

    if (errors.length > 0) {
      const errorMessage = customToastError(
        errors,
        "Preencha todos os valores corretamente para salvar as informações dos montantes. Falta(m):"
      );
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  toJSON() {
    return JSON.stringify(this.tower);
  }
}

export default TowerModel;
