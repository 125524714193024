function calculateQVento(projectProps, alturaFixacao) {
  let b = 1;
  let Fr = 1;
  let p = 1;
  const s2 = projectProps.s2;
  const z = Number(projectProps.alturaEdificacao) + Number(alturaFixacao);
  const classeVento = s2?.slice(-1) || "A";
  let s2Value = 1;
  if (s2?.startsWith("V")) {
    if (classeVento === "A") {
      b = 0.74;
      p = 0.15;
    }
    else if (classeVento === "B") {
      b = 0.73;
      p = 0.16;
      Fr = 0.98;
    }
    else {
      b = 0.71;
      p = 0.175;
      Fr = 0.95;
    }
  }
  else if (s2?.slice(0, 2) === "IV") {
    if (classeVento === "A") {
      b = 0.86;
      p = 0.12;
    }
    else if (classeVento === "B") {
      b = 0.85;
      p = 0.125;
      Fr = 0.98;
    }
    else {
      b = 0.84;
      p = 0.135;
      Fr = 0.95;
    }
  }
  else if (s2?.slice(0, 3) === "III") {
    if (classeVento === "A") {
      b = 0.94;
      p = 0.10;
    }
    else if (classeVento === "B") {
      b = 0.94;
      p = 0.105;
      Fr = 0.98;
    }
    else {
      b = 0.93;
      p = 0.115;
      Fr = 0.95;
    }
  }
  else if (s2?.slice(0, 2) === "II") {
    if (classeVento === "A") {
      p = 0.085;
    }
    else if (classeVento === "B") {
      p = 0.09;
      Fr = 0.98;
    }
    else {
      p = 0.10;
      Fr = 0.95;
    }
  }
  else {
    if (classeVento === "A") {
      b = 1.10;
      p = 0.06;
    }
    else if (classeVento === "B") {
      b = 1.11;
      p = 0.065;
      Fr = 0.98;
    }
    else {
      b = 1.12;
      p = 0.07;
      Fr = 0.95;
    }
  }
  s2Value = b * Fr * (z / 10) ** p;

  let carregamentoVento;
  carregamentoVento = Number(projectProps.v0)*Number(projectProps.s1)*s2Value*Number(projectProps.s3);
  carregamentoVento = (Math.pow(carregamentoVento, 2))*0.06139;
  return carregamentoVento;
};

function calculateAEV(index, mastProps) {
  const altura = Number(mastProps[`antena${index}Altura`]);
  const largura = Number(mastProps[`antena${index}Diametro`]) || Number(mastProps[`antena${index}Largura`]);
  const CA = Number(mastProps[`antena${index}CA`]) || 1.2;
  let aev;
  if(mastProps[`antena${index}Tipo`] !== "MW-S" && mastProps[`antena${index}Tipo`] !== "MW-V" && mastProps[`antena${index}Tipo`] !== "OMNI" && mastProps[`antena${index}Tipo`]!== "PV" && mastProps[`antena${index}Tipo`] !== "MW CHEIA" && mastProps[`antena${index}Tipo`] !== "MW VAZADA" && mastProps[`antena${index}Tipo`] !== "PAINEL VAZADO") {
    aev = ((largura*altura*CA)/Math.pow(10, 6)).toFixed(2);
  }
  else{
    aev = ((Math.PI*(largura**2)/(Math.pow(10, 6)*4))*CA).toFixed(2);
  }
  return aev;
};

export function calculateAntennaValues(projectProps, mastProps, index) {
  let area;
  const qVento = calculateQVento({ ...projectProps, alturaEdificacao: mastProps.alturaEdificacao }, Number(mastProps[`antena${index+1}AlturaMastro`]));
  const altura = Number(mastProps[`antena${index+1}Altura`]);
  const largura = Number(mastProps[`antena${index+1}Largura`]);
  const diametro = Number(mastProps[`antena${index+1}Diametro`]);
  const CA = Number(mastProps[`antena${index+1}CA`]) || 1.2;

  if(mastProps[`antena${index+1}Tipo`] === "MW CHEIA" || mastProps[`antena${index+1}Tipo`] === "MW VAZADA" || mastProps[`antena${index+1}Tipo`] === "OMNI" || mastProps[`antena${index+1}Tipo`] === "PV" || mastProps[`antena${index+1}Tipo`] === "PAINEL VAZADO" ) {
    area = (Math.PI*(diametro**2)/(Math.pow(10, 6)*4));
  } else if (largura !== 0) {
    area = (largura*altura)/Math.pow(10, 6);
  }
  else {
    area = (Math.PI*(diametro**2)/(Math.pow(10, 6)*4));
  }
  if(mastProps[`antena${index+1}Tipo`] === "MW VAZADA" || mastProps[`antena${index+1}Tipo`] === "PAINEL VAZADO" ) {
    area = area / 2;
  }
  const aev = calculateAEV(index+1, mastProps);
  mastProps[`antena${index+1}Area`] = area.toFixed(4);
  mastProps[`antena${index+1}AEV`] = aev;
  mastProps[`antena${index+1}ArrastoArea`] = (CA*area).toFixed(4);
  mastProps[`antena${index+1}CargaPorM2`] = (qVento).toFixed(4);
  mastProps[`antena${index+1}CarregFrontal`] = (CA*area*qVento/1000).toFixed(4);
  mastProps[`antena${index+1}CarregDiagonal`] = ((CA*area*qVento/1000)*Math.sin(0.785)).toFixed(4);
};

export const calculateSomas = (mastProps) => {
  const somas = [];
  if (!mastProps.qtdTotalAntenas || mastProps.qtdTotalAntenas === 0) return somas;
  for (const chave in mastProps) {
    if (chave.includes("antena") && chave.includes("Grupo")) {
      const grupo = mastProps[chave];
      const grupoExistente = somas.find(item => item.grupo === grupo);
      if (!grupoExistente) {
        somas.push({
          grupo: grupo,
          somaArea: 0,
          somaAreaECA: 0,
          somaCargaFrontal: 0,
          somaCargaDiagonal: 0,
          condicao: mastProps[`${chave.substring(0, chave.lastIndexOf("G"))}Condicao`],
          cargaPorM2: mastProps[`${chave.substring(0, chave.lastIndexOf("G"))}CargaPorM2`]
        });
      }
    }
  }

  for (let i = 1; i <= mastProps.qtdTotalAntenas; i++) {
    const grupo = mastProps[`antena${i}Grupo`];
    const area = parseFloat(mastProps[`antena${i}Area`]) || 0;
    const arrasto = parseFloat(mastProps[`antena${i}ArrastoArea`]) || 0;
    const cargaFrontal = parseFloat(mastProps[`antena${i}CarregFrontal`]) || 0;
    const cargaDiagonal = parseFloat(mastProps[`antena${i}CarregDiagonal`]) || 0;

    const grupoExistente = somas.find(item => item.grupo === grupo);
    if (grupoExistente) {
      grupoExistente.somaArea += isNaN(area) ? 0 : area;
      grupoExistente.somaAreaECA += isNaN(arrasto) ? 0 : arrasto;
      grupoExistente.somaCargaFrontal += isNaN(cargaFrontal) ? 0 : cargaFrontal;
      grupoExistente.somaCargaDiagonal += isNaN(cargaDiagonal) ? 0 : cargaDiagonal;
    }
  }

  return somas;
};
