import * as projectApi from "../../services/projectApi";
import useAsync from "../useAsync";

export default function useGetAllProjectsNamesWithDates() {
  const {
    data: projectsNamesWithDates,
    loading: getAllProjectsNamesWithDatesLoading,
    error: getAllProjectsNamesWithDatesError,
    act: getAllProjectsNamesWithDates,
  } = useAsync((token, projectType) => projectApi.getAllProjectsNamesWithDate(token, projectType), false);

  return {
    projectsNamesWithDates,
    getAllProjectsNamesWithDatesLoading,
    getAllProjectsNamesWithDatesError,
    getAllProjectsNamesWithDates
  };
}
