import useAsync from "../../useAsync";
import { getSections, postSections, updateSections } from "../../../services/tower-services/towerSectionsApi";

export const useTowerSections = (action) => {
  const execute = {
    get: getSections,
    post: postSections,
    update: updateSections,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
