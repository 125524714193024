import { useMemo } from "react";
import { useTable } from "react-table";
import styled from "styled-components";

export function ResultsTable({ AEVValues, mastProps, mastSelected, sizingResultsArray,  }) {
  if (!mastProps.qtdEstais) mastProps.qtdEstais = 0;
  
  const createComponentsArray = (mastProps) => {
    const componentsArray = [];

    for (let i = 1; i <= mastProps.qtdModulos; i++) {
      const moduleKey = `modulo${i}`;
      const diameter = mastProps[`${moduleKey}Diametro`];
      const thickness = mastProps[`${moduleKey}Espessura`];
      const moduleObject = { 
        peca: `Módulo ${i}`,
        perfil: `${diameter} x ${thickness} mm`,
        tracao: sizingResultsArray[i-1]?.tracao?.toFixed(3),
        compressao: sizingResultsArray[i-1]?.compressao?.toFixed(3),
        cortante: sizingResultsArray[i-1]?.cortante?.toFixed(3),
        momento: sizingResultsArray[i-1]?.momento?.toFixed(3),
        esbeltez: sizingResultsArray.length !== 0 ? sizingResultsArray[i - 1]?.esbeltez?.toFixed(0) : "",
        utilizacao: sizingResultsArray.length !== 0 ? `${Math.ceil(Number(sizingResultsArray[i - 1]?.completeVerification))}%` : ""
      };
      componentsArray.push(moduleObject);
    }
    for (let i = 1; i <= mastProps.qtdEstais; i++) {
      const estaiKey = `estai${i}`;
      const aba = mastProps[`${estaiKey}Aba`];
      const espessura = mastProps[`${estaiKey}Espessura`];
      const estaiObject = {
        peca: `Estai ${i}`,
        perfil: `${aba} x ${espessura} mm`,
        tracao: sizingResultsArray[i+mastProps.qtdModulos-1]?.tracao?.toFixed(3),
        compressao: sizingResultsArray[i+mastProps.qtdModulos-1]?.compressao?.toFixed(3),
        cortante: sizingResultsArray[i+mastProps.qtdModulos-1]?.cortante?.toFixed(3),
        momento: sizingResultsArray[i+mastProps.qtdModulos-1]?.momento?.toFixed(3),
        esbeltez: sizingResultsArray.length !== 0 ? sizingResultsArray[i + mastProps.qtdModulos - 1]?.esbeltez.toFixed(0) : "",
        utilizacao: sizingResultsArray.length !== 0 ? `${Math.ceil(Number(sizingResultsArray[i + mastProps.qtdModulos - 1]?.completeVerification))}%` : ""
      };
      componentsArray.push(estaiObject);
    }
    return componentsArray;
  };
  const moduleAndEstaiData = createComponentsArray(mastProps);

  let data = [
  ]; 
  data = data.concat(moduleAndEstaiData);
  const columns = useMemo(
    () => [
      {
        Header: "PEÇA",
        accessor: "peca",
      },
      {
        Header: "PERFIL",
        accessor: "perfil",
      },
      {
        Header: "TRAÇÃO (tf)",
        accessor: "tracao",
      },
      {
        Header: "COMPRESSÃO (tf)",
        accessor: "compressao",
      },
      {
        Header: "CORTANTE (tf)",
        accessor: "cortante",
      },
      {
        Header: "MOMENTO (tf*m)",
        accessor: "momento",
      },
      {
        Header: "ESBELTEZ",
        accessor: "esbeltez",
      },       {
        Header: "UTILIZAÇÃO (%)(adm/atuante)",
        accessor: "utilizacao",
      },        ],
    []
  );

  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <TableContainer>
      <TableTitle>Resultados (Valores de Cálculo)</TableTitle>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{
                backgroundColor: headerGroupIndex === 0 ? "#DADADA" : "#fff",
                color: headerGroupIndex === 0 ? "#000" : "#000",
                fontWeight: 500,
                verticalAlign: "middle",
              }}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    fontSize: "14px",
                    border: "2.75px solid #F2F0F4",
                    padding: "12px",
                    verticalAlign: "middle",
                    textAlign: "center"
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}
                style={{
                  fontSize: "14px",
                  backgroundColor: "#ffffff",
                  whiteSpace: "nowrap",
                  verticalAlign: "middle",
                  textAlign: "center"
                }}
              >
                {row.cells.map((cell) => {
                  let cellStyle = {
                    textAlign: "center",
                    padding: "14px",
                    verticalAlign: "middle",
                  };
                  if (cell.column.id === "utilizacao" && parseFloat(cell.value) > 100) {
                    cellStyle.color = "red";
                  } else if (cell.column.id === "utilizacao" && parseFloat(cell.value) <= 100) {
                    cellStyle.color = "green";
                  }
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={cellStyle}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  border-radius: 8px;
`;

const Table = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  background-color: #d0cece;
  td {
    border: 2.75px solid #F2F0F4;
    color: #565656;
  }
`;

const TableTitle = styled.div`
  width: 91.5%;
  height: 40px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 18px;
`;
