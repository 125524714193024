import useAsync from "../../useAsync";
import { getTrusses, postTrusses, updateTrusses } from "../../../services/tower-services/towerTrussesApi";

export const useTowerTrusses = (action) => {
  const execute = {
    get: getTrusses,
    post: postTrusses,
    update: updateTrusses,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
