import { useEffect, useState } from "react";
import styled from "styled-components";

export default function ProjectMemorialForm({ memorialMenuSelected, setProjectSelected, printableStructures, setPrintableStructures, projectSelectedObj, setProjectSelectedObj }) {
  function changeMastProps(mastPropsKey, mastPropsValue) {
    if(!projectSelectedObj) return;
    projectSelectedObj.props[mastPropsKey] = mastPropsValue;
    setProjectSelectedObj({ ...projectSelectedObj }); 
    setProjectSelected({ ...projectSelectedObj, props: JSON.stringify(projectSelectedObj.props) });
  }

  if (memorialMenuSelected === 1) {
    return (
      <FormGroupContainer>
        <LineFormOptionsContainer>
          <FormBox>
            <h2>Endereço</h2>
            <FormLineWrapper>
              <FormDescriptionContainer  inputWidth={"100%"}>
                <h3>Rua</h3>
                <FormInput autoFocus placeholder={"Rua"} type="text" value={projectSelectedObj?.props?.enderecoRua || ""} onChange={(e) => changeMastProps("enderecoRua", e.target.value)}></FormInput>
              </FormDescriptionContainer>
            </FormLineWrapper>
            <FormLineWrapper>
              <FormDescriptionContainer inputWidth={"74%"}>
                <h3>Bairro</h3>
                <FormInput placeholder={"Bairro"} type="text" value={projectSelectedObj?.props?.enderecoBairro || ""} onChange={(e) => changeMastProps("enderecoBairro", e.target.value)}></FormInput>
              </FormDescriptionContainer>
              <FormDescriptionContainer inputWidth={"24%"}>
                <h3>Nº</h3>
                <FormInput placeholder={"Nº"} type="number" value={projectSelectedObj?.props?.enderecoNumero || ""} onChange={(e) => changeMastProps("enderecoNumero", e.target.value)}></FormInput>
              </FormDescriptionContainer>
            </FormLineWrapper>
            <FormLineWrapper>
              <FormDescriptionContainer inputWidth={"74%"}>
                <h3>Cidade</h3>
                <FormInput placeholder={"Cidade"} type="text" value={projectSelectedObj?.props?.enderecoCidade  || ""} onChange={(e) => changeMastProps("enderecoCidade", e.target.value)}></FormInput>
              </FormDescriptionContainer>
              <FormDescriptionContainer inputWidth={"24%"}>
                <h3>UF</h3>
                <FormInput placeholder={"UF"} type="text" value={projectSelectedObj?.props?.enderecoUF  || ""} onChange={(e) => changeMastProps("enderecoUF", e.target.value)}></FormInput>
              </FormDescriptionContainer>
            </FormLineWrapper>
          </FormBox>
          <FormBox>
            <h2>Dados Edificação</h2>
            <FormLineWrapper>
              <FormDescriptionContainer inputWidth={"49%"}>
                <h3>Altura (m)</h3>
                <FormInput placeholder={"Altura [m]"} type="number" value={projectSelectedObj?.props?.edificacaoAltura  || ""} onChange={(e) => changeMastProps("edificacaoAltura", e.target.value)}></FormInput>
              </FormDescriptionContainer>
              <FormDescriptionContainer inputWidth={"49%"}>
                <h3>Quantidade de Andares</h3>
                <FormInput placeholder={"Qtd. Andares"} type="number" value={projectSelectedObj?.props?.edificacaoAndares  || ""} onChange={(e) => changeMastProps("edificacaoAndares", e.target.value)}></FormInput>
              </FormDescriptionContainer>
            </FormLineWrapper>
            <FormLineWrapper>
              <FormDescriptionContainer inputWidth={"100%"}>
                <h3>Uso da Edificação</h3>
                <FormSelect uso={projectSelectedObj?.props?.edificacaoUso} placeholder={"Residencial / Comercial"} value={projectSelectedObj?.props?.edificacaoUso  || ""} onChange={(e) => changeMastProps("edificacaoUso", e.target.value)}>
                  <option value={""}>Selecione o tipo de uso</option>
                  <option value={"Comercial"}>Comercial</option>
                  <option value={"Residencial"}>Residencial</option>
                </FormSelect>
              </FormDescriptionContainer>
            </FormLineWrapper>
          </FormBox>
        </LineFormOptionsContainer>
        <HorizontalLine />
        <LineFormOptionsContainer>
          <FormBox>
            <h2>Dados da Empresa</h2>
            <FormLineWrapper>
              <FormDescriptionContainer inputWidth={"100%"}>
                <h3>Informe o nome da empresa</h3>
                <FormInput placeholder={"Informe o nome da empresa"} type="text" value={projectSelectedObj?.props?.empresaNome  || ""} onChange={(e) => changeMastProps("empresaNome", e.target.value)}></FormInput>
              </FormDescriptionContainer>
            </FormLineWrapper>
            <FormLineWrapper>
              <FormDescriptionContainer inputWidth={"100%"}>
                <h3>Código do site</h3>
                <FormInput placeholder={"Código do Site"} type="text" value={projectSelectedObj?.props?.empresaCodigoSite || ""} onChange={(e) => changeMastProps("empresaCodigoSite", e.target.value)}></FormInput>
              </FormDescriptionContainer>
            </FormLineWrapper>
            <FormLineWrapper>
              <FormDescriptionContainer inputWidth={"100%"}>
                <h3>Responsável Técnico</h3>
                <FormInput placeholder={"Responsável Técnico"} type="text" value={projectSelectedObj?.props?.empresaResponsavel || ""} onChange={(e) => changeMastProps("empresaResponsavel", e.target.value)}></FormInput>
              </FormDescriptionContainer>
            </FormLineWrapper>
          </FormBox>
          <FormBox>
            <h2>Dados do Cliente</h2>
            <FormLineWrapper>
              <FormDescriptionContainer inputWidth={"100%"}>
                <h3>Informe o nome do cliente</h3>
                <FormInput placeholder={"Informe o nome do cliente"} type="text" value={projectSelectedObj?.props?.clienteNome || ""} onChange={(e) => changeMastProps("clienteNome", e.target.value)}></FormInput>
              </FormDescriptionContainer>
            </FormLineWrapper>
            <FormLineWrapper>
              <FormDescriptionContainer inputWidth={"100%"}>
                <h3>ID do site</h3>
                <FormInput placeholder={"ID do Site"} type="text" value={projectSelectedObj?.props?.clienteIdSite || ""} onChange={(e) => changeMastProps("clienteIdSite", e.target.value)}></FormInput>
              </FormDescriptionContainer>
            </FormLineWrapper>
            <FormLineWrapper>
              <FormDescriptionContainer inputWidth={"100%"}>
                <h3>Operadora</h3>
                <FormInput placeholder={"Operadora"} type="text" value={projectSelectedObj?.props?.clienteOperadora || ""} onChange={(e) => changeMastProps("clienteOperadora", e.target.value)}></FormInput>
              </FormDescriptionContainer>
            </FormLineWrapper>
          </FormBox> 
        </LineFormOptionsContainer>
      </FormGroupContainer>
    );
  }
  else if (memorialMenuSelected === 2) {
    return (
      <SelectionGroupsContainer>
        <SiteStructuresContainer>
          <h2>Mastros que serão impressos</h2>
          <SiteStructuresOptionsBox>
            {projectSelectedObj?.Mastro?.map((mast) => (
              <>
                <SelectableOption key={mast?.id} title={mast?.identificador} printableStructures={printableStructures} setPrintableStructures={setPrintableStructures}/>
                <HorizontalOptionLine />
              </>
            ))}
          </SiteStructuresOptionsBox>
        </SiteStructuresContainer>
      </SelectionGroupsContainer>
    );
  }
};

function SelectableOption({ title, printableStructures, setPrintableStructures }) {
  const [isSelected, setIsSelected] = useState(true);
  useEffect(() => {
    const updatedPrintableStructures = { ...printableStructures };
    if(!updatedPrintableStructures.identificador.includes(title) && isSelected) updatedPrintableStructures.identificador.push(title);
    const index = updatedPrintableStructures.identificador.indexOf(title);
    if(!isSelected && index !== -1) updatedPrintableStructures.identificador.splice(index, 1);
    setPrintableStructures(updatedPrintableStructures);
  }, [isSelected]);

  return (
    <SelectableOptionContainer isSelected={isSelected}>
      <div onClick={() => setIsSelected(!isSelected)}>
        <ion-icon name="checkmark-sharp"></ion-icon>
      </div>
      <h1>{title}</h1>
    </SelectableOptionContainer>
  );
};

const FormGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  height: 80%;
  margin-top: -10%;

  input, select {
    background-color: #D0CECE;
    height: 4vh;
    border: 1px solid #C8C8C8;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 15px;
    justify-content: flex-start;
    padding: 10px;
    background-color: #fff;
  }
  select {
    padding: 0 0 0 8px !important;
    font-family: 'Roboto', sans-serif;
  }
`;

const LineFormOptionsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50%;
  justify-content: space-between;
  align-items: flex-start;
`;

const HorizontalLine = styled.div`
  width: 95%;
  margin: -20px 0 40px 10px;
  height: 0.5px;
  background-color: #C5C5C5;
  opacity: 0.7;
`;

const FormBox = styled.div`
    display: flex;
    flex-direction: column;
    height: 31.5%;
    width: 50%;

    h2 {
      font-size: 22px;
      font-weight: 600;
      margin-left: 10px;
    }
`;

const FormLineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
`;

const FormDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.inputWidth} !important;
  h3 {
    padding-left: 10px;
    margin-top: 14px;
  }
`;

const FormInput = styled.input`
  margin: 10px;
  width: 100%;
`;

const FormSelect= styled.select`
  margin: 10px;
  width: 100%;
  color: ${(props) => (props.uso? "#000" : "gray")};
`;

const SelectionGroupsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  width: 100%;
  justify-content: space-around;
  max-height: 100%;
`;

const SiteStructuresContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 410px;
  h2 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }
`;

const SiteStructuresOptionsBox = styled.div`
  background-color: #F2F2F2;
  display: flex;
  flex-direction: column;
  padding: 16px;
  height: 480px;
  overflow-y: scroll;
  width: 100%;

  ::-webkit-scrollbar {
    width: 5px; 
    height: 5px;
    margin-left: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #FE8F03; 
    border-radius: 5px; 
    margin-left: 5px;
    margin-top: 20px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  scrollbar {
    width: 5px; 
    height: 5px;
    margin-left: 5px;
  }
  scrollbar-thumb {
    background-color: #FE8F03;
    border-radius: 5px; 
    margin-left: 5px;
  }
  scrollbar-thumb:hover {
    background-color: #555; 
  }
`;

const HorizontalOptionLine = styled.div`
  height: 4px;
  width: 100%;
`;

const SelectableOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 16px;
  h1 {
    font-size: 21px;
  }
  div {
    width: 22px;
    height: 22px;
    background-color: ${(props) => (props.isSelected ? "#F16E00" : "#fff") };
    border: 1px solid #000;
    margin: 0 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ion-icon {
    font-size: 24px;
    color: #fff;
  }
`;
