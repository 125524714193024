import styled from "styled-components";
import { ButtonPositioner, ReturnButton, LeftTitle, BottomBorderTitle } from "../../project-components/wind-components/project-windData";
import ResizableLeftContainer from "../../common/resizableLeftContainer";
import ResizableRightContainer from "../../common/resizableRightContainer";
import { useState, useContext, useRef } from "react";
import { MenuOption } from "../module-components/mast-moduleData";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { calculateSomas } from "../antenna-components/mast-antennaCalculation";
import MastGeometryImg from "../geometry-components/mast-geometryImg";
import { DeflectionTable } from "./mast-deflectionsTable";
import { ReactionTable } from "./mast-reactionsTable";
import { ResultsTable } from "./mast-resultsTable";
import { ReturnIcon, AdvanceIcon } from "../../project-components/wind-components/project-windData";
import { DrawPage } from "./mast-strapImageByGeometry";
import images from "../../../assets/images/numberResponses/responsesImages";

export default function StrapResultsPage() {
  const leftMenuRef = useRef(null);
  const { setLeftMenuOptionSelected, mastSelected, projectSelected, setProjectSelected, setProjectNameSelected, setMastSelected, reloadProject, setReloadProject } = useContext(ProjectContext);
  const mastProps = JSON.parse(mastSelected.props);
  const [drawOrResults, setDrawOrResults] = useState("results");
  const [cameraReactionsRightMax, setCameraReactionsRightMax] = useState();
  const [cameraReactionsRightMin, setCameraReactionsRightMin] = useState();
  const [cameraCapacityRight, setCameraCapacityRight] = useState();
  const [cameraEsbeltezRight, setCameraEsbeltezRight] = useState();
  const [isRendered, setIsRendered] = useState(0);
  const [maxOrMin, setMaxOrMin] = useState("max");
  const somasGruposAntenas = calculateSomas(mastProps);
  const [exibitionOption, setExibitionOption] = useState("geometry");
  const geometryArray = mastProps ? [mastProps][0]?.strapMessage[4] : undefined;
  const reactionsArray = mastProps ? [mastProps][0]?.strapMessage[3].reactions : undefined;
  const sizingResultsArray = mastProps ? [mastProps][0]?.strapMessage[5] : undefined;
  const mastImageProps = {
    mastType: mastProps.tipoMastro,
    mastGeometry: geometryArray,
    mastReactions: reactionsArray,
    mastHeight: mastProps.alturaMastro,
    cameraReactionsRightMax,
    setCameraReactionsRightMax,
    cameraReactionsRightMin,
    setCameraReactionsRightMin,
    cameraCapacityRight,
    setCameraCapacityRight,
    cameraEsbeltezRight,
    setCameraEsbeltezRight,
    isRendered,
    setIsRendered,
    exibitionOption,
    leftMenuRef,
    sizingResultsArray
  };
  let AEVTotal = 0;
  for (let i = 0; i < somasGruposAntenas.length; i++) {
    if (somasGruposAntenas[i].condicao === "Existente") AEVTotal += somasGruposAntenas[i]?.somaAreaECA;
    if (somasGruposAntenas[i].condicao === "Retirar") AEVTotal -= somasGruposAntenas[i]?.somaAreaECA;
    if (somasGruposAntenas[i].condicao === "Instalar") AEVTotal += somasGruposAntenas[i]?.somaAreaECA;
    if (somasGruposAntenas[i].condicao === "Reserva") AEVTotal += somasGruposAntenas[i]?.somaAreaECA;
  }

  const operadorasArray = [];
  let operadorasString = "";
  for (const key in mastProps) {
    if (key.startsWith("antena") && key.endsWith("Operadora")) {
      const operadora = mastProps[key];
      if (!operadorasArray.includes(operadora)) {
        operadorasArray.push(operadora);
        if (operadorasString.length !== 0) operadorasString += ` / ${operadora}`;
        else operadorasString += `${operadora}`;
      }
    }
  }

  function handleMenuClick(menuType, menuName) {
    if (menuType === "project") {
      setProjectSelected({ });
      setProjectNameSelected("");
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else if (menuType === "projectName") {
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else {
      setLeftMenuOptionSelected("");
    }
  }

  return(
    <>
      <ResizableLeftContainer>
        <LeftTitle>Metálica</LeftTitle>
        <ReturnIcon onClick={() => setLeftMenuOptionSelected("")}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ReturnIcon>
        <AdvanceIcon onClick={() => setLeftMenuOptionSelected("ancoragem")}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </AdvanceIcon>
        <VerticalSpacing />
        <MenuOption>
          <h2>Resumo do Mastro</h2>
        </MenuOption>
        <MastSummaryContainer>
          <h3><span>Projeto: </span>{projectSelected.nomeProjeto}</h3>
          <h3><span>Mastro: </span>{mastSelected?.identificador} | <span>H=</span>{mastProps.alturaMastro}m | {operadorasString}</h3>
          <h3><span>Nível: </span>{mastProps.alturaEdificacao}m | <span>AEV: </span>{AEVTotal.toFixed(2) || "0"}m²</h3>
        </MastSummaryContainer>
        <MenuOption>
          <h2>Tipo de Exibição</h2>
        </MenuOption>
        <ExibitionContainer ref={leftMenuRef} exibitionOption={exibitionOption} drawOrResults={drawOrResults}>
          <select onChange={(event) => {setExibitionOption(event.target.value); setDrawOrResults(event.target.value);}}>
            <option value="geometry">Geometria</option>
            <option value="reactions">Reações de Apoio</option>
            <option value="esbeltez">Esbeltez</option>
            <option value="capacity">Capacidade</option>
          </select>
          {(geometryArray && reactionsArray && sizingResultsArray.length) ? <ImageMast display={exibitionOption === "reactions" ? "flex" : "none"}><DrawPage mastImageProps={mastImageProps} position={"reactions-left"}/></ImageMast> : ""}
          {(geometryArray && reactionsArray && sizingResultsArray.length) ? <ImageMast display={exibitionOption === "capacity" ? "flex" : "none"}><DrawPage mastImageProps={mastImageProps} position={"capacity-left"}/></ImageMast> : ""}
          {(geometryArray && reactionsArray && sizingResultsArray.length) ? <ImageMast display={exibitionOption === "esbeltez" ? "flex" : "none"}><DrawPage mastImageProps={mastImageProps} position={"esbeltez-left"}/></ImageMast> : ""}
          <ImageMast display={exibitionOption === "geometry" ? "flex" : "none"}>
            <MastGeometryImg type={mastProps.tipoMastro} modulesNum={mastProps.qtdModulos} />
          </ImageMast>
          {drawOrResults === "results" ?
            <>
              <HideOrShow display={exibitionOption === "reactions" ? "flex" : "none"}>
                <SavingButtonContainer onClick={() => {setDrawOrResults("reactions");}}>{"VISUALIZAR REAÇÕES DE APOIO"}
                </SavingButtonContainer>
              </HideOrShow>
              <HideOrShow display={exibitionOption === "capacity" ? "flex" : "none"}>
                <SavingButtonContainer onClick={() => {setDrawOrResults("capacity");}}>{"VISUALIZAR CAPACIDADE"}
                </SavingButtonContainer>
              </HideOrShow>
              <HideOrShow display={exibitionOption === "esbeltez" ? "flex" : "none"}>
                <SavingButtonContainer onClick={() => {setDrawOrResults("esbeltez");}}>{"VISUALIZAR ESBELTEZ"}
                </SavingButtonContainer>
              </HideOrShow>
              <HideOrShow display={exibitionOption === "geometry" ? "flex" : "none"}>
                <SavingButtonContainer onClick={() => {setDrawOrResults("geometry");}}>{"VISUALIZAR GEOMETRIA"}
                </SavingButtonContainer>
              </HideOrShow>
            </>
            : 
            <>
              <MaxMinButtons display={exibitionOption === "reactions" ? "flex" : "none" }>
                <MaxMinReactionButtonsContainer>
                  <SavingButtonContainer onClick={() => {setMaxOrMin("max");}}>{"Máximos"}</SavingButtonContainer>
                  <SavingButtonContainer onClick={() => {setMaxOrMin("min");}}>{"Mínimos"}</SavingButtonContainer>
                </MaxMinReactionButtonsContainer>
                <SavingButtonContainer onClick={() => {setDrawOrResults("results");}}>{"VER TABELAS E RESULTADOS"}</SavingButtonContainer>
              </MaxMinButtons>
              <MaxMinButtons display={exibitionOption === "capacity" ? "flex" : "none" }>
                <SavingButtonContainer onClick={() => {setDrawOrResults("results");}}>{"VER TABELAS E RESULTADOS"}</SavingButtonContainer>
              </MaxMinButtons>
              <MaxMinButtons display={exibitionOption === "esbeltez" ? "flex" : "none" }>
                <SavingButtonContainer onClick={() => {setDrawOrResults("results");}}>{"VER TABELAS E RESULTADOS"}</SavingButtonContainer>
              </MaxMinButtons>
              <MaxMinButtons display={exibitionOption === "geometry" ? "flex" : "none" }>
                <SavingButtonContainer onClick={() => {setDrawOrResults("results");}}>{"VER TABELAS E RESULTADOS"}</SavingButtonContainer>
              </MaxMinButtons>
            </>}
        </ExibitionContainer>
        <ButtonPositioner >
          <ReturnButton onClick={() => {
            if (1 === 2) {
              if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("");
            }
            else setLeftMenuOptionSelected("");
          }} >{" Voltar "}</ReturnButton>
        </ButtonPositioner>
      </ResizableLeftContainer>
      <ResizableRightContainer>
        <ProjectNameWrapper position={drawOrResults === "results" ? "fixed" : "fixed"}>
          <h2><span onClick={() => handleMenuClick("project")}>Projetos</span> / <span onClick={() => handleMenuClick("projectName")}>{projectSelected?.nomeProjeto}</span> <span onClick={() => handleMenuClick("")}>{mastSelected?.identificador ? ` / ${mastSelected?.identificador}` : ""}</span></h2>
          <h1><span>Metálica</span></h1>
          <div />
        </ProjectNameWrapper>
        <StrapResultsTablesContainer display={drawOrResults === "results" ? "flex" : "none" }>
          <div>
            <DeflectionTable mastProps={mastProps} mastSelected={mastSelected}></DeflectionTable>
            <ReactionTable mastProps={mastProps} mastSelected={mastSelected}></ReactionTable>
          </div>
          <ResultsTable mastProps={mastProps} mastSelected={mastSelected} sizingResultsArray={sizingResultsArray}></ResultsTable>
        </StrapResultsTablesContainer> 
        {(geometryArray && reactionsArray && sizingResultsArray.length) ? 
          <>
            <ImageMastRight display={drawOrResults === "geometry" ? "flex" : "none"}>
              <MastGeometryImg type={mastProps.tipoMastro} modulesNum={mastProps.qtdModulos} />
            </ImageMastRight>
            <MastGeometryDivMax display={drawOrResults+maxOrMin === "reactionsmax" ? "flex" : "none"}>
              <DrawPage mastImageProps={mastImageProps} position={"reactions-right-max"} maxOrMin={"max"}/>
              { cameraReactionsRightMax ? <MastGeometryDivAxes><DrawPage mastImageProps={mastImageProps} position={"reactions-axes-max"} copyCameraRightMax={true}/></MastGeometryDivAxes> : ""}
            </MastGeometryDivMax>
            <MastGeometryDivMax display={drawOrResults+maxOrMin === "reactionsmin" ? "flex" : "none"}>
              <DrawPage mastImageProps={mastImageProps} position={"reactions-right-min"} maxOrMin={"min"}/>
              { cameraReactionsRightMin ? <MastGeometryDivAxes><DrawPage mastImageProps={mastImageProps} position={"reactions-axes-min"} copyCameraRightMax={false}/></MastGeometryDivAxes> : ""}
            </MastGeometryDivMax>
            <MastGeometryDivMax display={drawOrResults === "capacity" ? "flex" : "none"}>
              <DrawPage mastImageProps={mastImageProps} position={"capacity-right"}/>
              { cameraCapacityRight ? <MastGeometryDivAxes><DrawPage mastImageProps={mastImageProps} position={"capacity-axes"} copyCameraRightMax={false}/></MastGeometryDivAxes> : ""}
            </MastGeometryDivMax>
            <MastGeometryDivMax display={drawOrResults === "esbeltez" ? "flex" : "none"}>
              <DrawPage mastImageProps={mastImageProps} position={"esbeltez-right"}/>
              { cameraCapacityRight ? <MastGeometryDivAxes><DrawPage mastImageProps={mastImageProps} position={"esbeltez-axes"} copyCameraRightMax={false}/></MastGeometryDivAxes> : ""}
            </MastGeometryDivMax>
          </> : ""}
      </ResizableRightContainer>
    </>
  );
}

const MastGeometryDivMax = styled.div`
  align-items: end;
  justify-content: flex-start;
  display: ${props => props.display};
`;

const MastGeometryDivAxes = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const MastSummaryContainer = styled.div`
  display: flex;
  width: 100%;
  height: 13vh;
  margin-bottom: 20px;
  background-color: #E8E8E8;
  flex-direction: column;
  align-items: left;
  justify-content: space-evenly;
  
  h3 {
    font-size: 16px;
    color: #000;
    margin: 2px 0 2px 20px;
    width: 92%;
  }
  span {
    font-weight: 500;
  }
`;

const ExibitionContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: ${(props) => ((props.exibitionOption === "reactions" && props.drawOrResults !== "results") ? "44vh" : "39vh")};
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  input,select {
    width: 100%;
    background-color: #ffffff;
    height: 4.5vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
    border: 1.9px #C8C8C8 solid;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }

  img {
    width: 100% !important;
    height: 72%;
    background-color: #ffffff;
  }
`;

const StrapResultsTablesContainer = styled.div`
  display: ${props => props.display};
  flex-direction: column;
  width: 80%;
  max-height: 90vh;
  margin-top: 10px;
  margin-bottom: -80px;

  > div {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ImageMast = styled.div`
  width: 100%;
  height: 28vh;
  border: 1.9px #C8C8C8 solid;
  border-radius: 8px;
  background-color: #ffffff !important;
  display: ${props => props.display};
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    min-width: 100%;
    height: 100%;
  }
`;

const ImageMastRight = styled.div`
  width: 99%;
  height: 99%;
  background-color: white;
  display: ${props => props.display};
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const MaxMinButtons = styled.div`
  display: ${props => props.display};
  width: 100%;
  flex-direction: column;
`;

const HideOrShow = styled.div`
  width: 100%;
  display: ${props => props.display};
`;

const VerticalSpacing = styled.div`
  margin-top: 12px;
`;

const SavingButtonContainer = styled.div`
  width: 100%;
  height: 3.5vh;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.disabled ? "#c9c9c9" : "#FE8F03")};
  color: ${(props) => (props.disabled ? "#626262" : "#ffffff")};
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
`;

const MaxMinReactionButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  div {
    width: 49%;
  }
`;

const ProjectNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 93%;
  margin-top: -12vh;
  position: ${(props) => props.position};
  z-index: 10;
  top: 210px;
  left: 27.5vw;
  @media (min-height: 850px) {
    top: 230px !important;
  }

  h2 {
    color: #666666;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  h1 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
  }
    span {
    transition: 0.0001s all;
    cursor: pointer;
    :hover {
      font-weight: 600 !important;
    }
  }
  >div {
    width: 100%;
    height: 1.25px;
    margin: -18px 0 15px 0;
    background-color: #D9D9D9;
    /* display: ${(props) => (props.position === "inherit" ? "none" : "inherit" )}; */
  }
`;
