import styled from "styled-components";
import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";
import { CustomSelect } from "../../common/custom-select";
import AutocompleteInput from "../../common/customAutoCompleteInput";
import CustomCheckbox from "../../common/custom-checkbox";
import { handleProfileSteelTypes, handleProfileDimensions } from "../../../utils/tower_handleProfilesData";
import { diagonalLockingsInfo } from "../../../constants/tower-trusses-data";
import { profileTypes, tubeProfileData, boltsDiameter, boltsSteel } from "../../../constants/tower-constants";
import colors from "../../../constants/color-constants";

export default function TowerDiagonalLockingLeftMenu({
  nSegments,
  nBars,
  inputValues,
  segmentSelectedInMenu,
  setSegmentSelectedInMenu,
  diagonalSelectedInMenu,
  setDiagonalSelectedInMenu,
  barSelectedInMenu,
  setBarSelectedInMenu,
  handleInputChange,
  isFieldEnabled,
  trussesTypes,
}) {
  const currentSegmentIndex = segmentSelectedInMenu === ""
    ? segmentSelectedInMenu : Number(segmentSelectedInMenu - 1);
  const currentDiagonal = currentSegmentIndex !== "" && diagonalSelectedInMenu !== ""
    && inputValues[segmentSelectedInMenu - 1]
    ? inputValues[segmentSelectedInMenu - 1][diagonalSelectedInMenu] : "";

  return (
    <>
      <InputMenu>
        <div>
          <div>
            <h2>Trecho Atual</h2>
            <select
              className="highlightColor"
              value={segmentSelectedInMenu}
              onChange={(e) => setSegmentSelectedInMenu(e.target.value)}>
              <option value="" key={"segment-empty"}>Selecione um trecho</option>
              {[...Array(nSegments).keys()].map((i) => (
                <option value={i + 1} key={`segment-${i + 1}`}>
                  Trecho {i + 1}
                </option>
              ))}
            </select>
          </div>

          <div>
            <h2>Diagonal</h2>
            <select
              className={segmentSelectedInMenu === "" || trussesTypes[segmentSelectedInMenu - 1] === "Treliça H"
                ? null : "highlightColor"}
              disabled={segmentSelectedInMenu === "" || trussesTypes[segmentSelectedInMenu - 1] === "Treliça H"}
              value={segmentSelectedInMenu === "" ? "" : diagonalSelectedInMenu}
              onChange={(e) => setDiagonalSelectedInMenu(e.target.value)}>
              <option value="" key={"diagonal-empty"}>Selecione</option>
              {trussesTypes[segmentSelectedInMenu - 1] !== "Treliça H" &&
                  <option value="superiorDiagonalData" key={"superiorDiagonalData"}>Superior</option>}
              {trussesTypes[segmentSelectedInMenu - 1] === "Treliça X" &&
                <option value="inferiorDiagonalData" key={"inferiorDiagonalData"}>Inferior</option>}
            </select>
          </div>
        </div>
      </InputMenu>

      {
        trussesTypes[segmentSelectedInMenu - 1] === "Treliça H"
          ? <MessageTrussesH>
            <p>{"Não há diagonais nesse trecho."}</p>
          </MessageTrussesH>
          : <>
            <InputMenu>
              <h2>Existe Travamento na Diagonal?</h2>
              <select
                disabled={segmentSelectedInMenu === "" || diagonalSelectedInMenu === ""}
                value={segmentSelectedInMenu === "" || diagonalSelectedInMenu === ""
                  ? "" : currentDiagonal?.hasDiagonalLocking ?? ""}
                onChange={(e) => handleInputChange("hasDiagonalLocking", e.target.value)}
              >
                <option value="" key={"hasDiagonalLocking-empty"}>Selecione</option>
                <option value="Sim" key={"yes"}>Sim</option>
                <option value="Não" key={"no"}>Não</option>
              </select>
            </InputMenu>

            {
              isFieldEnabled() &&
              <>
                <InputMenu>
                  <div>
                    <div>
                      <h2>Pontos Travamento</h2>
                      <select
                        value={currentDiagonal?.lockingPoints ?? ""}
                        onChange={(e) => handleInputChange("lockingPoints", e.target.value)}
                      >
                        <option value="" key={"lockingPoints-empty"}>Selecione o número de pontos de travamento</option>
                        {Object.keys(diagonalLockingsInfo).map((type) => (
                          <option value={type} key={type}>{`${type} ${type <= 1 ? " Parte" : " Partes"}`}</option>
                        ))}
                      </select>
                    </div>

                    <div>
                      <h2>Escolha o tipo</h2>
                      <select
                        value={currentDiagonal?.lockingPoints === "" ? "" :
                          currentDiagonal?.lockingType ?? ""}
                        onChange={(e) => handleInputChange("lockingType", e.target.value)}
                      >
                        <option value="" key={"lockingType-empty"}>Escolha o tipo de travamento</option>
                        {currentDiagonal?.lockingPoints !== "" &&
                          diagonalLockingsInfo[currentDiagonal?.lockingPoints]?.map((type) => (
                            <option value={type.lockingType} key={type.lockingType}>{type.lockingType}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </InputMenu>

                <InputMenu>
                  <div>
                    <div>
                      <h2>Barra</h2>
                      <select
                        value={isFieldEnabled() ? barSelectedInMenu : ""}
                        onChange={(e) => setBarSelectedInMenu(e.target.value)}
                      >
                        <option value="" key={"bar-empty"}>Selecione a barra de travamento</option>
                        {[...Array(nBars[diagonalSelectedInMenu][segmentSelectedInMenu - 1]).keys()]
                          .map(index => (
                            <option value={index + 1} key={`bar-${index + 1}`}>Barra {index + 1}</option>
                          ))}
                      </select>
                    </div>
                    <CheckboxContainer>
                      <CustomCheckbox
                        id={`bar${barSelectedInMenu - 1}-segment${segmentSelectedInMenu - 1}`}
                        disabled={barSelectedInMenu === ""}
                        label={"Barra Faltante"}
                        checked={barSelectedInMenu === ""
                          ? false : (currentDiagonal?.bars[barSelectedInMenu - 1]?.isMissingBar || false)}
                        onChange={() => handleInputChange("isMissingBar")}
                      />
                    </CheckboxContainer>
                  </div>
                </InputMenu>

                {
                  (isFieldEnabled()
                    && barSelectedInMenu !== ""
                    && currentDiagonal?.bars[barSelectedInMenu - 1]?.isMissingBar !== true) &&
                  <>
                    <InputMenu>
                      <h2>Tipo de Perfil</h2>
                      <select
                        value={currentDiagonal?.bars[barSelectedInMenu - 1]?.profileType ?? ""}
                        onChange={(e) => handleInputChange("profileType", e.target.value)}
                      >
                        <option value="" key={"profileType-empty"}>Selecione o tipo de perfil</option>
                        {profileTypes.map((type) => (
                          <option value={type} key={type}>{type}</option>
                        ))}
                      </select>
                    </InputMenu>

                    <InputMenu>
                      <h2>Aço do Perfil</h2>
                      <select
                        value={currentDiagonal?.bars[barSelectedInMenu - 1]?.profileSteel ?? ""}
                        onChange={(e) => handleInputChange("profileSteel", e.target.value)}
                      >
                        <option value="" key={"profileSteel-empty"}>Selecione o aço do perfil</option>
                        {currentDiagonal?.bars[barSelectedInMenu - 1]?.profileType
                          && handleProfileSteelTypes(currentDiagonal?.bars[barSelectedInMenu - 1]?.profileType)
                            ?.map( (type) => (
                              <option value={type} key={type}>{type}</option>
                            ))}
                      </select>
                    </InputMenu>

                    <InputMenu>
                      <h2>Dimensões do Perfil</h2>
                      {
                        currentDiagonal?.bars[barSelectedInMenu - 1]?.profileType === "L - Cantoneira" &&
                        <AutocompleteInput
                          inputValue={currentDiagonal?.bars[barSelectedInMenu - 1]?.profileDimensions || ""}
                          handleInputChange={(field, value) => handleInputChange(field, value)}
                          list={handleProfileDimensions(currentDiagonal?.bars[barSelectedInMenu - 1]?.profileType) || []}
                          field={"profileDimensions"}
                          placeholder={"Selecione as dimensões do perfil"}
                        />

                      }
                      {
                        currentDiagonal?.bars[barSelectedInMenu - 1]?.profileType === "T - Tubo Vazado" &&
                        <div>
                          <AutocompleteInput
                            inputValue={currentDiagonal?.bars[barSelectedInMenu - 1]?.profileDiameter || ""}
                            handleInputChange={(field, value) => handleInputChange(field, value)}
                            list={currentDiagonal?.bars[barSelectedInMenu - 1]?.profileSteel
                              ? handleProfileDimensions(currentDiagonal?.bars[barSelectedInMenu - 1].profileType,
                                currentDiagonal?.bars[barSelectedInMenu - 1]?.profileSteel) : []}
                            field={"profileDiameter"}
                            placeholder={"Diâmetro"}
                            label={"Diâmetro"}
                          />

                          <AutocompleteInput
                            inputValue={currentDiagonal?.bars[barSelectedInMenu - 1]?.profileThickness || ""}
                            handleInputChange={(field, value) => handleInputChange(field, value)}
                            list={currentDiagonal?.bars[barSelectedInMenu - 1]?.profileDiameter
                              ? tubeProfileData[currentDiagonal?.bars[barSelectedInMenu - 1]?.profileSteel]
                                [currentDiagonal?.bars[barSelectedInMenu - 1]?.profileDiameter] : []}
                            field={"profileThickness"}
                            placeholder={"Espessura"}
                            label={"Espessura"}
                          />
                        </div>
                      }
                      {
                        currentDiagonal?.bars[barSelectedInMenu - 1]?.profileType === "BR - Barra Maciça" &&
                        <AutocompleteInput
                          inputValue={currentDiagonal?.bars[barSelectedInMenu - 1]?.profileDiameter || ""}
                          handleInputChange={(field, value) => handleInputChange(field, value)}
                          list={handleProfileDimensions(currentDiagonal?.bars[barSelectedInMenu - 1].profileType)}
                          field={"profileDiameter"}
                          placeholder={"Selecione o diâmetro do perfil"}
                        />
                      }
                      {
                        currentDiagonal?.bars[barSelectedInMenu - 1]?.profileType === "U - Perfil U" &&
                        <>
                          <div>
                            <CustomInput
                              placeholder={"Aba (mm)"}
                              label={"Aba"}
                              value={currentDiagonal?.bars[barSelectedInMenu - 1]?.profileFlange ?? ""}
                              onChange={(newValue) => handleInputChange("profileFlange", newValue)}
                              rawNumber={true}
                              unit={"mm"}
                            />

                            <CustomInput
                              placeholder={"Alma (mm)"}
                              label={"Alma"}
                              value={currentDiagonal?.bars[barSelectedInMenu - 1]?.profileWeb ?? ""}
                              onChange={(newValue) => handleInputChange("profileWeb", newValue)}
                              rawNumber={true}
                              unit={"mm"}
                            />
                          </div>
                          <div>
                            <AutocompleteInput
                              inputValue={currentDiagonal?.bars[barSelectedInMenu - 1]?.profileThickness || ""}
                              handleInputChange={(field, value) => handleInputChange(field, value)}
                              list={handleProfileDimensions(currentDiagonal?.bars[barSelectedInMenu - 1].profileType) || []}
                              field={"profileThickness"}
                              placeholder={"Selecione a espessura do perfil"}
                              label={"Espessura"}
                            />
                          </div>
                        </>
                      }
                    </InputMenu>

                    <InputMenu>
                      <h2>Aço dos Parafusos</h2>
                      <select
                        value={currentDiagonal?.bars[barSelectedInMenu - 1]?.boltsSteel ?? ""}
                        onChange={(e) => handleInputChange("boltsSteel", e.target.value)}
                      >
                        <option value="" key={"boltsSteel-empty"}>Selecione o aço dos parafusos</option>
                        {boltsSteel.map((type) => (
                          <option value={type} key={type}>{type}</option>
                        ))}
                      </select>
                    </InputMenu>

                    <InputMenu>
                      <h2>Parafusos de Conexão</h2>
                      <div>
                        <CustomInput
                          placeholder={"Quantidade"}
                          label={"Quantidade"}
                          value={currentDiagonal?.bars[barSelectedInMenu - 1]?.numConnectionBolts ?? ""}
                          onChange={(newValue) => handleInputChange("numConnectionBolts", newValue)}
                          toFixed={0}
                        />

                        <CustomSelect
                          value={currentDiagonal?.bars[barSelectedInMenu - 1]?.diameterConnectionBolts ?? ""}
                          label={"Diâmetro"}
                          onChange={(e) => handleInputChange("diameterConnectionBolts", e)}
                        >
                          <option value="" key={"diameterConnectionBolts-empty"}>Diâmetro</option>
                          {boltsDiameter.map((type) => (
                            <option value={type} key={type}>{type}</option>
                          ))}
                        </CustomSelect>
                      </div>
                    </InputMenu>
                  </>
                }
              </>
            }
          </>
      }
    </>
  );
};

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 8px 0;
`;

const MessageTrussesH = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 15px;

    p {
        color: ${colors.kcTextColor};
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        font-family: 'Roboto', sans-serif;
        text-align: center;
    }
`;
