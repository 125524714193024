import { useState } from "react";
import styled from "styled-components";
import left_menu_icon from "../../../assets/images/left-menu-icon-light-gray.svg";

export default function ClassificatingProjectBox({ setProjectClassificationType }) {
  const [isOn, setIsOn] = useState(false);
  function handleClassificationClick(projectClassificationType) {
    setProjectClassificationType(projectClassificationType);
    setIsOn(false);
  }
  return (
    <ProjectClassificationContainer onMouseEnter={() => setIsOn(true)} onMouseLeave={() => setIsOn(false)}>
      <img src={left_menu_icon} alt="left menu opening icon"/>
      <p>Classificar</p>
      <ProjectClassificationOptionsContainer isOn={isOn}>
        <ProjectClassificationOption onClick={() => handleClassificationClick("dateDesc")}>Mais Recentes</ProjectClassificationOption>
        <ProjectClassificationOption onClick={() => handleClassificationClick("dateAsc")}>Mais Antigos</ProjectClassificationOption>
        <ProjectClassificationOption onClick={() => handleClassificationClick("nameAsc")}>Nome do Projeto (A-Z)</ProjectClassificationOption>
        <ProjectClassificationOption onClick={() => handleClassificationClick("nameDesc")}>Nome do Projeto (Z-A)</ProjectClassificationOption>
      </ProjectClassificationOptionsContainer>
    </ProjectClassificationContainer>
  );
}

const ProjectClassificationContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 22px;
  right: calc(250px + 130px);
  width: 130px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #C5C5C5;
  font-size: 16px;
  text-align: left;
  padding-left: 10px;
  border-radius: 4px;
  color: #808080;
  z-index: 10;
  cursor: pointer;
  img {
    width: 20px;
    margin-right: 8px;
  }
  @media (max-height: 720px) {
    top: 14px;
  }
`;

const ProjectClassificationOptionsContainer = styled.div`
  display: ${(props) => props.isOn ? "flex" : "none"};
  flex-direction: column;
  justify-content: space-evenly;
  width: 200px;
  height: 160px;
  position: absolute;
  top: 39px;
  left: 0;
  border: 1px solid #C5C5C5;
  background-color: #ffffff;
  border-radius: 0px 0px 10px 10px;
`;

const ProjectClassificationOption = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  padding: 12px 0 12px 12px;
  color: #333333;
  :nth-child(4) {
    border-radius: 0px 0px 10px 10px;
  }
  :hover {
    background-color: #c7c7c7;
  }
`;
