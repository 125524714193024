import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";

class AnchorModel {
  constructor(
    anchorType = "",
    basePlateSteelType = "",
    basePlateL1 = "",
    basePlateL2 = "",
    basePlateThickness = "",
    basePlateDistance1 = "",
    basePlateDistance2 = "",
    anchorSteelType = "",
    anchorBoltsNumber = "",
    anchorBoltsDiameter = "",
  ) {
    this.anchorType = anchorType;
    this.basePlateSteelType = basePlateSteelType;
    this.basePlateL1 = basePlateL1;
    this.basePlateL2 = basePlateL2;
    this.basePlateThickness = basePlateThickness;
    this.basePlateDistance1 = basePlateDistance1;
    this.basePlateDistance2 = basePlateDistance2;
    this.anchorSteelType = anchorSteelType;
    this.anchorBoltsNumber = anchorBoltsNumber;
    this.anchorBoltsDiameter = anchorBoltsDiameter;
  }

  updateField(field, value) {
    if (value === this[field] || this[field] === undefined) return;
    this[field] = value;
  }
}

class AnchorsModel {
  constructor(anchor = {}) {
    this.anchor = new AnchorModel(
      anchor.anchorType,
      anchor.basePlateSteelType,
      anchor.basePlateL1,
      anchor.basePlateL2,
      anchor.basePlateThickness,
      anchor.basePlateDistance1,
      anchor.basePlateDistance2,
      anchor.anchorSteelType,
      anchor.anchorBoltsNumber,
      anchor.anchorBoltsDiameter,
    );
  }

  updateAnchorField(field, value) {
    this.anchor.updateField(field, value);
  }

  validate() {
    let errors = [];

    if (!this.anchor.anchorType)
      errors.push("Tipo de ancoragem");
    if (!this.anchor.basePlateSteelType)
      errors.push("Tipo de aço da placa base");
    if (!this.anchor.basePlateL1)
      errors.push("Largura 1 da placa base");
    if (!this.anchor.basePlateL2)
      errors.push("Largura 2 da placa base");
    if (!this.anchor.basePlateThickness)
      errors.push("Espessura da placa base");
    if (!this.anchor.basePlateDistance1)
      errors.push("Distância 1 da placa base");
    if (!this.anchor.basePlateDistance2)
      errors.push("Distância 2 da placa base");
    if (!this.anchor.anchorSteelType)
      errors.push("Tipo de aço do chumbador");
    if (!this.anchor.anchorBoltsNumber)
      errors.push("Quantidade de chumbadores");
    if (!this.anchor.anchorBoltsDiameter)
      errors.push("Diâmetro dos chumbadores");

    if (errors.length > 0) {
      const errorMessage = customToastError(errors,
        "Preencha todos os valores corretamente para salvar as informações de ancoragem. Falta(m):");
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  toJSON() {
    return JSON.stringify({
      anchorType: this.anchor.anchorType,
      basePlateSteelType: this.anchor.basePlateSteelType,
      basePlateL1: this.anchor.basePlateL1,
      basePlateL2: this.anchor.basePlateL2,
      basePlateThickness: this.anchor.basePlateThickness,
      basePlateDistance1: this.anchor.basePlateDistance1,
      basePlateDistance2: this.anchor.basePlateDistance2,
      anchorSteelType: this.anchor.anchorSteelType,
      anchorBoltsNumber: this.anchor.anchorBoltsNumber,
      anchorBoltsDiameter: this.anchor.anchorBoltsDiameter,
    });
  }

  static fromJSON(json) {
    return new AnchorModel(
      json.anchorType || "",
      json.basePlateSteelType || "",
      json.basePlateL1 || "",
      json.basePlateL2 || "",
      json.basePlateThickness || "",
      json.basePlateDistance1 || "",
      json.basePlateDistance2 || "",
      json.anchorSteelType || "",
      json.anchorBoltsNumber || "",
      json.anchorBoltsDiameter || "",
    );
  }
}

export default AnchorsModel;
