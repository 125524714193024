import useAsync from "../useAsync";

import * as authApi from "../../services/userApi";

export default function useConfirmEmail() {
  const {
    loading: confirmEmailLoading,
    error: confirmEmailError,
    act: confirmEmail
  } = useAsync((email, confirmationType) => authApi.confirmEmail(email, confirmationType), false);

  return {
    confirmEmailLoading,
    confirmEmailError,
    confirmEmail
  };
}
