import styled from "styled-components";
import { useContext, useState, useEffect, useCallback } from "react";
import UserContext from "../../../contexts/UserContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { TowerContext } from "../../../contexts/TowerContext";
import TowerAnchorsLeftMenu from "./tower_anchorsLeftMenu";
import TowerAnchorsRightContainer from "./tower_anchorsRightContainer";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import useUnsavedChangesAlert from "../../../hooks/useUnsavedChangesAlert";
import { useTowerAnchors } from "../../../hooks/api/tower-api/useTowerAnchors";
import AnchorsModel from "../../../models/AnchorModel";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function TowerArchorsPage() {
  const { userData } = useContext(UserContext);
  const { towerId, anchorsSelected, setAnchorsSelected } = useContext(TowerContext);
  const { reloadProject, setIsUpdatedWithoutCalculation,
    isLoading, setIsLoading } = useContext(ProjectContext);

  const { act: fetchAnchors } = useTowerAnchors("get");
  const { act: saveAnchors } = useTowerAnchors("post");

  const [inputValues, setInputValues] = useState(new AnchorsModel().anchor);

  useEffect(() => {
    loadAnchorsData().then(response => {
      if (response) {
        const anchorModel = new AnchorsModel(response);
        setAnchorsSelected(anchorModel.anchor);
        setInputValues(anchorModel.anchor);
      }
    });
  }, [towerId, userData.token]);

  const loadAnchorsData = useCallback(async() => {
    setIsLoading(true);
    try {
      return await fetchAnchors(towerId, userData.token);
    } catch (error) {
      toast.error("Erro ao buscar dados de ancoragem.");
    } finally {
      setIsLoading(false);
    }
  }, [fetchAnchors, towerId, userData.token]);

  const anchorTypes = ["Stub", "Chumbadores"];

  const fieldsToCheck = [ "anchorType", "basePlateSteelType", "basePlateL1", "basePlateL2", "basePlateThickness",
    "basePlateDistance1", "basePlateDistance2", "anchorSteelType", "anchorBoltsNumber", "anchorBoltsDiameter"];

  const isInputModified = useUnsavedChangesAlert(
    { ...inputValues }, anchorsSelected, reloadProject, fieldsToCheck);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    const anchorModel = new AnchorsModel(inputValues);
    anchorModel.updateAnchorField(name, value);
    setInputValues(anchorModel.anchor);
  }, [inputValues, setInputValues]);

  async function updateAnchorsData() {
    const anchorModel = new AnchorsModel(inputValues);
    if (!anchorModel.validate()) return false;

    setIsLoading(true);
    try {
      const updatedData = await saveAnchors(anchorModel.toJSON(), towerId, userData.token);
      const newAnchorModel = new AnchorsModel(updatedData).anchor;
      setAnchorsSelected(newAnchorModel);
      setInputValues(newAnchorModel);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações de ancoragem atualizadas com sucesso");
      return true;
    } catch (error) {
      toast.error("Erro ao salvar dados de ancoragem.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Ancoragem"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updateAnchorsData}
      >
        <TowerAnchorsLeftMenu 
          inputValues={inputValues}
          anchorTypes={anchorTypes}
          handleInputChange={handleInputChange}
        />
      </LeftMenu>
      <RightContainer>
        <TowerAnchorsRightContainer
          inputValues={inputValues}
          anchorTypes={anchorTypes}
          handleInputChange={handleInputChange}
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
