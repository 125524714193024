import { useMemo, useState, useEffect } from "react";
import { useTable } from "react-table";
import styled from "styled-components";

export function ReactionTable({ AEVValues, mastProps, mastSelected }) {
  const [props, setProps] = useState(JSON.parse(mastSelected.props) || mastProps);
  const [characteristicReactionValues, setCharacteristicReactionValues] = useState([]);
  const strapMessage = props.strapMessage || [];
  useEffect(() => {
    if(strapMessage?.length > 1) findMaxValues();
  }, []);

  function findMaxValues() {
    const maxResponses = {};
    let reactions = strapMessage && strapMessage.length !== 0 ? strapMessage[2] : [];
    reactions = reactions.filter(reaction => (reaction.combinacao.indexOf("1.00") >= 0));
    let nodes = {};
    for (const reaction of reactions) {
      if(!nodes[reaction.node]) nodes[reaction.node] = true; 
    }
    const arrNodes = [];
    nodes = Object.keys(nodes).map(node => arrNodes.push(Number(node)));
    props.nodesReactions = arrNodes;
    const allReactions = [];
    
    for (let i = 0; i <= nodes.length-1; i++) {
      const selectedReactions = reactions.filter(reaction => reaction.node === arrNodes[i]);
      allReactions.push(selectedReactions);
      maxResponses[`no${arrNodes[i]}tracao`] = selectedReactions.reduce((lowReaction, reaction) => { return (lowReaction.x3 < reaction.x3 ? lowReaction : reaction); }).x3;
      maxResponses[`no${arrNodes[i]}compressao`] = selectedReactions.reduce((maxReaction, reaction) => { return (maxReaction.x3 > reaction.x3 ? maxReaction : reaction); }).x3;
      maxResponses[`no${arrNodes[i]}cortanteX2`] = selectedReactions.reduce((maxReaction, reaction) => { return (maxReaction.x2 > reaction.x2 ? maxReaction : reaction); }).x2;
      maxResponses[`no${arrNodes[i]}cortanteX1`] = selectedReactions.reduce((maxReaction, reaction) => { return (maxReaction.x1 > reaction.x1 ? maxReaction : reaction); }).x1;
      maxResponses[`no${arrNodes[i]}cortante`] = maxResponses[`no${arrNodes[i]}cortanteX2`] > maxResponses[`no${arrNodes[i]}cortanteX1`] ? maxResponses[`no${arrNodes[i]}cortanteX2`] : maxResponses[`no${arrNodes[i]}cortanteX1`];
      delete maxResponses[`no${arrNodes[i]}cortanteX2`];
      delete maxResponses[`no${arrNodes[i]}cortanteX1`];
      maxResponses[`no${arrNodes[i]}momentoX4`] = selectedReactions.reduce((maxReaction, reaction) => { return (maxReaction.x4 > reaction.x4 ? maxReaction : reaction); }).x4;
      maxResponses[`no${arrNodes[i]}momentoX5`] = selectedReactions.reduce((maxReaction, reaction) => { return (maxReaction.x5 > reaction.x5 ? maxReaction : reaction); }).x5;
      maxResponses[`no${arrNodes[i]}momentoX6`] = selectedReactions.reduce((maxReaction, reaction) => { return (maxReaction.x6 > reaction.x6 ? maxReaction : reaction); }).x6;
      const moments = [maxResponses[`no${arrNodes[i]}momentoX4`], maxResponses[`no${arrNodes[i]}momentoX5`], maxResponses[`no${arrNodes[i]}momentoX6`]];
      maxResponses[`no${arrNodes[i]}momento`] = moments.reduce((max, current) => { return (max > current ? max : current); });
      delete maxResponses[`no${arrNodes[i]}momentoX4`];
      delete maxResponses[`no${arrNodes[i]}momentoX5`];
      delete maxResponses[`no${arrNodes[i]}momentoX6`];
    }
    function getCharacteristicValues(sequence) {
      let maxValues = { x1: Number.NEGATIVE_INFINITY, x2: Number.NEGATIVE_INFINITY, x3: Number.NEGATIVE_INFINITY, x4: Number.NEGATIVE_INFINITY, x5: Number.NEGATIVE_INFINITY, x6: Number.NEGATIVE_INFINITY };
      let minValues = { x1: Number.POSITIVE_INFINITY, x2: Number.POSITIVE_INFINITY, x3: Number.POSITIVE_INFINITY, x4: Number.POSITIVE_INFINITY, x5: Number.POSITIVE_INFINITY, x6: Number.POSITIVE_INFINITY };
      for (const obj of sequence) {
        for (let i = 1; i <= 6; i++) {
          const xKey = `x${i}`;
          maxValues[xKey] = Math.max(maxValues[xKey], obj[xKey]);
          minValues[xKey] = Math.min(minValues[xKey], obj[xKey]);
        }
      }
      return { maxValues, minValues };
    }
    const characteristicValuesOfSequences = allReactions.map(getCharacteristicValues);
    setCharacteristicReactionValues(characteristicValuesOfSequences);
    setProps({ ...props, nodesReactions: arrNodes });
    return maxResponses;
  }

  const data = [
    {
      posicao: "Bloco central",
      valor: "Máx",
      x1: characteristicReactionValues[0] ? characteristicReactionValues[0].maxValues.x1?.toFixed(3) : "", 
      x2: characteristicReactionValues[0] ? characteristicReactionValues[0].maxValues.x2?.toFixed(3) : "", 
      x3: characteristicReactionValues[0] ? characteristicReactionValues[0].maxValues.x3?.toFixed(3) : "", 
      x4: characteristicReactionValues[0] ? characteristicReactionValues[0].maxValues.x4?.toFixed(3) : "", 
      x5: characteristicReactionValues[0] ? characteristicReactionValues[0].maxValues.x5?.toFixed(3) : "", 
      x6: characteristicReactionValues[0] ? characteristicReactionValues[0].maxValues.x6?.toFixed(3) : "", 
      rowspan: 2,
    },
    {
      valor: "Min",
      x1: characteristicReactionValues[0] ? characteristicReactionValues[0].minValues.x1?.toFixed(3) : "", 
      x2: characteristicReactionValues[0] ? characteristicReactionValues[0].minValues.x2?.toFixed(3) : "", 
      x3: characteristicReactionValues[0] ? characteristicReactionValues[0].minValues.x3?.toFixed(3) : "", 
      x4: characteristicReactionValues[0] ? characteristicReactionValues[0].minValues.x4?.toFixed(3) : "", 
      x5: characteristicReactionValues[0] ? characteristicReactionValues[0].minValues.x5?.toFixed(3) : "", 
      x6: characteristicReactionValues[0] ? characteristicReactionValues[0].minValues.x6?.toFixed(3) : "", 
    },
  ];
  for (let i = 1; i < characteristicReactionValues?.length; i ++) {
    data.push({ posicao: `Estai ${i}`, valor: "Máx",
      x1: characteristicReactionValues[i] ? characteristicReactionValues[i].maxValues.x1?.toFixed(3) : "", 
      x2: characteristicReactionValues[i] ? characteristicReactionValues[i].maxValues.x2?.toFixed(3) : "", 
      x3: characteristicReactionValues[i] ? characteristicReactionValues[i].maxValues.x3?.toFixed(3) : "", 
      x4: characteristicReactionValues[i] ? characteristicReactionValues[i].maxValues.x4?.toFixed(3) : "", 
      x5: characteristicReactionValues[i] ? characteristicReactionValues[i].maxValues.x5?.toFixed(3) : "", 
      x6: characteristicReactionValues[i] ? characteristicReactionValues[i].maxValues.x6?.toFixed(3) : "", 
      rowspan: 2,
    },     {
      valor: "Min",
      x1: characteristicReactionValues[i] ? characteristicReactionValues[i].minValues.x1?.toFixed(3) : "", 
      x2: characteristicReactionValues[i] ? characteristicReactionValues[i].minValues.x2?.toFixed(3) : "", 
      x3: characteristicReactionValues[i] ? characteristicReactionValues[i].minValues.x3?.toFixed(3) : "", 
      x4: characteristicReactionValues[i] ? characteristicReactionValues[i].minValues.x4?.toFixed(3) : "", 
      x5: characteristicReactionValues[i] ? characteristicReactionValues[i].minValues.x5?.toFixed(3) : "", 
      x6: characteristicReactionValues[i] ? characteristicReactionValues[i].minValues.x6?.toFixed(3) : "", 
    },);
  }
  const columns = useMemo(
    () => [
      {
        Header: "POSIÇÃO",
        accessor: "posicao",
        headerClassName: "cell-center",
        HeaderSpan: 9,
      },
      {
        Header: "VALOR",
        accessor: "valor",
      },
      {
        Header: "X1",
        accessor: "x1",
      },
      {
        Header: "X2",
        accessor: "x2",
      },
      {
        Header: "X3",
        accessor: "x3",
      },
      {
        Header: "X4",
        accessor: "x4",
      },
      {
        Header: "X5",
        accessor: "x5",
      },
      {
        Header: "X6",
        accessor: "x6",
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <TableContainer>
      <TableTitle>Reações de Apoio (Valores Característicos)</TableTitle>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, headerGroupIndex) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              style={{
                border: "2.75px solid #F2F0F4",
                backgroundColor: headerGroupIndex === 0 ? "#DADADA" : "#fff",
                color: headerGroupIndex === 0 ? "#000" : "#565656",
                fontWeight: 500,
                fontSize: "14px",
                verticalAlign: "middle",
              }}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    border: "2.75px solid #F2F0F4",
                    padding: "10px",
                    fontSize: "14px",
                    verticalAlign: "middle",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={row.original.className}
                style={{
                  backgroundColor: "#ffffff",
                  borderBottom: rowIndex % 2 !== 0 ? "2.75px solid #F2F0F4" : "none",
                  fontSize: "14px",
                  verticalAlign: "middle",
                }} >
                {row.cells.map((cell, cellIndex) => {
                  const cellProps = cell.getCellProps();
                  const auxInCaseOfTypeMA = mastProps.tipoMastro === "MA" ? 30 : 0;
                  if ((Number(rowIndex) + 1) % 2 !== 0 && cellIndex == 0) {
                    return (
                      <EstaiPositionTableCell {...cellProps} height={`${25 + 30 * (rowIndex/2) + auxInCaseOfTypeMA}%`} left={rowIndex == 0 ? "3%" : "5.5%"}>
                        {cell.render("Cell")}
                      </EstaiPositionTableCell>
                    );
                  }
                  else return (
                    <TableCell {...cellProps}>
                      {cell.render("Cell")}
                    </TableCell>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
};

const TableContainer = styled.div`
  width: 71%;
  max-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: auto;
  border-radius: 8px;
  margin-left: 20px;
  overflow: hidden;
`;

const Table = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  background-color: #d0cece;
  border-left: 2.75px solid #F2F0F4;
  position: relative;
  td {
    border-right: 2.75px solid #F2F0F4;
    border-left: 2.75px solid #F2F0F4;
    color: #565656;
  }
`;

const TableCell = styled.td`
  text-align: center;
  padding: 8px;
  border-left: 2.75px solid #F2F0F4;
`;

const EstaiPositionTableCell = styled.td`
  text-align: center;
  position: absolute;
  height: 100%;
  top: ${(props) => props.height};
  left: ${(props) => props.left};
  border: none !important;
`;

const TableTitle = styled.div`
  width: 91.5%;
  height: 40px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 18px;
`;
