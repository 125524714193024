import React from "react";
import styled from "styled-components";
import colors from "../../../constants/color-constants";

export default function TowerAntennasTable({
  columnTitles,
  conditionsColors,
  content,
  totals,
}) {
  const renderColumnTitles = columnTitles.map((title, index) => (
    <TableHeader
      key={index}
      isFirst={index === 0}
      isLast={index === columnTitles.length - 1}
    >{title}</TableHeader>
  ));

  const renderTableBody = content.map((row, rowIndex) => {
    let color;
    if (row[2] === "Existente") color = conditionsColors.existing;
    if (row[2] === "Retirar") color = conditionsColors.remove;
    if (row[2] === "Instalar") color = conditionsColors.install;
    if (row[2] === "Reserva") color = conditionsColors.reserve;

    return (
      <tr key={rowIndex}>
        {row.map((cell, cellIndex) => (
          <TableData key={cellIndex} color={color}>{cell}</TableData>
        ))}
      </tr>
    );
  });

  return (
    <Container>
      <h2>Tabela de Antenas</h2>
      <Table>
        <thead>
          <tr>{renderColumnTitles}</tr>
        </thead>
        <tbody>
          {renderTableBody}
          <ResultsRow>
            <EmptyCell colSpan={8} />
            <TotalCell>TOTAL</TotalCell>
            <TotalResultsCell>{totals.area.toFixed(2)}</TotalResultsCell>
            <TotalResultsCell>{totals.areaAndDragCoef.toFixed(2)}</TotalResultsCell>
            <TotalResultsCell>{totals.load.toFixed(2)}</TotalResultsCell>
          </ResultsRow>
        </tbody>
      </Table>
    </Container>
  );
}

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;

    h2 {
        margin-bottom: 12px;
        color: ${colors.kcTextColor};
        font-size: 20px;
        font-weight: 500;
    }
`;

const Table = styled.table`
    width: 100%;
    height: min-content;
    border-collapse: separate;
    border-spacing: 0;
    background-color: ${colors.kcWhiteColor};
    overflow: scroll;

    tbody tr {
        height: 40px;
    }

    thead tr:first-child th:first-child {
        border-top-left-radius: 6px;
    }

    thead tr:first-child th:last-child {
        border-top-right-radius: 6px;
    }

    tbody tr:last-child td:first-child {
        border-bottom-left-radius: 6px;
    }

    tbody tr:last-child td:last-child {
        border-bottom-right-radius: 6px;
    }
`;

const TableHeader = styled.th`
    max-height: 50px;
    padding: 10px 4px;
    border: 1px solid #F2F0F4;
    background-color: #DADADA;
    color: ${colors.kcBlackColor};
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
`;

const TableData = styled.td`
    padding: 10px 4px;
    border: 1px solid #F2F0F4;
    font-size: 12px;
    font-weight: 300;
    text-align: center;
    vertical-align: middle;
    color: ${(props) => props.color || colors.kcDarkGray};
`;

const ResultsRow = styled.tr`
    width: 100%;
    align-items: flex-end;
`;

const TotalCell = styled.td`
    padding: 10px 4px;
    border: 1px solid #F2F0F4;
    background-color: #464646;
    color: ${colors.kcWhiteColor};
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    font-weight: 500 !important;
    border-radius: 0 0 0 6px !important;
`;

const TotalResultsCell = styled.td`
    padding: 10px 4px;
    border: 1px solid #F2F0F4;
    background-color: #E2E2E2;
    color: #333333;
    text-align: center;
    vertical-align: middle;
    font-size: 13px;
    font-weight: 500 !important;
`;

const EmptyCell = styled.td`
    color: ${colors.kcWhiteColor};
    border: none !important;
`;
