import useAsync from "../useAsync";
import * as projectApi from "../../services/projectApi";

export default function useUpdateProjectName() {
  const {
    data: newProjectName,
    loading: updateProjectNameLoading,
    error: updateProjectNameError,
    act: updateProjectName,
  } = useAsync((props, token) => projectApi.updateProjectName(props, token), false);

  return {
    newProjectName: newProjectName,
    updateProjectNameLoading: updateProjectNameLoading,
    updateProjectNameError: updateProjectNameError,
    updateProjectName: updateProjectName
  };
}
