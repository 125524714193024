import * as projectApi from "../../services/projectApi";
import useAsync from "../useAsync";

export default function useGetUserProjectAccessHistory() {
  const {
    data: userProjectAccessHistory,
    loading: getUserProjectAccessHistoryLoading,
    error: getUserProjectAccessHistoryError,
    act: getUserProjectAccessHistory,
  } = useAsync((token, projectType) => projectApi.getUserProjectAccessHistory(token, projectType), false);

  return {
    userProjectAccessHistory,
    getUserProjectAccessHistoryLoading,
    getUserProjectAccessHistoryError,
    getUserProjectAccessHistory
  };
}
