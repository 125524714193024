import React from "react";
import styled from "styled-components";
import radioButtonSelected from "../../assets/images/radio-button-selected.svg";
import radioButtonUnselected from "../../assets/images/radio-button-unselected.svg";

const CustomRadioButton = ({ value, label, checked, onClick, disabled }) => {
  return (
    <RadioButtonContainer 
      onClick={() => onClick(value)}>
      <img src={!disabled && checked ? radioButtonSelected : radioButtonUnselected} alt="" />
      <h3>{label ? label : value}</h3>
    </RadioButtonContainer>
  );
};

const RadioButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  cursor: pointer;

  img {
    width: 16px;
  }

  h3 {
    margin-left: 6px;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    text-align: center;
    text-transform: capitalize;
    font-family: 'Roboto', sans-serif;
  }
`;

export default CustomRadioButton;
