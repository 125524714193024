import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";

class WindObjectModel {
  constructor(
    location = "",
    coordinates = "",
    v0 = "",
    s1 = "",
    s2 = "",
    s3 = "",
    roadMapImageURL = "",
    satelliteMapImageURL = ""
  ) {
    this.location = location;
    this.coordinates = coordinates;
    this.v0 = v0;
    this.s1 = s1;
    this.s2 = s2;
    this.s3 = s3;
    this.roadMapImageURL = roadMapImageURL;
    this.satelliteMapImageURL = satelliteMapImageURL;
  }

  updateField(field, value) {
    if (this[field] !== value) {
      this[field] = value;
    }
  }
}

class WindModel {
  constructor(wind = {}) {
    this.wind = new WindObjectModel(
      wind.location,
      wind.coordinates,
      wind.v0,
      wind.s1,
      wind.s2,
      wind.s3,
      wind.roadMapImageURL,
      wind.satelliteMapImageURL,
    );
  }

  updateWind(field, value) {
    this.wind.updateField(field, value);
  }

  validate() {
    const wind = this.wind;
    let errors = [];

    const coordinatesLocalRegex = /^-?\d{1,2}\.\d{0,8}, -?\d{1,2}\.\d{0,8}$/;
    const areCoordinatesValid = coordinatesLocalRegex.test(wind.coordinates);
    if (!areCoordinatesValid) {
      toast.error("Insira um valor válido de coordenadas ou selecione o local no mapa");
      return false;
    }

    if (wind.v0 <= 0 || wind.v0 > 100) {
      toast.error("V0 deve ser um valor positivo e menor que 100 m/s");
      return false;
    }

    if (!wind.location) errors.push("Localização");
    if (!wind.coordinates) errors.push("Coordenadas");
    if (!wind.v0) errors.push("V0 - Vento Operacional (m/s)");
    if (!wind.s1) errors.push("S1 - Fator Topográfico");
    if (!wind.s2) errors.push("S2 - Fator de Rugosidade");
    if (!wind.s3) errors.push("S3 - Fator Estatístico");

    if (errors.length > 0) {
      const errorMessage = customToastError(errors,
        "Preencha todos os valores corretamente para salvar as informações dos ventos. Falta(m):");
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  toJSON() {
    return JSON.stringify(this.wind);
  }

  static fromJSON(json) {
    return new WindModel(json.wind);
  }
}

export default WindModel;
