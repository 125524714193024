import api from "./api";

export async function getAntenna(model, token) {
  const response = await api.get(`/antennas/getOne/${model}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};

export async function getAllAntennas(token) {
  const response = await api.get("/antennas/all", {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};
  
