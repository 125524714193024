import { useContext, useEffect } from "react";
import styled from "styled-components";
import { MenuContext } from "../../contexts/MenuContext";
import gray_arrow_icon from "../../assets/images/gray-arrow-icon.svg";
import { ProjectContext } from "../../contexts/ProjectContext";
import useMastStage from "../../hooks/useMastStage";

export default function ProjectMainContainer() {
  const { leftMenuWidth } = useContext(MenuContext);
  const { projectSelected, mastSelected, setProjectSelected, setMastSelected, setProjectNameSelected, setLeftMenuOptionSelected, reloadProject, setReloadProject, projectType } = useContext(ProjectContext);
  const mastStage = useMastStage();
  useEffect(() => {
  }, [mastSelected, projectSelected]);
  function handleMenuClick(menuType, menuName) {
    if (menuType === "project") {
      setProjectSelected({});
      setProjectNameSelected("");
      setMastSelected({});
      setReloadProject(!reloadProject);
    }
    else if (menuType === "projectName") {
      setMastSelected({});
      setReloadProject(!reloadProject);
    }
    else {
      setLeftMenuOptionSelected("");
    }
  }
  return (
    <Container leftMenuWidth={leftMenuWidth}>
      <ProjectNameWrapper>
        <h2><span onClick={() => handleMenuClick("project")}>Projetos</span> / <span onClick={() => handleMenuClick("projectName")}>{projectSelected?.nomeProjeto}</span> <span onClick={() => handleMenuClick("")}>{mastSelected?.identificador ? ` / ${mastSelected?.identificador}` : ""}</span></h2>
        <h1><span onClick={() => handleMenuClick("")}>{mastSelected?.identificador ? `${mastSelected?.identificador}` : projectSelected?.nomeProjeto}</span></h1>
      </ProjectNameWrapper>
      <ProjectStepByStepContainer>
        <h1>Para elaborar o seu projeto siga a seguinte sequência de telas e definições</h1>
        <ProjectStepByStepWrapper>
          {projectType === "Rooftop" ? (
            <ProjectStep isInStage={!mastStage || mastStage === 1}>
              <div>1</div>
              {projectSelected && projectSelected.Mastro?.length ? <h2>Selecionar Mastro</h2> : <h2>Adicionar Mastro</h2>}
              {projectSelected && projectSelected.Mastro?.length ? <h3>Selecione um mastro existente ou adicione um novo mastro ao seu rooftop</h3> : <h3>Adicione os mastros do seu rooftop</h3>}
            </ProjectStep>
          ) : (
            <ProjectStep isInStage={true}>
              <div>1</div>
              {<h2>Dados Gerais</h2>}
              <h3>Preencha os dados gerais, referêntes a geometria da torre</h3>
            </ProjectStep>
          )}
          <img src={gray_arrow_icon} alt="Gray Arrow pointing right" />
          <ProjectStep isInStage={mastStage === 2}>
            <div>2</div>
            <h2>Parâmetros de Vento</h2>
            <h3>Configure os parâmetros de vento conforme a região do país</h3>
          </ProjectStep>
          <img src={gray_arrow_icon} alt="Gray Arrow pointing right" />
          <ProjectStep isInStage={false}>
            <div>3</div>
            <h2>Informações de Geometria</h2>
            <h3>Modele o mastro do seu rooftop configurando as informações de geometria</h3>
          </ProjectStep>
          <img src={gray_arrow_icon} alt="Gray Arrow pointing right" />
          {projectType === "Rooftop" ? (
            <ProjectStep isInStage={mastStage === 4}>
              <div>4</div>
              <h2>Carregamento de Antenas</h2>
              <h3>Indique o carregamento de antenas e equipamentos que serão considerados</h3>
            </ProjectStep>
          ) : (
            <ProjectStep isInStage={false}>
              <div>4</div>
              <h2>Combinações</h2>
              <h3>Defina as combinações de cargas que serão consideradas no cálculo</h3>
            </ProjectStep>
          )}
          <img src={gray_arrow_icon} alt="Gray Arrow pointing right" />
          {projectType === "Rooftop" ? (
            <ProjectStep isInStage={false}>
              <div>5</div>
              <h2>Calcule</h2>
              <h3>Calcule o mastro e veja os resultados do seu projeto</h3>
            </ProjectStep>
          ) : (
            <ProjectStep isInStage={mastStage === 5}>
              <div>5</div>
              <h2>Informações de Geometria</h2>
              <h3>Calcule o mastro e veja os resultados do seu projeto</h3>
            </ProjectStep>
          )}
          {projectType === "Torre" ? (
            <>
              <img src={gray_arrow_icon} alt="Gray Arrow pointing right" />
              <ProjectStep isInStage={false}>
                <div>6</div>
                <h2>Calcule</h2>
                <h3>Calcule o mastro e veja os resultados do seu projeto</h3>
              </ProjectStep>
            </>
          ) : null}
        </ProjectStepByStepWrapper>
      </ProjectStepByStepContainer>
    </Container>
  );
}

const Container = styled.div`
  width: ${(props) => `calc(100% - ${props.leftMenuWidth})` };
  height: calc(100vh - 50px);
  background-color: #ffffff;
  border-right: #B0b0b0 solid 30px;
  border-top: #B0b0b0 solid 30px;
 `;
 
const ProjectNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4vh;
  margin-left: 2.5vw;
  h2 {
    color: #666666;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  h1 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }
  span {
    transition: 0.0001s all;
    cursor: pointer;
    :hover {
      font-weight: 600 !important;
    }
  }
`;

const ProjectStepByStepContainer = styled.div`
  display: flex;
  margin-top: 4vh;
  flex-direction: column;
  background-color: #F6F6F6;
  width: 100%;
  height: 280px;
  align-items: center;
  position: relative;
  justify-content: space-evenly;
  h1 {
    font-size: 20px;
  }
`;

const ProjectStepByStepWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  :last-child {
    padding-right: 20px;
  }
`;

const ProjectStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 15%;
  div {
    display: flex;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: ${(props) => (props.isInStage ? "#FE8F03" : "#E1E5E6")};
    color: ${(props) => (props.isInStage ? "#ffffff" : "#B6C0C1")};
    margin-bottom: 10px;
  }
  h2 {
    font-size: 14px;
    color: #292929;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 14px;
    color: #939393;
    line-height: 17px;
  }
`;
