import useAsync from "../useAsync";
import * as usersApi from "../../services/userApi";

export default function usePostLogout() {
  const {
    data: logout,
    loading: postLogoutLoading,
    error: postLogoutError,
    act: postLogout,
  } = useAsync((token) => usersApi.postLogout(token), false);

  return {
    logout,
    postLogoutLoading,
    postLogoutError,
    postLogout
  };
}
