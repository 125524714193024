import styled from "styled-components";
import tower_profile_calculated from "../../../assets/images/tower-profile-calculated.jpg"; 

export default function TowerCalculationRightContainer() {
  return (
    <RightPageContainer>
      <TowerContainer>
        <DescriptionContainer>Resultados Metálica</DescriptionContainer>
        <TowerImgContainer>
          <img src={tower_profile_calculated} alt="Tower Profile"/>
        </TowerImgContainer>
      </TowerContainer>
      <LegendContainer>
        <DescriptionContainer>Legenda das Cores</DescriptionContainer>
        <ColorLegendContainer>
          <LegendLine>
            <ColorInLegend color={"yellow"}></ColorInLegend>
            <LegendDescription>0% a 25%</LegendDescription>
          </LegendLine>
          <LegendLine>
            <ColorInLegend color={"green"}></ColorInLegend>
            <LegendDescription>26% a 50%</LegendDescription>
          </LegendLine>
          <LegendLine>
            <ColorInLegend color={"lightBlue"}></ColorInLegend>
            <LegendDescription>51% a 75%</LegendDescription>
          </LegendLine>
          <LegendLine>
            <ColorInLegend color={"darkBlue"}></ColorInLegend>
            <LegendDescription>76% a 100%</LegendDescription>
          </LegendLine>
          <LegendLine>
            <ColorInLegend color={"red"}></ColorInLegend>
            <LegendDescription>100% a 110%</LegendDescription>
          </LegendLine>
          <LegendLine>
            <ColorInLegend color={"purple"}></ColorInLegend>
            <LegendDescription>{"> 111%"}</LegendDescription>
          </LegendLine>
        </ColorLegendContainer>
      </LegendContainer>
    </RightPageContainer>
  );
};

const RightPageContainer = styled.div`
  width: 90%;
  margin-left: 5%;
  display: flex;
  align-items: top;
  justify-content: space-around;
  margin-top: 50px;
`;

const TowerContainer = styled.div`
  width: 64%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const LegendContainer = styled.div`
  width: 28%;
`;

const DescriptionContainer = styled.div`
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    color: #000000;
    font-size: 21px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
    margin-bottom: 20px;
`;

const TowerImgContainer = styled.div`
  display: flex;
  width: 100%;
  height: 66vh;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
  align-items: center;
  justify-content: center;

  img {
    height: 94%;
  }
`;

const ColorLegendContainer = styled.div`
  display: grid;
  grid-template-columns: auto; 
  grid-column-gap: 10px;
  width: 100%;
  height: 30vh;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
`;

const LegendLine = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const ColorInLegend = styled.div`
  width: 50px;
  height: 10px;
  background-color: ${(props) => (props.color)};
  margin-left: 45px;
`;

const LegendDescription = styled.p`
  color: #000;
  font-size: 19px;
  margin-left: 75px;
`;
