import { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import CreateReportDialog from "../project-components/report-components/project-reportCreationDialog";
import { ProjectContext } from "../../contexts/ProjectContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserContext from "../../contexts/UserContext";

export default function ResultsTopMenuOptions() {
  const [isMenuOpen, setIsMenuOpen] = useState("");
  const handleMouseEnter = (menu) => {
    setIsMenuOpen(menu);
  };

  const handleMouseLeave = () => {
    setIsMenuOpen(false);
  };

  const [confirmDialog, setConfirmDialog] = useState({
    type: "",
    isOpen: false,
  });
  
  return (
    <MenuOptionSelectorContainer isClicked={isMenuOpen} onMouseEnter={() => handleMouseEnter("menu-options") }
      onMouseLeave={() => handleMouseLeave("menu-options")}>
      <ResultsSelectorContainer>
        <PrinterIconContainer>
          <ion-icon name="print-outline"></ion-icon>
        </PrinterIconContainer>
        <p>Resultados</p>
        <ion-icon name="chevron-down-outline"></ion-icon>
      </ResultsSelectorContainer>
      {isMenuOpen === "menu-options" && <InfoTopMenuOptions type="menu-options" isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />}
    </MenuOptionSelectorContainer>
  );
}

function InfoTopMenuOptions({ setIsMenuOpen, type }) {
  const { userInfo } = useContext(UserContext);
  const handleMouseEnter = () => {
    setIsMenuOpen(type);
  };
  const [confirmCreatingReportDialog, setConfirmCreatingReportDialog] = useState({
    type: "",
    isOpen: false,
  });

  return (
    <MenuContainer onMouseEnter={handleMouseEnter} right={userInfo.name? "220px" : "120px"}>
      <MenuOption confirmDialog={confirmCreatingReportDialog} setConfirmDialog={setConfirmCreatingReportDialog} title={"Relatório do Mastro"} subtitle={"Imprimir relatório de resultados do mastro selecionado"} cursor={"pointer"} navigationRoute={"relatorio"}/>
      <MenuOption title={"Memorial de Cálculo"} subtitle={"Imprimir memorial de cálculo completo com dados gerais e todos os mastros"} cursor={"pointer"} navigationRoute={"memorial"}/>
      <CreateReportDialog confirmDialog={confirmCreatingReportDialog} setConfirmDialog={setConfirmCreatingReportDialog} />
    </MenuContainer>
  );
}

function MenuOption({ title, subtitle, cursor, navigationRoute, confirmDialog, setConfirmDialog }) {
  const {  mastSelected, isUpdatedWithoutCalculation, reloadProject, projectSelected, setLeftMenuOptionSelected } = useContext(ProjectContext);
  const [parsedMastProps, setParsedMastProps] = useState({ });
  useEffect(() => {
    const mastProps = mastSelected?.props ? JSON.parse(mastSelected.props) : {};
    setParsedMastProps(mastProps);
  }, [isUpdatedWithoutCalculation, mastSelected, reloadProject]);
  const [isMouseOn, setIsMouseOn] = useState(false);
  const handleMouseEnter = () => {
    setIsMouseOn(true);
  };
  const handleMouseLeave = () => {
    setIsMouseOn(false);
  };

  function verifyProjectMasts() {
    if (!projectSelected.id) return;
    const notCalculatedMasts = [];
    projectSelected.Mastro.forEach(mast => {
      if (!mast.isCalculated) {
        notCalculatedMasts.push(mast.identificador);
      }
    });
    return notCalculatedMasts;
  }

  function handleNavigateClick() {
    if (navigationRoute === "relatorio") {
      if (!mastSelected.identificador) return toast.error("É necessário ter um mastro selecionado para acessar os resultados");
      if (parsedMastProps?.strapMessage && parsedMastProps?.strapMessage?.length !== 0) {
        setConfirmDialog( { ...confirmDialog, isOpen: true });
      }
      else return toast.error("É necessário calcular o mastro para acessar o relatório de resultados");
    }
    else {
      const notCalculatedMasts = verifyProjectMasts();
      if (!projectSelected?.Mastro?.length) return toast.error("O projeto ainda não possui mastros cadastrados. Adicione e calcule um mastro para gerar o relatório.");
      if (notCalculatedMasts.length) {
        let errorMessage = "Existem mastros não calculados no projeto. Antes de acessar o relatório, calcule os mastros:";
        notCalculatedMasts.forEach((mastName) => {
          errorMessage += ` ${mastName};`;
        });
        return toast.error(errorMessage);
      }
      return setLeftMenuOptionSelected("memorial");
    }
  }
  return (
    !mastSelected?.identificador && navigationRoute === "relatorio" ? "" :
      <MenuOptionContainer cursor={cursor} onClick={handleNavigateClick} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} isMouseOn={isMouseOn}>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </MenuOptionContainer>
  );
}

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 44px;
  right: ${(props) => props.right};
  height: auto;
  width: 190px;
  z-index: 5;
  background-color: #d0cece;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
`;

const MenuOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  align-items: flex-start;
  justify-content: space-evenly;
  height: 88px;
  width: 230px;
  margin-right: 20px;
  background-color: ${(props) => (props.isMouseOn ? "#F16E00" : "#d0cece")};
  :nth-last-child(1){
    border-bottom-left-radius: 10px;
  }
  cursor: pointer;
  h1 {
    font-size: 15px;
    color: ${(props) => (props.isMouseOn ? "#000" : "#333333")};
    font-weight: ${(props) => (props.isMouseOn ? "500" : "400")};;
  }
  h2 {
    font-size: 13.3px;
    color: ${(props) => (props.isMouseOn ? "#ffffff" : "#8B8B8B")};
  }

  div, ion-icon {
    font-family: "Open-sans", sans-serif;
    font-size: 19px;
    color: #000;
    font-weight: 300;
  }
  ion-icon {
    font-size: 23px;
    margin-right: 12px;
  }
`;

const MenuOptionSelectorContainer = styled.div`
  height: 38px;
  width: 160px;
  display: flex;
  align-items: center;
  margin-right: 16px;
  border-radius: 20px;
  justify-content: center;
  background-color: ${(props) => (props.isClicked ? "#d0cece" : "#D9D9D9")};
`;

const PrinterIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background-color: #F0F0F0;
  >ion-icon {
    font-size: 24px !important;
    padding: 0 !important;
  }
  p {
    font-size: 14px;
  }
`; 

const ResultsSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ion-icon {
    font-size: 14px;
    color: #000000;
    padding-right: 10px;
  }
`;
