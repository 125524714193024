import React, { useState } from "react";
import styled from "styled-components";
import { CustomInput } from "../../common/custom-input";
import { InputMenu } from "../../common/input-menu";
import colors from "../../../constants/color-constants";

export default function TowerWindLeftMenu({
  inputValues,
  handleInputChange,
  s2Types,
  s3Types,
}) {
  const [showInfo, setShowInfo] = useState({ isShown: false, infoType: "" });

  function handleInfoHover(event, infoType) {
    event.type === "mouseleave"
      ? setShowInfo({ isShown: false, infoType: "" })
      : setShowInfo({ isShown: event.type === "mouseenter", infoType: infoType });
  }

  return (
    <>
      <InputMenu>
        <h2>Local</h2>
        <Spacer />
      </InputMenu>

      <InputMenu>
        <div>
          <h2>Coordenadas</h2>
          <InformationIconContainer
            onMouseEnter={(e) => handleInfoHover(e, "coordinates")}
            onMouseLeave={handleInfoHover}
          >
            <ion-icon name="information-circle"></ion-icon>
          </InformationIconContainer>

          {showInfo.isShown && showInfo.infoType === "coordinates" && (
            <InfoDiv>
              {"Informação Local: Insira as coordenadas (ex: -21.20958,-43.755555), " +
                "clique na região do projeto no mapa à direita ou digite o endereço no campo acima."}
            </InfoDiv>
          )}
        </div>

        <input
          name="coordinates"
          placeholder="Coordenadas"
          value={inputValues?.coordinates ?? ""}
          onChange={newValue => handleInputChange({ target: { name: "coordinates", value: newValue } })}
        />
      </InputMenu>

      <InputMenu>
        <div>
          <h2>V0 - Vento Operacional (m/s)</h2>
          <InformationIconContainer
            onMouseEnter={(e) => handleInfoHover(e, "v0")}
            onMouseLeave={handleInfoHover}
          >
            <ion-icon name="information-circle"></ion-icon>
          </InformationIconContainer>

          {showInfo.isShown && showInfo.infoType === "v0" && (
            <InfoDiv>
              {"V0: O vento deve ser interpolado entre 30m/s e 50m/s de acordo " +
                "com o mapa de isopletas segundo a ABNT NBR 6123"}
            </InfoDiv>
          )}
        </div>

        <CustomInput
          name="v0"
          placeholder="V0 (m/s)"
          value={inputValues?.v0 ?? ""}
          onChange={newValue => handleInputChange({ target: { name: "v0", value: newValue } })}
          isNumber={true}
          unit={"m/s"}
        />
      </InputMenu>

      <InputMenu>
        <h2>S1 - Fator Topográfico</h2>
        <CustomInput
          name="s1"
          placeholder="Insira o valor de S1"
          value={inputValues?.s1 ?? ""}
          onChange={newValue => handleInputChange({ target: { name: "s1", value: newValue } })}
          isNumber={true}
        />
      </InputMenu>

      <InputMenu>
        <h2>S2 - Fator de Rugosidade</h2>
        <select
          name="s2"
          value={inputValues.s2}
          onChange={handleInputChange}
        >
          <option value={""} key={"s2-empty"}>Selecione uma opção...</option>
          {s2Types?.map((type) => (
            <option value={type} key={type}>{type}</option>
          ))}
        </select>
      </InputMenu>

      <InputMenu>
        <h2>S3 - Fator Estatístico</h2>
        <select
          name="s3"
          value={inputValues.s3}
          onChange={handleInputChange}>
          <option value={""} key={"s3-empty"}>Selecione uma opção...</option>
          {s3Types?.map((type) => (
            <option value={type} key={type}>{type}</option>
          ))}
        </select>
      </InputMenu>
    </>
  );
}

const InformationIconContainer = styled.div`
    width: min-content;
    max-width: 60px;
    height: min-content;
    position: relative;
    
    ion-icon {
      font-size: 26px;
      position: absolute;
      top: 50%;
      right: 0;
      rotate: -90deg;
      color: ${colors.kcOrange};
    }
`;

const InfoDiv = styled.div`
    position: absolute;
    width: 150px;
    top: 100%;
    right: 0;
    background-color: ${colors.kcWhiteColor};
    padding: 8px;
    font-size: 16px;
    color: ${colors.kcBlackColor};
    border: 1px dashed ${colors.kcOrange};
    border-radius: 4px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    z-index: 5;
`;

const Spacer = styled.div`
    display: flex;
    width: 100%;
    height: 42px;
`;
