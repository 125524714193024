import useAsync from "../../useAsync";
import { getMounts, postMounts, updateMounts } from "../../../services/tower-services/towerMountsApi";

export const useTowerMounts = (action) => {
  const execute = {
    get: getMounts,
    post: postMounts,
    update: updateMounts,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
