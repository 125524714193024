import api from "../apiTower";

const makeHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getTower = async(projectId, token) => {
  const response = await api
    .get(`/tower/data/${encodeURIComponent(projectId)}`, makeHeaders(token));
  return response.data;
};
