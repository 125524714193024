import api from "../apiTower";

const makeHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getDiagonals = async(towerId, token) => {
  const response = await api
    .get(`/tower/diagonals/${encodeURIComponent(towerId)}`, makeHeaders(token));
  return response.data;
};

export const postDiagonals = async(body, towerId, token) => {
  const parsedBody = JSON.parse(body);
  const response = await api
    .post(`/tower/diagonals/${encodeURIComponent(towerId)}`, parsedBody, makeHeaders(token));
  return response.data;
};

export const updateDiagonals = async(body, towerId, token) => {
  const parsedBody = JSON.parse(body);
  const response = await api
    .put(`/tower/diagonals/${encodeURIComponent(towerId)}`, parsedBody, makeHeaders(token));
  return response.data;
};
