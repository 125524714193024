import { useMemo, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useTable } from "react-table";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { calculateSomas } from "./mast-antennaCalculation";
import esteira_cabos from "../../../assets/images/esteira_cabos.png";

export default function AEVTable({ inputValues, reloadProject, mastProps }) {
  const { mastSelected } = useContext(ProjectContext);
  const [somasGrupos, setSomasGrupos] = useState([]);
  const updatedMastProps = typeof mastProps === "object" ? mastProps : JSON.parse(mastProps);
  const [AEVValues, setAEVValues] = useState({
    AEVExistente: 0,
    AEVRetirar: 0,
    AEVInstalar: 0,
    AEVReserva: 0,
    AEVTotal: 0,
  });
  const [esteira, setEsteira] = useState(JSON.parse(mastSelected.props).esteira);

  useEffect(() => {
    const updatedSomasGrupos = calculateSomas(updatedMastProps);
    setSomasGrupos(updatedSomasGrupos);
    setEsteira(JSON.parse(mastSelected.props).esteira);
  }, [mastProps, mastSelected]);

  useEffect(() => {
    let AEVExistente = 0;
    let AEVRetirar = 0;
    let AEVInstalar = 0;
    let AEVReserva = 0;
    let AEVTotal = 0;

    for (let i = 0; i < somasGrupos.length; i++) {
      if (somasGrupos[i].condicao === "Existente") {
        AEVExistente += somasGrupos[i]?.somaAreaECA;
        AEVTotal += somasGrupos[i]?.somaAreaECA;
      }
      if (somasGrupos[i].condicao === "Retirar") {
        AEVRetirar += somasGrupos[i]?.somaAreaECA;
        AEVTotal -= somasGrupos[i]?.somaAreaECA;
      }
      if (somasGrupos[i].condicao === "Instalar") {
        AEVInstalar += somasGrupos[i]?.somaAreaECA;
        AEVTotal += somasGrupos[i]?.somaAreaECA;
      }
      if (somasGrupos[i].condicao === "Reserva") {
        AEVReserva += somasGrupos[i]?.somaAreaECA;
        AEVTotal += somasGrupos[i]?.somaAreaECA;
      }
    }

    setAEVValues({
      AEVExistente,
      AEVRetirar,
      AEVInstalar,
      AEVReserva,
      AEVTotal,
    });
  }, [somasGrupos, mastProps]);

  const columns = useMemo(
    () => [
      {
        Header: `Resumo AEV - ${mastSelected.identificador}`,
        accessor: "AEV",
      },
    ], [AEVValues, mastProps]);

  const data = useMemo(() => {
    return [
      {
        AEV: `AEV existente (com C.A.) = ${AEVValues.AEVExistente.toFixed(3)}m²`,
        cellClassName: "normal",
      },
      {
        AEV: `AEV à retirar (com C.A.) = ${AEVValues.AEVRetirar.toFixed(3)}m²`,
        cellClassName: "blue",
      },
      {
        AEV: `AEV à instalar (com C.A.) = ${AEVValues.AEVInstalar.toFixed(3)}m²`,
        cellClassName: "red",
      },
      {
        AEV: `AEV Reserva (com C.A.) = ${AEVValues.AEVReserva.toFixed(3)}m²`,
        cellClassName: "green",
      },
      {
        AEV: `AEV Total (com C.A.) = ${AEVValues.AEVTotal.toFixed(3)}m²`,
        cellClassName: "bold",
      },
      {
        AEV: `Esteira de cabos: ${updatedMastProps.esteira ? esteira : ""}mm`,
        cellClassName: "esteira",
      }
    ];
  }, [AEVValues, mastProps]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });
  return (
    <TableContainer>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    borderRadius: index === 0 && columnIndex === 0 ? "14px" : "0",
                  }}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            const isPenultimateRow = rowIndex === rows.length - 2;
            const isLastRow = rowIndex === rows.length - 1;
            return (
              <tr
                {...row.getRowProps()}
                className={isPenultimateRow ? "penultimate-row" : row.original.cellClassName}
              >
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    style={{ fontWeight: isPenultimateRow ? 700 : "normal", padding: isLastRow ? "14px 14px 14px 100px" : "10px 10px 10px 70px", paddingLeft: isLastRow ? "106px" : "58px", }}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <EsteiraImg src={esteira_cabos} />
    </TableContainer>
  );
}

const TableContainer = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 60vh;
  display: flex;
  justify-content: flex-start;
  overflow: auto;
`;

const Table = styled.table`
  min-width: 18vw;
  height: 100%;
  border-collapse: collapse;
  background-color: #ffffff;

  th,
  td {
    border: 5px solid #F2F0F4;
    padding: 10px;
    vertical-align: middle;
  }

  td {
    text-align: left;
  }

  th {
    background-color: #464646;
    color: #FE8F03;
    font-size: 17px;
    padding: 11px;
    font-weight: 500 !important;
    text-align: center;
  }

  tr.green {
    color: green;
  }

  tr.red {
    color: #FC3333;
  }

  tr.blue {
    color: #157BC5;
  }

  tr.bold {
    font-weight: 500;
    font-size: 17px;
  }
  tr.esteira {
    font-weight: 400;
    font-size: 17px;
    position: relative;
  }
`;

const EsteiraImg = styled.img`
  width: 50px;
  height: 32px;
  position: absolute;
  z-index: 3;
  bottom: 4.5%;
  left: 14%;
`;
