import useAsync from "../useAsync";
import * as mastsApi from "../../services/mastApi";

export default function useMasts() {
  const {
    data: masts,
    loading: getMastsLoading,
    error: getMastsError,
    act: getMasts,
  } = useAsync((projectId, token) => mastsApi.getMasts(projectId, token), false);

  return {
    masts,
    getMastsLoading,
    getMastsError,
    getMasts
  };
}
