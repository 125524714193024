import useAsync from "../useAsync";

import * as authApi from "../../services/userApi";

export default function useLogin() {
  const {
    loading: signInLoading,
    error: signInError,
    act: signIn
  } = useAsync(({ email, password }) => authApi.signIn({ email, password }), false);

  return {
    signInLoading,
    signInError,
    signIn
  };
}
