import useAsync from "../useAsync";
import * as userApi from "../../services/userApi";

export default function useGetUserInfo() {
  const {
    data: userInfo,
    loading: getUserInfoLoading,
    error: getUserInfoError,
    act: getUserInfo,
  } = useAsync((token) => userApi.getUserInfo(token), false);

  return {
    userInfo,
    getUserInfoLoading,
    getUserInfoError,
    getUserInfo
  };
}
