import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { defaultError } from "../errors/default-error";
import { errorUserVerification } from "../errors/errorsUser";
import useConfirmEmail from "../hooks/api/useConfirmEmail";
import useLogup from "../hooks/api/useSignup";
import zeo_logo from "../assets/images/zeo-logo-blackfont.png";
import {
  Input,
  Button,
  Espacing,
  EspacingDiv,
  Error,
  BlackButton
} from "./home-page";
import { CentralAlignedDiv, Form, FullPageContainer, Img, PasswordInputContainer, BlackLeftContainer, FourBallsDesignContainer, WhiteRightContainer, ImgCircularContainer } from "./login-page";
import styled from "styled-components";
import fundacao_big_icon from "../assets/images/fundacao-big-icon.png";
import poste_big_icon from "../assets/images/poste-big-icon.png";
import rooftop_big_icon from "../assets/images/rooftop-big-icon.png";
import torre_big_icon from "../assets/images/torre-big-icon.png";

export function LogupPage() {
  const navigate = useNavigate();
  const [logupInfo, setLoginInfo] = useState({ email: "", password: "", confirmPassword: "" });
  const { signUpLoading, signUp } = useLogup();
  const { confirmEmailLoading, confirmEmail } = useConfirmEmail();
  const [errorMessage, setErrorMessage] = useState([]);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isRepeatedPasswordShown, setIsRepeatedPasswordShown] = useState(false);

  function preventAtt(event) {
    event.preventDefault();
  }

  async function submitLogin() {
    try {
      if (logupInfo.confirmPassword !== logupInfo.password)
        throw defaultError("Senhas não conferem");
      await signUp(logupInfo);
      navigate("/login");
    } catch (error) {
      return errorUserVerification(error, setErrorMessage);
    }
  }

  async function submitConfirmationEmail() {
    try {
      setErrorMessage([]);
      await confirmEmail(logupInfo.email);
    } catch (error) {
      return errorUserVerification(error, setErrorMessage);
    }
  }

  function toggleShowPassword() {
    setIsPasswordShown(!isPasswordShown);
  }

  function toggleShowRepeatedPassword() {
    setIsRepeatedPasswordShown(!isRepeatedPasswordShown);
  }

  return (
    <FullPageContainer>
      <BlackLeftContainer>
        <h1>Software online para análises e projetos estruturais para infraestrutura de telecomunicações</h1>
        <h2>Uma ferramenta poderosa com uma interface intuitiva que possibilita análises precisas e eficientes.
Reunimos softwares para todos os tipos de estruturas metálicas e de concreto para o segmento, simplificando o processo de cálculo e promovendo estruturas sólidas e confiáveis.</h2>
        <FourBallsDesignContainer>
          <ImgCircularContainer width="40%" height="70%">
            <img src={rooftop_big_icon} alt="Rooftop Icon"/>
          </ImgCircularContainer>
          <ImgCircularContainer width="40%" height="70%">
            <img src={torre_big_icon} alt="Torre Icon"/>
          </ImgCircularContainer>
          <ImgCircularContainer width="40%" height="70%">
            <img src={poste_big_icon} alt="Poste Icon"/>
          </ImgCircularContainer>
          <ImgCircularContainer width="64%" height="59%">
            <img src={fundacao_big_icon} alt="Fundação Icon"/>
          </ImgCircularContainer>
        </FourBallsDesignContainer>
      </BlackLeftContainer>
      <WhiteRightContainer>
        <Form onSubmit={preventAtt}>
          <a href="http://www.kk.eng.br/" target="_blank">
            <Img src={zeo_logo}/>
          </a>
          <Espacing>
            <Input type="text" placeholder="E-mail" onChange={(event) => { setLoginInfo({ ...logupInfo, email: event.target.value }); }}
            />
          </Espacing>
          <ButtonEspacingDiv>
            {confirmEmailLoading ? (
              <Button>Carregando...</Button>
            ) : (
              <BlackButton onClick={submitConfirmationEmail} disabled={signUpLoading}>
                Enviar verificação
              </BlackButton>
            )}
          </ButtonEspacingDiv>
          <Espacing>
            <Input
              type="text" placeholder="Código de verificação" onChange={(event) => { setLoginInfo({ ...logupInfo, validationNumber: event.target.value }); } } />
          </Espacing>
          <Espacing>
            <Input type="text" placeholder="Nome" onChange={(event) => { setLoginInfo({ ...logupInfo, name: event.target.value }); }} />
          </Espacing>
          <Espacing>
            <PasswordInputContainer>
              <Input type={!isPasswordShown ? "password" : "text"} placeholder="Senha" onChange={(event) => {setLoginInfo({ ...logupInfo, password: event.target.value });}}>
              </Input>
              <ion-icon onClick={toggleShowPassword} name={isPasswordShown ? "eye-outline" : "eye-off-outline"}></ion-icon>
            </PasswordInputContainer>
          </Espacing>
          <Espacing>
            <PasswordInputContainer>
              <Input type={!isRepeatedPasswordShown ? "password" : "text"} placeholder="Repita sua senha" onChange={(event) => {setLoginInfo({ ...logupInfo, confirmPassword: event.target.value });}}>
              </Input>
              <ion-icon onClick={toggleShowRepeatedPassword} name={isRepeatedPasswordShown ? "eye-outline" : "eye-off-outline"}> </ion-icon>
            </PasswordInputContainer>
          </Espacing>
          <EspacingDiv>
            {signUpLoading ? (
              <Button>Carregando...</Button>
            ) : (
              <BlackButton onClick={submitLogin} disabled={signUpLoading}>
                Criar conta
              </BlackButton>
            )}
          </EspacingDiv>
          <Error>{errorMessage}</Error>
          <CentralAlignedDiv
            onClick={() => {
              navigate("/login");
            }}
          >
            Já tem conta? <span>Clique aqui logar</span>
          </CentralAlignedDiv>
          <BottomSpacingDiv>
          </BottomSpacingDiv>
        </Form>
      </WhiteRightContainer>
    </FullPageContainer>
  );
}

const ButtonEspacingDiv = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const BottomSpacingDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
`;
