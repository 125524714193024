import styled from "styled-components";

export default function SearchingProjectBox({ projectSearchingTerm, setProjectSearchingTerm, projectFilterType }) {
  return (
    <SearchProjectContainer>
      <SearchProjectInput placeholder="Procurar projeto" value={projectSearchingTerm} onChange={(e) => setProjectSearchingTerm(e.target.value)}></SearchProjectInput>
      <ion-icon name="search-outline"></ion-icon>
    </SearchProjectContainer>
  );
}

const SearchProjectContainer = styled.div`
  display: flex;
  position: absolute;
  top: 22px;
  right: 10px;
  z-index: 10;
  @media (max-height: 720px) {
    top: 14px;
  }
  ion-icon {
    position: absolute;
    top: 9px;
    right: 10px;
    font-size: 20px;
    color: #8E8E8E;
    cursor: pointer;
  }
`;
const SearchProjectInput = styled.input`
  width: 250px;
  height: 40px;
  color: #000000 !important;
  background-color: #fff;
  border: 1px solid #C5C5C5;
  font-size: 16px;
  text-align: left;
  padding-left: 10px;
  border-radius: 4px;
`;
