import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";

class SectionModel {
  constructor(
    sectionNumber = "",
    initialSegment = "",
    finalSegment = "",
    topWidth = "",
    baseWidth = ""
  ) {
    this.sectionNumber = sectionNumber;
    this.initialSegment = initialSegment;
    this.finalSegment = finalSegment;
    this.topWidth = topWidth;
    this.baseWidth = baseWidth;
  }

  updateField(field, value) {
    this[field] = value;
  }
}

class SectionsModel {
  constructor(sections = []) {
    this.sections = sections.map(section => new SectionModel(
      section.sectionNumber,
      section.initialSegment,
      section.finalSegment,
      section.topWidth,
      section.baseWidth
    ));
  }

  updateSection(sectionNumber, field, value, nSegments, geometryTopWidth, geometryBaseWidth) {
    const section = this.sections.find(section => Number(section.sectionNumber) === sectionNumber);

    if (section && section[field] === value) return;

    section.updateField(field, value);

    if (field === "topWidth" && sectionNumber === 2)
      this.sections[0].updateField("baseWidth", value);

    if (field === "topWidth" && sectionNumber === 3)
      this.sections[1].updateField("baseWidth", value);

    if (field === "baseWidth" && sectionNumber === 1 && this.sections[1])
      this.sections[1].updateField("topWidth", value);

    if (field === "baseWidth" && sectionNumber === 2 && this.sections[2])
      this.sections[2].updateField("topWidth", value);

    if (field === "initialSegment" && sectionNumber === 1)
      section.updateField("topWidth", geometryTopWidth);

    if (field === "finalSegment" && Number(value) === Number(nSegments))
      section.updateField("baseWidth", geometryBaseWidth);
  }

  updateSectionHeights(trussesHeights) {
    return {
      section1: this.sections[0] ? this.calcSectionHeight(this.sections[0].initialSegment, this.sections[0].finalSegment, trussesHeights) : 0,
      section2: this?.sections[1] ? this.calcSectionHeight(this.sections[1].initialSegment, this.sections[1].finalSegment, trussesHeights) : 0,
      section3: this?.sections[2] ? this.calcSectionHeight(this.sections[2].initialSegment, this.sections[2].finalSegment, trussesHeights) : 0,
    };
  }

  calcSectionHeight(initialSegment, finalSegment, trussesHeights) {
    let sectionHeight = 0;
    for (let i = initialSegment - 1; i <= finalSegment - 1; i++) {
      const height = parseFloat(trussesHeights[i]);
      if (!isNaN(height)) sectionHeight += height;
    }
    return sectionHeight;
  }

  sumHeights(sectionNumber, sectionHeights) {
    let sum = 0;
    for (let i = 3; i >= sectionNumber; i--) {
      const height = Number(sectionHeights[`section${i}`]);
      if (!isNaN(height)) sum += height;
    }
    return sum;
  }

  validate(geometryTotalHeight, trussesHeights) {
    let errors = [];
    const sectionHeights = this.updateSectionHeights(trussesHeights);

    this.sections.forEach((section, index) => {
      if (!section.initialSegment) errors.push(`Trecho Inicial da Seção ${index + 1}`);
      if (!section.finalSegment) errors.push(`Trecho Final da Seção ${index + 1}`);
      if (!section.topWidth) errors.push(`Largura no Topo da Seção ${index + 1}`);
      if (!section.baseWidth) errors.push(`Largura na Base da Seção ${index + 1}`);
      if (Number(section.topWidth) > Number(section.baseWidth)) {
        errors.push(`A largura do topo não pode ser maior que a largura da base na seção ${index + 1}`);
      }
    });

    if (geometryTotalHeight === "") {
      errors.push("A altura da torre não pode ser 0.");
    }
    const towerSectionsHeight = this.sumHeights(1, sectionHeights);
    if (Number(geometryTotalHeight) * 1000 !== Number(towerSectionsHeight)) {
      errors.push("A altura total da torre deve ser igual à soma das alturas das seções.");
    }

    if (this.sections.length === 2) {
      if (Number(this.sections[0].baseWidth) !== Number(this.sections[1].topWidth)) {
        errors.push("A largura da base da Seção 1 deve ser igual a largura do topo da Seção 2.");
      }

      if (Number(this.sections[0].finalSegment) + 1 !== Number(this.sections[1].initialSegment)
        && this.sections[0].finalSegment !== "" && this.sections[1].finalSegment !== ""
      ) {
        errors.push("O trecho final da Seção 1 deve ser o trecho anterior ao trecho inicial da Seção 2.");
      }
    }

    if (this.sections.length === 3) {
      if (Number(this.sections[1].baseWidth) !== Number(this.sections[2].topWidth)) {
        errors.push("A largura da base da Seção 2 deve ser igual a largura do topo da Seção 3.");
      }

      if (Number(this.sections[1].finalSegment) + 1 !== Number(this.sections[2].initialSegment)
        && this.sections[1].finalSegment !== "" && this.sections[2].finalSegment !== "") {
        errors.push("O trecho final da Seção 2 deve ser o trecho anterior ao trecho inicial da Seção 3.");
      }
    }

    if (errors.length > 0) {
      const errorMessage = customToastError(errors,
        "Preencha todos os valores corretamente para salvar as informações das seções. Falta(m):");
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  static fromJSON(jsonArray) {
    return new SectionsModel(jsonArray.map(section => new SectionModel(
      section.sectionNumber,
      section.initialSegment,
      section.finalSegment,
      section.topWidth,
      section.baseWidth
    )));
  }
}

export default SectionsModel;
