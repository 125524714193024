import MB from "./MB.png";
import MC from "./MC.png";
import MD from "./MD.png";
import ME from "./ME.png";
import MF from "./MF.png";
import MG from "./MG.png";
import MH from "./MH.png";
import MI from "./MI.png";

const mastEstaiPositions = { MB, MC, MD, ME, MF, MG, MH, MI };
export default mastEstaiPositions;
