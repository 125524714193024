import styled from "styled-components";
import MastNameSelection from "../mast-components/mast-nameSelection";
import { useState, useContext, useRef, useEffect } from "react";
import { ProjectContext } from "../../contexts/ProjectContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MenuContext } from "../../contexts/MenuContext";
import { debounce } from "lodash";
import verifyNecessaryProps from "../mast-components/calculation-components/verify-necessary-props";
import useMasts from "../../hooks/api/useMasts";
import UserContext from "../../contexts/UserContext";
import LoadingAnimation from "../common/loading-animation";
import usePostDownloadDwg from "../../hooks/api/usePostDownloadDwg";

export default function ProjectLeftMenu({ isDonwloadingStrap }) {
  const { leftMenuOptionSelected, setLeftMenuOptionSelected, mastSelected, projectSelected, isUpdatedWithoutCalculation, reloadProject, projectType } = useContext(ProjectContext);
  const [isResizing, setIsResizing] = useState(false);
  const [resizeHandleRight, setResizeHandleRight] = useState(-8);
  const [resizeHandleWidth, setResizeHandleWidth] = useState(12);
  const [resizeOffsetX, setResizeOffsetX] = useState(0);
  const [resizeStartWidth, setResizeStartWidth] = useState(0);
  const containerRef = useRef(null);
  const { leftMenuWidth, setLeftMenuWidth } = useContext(MenuContext);
  const [handleDragDebounced] = useState(() => debounce(handleDrag, 500));
  const [parsedMastProps, setParsedMastProps] = useState({});
  const [projectMasts, setProjectMasts] = useState([]);
  const { userData } = useContext(UserContext);
  const { getMasts, getMastsLoading } = useMasts();
  const { postMastDwg } = usePostDownloadDwg();

  function selectMastPage(page) {
    const projectProps = JSON.parse(projectSelected.props) || {};
    if (!mastSelected.identificador) return toast.error("Selecione o mastro que será editado");
    const mastProps = JSON.parse(mastSelected.props) || {};
    if (page === "modulos" && !mastProps.qtdModulos) return toast.error("É necessário definir o número de módulos do mastro em Geometria");
    if (page === "antenas" && (!projectProps.s1 || !projectProps.s2 || !projectProps.s3 || !projectProps.v0)) return toast.error("É necessário definir os valores de vento para adicionar antenas");
    return setLeftMenuOptionSelected(page);
  }
  useEffect(() => {
    async function fetchAllMastsNames() {
      const masts = await getMasts(projectSelected.id, userData.token);
      setProjectMasts(masts);
    }
    if (projectSelected.id) {
      fetchAllMastsNames();
    }
  }, [projectSelected, reloadProject, projectType, leftMenuOptionSelected]);
  useEffect(() => {
    document.addEventListener("mousemove", handleDragDebounced);
    document.addEventListener("mouseup", handleResizeEnd);
    return () => {
      document.removeEventListener("mousemove", handleDragDebounced);
      document.removeEventListener("mouseup", handleResizeEnd);
    };
  }, [handleDragDebounced]);

  function handleDragStart(e) {
    e?.dataTransfer?.setData("text/plain", e?.target?.id);
  }

  function handleResizeStart(e) {
    e.preventDefault();
    if (e.button === 0) {
      setIsResizing(true);
      setResizeOffsetX(e.clientX);
      setResizeStartWidth(containerRef.current.offsetWidth);
    }
  }

  function handleResizeEnd() {
    setIsResizing(false);
    setResizeHandleRight(-8);
    setResizeHandleWidth(12);
  }

  function handleDrag(e) {
    if (isResizing && e.buttons === 1) {
      const mouseX = e.clientX;
      const resizeDiffX = mouseX - resizeOffsetX;
      let newWidth = (resizeStartWidth + resizeDiffX) / window.innerWidth * 100;
      newWidth = Math.min(55, Math.max(25, newWidth));
      setLeftMenuWidth(`${newWidth}%`);
      setResizeHandleRight(-350);
      setResizeHandleWidth(450);
    }
  }

  async function downloadMastDwg() {
    try {
      console.log("teste");
      const dwg = await postMastDwg(mastSelected.identificador, projectSelected.id, userData.token);
      console.log(dwg);
      if(dwg.type !== "image/vnd.dwg") {
        const errorMsg = await blobToString(dwg);
        return errorMsg.map(error => toast.error(error));
      }
      await blobToDownload(dwg);
      return toast.success("Memorial gerado e baixado com sucesso");
    } catch (error) {
      toast.error("Não foi possivel gerar o dwg, tente novamente mais tarde...");
      console.log(error);
    }
  }

  async function blobToString(dwg) {
    const data = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const jsonData = reader.result.split("\n");
          resolve(jsonData);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = reject;
      reader.readAsText(dwg);
    });
    return data;
  }
    
  async function blobToDownload(memorial) {
    const file = memorial;
    const fileUrl = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = `mastro${userData.name.replace(/\s/g, "")}.dwg`;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(fileUrl);
    toast.success("Download realizado com sucesso");
  }

  useEffect(() => {
    const mastProps = mastSelected?.props ? JSON.parse(mastSelected.props) : {};
    setParsedMastProps(mastProps);
  }, [isUpdatedWithoutCalculation, mastSelected, reloadProject]);

  return (
    <StyledLeftMenu
      isDonwloadingStrap={isDonwloadingStrap}
      ref={containerRef}
      onDragStart={() => {
        setIsResizing(true);
        handleDragStart();
      }}
      onMouseMove={handleDrag}
      width={leftMenuWidth}
    >
      {((leftMenuOptionSelected.length === 0 || leftMenuOptionSelected === "calcular") && !getMastsLoading) ? (
        <>
          <MastNameSelection projectMasts={projectMasts} />
          <SessionTitle opacity={projectMasts?.length ? "1" : "0.4"}>
            <h1>Geral</h1>
            <GeneralDataContainer>
              <GeneralDataSingleBigContainer onClick={() => {
                if (!projectMasts.length) return toast.error("Adicione um mastro ao projeto para poder acessar a página de vento!");
                if (!isDonwloadingStrap) setLeftMenuOptionSelected("vento");
              }}>
                VENTO
              </GeneralDataSingleBigContainer>
            </GeneralDataContainer>
          </SessionTitle>
          <SessionTitle opacity={mastSelected?.identificador ? "1" : "0.4"}>
            <h1>Geometria e Antenas</h1>
            <GeneralDataContainer>
              <GeneralDataSingleMediumContainer onClick={() => selectMastPage("geometria")}>
                GEOMETRIA
              </GeneralDataSingleMediumContainer>
              <GeneralDataSingleMediumContainer onClick={() => selectMastPage("modulos")}>
                MÓDULOS
              </GeneralDataSingleMediumContainer>
            </GeneralDataContainer>
            <GeneralDataContainer>
              <GeneralDataSingleMediumContainer onClick={() => selectMastPage("estais")}>
                ESTAIS
              </GeneralDataSingleMediumContainer>
              <GeneralDataSingleMediumContainer onClick={() => selectMastPage("chumbadores")}>
                CHUMBADORES
              </GeneralDataSingleMediumContainer>
            </GeneralDataContainer>
            <GeneralDataContainer>
              <GeneralDataSingleBigContainer onClick={() => selectMastPage("antenas")}>
                ANTENAS
              </GeneralDataSingleBigContainer>
            </GeneralDataContainer>
          </SessionTitle>
          <SessionTitle opacity={mastSelected?.identificador ? "1" : "0.4"}>
            <h1>Dimensionamento</h1>
            <GeneralDataContainer>
              <GeneralDataSingleBigContainer onClick={() => {
                if (!mastSelected.identificador) return toast.error("Selecione o mastro que será editado");
                const whatIsMissingMessage = (verifyNecessaryProps({ ...JSON.parse(mastSelected.props), ...JSON.parse(projectSelected.props) }));
                if (!whatIsMissingMessage) selectMastPage("calcular");
                else toast.error(`Os seguintes dados ainda precisam serem preenchidos: \n\n ${whatIsMissingMessage}`);
              }}>
                CALCULAR
              </GeneralDataSingleBigContainer>
              <GeneralDataSingleBigContainer onClick={() => { 
                const whatIsMissingMessage = (verifyNecessaryProps({ ...JSON.parse(mastSelected.props), ...JSON.parse(projectSelected.props) }));
                if(!whatIsMissingMessage) downloadMastDwg();
                else toast.error(`Os seguintes dados ainda precisam serem preenchidos: \n\n ${whatIsMissingMessage}`);
              } }>DOWNLOAD DWG</GeneralDataSingleBigContainer>
            </GeneralDataContainer>
            <GeneralDataContainer>
              {((mastSelected?.strapMessage && mastSelected?.strapMessage?.length !== 0) || (parsedMastProps?.strapMessage && parsedMastProps?.strapMessage?.length !== 0))
                && !isUpdatedWithoutCalculation
                && <GeneralDataSingleMediumContainer onClick={() => { selectMastPage("metalica"); }}>
                  METÁLICA
                </GeneralDataSingleMediumContainer>}
              {((mastSelected?.strapMessage && mastSelected?.strapMessage?.length !== 0) || (parsedMastProps?.strapMessage && parsedMastProps?.strapMessage?.length !== 0))
                && !isUpdatedWithoutCalculation
                && <GeneralDataSingleMediumContainer onClick={() => {
                  if (!mastSelected.identificador) return toast.error("Selecione o mastro que será editado");
                  if ((mastSelected?.strapMessage && mastSelected?.strapMessage?.length !== 0) || (parsedMastProps?.strapMessage && parsedMastProps?.strapMessage?.length !== 0)) {
                    selectMastPage("ancoragem");
                  }
                  else toast.error("É necessário calcular a estrutura antes de editar sua ancoragem");
                }}>
                  ANCORAGEM
                </GeneralDataSingleMediumContainer>}
            </GeneralDataContainer>
          </SessionTitle>
        </>
      ) : <LoadingAnimation />}
      <ResizeHandle onMouseDown={handleResizeStart} resizeHandleRight={resizeHandleRight} resizeHandleWidth={resizeHandleWidth} />
    </StyledLeftMenu>
  );
}

const StyledLeftMenu = styled.div`
  position: relative;
  width: ${(props) => props.width};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
  border-right: 1px solid #ffffff;
  background-color: #F2F2F2;
  transition: width 0.2s ease-in-out;
  border-left: #B0b0b0 solid 30px;
  border-top: #B0b0b0 solid 30px;
  pointer-events: ${(props) => (props.isDonwloadingStrap ? "none" : "inherit")};
`;

const ResizeHandle = styled.div`
  position: absolute;
  top: 0;
  right: ${(props) => props.resizeHandleRight}px;
  height: 100%;
  width: ${(props) => props.resizeHandleWidth}px;
  cursor: ew-resize;
`;

export const SessionTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
  opacity: ${(props) => props.opacity};
  h1 {
    font-weight: 500;
    color: #000000;
    font-size: 20px;
    padding-bottom: 8px;
    padding-left: 5px;
  }
`;

export const BottomBorderTitle = styled.div`
  width: 100%;
  height: 1px;
  background-color: #808080;
  margin-bottom: 10px;
`;

export const GeneralDataContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    color: #010101;
    border: solid 1px #C8C8C8;
    margin: 5px;
    transition-duration: 100ms;
    transition-property: border, color;
    background-color: #fff;
    cursor: pointer;
    :hover {
      border: none;
      color: #FFFFFF;
      background-color: #000;
      box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3);
    }
  }
`;

const GeneralDataSingleBigContainer = styled.div`
  width: 100%;
  height: 5vh;
  background-color: #fff;
`;

export const GeneralDataSingleMediumContainer = styled.div`
  width: 47%;
  height: 5vh;
  display: flex;
  line-height: 17px;
  position: relative;
  overflow: hidden;
  align-items: ${(props) => (Number(props.textSize) > 30 ? "flex-start !important" : "center")};

  span {
    color: #7f7f7f;
  }

  :hover {
    ion-icon,
    span {
      color: #F16E00;
      display: inherit;
    }
  }

  ion-icon {
    position: absolute;
    top: 2px;
    right: 2px;
    font-size: 21px;
    display: none;
    z-index: 2;
  }
`;
