import useAsync from "../useAsync";

import * as authApi from "../../services/userApi";

export default function useLogup() {
  const {
    loading: signUpLoading,
    error: signUpError,
    act: signUp
  } = useAsync(({ email, name, password, validationNumber }) => authApi.signUp({ email, name, password, validationNumber }), false);

  return {
    signUpLoading,
    signUpError,
    signUp
  };
}
