import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";

export default function TowerGeometryLeftMenu({ sectionTypes, inputValues, handleInputChange }) {
  const inputConfig = [
    { label: "Altura Total (m)", name: "totalHeight", placeholder: "Informe a altura total", unit: "m" },
    { label: "Largura no Topo (mm)", name: "topWidth", placeholder: "Informe a largura no topo", unit: "mm" },
    { label: "Largura na Base (mm)", name: "baseWidth", placeholder: "Informe a largura na base", unit: "mm" },
    { label: "Nº de Trechos", name: "nSegments", placeholder: "Informe o nº de trechos", unit: "Trechos", toFixed: 0 },
    { label: "Elevação em relação ao solo (m)", name: "elevation", placeholder: "Informe a elevação em relação ao solo", unit: "m" },
  ];

  return (
    <>
      <InputMenu>
        <h2>{"Seção da Torre"}</h2>
        <select
          className="highlightColor"
          name="towerSection"
          value={inputValues.towerSection}
          onChange={handleInputChange}
        >
          <option value={""} key={"towerSection-empty"}>Selecione a seção da Torre</option>
          {sectionTypes.map((section) => (
            <option value={section} key={section}>{section}</option>
          ))}
        </select>
      </InputMenu>

      {inputConfig.map(({ label, name, placeholder, unit, toFixed }) => (
        <InputMenu key={name}>
          <h2>{label}</h2>
          <CustomInput
            placeholder={placeholder}
            name={name}
            value={inputValues[name]}
            onChange={newValue => handleInputChange({ target: { name, value: newValue } })}
            toFixed={toFixed}
            isNumber={true}
            unit={unit}
          />
        </InputMenu>
      ))}
    </>
  );
};
