import useAsync from "../useAsync";
import * as userApi from "../../services/userApi";

export default function useUpdateUserInfo() {
  const {
    data: updatedUserInfo,
    loading: updateUserInfoLoading,
    error: updateUserInfoError,
    act: updateUserInfo,
  } = useAsync((token, data) => userApi.updateUserInfo(token, data), false);

  return {
    updatedUserInfo,
    updateUserInfoLoading,
    updateUserInfoError,
    updateUserInfo
  };
}
