import styled from "styled-components";
import {
  Dialog,
  DialogActions,
} from "@mui/material";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserContext from "../../contexts/UserContext";
import usePostBugReport from "../../hooks/api/usePostBugReport";
import { ProjectContext } from "../../contexts/ProjectContext";

export default function ReportingBugDialog({
  confirmDialog,
  setConfirmDialog,
  setIsMenuOpen
}) {
  const { userData } = useContext(UserContext);
  const { projectSelected } = useContext(ProjectContext);
  const { postBugReport, postBugReportLoading } = usePostBugReport();
  const [inputValues, setInputValues] = useState({
    projectId: projectSelected.id,
    email: "",
    subject: "",
    description: "",
  });
  function handleClosingIconClick() {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setIsMenuOpen(false);
  };

  async function sendBugReport() {
    if(!inputValues.email || !inputValues.subject || !inputValues.description) {
      return toast.error("É necessário preencher todos os itens!");
    };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if(!emailRegex.test(inputValues.email)) return toast.error("O e-mail digitado não é válido!");
    if(!projectSelected.id) projectSelected.id = 999;
    try {
      const body = { projectId: projectSelected.id, email: inputValues.email, subject: inputValues.subject, description: inputValues.description };
      await postBugReport(body, userData.token);
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      setIsMenuOpen(false);
      return toast.success("Relatório enviado com sucesso!");
    } catch (error) {
      return toast.error("Não foi possível enviar o relatório. Revise os dados e tente novamente.");
    }
  };

  return (
    <Dialog open={confirmDialog.isOpen} fullWidth={true} maxWidth="sm">
      <DiolagContainer dialogType={confirmDialog.type}>
        <h1>Reportar bug encontrado no site</h1>
        <SpacingLine />
        <ProjectActionContainer>
          <h2>Digite seu e-mail</h2>
          <input autoFocus type="email" onChange={(e) => setInputValues({ ...inputValues, email: e.target.value })} placeholder="e-mail"></input>
          <h2>Assunto</h2>
          <input type="text"onChange={(e) => setInputValues({ ...inputValues, subject: e.target.value })} placeholder="assunto"></input>
          <h2>Descrição do bug</h2>
          <textarea type="text"onChange={(e) => setInputValues({ ...inputValues, description: e.target.value })} placeholder="bug encontrado"></textarea>
          <GreenConfirmDialogButton onClick={sendBugReport}>
            <p>Enviar bug encontrado</p>
          </GreenConfirmDialogButton>
        </ProjectActionContainer>
        <DialogActions>
          {" "}
        </DialogActions>
        <ion-icon onClick={() => {setConfirmDialog({ ...confirmDialog, isOpen: false }); }} name="close"></ion-icon>
      </DiolagContainer>
    </Dialog>
  );
}

const DiolagContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
  width: 100%;
  background-color: #ffffff;

  h1 {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    width: 92%;

    span {
      font-weight: 500;
    }
  }

  ion-icon {
    position: absolute;
    top: 10px;
    right: 16px;
    font-size: 28px;
    color: #AEAEAE;
    cursor: pointer;

    :active {
      color: #000;
    }
  };
`;

export const ProjectActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  padding: 0 34px 0 10px;
  background-color: #ffffff;
  height: 280px;
  width: 540px;

  h2 {
    color: #666666;
    font-size: 16px;
    margin-bottom: 6px;
  }

  h3 {
    color: #000000;
    font-weight: 500;
    font-size: 20px;
  }

  input,
  select, textarea {
    font-family: 'Roboto', sans-serif;
    height: 4vh;
    width: 106%;
    color: #000000 !important;
    background-color: #fff;
    border: 1px solid #C5C5C5;
    font-size: 16px;
    text-align: left;
    padding-left: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  input::placeholder{
  }
  textarea {
    resize: none;            
    height: 90px;     
    outline: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 10px 0 0 0;
`;

const SpacingLine = styled.div`
  display: flex;
  width: 100%;
  height: 1.35px;
  margin: 12px 0 18px 0;
  background-color: #DDDDDD;
`;

const GreenConfirmDialogButton = styled.div `
  font-size: 15px;
  width: 106%;
  height: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #5FA037;
  border-radius: 4px;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  &:active {
    background: linear-gradient(#333, #333);
  }
  img {
    margin-left: 12px;
  }
`;
