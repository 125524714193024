export const trussesInfo = {
  "Treliça X": {
    1: [
      {
        bracingType: "A",
        hasHorizontal: false,
        redundants: 0,
      }
    ],
    2: [
      {
        bracingType: "A",
        hasHorizontal: true,
        redundants: 0,
      },
      {
        bracingType: "B",
        hasHorizontal: true,
        redundants: 2,
      },
      {
        bracingType: "C",
        hasHorizontal: false,
        redundants: 3,
      }
    ],
    3: [
      {
        bracingType: "A",
        hasHorizontal: false,
        redundants: 5,
      },
      {
        bracingType: "B",
        hasHorizontal: false,
        redundants: 4,
      }
    ],
    4: [
      {
        bracingType: "A",
        hasHorizontal: true,
        redundants: 4,
      },
      {
        bracingType: "B",
        hasHorizontal: true,
        redundants: 5,
      },
      {
        bracingType: "C",
        hasHorizontal: false,
        redundants: 4,
      }
    ],
  },
  "Treliça K": {
    1: [
      {
        bracingType: "A",
        redundants: 0,
      },
      {
        bracingType: "B",
        redundants: 1,
      }
    ],
    2: [
      {
        bracingType: "A",
        redundants: 2,
      },
      {
        bracingType: "B",
        redundants: 3,
      },
      {
        bracingType: "C",
        redundants: 3,
      }
    ],
    3: [
      {
        bracingType: "A",
        redundants: 4,
      },
      {
        bracingType: "B",
        redundants: 5,
      },
      {
        bracingType: "C",
        redundants: 6,
      }
    ],
    4: [
      {
        bracingType: "A",
        redundants: 6,
      },
      {
        bracingType: "B",
        redundants: 7,
      },
      {
        bracingType: "C",
        redundants: 9,
      }
    ],
    5: [
      {
        bracingType: "A",
        redundants: 8,
      },
      {
        bracingType: "B",
        redundants: 12,
      }
    ],
    6: [
      {
        bracingType: "A",
        redundants: 10,
      },
      {
        bracingType: "B",
        redundants: 11,
      }
    ],
  },
  "Treliça V": {
    1: [
      {
        bracingType: "A",
        redundants: 0,
      },
      {
        bracingType: "B",
        redundants: 1,
      },
    ],
    2: [
      {
        bracingType: "A",
        redundants: 2,
      },
      {
        bracingType: "B",
        redundants: 3,
      },
    ],
    3: [
      {
        bracingType: "A",
        redundants: 4,
      },
      {
        bracingType: "B",
        redundants: 6,
      },
    ],
    4: [
      {
        bracingType: "A",
        redundants: 6,
      }
    ],
    5: [
      {
        bracingType: "A",
        redundants: 8,
      }
    ],
    6: [
      {
        bracingType: "A",
        redundants: 10,
      }
    ],
  },
  "Treliça Z": {
    1: [
      {
        bracingType: "A",
        hasHorizontal: false,
        redundants: 0,
      },
    ],
  },
  "Treliça H": {
    1: [
      {
        bracingType: "A",
        hasHorizontal: false,
        hasSuperiorHorizontal: false,
        hasInferiorHorizontal: false,
      },
      {
        bracingType: "B",
        hasHorizontal: false,
        hasSuperiorHorizontal: true,
        hasInferiorHorizontal: false,
      },
    ],
  }
};

export const diagonalLockingsInfo = {
  1: [
    {
      lockingPoints: 1,
      lockingType: "A",
      bars: 1,
      isMissingBar: false, // missingBars: ["T1", "T3"],
    },
    {
      lockingPoints: 1,
      lockingType: "B",
      bars: 3,
      isMissingBar: false,
    },
    {
      lockingPoints: 1,
      lockingType: "C",
      bars: 3,
      isMissingBar: false,
    },
    {
      lockingPoints: 1,
      lockingType: "D",
      bars: 3,
      isMissingBar: false,
    },
  ],
  2: [
    {
      lockingPoints: 2,
      lockingType: "A",
      bars: 2,
      isMissingBar: false,
    },
    {
      lockingPoints: 2,
      lockingType: "B",
      bars: 6,
      isMissingBar: false,
    },
    {
      lockingPoints: 2,
      lockingType: "C",
      bars: 6,
      isMissingBar: false,
    },
    {
      lockingPoints: 2,
      lockingType: "D",
      bars: 6,
      isMissingBar: false,
    },
    {
      lockingPoints: 2,
      lockingType: "E",
      bars: 6,
      isMissingBar: false,
    },
    {
      lockingPoints: 2,
      lockingType: "F",
      bars: 6,
      isMissingBar: false,
    },
  ],
  3: [
    {
      lockingPoints: 3,
      lockingType: "A",
      bars: 3,
      isMissingBar: false,
    },
    {
      lockingPoints: 3,
      lockingType: "B",
      bars: 9,
      isMissingBar: false,
    },
    {
      lockingPoints: 3,
      lockingType: "C",
      bars: 9,
      isMissingBar: false,
    },
    {
      lockingPoints: 3,
      lockingType: "D",
      bars: 9,
      isMissingBar: false,
    },
    {
      lockingPoints: 3,
      lockingType: "E",
      bars: 9,
      isMissingBar: false,
    },
    {
      lockingPoints: 3,
      lockingType: "F",
      bars: 9,
      isMissingBar: false,
    },
    {
      lockingPoints: 3,
      lockingType: "G",
      bars: 9,
      isMissingBar: false,
    },
    {
      lockingPoints: 3,
      lockingType: "H",
      bars: 9,
      isMissingBar: false,
    },
    {
      lockingPoints: 3,
      lockingType: "I",
      bars: 9,
      isMissingBar: false,
    },
  ],
  4: [
    {
      lockingPoints: 4,
      lockingType: "A",
      bars: 4,
      isMissingBar: false,
    },
    {
      lockingPoints: 4,
      lockingType: "B",
      bars: 12,
      isMissingBar: false,
    },
    {
      lockingPoints: 4,
      lockingType: "C",
      bars: 12,
      isMissingBar: false,
    },
    {
      lockingPoints: 4,
      lockingType: "D",
      bars: 12,
      isMissingBar: false,
    },
    {
      lockingPoints: 4,
      lockingType: "E",
      bars: 12,
      isMissingBar: false,
    },
    {
      lockingPoints: 4,
      lockingType: "F",
      bars: 12,
      isMissingBar: false,
    },
    {
      lockingPoints: 4,
      lockingType: "G",
      bars: 12,
      isMissingBar: false,
    },
    {
      lockingPoints: 4,
      lockingType: "H",
      bars: 12,
      isMissingBar: false,
    },
    {
      lockingPoints: 4,
      lockingType: "I",
      bars: 12,
      isMissingBar: false,
    },
  ],
  5: [
    {
      lockingPoints: 5,
      lockingType: "A",
      bars: 5,
      isMissingBar: false,
    },
    {
      lockingPoints: 5,
      lockingType: "B",
      bars: 15,
      isMissingBar: false,
    },
    {
      lockingPoints: 5,
      lockingType: "C",
      bars: 15,
      isMissingBar: false,
    },
    {
      lockingPoints: 5,
      lockingType: "D",
      bars: 15,
      isMissingBar: false,
    },
    {
      lockingPoints: 5,
      lockingType: "E",
      bars: 15,
      isMissingBar: false,
    },
    {
      lockingPoints: 5,
      lockingType: "F",
      bars: 15,
      isMissingBar: false,
    },
    {
      lockingPoints: 5,
      lockingType: "G",
      bars: 15,
      isMissingBar: false,
    },
    {
      lockingPoints: 5,
      lockingType: "H",
      bars: 15,
      isMissingBar: false,
    },
    {
      lockingPoints: 5,
      lockingType: "I",
      bars: 15,
      isMissingBar: false,
    },
    {
      lockingPoints: 5,
      lockingType: "J",
      bars: 15,
      isMissingBar: false,
    },
    {
      lockingPoints: 5,
      lockingType: "K",
      bars: 15,
      isMissingBar: false,
    },
    {
      lockingPoints: 5,
      lockingType: "L",
      bars: 15,
      isMissingBar: false,
    },
  ],
  6: [
    {
      lockingPoints: 6,
      lockingType: "A",
      bars: 6,
      isMissingBar: false,
    },
    {
      lockingPoints: 6,
      lockingType: "B",
      bars: 18,
      isMissingBar: false,
    },
    {
      lockingPoints: 6,
      lockingType: "C",
      bars: 18,
      isMissingBar: false,
    },
    {
      lockingPoints: 6,
      lockingType: "D",
      bars: 18,
      isMissingBar: false,
    },
    {
      lockingPoints: 6,
      lockingType: "E",
      bars: 18,
      isMissingBar: false,
    },
    {
      lockingPoints: 6,
      lockingType: "F",
      bars: 18,
      isMissingBar: false,
    },
    {
      lockingPoints: 6,
      lockingType: "G",
      bars: 18,
      isMissingBar: false,
    },
    {
      lockingPoints: 6,
      lockingType: "H",
      bars: 18,
      isMissingBar: false,
    },
    {
      lockingPoints: 6,
      lockingType: "I",
      bars: 18,
      isMissingBar: false,
    },
    {
      lockingPoints: 6,
      lockingType: "J",
      bars: 18,
      isMissingBar: false,
    },
    {
      lockingPoints: 6,
      lockingType: "K",
      bars: 18,
      isMissingBar: false,
    },
    {
      lockingPoints: 6,
      lockingType: "L",
      bars: 18,
      isMissingBar: false,
    },
    {
      lockingPoints: 6,
      lockingType: "M",
      bars: 18,
      isMissingBar: false,
    },
    {
      lockingPoints: 6,
      lockingType: "N",
      bars: 18,
      isMissingBar: false,
    },
  ],
};

export const horizontalLockingsInfo = {
  "Triangular": [
    {
      lockingType: "2A",
      bars: 3,
      isMissingBar: false,
    },
    {
      lockingType: "2B",
      bars: 6,
      isMissingBar: false,
    },
    {
      lockingType: "4A",
      bars: 9,
      isMissingBar: false,
    },
    {
      lockingType: "4B",
      bars: 18,
      isMissingBar: false,
    },
    {
      lockingType: "4C",
      bars: 6,
      isMissingBar: false,
    },
    {
      lockingType: "4D",
      bars: 9,
      isMissingBar: false,
    },
    {
      lockingType: "4E",
      bars: 9,
      isMissingBar: false,
    },
  ],
  "Quadrada": [
    {
      lockingType: "2A",
      bars: 4,
      isMissingBar: false,
    },
    {
      lockingType: "2B",
      bars: 8,
      isMissingBar: false,
    },
    {
      lockingType: "2C",
      bars: 8,
      isMissingBar: false,
    },
    {
      lockingType: "2D",
      bars: 10,
      isMissingBar: false,
    },
    {
      lockingType: "2E",
      bars: 12,
      isMissingBar: false,
    },
    {
      lockingType: "4A",
      bars: 12,
      isMissingBar: false,
    },
    {
      lockingType: "4B",
      bars: 16,
      isMissingBar: false,
    },
    {
      lockingType: "4C",
      bars: 12,
      isMissingBar: false,
    },
    {
      lockingType: "4D",
      bars: 8,
      isMissingBar: false,
    },
  ],
};
