import { useState, useContext, useRef, useEffect } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import { MenuContext } from "../../contexts/MenuContext";
import { ProjectContext } from "../../contexts/ProjectContext";

export default function ResizableLeftContainer({ children }) {
  const [isResizing, setIsResizing] = useState(false);
  const [resizeHandleRight, setResizeHandleRight] = useState(-8);
  const [resizeHandleWidth, setResizeHandleWidth] = useState(12);
  const [resizeOffsetX, setResizeOffsetX] = useState(0);
  const [resizeStartWidth, setResizeStartWidth] = useState(0);
  const containerRef = useRef(null);
  const { leftMenuWidth, setLeftMenuWidth } = useContext(MenuContext);
  const [handleDragDebounced] = useState(() => debounce(handleDrag, 500));
  const { leftMenuOptionSelected } = useContext(ProjectContext);

  useEffect(() => {
    document.addEventListener("mousemove", handleDragDebounced);
    document.addEventListener("mouseup", handleResizeEnd);
    return () => {
      document.removeEventListener("mousemove", handleDragDebounced);
      document.removeEventListener("mouseup", handleResizeEnd);
    };
  }, [handleDragDebounced]);

  function handleDragStart(e) {
    e?.dataTransfer?.setData("text/plain", e?.target?.id);
  }

  function handleResizeStart(e) {
    e.preventDefault();
    if (e.button === 0) {
      setIsResizing(true);
      setResizeOffsetX(e.clientX);
      setResizeStartWidth(containerRef.current.offsetWidth);
    }
  }

  function handleResizeEnd() {
    setIsResizing(false);
    setResizeHandleRight(-8);
    setResizeHandleWidth(12);
  }

  function handleDrag(e) {
    if (isResizing && e.buttons === 1) {
      const mouseX = e.clientX;
      const resizeDiffX = mouseX - resizeOffsetX;
      let newWidth = (resizeStartWidth + resizeDiffX) / window.innerWidth * 100;
      newWidth = Math.min(55, Math.max(25, newWidth));
      setLeftMenuWidth(`${newWidth}%`);
      setResizeHandleRight(-350);
      setResizeHandleWidth(450);
    }
  }

  return (
    <Container
      shouldIndexBeNone={leftMenuOptionSelected === "vento"}
      ref={containerRef}
      onDragStart={() => {
        setIsResizing(true);
        handleDragStart();
      }}
      onMouseMove={handleDrag}
      width={leftMenuWidth}>
      {children}
      <ResizeHandle onMouseDown={handleResizeStart} resizeHandleRight={resizeHandleRight} resizeHandleWidth={resizeHandleWidth} width={leftMenuWidth} />
    </Container>
  );
};

export const Container = styled.div`
  width: ${(props) => props.width};
  height: calc(100vh - 50px);
  background-color: rgba(242, 242, 242, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px;
  position: relative;
  z-index: ${(props) => (props.shouldIndexBeNone ? "inherit" : 4)};
  border-left: #B0b0b0 solid 30px;
  border-top: #B0b0b0 solid 30px;
`;

const ResizeHandle = styled.div`
  position: absolute;
  top: 0;
  right: ${(props) => props.resizeHandleRight}px;
  width: ${(props) => props.resizeHandleWidth}px; 
  height: 100%;
  cursor: ew-resize;
  z-index: 5;
  overflow: hidden;
`;
