import useAsync from "../useAsync";
import * as mastsApi from "../../services/mastApi";

export default function useCalcAnchorBoltAndAnchorage() {
  const {
    data: response,
    loading: calcAchorBoltAndAnchorageLoading,
    error: calcAnchorBoltAndAnchorageError,
    act: calcAnchorBoltAndAnchorage,
  } = useAsync(({ projectId, identificador }, token) => mastsApi.getAnchorBoltAndAnchorageResult({ projectId, identificador }, token), false);

  return {
    response,
    calcAchorBoltAndAnchorageLoading: calcAchorBoltAndAnchorageLoading,
    calcAnchorBoltAndAnchorageError: calcAnchorBoltAndAnchorageError,
    calcAnchorBoltAndAnchorage: calcAnchorBoltAndAnchorage
  };
}
