import { useEffect, useState } from "react";
import styled from "styled-components";
import { DescriptionContainer, MastGeometryImage } from "../module-components/mast-moduleRightContainer";
import MastGeometryImg from "../geometry-components/mast-geometryImg";
import MastEstaiPositionImg from "./mast-estaiPositionImg";

export default function EstaiRightContainer({ inputValues, mastProps }) {
  const [mastImgId, setMastImgId] = useState("MA_1");
  const [mastImgProps, setMastImgProps] = useState({ paddingTop: "100px", imageSize: "50vh" });
  const [estaiPositions, setEstaiPositions] = useState({ });
  const qtdModulos = Number(mastProps.qtdModulos);
  const posEst1 = Number(mastProps.estai1ModuloAcoplado) || 1;
  const posEst3 = Number(mastProps.estai3ModuloAcoplado) || 1;
  const estaiContainerPositions = { 
    MB: {
      1: { top: 40, left: 6, right: 8 }, 2: { top: 60, left: 9, right: 8.5 }, 3: { top: 60 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 12, right: 12 }, 4: { top: 66 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 13, right: 12 }, 5: { top: 70 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15, right: 13 }, 6: { top: 73 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15, right: 14 }, 7: { top: 74 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 14, right: 14 }, 8: { top: 75 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15.5, right: 14 }, 9: { top: 78 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 16.4, right: 13.5 }, 10: { top: 80 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 14.5, right: 14.5 },
    },
    MC: {
      1: { top1: 26, top2: 46, left1: 6, left2: 6, right1: 4, right2: 4 }, 2: { top1: 46, top2: 62, left1: 5, left2: 8, right1: 2, right2: 5 }, 3: { top1: 56 * (1/posEst1 * 1.35), top2: 69 * (posEst3 !== 1 ? (1/posEst3 * 2) : 1), left1: 10, left2: 7, right1: 10, right2: 7 }, 4: { top1: 60 * (posEst1 > 2 ? 1/posEst1 * 1.6 : 1), top2: 74 * (posEst3 > 2 ? (1/posEst3 * 2.3) : 1), left1: 12.9, left2: 11, right1: 11.3, right2: 10.2 }, 5: { top1: 70 * (posEst1 > 2 ? 1/posEst1 * 1.6 : 1), top2: 79 * (posEst3 > 2 ? (1/posEst3 * 2.3) : 1), left1: 13, left2: 11.5, right1: 11, right2: 10 }, 6: { top1: 69 * (posEst1 > 2 ? 1/posEst1 * 1.8 : 1), top2: 81 * (posEst3 > 2 ? (1/posEst3 * 2.3) : 1), left1: 14, left2: 12, right1: 13, right2: 12 }, 7: { top1: 70 * (posEst1 > 2 ? 1/posEst1 * 2 : 1), top2: 82 * (posEst3 > 2 ? (1/posEst3 * 2.6) : 1), left1: 13, left2: 12, right1: 13, right2: 12.5 }, 8: { top1: 73 * (posEst1 > 2 ? 1/posEst1 * 1.6 : 1), top2: 78 * (posEst3 > 2 ? (1/posEst3 * 2.9) : 1), left1: 13, left2: 13, right1: 13, right2: 12 }, 9: { top1: 75 * (posEst1 > 2 ? 1/posEst1 * 2.6 : 1), top2: 80 * (posEst3 > 2 ? (1/posEst3 * 3.6) : 1), left1: 13, left2: 12, right1: 13, right2: 12 }, 10: { top1: 75 * (posEst1 > 2 ? 1/posEst1 * 3 : 1), top2: 78 * (posEst3 > 2 ? (1/posEst3 * 4) : 1), left1: 14, left2: 12, right1: 14, right2: 12 },
    },
    MD: {
      1: { top: 40, left: 6, right: 2 }, 2: { top: 60, left: 9, right: 8.5 }, 3: { top: 60 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 12, right: 12 }, 4: { top: 66 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 13, right: 12 }, 5: { top: 70 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15, right: 13 }, 6: { top: 73, left: 15, right: 14 }, 7: { top: 74 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15, right: 14.6 }, 8: { top: 77 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15.5, right: 14 }, 9: { top: 80 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 16.4, right: 13.5 }, 10: { top: 82 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 14.5, right: 14.5 },
    },
    ME: {
      1: { top: 40, left: 6, right: 2 }, 2: { top: 60, left: 9, right: 8.5 }, 3: { top: 60 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 12, right: 12 }, 4: { top: 66 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 13, right: 12 }, 5: { top: 70 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15, right: 13 }, 6: { top: 73 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15, right: 14 }, 7: { top: 74 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15, right: 14.6 }, 8: { top: 77 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15.5, right: 14 }, 9: { top: 80 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 16.4, right: 13.5 }, 10: { top: 82 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 14.5, right: 14.5 },
    },
    MF: {
      1: { top: 40, left: 6, right: 2 }, 2: { top: 60, left: 9, right: 8.5 }, 3: { top: 60 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 12, right: 12 }, 4: { top: 66 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 13, right: 12 }, 5: { top: 70 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15, right: 13 }, 6: { top: 73 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15, right: 14 }, 7: { top: 74 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15, right: 14.6 }, 8: { top: 77 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15.5, right: 14 }, 9: { top: 80 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 16.4, right: 13.5 }, 10: { top: 82 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 14.5, right: 14.5 },
    },
    MG: {
      1: { top: 40, left: 6, right: 2 }, 2: { top: 60, left: 9, right: 8.5 }, 3: { top: 60 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 12, right: 12 }, 4: { top: 66 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 13, right: 12 }, 5: { top: 70 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15, right: 13 }, 6: { top: 73 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15, right: 14 }, 7: { top: 74 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15, right: 14.6 }, 8: { top: 77 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 15.5, right: 14 }, 9: { top: 80 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 16.4, right: 13.5 }, 10: { top: 82 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), left: 14.5, right: 14.5 },
    },
    MH: {
      1: { top: 35, right: 11 }, 2: { top: 60, right: 11 }, 3: { top: 65 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), right: 14 }, 4: { top: 70 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), right: 16 }, 5: { top: 73 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), right: 16 }, 6: { top: 76 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), right: 17 }, 7: { top: 77 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), right: 18.5 }, 8: { top: 78.5 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), right: 19 }, 9: { top: 79 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), right: 20 }, 10: { top: 80 * (posEst1 > 2 ? (1/posEst1 * 2) : 1), right: 19 },
    },
    MI: {
      1: { top1: 28, top2: 63, left: 13 }, 2: { top1: 45 * (1.44 - posEst1/qtdModulos), top2: 70 * (1.55 - posEst3/qtdModulos), left: 13 }, 3: { top1: 60 * (1.25 - posEst1/qtdModulos), top2: 78 * (1.3 - posEst3/qtdModulos), left: 16 }, 4: { top1: 65 * (1.15 - posEst1/qtdModulos), top2: 81 * (1.3 - posEst3/qtdModulos), left: 17 }, 5: { top1: 70 * (1.12 - posEst1/qtdModulos), top2: 84 * (1.16 - posEst3/qtdModulos), left: 16 }
    },
  };

  useEffect(() => {
    const type = mastProps.tipoMastro;
    const modulesNum = Number(mastProps.qtdModulos);
    function defineMastImgId() {
      if (type === "MA") {
        setMastImgId(`${type}_${modulesNum}`);
      }
      else if (type === "MC") {
        if (modulesNum == 1) return setMastImgId(`${type}_1`);
        const positionEstai1 = (mastProps.estai1ModuloAcoplado && Number(mastProps.estai1ModuloAcoplado) <= modulesNum ) ? Number(mastProps.estai1ModuloAcoplado) : 1;
        const positionEstai3 = (mastProps.estai3ModuloAcoplado && Number(mastProps.estai3ModuloAcoplado) <= modulesNum ) ? Number(mastProps.estai3ModuloAcoplado) : 1;
        if (positionEstai1 > modulesNum || positionEstai3 > modulesNum || positionEstai3 > positionEstai1) {
          if (!mastImgId) setMastImgId(`${type}_1`);
          return;
        } 
        const imageId = `${type}_${modulesNum}_${positionEstai1}_${positionEstai3}` !== `${type}_1_1_1` ? `${type}_${modulesNum}_${positionEstai1}_${positionEstai3}` : `${type}_1`;
        setMastImgId(imageId);
      }
      else if (type === "MI") {
        if (modulesNum == 1) return setMastImgId(`${type}_1_1`);
        const positionEstai1 = (mastProps.estai1ModuloAcoplado && Number(mastProps.estai1ModuloAcoplado) <= modulesNum ) ? Number(mastProps.estai1ModuloAcoplado) : 1;
        const positionEstai3 = (mastProps.estai3ModuloAcoplado && Number(mastProps.estai3ModuloAcoplado) <= modulesNum ) ? Number(mastProps.estai3ModuloAcoplado) : 1;
        if (positionEstai1 > modulesNum || positionEstai3 > modulesNum || positionEstai3 > positionEstai1) {
          if (!mastImgId) setMastImgId(`${type}_1_1`);
          return;
        } 
        const imageId = `${type}_${modulesNum}_${positionEstai1}_${positionEstai3}` !== `${type}_1_1_1` ? `${type}_${modulesNum}_${positionEstai1}_${positionEstai3}` : `${type}_1_1`;
        setMastImgId(imageId);
      }
      else {
        const positionEstai1 = (mastProps.estai1ModuloAcoplado && mastProps.estai1ModuloAcoplado <= modulesNum ) ? mastProps.estai1ModuloAcoplado : 1;
        if (positionEstai1 > modulesNum) return;
        const imageId = `${type}_${positionEstai1}_${modulesNum}`;
        setMastImgId(imageId);
      }
    }
    function defineMastEstaiPositionImgId() {
      const previousMastImgProps = { ...mastImgProps };
      if (Number(mastProps.qtdModulos) === 1) {
        previousMastImgProps.paddingTop = "100px";
        previousMastImgProps.imageSize = "50vh";
        setMastImgProps(previousMastImgProps);
      }
      else if (Number(mastProps.qtdModulos) === 2) {
        previousMastImgProps.paddingTop = "40px";
        previousMastImgProps.imageSize = "55vh";
        setMastImgProps(previousMastImgProps);
      }
      else if (Number(mastProps.qtdModulos) < 5) {
        previousMastImgProps.paddingTop = "0px";
        previousMastImgProps.imageSize = "60vh";
        setMastImgProps(previousMastImgProps);
      }
      else if (Number(mastProps.qtdModulos) < 8) {
        previousMastImgProps.paddingTop = "0px";
        previousMastImgProps.imageSize = "65vh";
        setMastImgProps(previousMastImgProps);
      }
      else {
        previousMastImgProps.paddingTop = "0px";
        previousMastImgProps.imageSize = "70vh";
        setMastImgProps(previousMastImgProps);
      }
    }
    defineMastImgId();
    defineMastEstaiPositionImgId();
  }, []);

  useEffect(() => {
    let estai1X1 = 0;
    let estai2X2 = 0;
    let estai3X1 = 0;
    let estai4X2 = 0;

    if (mastProps.tipoMastro !== "MH") {
      estai1X1 = inputValues["estai1LocacaoX1"] || mastProps.estai1LocacaoX1 || 0;
      estai2X2 = inputValues["estai2LocacaoX2"] || mastProps.estai2LocacaoX2 || 0;
      estai3X1 = inputValues["estai3LocacaoX1"] || mastProps.estai3LocacaoX1 || 0;
      estai4X2 = inputValues["estai4LocacaoX2"] || mastProps.estai4LocacaoX2 || 0;
    }
    else {
      estai1X1 = inputValues["estai1LocacaoX2"] || mastProps.estai1LocacaoX2;
    }
    setEstaiPositions({ estai1X1: `${Number(estai1X1).toFixed(2)} m`, estai2X2: `${Number(estai2X2).toFixed(2)} m`, estai3X1: `${Number(estai3X1).toFixed(2)} m`, estai4X2: `${Number(estai4X2).toFixed(2)} m` });
  }, [inputValues]);
  return (
    <>
      {mastProps.tipoMastro !== "MA" && <EstaiGeometryImgContainer imageObjectFit="cover" paddingTop={mastImgProps.paddingTop} imageSize={mastImgProps.imageSize}>
        <div>
          <DescriptionContainer>Definição dos Estais</DescriptionContainer>
          <MastGeometryImgPositionalContainer>
            <MastGeometryImage>
              <MastGeometryImg type={mastProps.tipoMastro} imgId={mastImgId}/>
            </MastGeometryImage>
            {(mastProps.tipoMastro === "MB" || mastProps.tipoMastro === "MD" || mastProps.tipoMastro === "ME" || mastProps.tipoMastro === "MF" || mastProps.tipoMastro === "MG") &&
              <>
                <LeftEstaiDescriptionContainer top={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].top}%`} left={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].left * 0.8}%`} color={Number(inputValues.estaiSelected) === 1 ? "#F16E00" : "#D0CECE"}>
                  <p>E1 - H = { inputValues.estai1AlturaFixacaoMastro || mastProps.estai1AlturaFixacaoMastro}m</p>
                  <p>{inputValues.estai1TipoPerfilCantoneira || mastProps.estai1TipoPerfilCantoneira}</p>
                  <p>{inputValues.estai1ParafusosNumero || mastProps.estai1ParafusosNumero} x {inputValues.estai1ParafusosDiametro || mastProps.estai1ParafusosDiametro}</p>
                  <div></div>
                </LeftEstaiDescriptionContainer>
                <RigthEstaiDescriptionContainer top={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].top }%`} right={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].right * 0.8}%`} color={Number(inputValues.estaiSelected) === 2 ? "#F16E00" : "#D0CECE"}>
                  <p>E2 - H = { inputValues.estai2AlturaFixacaoMastro || mastProps.estai2AlturaFixacaoMastro}m</p>
                  <p>{inputValues.estai2TipoPerfilCantoneira || mastProps.estai2TipoPerfilCantoneira}</p>
                  <p>{inputValues.estai2ParafusosNumero || mastProps.estai2ParafusosNumero} x {inputValues.estai2ParafusosDiametro || mastProps.estai2ParafusosDiametro}</p>
                  <div></div>
                </RigthEstaiDescriptionContainer>
              </>
            }
            {(mastProps.tipoMastro === "MC") &&
              <>
                <LeftEstaiDescriptionContainer top={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].top1}%`} left={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].left1}%`} color={Number(inputValues.estaiSelected) === 1 ? "#F16E00" : "#D0CECE"}>
                  <p>E1 - H = { inputValues.estai1AlturaFixacaoMastro || mastProps.estai1AlturaFixacaoMastro}m</p>
                  <p>{inputValues.estai1TipoPerfilCantoneira || mastProps.estai1TipoPerfilCantoneira}</p>
                  <p>{inputValues.estai1ParafusosNumero || mastProps.estai1ParafusosNumero} x {inputValues.estai1ParafusosDiametro || mastProps.estai1ParafusosDiametro}</p>
                  <div></div>
                </LeftEstaiDescriptionContainer>
                <RigthEstaiDescriptionContainer top={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].top1}%`} right={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].right1}%`} color={Number(inputValues.estaiSelected) === 2 ? "#F16E00" : "#D0CECE"}>
                  <p>E2 - H = { inputValues.estai2AlturaFixacaoMastro || mastProps.estai2AlturaFixacaoMastro}m</p>
                  <p>{inputValues.estai2TipoPerfilCantoneira || mastProps.estai2TipoPerfilCantoneira}</p>
                  <p>{inputValues.estai2ParafusosNumero || mastProps.estai2ParafusosNumero} x {inputValues.estai2ParafusosDiametro || mastProps.estai2ParafusosDiametro}</p>
                  <div></div>
                </RigthEstaiDescriptionContainer>
                <LeftEstaiDescriptionContainer top={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].top2}%`} left={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].left2}%`} color={Number(inputValues.estaiSelected) === 3 ? "#F16E00" : "#D0CECE"}>
                  <p>E3 - H = { inputValues.estai3AlturaFixacaoMastro || mastProps.estai3AlturaFixacaoMastro}m</p>
                  <p>{inputValues.estai3TipoPerfilCantoneira || mastProps.estai3TipoPerfilCantoneira}</p>
                  <p>{inputValues.estai3ParafusosNumero || mastProps.estai3ParafusosNumero} x {inputValues.estai3ParafusosDiametro || mastProps.estai3ParafusosDiametro}</p>
                  <div></div>
                </LeftEstaiDescriptionContainer>
                <RigthEstaiDescriptionContainer top={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].top2}%`} right={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].right2}%`} color={Number(inputValues.estaiSelected) === 4 ? "#F16E00" : "#D0CECE"}>
                  <p>E4 - H = { inputValues.estai4AlturaFixacaoMastro || mastProps.estai4AlturaFixacaoMastro}m</p>
                  <p>{inputValues.estai4TipoPerfilCantoneira || mastProps.estai4TipoPerfilCantoneira}</p>
                  <p>{inputValues.estai4ParafusosNumero || mastProps.estai4ParafusosNumero} x {inputValues.estai4ParafusosDiametro || mastProps.estai4ParafusosDiametro}</p>
                  <div></div>
                </RigthEstaiDescriptionContainer>
              </>
            }
            {(mastProps.tipoMastro === "MH") &&
              <RigthEstaiDescriptionContainer top={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].top}%`} right={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].right}%`} color={Number(inputValues.estaiSelected) === 1 ? "#F16E00" : "#D0CECE"}>
                <p>E1 - H = { inputValues.estai1AlturaFixacaoMastro || mastProps.estai1AlturaFixacaoMastro}m</p>
                <p>{inputValues.estai1TipoPerfilCantoneira || mastProps.estai1TipoPerfilCantoneira}</p>
                <p>{inputValues.estai1ParafusosNumero || mastProps.estai1ParafusosNumero} x {inputValues.estai1ParafusosDiametro || mastProps.estai1ParafusosDiametro}</p>
                <div></div>
              </RigthEstaiDescriptionContainer>
            }
            {(mastProps.tipoMastro === "MI") &&
              <>
                <RigthEstaiDescriptionContainer top={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].top1}%`} right={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].left}%`} color={Number(inputValues.estaiSelected) === 1 ? "#F16E00" : "#D0CECE"}>
                  <p>E1 - H = { inputValues.estai1AlturaFixacaoMastro || mastProps.estai1AlturaFixacaoMastro}m</p>
                  <p>{inputValues.estai1TipoPerfilCantoneira || mastProps.estai1TipoPerfilCantoneira}</p>
                  <p>{inputValues.estai1ParafusosNumero || mastProps.estai1ParafusosNumero} x {inputValues.estai1ParafusosDiametro || mastProps.estai1ParafusosDiametro}</p>
                  <div></div>
                </RigthEstaiDescriptionContainer>
                <RigthEstaiDescriptionContainer top={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].top2}%`} right={`${estaiContainerPositions[mastProps.tipoMastro][mastProps.qtdModulos].left}%`} color={Number(inputValues.estaiSelected) === 2 ? "#F16E00" : "#D0CECE"}> 
                  <p>E2 - H = { inputValues.estai2AlturaFixacaoMastro || mastProps.estai2AlturaFixacaoMastro}m</p>
                  <p>{inputValues.estai2TipoPerfilCantoneira || mastProps.estai2TipoPerfilCantoneira}</p>
                  <p>{inputValues.estai2ParafusosNumero || mastProps.estai2ParafusosNumero} x {inputValues.estai2ParafusosDiametro || mastProps.estai2ParafusosDiametro}</p>
                  <div></div>
                </RigthEstaiDescriptionContainer>
              </>
            }
          </MastGeometryImgPositionalContainer>
        </div>
        <div>
          <MastEstaiPositionContainer>
            <PositionDescriptionContainer>Posição dos Estais</PositionDescriptionContainer>
            <MastEstaiPositionImg type={mastProps.tipoMastro}></MastEstaiPositionImg>
            {(mastProps.tipoMastro === "MB" || mastProps.tipoMastro === "MD" || mastProps.tipoMastro === "ME" || mastProps.tipoMastro === "MF" || mastProps.tipoMastro === "MG") && 
            <>
              <EstaiCoordenatesContainer top={"21.5%"} left={"24%"} color={Number(inputValues.estaiSelected) === 1 ? "#F16E00" : "#000000"}>{estaiPositions.estai1X1}</EstaiCoordenatesContainer>
              <EstaiCoordenatesContainer rotate={true} top={"62.5%"} right={"-0.2vw"} left={"75%"} color={Number(inputValues.estaiSelected) === 2 ? "#F16E00" : "#000000"}>{estaiPositions.estai2X2}</EstaiCoordenatesContainer>
            </>}
            {(mastProps.tipoMastro === "MC") && 
            <>
              <EstaiCoordenatesContainer top={"18.5%"} left={"25%"} fontSize={"17px"} color={Number(inputValues.estaiSelected) === 1 ? "#F16E00" : "#000000"}>{estaiPositions.estai1X1}</EstaiCoordenatesContainer>
              <EstaiCoordenatesContainer  rotate={true} top={"53.5%"} right={"-0.1vw"} left={"68.75%"} fontSize={"17px"} color={Number(inputValues.estaiSelected) === 2 ? "#F16E00" : "#000000"}>{estaiPositions.estai4X2}</EstaiCoordenatesContainer>
              <EstaiCoordenatesContainer top={"27%"} left={"36.5%"} fontSize={"17px"} color={Number(inputValues.estaiSelected) === 3 ? "#F16E00" : "#000000"}>{estaiPositions.estai3X1} </EstaiCoordenatesContainer>
              <EstaiCoordenatesContainer  rotate={true} top={"66%"} right={"-0.1vw"} left={"78.95%"} fontSize={"17px"} color={Number(inputValues.estaiSelected) === 4 ? "#F16E00" : "#000000"}>{estaiPositions.estai2X2}</EstaiCoordenatesContainer>
            </>}
            {(mastProps.tipoMastro === "MH") && 
              <EstaiCoordenatesContainer rotate={true} top={"56%"} right={"-0.1vw"} left={"54.5%"} color={Number(inputValues.estaiSelected) === 1 ? "#F16E00" : "#000000"}>{estaiPositions.estai1X1}</EstaiCoordenatesContainer>
            }
            {(mastProps.tipoMastro === "MI") && 
              <EstaiCoordenatesContainer rotate={true} top={"53%"} right={"-0.1vw"} left={"75.5%"} fontSize={"17px"} color={Number(inputValues.estaiSelected) === 1 ? "#F16E00" : "#000000"}>{estaiPositions.estai1X1}</EstaiCoordenatesContainer>
            }
          </MastEstaiPositionContainer>
        </div>
      </EstaiGeometryImgContainer>}
    </>
  );
};

const EstaiGeometryImgContainer = styled.div`
  display: flex;
  height: calc(100vh - 550px);
  align-items: flex-start;
  justify-content: space-around;
  max-width: 75%;
  margin-left: 370px;
  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 26vw;
  }
  >div:nth-child(2) {
    margin-left: 10vw;
  }
`;

const MastEstaiPositionContainer = styled.div`
  position: relative;
  width: 24vw !important;
  img {
    width: 24vw !important;
    height: 24vw !important;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    margin-top: 20px;
    border-radius: 16px;
  }
`;

const MastGeometryImgPositionalContainer = styled.div`
  position: relative;
`;

const LeftEstaiDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 200px;
  height: 80px;
  background-color: #ffffff;
  border: 1px solid;
  border-color: ${(props) => props.color};
  border-radius: 10px;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  div {
    position: absolute;
    top: 50%;
    right: -50px;
    width: 50px;
    height: 2px;
    background-color: ${(props) => props.color};
  }
  p {
    padding: 3px 0;
  }
`;

const RigthEstaiDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 200px;
  height: 80px;
  background-color: #ffffff;
  border: 1px solid;
  border-color: ${(props) => props.color};
  border-radius: 10px;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  div {
    position: absolute;
    top: 50%;
    left: -50px;
    width: 50px;
    height: 2px;
    background-color: ${(props) => props.color};
  }
  p {
    padding: 3px 0;
  }
`;

const EstaiCoordenatesContainer = styled.p`
  position: absolute;
  transform: ${(props) => (props.rotate ? "rotate(270deg)" : "rotate(0deg)")};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "20px")};
  min-width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.color};
`;

const PositionDescriptionContainer = styled.div`
    width: 24vw !important;
    height: 50px;
    background-color: #ffffff;
    color: #000000;
    font-size: 21px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
`;
