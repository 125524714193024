import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { MenuContext } from "../../contexts/MenuContext";

export default function ResizableRightContainer({ children }) {
  const { leftMenuWidth } = useContext(MenuContext);
  const [rightMenuWidth, setRightMenuWidth] = useState(`${100 - Number(leftMenuWidth.replace("%", ""))}%`);
  useEffect(() => {
    setRightMenuWidth(`${100 - Number(leftMenuWidth.replace("%", ""))}%`);
  }, [leftMenuWidth]);
  return (
    <Container width={rightMenuWidth}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: ${(props) => props.width};
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  background-color: #ffffff;
  border-right: #B0b0b0 solid 30px;
  border-top: #B0b0b0 solid 30px;
  overflow: hidden;
`;
