import useAsync from "../../useAsync";
import { getPlatforms, postPlatforms, updatePlatforms } from "../../../services/tower-services/towerPlatformsApi";

export const useTowerPlatforms = (action) => {
  const execute = {
    get: getPlatforms,
    post: postPlatforms,
    update: updatePlatforms,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
