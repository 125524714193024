import { useState } from "react";
import styled from "styled-components";
import filtering_icon from "../../../assets/images/filtering-icon.svg";

export default function ProjectFilteringBox({ setProjectFilterType, setProjectSearchingTerm }) {
  const [isOn, setIsOn] = useState(false);
  function handleFilteringClick(projectFilterType) {
    setProjectFilterType(projectFilterType);
    setIsOn(false);
  }
  return (
    <ProjectFilteringContainer onMouseEnter={() => setIsOn(true)} onMouseLeave={() => setIsOn(false)}>
      <img src={filtering_icon} alt="filtering icon"/>
      <p>Filtrar</p>
      <ProjectFilteringOptionsContainer isOn={isOn}>
        <ProjectFilteringOption onClick={() => handleFilteringClick("name")}>Nome</ProjectFilteringOption>
        <ProjectFilteringOption onClick={() => handleFilteringClick("user")}>Criado por</ProjectFilteringOption>
        <ProjectFilteringOption onClick={() => handleFilteringClick("date")}>Data</ProjectFilteringOption>
        <ProjectFilteringOption onClick={() => {
          handleFilteringClick("");
          setProjectSearchingTerm("");
        }}>Limpar Filtro</ProjectFilteringOption>
      </ProjectFilteringOptionsContainer>
    </ProjectFilteringContainer>
  );
};

const ProjectFilteringContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 10;
  top: 22px;
  right: calc(10px + 250px + 14px);
  width: 92px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #C5C5C5;
  font-size: 16px;
  text-align: left;
  padding-left: 10px;
  border-radius: 4px;
  color: #808080;
  cursor: pointer;
  img {
    width: 20px;
    margin-right: 8px;
  }
  @media (max-height: 720px) {
    top: 14px;
  }
`;

const ProjectFilteringOptionsContainer = styled.div`
  display: ${(props) => props.isOn ? "flex" : "none"};
  flex-direction: column;
  justify-content: space-evenly;
  width: 170px;
  height: 160px;
  position: absolute;
  top: 39px;
  left: 0;
  border: 1px solid #C5C5C5;
  background-color: #ffffff;
  border-radius: 0px 0px 10px 10px;
`;

const ProjectFilteringOption = styled.div`
  display: flex;
  width: 100%;
  text-align: center;
  padding: 12px 0 12px 12px;
  color: #333333;
  :nth-child(4) {
    border-radius: 0px 0px 10px 10px;
  }
  :hover {
    background-color: #c7c7c7;
  }
`;
