import useAsync from "../../useAsync";
import { getDiagonalLockings, postDiagonalLockings, updateDiagonalLockings } from "../../../services/tower-services/towerDiagonalLockingsApi";

export const useTowerDiagonalLockings = (action) => {
  const execute = {
    get: getDiagonalLockings,
    post: postDiagonalLockings,
    update: updateDiagonalLockings,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
