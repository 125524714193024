import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";

class PlatformModel {
  constructor(
    platformNumber = "",
    platformType = "",
    platformElevation = ""
  ) {
    this.platformNumber = platformNumber;
    this.platformType = platformType;
    this.platformElevation = platformElevation;
  }

  updateField(field, value) {
    this[field] = value;
  }
}

class PlatformsModel {
  constructor(platforms = []) {
    this.platforms = platforms.map(platform => new PlatformModel(
      platform.platformNumber,
      platform.platformType,
      platform.platformElevation
    ));
  }

  updatePlatform(platformNumber, field, value) {
    const platform = this.platforms.find(platform => platform.platformNumber === platformNumber);

    if (platform && platform[field] !== value) {
      platform.updateField(field, value);
    }
  }

  validate(geometryTotalHeight) {
    let errors = [];

    this.platforms.forEach((platform, index) => {
      if (!platform.platformType) errors.push(`Tipo da plataforma ${index + 1}`);
      if (platform.platformElevation === "")
        errors.push(`Elevação da plataforma ${index + 1}`);
      if (platform.platformElevation > geometryTotalHeight)
        errors.push(
          `A elevação da plataforma ${index + 1} deve ser menor que a altura total da torre`
        );
    });

    if (this.platforms.length > 10) errors.push("Deve haver no máximo 10 plataformas");
    if (this.platforms.length < 1) errors.push("Deve haver no mínimo 1 plataforma");

    const elevations = this.platforms.map(platform => platform.platformElevation);
    const duplicateElevations = elevations.filter((elevation, index, self) =>
      self.indexOf(elevation) !== index
    );

    if (duplicateElevations.length > 0) {
      duplicateElevations.forEach((elevation) => {
        const platformsWithSameElevation = this.platforms
          .filter(platform => platform.platformElevation === elevation)
          .map(platform => platform.platformNumber);
        errors.push(`As plataformas ${platformsWithSameElevation.join(" e ")} possuem a mesma elevação de ${elevation}m`);
      });
    }

    if (errors.length > 0) {
      const errorMessage = customToastError(
        errors,
        "Preencha todos os valores corretamente para salvar as informações das plataformas. Falta(m):"
      );
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  toJSON() {
    return JSON.stringify(this.platforms);
  }

  static fromJSON(json) {
    const platformsArray = JSON.parse(json);
    return new PlatformsModel(platformsArray);
  }
}

export default PlatformsModel;
