import platformExternalSquared from "./platform-external-squared.png";
import platformInternalSquared from "./platform-internal-squared.png";
import platformExternalTriangular from "./platform-external-triangular.png";
import platformInternalTriangular from "./platform-internal-triangular.png";

const platformImages = {
  platformExternalSquared,
  platformInternalSquared,
  platformExternalTriangular,
  platformInternalTriangular,
};

export default platformImages;
