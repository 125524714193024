import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import colors from "../../../constants/color-constants";
/* global google */
import { GoogleMap, Marker, useJsApiLoader, Autocomplete } from "@react-google-maps/api";

const libraries = ["places"];

export default function TowerWindRightContainer({
  inputValues,
  handleInputChange,
  handleLocationChange,
  googleMapsApiKey,
  towerLeftMenuWidth,
}) {
  const mapRef = useRef(null);
  const autocompleteInputRef = useRef(null);
  const kmlLayerRef = useRef(null);
  const kml2LayerRef = useRef(null);

  const DEFAULT_ZOOM_LEVEL = 5;
  const DEFAULT_LATITUDE = Number(inputValues.coordinates?.split(" ")[0]) || -14.235004;
  const DEFAULT_LONGITUDE = Number(inputValues.coordinates?.split(" ")[1]) || -51.925280;

  const [map, setMap] = useState(null);
  const [mapType, setMapType] = useState("Mapa");
  const [mapCenter, setMapCenter] = useState({ lat: DEFAULT_LATITUDE, lng: DEFAULT_LONGITUDE });
  const [markerPosition, setMarkerPosition] = useState(null);
  const [shouldUpdateCenter, setShouldUpdateCenter] = useState(true);
  const [updateMapWithCoordinates, setUpdateMapWithCoordinates] = useState(
    { shouldUpdate: false, lat: "", long: "" });
  const [autocomplete, setAutocomplete] = useState(null);
  const [isKmlLayerVisible, setIsKmlLayerVisible] = useState(false);
  const [isopletaMaxWind, setIsopletaMaxWind] = useState("");

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey,
    libraries
  });

  const onLoad = useCallback((map) => {
    mapRef.current = map;
    setMap(map);
  }, []);

  useEffect(() => {
    if (updateMapWithCoordinates.shouldUpdate) {
      const coordinates = { lat: Number(updateMapWithCoordinates.lat), lng: Number(updateMapWithCoordinates.long) };
      setMapCenter(coordinates);
      setMarkerPosition(coordinates);
      setUpdateMapWithCoordinates({ ...updateMapWithCoordinates, shouldUpdate: false });
    }
  }, [updateMapWithCoordinates]);

  useEffect(() => {
    if (map && markerPosition && shouldUpdateCenter) {
      map.panTo(markerPosition);
      map.setZoom(19);
      setShouldUpdateCenter(false);
    }
  }, [map, markerPosition]);

  useEffect(() => {
    setShouldUpdateCenter(true);
  }, [inputValues.location, inputValues.coordinates]);

  useEffect(() => {
    if (mapType === "Isopletas" && isKmlLayerVisible) {
      loadKmlLayer();
    } else {
      if (kmlLayerRef.current) {
        kmlLayerRef.current.setMap(null);
        kmlLayerRef.current = null;
      }
      if (kml2LayerRef.current) {
        kml2LayerRef.current.setMap(null);
        kml2LayerRef.current = null;
      }
    }
  }, [mapType, isKmlLayerVisible]);

  const onMapClick = useCallback((event) => {
    const latLng = event.latLng;
    const geocoder = new google.maps.Geocoder();
    setMarkerPosition({ lat: latLng.lat(), lng: latLng.lng() });

    geocoder.geocode({ "location": latLng }, function(results, status) {
      if (status !== "OK") {
        console.log("Geocoder falhou devido a: " + status);
        toast.error("Nenhum resultado encontrado");
        setShouldUpdateCenter(false);
        return;
      }

      if (results[1] && results[1].formatted_address) {
        handleLocationChange(results[1].formatted_address,
          `${latLng.lat().toFixed(5)}, ${latLng.lng().toFixed(5)}`);
      } else {
        toast.error("Nenhum resultado encontrado");
      }
    });
    setShouldUpdateCenter(false);
  }, []);

  const loadKmlLayer = useCallback(() => {
    if (!map || !window.google) return;

    const kmlUrl = "https://raw.githubusercontent.com/KKEngenharia/google-earth-isopletas/main/ISOPLETAS_GEO_WGS84_v8.kml";
    const kmlStatesUrl = "https://raw.githubusercontent.com/KKEngenharia/google-earth-isopletas/main/brazilian-states_v2.kml";

    const kmlLayer = new window.google.maps.KmlLayer({
      url: kmlUrl,
      map: map,
      preserveViewport: true,
      suppressInfoWindows: false,
      clickable: true
    });

    const kmlLayer2 = new window.google.maps.KmlLayer({
      url: kmlStatesUrl,
      map: map,
      preserveViewport: true,
      suppressInfoWindows: false,
      clickable: false
    });

    kmlLayer.addListener("click", (event) => {
      const latLng = event.latLng;
      if (event.featureData.name) {
        setIsopletaMaxWind(event.featureData.name.slice(-2));
      }
      map.panTo(latLng);
      setShouldUpdateCenter(false);
      setMarkerPosition({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });

      handleInputChange({ target: { name: "coordinates",
        value: `${latLng.lat().toFixed(5)}, ${latLng.lng().toFixed(5)}` } });

      // if (autocompleteInputRef.current) {
      //   autocompleteInputRef.current.placeholder = `${latLng.lat().toFixed(5)}, ${latLng.lng().toFixed(5)}`;
      // }
      setShouldUpdateCenter(false); // Impede a atualização do centro do mapa
    });

    kmlLayerRef.current = kmlLayer;
    kml2LayerRef.current = kmlLayer2;
  }, [map, inputValues.coordinates, mapType]);

  const onAutocompleteLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
    autocomplete.setBounds({ east: -30, west: -70, north: 5, south: -35 });
  };

  const onPlaceChanged = (e) => {
    if (markerPosition) setMarkerPosition(null);

    if (!autocomplete) {
      return toast.error("O local digitado é inválido");
    } else {
      const place = autocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) return;
      const coordinates = place.geometry.location;

      if (place?.formatted_address) {
        handleLocationChange(place.formatted_address,
          `${coordinates.lat().toFixed(5)}, ${coordinates.lng().toFixed(5)}`);
      }

      setMapCenter({ lat: coordinates.lat(), lng: coordinates.lng() });
      setMarkerPosition({ lat: coordinates.lat(), lng: coordinates.lng() });
    }
  };

  return (
    <Container>
      {isLoaded && (
        <GoogleMap
          onLoad={onLoad}
          mapContainerStyle={{ width: "100%", height: "100%", zIndex: "3" }}
          center={mapCenter}
          zoom={DEFAULT_ZOOM_LEVEL}
          onClick={onMapClick}
          options={{
            mapTypeControl: true,
            minZoom: 4,
            mapTypeControlOptions: {
              style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
            zoomControl: true,
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            streetViewControl: false,
            gestureHandling: "greedy"
          }}
        >
          {markerPosition && <Marker position={markerPosition} />}

          <GoogleMapsMenuOption towerLeftMenuWidth={towerLeftMenuWidth}>
            <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
              <input
                type="text"
                placeholder={"Insira o local do projeto"}
                value={inputValues?.location}
                onChange={(e) => handleInputChange({ target: { name: "location", value: e.target.value } })}
                ref={autocompleteInputRef}
                autoFocus
              />
            </Autocomplete>
          </GoogleMapsMenuOption>
        </GoogleMap>
      )}

      <MapOptionContainer mapType={mapType} towerLeftMenuWidth={towerLeftMenuWidth}>
        <div
          onClick={() => {
            setMapType("Mapa");
            setIsopletaMaxWind("");
            setIsKmlLayerVisible(false);
            if (kmlLayerRef.current) {
              kmlLayerRef.current.setMap(null);
              kmlLayerRef.current = null;
            }
          }}
          className={mapType === "Mapa" ? "selected" : ""}
        >
          Mapa
        </div>
        <div
          onClick={() => {
            setMapType("Isopletas");
            setIsKmlLayerVisible(true);
          }}
          className={mapType === "Isopletas" ? "selected" : ""}
        >
          Isopletas
        </div>
      </MapOptionContainer>
    </Container>
  );
}

const Container = styled.div`
    display: flex;
    width: 100%;
    max-width: 100%;
    height: 100%;
`;

const GoogleMapsMenuOption = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    top: 50vh;
    left: 62px;

    @media (max-height: 500px) {
        top: 33.5vh;
    }
    @media (min-height: 500px) and (max-height: 550px) {
        top: 31.5vh;
    }
    @media (min-height: 550px) and (max-height: 600px) {
        top: 29.3vh;
    }
    @media (min-height: 600px) and (max-height: 650px) {
        top: 27vh;
    }
    @media (min-height: 650px) and (max-height: 700px) {
        top: 25.2vh;
    }
    @media (min-height: 700px) and (max-height: 750px) {
        top: 23.8vh;
    }
    @media (min-height: 750px) and (max-height: 800px) {
        top: 22.5vh;
    }
    @media (min-height: 800px) and (max-height: 850px) {
        top: 21.2vh;
    }
    @media (min-height: 850px) and (max-height: 900px) {
        top: 20vh;
    }
    @media (min-height: 900px) and (max-height: 950px) {
        top: 19vh;
    }
    @media (min-height: 950px) and (max-height: 1000px) {
        top: 18.3vh;
    }
    @media (min-height: 1000px) and (max-height: 1050px) {
        top: 17.3vh;
    }
    @media (min-height: 1050px) and (max-height: 1100px) {
        top: 16.6vh;
    }
    @media (min-height: 1100px){
        top: 16vh;
    }

    input, select {
        width: ${({ towerLeftMenuWidth }) => `calc(${towerLeftMenuWidth}px - 68px)`} !important;
        border: 1px solid ${colors.kcBorderGray} !important;
        height: 40px !important;
        padding: 6px 0 6px 15px !important;
        border-radius: 6px !important;
        background-color: ${colors.kcWhiteColor} !important;
        color: ${colors.kcTextColor} !important;
        font-size: 12px !important;
        font-weight: 500 !important;
        line-height: 24px !important;
        font-family: 'Roboto', sans-serif !important;
        margin-top: 16px !important;
    }

    input:disabled {
        background-color: ${colors.kcWhiteColor} !important;
        color: ${colors.kcMediumGray} !important;
    }

    input::placeholder {
        color: ${colors.kcTextColor} !important;
    }
`;

const MapOptionContainer = styled.div`
    display: flex;
    width: 100%;
    position: fixed;
    top: 90px;
    left: ${({ towerLeftMenuWidth }) => `calc(${towerLeftMenuWidth}px + 250px)`} !important;
    z-index: 10;
    font-family: 'Roboto', sans-serif !important;

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 40px;
        background-color: ${colors.kcWhiteColor};
        border: 1px solid ${colors.kcBorderGray};
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        color: ${colors.kcTextColor} !important;
        font-size: 16px;
        font-weight: 300;

        &:hover {
            background-color: ${colors.kcLightGray};
        }
    }

    .selected {
        font-weight: 500 !important;
    }
`;
