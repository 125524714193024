import useAsync from "../useAsync";
import * as apiKeysApi from "../../services/apiKeysApi";

export default function useGetGoogleMapsApi() {
  const {
    data: googleMapsApi,
    loading: getGoogleMapsApiLoading,
    error: getGoogleMapsApiError,
    act: getGoogleMapsApi,
  } = useAsync((token) => apiKeysApi.getGoogleMapsApi( token), false);

  return {
    googleMapsApi,
    getGoogleMapsApiLoading,
    getGoogleMapsApiError,
    getGoogleMapsApi
  };
}
