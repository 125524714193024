import { useContext } from "react";
import { ProjectContext } from "../contexts/ProjectContext";
import verifyNecessaryProps from "../components/mast-components/calculation-components/verify-necessary-props";

// Stages:
// 1: Mastro ainda não foi selecionado
// 2: Parâmetros de vento ainda não foram completos
// 3: Informações de geometria ainda não foram completas
// 4: Informações de geometria finalizadas, mas carregamento de antenas ainda não foi feito
// 5: Mastro pronto para ser calculado, ou já foi calculado

export default function useMastStage() {
  let mastStage = 1;
  const { mastSelected, projectSelected } = useContext(ProjectContext);
  const mastProps = mastSelected?.props ? JSON.parse(mastSelected?.props) : {};
  const projectProps = projectSelected?.props ? JSON.parse(projectSelected?.props) : {};
  if (verifyIfMastIsInStage1(mastSelected)) mastStage = 1;
  else if (verifyIfMastIsInStage2(mastProps, projectProps)) mastStage = 2;
  else if (verifyIfMastIsInStage3(mastProps)) mastStage = 3;
  else if (verifyIfMastIsInStage4(mastProps)) mastStage = 4;
  else mastStage = 5;

  return mastStage;
}

function verifyIfMastIsInStage1(mastSelected) {
  return (!mastSelected?.identificador);
}

function verifyIfMastIsInStage2(mastProps, projectProps) {
  const missingProps = verifyNecessaryProps({ ...mastProps, ...projectProps });
  return (missingProps.includes("Vento"));
}

function verifyIfMastIsInStage3(mastProps) {
  const missingProps = verifyNecessaryProps(mastProps);
  return (missingProps.includes("Geometria") || missingProps.includes("Módulos") || missingProps.includes("Estais") || missingProps.includes("Chumbadores"));
}

function verifyIfMastIsInStage4(mastProps) {
  const missingProps = verifyNecessaryProps(mastProps);
  function temAntena(mastProps) {
    for (let prop in mastProps) {
      if (prop.startsWith("antena")) {
        return true;
      }
    }
    return false;
  }
  return (missingProps.includes("Antenas") && !temAntena(mastProps));
}
