import { useState, useContext } from "react";
import styled from "styled-components";
import usePostLogout from "../../hooks/api/useLogout";
import UserContext from "../../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { ProjectContext } from "../../contexts/ProjectContext";

export default function UserTopMenuOptions({ isMenuOpen, setIsMenuOpen, type }) {
  const { userData, userInfo } = useContext(UserContext);
  const { setProjectType, setProjectSelected, setMastSelected, setProjectNameSelected, setLeftMenuOptionSelected } = useContext(ProjectContext);
  const { postLogout } = usePostLogout();
  const navigate = useNavigate();
  const handleMouseEnter = () => {
    setIsMenuOpen(type);
  };

  async function userLogout() {
    try {
      await postLogout(userData.token);
      setProjectType("");
      setProjectSelected({});
      setMastSelected({});
      setProjectNameSelected("");
      setLeftMenuOptionSelected("");
      window.localStorage.removeItem("pageProps");
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  }

  function navigateToUserPageInfo() {
    navigate("/account/edit");
  }

  return (
    <MenuContainer
      onMouseEnter={handleMouseEnter}
      right={userInfo.name ? "-117px" : "-16px"}
    >
      <MenuOption onClickFunction={navigateToUserPageInfo} title={"Minha Conta"} iconName={"settings-outline"} />
      <MenuOption onClickFunction={userLogout} title={"Logout"} iconName={"log-out-outline"} />
    </MenuContainer>
  );
}

function MenuOption({ title, iconName, onClickFunction }) {
  const [isMouseOn, setIsMouseOn] = useState(false);
  const handleMouseEnter = () => {
    setIsMouseOn(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOn(false);
  };
  return (
    <MenuOptionContainer onClick={onClickFunction} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} isMouseOn={isMouseOn}>
      <ion-icon name={iconName}></ion-icon>
      <div>{title}</div>
    </MenuOptionContainer>
  );
}

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 42px;
  right: ${(props) => props.right};
  height: auto;
  width: 190px;
  z-index: 5;
  background-color: #d0cece;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 0px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
`;

const MenuOptionContainer = styled.div`
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: flex-start;
  height: 46px;
  width: 190px;

  background-color: ${(props) => (props.isMouseOn ? "#F16E00" : "#d0cece")};
  :nth-last-child(1){
    border-bottom-left-radius: 10px;
  }
  cursor: pointer;

  div, ion-icon {
    font-family: "Open-sans", sans-serif;
    font-size: 17px;
    color: #000;
    font-weight: 300;
  }
  ion-icon {
    font-size: 19px;
    margin-right: 12px;
  }
`;
