import { useEffect, useContext, useState } from "react";
import { ProjectContext } from "../../contexts/ProjectContext";

function ConfirmationPopup() {
  const { projectNameSelected, projectSelected, mastSelected, leftMenuOptionSelected } = useContext(ProjectContext);
  const [pageProps, setPageProps] = useState({
    projectNameSelected: projectNameSelected,
    projectSelected: projectSelected,
    mastSelected: mastSelected,
    leftMenuOptionSelected: leftMenuOptionSelected,
  });

  useEffect(() => {
    setPageProps({
      projectNameSelected: projectNameSelected,
      projectSelected: projectSelected,
      mastSelected: mastSelected,
      leftMenuOptionSelected: leftMenuOptionSelected,
    });
  }, [projectNameSelected, mastSelected, leftMenuOptionSelected]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "As alterações que você fez talvez não sejam salvas.";
      localStorage.setItem("pageProps", JSON.stringify(pageProps));
    };

    const handlePopstate = () => {
      alert("As alterações que você fez talvez não sejam salvas.");
      localStorage.setItem("pageProps", JSON.stringify(pageProps));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [pageProps]);

  return null;
}

export default ConfirmationPopup;
