/* eslint-disable */
import { useContext, useState } from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";
import fundacao_big_icon from "../../../assets/images/fundacao-big-icon.png";
import poste_big_icon from "../../../assets/images/poste-big-icon.png";
import rooftop_big_icon from "../../../assets/images/rooftop-big-icon.png";
import torre_big_icon from "../../../assets/images/torre-big-icon.png";
import zeo_logo from "../../../assets/images/zeo-logo-blackfont.png";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { useNavigate } from "react-router-dom";

export default function ProjectMovingLeftMenu({ isLeftMenuOpen }) {
  const { projectType, setProjectType } = useContext(ProjectContext);
  const [softwareSelected, setSoftwareSelected] = useState(projectType);
  const navigate = useNavigate();
  return (
    <LeftMovingMenuWrapper isLeftMenuOpen={isLeftMenuOpen}>
      <SoftwareOptionsContainer>
        <h1>Softwares</h1>
        <ProjectOptionSelectorContainer onClick={() => {
          setProjectType("Rooftop");
          navigate("/rooftop");
        }} softwareSelected={softwareSelected} softwareName={'Rooftop'} cursor={"pointer"}>
          <img src={rooftop_big_icon} alt="Rooftop Icon" />
          Rooftop
        </ProjectOptionSelectorContainer>
        <ProjectOptionSelectorContainer softwareSelected={softwareSelected} softwareName={"Poste"}
          onMouseEnter={() => setSoftwareSelected(projectType)} onMouseLeave={() => setSoftwareSelected(projectType)} cursor={"not-allowed"}>
          <img src={poste_big_icon} alt="Poste Icon" />
          Poste
        </ProjectOptionSelectorContainer >
        <ProjectOptionSelectorContainer onClick={() => {
          setProjectType("Torre");
          navigate("/torre");
        }} softwareSelected={softwareSelected} softwareName={"Torre"}
          // onMouseEnter={() => setSoftwareSelected("Torre")} onMouseLeave={() => setSoftwareSelected(projectType)}
          cursor={"pointer"}>
          <img src={torre_big_icon} alt="Torre Icon" />
          Torre
        </ProjectOptionSelectorContainer>
        <ProjectOptionSelectorContainer softwareSelected={softwareSelected} softwareName={"Fundação"} onMouseEnter={() => setSoftwareSelected("Fundação")} onMouseLeave={() => setSoftwareSelected(projectType)} cursor={"not-allowed"}>
          <img src={fundacao_big_icon} alt="Fundação Icon" />
          Fundação
        </ProjectOptionSelectorContainer>
      </SoftwareOptionsContainer>
      <ZeoRightsContainer>
        <img src={zeo_logo} alt="Zeo Logo" />
        <p>Copyright © ZEO</p>
        <p>Todos os direitos reservados</p>
      </ZeoRightsContainer>
    </LeftMovingMenuWrapper >
  );
}

const slideIn = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

const LeftMovingMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 250px; 
  height: 100%;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  animation: ${({ isLeftMenuOpen }) => (isLeftMenuOpen ? slideIn : slideOut)} 0.5s ease-in-out;
  opacity: ${(props) => (props.isLeftMenuOpen ? "1" : "0")};
`;

const SoftwareOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  h1 {
    font-size: 18px;
    font-weight: 500;
    padding: 20px 0px 20px 20px;
  }
`;

const ProjectOptionSelectorContainer = styled.div`
  display: flex;
  height: 52px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  background-color: ${(props) => (props.softwareName === props.softwareSelected ? "#e6e6e6" : "#ffffff")};
  border-left: ${(props) => (props.softwareName === props.softwareSelected ? "5px solid #F16E00" : "none")};
  cursor: ${(props) => props.cursor};
  img {
    margin: 0 20px;
    width: 16px;
    height: 30px;
  }
  :hover {
    background-color: #e6e6e6;
    border-left: 5px solid #F16E00;
    transition: 0.01s;
  }
`;

const ZeoRightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  img {
    width: 56%;
    padding-bottom: 8px;
  }
  p {
    font-size: 14px;
    color: #7E7E7E;
    padding-top: 6px;
  }
  p:nth-child(3) {
    padding-bottom: 24px;
  }
`;
