import React, { useContext, useState } from "react";
import styled from "styled-components";
import { TowerContext } from "../../contexts/TowerContext";
import colors from "../../constants/color-constants";
import trussImages from "../../assets/images/towerImages/trussTypes/trusses-images";

const TowerSilhouette = ({ images }) => {
  const { setTowerImages, trussesSelected } = useContext(TowerContext);

  const [isZoomActive, setIsZoomActive] = useState(false);

  const imagePaths = images ?? getTowerImages();

  function getTowerImages() {
    const paths = trussesSelected?.map((value) => {
      if (!value?.trussType || !value?.mountDivisions || !value?.bracingType) return null;
      const imageName = `${value.trussType.charAt(value.trussType.length - 1)}${value.mountDivisions}${value.bracingType}`;
      return trussImages[value.trussType][imageName];
    });
    setTowerImages(paths);
    return paths;
  }

  function renderImages() {
    return imagePaths?.map((imageSrc, index) => {
      return imageSrc && (
        <SegmentImage
          src={imageSrc}
          key={index}
          alt={`Trecho ${index + 1}`}
          isZoomActive={isZoomActive}
        />
      );
    });
  }

  return (
    <SilhouetteContainer>
      <ZoomButtonsContainer isZoomActive={isZoomActive}>
        <button onClick={() => setIsZoomActive(true)}>{" + "}</button>
        <button onClick={() => setIsZoomActive(false)} className="zoomOut">
          {" - "}
        </button>
      </ZoomButtonsContainer>

      <TrussImagesContainer isZoomActive={isZoomActive} imgCount={imagePaths?.length}>
        {renderImages()}
      </TrussImagesContainer>
    </SilhouetteContainer>
  );
};

export default TowerSilhouette;

const SilhouetteContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    min-height: 90px;
    max-height: calc(100vh - 260px);
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    border-radius: 6px;
`;

const TrussImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    min-width: 200px;
    max-height: 600px;
    gap: 0;
    padding: 0 50px;
    box-sizing: border-box;
    overflow-y: ${(props) => (props.isZoomActive ? "auto" : "hidden")} !important;

    img {
        height: ${(props) => (props.isZoomActive ? "auto" : "calc(100% / " + props.imgCount + ")")} !important;
    }
`;

const ZoomButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: min-content;
    gap: 8px !important;
    z-index: 2;

    button {
        width: 40px;
        height: 40px;
        border-radius: 6px;
        background-color: ${props => props.isZoomActive ? colors.kcOrange : colors.kcWhiteColor};
        color: ${props => props.isZoomActive ? colors.kcWhiteColor : colors.kcTextColor};
        font-family: "Roboto", sans-serif;
        font-size: 24px;
        font-weight: 700;

        :hover {
            background-color: ${props => props.isZoomActive ? colors.kcDarkOrange : colors.kcButtonGray};
        }
    }

    .zoomOut {
        background-color: ${props => props.isZoomActive ? colors.kcWhiteColor : colors.kcOrange};
        color: ${props => props.isZoomActive ? colors.kcTextColor : colors.kcWhiteColor};

        :hover {
            background-color: ${props => props.isZoomActive ? colors.kcButtonGray : colors.kcDarkOrange};
        }
    }
`;

const SegmentImage = styled.img`
    width: ${(props) => (props.isZoomActive ? "100px" : "auto")};
    max-width: 100px;
    height: ${(props) => (props.isZoomActive ? "auto" : "calc(100% / ${props.imgCount})")};
    object-fit: cover;
`;
