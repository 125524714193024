import { Navigate, BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LoginPage } from "./pages/login-page";
import { HomePage } from "./pages/home-page";
import { LogupPage } from "./pages/logup-page";
import { UserProvider } from "./contexts/UserContext";
import { ProjectStorage } from "./contexts/ProjectContext";
import { TowerStorage } from "./contexts/TowerContext";
import { ToastContainer } from "react-toastify";
import { RooftopPage } from "./pages/rooftop-page";
import { TowerPage } from "./pages/tower-page";
import { UpdatePasswordPage } from "./pages/updatePassword-page";
import NotFoundPage from "./pages/notFound-page";
import UserInfoPage from "./pages/userInfo-page";
import ConfirmationPopup from "./components/common/reload-confirmation-popup";
import useToken from "./hooks/useToken";
import { signInToken } from "./services/userApi";
import { MenuStorage } from "./contexts/MenuContext";

function App() {
  return (
    <>
      <ToastContainer />
      <UserProvider>
        <ProjectStorage>
          <TowerStorage>
            <ConfirmationPopup />
            <MenuStorage>
              <Router>
                <Routes>
                  <Route path="/" element={<ProtectedRouteGuard><HomePage /></ProtectedRouteGuard>} />
                  <Route path="/rooftop" element={<ProtectedRouteGuard><RooftopPage /></ProtectedRouteGuard>} />
                  <Route path="/torre" element={<ProtectedRouteGuard><TowerPage /></ProtectedRouteGuard>} />
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/logup" element={<LogupPage />} />
                  <Route path="/account/edit" element={<ProtectedRouteGuard><UserInfoPage /></ProtectedRouteGuard>} />
                  <Route path="/changePassword" element={<UpdatePasswordPage />} />
                  <Route path="/*" element={<NotFoundPage />} />
                </Routes>
              </Router>
            </MenuStorage>
          </TowerStorage>
        </ProjectStorage>
      </UserProvider>
    </>
  );
}

function ProtectedRouteGuard({ children }) {
  const user = useToken();
  async function validateToken() {
    try {
      await signInToken(user.id, user.token);
    } catch (error) {
      window.localStorage.removeItem("pageProps");
      window.localStorage.removeItem("userData");
      window.location.reload(false);
      return;
    }
  }
  if (user.token) validateToken();

  if (!user.token) {
    return <Navigate to="/login" />;
  }

  return <>{children}</>;
}

export default App;
