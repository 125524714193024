import React from "react";
import styled from "styled-components";

const CustomTable = ({ columnTitles, content }) => {
  const renderColumnTitles = columnTitles.map((title, index) => (
    <TableHeader key={index}>{title}</TableHeader>
  ));

  const renderTableBody = content.map((row, rowIndex) => (
    <tr key={rowIndex}>
      {row.map((cell, cellIndex) => (
        <TableData key={cellIndex}>{cell}</TableData>
      ))}
    </tr>
  ));

  return (
    <Table>
      <thead>
        <tr>{renderColumnTitles}</tr>
      </thead>
      <tbody>{renderTableBody}</tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  
  tbody tr {
    height: 40px;
  }
`;

const TableHeader = styled.th`
  height: 50px;
  padding: 16px 10px;
  background-color: #F2F2F2;
  color: #333;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
`;

const TableData = styled.td`
  border-bottom: 1px solid #F2F2F2;
  padding: 8px;
  vertical-align: middle;
  text-align: left;
  background-color: #FFFFFF;
  color: #888888;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
`;

export default CustomTable;
