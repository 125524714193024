import React from "react";
import styled from "styled-components";
import TowerSilhouetteSections from "./tower-silhouette-sections";
import colors from "../../../constants/color-constants";

export default function TowerSectionRightContainer({
  inputValues,
  sectionSelectedInMenu,
  handleInputChange,
  geometryTotalHeight,
  geometryTopWidth,
  geometryBaseWidth,
  sectionHeights,
  towerImages,
  setTowerImages,
  trussesSelected,
}) {
  return(
    <Container>
      <TowerGeometryImgContainer>
        <h2>Seções da Torre</h2>
        <TowerSilhouetteSections
          images={towerImages}
          inputValues={inputValues}
          sectionHeights={sectionHeights}
          geometryTotalHeight={geometryTotalHeight}
          geometryTopWidth={geometryTopWidth}
          geometryBaseWidth={geometryBaseWidth}
          sectionSelectedInMenu={sectionSelectedInMenu}
          handleInputChange={() => handleInputChange()}
          trussesSelected={trussesSelected}
          setTowerImages={setTowerImages}
        />
      </TowerGeometryImgContainer>
    </Container>
  );
}

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
`;

const TowerGeometryImgContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 165px;
    max-height: calc(100vh - 245px);
    padding: 20px;
    border-radius: 6px;
    box-sizing: border-box;
    position: relative;
    background-color: ${colors.kcLightGray};
    font-family: 'Roboto', sans-serif;
    gap: 20px;

    h2 {
        font-size: 14px;
        font-weight: 500;
        color: ${colors.kcBlackColor};
    }
`;
