import useAsync from "../useAsync";
import * as projectApi from "../../services/projectApi";

export default function useCopyTowerProject() {
  const {
    data: copyTowerProjectData,
    loading: copyTowerProjectLoading,
    error: copyTowerProjectError,
    act: copyTowerProject
  } = useAsync((projectId, newProjectName, token) => projectApi.copyTowerProject(projectId, newProjectName, token), false);

  return {
    copyTowerProjectData,
    copyTowerProjectLoading,
    copyTowerProjectError,
    copyTowerProject
  };
}
