import { useMemo, useContext, useEffect } from "react";
import styled from "styled-components";
import { useTable } from "react-table";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { calculateSomas } from "./mast-antennaCalculation";

export default function AntennaTable({ inputValues, reloadProject }) {
  const { mastSelected } = useContext(ProjectContext);
  const mastProps = JSON.parse(mastSelected.props);

  useEffect(() => {
  }, [reloadProject, mastSelected, inputValues]);

  const somasGrupos = calculateSomas(mastProps);
  let totalCarga = 0;

  const columns = useMemo(
    () => [
      {
        Header: "ANTENA",
        accessor: "antenna",
      },
      {
        Header: "OPERADORA",
        accessor: "operator",
      },
      {
        Header: "CONDIÇÃO",
        accessor: "condicao",
      },
      {
        Header: "TIPO",
        accessor: "type",
      },
      {
        Header: "MODELO",
        accessor: "model",
      },
      {
        Header: "QUANTIDADE",
        accessor: "qtd",
      },
      {
        Header: "DIMENSÕES [mm]",
        accessor: "dimensions",
      },
      {
        Header: "ALTURA [m]",
        accessor: "height",
      },
      {
        Header: "COEF. ARRASTO",
        accessor: "coefArrasto",
      },
      {
        Header: "ÁREA [m²]",
        accessor: "area",
      },
      {
        Header: "ÁREA + C.A. [m²]",
        accessor: "areaECA",
      },
      {
        Header: "CARGA [Kgf]",
        accessor: "carga",
      },
    ],
    []
  );

  const data = useMemo(() => {
    let totalArea = 0;
    let totalAreaECA = 0;
    let totalCarga = 0;

    for (let i = 0; i < somasGrupos.length; i++) {
      totalArea += somasGrupos[i]?.somaArea;
      totalAreaECA += somasGrupos[i]?.somaAreaECA;
      // totalCarga += somasGrupos[i]?.somaCargaFrontal;
      // totalCarga += somasGrupos[i]?.somaCargaDiagonal;
      totalCarga += Math.ceil(somasGrupos[i]?.somaAreaECA * somasGrupos[i]?.cargaPorM2);
    };
  
    const uniqueGroups = [];
    for (const key in mastProps) {
      if (key.startsWith("antena") && key.endsWith("Grupo")) {
        const group = mastProps[key];
        if (!uniqueGroups.includes(group)) {
          uniqueGroups.push(group);
        }
      }
    }

    const newRows = uniqueGroups.map((group, index) => {
      const groupAntennas = [];
      for (const key in mastProps) {
        if (key.startsWith("antena") && key.endsWith("Grupo")) {
          const antennaNumber = key.slice(5, -5);
          const currentGroup = mastProps[key];
          if (currentGroup === group) {
            groupAntennas.push(antennaNumber);
          }
        }
      }
      let condicaoClass = "";

      switch (mastProps[`antena${groupAntennas[0].split("a")[1]}Condicao`]) {
      case "Existente":
        condicaoClass = "condicao-existing";
        break;
      case "Retirar":
        condicaoClass = "condicao-remove";
        break;
      case "Instalar":
        condicaoClass = "condicao-install";
        break;
      default:
        condicaoClass = "condicao-reserve";
        break;
      }

      return {
        antenna: group,
        operator: mastProps[`antena${groupAntennas[0].split("a")[1]}Operadora`],
        condicao: mastProps[`antena${groupAntennas[0].split("a")[1]}Condicao`],
        type: mastProps[`antena${groupAntennas[0].split("a")[1]}Tipo`],
        model: mastProps[`antena${groupAntennas[0].split("a")[1]}Modelo`],
        qtd: groupAntennas.length,
        dimensions: mastProps[`antena${groupAntennas[0].split("a")[1]}Diametro`] != 0 ? `ø ${mastProps[`antena${groupAntennas[0].split("a")[1]}Diametro`]}` : `${mastProps[`antena${groupAntennas[0].split("a")[1]}Altura`]} x ${mastProps[`antena${groupAntennas[0].split("a")[1]}Largura`]}`,
        coefArrasto: mastProps[`antena${groupAntennas[0].split("a")[1]}CA`] || "-",
        height: Number(mastProps[`antena${groupAntennas[0].split("a")[1]}AlturaMastro`]).toFixed(2),
        area: somasGrupos[index]?.somaArea.toFixed(2),
        areaECA: somasGrupos[index]?.somaAreaECA.toFixed(2),
        carga: Math.ceil((Number(somasGrupos[index]?.somaAreaECA)) * Number(mastProps[`antena${groupAntennas[0].split("a")[1]}CargaPorM2`])),
        condicaoClass: condicaoClass,
      };
    });

    return [
      ...newRows,
      {
        antenna: "",
        operator: "",
        condicao: "",
        type: "",
        model: "",
        qtd: "",
        dimensions: "",
        height: "",
        coefArrasto: "TOTAL",
        area: totalArea.toFixed(2),
        areaECA: totalAreaECA.toFixed(2),
        carga: Math.ceil(totalCarga),
        condicaoClass: "total"
      },
    ];
  }, [inputValues]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });
  return (
    <TableContainer>
      <TableTitle>Tabela de Antenas - Mastro {mastProps.identificador}</TableTitle>
      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={row.original.condicaoClass}>
                {row.cells.map((cell, cellIndex) => {
                  if (rowIndex === rows.length - 1 && cellIndex === row.cells.length - 4) {
                    return <TotalCell {...cell.getCellProps()}>{cell.render("Cell")}</TotalCell>;
                  }
                  else if (rowIndex === rows.length - 1  && cellIndex < row.cells.length - 4) {
                    return <EmptyCell {...cell.getCellProps()}>{cell.render("Cell")}</EmptyCell>;
                  }
                  else if (rowIndex === rows.length - 1  && cellIndex > row.cells.length - 4) {
                    return <TotalResultsCell {...cell.getCellProps()}>{cell.render("Cell")}</TotalResultsCell>;
                  }
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
}

const TableContainer = styled.div`
  position: relative;
  width: 100%;
  max-height: 38vh;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 5px; 
    height: 30px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #FE8F03; 
    border-radius: 5px; 
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  scrollbar {
    width: 5px; 
    height: 30px;
  }
  scrollbar-thumb {
    background-color: #FE8F03;
    border-radius: 5px; 
  }
  scrollbar-thumb:hover {
    background-color: #555; 
  }
`;

const TableTitle = styled.div`
  width: 91.5%;
  height: 40px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: 500;
  font-size: 21px;
  margin-bottom: 4px;
`;

const TotalCell = styled.td`
  background-color: #464646;
  color: #ffffff;
  font-weight: 500 !important;
  letter-spacing: 1.75px;
  padding: 15px 0 !important;
`;

const EmptyCell = styled.td`
  color: #Ffffff;
  border: none !important;
  border-radius: 0px !important;
`;

const TotalResultsCell = styled.td`
  color: #Ffffff;
  background-color: #E2E2E2;
  color: #000;
  font-weight: 500 !important;
`;

const Table = styled.table`
  width: 91.5%;
  height: 100%;
  background-color: #ffffff;
  overflow: scroll;

  th,
  td {
    border: 2.75px solid #F2F0F4;
    text-align: center;
    vertical-align: middle;
    font-size: 15px;
    padding: 10px 0;
    font-weight: 300;
  }

  th {
    background-color: #DADADA;
    color: #000;
    font-size: 14.5px;
    font-weight: 500;
    padding: 14px 0;
  }

  tr.condicao-reserve {
    color: green;
  }

  tr.condicao-remove {
    color: #157BC5;
  }

  tr.condicao-install {
    color:  #FC3333;
  }
`;
