import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";

class RedundantDataModel {
  constructor(
    redundant = "",
    profileType = "",
    profileSteel = "",
    profileDimensions = "",
    profileDiameter = "",
    profileThickness = "",
    profileFlange = "",
    profileWeb = "",
    boltsSteel = "",
    numConnectionBolts = "",
    diameterConnectionBolts = "",
    reinforcementDimensions = "",
    reinforcementDiameter = "",
    reinforcementThickness = "",
    reinforcementFlange = "",
    reinforcementWeb = ""
  ) {
    this.redundant = redundant;
    this.profileType = profileType;
    this.profileSteel = profileSteel;
    this.profileDimensions = profileDimensions;
    this.profileDiameter = profileDiameter;
    this.profileThickness = profileThickness;
    this.profileFlange = profileFlange;
    this.profileWeb = profileWeb;
    this.boltsSteel = boltsSteel;
    this.numConnectionBolts = numConnectionBolts;
    this.diameterConnectionBolts = diameterConnectionBolts;
    this.reinforcementDimensions = reinforcementDimensions;
    this.reinforcementDiameter = reinforcementDiameter;
    this.reinforcementThickness = reinforcementThickness;
    this.reinforcementFlange = reinforcementFlange;
    this.reinforcementWeb = reinforcementWeb;
  }

  updateField(field, value) {
    if (value === this[field] || this[field] === undefined) return;

    if (field === "profileType" && value !== this[field]) {
      this[field] = value;
      this.profileSteel = "";
      this.profileDimensions = "";
      this.profileDiameter = "";
      this.profileThickness = "";
      this.profileFlange = "";
      this.profileWeb = "";
      this.reinforcementDimensions = "";
      this.reinforcementDiameter = "";
      this.reinforcementThickness = "";
      this.reinforcementFlange = "";
      this.reinforcementWeb = "";
      return;
    }

    if (this.profileType === "T - Tubo Vazado" && value !== this[field]) {
      if (field === "profileSteel") {
        this[field] = value;
        this.profileDimensions = "";
        this.profileDiameter = "";
        this.profileThickness = "";
        this.profileFlange = "";
        this.profileWeb = "";
        this.reinforcementDimensions = "";
        this.reinforcementDiameter = "";
        this.reinforcementThickness = "";
        this.reinforcementFlange = "";
        this.reinforcementWeb = "";
        return;
      }

      if (field === "profileDiameter") {
        this[field] = value;
        this.profileThickness = "";
        return;
      }

      if ((field === "reinforcementDiameter" || field === "reinforcementThickness") && value === "Não Há Reforço") {
        this.reinforcementDiameter = "Não Há Reforço";
        this.reinforcementThickness = "Não Há Reforço";
        return;
      }

      if (field === "reinforcementDiameter") {
        this[field] = value;
        this.reinforcementThickness = "";
        return;
      }
    }

    if (this.profileType === "U - Perfil U") {
      if (field === "reinforcementThickness") {
        if (value === "Não Há Reforço") {
          this.reinforcementFlange = "Não Há Reforço";
          this.reinforcementWeb = "Não Há Reforço";
          this.reinforcementThickness = "Não Há Reforço";
        } else {
          if (this.reinforcementThickness === "Não Há Reforço") {
            this.reinforcementFlange = "";
            this.reinforcementWeb = "";
            this.reinforcementThickness = value;
          } else {
            this.reinforcementThickness = value;
          }
        }
        return;
      }
    }

    this[field] = value;
  }
}

class RedundantSegmentModel {
  constructor(
    segmentId = "",
    trussType = "",
    redundants = []
  ) {
    this.segmentId = segmentId;
    this.trussType = trussType;
    this.redundants = this.trussType !== "Treliça H" ? redundants.map(redundant => new RedundantDataModel(
      redundant.redundant,
      redundant.profileType,
      redundant.profileSteel,
      redundant.profileDimensions,
      redundant.profileDiameter,
      redundant.profileThickness,
      redundant.profileFlange,
      redundant.profileWeb,
      redundant.boltsSteel,
      redundant.numConnectionBolts,
      redundant.diameterConnectionBolts,
      redundant.reinforcementDimensions,
      redundant.reinforcementDiameter,
      redundant.reinforcementThickness,
      redundant.reinforcementFlange,
      redundant.reinforcementWeb
    )) : [];
  }

  updateRedundantData(redundantIndex, field, value) {
    if (this.redundants[redundantIndex]) {
      this.redundants[redundantIndex].updateField(field, value);
    }
  }
}

class RedundantsModel {
  constructor(redundants = []) {
    this.redundants = redundants?.map(segment => new RedundantSegmentModel(
      segment.segmentId,
      segment.trussType,
      segment.redundants
    ));
  }

  updateRedundant(segmentId, redundantIndex, field, value) {
    const segment = this.redundants.find(segment => segment.segmentId === segmentId);
    if (segment) {
      segment.updateRedundantData(redundantIndex, field, value);
    }
  }

  validate() {
    let errors = [];

    this.redundants.forEach((segment, index) => {
      if (segment.redundants.length > 0) {
        segment.redundants.forEach((redundant, idx) => {
          if (!redundant.profileType) errors.push(`Tipo de Perfil na redundante ${idx + 1} trecho ${index + 1}`);
          if (redundant.profileSteel === "") errors.push(`Aço do Perfil na redundante ${idx + 1} trecho ${index + 1}`);
          if (!redundant.profileDimensions && !redundant.profileDiameter && !redundant.profileThickness &&
            !redundant.profileFlange && !redundant.profileWeb) {
            errors.push(`Dimensões do Perfil na redundante ${idx + 1} trecho ${index + 1}`);
          } else {
            if (redundant.profileType === "T - Tubo Vazado" && (!redundant.profileDiameter || !redundant.profileThickness))
              errors.push(`Dimensões do Perfil na redundante ${idx + 1} trecho ${index + 1}`);
            if (redundant.profileType === "U - Perfil U" && (!redundant.profileFlange || !redundant.profileWeb || !redundant.profileThickness))
              errors.push(`Dimensões do Perfil na redundante ${idx + 1} trecho ${index + 1}`);
          }
          if (!redundant.boltsSteel) errors.push(`Aço dos Parafusos na redundante ${idx + 1} trecho ${index + 1}`);
          if (!redundant.numConnectionBolts) errors.push(`Quantidade de parafusos de conexão na redundante ${idx + 1} trecho ${index + 1}`);
          if (!redundant.diameterConnectionBolts) errors.push(`Diametro de parafusos de conexão na redundante ${idx + 1} trecho ${index + 1}`);
          if (!redundant.reinforcementDimensions && !redundant.reinforcementDiameter && !redundant.reinforcementThickness &&
            !redundant.reinforcementFlange && !redundant.reinforcementWeb) {
            errors.push(`Dimensões do Perfil Reforço na redundante ${idx + 1} trecho ${index + 1}`);
          } else {
            if (redundant.profileType === "T - Tubo Vazado" && (!redundant.reinforcementDiameter || !redundant.reinforcementThickness))
              errors.push(`Dimensões do Perfil Reforço na redundante ${idx + 1} trecho ${index + 1}`);
            if (redundant.profileType === "U - Perfil U" && (!redundant.reinforcementFlange || !redundant.reinforcementWeb || !redundant.reinforcementThickness))
              errors.push(`Dimensões do Perfil Reforço na redundante ${idx + 1} trecho ${index + 1}`);
          }
        });
      }
    });

    if (errors.length > 0) {
      const errorMessage = customToastError(
        errors,
        "Preencha todos os valores corretamente para salvar as informações das redundantes. Falta(m):"
      );
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  toJSON() {
    return JSON.stringify(this.redundants);
  }

  static fromJSON(json) {
    const redundantsArray = JSON.parse(json);
    return new RedundantsModel(redundantsArray);
  }
}

export default RedundantsModel;
