import H1A from "./trusses-H/H1A.svg";
import H1B from "./trusses-H/H1B.svg";

import Z1A from "./trusses-Z/Z1A.svg";

import X1A from "./trusses-X/X1A.svg";
import X2A from "./trusses-X/X2A.svg";
import X2B from "./trusses-X/X2C.svg";
import X2C from "./trusses-X/X2D.svg";
import X3A from "./trusses-X/X3A.svg";
import X3B from "./trusses-X/X3B.svg";
import X4A from "./trusses-X/X4A.svg";
import X4B from "./trusses-X/X4D.svg";
import X4C from "./trusses-X/X4G.svg";

import K1A from "./trusses-K/K1A.svg";
import K1B from "./trusses-K/K1B.svg";
import K2A from "./trusses-K/K2A.svg";
import K2B from "./trusses-K/K2B.svg";
import K2C from "./trusses-K/K2C.svg";
import K3A from "./trusses-K/K3A.svg";
import K3B from "./trusses-K/K3C.svg";
import K3C from "./trusses-K/K3D.svg";
import K4A from "./trusses-K/K4A.svg";
import K4B from "./trusses-K/K4C.svg";
import K4C from "./trusses-K/K4E.svg";
import K5A from "./trusses-K/K5A.svg";
import K5B from "./trusses-K/K5F.svg";
import K6A from "./trusses-K/K6A.svg";
import K6B from "./trusses-K/K6C.svg";
import K6D from "./trusses-K/K6D.svg";
import K6E from "./trusses-K/K6E.svg";

import V1A from "./trusses-V/V1A.svg";
import V1B from "./trusses-V/V1B.svg";
import V2A from "./trusses-V/V2A.svg";
import V2B from "./trusses-V/V2C.svg";
import V3A from "./trusses-V/V3A.svg";
import V3B from "./trusses-V/V3D.svg";
import V4A from "./trusses-V/V4A.svg";
import V5A from "./trusses-V/V5A.svg";
import V6A from "./trusses-V/V6A.svg";

const trussH = { H1A, H1B };
const trussZ = { Z1A };
const trussX = { X1A, X2A, X2B, X2C, X3A, X3B, X4A, X4B, X4C };
const trussK = { K1A, K1B, K2A, K2B, K2C, K3A, K3B, K3C, K4A, K4B, K4C, K5A, K5B, K6A, K6B, K6D, K6E };
const trussV = { V1A, V1B, V2A, V2B, V3A, V3B, V4A, V5A, V6A };

const trussImages = {
  "Treliça H": trussH,
  "Treliça Z": trussZ,
  "Treliça X": trussX,
  "Treliça K": trussK,
  "Treliça V": trussV,
};
export default trussImages;
