import {
  diameterProfileBR,
  dimensionsProfileL,
  profileSteelTypes,
  thicknessProfilesOmegaU,
  tubeProfileData,
} from "../constants/tower-constants";

export const handleProfileSteelTypes = (selectedProfileType) => {
  if (selectedProfileType === "T - Tubo Vazado") {
    return Object.keys(tubeProfileData);
  } else {
    return profileSteelTypes;
  }
};

export const handleProfileDimensions = (selectedProfileType, selectedProfileSteel) => {
  if (selectedProfileType === "L - Cantoneira") {
    return dimensionsProfileL;
  } else if (selectedProfileType === "T - Tubo Vazado") {
    const steelData = tubeProfileData[selectedProfileSteel];
    if (steelData) {
      return Object.keys(steelData).filter(key => Array.isArray(steelData[key]));
    }
    return [];
  } else if (selectedProfileType === "BR - Barra Maciça") {
    return diameterProfileBR;
  } else if (selectedProfileType === "O - Ômega" || selectedProfileType === "U - Perfil U") {
    return thicknessProfilesOmegaU;
  }
  return [];
};
