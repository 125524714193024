import useAsync from "../useAsync";
import * as mastApi from "../../services/mastApi";

export default function usePostDownloadReport() {
  const {
    data: mastReport,
    loading: postReportLoading,
    error: postReportError,
    act: postReport,
  } = useAsync((identificador, nomeProjeto, documentType, token) => mastApi.postMastReportInfoToDownload(identificador, nomeProjeto, documentType, token), false);

  return {
    mastReport,
    postReportLoading,
    postReportError,
    postReport
  };
}
