import { useEffect, useState } from "react";

const useUnsavedChangesAlert = (inputValues, props, reloadProject, fieldsToCheck) => {
  // console.log("inputValues: ", inputValues);
  // console.log("props: ", props);
  const [inputModified, setInputModified] = useState(false);
  useEffect(() => {
    const isModified = fieldsToCheck.some((field) => {
      const comparison = inputValues[field] != props?.[field];
      return comparison === undefined ? false : comparison;
    });
    // console.log("isModified: ", isModified);
    setInputModified(isModified);
  }, [inputValues, props, reloadProject, fieldsToCheck]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (inputModified) {
        event.preventDefault();
        event.returnValue = ""; 
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [inputModified]);

  return inputModified;
};

export default useUnsavedChangesAlert;
