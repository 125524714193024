import styled from "styled-components";
import { useContext, useState, useEffect, useCallback } from "react";
import UserContext from "../../../contexts/UserContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { TowerContext } from "../../../contexts/TowerContext";
import RedundantLeftMenu from "./tower_redundantLeftMenu";
import TowerRedundantRightContainer from "./tower_redundantRightContainer";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import { useTowerRedundants } from "../../../hooks/api/tower-api/useTowerRedundants";
import useUnsavedChangesAlertForObjects from "../../../hooks/useUnsavedChangesAlertForObjects";
import { trussesInfo } from "../../../constants/tower-trusses-data";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RedundantsModel from "../../../models/RedundantModel";

export default function TowerRedundantPage() {
  const { userData } = useContext(UserContext);
  const { towerId, towerImages, segmentsSelected, geometrySelected, trussesSelected,
    redundantSelected, setRedundantSelected } = useContext(TowerContext);
  const { reloadProject, setIsUpdatedWithoutCalculation,
    isLoading, setIsLoading } = useContext(ProjectContext);

  const { act: fetchRedundants } = useTowerRedundants("get");
  const { act: saveRedundants } = useTowerRedundants("post");

  const nSegments = Number(geometrySelected.nSegments) || 0;
  const trussesTypes = trussesSelected.map(truss => truss.trussType);
  let nRedundants = countRedundants();

  const [segmentSelectedInMenu, setSegmentSelectedInMenu] = useState(nSegments > 0 ? 1 : "");
  const [redundantSelectedInMenu, setRedundantSelectedInMenu] = useState(
    (nSegments > 0 && nRedundants[segmentSelectedInMenu - 1] > 0) ? 1 : "");
  const [checkedSegments, setCheckedSegments] = useState(
    Array.from({ length: nSegments }, (_) => []));
  const [checkedRedundants, setCheckedRedundants] = useState(() => {
    return nRedundants === 0 ? []
      : nRedundants.map(redundantCount => Array.from({ length: redundantCount }, () => []));
  });
  const [inputValues, setInputValues] = useState(new RedundantsModel(redundantSelected).redundants);

  useEffect(() => {
    loadRedundantsData().then(response => {
      if (response.length === 0) {
        setInitialEmptyValues();
      } else {
        const redundantsModel = new RedundantsModel(response);
        setRedundantSelected(redundantsModel.redundants);
        setInputValues(redundantsModel.redundants);
      }
    });
  }, [towerId, userData.token]);

  const loadRedundantsData = useCallback(async() => {
    setIsLoading(true);
    try {
      return await fetchRedundants(towerId, userData.token);
    } catch (error) {
      toast.error("Erro ao buscar dados das redundantes.");
    } finally {
      setIsLoading(false);
    }
  }, [fetchRedundants, towerId, userData.token]);

  function setInitialEmptyValues() {
    let initialData = [];
    for (let i = 0; i < nSegments; i++) {
      initialData[i] = {
        segmentId: segmentsSelected[i].segmentId || "",
        trussType: trussesTypes[i],
        redundants: []
      };

      if (trussesTypes[i] !== "Treliça H") {
        for(let j = 0; j < nRedundants[i]; j++) {
          initialData[i].redundants[j] = (nRedundants[i] === 0) ? {} : {
            redundant: j + 1,
            profileType: redundantSelected[i]?.redundants[j]?.profileType || "",
            profileSteel: redundantSelected[i]?.redundants[j]?.profileSteel || "",
            profileDimensions: redundantSelected[i]?.redundants[j]?.profileDimensions || "",
            profileDiameter: redundantSelected[i]?.redundants[j]?.profileDiameter || "",
            profileThickness: redundantSelected[i]?.redundants[j]?.profileThickness || "",
            profileFlange: redundantSelected[i]?.redundants[j]?.profileFlange || "",
            profileWeb: redundantSelected[i]?.redundants[j]?.profileWeb || "",
            boltsSteel: redundantSelected[i]?.redundants[j]?.boltsSteel || "",
            numConnectionBolts: redundantSelected[i]?.redundants[j]?.numConnectionBolts || "",
            diameterConnectionBolts: redundantSelected[i]?.redundants[j]?.diameterConnectionBolts || "",
            reinforcementDimensions: redundantSelected[i]?.redundants[j]?.reinforcementDimensions || "",
            reinforcementDiameter: redundantSelected[i]?.redundants[j]?.reinforcementDiameter || "",
            reinforcementThickness: redundantSelected[i]?.redundants[j]?.reinforcementThickness || "",
            reinforcementFlange: redundantSelected[i]?.redundants[j]?.reinforcementFlange || "",
            reinforcementWeb: redundantSelected[i]?.redundants[j]?.reinforcementWeb || "",
          };
        }
      }
    }

    const model = new RedundantsModel(initialData);
    setInputValues(model.redundants);
  }

  function countRedundants() {
    if (trussesSelected.length === 0 || trussesSelected[0].trussType === "") return 0;

    let redundantsQuantity = [];
    for (let i = 0; i < nSegments; i++) {
      let truss = trussesSelected[i];
      if (!truss) return 0;

      if (trussesTypes[i] !== "Treliça H") {
        let redundant = trussesInfo[truss?.trussType][truss?.mountDivisions]
          .filter(type => type?.bracingType === truss?.bracingType)[0].redundants;
        redundantsQuantity.push(redundant);
      } else {
        redundantsQuantity.push(0);
      }
    }
    return redundantsQuantity;
  }

  function countSegmentsRedundants(segmentIndex) {
    const selectedTruss = trussesSelected[segmentIndex];
    if (trussesTypes[segmentIndex] !== "Treliça H") {
      return trussesInfo[selectedTruss?.trussType][selectedTruss?.mountDivisions]
        .filter(type => type.bracingType === selectedTruss?.bracingType)[0].redundants;
    } else {
      return 0;
    }
  }

  const fieldsToCheck = ["redundants.profileType", "redundants.profileDimensions", 
    "redundants.profileSteel", "redundants.profileDiameter", "redundants.profileThickness", 
    "redundants.profileFlange", "redundants.profileWeb", "redundants.boltsSteel", 
    "redundants.numConnectionBolts", "redundants.diameterConnectionBolts",
    "redundants.reinforcementDimensions", "redundants.reinforcementDiameter", 
    "redundants.reinforcementThickness", "redundants.reinforcementFlange", 
    "redundants.reinforcementWeb"];

  const isInputModified = useUnsavedChangesAlertForObjects(
    [...inputValues], redundantSelected, reloadProject, fieldsToCheck);

  const handleInputChange = useCallback((field, value) => {
    const segmentIndex = segmentSelectedInMenu - 1;
    const redundantIndex = redundantSelectedInMenu - 1;
    const redundantsModel = new RedundantsModel(inputValues);
    redundantsModel.updateRedundant(inputValues[segmentIndex].segmentId, redundantIndex, field, value);
    setInputValues(redundantsModel.redundants);
  }, [inputValues, setInputValues, segmentSelectedInMenu, redundantSelectedInMenu]);

  const setEqualRedundant = (targetIndex) => {
    if (trussesTypes[segmentSelectedInMenu - 1] === "Treliça H") return;

    const currentValues = new RedundantsModel(inputValues);
    const selectedSegmentIndex = segmentSelectedInMenu - 1;
    const selectedRedundantIndex = redundantSelectedInMenu - 1;
    const targetRedundantLabel = `Redundante ${targetIndex + 1}`;

    const fieldsToUpdate = ["profileType", "profileDimensions", "profileSteel", "profileDiameter", 
      "profileThickness", "profileFlange", "profileWeb", "boltsSteel", "numConnectionBolts", 
      "diameterConnectionBolts", "reinforcementDimensions", "reinforcementDiameter", 
      "reinforcementThickness", "reinforcementFlange", "reinforcementWeb"];
    
    if (checkedRedundants[selectedSegmentIndex][selectedRedundantIndex].includes(targetIndex)) {
      setCheckedRedundants(prev => {
        const newChecked = [...prev];
        newChecked[selectedSegmentIndex][selectedRedundantIndex] = 
          newChecked[selectedSegmentIndex][selectedRedundantIndex].filter(i => i !== targetIndex);
        newChecked[selectedSegmentIndex][targetIndex] =
          newChecked[selectedSegmentIndex][targetIndex].filter(i => i !== selectedRedundantIndex);
        return newChecked;
      });

      fieldsToUpdate.forEach(field => currentValues.updateRedundant(
        inputValues[selectedSegmentIndex].segmentId, targetIndex, field, ""));
    } else {
      setCheckedRedundants(prev => {
        const newChecked = [...prev];
        newChecked[selectedSegmentIndex][selectedRedundantIndex] = 
          [...newChecked[selectedSegmentIndex][selectedRedundantIndex], targetIndex];
        newChecked[selectedSegmentIndex][targetIndex] =
          [...newChecked[selectedSegmentIndex][targetIndex], selectedRedundantIndex];
        return newChecked;
      });

      fieldsToUpdate.forEach(field => currentValues.updateRedundant(
        inputValues[selectedSegmentIndex].segmentId, targetIndex, field,
        inputValues[selectedSegmentIndex].redundants[selectedRedundantIndex][field]));
    }

    setInputValues(currentValues.redundants);
  };

  const setEqualSegment = (targetIndex) => {
    if (trussesTypes[targetIndex] === "Treliça H") return;
    const currentValues = new RedundantsModel(inputValues);
    const selectedSegmentIndex = segmentSelectedInMenu - 1;

    const fieldsToUpdate = ["profileType", "profileDimensions", "profileSteel", "profileDiameter", 
      "profileThickness", "profileFlange", "profileWeb", "boltsSteel", "numConnectionBolts", 
      "diameterConnectionBolts", "reinforcementDimensions", "reinforcementDiameter", 
      "reinforcementThickness", "reinforcementFlange", "reinforcementWeb"];
    
    if (checkedSegments[selectedSegmentIndex].includes(targetIndex)) {
      setCheckedSegments(prev => {
        const newChecked = [...prev];
        newChecked[selectedSegmentIndex] = newChecked[selectedSegmentIndex].filter(i => i !== targetIndex);
        newChecked[targetIndex] = newChecked[targetIndex].filter(i => i !== selectedSegmentIndex);
        return newChecked;
      });

      inputValues[targetIndex].redundants?.map((redundant, idx) =>
        fieldsToUpdate.forEach(field =>
          currentValues.updateRedundant(inputValues[targetIndex].segmentId, idx, field, "")
        )
      );
    } else {
      setCheckedSegments(prev => {
        const newChecked = [...prev];
        newChecked[selectedSegmentIndex] = [...newChecked[selectedSegmentIndex], targetIndex];
        newChecked[targetIndex] = [...newChecked[targetIndex], selectedSegmentIndex];
        return newChecked;
      });

      inputValues[targetIndex].redundants?.map((redundant, idx) => {
        if (idx <= inputValues[selectedSegmentIndex]?.redundants?.length - 1) {
          return fieldsToUpdate.forEach(field => {
            currentValues.updateRedundant(
              inputValues[targetIndex].segmentId, idx, field, inputValues[selectedSegmentIndex]?.redundants[idx][field]);
          });
        } else {
          return null;
        }
      });
    }

    setInputValues(currentValues.redundants);
  };

  async function updateRedundantsData() {
    const redundantsModel = new RedundantsModel(inputValues);
    if (!redundantsModel.validate()) return false;

    setIsLoading(true);
    try {
      const updatedData = await saveRedundants(redundantsModel.toJSON(), towerId, userData.token);
      const updatedModel = new RedundantsModel(updatedData);
      setRedundantSelected(updatedModel.redundants);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações das redundantes atualizadas com sucesso");
      return true;
    } catch (error) {
      toast.error("Erro ao salvar dados das redundantes.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Redundantes"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updateRedundantsData}
      >
        <RedundantLeftMenu
          nSegments={nSegments}
          nRedundants={nRedundants}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          setSegmentSelectedInMenu={setSegmentSelectedInMenu}
          redundantSelectedInMenu={redundantSelectedInMenu}
          setRedundantSelectedInMenu={setRedundantSelectedInMenu}
          handleInputChange={(field, value) => handleInputChange(field, value)}
          checkedSegments={checkedSegments}
          checkedRedundants={checkedRedundants}
          setEqualSegment={(index) => setEqualSegment(index)}
          setEqualRedundant={(index) => setEqualRedundant(index)}
          countSegmentsRedundants={(segmentIndex) => countSegmentsRedundants(segmentIndex)}
        />
      </LeftMenu>
      <RightContainer>
        <TowerRedundantRightContainer
          nRedundants={nRedundants}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          trussesSelected={trussesSelected}
          towerImages={towerImages}
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
