import styled from "styled-components";
import { ButtonPositioner, ReturnButton, LeftTitle } from "../../project-components/wind-components/project-windData";
import ResizableLeftContainer from "../../common/resizableLeftContainer";
import ResizableRightContainer from "../../common/resizableRightContainer";
import { useState, useContext, useEffect } from "react";
import { ProjectContext } from "../../../contexts/ProjectContext";
import bloco_estai from "./bloco_estai.jpg";
import bloco_mastro from "./bloco_mastro.jpg";
import { useSpring, animated } from "react-spring";
import { ReturnIcon, AdvanceIcon } from "../../project-components/wind-components/project-windData";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import arrow_icon from "../../../assets/images/arrow-icon.jpg";
import useUpdateMast from "../../../hooks/api/useUpdateMast";
import SavingButton from "../../common/saving-button";
import LoadingButton from "../../common/loading-button";
import useUnsavedChangesAlert from "../../../hooks/useUnsavedChangesAlert";
import UserContext from "../../../contexts/UserContext";
import useCalcAnchorBoltAndAnchorage from "../../../hooks/api/useCalcAnchorBoltAndAnchorage";

export default function MastAnchorageCalculationPage() {
  const { setLeftMenuOptionSelected, mastSelected, projectSelected, setReloadProject, reloadProject, setProjectNameSelected, setProjectSelected, setMastSelected, setIsUpdatedWithoutCalculation } = useContext(ProjectContext);
  const [mastProps, setMastProps] = useState();
  if(mastProps === undefined) setMastProps(JSON.parse(mastSelected.props));
  const { userData } = useContext(UserContext);
  const [anchorageSelected, setAnchorageSelected] = useState("Ancoragem Central");
  const [anchorageVerifications, setAnchorageVerifications] = useState({
    puntion: true,
    combinedVerification: true,
    chumbAnchorage: true,
    ironAnchorage: true
  });
  const [inputValues, setInputValues] = useState({
    alturaLaje: mastProps?.alturaLaje || 12,
    cobrimento: mastProps?.cobrimento || 2,
    armadura: mastProps?.armadura || 6,
    espacamento: mastProps?.espacamento || 25,
    chumbadorBlocoTipoAderencia: mastProps?.chumbadorBlocoTipoAderencia || "Boa",
    chumbadorBlocoTipoBarra: mastProps?.chumbadorBlocoTipoBarra || "Lisa",
    chumbadorBlocoGancho: mastProps?.chumbadorBlocoGancho || "Sim",
    ferrosLajeTipoBarra: mastProps?.ferrosLajeTipoBarra || "Nervurada",
    ferrosLajeTipoAderencia: mastProps?.ferrosLajeTipoAderencia || "Boa",
    ferrosLajeFixacaoGancho: mastProps?.ferrosLajeFixacaoGancho || "Não"
  });
  const [props, setProps] = useState(JSON.parse(mastSelected.props) || mastProps);
  const [maxResponses, setMaxResponses] = useState([]);
  const arrNodes = [];
  const { updateMast, updateMastLoading } = useUpdateMast();
  const { calcAnchorBoltAndAnchorage, response } = useCalcAnchorBoltAndAnchorage();
  const [strapMessage, setStapMessage ] = useState(props.strapMessage || []);
  const [anchorageResults, setAnchorageResults] = useState(strapMessage?.[3]?.anchorage || []);
  const [anchorBoltResults, setAnchorBoltResults] = useState(strapMessage?.[3]?.anchorbolt || []);
  const [reloadLeftSummary, setReloadLeftSummary] = useState(false);
  const [firstOpening, setFirstOpening] = useState(true);
  const [parametroAncoragemIsClicked, setParametroAncoragemIsClicked] = useState(false);

  useEffect(() => {
    if (strapMessage && strapMessage[3]?.anchorbolt) {
      setAnchorBoltResults(strapMessage[3]?.anchorbolt);
    }
    if (strapMessage && strapMessage[3]?.anchorage) {
      setAnchorageResults(strapMessage[3]?.anchorage);
    }
    setMaxResponses(findMaxValues());
    setReloadLeftSummary(!reloadLeftSummary);
  }, [strapMessage]);

  const fieldsToCheck = ["alturaLaje", "cobrimento", "espacamento", "armadura", "chumbadorBlocoTipoAderencia", "chumbadorBlocoTipoBarra", "ferrosLajeTipoBarra", "ferrosLajeTipoAderencia", "ferrosLajeFixacaoGancho"];
  const isInputModified = useUnsavedChangesAlert(inputValues, mastProps, reloadProject, fieldsToCheck);

  function handleMenuClick(menuType, menuName) {
    if (menuType === "project") {
      setProjectSelected({ });
      setProjectNameSelected("");
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else if (menuType === "projectName") {
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else {
      setLeftMenuOptionSelected("");
    }
  }

  useEffect(() => {
    let estaiNumberAux;
    let titleAux;
    if (anchorageSelected.indexOf("Central") !== -1) {
      estaiNumberAux = 0;
      titleAux = "ancoragemEstaiCentral";
    } 
    else {
      estaiNumberAux = anchorageSelected[anchorageSelected.length-1];
      titleAux = `ancoragemEstai${anchorageSelected[anchorageSelected.length-1]}`;
    }
    setAnchorageVerifications({
      puntion: true,
      combinedVerification: anchorBoltResults?.[estaiNumberAux]?.[titleAux]?.length !== 0 && anchorBoltResults?.[estaiNumberAux]?.[titleAux][8] === "EM CONFORMIDADE",
      chumbAnchorage: anchorBoltResults?.[estaiNumberAux]?.[titleAux]?.length !== 0 && anchorBoltResults?.[estaiNumberAux]?.[titleAux][anchorBoltResults?.[estaiNumberAux]?.[titleAux]?.length - 1] === "EM CONFORMIDADE",
      ironAnchorage: anchorageResults?.[estaiNumberAux]?.[titleAux]?.length !== 0 && anchorageResults?.[estaiNumberAux]?.[titleAux][anchorageResults?.[estaiNumberAux]?.[titleAux]?.length - 1] === "EM CONFORMIDADE",
    });
  }, [anchorageSelected, anchorageResults, anchorBoltResults, reloadLeftSummary]);
  function findMaxValues() {
    const maxResponses = {};
    let reactions = strapMessage ? strapMessage[2] : [];
    reactions = reactions.filter(reaction => (reaction.combinacao.indexOf("1.00") >= 0));
    let nodes = {};
    for (const reaction of reactions) {
      if(!nodes[reaction.node]) nodes[reaction.node] = true; 
    }
    nodes = Object.keys(nodes).map(node => arrNodes.push(Number(node)));
    props.nodesReactions = arrNodes;
    for (let i = 0; i <= nodes.length-1; i++) {
      const selectedReactions = reactions.filter(reaction => reaction.node === arrNodes[i]);
      maxResponses[`no${arrNodes[i]}tracao`] = selectedReactions.reduce((lowReaction, reaction) => { return (lowReaction.x3 < reaction.x3 ? lowReaction : reaction); }).x3;
      maxResponses[`no${arrNodes[i]}compressao`] = selectedReactions.reduce((maxReaction, reaction) => { return (maxReaction.x3 > reaction.x3 ? maxReaction : reaction); }).x3;
      maxResponses[`no${arrNodes[i]}cortanteX2`] = selectedReactions.reduce((maxReaction, reaction) => { return (maxReaction.x2 > reaction.x2 ? maxReaction : reaction); }).x2;
      maxResponses[`no${arrNodes[i]}cortanteX1`] = selectedReactions.reduce((maxReaction, reaction) => { return (maxReaction.x1 > reaction.x1 ? maxReaction : reaction); }).x1;
      maxResponses[`no${arrNodes[i]}cortante`] = maxResponses[`no${arrNodes[i]}cortanteX2`] > maxResponses[`no${arrNodes[i]}cortanteX1`] ? maxResponses[`no${arrNodes[i]}cortanteX2`] : maxResponses[`no${arrNodes[i]}cortanteX1`];
      delete maxResponses[`no${arrNodes[i]}cortanteX2`];
      delete maxResponses[`no${arrNodes[i]}cortanteX1`];
      maxResponses[`no${arrNodes[i]}momentoX4`] = selectedReactions.reduce((maxReaction, reaction) => { return (maxReaction.x4 > reaction.x4 ? maxReaction : reaction); }).x4;
      maxResponses[`no${arrNodes[i]}momentoX5`] = selectedReactions.reduce((maxReaction, reaction) => { return (maxReaction.x5 > reaction.x5 ? maxReaction : reaction); }).x5;
      maxResponses[`no${arrNodes[i]}momentoX6`] = selectedReactions.reduce((maxReaction, reaction) => { return (maxReaction.x6 > reaction.x6 ? maxReaction : reaction); }).x6;
      const moments = [maxResponses[`no${arrNodes[i]}momentoX4`], maxResponses[`no${arrNodes[i]}momentoX5`], maxResponses[`no${arrNodes[i]}momentoX6`]];
      maxResponses[`no${arrNodes[i]}momento`] = moments.reduce((max, current) => { return (max > current ? max : current); });
      delete maxResponses[`no${arrNodes[i]}momentoX4`];
      delete maxResponses[`no${arrNodes[i]}momentoX5`];
      delete maxResponses[`no${arrNodes[i]}momentoX6`];
      if (!mastProps.qtdEstais && mastProps.ancoragemEstaiCentralChumbadoresAncoragem) {
        const distEntreChumbadores = mastProps?.ancoragemEstaiCentralChumbadoresAncoragem;
        const coef = (distEntreChumbadores / 100) * Math.sqrt(2);
        maxResponses[`no${arrNodes[i]}tracao`] = (((maxResponses[`no${arrNodes[i]}momento`] * 1000) / coef) /1000);
        maxResponses[`no${arrNodes[i]}compressao`] = (((maxResponses[`no${arrNodes[i]}momento`] * 1000) / coef) /1000);
      }
    }
    setProps({ ...props, nodesReactions: arrNodes });
    return maxResponses;
  }

  async function updateMastAnchorageData(recalculateAnchorBoltAndAnchorage) {
    let whatIsMissingMessage = "";
    if (!inputValues.alturaLaje) whatIsMissingMessage += "Altura da laje; ";
    if (!inputValues.cobrimento) whatIsMissingMessage += "Cobrimento; ";
    if (!inputValues.armadura) whatIsMissingMessage += "Armadura; ";
    if (!inputValues.espacamento) whatIsMissingMessage += "Espaçamento; ";
    if (whatIsMissingMessage) return toast.error(`Preencha todos os valores para salvar as informações da Laje. Falta(m): ${whatIsMissingMessage}`);
    try {
      const updatedMastProps = JSON.stringify({
        ...mastProps,
        ...(inputValues.alturaLaje !== "" && { alturaLaje: inputValues.alturaLaje }),
        ...(inputValues.cobrimento !== "" && { cobrimento: inputValues.cobrimento }),
        ...(inputValues.armadura !== "" && { armadura: inputValues.armadura }),
        ...(inputValues.espacamento !== "" && { espacamento: inputValues.espacamento }),
        ...{ chumbadorBlocoTipoBarra: inputValues.chumbadorBlocoTipoBarra },
        ...{ chumbadorBlocoTipoAderencia: inputValues.chumbadorBlocoTipoAderencia },
        ...{ chumbadorBlocoGancho: inputValues.chumbadorBlocoGancho },
        ...{ ferrosLajeTipoBarra: inputValues.ferrosLajeTipoBarra },
        ...{ ferrosLajeTipoAderencia: inputValues.ferrosLajeTipoAderencia },
        ...{ ferrosLajeFixacaoGancho: inputValues.ferrosLajeFixacaoGancho },
      });
      const toBeUpdatedMast = { ...mastSelected, updatedAt: new Date(), props: updatedMastProps };
      const updatedMast = await updateMast(toBeUpdatedMast, userData.token);
      if(recalculateAnchorBoltAndAnchorage) {
        const { anchorage, anchorbolt } = await calcAnchorBoltAndAnchorage({ identificador: mastProps.identificador, projectId: mastProps.projectId }, userData.token);
        const strapMessageResp = JSON.parse(updatedMast.props).strapMessage;
        strapMessageResp[3].anchorage = anchorage;
        strapMessageResp[3].anchorbolt = anchorbolt;
        setMastProps({ ...mastProps, strapMessage: strapMessageResp });
        setStapMessage(strapMessageResp);
      }
      setMastSelected(updatedMast);
      setReloadProject(!reloadProject);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações atualizadas com sucesso");
    } catch (error) {
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
      if (error?.message) return toast.error(error.message);
    }
  }

  return(
    <>
      <ResizableLeftContainer>
        <LeftTitle>Ancoragem</LeftTitle>
        <ReturnIcon onClick={() => setLeftMenuOptionSelected("metalica")}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ReturnIcon>
        <AdvanceIcon onClick={() => {setLeftMenuOptionSelected("");}}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </AdvanceIcon>
        <MenuAnchorageDoubleOption>
          <h1>Dados da Laje</h1>
          <div>
            <div>
              <h2>Altura da Laje (cm)</h2>
              <input autoFocus value={inputValues.alturaLaje} type="number" placeholder={inputValues.alturaLaje ? inputValues.alturaLaje : "Altura da laje"} onChange={(e) => setInputValues({ ...inputValues, alturaLaje: e.target.value })}></input>
            </div>
            <div>
              <h2>Cobrimento (cm)</h2>
              <input value={inputValues.cobrimento} type="number" placeholder={inputValues.cobrimento ? inputValues.cobrimento : "Cobrimento"} onChange={(e) => setInputValues({ ...inputValues, cobrimento: e.target.value })}></input>
            </div>
          </div>
        </MenuAnchorageDoubleOption>
        <MenuAnchorageDoubleOption>
          <div>
            <div>
              <h2>Armadura (mm)</h2>
              <input value={inputValues.armadura} type="number" placeholder={inputValues.armadura ? inputValues.armadura : "Armadura"} onChange={(e) => setInputValues({ ...inputValues, armadura: e.target.value })}></input>
            </div>
            <div>
              <h2>Espaçamento (cm)</h2>
              <input value={inputValues.espacamento} type="number" placeholder={inputValues.espacamento ? inputValues.espacamento : "Espaçamento"} onChange={(e) => setInputValues({ ...inputValues, espacamento: e.target.value })}></input>
            </div>
          </div>
        </MenuAnchorageDoubleOption>
        <AnchorageSummaryContainer>
          <h1>Resumo do Cálculo</h1>
          {mastProps?.tipoMastro === "MA" ? 
            <input value={"Ancoragem Central"} disabled={true}></input> : 
            <select onChange={(e) => {
              setAnchorageSelected(e.target.value);
            }}>
              <option>Ancoragem Central</option>
              <option>Ancoragem Estai 1</option>
              {mastProps?.tipoMastro !== "MH" && <option>Ancoragem Estai 2</option>}
              {mastProps?.tipoMastro === "MC" && 
              <>
                <option>Ancoragem Estai 3</option>
                <option>Ancoragem Estai 4</option>
              </>
              }
            </select>
          }
          <div>
            <AnchorageVerificationLine color={anchorageVerifications.puntion ? "green" : "#FC3333"}>
              <h3>Punção na laje: </h3> 
              <ion-icon name={anchorageVerifications.puntion ? "checkmark-outline" : "close-outline"}></ion-icon>
            </AnchorageVerificationLine>
            <AnchorageVerificationLine color={anchorageVerifications.combinedVerification ? "green" : "#FC3333"}>
              <h3>Verificação combinada:</h3>
              <ion-icon name={anchorageVerifications.combinedVerification ? "checkmark-outline" : "close-outline"}></ion-icon>
            </AnchorageVerificationLine>
            <AnchorageVerificationLine color={anchorageVerifications.chumbAnchorage ? "green" : "#FC3333"}>
              <h3>Ancoragem dos chumbadores:</h3>
              <ion-icon name={anchorageVerifications.chumbAnchorage ? "checkmark-outline" : "close-outline"}></ion-icon>
            </AnchorageVerificationLine>
            <AnchorageVerificationLine color={anchorageVerifications.ironAnchorage ? "green" : "#FC3333"}>
              <h3>Ancoragem dos ferros na laje:</h3> 
              <ion-icon name={anchorageVerifications.ironAnchorage ? "checkmark-outline" : "close-outline"}></ion-icon>
            </AnchorageVerificationLine>
          </div>
          <LeftMenuAnchorageImgDescription>
            {anchorageSelected === "Ancoragem Central" ? "Bloco Mastro" : `Bloco Estai ${anchorageSelected[anchorageSelected.length - 1]}`}
          </LeftMenuAnchorageImgDescription>
          <LeftMenuAnchorageImgContainer anchorageSelected={anchorageSelected}>
            <img src={anchorageSelected !== "Ancoragem Central" ? bloco_estai : bloco_mastro} alt=""/>
          </LeftMenuAnchorageImgContainer>
          <ParametroAncoragemButton onClick={() => {setParametroAncoragemIsClicked(!parametroAncoragemIsClicked);}} isClicked={parametroAncoragemIsClicked}>
            Parâmetro Ancoragem {"  >>"}
          </ParametroAncoragemButton>
        </AnchorageSummaryContainer>
        <ButtonPositioner >
          <ReturnButton onClick={() => {
            if (1 === 2) {
              if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("");
            }
            else setLeftMenuOptionSelected("");
          }} >{" Voltar "}</ReturnButton>
          {updateMastLoading ?           
            <LoadingButton>{ "Carregando" }</LoadingButton> :
            <SavingButton disabled={!isInputModified} onClick={() => { if(isInputModified) { updateMastAnchorageData(); }; }}>{ "Salvar" }</SavingButton>}
        </ButtonPositioner>
      </ResizableLeftContainer>
      <ModalParametroAncoragem isVisible={parametroAncoragemIsClicked}>
        <div>
          Ancoragem do Chumbador no Bloco
        </div>
        <div>
          <div>Tipo de Barra</div>
          <select value={inputValues.chumbadorBlocoTipoBarra} onChange={(e) => setInputValues({ ...inputValues, chumbadorBlocoTipoBarra: e.target.value })}>
            <option value={"Lisa"}>Lisa</option>
            <option value={"Nervurada"}>Nervurada</option>
          </select>
        </div>
        <div>
          <div>Tipo de Aderencia</div>
          <select value={inputValues.chumbadorBlocoTipoAderencia} onChange={(e) => setInputValues({ ...inputValues, chumbadorBlocoTipoAderencia: e.target.value })}>
            <option>Selecione um valor...</option>
            <option value={"Boa"}>Boa</option>
            <option value={"Má"}>Má</option>
          </select>
        </div>
        <div>
          <div>Fixação com Gancho?</div>
          <select value={inputValues.chumbadorBlocoGancho} onChange={(e) => setInputValues({ ...inputValues, chumbadorBlocoGancho: e.target.value })}>
            <option value={"Sim"}>Sim</option>
            <option value={"Não"}>Não</option>
          </select>
        </div>
        <div>
          Ancoragem dos Ferros na Laje
        </div>
        <div>
          <div>Tipo de Barra</div>
          <select value={inputValues.ferrosLajeTipoBarra} onChange={(e) => setInputValues({ ...inputValues, ferrosLajeTipoBarra: e.target.value })}>
            <option value={"Lisa"}>Lisa</option>
            <option value={"Nervurada"}>Nervurada</option>
          </select>
        </div>
        <div>
          <div>Tipo de Aderencia</div>
          <select value={inputValues.ferrosLajeTipoAderencia} onChange={(e) => setInputValues({ ...inputValues, ferrosLajeTipoAderencia: e.target.value })}>
            <option value={"Boa"}>Boa</option>
            <option value={"Má"}>Má</option>
          </select>
        </div>
        <div>
          <div>Fixação com Gancho?</div>
          <select value={inputValues.ferrosLajeFixacaoGancho} onChange={(e) => setInputValues({ ...inputValues, ferrosLajeFixacaoGancho: e.target.value })}>
            <option value={"Sim"}>Sim</option>
            <option value={"Não"}>Não</option>
          </select>
        </div>
        <SavingButton onClick={async() => { await updateMastAnchorageData(true); setParametroAncoragemIsClicked(false); }}>{ "Recalcular" }</SavingButton>
      </ModalParametroAncoragem>
      <ResizableRightContainer>
        <ProjectNameWrapper>
          <h2><span onClick={() => handleMenuClick("project")}>Projetos</span> / <span onClick={() => handleMenuClick("projectName")}>{projectSelected?.nomeProjeto}</span> <span onClick={() => handleMenuClick("")}>{mastSelected?.identificador ? ` / ${mastSelected?.identificador}` : ""}</span></h2>
          <h1><span>Ancoragem</span></h1>
          <div />
        </ProjectNameWrapper>
        <AnchorageDataContainer>
          <AnchorageCard mastProps={props} maxResponses={maxResponses} anchorageSelected={anchorageSelected} title={"Ancoragem Central"} index={1} anchorageResults={anchorageResults} setAnchorageResults={setAnchorageResults} anchorBoltResults={anchorBoltResults} setAnchorBoltResults={setAnchorBoltResults} reloadLeftSummary={reloadLeftSummary} setReloadLeftSummary={setReloadLeftSummary} firstOpening={firstOpening} inputValues={inputValues}></AnchorageCard>
          {Array.from({ length: mastProps?.qtdEstais }, (_, index) => (
            <AnchorageCard
              mastProps={props}
              key={`anchorage-${index}`}
              index={Number(mastProps.qtdModulos)+index+2}
              anchorageSelected={anchorageSelected}
              title={`Ancoragem Estai ${index + 1}`}
              maxResponses={maxResponses}
              anchorageResults={anchorageResults}
              setAnchorageResults={setAnchorageResults}
              anchorBoltResults={anchorBoltResults}
              setAnchorBoltResults={setAnchorBoltResults}
              reloadLeftSummary={reloadLeftSummary}
              setReloadLeftSummary={setReloadLeftSummary}
              firstOpening={firstOpening} 
              aux={index+1}
              inputValues={inputValues}
            />
          ))}
        </AnchorageDataContainer>
      </ResizableRightContainer>
    </>
  );
}

function AnchorageCard({ anchorageSelected, title, mastProps, maxResponses, index, anchorageResults, anchorBoltResults,  reloadLeftSummary, setReloadLeftSummary, firstOpening, setFirstOpening, aux, inputValues }) {
  // const [isCardOpen, setIsCardOpen] = useState(title !== "Ancoragem Estai 3" && title !== "Ancoragem Estai 4" && title !== "Ancoragem Estai 3" && title !== "Ancoragem Estai 5" && title !== "Ancoragem Estai 6");
  const [isCardOpen, setIsCardOpen] = useState(true);
  const [isTensionOpen, setIsTensionOpen] = useState(false);
  const [isPuntionOpen, setIsPuntionOpen] = useState(false);
  const [isAnchorOpen, setIsAnchorOpen] = useState(false);
  const [isBlockOpen, setIsBlockOpen] = useState(false);
  const [isSlabVerified, setIsSlabVerified] = useState(true);
  let titleAux;
  let estaiNumberAux;
  if (title.indexOf("Central") !== -1) {
    titleAux = "ancoragemEstaiCentral";
    estaiNumberAux = 0;
  } 
  else {
    titleAux = `ancoragemEstai${title[title.length-1]}`;
    estaiNumberAux = aux;
  }
  useEffect(() => {
    if (!firstOpening) {
      if (anchorageSelected === title) setIsCardOpen(true);
      else setIsCardOpen(false);
    }
  }, [anchorageSelected]);

  const cardAnimation = useSpring({
    width: isCardOpen ? 330 : 60,
    config: { duration: 300, timingFunction: "ease-in-out" },
  });
  const toggleInnerCard = (functionName) => {
    functionName((prevState) => !prevState);
  };

  function calculatePunchingShearSlabResults() {
    const props = mastProps;
    const punchingShearSlabResult = {};
    punchingShearSlabResult.cover = inputValues.cobrimento || props.cobrimento || 2;
    punchingShearSlabResult.fck = inputValues.ancoragemEstaiCentralConcreto || props.ancoragemEstaiCentralConcreto || 20;
    punchingShearSlabResult.slabHeight = inputValues.alturaLaje || props.alturaLaje || 12;
    punchingShearSlabResult.reinforcementDiameter = inputValues.armadura || props.armadura || 6;
    punchingShearSlabResult.spacing = inputValues.espacamento || props.espacamento || 25;
    for (let i = 0; i <= props?.qtdEstaisAnchorage; i++) {
      if (i === 0) {
        const blockWeight = props.ancoragemEstaiCentralL1*props.ancoragemEstaiCentralL2*props.ancoragemEstaiCentralH*0.0025;
        punchingShearSlabResult.criticalPerimeterC = (props.ancoragemEstaiCentralL1+props.ancoragemEstaiCentralL2)*2;
        setPunchingShearSlab(punchingShearSlabResult, blockWeight);
      } else {
        const blockWeight = props[`ancoragemEstai${i}L1`]*props[`ancoragemEstai${i}L2`]*props[`ancoragemEstai${i}H`]*0.0025;
        punchingShearSlabResult.criticalPerimeterC = (props[`ancoragemEstai${i}L1`]+props[`ancoragemEstai${i}L2`])*2;
        setPunchingShearSlab(punchingShearSlabResult, blockWeight);
      }
    };

    function setPunchingShearSlab(punchingShearSlabResult, blockWeight) {
      punchingShearSlabResult.criticalPerimeterCLine = punchingShearSlabResult.criticalPerimeterC+2*Math.PI*(2*(punchingShearSlabResult.slabHeight-5));
      punchingShearSlabResult.tRD2 = 0.27*(1-(punchingShearSlabResult.fck/250))*(punchingShearSlabResult.fck/1.4);
      punchingShearSlabResult.tSDC = 1.4*((punchingShearSlabResult.slabHeight+blockWeight)/100)/(punchingShearSlabResult.criticalPerimeterC*(punchingShearSlabResult.slabHeight-5))*10;
      punchingShearSlabResult.tSDCLine = 1.4*((punchingShearSlabResult.slabHeight+blockWeight)/100)/(punchingShearSlabResult.criticalPerimeterCLine*(punchingShearSlabResult.slabHeight-5))*10;
      punchingShearSlabResult.p = ((((punchingShearSlabResult.reinforcementDiameter/10)**2)*Math.PI/4)*(100/punchingShearSlabResult.spacing)/(punchingShearSlabResult.slabHeight*100));
      punchingShearSlabResult.resistence = 0.13*(1+((punchingShearSlabResult.fck/(punchingShearSlabResult.slabHeight-5))**0.5))*((100*punchingShearSlabResult.p*punchingShearSlabResult.slabHeight)**(1/3));
      punchingShearSlabResult.utilization = ((punchingShearSlabResult.tSDCLine/punchingShearSlabResult.resistence)*100).toFixed(2)+"%";
      punchingShearSlabResult.verification = punchingShearSlabResult.resistence > punchingShearSlabResult.tSDCLine ? "OK" : "NOT OK";
      setIsSlabVerified(punchingShearSlabResult.resistence > punchingShearSlabResult.tSDCLine ? true : false);
    }
  }

  useEffect(() => {
    calculatePunchingShearSlabResults();
  }, [inputValues]);
  const necessaryAnchorBolt = (anchorBoltResults[estaiNumberAux][titleAux] && !isNaN(Number(anchorBoltResults[estaiNumberAux][titleAux][10]?.split(": ")[1]?.split(" cm")[0]))) ? Number(anchorBoltResults[estaiNumberAux][titleAux][10]?.split(": ")[1]?.split(" cm")[0]) : 60;
  const existingAnchorBolt = mastProps?.ancoragemEstaiCentralChumbadoresAncoragem || 100;
  const verifiedAnchorBolt = ((necessaryAnchorBolt / existingAnchorBolt) * 100).toFixed(2) || "-";
  const necessaryAnchorage = anchorageResults[estaiNumberAux][titleAux] ? Number(anchorageResults[estaiNumberAux][titleAux][anchorageResults[estaiNumberAux][titleAux].length - 2]?.split(": ")[1]?.split(" cm")[0]) : 60;
  const existingAnchorage = Number(mastProps?.ancoragemEstaiCentralFerrosAncoragem) || 100;
  const verifiedAnchorage = ((necessaryAnchorage / existingAnchorage) * 100).toFixed(2);
  return (
    <ClosedAnchorageCard title={title} isCardOpen={isCardOpen} style={cardAnimation} >
      <h1>{title}</h1>
      {isCardOpen && 
        <OpenedAnchorageCard title={title} isCardOpen={isCardOpen} style={cardAnimation}>
          <h2>{title}</h2>
          <CardValuesContainer isOpen={isTensionOpen} minClosedHeight={"40px"} minOpenHeight={"180px"}>
            <CardValueTitle>Esforços Atuantes</CardValueTitle>
            <VerticalSpacing />
            <img src={arrow_icon} onClick={() => {
              if (!anchorBoltResults[estaiNumberAux][titleAux]) return;
              toggleInnerCard(setIsTensionOpen);
              if (isAnchorOpen && isBlockOpen) {
                setIsAnchorOpen(false);
                setIsBlockOpen(false);
              }
            }} alt=""/>
            {isTensionOpen && <>
              <CardTextContainer isOpen={isTensionOpen}><h3>Compressão</h3><p>{(maxResponses[`no${index}compressao`]*1000)?.toFixed(0)} kgf</p></CardTextContainer>
              <CardTextContainer isOpen={isTensionOpen}><h3>Tração</h3><p>{Math.abs(maxResponses[`no${index}tracao`]*1000)?.toFixed(0)} kgf</p></CardTextContainer>
              <CardTextContainer isOpen={isTensionOpen}><h3>Momento</h3><p>{maxResponses[`no${index}momento`]*1000} kgf</p></CardTextContainer>
              <CardTextContainer isOpen={isTensionOpen}><h3>Cortante</h3><p>{maxResponses[`no${index}cortante`]*1000} kgf</p></CardTextContainer>
            </>}
          </CardValuesContainer>
          <CardValuesContainer isOpen={isPuntionOpen} minClosedHeight={"90px"} minOpenHeight={"190px"}>
            <CardValueTitle>Punção na Laje</CardValueTitle>
            <VerticalSpacing />
            <img src={arrow_icon} onClick={() => {
              if (!anchorBoltResults[estaiNumberAux][titleAux]) return;
              toggleInnerCard(setIsPuntionOpen);
              if (isAnchorOpen && isBlockOpen) {
                setIsAnchorOpen(false);
                setIsBlockOpen(false);
              }
            }} alt="" />
            {!isPuntionOpen && <>
              <CardTextContainer isOpen={!isPuntionOpen} emConformidade={isSlabVerified}><h4>{isSlabVerified ? "EM CONFORMIDADE" : "EM NÃO CONFORMIDADE"}</h4></CardTextContainer>
            </>}
            {isPuntionOpen && <>
              <CardTextContainer isOpen={isPuntionOpen}><h3>H Laje</h3><HorizontalSpacing /><p>{inputValues.alturaLaje || 12} cm</p></CardTextContainer>
              <CardTextContainer isOpen={isPuntionOpen}><h3>Armadura</h3><HorizontalSpacing /><p>Ø {Number(inputValues.armadura).toFixed(1) || "6.0"}mm c/{inputValues.espacamento || 25}cm</p></CardTextContainer>
              <CardTextContainer isOpen={isPuntionOpen}><h3>Verificação</h3><HorizontalSpacing /><p>{"τSD-C'<τ-RD2"}</p></CardTextContainer>
              <CardTextContainer isOpen={isPuntionOpen} emConformidade={isSlabVerified}><h4>{isSlabVerified ? "EM CONFORMIDADE" : "EM NÃO CONFORMIDADE"}</h4></CardTextContainer>
            </>}
          </CardValuesContainer>
          <CardValuesContainer isOpen={isAnchorOpen} minClosedHeight="240px" minOpenHeight="280px">
            <CardValueTitle>Chumbadores</CardValueTitle>
            <VerticalSpacing />
            <img src={arrow_icon}  onClick={() => {
              if (!anchorBoltResults[estaiNumberAux][titleAux]) return;
              toggleInnerCard(setIsAnchorOpen);
              if (!isAnchorOpen && isBlockOpen) setIsTensionOpen(false);
              if (!isAnchorOpen && isBlockOpen) setIsPuntionOpen(false);
            }} alt="" />
            {!isAnchorOpen && <>
              <CardTextContainer isOpen={!isAnchorOpen}><h6>Verificação Combinada </h6></CardTextContainer>
              <CardTextContainer isOpen={!isAnchorOpen}><h5>(Tração e Compressão): {anchorBoltResults[estaiNumberAux][titleAux] ? anchorBoltResults[estaiNumberAux][titleAux][6].split(": ")[1] : "80"}%</h5></CardTextContainer>
              <CardTextContainer isOpen={!isAnchorOpen} emConformidade={anchorBoltResults[estaiNumberAux][titleAux] && anchorBoltResults[estaiNumberAux][titleAux][8] === "EM CONFORMIDADE"}><h4>{anchorBoltResults[estaiNumberAux][titleAux] ? anchorBoltResults[estaiNumberAux][titleAux][8] : ""}</h4></CardTextContainer>
              <CardTextContainer isOpen={!isAnchorOpen}><h6>Verificação Ancoragem </h6></CardTextContainer>
              <CardTextContainer isOpen={!isAnchorOpen}><h5>(Chumbador no bloco): {verifiedAnchorBolt}%</h5></CardTextContainer>
              <CardTextContainer isOpen={!isAnchorOpen} emConformidade={anchorBoltResults[estaiNumberAux][titleAux] && anchorBoltResults[estaiNumberAux][titleAux][anchorBoltResults[estaiNumberAux][titleAux]?.length-1] === "EM CONFORMIDADE"}><h4>{anchorBoltResults[estaiNumberAux][titleAux] && anchorBoltResults[estaiNumberAux][titleAux][anchorBoltResults[estaiNumberAux][titleAux]?.length-1]}</h4></CardTextContainer>
            </>}
            {isAnchorOpen && <>
              <CardTextContainer isOpen={isAnchorOpen}><h3>Chumbadores</h3><p>{mastProps[`ancoragemEstai${title?.split(" ")[title?.split(" ")?.length-1]}ChumbadoresQtd`]} x ø{mastProps[`ancoragemEstai${title?.split(" ")[title?.split(" ")?.length-1]}ChumbadoresDiametro`]?.split("- ")[1]}</p></CardTextContainer>
              <CardTextContainer isOpen={isAnchorOpen}><h3>Ancoragem</h3><p>{mastProps[`ancoragemEstai${title?.split(" ")[title?.split(" ")?.length-1]}ChumbadoresAncoragem`]} cm</p></CardTextContainer>
              <CardTextContainer isOpen={isAnchorOpen}><h6>Verificação Combinada </h6></CardTextContainer>
              <CardTextContainer isOpen={isAnchorOpen}><h5>(Tração e Compressão): {anchorBoltResults[estaiNumberAux][titleAux]? anchorBoltResults[estaiNumberAux][titleAux][6]?.split(": ")[1] : "80"}%</h5></CardTextContainer>
              <CardTextContainer isOpen={isAnchorOpen} emConformidade={anchorBoltResults[estaiNumberAux][titleAux] && anchorBoltResults[estaiNumberAux][titleAux][8] === "EM CONFORMIDADE"}><h4>{anchorBoltResults[estaiNumberAux][titleAux] ? anchorBoltResults[estaiNumberAux][titleAux][8] : ""}</h4></CardTextContainer>
              <CardTextContainer isOpen={isAnchorOpen}><h6>Verificação Ancoragem </h6></CardTextContainer>
              <CardTextContainer isOpen={isAnchorOpen}><h5>(Chumbador no bloco): {verifiedAnchorBolt}%</h5></CardTextContainer>
              <CardTextContainer isOpen={isAnchorOpen} emConformidade={anchorBoltResults[estaiNumberAux][titleAux][anchorBoltResults[estaiNumberAux][titleAux]?.length-1] === "EM CONFORMIDADE"}><h4>{anchorBoltResults[estaiNumberAux][titleAux][anchorBoltResults[estaiNumberAux][titleAux]?.length-1]}</h4></CardTextContainer>
            </>}
          </CardValuesContainer>
          <CardValuesContainer isOpen={isBlockOpen} minClosedHeight="140px" minOpenHeight="320px">
            <CardValueTitle>Bloco de Ancoragem</CardValueTitle>
            <VerticalSpacing />
            <img src={arrow_icon} onClick={() => {
              if (!anchorBoltResults[estaiNumberAux][titleAux]) return;
              toggleInnerCard(setIsBlockOpen);
              if (!isBlockOpen && isAnchorOpen) setIsTensionOpen(false);
              if (!isBlockOpen && isAnchorOpen) setIsPuntionOpen(false);
            }} alt="" />
            {!isBlockOpen && <>
              <CardTextContainer isOpen={!isBlockOpen}><h6>Verificação Ancoragem </h6></CardTextContainer>
              <CardTextContainer isOpen={!isBlockOpen}><h5>(Ferros do bloco na laje): {verifiedAnchorage}%</h5></CardTextContainer>
              <CardTextContainer isOpen={!isBlockOpen} emConformidade={anchorageResults[estaiNumberAux][titleAux] && anchorageResults[estaiNumberAux][titleAux][anchorageResults[estaiNumberAux][titleAux]?.length-1] === "EM CONFORMIDADE"}><h4>{anchorageResults[estaiNumberAux][titleAux] && anchorageResults[estaiNumberAux][titleAux][anchorageResults[estaiNumberAux][titleAux]?.length-1]}</h4></CardTextContainer>
            </>}
            {isBlockOpen && <>
              <CardTextContainer isOpen={isBlockOpen}><h3>Dimensões do bloco:</h3><p>{mastProps[`ancoragemEstai${title?.split(" ")[title?.split(" ")?.length-1]}H`]}x{mastProps[`ancoragemEstai${title?.split(" ")[title?.split(" ")?.length-1]}L1`]}x{mastProps[`ancoragemEstai${title?.split(" ")[title?.split(" ")?.length-1]}L2`]} cm</p></CardTextContainer>
              <CardTextContainer isOpen={isBlockOpen}><h3>Ancoragem Ferros na Laje</h3><p>{mastProps[`ancoragemEstai${title?.split(" ")[title?.split(" ")?.length-1]}FerrosQtd`]} x ø{mastProps[`ancoragemEstai${title?.split(" ")[title?.split(" ")?.length-1]}FerrosDiametro`]?.split("- ")[1]}</p></CardTextContainer>
              <CardTextContainer isOpen={isBlockOpen}><h3>Ancoragem</h3><p>{mastProps[`ancoragemEstai${title?.split(" ")[title?.split(" ").length-1]}FerrosAncoragem`]} cm</p></CardTextContainer>
              <CardTextContainer isOpen={isBlockOpen}><h6>Verificação Ancoragem </h6></CardTextContainer>
              <CardTextContainer isOpen={isBlockOpen}><h5>(ferros do bloco na laje): {verifiedAnchorage}%</h5></CardTextContainer>
              <CardTextContainer isOpen={isBlockOpen} emConformidade={anchorageResults[estaiNumberAux][titleAux] && anchorageResults[estaiNumberAux][titleAux][anchorageResults[estaiNumberAux][titleAux]?.length-1] === "EM CONFORMIDADE"}><h4>{anchorageResults[estaiNumberAux][titleAux] && anchorageResults[estaiNumberAux][titleAux][anchorageResults[estaiNumberAux][titleAux]?.length-1]}</h4></CardTextContainer>
            </>}
          </CardValuesContainer>
        </OpenedAnchorageCard>
      }
    </ClosedAnchorageCard>
  );
}

const AnchorageSummaryContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  margin-top: 12px;
  
  input,select {
    width: 100%;
    background-color: #ffffff;
    height: 4.5vh;
    padding: 10px;
    border: 1px solid #C8C8C8;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #000;
    margin-bottom: 14px;
  }

  input::placeholder ,select::placeholder {
    color: #565565;
  }
   h1 {
    margin-bottom: 8px !important;
    font-size: 17px !important;
    font-family: 'Roboto', sans-serif !important;
    color: #313131 !important;
  }
`;

const AnchorageVerificationLine = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
  color: #333333;
  margin-top: 8px;
  h3 {
    font-size: 16px;
    color: #000;
  }
  ion-icon {
    font-size: 22px;
    color: ${(props) => props.color};
    margin-left: 10px;
  }

`;

const ClosedAnchorageCard = styled(animated.div)`
  display: flex;
  height: 95%;
  position: relative;
  margin: 0 10px;
  flex-direction: column;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  h1 {
    color: #FE8F03;
    transform: rotate(270deg);
    white-space: nowrap; 
    position: fixed;
    min-height: 50px;
    top: 170px;
    left: ${(props) => (props.title === "Ancoragem Central" ? "-90px" : "-86px")};
    z-index: 2;
    font-size: 20px;
    font-weight: 700;
    opacity: ${(props) => (!props.isCardOpen ? "1" : "0" )};
    transition: all 700ms ease-in-out;
  }

  ion-icon {
    transform: ${(props) => (props.isCardOpen ? "rotate(270deg)" : "rotate(90deg)" )}; 
    position: absolute;
    z-index: 2;
    color: #F16E00;
    font-size: 26px;
    top: 11px;
    left: ${(props) => (!props.isCardOpen ? "17px" : "280px" )};
    transition-property: left, transform;
    transition-duration: 700ms;
    transition-timing-function: ease-in-out; 
    cursor: pointer;
  }
`;

const OpenedAnchorageCard = styled(animated.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 330px;
  height: 100%;
  margin: 0 10px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    height: 7px;
    cursor: pointer;
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #FE8F03; 
    width: 5px;
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #F2F2F2;
    cursor: pointer;
  }
  h2 {
    width: 88%;
    color: #313131;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-height: 50px;
    font-weight: 500;
    margin-bottom: -10px;
    font-size: 18px;
    opacity: ${(props) => (!props.isOpen ? "1" : "0" )};
    transition: all 300ms ease-in-out;
  }
`;

const CardValuesContainer = styled(animated.div)`
  width: 90%;
  margin: 5px 0px;
  min-height: ${(props) => (props.isOpen ? props.minOpenHeight  : props.minClosedHeight )}; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #fff;
  position: relative;
  transition: all 300ms ease-in-out;
  font-size: 16px;
  background-color: #F2F2F2;
  border-radius: 8px;

  img {
    position: absolute;
    top: 9px;
    left: 254px;
    width: 24px;
    cursor: pointer;
    transform: ${(props) => (props.isOpen ? "rotate(180deg)" : "rotate(360deg)" )}; 
  }
  ion-icon {
    transform: ${(props) => (props.isOpen ? "rotate(90deg)" : "rotate(270deg)" )}; 
    position: absolute;
    z-index: 2;
    color: #7F7F7F;
    font-size: 23px;
    top: 8px;
    left: 244px;
    cursor: pointer;
  }
`;
const VerticalSpacing = styled.div`
  margin-bottom: 44px;
`;
const CardValueTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  height: 44px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-weight: 500;
  padding-left: 30px;
  background-color: #E8E8E8;
`;

const LeftMenuAnchorageImgDescription = styled.div`
  display: flex;
  font-weight: 600;
  margin: 14px 0 14px 0;
`;

const LeftMenuAnchorageImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25vh;
  background-color: #fff;
  border: 1px solid #C8C8C8;
  img {
    width: ${(props) => (props.anchorageSelected === "Ancoragem Central" ? "80%" : "74%")};
  }
`;

const CardTextContainer  = styled.div`
  opacity: ${(props) => (props.isOpen ? "1" : "0" )};
  transition: all 700ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 6px;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  h3 {
    font-weight: 600;
    font-size: 15px;
  }
  h4 {
    color: ${(props) => (props.emConformidade ? "green" : "#FC3333")};
    text-align: center;
    width: 100%;
    font-size: 15px;
  }
  h5 {
    margin-top: -10px;
    text-align: center;
    width: 100%;
    font-size: 15px;
  }
  h6 {
    font-weight: 600;
    padding-left: 40px;
  }
  p {
    text-align: right;
    padding-right: 12px;
  }
`;

const AnchorageDataContainer = styled.div`
  display: flex;
  width: 100%;
  height: 80%;
  align-items: center;
  overflow-y: hidden !important;
  overflow-x: scroll;
  padding: 0 20px !important;

  ::-webkit-scrollbar {
    height: 7px;
    cursor: pointer;
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #FE8F03; 
    width: 7px;
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }
  ::-webkit-scrollbar-track-piece {
    background-color: #F2F2F2;
    cursor: pointer;
  }
`;

const HorizontalSpacing = styled.span`
  margin-left: 18px;
`;

const ProjectNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 93%;
  margin-top: -5.3vh;
  margin-left: -5px;

  h2 {
    color: #666666;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  h1 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
  }
    span {
    transition: 0.0001s all;
    cursor: pointer;
    :hover {
      font-weight: 600 !important;
    }
  }
  >div {
    width: 100%;
    height: 1.25px;
    margin: -18px 0 0px 0;
    background-color: #D9D9D9;
  }
`;

export const OpeningAndClosingIcon = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: #FE8F03;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const MenuAnchorageDoubleOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0.75vh 0;
  position: relative;

  h1 {
    margin-bottom: 8px;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    color: #313131;
  }

  h2 {
    font-weight: 300;
    font-size: 16px;
    color: #313131;
    margin-bottom: 0.4vh;
  }

  input,select {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4.5vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }

  ion-icon {
    position: absolute;
    top: 0;
    right: 0;
    color: #ffffff;
  }

  >div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  >div>div{
    width: 46%;
  }

  h4 {
    margin-bottom: 6px;
  }
`;

const ParametroAncoragemButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 4.5vh;
  margin-top: 10px;
  border-radius: 8px;
  font-family: 'Roboto', sans-serif;;
  font-size: 16px;
  cursor: pointer;
  background-color: ${(props) => (props.isClicked ? "#FE8F03" : "#D0CECE")};
  color: ${(props) => (props.isClicked ? "#fff" : "#313131")};
  img {
    margin-left: 10px;
    width: 22px;
  }
`;

const ModalParametroAncoragem = styled.div`
  padding: 5px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: absolute;
  z-index: 1;
  bottom: calc(50% - 270px);
  left: calc(25%);
  background-color: #F2F2F2;
  width: 360px;
  height: 600px;
  border-radius: 5px;
  display: ${(props) => props.isVisible ? "flex" : "none"};
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border-left: 1px solid #C8C8C8;


  input,select {
    width: 100%;
    background-color: #ffffff;
    height: 4.5vh;
    padding: 10px;
    border: 1px solid #C8C8C8;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #000;
    margin-bottom: 14px;
  }

  div {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  div:last-child {
    align-items: center;
  }
`;
