import api from "./api";

export async function postNewMast(props, token) {
  const response = await api.post("/masts/newMast", props, {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};

export async function getMasts(projectId, token) {
  const response = await api.get(`/masts/project/${projectId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};

export async function getMast(identificador, token) {
  const response = await api.get(`/masts/getOne/${identificador}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};

export async function updateMast(projectProps, token) {
  if (typeof projectProps.userId === "string") {
    projectProps.userId = Number(projectProps.userId);
  };
  if (projectProps.strapMessage) delete projectProps.strapMessage;
  const response = await api.put("/masts/update", projectProps, {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};

export async function postCalculateStrap(props, action, token) {
  const response = await api.post(`/masts/strap/${action}`, props, {
    headers: {
      Authorization: `Bearer ${token}`,
    }, responseType: "blob" });
  return response.data;
};

export async function updateStrapScript(props, tipo, token) {
  const response = await api.put(`/masts/updateScript/${tipo}`, props, {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};

export async function deleteMast(mastId, token) {
  const response = await api.delete(`/masts/${mastId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};

export async function getWindVelocity(coordinates, token) {
  const response = await api.get(`/masts/windVelocity/${coordinates.latitude}/${coordinates.longitude}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};

export async function postMastAccessHistory(mastId, token) {
  const response = await api.post("/masts/accessHistory", { mastId }, {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};

export async function postMastReportInfoToDownload(identificador, nomeProjeto, documentType, token) {
  const response = await api.post("/masts/downloadMastReport", { identificador, nomeProjeto, documentType }, {
    headers: {
      Authorization: `Bearer ${token}`,
    }, responseType: "blob" });
  return response.data;
}

export async function getAnchorBoltAndAnchorageResult({ projectId, identificador }, token) {
  const response = await api.put("/masts/recalculateAnchorboltAndAnchorage", { projectId, identificador }, {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};

export async function postDownloadMastDWG(identificador, projectId, token) {
  const response = await api.post("/masts/downloadMastDwg", { identificador, projectId, token }, {
    headers: {
      Authorization: `Bearer ${token}`,
    }, responseType: "blob" });
  return response.data;
}
