import useAsync from "../../useAsync";
import { getDiagonals, postDiagonals, updateDiagonals } from "../../../services/tower-services/towerDiagonalsApi";

export const useTowerDiagonals = (action) => {
  const execute = {
    get: getDiagonals,
    post: postDiagonals,
    update: updateDiagonals,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
