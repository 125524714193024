import MG_1_1 from "./1MOD/MG-1-1.jpg";
import MG_1_2 from "./1MOD/MG-1-2.jpg";
import MG_1_3 from "./1MOD/MG-1-3.jpg";
import MG_1_4 from "./1MOD/MG-1-4.jpg";
import MG_1_5 from "./1MOD/MG-1-5.jpg";
import MG_1_6 from "./1MOD/MG-1-6.jpg";
import MG_1_7 from "./1MOD/MG-1-7.jpg";
import MG_1_8 from "./1MOD/MG-1-8.jpg";
import MG_1_9 from "./1MOD/MG-1-9.jpg";
import MG_1_10 from "./1MOD/MG-1-10.jpg";

import MG_2_2 from "./2MOD/MG-2-2.jpg";
import MG_2_3 from "./2MOD/MG-2-3.jpg";
import MG_2_4 from "./2MOD/MG-2-4.jpg";
import MG_2_5 from "./2MOD/MG-2-5.jpg";
import MG_2_6 from "./2MOD/MG-2-6.jpg";
import MG_2_7 from "./2MOD/MG-2-7.jpg";
import MG_2_8 from "./2MOD/MG-2-8.jpg";
import MG_2_9 from "./2MOD/MG-2-9.jpg";
import MG_2_10 from "./2MOD/MG-2-10.jpg";

import MG_3_3 from "./3MOD/MG-3-3.jpg";
import MG_3_4 from "./3MOD/MG-3-4.jpg";
import MG_3_5 from "./3MOD/MG-3-5.jpg";
import MG_3_6 from "./3MOD/MG-3-6.jpg";
import MG_3_7 from "./3MOD/MG-3-7.jpg";
import MG_3_8 from "./3MOD/MG-3-8.jpg";
import MG_3_9 from "./3MOD/MG-3-9.jpg";
import MG_3_10 from "./3MOD/MG-3-10.jpg";

import MG_4_4 from "./4MOD/MG-4-4.jpg";
import MG_4_5 from "./4MOD/MG-4-5.jpg";
import MG_4_6 from "./4MOD/MG-4-6.jpg";
import MG_4_7 from "./4MOD/MG-4-7.jpg";
import MG_4_8 from "./4MOD/MG-4-8.jpg";
import MG_4_9 from "./4MOD/MG-4-9.jpg";
import MG_4_10 from "./4MOD/MG-4-10.jpg";

import MG_5_5 from "./5MOD/MG-5-5.jpg";
import MG_5_6 from "./5MOD/MG-5-6.jpg";
import MG_5_7 from "./5MOD/MG-5-7.jpg";
import MG_5_8 from "./5MOD/MG-5-8.jpg";
import MG_5_9 from "./5MOD/MG-5-9.jpg";
import MG_5_10 from "./5MOD/MG-5-10.jpg";

import MG_6_6 from "./6MOD/MG-6-6.jpg";
import MG_6_7 from "./6MOD/MG-6-7.jpg";
import MG_6_8 from "./6MOD/MG-6-8.jpg";
import MG_6_9 from "./6MOD/MG-6-9.jpg";
import MG_6_10 from "./6MOD/MG-6-10.jpg";

import MG_7_7 from "./7MOD/MG-7-7.jpg";
import MG_7_8 from "./7MOD/MG-7-8.jpg";
import MG_7_9 from "./7MOD/MG-7-9.jpg";
import MG_7_10 from "./7MOD/MG-7-10.jpg";

import MG_8_8 from "./8MOD/MG-8-8.jpg";
import MG_8_9 from "./8MOD/MG-8-9.jpg";
import MG_8_10 from "./8MOD/MG-8-10.jpg";

import MG_9_9 from "./9MOD/MG-9-9.jpg";
import MG_9_10 from "./9MOD/MG-9-10.jpg";

import MG_10_10 from "./10MOD/MG-10-10.jpg";

const MGImg = { MG_1_1, MG_1_2, MG_1_3, MG_1_4, MG_1_5, MG_1_6, MG_1_7, MG_1_8, MG_1_9, MG_1_10, MG_2_2, MG_2_3, MG_2_4, MG_2_5, MG_2_6, MG_2_7, MG_2_8, MG_2_9, MG_2_10, MG_3_3, MG_3_4, MG_3_5, MG_3_6, MG_3_7, MG_3_8, MG_3_9, MG_3_10, MG_4_4, MG_4_5, MG_4_6, MG_4_7, MG_4_8, MG_4_9, MG_4_10, MG_5_5, MG_5_6, MG_5_7, MG_5_8, MG_5_9, MG_5_10, MG_6_6, MG_6_7, MG_6_8, MG_6_9, MG_6_10, MG_7_7, MG_7_8, MG_7_9, MG_7_10, MG_8_8, MG_8_9, MG_8_10, MG_9_9, MG_9_10, MG_10_10 };

export default MGImg;
