import { useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { horizontalLockingsInfo } from "../../../constants/tower-trusses-data";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { TowerContext } from "../../../contexts/TowerContext";
import UserContext from "../../../contexts/UserContext";
import { useTowerHorizontalLockings } from "../../../hooks/api/tower-api/useTowerHorizontalLockings";
import useUnsavedChangesAlertForObjects from "../../../hooks/useUnsavedChangesAlertForObjects";
import LeftMenu from "../../common/left-menu";
import RightContainer from "../../common/right-container";
import TowerHorizontalLockingLeftMenu from "./tower_horizontalLockingLeftMenu";
import TowerHorizontalLockingRightContainer from "./tower_horizontalLockingRightContainer";
import HorizontalLockingsModel from "../../../models/HorizontalLockingModel";

export default function TowerHorizontalLockingPage() {
  const { userData } = useContext(UserContext);
  const { towerId, towerImages, segmentsSelected, geometrySelected, trussesSelected,
    horizontalLockingSelected, setHorizontaLockingSelected } = useContext(TowerContext);
  const { reloadProject, setIsUpdatedWithoutCalculation, isLoading, setIsLoading } = useContext(ProjectContext);

  const { act: fetchHorizontalLockings } = useTowerHorizontalLockings("get");
  const { act: saveHorizontalLockings } = useTowerHorizontalLockings("post");

  const nSegments = Number(geometrySelected.nSegments) || 0;
  const sectionType = geometrySelected.towerSection;

  const trussesTypes = trussesSelected.map(truss => truss.trussType);
  const trussesWithHorizontals = trussesSelected.map(truss => truss.hasSuperiorHorizontal);

  const [segmentSelectedInMenu, setSegmentSelectedInMenu] = useState(nSegments > 0 ? 1 : "");
  const [horizontalSelectedInMenu, setHorizontalSelectedInMenu] = useState(
    trussesTypes[segmentSelectedInMenu - 1] !== "Treliça V"
      ? "mainHorizontalData"
      : trussesWithHorizontals[segmentSelectedInMenu - 1] === "Sim" ? "superiorHorizontalData" : ""
  );
  const [barSelectedInMenu, setBarSelectedInMenu] = useState("");
  const [nBars, setNBars] = useState({
    mainHorizontalData: [0],
    superiorHorizontalData: [0],
  });
  const [inputValues, setInputValues] = useState(new HorizontalLockingsModel(horizontalLockingSelected).horizontalLockings);

  useEffect(() => {
    loadHorizontalLockingsData().then(response => {
      if (response.length === 0) {
        setInitialEmptyValues();
      } else {
        const horizontalLockingsModel = HorizontalLockingsModel.fromJSON(JSON.stringify(response));
        setHorizontaLockingSelected(horizontalLockingsModel.horizontalLockings);
        setInputValues(horizontalLockingsModel.horizontalLockings);
      }
    });
  }, [towerId, userData.token]);

  const loadHorizontalLockingsData = useCallback(async() => {
    setIsLoading(true);
    try {
      return await fetchHorizontalLockings(towerId, userData.token);
    } catch (error) {
      toast.error("Erro ao buscar dados dos travamentos horizontais.");
    } finally {
      setIsLoading(false);
    }
  }, [fetchHorizontalLockings, towerId, userData.token]);

  useEffect(() => {
    if (segmentSelectedInMenu !== "") {
      setBarSelectedInMenu("");
      setHorizontalSelectedInMenu(trussesTypes[segmentSelectedInMenu - 1] !== "Treliça V"
        ? "mainHorizontalData"
        : trussesWithHorizontals[segmentSelectedInMenu - 1] === "Sim" ? "superiorHorizontalData" : "");
    } else {
      setBarSelectedInMenu("");
      setHorizontalSelectedInMenu("");
    }
    setNBars(countBars());
  }, [segmentSelectedInMenu]);

  useEffect(() => {
    setNBars(countBars());
  }, [inputValues]);

  function setInitialEmptyValues() {
    let initialData = [];
    setInputValues(() => {
      for (let i = 0; i < nSegments; i++) {
        initialData[i] = {
          segmentId: segmentsSelected[i].segmentId || "",
          trussType: trussesTypes[i],
          hasSuperiorHorizontal: trussesWithHorizontals[i],
          mainHorizontalData: trussesTypes[i] === "Treliça V" ? {} : {
            hasHorizontalLocking: "",
            lockingType: "",
            bars: [],
          },
          superiorHorizontalData: trussesWithHorizontals[i] !== "Sim" ? {} : {
            hasHorizontalLocking: "",
            lockingType: "",
            bars: [],
          },
        };

        if (trussesTypes[i] !== "Treliça V" && horizontalLockingSelected[i]?.mainHorizontalData) {
          const mainHorizontalInfo = horizontalLockingSelected[i].mainHorizontalData;
          const mainHorizontalLockingType = horizontalLockingsInfo[sectionType];

          initialData[i].mainHorizontalData.hasHorizontalLocking = mainHorizontalInfo.hasHorizontalLocking || "";
          initialData[i].mainHorizontalData.lockingType = mainHorizontalInfo.lockingType || "";

          if (mainHorizontalLockingType) {
            let nMainBars = mainHorizontalLockingType.find(
              type => type.lockingType === mainHorizontalInfo.lockingType)?.bars || 0;
            initialData[i].mainHorizontalData.bars = Array.from({ length: nMainBars }, (_, j) => ({
              barNumber: j + 1,
              isMissingBar: mainHorizontalInfo.bars[j]?.isMissingBar || false,
              profileSteel: mainHorizontalInfo.bars[j]?.profileSteel || "",
              profileType: mainHorizontalInfo.bars[j]?.profileType || "",
              profileDimensions: mainHorizontalInfo.bars[j]?.profileDimensions || "",
              profileDiameter: mainHorizontalInfo.bars[j]?.profileDiameter || "",
              profileThickness: mainHorizontalInfo.bars[j]?.profileThickness || "",
              profileFlange: mainHorizontalInfo.bars[j]?.profileFlange || "",
              profileWeb: mainHorizontalInfo.bars[j]?.profileWeb || "",
              boltsSteel: mainHorizontalInfo.bars[j]?.boltsSteel || "",
              numConnectionBolts: mainHorizontalInfo.bars[j]?.numConnectionBolts || "",
              diameterConnectionBolts: mainHorizontalInfo.bars[j]?.diameterConnectionBolts || "",
            }));
          }
        }

        if (trussesWithHorizontals[i] === "Sim" && horizontalLockingSelected[i]?.superiorHorizontalData) {
          const superiorHorizontalInfo = horizontalLockingSelected[i].superiorHorizontalData;
          const superiorHorizontalLockingType = horizontalLockingsInfo[sectionType];

          initialData[i].superiorHorizontalData.hasHorizontalLocking = superiorHorizontalInfo.hasHorizontalLocking || "";
          initialData[i].superiorHorizontalData.lockingType = superiorHorizontalInfo.lockingType || "";

          if (superiorHorizontalLockingType) {
            let nSuperiorBars = superiorHorizontalLockingType.find(
              type => type.lockingType === superiorHorizontalInfo.lockingType)?.bars || 0;
            initialData[i].superiorHorizontalData.bars = Array.from({ length: nSuperiorBars }, (_, j) => ({
              barNumber: j + 1,
              isMissingBar: superiorHorizontalInfo.bars[j]?.isMissingBar || false,
              profileSteel: superiorHorizontalInfo.bars[j]?.profileSteel || "",
              profileType: superiorHorizontalInfo.bars[j]?.profileType || "",
              profileDimensions: superiorHorizontalInfo.bars[j]?.profileDimensions || "",
              profileDiameter: superiorHorizontalInfo.bars[j]?.profileDiameter || "",
              profileThickness: superiorHorizontalInfo.bars[j]?.profileThickness || "",
              profileFlange: superiorHorizontalInfo.bars[j]?.profileFlange || "",
              profileWeb: superiorHorizontalInfo.bars[j]?.profileWeb || "",
              boltsSteel: superiorHorizontalInfo.bars[j]?.boltsSteel || "",
              numConnectionBolts: superiorHorizontalInfo.bars[j]?.numConnectionBolts || "",
              diameterConnectionBolts: superiorHorizontalInfo.bars[j]?.diameterConnectionBolts || "",
            }));
          }
        }
      }

      return initialData;
    });
  }

  function countBars() {
    let updatedBars = {
      mainHorizontalData: [],
      superiorHorizontalData: [],
    };

    if (segmentSelectedInMenu === "" || horizontalSelectedInMenu === "" || inputValues.length === 0) return updatedBars;

    let nMainHorizontalBars;
    let nSuperiorHorizontalBars;

    for (let i = 0; i < nSegments; i++) {
      nMainHorizontalBars = 0;
      nSuperiorHorizontalBars = 0;

      if (trussesTypes[i] !== "Treliça V") {
        if (inputValues[i]?.mainHorizontalData?.lockingType !== "") {
          nMainHorizontalBars = horizontalLockingsInfo[sectionType]?.filter(
            type => type.lockingType === inputValues[i]?.mainHorizontalData?.lockingType
          )[0]?.bars;
        }
        nMainHorizontalBars
          ? updatedBars.mainHorizontalData.push(nMainHorizontalBars)
          : updatedBars.mainHorizontalData.push(0);
      } else {
        updatedBars.mainHorizontalData.push(0);
      }

      if (trussesWithHorizontals[i] === "Sim") {
        if (inputValues[i]?.superiorHorizontalData?.lockingType !== "") {
          nSuperiorHorizontalBars = horizontalLockingsInfo[sectionType]?.filter(
            type => type.lockingType === inputValues[i]?.superiorHorizontalData?.lockingType
          )[0]?.bars;
        }
        nSuperiorHorizontalBars
          ? updatedBars.superiorHorizontalData.push(nSuperiorHorizontalBars)
          : updatedBars.superiorHorizontalData.push(0);
      } else {
        updatedBars.superiorHorizontalData.push(0);
      }
    }

    return updatedBars;
  }

  const fieldsToCheck = ["mainHorizontalData.hasHorizontalLocking",
    "mainHorizontalData.lockingType", "mainHorizontalData.bars.isMissingBar",
    "mainHorizontalData.bars.profileType", "mainHorizontalData.bars.profileSteel",
    "mainHorizontalData.bars.profileDimensions", "mainHorizontalData.bars.profileDiameter",
    "mainHorizontalData.bars.profileThickness", "mainHorizontalData.bars.profileFlange",
    "mainHorizontalData.bars.profileWeb", "mainHorizontalData.bars.boltsSteel",
    "mainHorizontalData.bars.numConnectionBolts", "mainHorizontalData.bars.diameterConnectionBolts",
    "superiorHorizontalData.hasHorizontalLocking", "superiorHorizontalData.lockingType",
    "superiorHorizontalData.bars.isMissingBar", "superiorHorizontalData.bars.profileType",
    "superiorHorizontalData.bars.profileSteel", "superiorHorizontalData.bars.profileDimensions",
    "superiorHorizontalData.bars.profileDiameter", "superiorHorizontalData.bars.profileThickness",
    "superiorHorizontalData.bars.profileFlange", "superiorHorizontalData.bars.profileWeb",
    "superiorHorizontalData.bars.numConnectionBolts", "superiorHorizontalData.bars.diameterConnectionBolts",
    "superiorHorizontalData.bars.boltsSteel"
  ];
  const isInputModified = useUnsavedChangesAlertForObjects(
    [...inputValues], horizontalLockingSelected, reloadProject, fieldsToCheck);

  const handleInputChange = useCallback((field, value) => {
    if (segmentSelectedInMenu === "" || horizontalSelectedInMenu === "" || field === "") return;
    const currentValues = new HorizontalLockingsModel(inputValues);
    const segmentIndex = segmentSelectedInMenu - 1;

    const fieldsToUpdate = ["isMissingBar", "profileType", "profileSteel", "profileDimensions", "profileDiameter",
      "profileThickness", "profileFlange", "profileWeb", "boltsSteel", "numConnectionBolts", "diameterConnectionBolts"];

    if (field === "hasHorizontalLocking" || field === "lockingType") {
      setBarSelectedInMenu(inputValues[segmentIndex][horizontalSelectedInMenu]?.bars.length === 0 ? 1 : "");
      currentValues.updateHorizontalLocking(
        inputValues[segmentIndex].segmentId, horizontalSelectedInMenu, field, value, sectionType);
    } else if (field === "isMissingBar") {
      const missingBar = inputValues[segmentIndex][horizontalSelectedInMenu].bars[barSelectedInMenu - 1]?.isMissingBar;
      missingBar === true
        ? currentValues.updateHorizontalLockingBar(inputValues[segmentIndex].segmentId,
          horizontalSelectedInMenu, barSelectedInMenu - 1, field, false)
        : currentValues.updateHorizontalLockingBar(inputValues[segmentIndex].segmentId,
          horizontalSelectedInMenu, barSelectedInMenu - 1, field, true);
    } else {
      currentValues.updateHorizontalLockingBar(inputValues[segmentIndex].segmentId,
        horizontalSelectedInMenu, barSelectedInMenu - 1, field, value);
    }

    if (field === "hasHorizontalLocking" && value === "Sim" && segmentSelectedInMenu > 1
      && inputValues[segmentIndex - 1][horizontalSelectedInMenu].hasHorizontalLocking === "Sim") {
      currentValues.updateHorizontalLocking(inputValues[segmentIndex].segmentId, horizontalSelectedInMenu, "lockingType",
        inputValues[segmentIndex - 1][horizontalSelectedInMenu].lockingType, sectionType);
      currentValues.horizontalLockings[segmentIndex][horizontalSelectedInMenu]?.bars?.map(
        (bar, i) => fieldsToUpdate.forEach(f => {
          if (currentValues.horizontalLockings[segmentIndex - 1][horizontalSelectedInMenu]?.bars[i]) {
            currentValues.updateHorizontalLockingBar(
              currentValues.horizontalLockings[segmentIndex].segmentId, horizontalSelectedInMenu, i, f,
              currentValues.horizontalLockings[segmentIndex - 1][horizontalSelectedInMenu]?.bars[i][f]);
          }
        })
      );
    }

    setInputValues(currentValues.horizontalLockings);
  }, [inputValues, setInputValues, segmentSelectedInMenu, horizontalSelectedInMenu, barSelectedInMenu, setBarSelectedInMenu]);

  function isFieldEnabled() {
    if (segmentSelectedInMenu === "" || horizontalSelectedInMenu === "" || Object.keys(inputValues).length === 0) {
      return false;
    }
    return inputValues[segmentSelectedInMenu - 1][horizontalSelectedInMenu]?.hasHorizontalLocking === "Sim";
  }

  async function updateHorizontalLockingsData() {
    const horizontalLockingsModel = new HorizontalLockingsModel(inputValues);
    if (!horizontalLockingsModel.validate()) return false;

    setIsLoading(true);
    try {
      const updatedData = await saveHorizontalLockings(JSON.stringify(inputValues), towerId, userData.token);
      setHorizontaLockingSelected(updatedData);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações dos travamentos horizontais atualizadas com sucesso");
      return true;
    } catch (error) {
      toast.error("Erro ao salvar dados dos travamentos horizontais.");
      return false;
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Container>
      <LeftMenu
        title="Travamento Horizontal"
        isInputModified={isInputModified}
        isLoading={isLoading}
        updateData={updateHorizontalLockingsData}
      >
        <TowerHorizontalLockingLeftMenu
          nSegments={nSegments}
          nBars={nBars}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          setSegmentSelectedInMenu={setSegmentSelectedInMenu}
          horizontalSelectedInMenu={horizontalSelectedInMenu}
          setHorizontalSelectedInMenu={setHorizontalSelectedInMenu}
          barSelectedInMenu={barSelectedInMenu}
          setBarSelectedInMenu={setBarSelectedInMenu}
          handleInputChange={(field, value) => handleInputChange(field, value)}
          isFieldEnabled={() => isFieldEnabled()}
          trussesWithHorizontals={trussesWithHorizontals}
          sectionType={sectionType}
          trussesTypes={trussesTypes}
        />
      </LeftMenu>
      <RightContainer>
        <TowerHorizontalLockingRightContainer
          nBars={nBars}
          inputValues={inputValues}
          segmentSelectedInMenu={segmentSelectedInMenu}
          horizontalSelectedInMenu={horizontalSelectedInMenu}
          handleInputChange={(field, value) => handleInputChange(field, value)}
          isFieldEnabled={() => isFieldEnabled()}
          sectionType={sectionType}
          towerImages={towerImages}
        />
      </RightContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
