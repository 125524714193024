import styled from "styled-components";
import { ButtonPositioner, ReturnButton, LeftTitle, BottomBorderTitle } from "../wind-components/project-windData";
import ResizableLeftContainer from "../../common/resizableLeftContainer";
import ResizableRightContainer from "../../common/resizableRightContainer";
import SavingButton from "../../common/saving-button";
import LoadingButton from "../../common/loading-button";
import { ReturnIcon, AdvanceIcon } from "../../project-components/wind-components/project-windData";
import { useState, useContext, useEffect } from "react";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useUnsavedChangesAlert from "../../../hooks/useUnsavedChangesAlert";
import useUpdateProject from "../../../hooks/api/useUpdateProject";
import UserContext from "../../../contexts/UserContext";
import { saveAs } from "file-saver";
import { IconContext } from "react-icons";
import { TfiPrinter } from "react-icons/tfi";

export default function ProjectReportPage() {
  const { setMastSelected, setLeftMenuOptionSelected, mastSelected, projectSelected, reloadProject, setReloadProject, setIsUpdatedWithoutCalculation } = useContext(ProjectContext);
  const mastProps = JSON.parse(mastSelected.props);
  const { userData } = useContext(UserContext);
  const { updateProject, updateProjectLoading } = useUpdateProject();
  const projectProps = JSON.parse(projectSelected.props);
  const [isGenerateReportButtonClicked, setIsGenerateReportButtonClicked] = useState(false);
  const [formValues, setFormValues] = useState( {
    enderecoRua: projectProps?.enderecoRua || "",
  } );

  const fieldsToCheck = ["enderecoRua",];
  const isInputModified = useUnsavedChangesAlert(formValues, projectProps, reloadProject, fieldsToCheck);

  const handleDownload = () => {
    const content = "Conteúdo do arquivo que você deseja baixar";
    const blob = new Blob([content], { type: "text/plain;charset=utf-8" });

    saveAs(blob, "nome_do_arquivo.txt");
  };

  async function updateProjectMemorialProps() {
    let whatIsMissingMessage = "";
    if (!formValues.enderecoRua) whatIsMissingMessage += "Rua; ";

    if (whatIsMissingMessage) return toast.error(`Preencha todos os valores para salvar os dados do memorial. Falta(m): ${whatIsMissingMessage}`);
    try {
      const updatedProps = { ...projectProps, enderecoRua: formValues.enderecoRua || "",
        enderecoNumero: formValues.enderecoNumero || "",
        enderecoBairro: formValues.enderecoBairro || "",
        enderecoCidade: formValues.enderecoCidade || "",
        enderecoUF: formValues.enderecoUF || "",
        edificacaoAltura: formValues.edificacaoAltura || "",
        edificacaoAndares: formValues.edificacaoAndares || "",
        edificacaoUso: formValues.edificacaoUso || "",
        empresaNome: formValues.empresaNome || "",
        empresaCodigoSite: formValues.empresaCodigoSite || "",
        empresaResponsavel: formValues.empresaResponsavel || "",
        clienteNome: formValues.clienteNome || "",
        clienteIDSite: formValues.clienteIDSite || "",
        clienteOperatora: formValues.clienteOperatora || "", };
      const updatedProject = { ...projectSelected, props: JSON.stringify(updatedProps), userId: userData.id, updatedAt: new Date() };
      delete updatedProject.id;
      delete updatedProject.Mastro;
      await updateProject(updatedProject, projectSelected.nomeProjeto, userData.token);
      setReloadProject(!reloadProject);
      toast.success("Informações atualizadas com sucesso");
    } catch (error) {
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
      if (error?.message) return toast.error(error.message);
    }
  }
  return(
    <>
      <ResizableLeftContainer>
        <LeftTitle>Relatório</LeftTitle>
        <ReturnIcon onClick={() => setLeftMenuOptionSelected("ancoragem")}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ReturnIcon>
        <AdvanceIcon onClick={() => {setLeftMenuOptionSelected("memorial");}}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </AdvanceIcon>
        <ReportMenuOption>
          <h2>Tipo de documento</h2>
          <select>
            <option>Documento do Word (*.docx)</option>
          </select>
        </ReportMenuOption>
        <ReportMenuOption>
          <h2>Nome do arquivo</h2>
          <input placeholder="Insira o nome do arquivo aqui"></input>
        </ReportMenuOption>
        <ReportMenuOption>
          <h2>Local do arquivo</h2>
          <input></input>
          <h2>Download</h2>
          <button onClick={handleDownload}>Baixar Arquivo</button>
        </ReportMenuOption>
        <GenerateMemorialButton>
          Gerar Relatório
          <IonIconContainer>
            <IconContext.Provider value={{ color: isGenerateReportButtonClicked ? "#fff" : "#000", size: "24px" }}>
              <TfiPrinter />
            </IconContext.Provider>
          </IonIconContainer>
        </GenerateMemorialButton>
        <ButtonPositioner >
          <ReturnButton onClick={() => {
            if (1 === 2) {
              if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("");
            }
            else setLeftMenuOptionSelected("");
          }} >{" Voltar "}</ReturnButton>
          {updateProjectLoading ?           
            <LoadingButton>{ "Carregando" }</LoadingButton> :
            <SavingButton disabled={!isInputModified} onClick={() => { if(!updateProjectLoading && isInputModified) updateProjectMemorialProps();} }>{ "Salvar" }</SavingButton>} 
        </ButtonPositioner>
      </ResizableLeftContainer>
      <ResizableRightContainer>
        <ProjectInformationContainer>
          <div>
            <DocumentVisualizationContainer>Visualização do Documento</DocumentVisualizationContainer>
            <DocumentVisualizationContainer>Visualização do Documento</DocumentVisualizationContainer>
          </div>
          <div>
            <DocumentNavegationButtons>Botões Navegação</DocumentNavegationButtons>
          </div>
        </ProjectInformationContainer>
      </ResizableRightContainer>
    </>
  );
}

const ReportMenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1vh 0;
  position: relative;
  cursor: pointer;

  h2 {
    font-weight: 300;
    font-size: 18px;
    color: #313131;
    margin-bottom: 1.8vh;
  }

  div, input, select {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4.5vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }
`;

const GenerateMemorialButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D0CECE;
  width: calc(100% - 64px);
  height: 5vh;
  position: absolute;
  bottom: 16vh;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  background-color: #fff;
  cursor: pointer;

  img {
    width: 24px;
    position: absolute;
    left: calc(100% - 46px);
  }
`;

const ProjectInformationContainer = styled.div`
  width: 90%;
  height: 90%;
  background-color: #D9D9D9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  >div:nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 84%;
    padding: 30px;
  }
  >div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 16%;
    width: 100%;
    padding-bottom: 20px;
  }
`;

const IonIconContainer = styled.div`
  position: absolute;
  right: 1.5vw;
`;

const DocumentVisualizationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 49%;
  height: 100%;
  background-color: #fff;
  border-radius: 8px;
  font-size: 24px;
`;

const DocumentNavegationButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20vw;
  height: 84%;
  background-color: #BFBFBF;
  padding-bottom: 10px;
  border-radius: 8px;
  font-size: 20px;
`;
