import MI_1_1 from "./1MOD/MI-1-1.jpg";

import MI_2_1_1 from "./2MOD/MI-2-1-1.jpg";
import MI_2_2_1 from "./2MOD/MI-2-2-1.jpg";
import MI_2_2_2 from "./2MOD/MI-2-2-2.jpg";

import MI_3_1_1 from "./3MOD/MI-3-1-1.jpg";
import MI_3_2_1 from "./3MOD/MI-3-2-1.jpg";
import MI_3_2_2 from "./3MOD/MI-3-2-2.jpg";
import MI_3_3_1 from "./3MOD/MI-3-3-1.jpg";
import MI_3_3_2 from "./3MOD/MI-3-3-2.jpg";
import MI_3_3_3 from "./3MOD/MI-3-3-3.jpg";

import MI_4_1_1 from "./4MOD/MI-4-1-1.jpg";
import MI_4_2_1 from "./4MOD/MI-4-2-1.jpg";
import MI_4_2_2 from "./4MOD/MI-4-2-2.jpg";
import MI_4_3_1 from "./4MOD/MI-4-3-1.jpg";
import MI_4_3_2 from "./4MOD/MI-4-3-2.jpg";
import MI_4_3_3 from "./4MOD/MI-4-3-3.jpg";
import MI_4_4_1 from "./4MOD/MI-4-4-1.jpg";
import MI_4_4_2 from "./4MOD/MI-4-4-2.jpg";
import MI_4_4_3 from "./4MOD/MI-4-4-3.jpg";
import MI_4_4_4 from "./4MOD/MI-4-4-4.jpg";

import MI_5_1_1 from "./5MOD/MI-5-1-1.jpg";
import MI_5_2_1 from "./5MOD/MI-5-2-1.jpg";
import MI_5_2_2 from "./5MOD/MI-5-2-2.jpg";
import MI_5_3_1 from "./5MOD/MI-5-3-1.jpg";
import MI_5_3_2 from "./5MOD/MI-5-3-2.jpg";
import MI_5_3_3 from "./5MOD/MI-5-3-3.jpg";
import MI_5_4_1 from "./5MOD/MI-5-4-1.jpg";
import MI_5_4_2 from "./5MOD/MI-5-4-2.jpg";
import MI_5_4_3 from "./5MOD/MI-5-4-3.jpg";
import MI_5_4_4 from "./5MOD/MI-5-4-4.jpg";
import MI_5_5_1 from "./5MOD/MI-5-5-1.jpg";
import MI_5_5_2 from "./5MOD/MI-5-5-2.jpg";
import MI_5_5_3 from "./5MOD/MI-5-5-3.jpg";
import MI_5_5_4 from "./5MOD/MI-5-5-4.jpg";
import MI_5_5_5 from "./5MOD/MI-5-5-5.jpg";

const MIImg = {
  MI_1_1,
  MI_2_1_1,
  MI_2_2_1,
  MI_2_2_2,
  MI_3_1_1,
  MI_3_2_1,
  MI_3_2_2,
  MI_3_3_1,
  MI_3_3_2,
  MI_3_3_3,
  MI_4_1_1,
  MI_4_2_1,
  MI_4_2_2,
  MI_4_3_1,
  MI_4_3_2,
  MI_4_3_3,
  MI_4_4_1,
  MI_4_4_2,
  MI_4_4_3,
  MI_4_4_4,
  MI_5_1_1,
  MI_5_2_1,
  MI_5_2_2,
  MI_5_3_1,
  MI_5_3_2,
  MI_5_3_3,
  MI_5_4_1,
  MI_5_4_2,
  MI_5_4_3,
  MI_5_4_4,
  MI_5_5_1,
  MI_5_5_2,
  MI_5_5_3,
  MI_5_5_4,
  MI_5_5_5,
};

export default MIImg;
