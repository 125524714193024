import useAsync from "../useAsync";
import * as projectApi from "../../services/projectApi";

export default function useGetProject() {
  const {
    data: project,
    loading: getProjectLoading,
    error: getProjectError,
    act: getProject,
  } = useAsync((nomeProjeto, token) => projectApi.getProject(encodeURIComponent(nomeProjeto), token), false);

  return {
    project,
    getProjectLoading,
    getProjectError,
    getProject
  };
}
