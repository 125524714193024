export const mountProfileTypes = [
  "L - Cantoneira",
  "T - Tubo Vazado",
  "BR - Barra Maciça",
  "O - Ômega"
];

export const profileTypes = [
  "L - Cantoneira",
  "T - Tubo Vazado",
  "BR - Barra Maciça",
  "U - Perfil U"
];

export const dimensionsProfileL = [
  "L 25.4x3.2mm", "L 25.4x4.8mm", "L 25.4x6.4mm",
  "L 31.8x3.2mm", "L 31.8x4.8mm", "L 31.8x6.4mm",
  "L 38.1x3.2mm", "L 38.1x4.8mm", "L 38.1x6.4mm",
  "L 44.5x3.2mm", "L 44.5x4.8mm", "L 44.5x6.4mm",
  "L 50.8x3.2mm", "L 50.8x4.8mm", "L 50.8x6.4mm",
  "L 50.8x7.9mm", "L 50.8x9.5mm", "L 63.5x4.8mm",
  "L 63.5x6.4mm", "L 63.5x7.9mm", "L 63.5x9.5mm",
  "L 76.2x4.8mm", "L 76.2x6.4mm", "L 76.2x7.9mm",
  "L 76.2x9.5mm", "L 76.2x12.7mm", "L 88.9x6.4mm",
  "L 88.9x7.9mm", "L 88.9x9.5mm", "L 101.6x6.4mm",
  "L 101.6x7.9mm", "L 101.6x9.5mm", "L 101.6x12.7mm",
  "L 101.6x15.9mm", "L 127x6.4mm", "L 127x7.9mm",
  "L 127x9.5mm", "L 127x12.7mm", "L 127x15.9mm",
  "L 127x19mm", "L 152.4x7.9mm", "L 152.4x9.5mm",
  "L 152.4x12.7mm", "L 152.4x15.9mm", "L 152.4x19mm",
];

export const tubeProfileData = {
  "ASTM A36": {
    "1/8\" - 10.3mm": ["1.7", "2.4"],
    "1/4\" - 13.7mm": ["2.2", "3.0"],
    "3/8\" - 17.1mm": ["2.3", "3.2"],
    "1/2\" - 21.3mm": ["2.8", "3.7", "4.8", "7.5"],
    "3/4\" - 26.7mm": ["2.9", "3.9", "5.6", "7.8"],
    "1\" - 33.4mm": ["3.4", "4.5", "6.4", "9.1"],
    "1\" 1/4 - 42.2mm": ["3.6", "4.8", "6.4", "9.7"],
    "1\" 1/2 - 48.3mm": ["3.7", "5.1", "7.1", "10.2"],
    "2\" - 60.3mm": ["3.9", "5.5", "8.7", "11.1"],
    "2\" 1/2 - 73mm": ["5.2", "7.0", "9.5", "14.0"],
    "3\" - 88.9mm": ["3.2", "4.0", "4.8", "5.5", "6.4", "7.1", "7.6", "11.1", "15.2"],
    "3\" 1/2 - 101.6mm": ["8.1", "3.2", "4.0", "4.8", "5.7", "6.4", "7.1"],
    "4\" - 114.3mm": ["3.2", "4.0", "4.8", "5.6", "6.0", "6.4", "7.1", "7.9", "8.6", "11.1", "13.5", "17.1"],
    "5\" - 141.3mm": ["4.0", "4.8", "5.6", "6.6", "7.1", "7.9", "8.7", "9.5", "12.7", "15.9", "19.0"],
    "6\" - 168.3mm": ["4.8", "5.6", "6.4", "7.1", "7.9", "8.7", "9.5", "11.0", "14.3", "18.3", "21.9"],
    "8\" - 219.1mm": ["4.8", "5.2", "5.6", "6.4", "7.0", "7.9", "8.2", "8.7", "9.5", "10.3", "11.1", "12.7", "15.1", "18.3", "20.6", "22.2", "23.0"],
    "10\" - 273mm": ["4.8", "5.2", "5.6", "6.4", "7.1", "7.8", "8.7", "9.3", "11.1", "12.7", "15.1", "18.3", "21.4", "25.4", "28.6"],
    "12\" - 323.8mm": ["5.2", "5.6", "6.4", "7.1", "7.9", "8.4", "8.7", "9.5", "10.3", "11.1", "12.7", "14.3", "17.5", "21.4", "25.4", "28.6", "33.3"],
    "14\" - 355.6mm": ["5.3", "5.6", "6.4", "7.1", "7.9", "8.7", "9.5", "11.1", "11.9", "12.7", "15.1", "19.0", "23.8", "27.8", "31.8", "35.7", "50.8", "54.0", "55.9", "63.5"],
  },
  "DIN 2440": {
    "1/4\" - 13.5mm": ["2.35"],
    "3/8\" - 17.2mm": ["2.35"],
    "1/2\" - 21.3mm": ["2.25", "2.65", "3.0"],
    "3/4\" - 26.9mm": ["2.25", "2.65", "3.0"],
    "1\" - 33.7mm": ["2.65", "3.25", "3.35", "3.75"],
    "1\" 1/4 - 42.4mm": ["2.65", "3.25", "3.35", "3.75"],
    "1\" 1/2 - 48.3mm": ["3.0", "3.25", "3.35", "3.75"],
    "2\" - 60.3mm": ["3.0", "3.65", "3.75", "4.5"],
    "2\" 1/2 - 76.1mm": ["3.35", "3.65", "3.75", "4.5"],
    "3\" - 88.9mm": ["3.35", "4.0", "4.05", "4.5"],
    "3\" 1/2 - 101.6mm": ["3.75", "4.24", "5.0"],
    "4\" - 114.3mm": ["3.75", "4.5", "5.6"],
    "5\" - 139.7mm": ["4.75", "4.85", "5.6"],
    "6\" - 165.1mm": ["4.85", "5.0", "5.6"],
    "8\" - 219.1mm": ["6.3"],
  },
  "SCHEDULE 40": {
    "1/2\" - 21.3mm": ["2.77"],
    "3/4\" - 26.7mm": ["2.87"],
    "1\" - 33.4mm": ["3.38"],
    "1\" 1/4 - 42.2mm": ["3.56"],
    "1\" 1/2 - 48.3mm": ["3.68"],
    "2\" - 60.3mm": ["3.91"],
    "2\" 1/2 - 73.0mm": ["5.16"],
    "3\" - 88.9mm": ["5.49"],
    "3\" 1/2 - 101.6mm": ["5.74"],
    "4\" - 114.3mm": ["6.02"],
    "5\" - 141.3mm": ["6.55"],
    "6\" - 168.3mm": ["7.11"],
    "8\" - 219.1mm": ["8.18"],
    "10\" - 273.05mm": ["9.27"],
    "12\" - 323.84mm": ["10.31"],
  },
  "SCHEDULE 80": {
    "1/2\" - 21.3mm": ["2.77"],
    "3/4\" - 26.7mm": ["2.87"],
    "1\" - 33.4mm": ["3.38"],
    "1\" 1/4 - 42.2mm": ["3.56"],
    "1\" 1/2 - 48.3mm": ["3.68"],
    "2\" - 60.3mm": ["3.91"],
    "2\" 1/2 - 73.0mm": ["5.16"],
    "3\" - 88.9mm": ["5.49"],
    "3\" 1/2 - 101.6mm": ["5.74"],
    "4\" - 114.3mm": ["6.02"],
    "5\" - 141.3mm": ["6.55"],
    "6\" - 168.3mm": ["7.11"],
    "8\" - 219.1mm": ["8.18"],
    "10\" - 273.05mm": ["9.27"],
    "12\" - 323.84mm": ["10.31"],
  },
};

export const diameterProfileBR = [
  "1/8\" - 3.2mm", "3/16\" - 4.8mm", "1/4\" - 6.4mm", "5/16\" - 7.9mm",
  "3/8\" - 9.5mm", "1/2\" - 12.7mm", "5/8\" - 15.9mm", "3/4\" - 19.05mm",
  "7/8\" - 22.2mm", "1\" - 25.4mm", "1 1/4\" - 31.8mm", "1 5/16\" - 33.3mm",
  "1 3/8\" - 34.9mm", "1 1/2\" - 38.1mm", "1 5/8\" - 41.3mm", "1 3/4\" - 44.45mm",
  "1 7/8\" - 47.6mm", "2\" - 50.8mm", "2 1/4\" - 57.2mm", "2 5/16\" - 58.7mm",
  "2 3/8\" - 60.3mm", "2 1/2\" - 63.5mm", "2 5/8\" - 66.7mm", "2 3/4\" - 69.85mm",
  "2 7/8\" - 73mm", "3\" - 74mm", "4 7/8\" - 75mm", "5 7/8\" - 76mm", "6 7/8\" - 77mm"
];

export const thicknessProfilesOmegaU = [
  "1/8\" - 3,2mm", "3/16\" - 4,8mm", "1/4\" - 6,4mm", "5/16\" - 7,9mm", "3/8\" - 9,5mm", 
  "1/2\" - 12,7mm", "5/8\" - 15,9mm", "3/4 - \"19mm", "7/8\" - 22,2mm", "1\" - 25,4mm", 
];

export const profileSteelTypes = [
  "ASTM A36",
  "ASTM A570 G36",
  "ASTM A572 G42",
  "ASTM A572 G50",
  "ASTM A572 G60",
  "ASTM A588",
  "COS AR COR 40",
  "COS AR COR 50",
  "COS AR COR 400",
  "COS AR COR 420",
  "COS AR COR 500",
  "DIN 2440",
  "SCHEDULE 40",
  "SCHEDULE 80",
  "USI SAC 300",
  "USI SAC 350",
];

export const mountConnectionType = [
  "Não há",
  "Emenda Simples",
  "Emenda Dupla",
  "Montante Sobreposto",
  "Flange"
];

export const boltsDiameter = [
  "1/8\" - 3.2mm",
  "3/16\" - 4.8mm",
  "1/4\" - 6.4mm",
  "5/16\" - 7.9mm",
  "3/8\" - 9.5mm",
  "1/2\" - 12.7mm",
  "5/8\" - 15.9mm",
  "3/4\" - 19.05mm",
  "7/8\" - 22.2mm",
  "1\" - 25.4mm",
  "1 1/4\" - 31.8mm",
  "1 5/16\" - 33.3mm",
  "1 3/8\" - 34.9mm",
  "1 1/2\" - 38.1mm",
  "1 5/8\" - 41.3mm",
  "1 3/4\" - 44.45mm",
  "1 7/8\" - 47.6mm",
  "2\" - 50.8mm",
  "2 1/4\" - 57.2mm",
  "2 5/16\" - 58.7mm",
  "2 3/8\" - 60.3mm",
  "2 1/2\" - 63.5mm",
  "2 5/8\" - 66.7mm",
  "2 3/4\" - 69.85mm",
  "2 7/8\" - 73mm",
  "3\" - 74mm",
  "4 7/8\" - 75mm",
  "5 7/8\" - 76mm",
  "6 7/8\" - 77mm",
];

export const boltsSteel = ["ASTM A307", "ASTM A394", "ASTM A325", "ASTM A490", "DIN 8.8"];

export const anchorsSteel = [
  "ASTM A36",
  "SAE 1008",
  "SAE 1010",
  "SAE 1020",
  "SAE 1045 Laminado",
  "SAE 1045 Trefilado",
  "ASTM A307",
  "ASTM A394",
  "ASTM A325",
  "ASTM A490",
  "DIN 8.8",
  "DYWIDAG (ST-85/105)",
];
