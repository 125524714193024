import { createContext, useState } from "react";

const ProjectContext = createContext();

const ProjectStorage = ({ children }) => {
  const [projectType, setProjectType] = useState("");
  const [projectIdSelected, setProjectIdSelected] = useState("");
  const [projectNameSelected, setProjectNameSelected] = useState({});
  const [projectSelected, setProjectSelected] = useState({});
  const [mastSelected, setMastSelected] = useState({});
  const [leftMenuOptionSelected, setLeftMenuOptionSelected] = useState("");
  const [reloadProject, setReloadProject] = useState(false);
  const [googleMapsApiKey, setGoogleMapsApiKey] = useState("");
  const [isUpdatedWithoutCalculation, setIsUpdatedWithoutCalculation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [towerLeftMenuWidth, setTowerLeftMenuWidth] = useState(0);

  return (
    <ProjectContext.Provider
      value={{
        projectType,
        setProjectType,
        projectSelected,
        setProjectSelected,
        projectIdSelected,
        setProjectIdSelected,
        mastSelected,
        setMastSelected,
        leftMenuOptionSelected,
        setLeftMenuOptionSelected,
        projectNameSelected,
        setProjectNameSelected,
        reloadProject,
        setReloadProject,
        googleMapsApiKey,
        setGoogleMapsApiKey,
        isUpdatedWithoutCalculation,
        setIsUpdatedWithoutCalculation,
        isLoading,
        setIsLoading,
        towerLeftMenuWidth,
        setTowerLeftMenuWidth,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export { ProjectContext, ProjectStorage };
