import styled from "styled-components";
import {
  Dialog,
  DialogActions,
} from "@mui/material";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserContext from "../../contexts/UserContext";
import useUpdatePasswordWithoutValidationCode from "../../hooks/api/useUpdatePasswordWithoutValidationCode";

export default function UpdatePasswordDialog({
  confirmDialog,
  setConfirmDialog,
}) {
  const { userData } = useContext(UserContext);
  const { updatePasswordWithoutValidationCode } = useUpdatePasswordWithoutValidationCode();
  const [inputValues, setInputValues] = useState({
    password: "",
    confirmPassword: "",
  });
  
  function verifyPassword() {
    if (inputValues.password?.length < 6) return toast.error("A senha precisa ter pelo menos 6 caracteres!");
    if (inputValues.password !== inputValues.confirmPassword) return toast.error("As senhas precisam ser iguais!");
    return;
  }

  async function updatePassword() {
    if(verifyPassword()) return;
    try {
      updatePasswordWithoutValidationCode(userData.token, inputValues.password);
      setInputValues({ password: "", confirmPassword: "" });
      setConfirmDialog({ ...confirmDialog, isOpen: false });
      return toast.success("Senha atualizada com sucesso!");
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Dialog open={confirmDialog.isOpen} fullWidth={true} maxWidth="sm">
      <DiolagContainer dialogType={confirmDialog.type}>
        <h1>Resetar Senha</h1>
        <SpacingLine />
        <ProjectActionContainer>
          <h2>Nova Senha</h2>
          <input autoFocus type="password" onChange={(e) => setInputValues({ ...inputValues, password: e.target.value })} placeholder="digite a nova senha..."></input>
          <h2>Repetir Nova Senha</h2>
          <input type="password"onChange={(e) => setInputValues({ ...inputValues, confirmPassword: e.target.value })} placeholder="repita a nova senha..."></input>
          <BlackConfirmDialogButton onClick={updatePassword}>Salvar nova senha</BlackConfirmDialogButton>
        </ProjectActionContainer>
        <DialogActions>
          {" "}
        </DialogActions>
        <ion-icon onClick={() => {setConfirmDialog({ ...confirmDialog, isOpen: false }); }} name="close"></ion-icon>
      </DiolagContainer>
    </Dialog>
  );
}

const DiolagContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  width: 100%;
  background-color: #ffffff;

  h1 {
    font-size: 20px;
    font-weight: 400;
    text-align: start;
    color: #000000;
    text-align: left;
    width: 92%;
  }

  ion-icon {
    position: absolute;
    top: 10px;
    right: 16px;
    font-size: 28px;
    color: #AEAEAE;
    cursor: pointer;

    :active {
      color: #000;
    }
  };
`;

export const ProjectActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #ffffff;
  height: 210px;
  width: 540px;

  h2 {
    margin-right: 30px;
    color: #666666;
    font-size: 16px;
    width: 100%;
  }

  h3 {
    text-align: center;
    color: #000000;
    font-weight: 500;
    font-size: 20px;
  }

  input,
  select {
    height: 4vh;
    width: 106%;
    color: #000000 !important;
    background-color: #fff;
    border: 1px solid #C5C5C5;
    font-size: 16px;
    text-align: left;
    padding-left: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
    select {
    border: 0px;
  }
  input::placeholder{
  }
`;

const SpacingLine = styled.div`
  display: flex;
  width: 100%;
  height: 1.35px;
  margin: 12px 0 18px 0;
  background-color: #DDDDDD;
`;

const BlackConfirmDialogButton = styled.div `
  font-size: 15px;
  width: 106%;
  height: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: ${(props) => (props.isDeletionType ? "#BF2929" : "#000")};
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  &:active {
    background: linear-gradient(#333, #333);
  }
`;
