export default function verifyNecessaryProps(props) {
  const windMissingProps = [];
  const geometryMissingProps = [];
  const moduleMissingProps = [];
  const estaiMissingProps = [];
  const antennaMissingProps = [];
  const anchorageMissingProps = [];

  if (!props.v0) windMissingProps.push("Vento Operacional");
  if (!props.s1) windMissingProps.push("Fator S1");
  if (!props.s2) windMissingProps.push("Fator S2");
  if (!props.s3) windMissingProps.push("Fator S3");

  if (!props.tipoMastro) geometryMissingProps.push("Tipo do Mastro");
  if (!props.alturaMastro) geometryMissingProps.push("Altura do Mastro");
  if (!props.qtdModulos) geometryMissingProps.push("Quantidade de módulos");
  if (!props.alturaEdificacao) geometryMissingProps.push("Altura Edificação");

  const qtdModulos = Number(props.qtdModulos) ? Number(props.qtdModulos) : 1;
  let alturaModuloSomadas = 0;
  for (let i = 1; i <= qtdModulos; i ++ ) {
    if (!props[`modulo${i}Altura`]) moduleMissingProps.push(`Altura do Módulo ${i}`);
    if (!props[`modulo${i}Diametro`]) moduleMissingProps.push(`Diâmetro do Módulo ${i}`);
    if (!props[`modulo${i}Espessura`]) moduleMissingProps.push(`Espessura do Módulo ${i}`);
    if (!props[`modulo${i}Tubos`]) moduleMissingProps.push(`Aço dos tubos do Módulo ${i}`);
    if (!props[`modulo${i}FlangeDiametro`] && i !== qtdModulos) moduleMissingProps.push(`Diâmetro da flange do Módulo ${i}`);
    if (!props[`modulo${i}FlangeEspessura`] && i !== qtdModulos) moduleMissingProps.push(`Espessura da flange do Módulo ${i}`);
    if (!props[`modulo${i}ParafusosFlangeDiametro`] && i !== qtdModulos) moduleMissingProps.push(`Diâmetro dos parafusos da flange do Módulo ${i}`);
    if (!props[`modulo${i}ParafusosFlangeNumero`] && i !== qtdModulos) moduleMissingProps.push(`Número de parafusos da flange do Módulo ${i}`);
    if (!props[`modulo${i}ParafusosAco`] && i !== qtdModulos) moduleMissingProps.push(`Aço dos parafusos do Módulo ${i}`);
    alturaModuloSomadas += Number(props[`modulo${i}Altura`]);
  };
  if(alturaModuloSomadas !== Number(props.alturaMastro)) moduleMissingProps.push("Altura dos módulos somada não é igual a altura do mastro");

  const estaisByModules = { MA: 0, MB: 2, MC: 4, MD: 2, ME: 2, MF: 2, MG: 2, MH: 1, MI: 2 };
  const qtdEstais = props.tipoMastro ? estaisByModules[props.tipoMastro] : 0;
  for (let i = 1; i <= qtdEstais; i ++ ) {
    if (!props[`estai${i}AlturaFixacaoMastro`]) estaiMissingProps.push(`Altura de Fixação Estai ${i}`);
    if (!props[`estai${i}Perfil`]) estaiMissingProps.push(`Perfil do Estai ${i}`);
    if(props[`estai${i}Perfil`] !== "T") {
      if (!props[`estai${i}TipoPerfilCantoneira`]) estaiMissingProps.push(`Tipo do Perfil do Estai ${i}`);
    }
    if(props[`estai${i}Perfil`] === "T") {
      if (!props[`estai${i}Aba`]) estaiMissingProps.push(`Aba do Perfil do Estai ${i}`);
      if (!props[`estai${i}Espessura`]) estaiMissingProps.push(`Espessura Perfil do Estai ${i}`);
    }
    if(props[`estai${i}Perfil`] === "U") {
      if (!props[`estai${i}Alma`]) estaiMissingProps.push(`Alma do Perfil do Estai ${i}`);
      if (!props[`estai${i}AlmaEspessura`]) estaiMissingProps.push(`Espessura da alma Perfil do Estai ${i}`);
      if (!props[`estai${i}Mesa`]) estaiMissingProps.push(`Mesa do Perfil do Estai ${i}`);
      if (!props[`estai${i}MesaEspessura`]) estaiMissingProps.push(`Espessura da mesa Perfil do Estai ${i}`);
    }
    if (!props[`estai${i}LocacaoX1`] && props[`estai${i}LocacaoX1`] !== 0 && props.tipoMastro !== "MH") estaiMissingProps.push(`Localização X1 Estai ${i}`);
    if (!props[`estai${i}LocacaoX2`] && props[`estai${i}LocacaoX2`] !== 0 && props.tipoMastro !== "MI") estaiMissingProps.push(`Localização X2 Estai ${i}`);
    if (!props[`estai${i}LocacaoX3`] && props[`estai${i}LocacaoX3`] !== 0 && props.tipoMastro !== "MI" && props.tipoMastro !== "MH") estaiMissingProps.push(`Localização X3 Estai ${i}`);
    if (!props[`estai${i}ParafusosDiametro`] && props.tipoMastro !== "MI") estaiMissingProps.push(`Diâmetro dos parafusos do Estai ${i}`);
    if (!props[`estai${i}ParafusosNumero`] && props.tipoMastro !== "MI") estaiMissingProps.push(`Número de parafusos do Estai ${i}`);
    if (!props[`estai${i}ParafusosAco`] && props.tipoMastro !== "MI") estaiMissingProps.push(`Aço dos parafusos do Estai ${i}`);

    if (!props[`ancoragemEstai${i}ChumbadoresAco`]) anchorageMissingProps.push(`Aço dos chumbadores do bloco do estai ${i}`);
    if (!props[`ancoragemEstai${i}ChumbadoresAncoragem`]) anchorageMissingProps.push(`Comprimento da ancoragem do bloco do estai ${i}`);
    if (!props[`ancoragemEstai${i}ChumbadoresDiametro`]) anchorageMissingProps.push(`Diâmetro dos chumbadores do bloco do estai ${i}`);
    if (!props[`ancoragemEstai${i}ChumbadoresQtd`]) anchorageMissingProps.push(`Quantidade de chumbadores do bloco do estai ${i}`);
    if (!props[`ancoragemEstai${i}Concreto`]) anchorageMissingProps.push(`Concreto do bloco do estai ${i}`);

    if (props[`ancoragemEstai${i}TemBloco`]) {
      if (!props[`ancoragemEstai${i}DistanciaFuros`] && props.tipoMastro === "MA") anchorageMissingProps.push(`Distância dos furos dos ferros do bloco do estai ${i}`);
      if (!props[`ancoragemEstai${i}FerrosAncoragem`]) anchorageMissingProps.push(`Ancoragem dos ferros do bloco do estai ${i}`);
      if (!props[`ancoragemEstai${i}FerrosDiametro`]) anchorageMissingProps.push(`Diâmetro dos ferros do bloco do estai ${i}`);
      if (!props[`ancoragemEstai${i}FerrosQtd`]) anchorageMissingProps.push(`Quantidade dos ferros chumbadores do bloco do estai ${i}`);
      if (!props[`ancoragemEstai${i}H`] || !props[`ancoragemEstai${i}L1`] || !props[`ancoragemEstai${i}L2`]) anchorageMissingProps.push(`Dimensões bloco do estai ${i}`);
    }
  };

  if(props.tipoMastro === "MB" || props.tipoMastro === "MD" || props.tipoMastro === "ME" || props.tipoMastro === "MF" || props.tipoMastro === "MG" || props.tipoMastro === "MI") {
    if(!props.estai1LocacaoX3 && !props.estai1LocacaoX2 && !props.estai1LocacaoX1) estaiMissingProps.push("Coordenadas do estai1 não podem ser nulas");
    if(!props.estai2LocacaoX3 && !props.estai2LocacaoX2 && !props.estai2LocacaoX1) estaiMissingProps.push("Coordenadas do estai2 não podem ser nulas");
    if(props.estai1LocacaoX3 === undefined || props.estai1LocacaoX2 === undefined || props.estai1LocacaoX1 === undefined) estaiMissingProps.push("Coordenadas do estai1 não podem ser nulas");
    if(props.estai2LocacaoX3 === undefined || props.estai2LocacaoX2 === undefined || props.estai2LocacaoX1 === undefined) estaiMissingProps.push("Coordenadas do estai2 não podem ser nulas");
  }
  if(props.tipoMastro === "MC") {
    if(!props.estai1LocacaoX3 && !props.estai1LocacaoX2 && !props.estai1LocacaoX1) estaiMissingProps.push("Coordenadas do estai1 não podem ser nulas");
    if(!props.estai2LocacaoX3 && !props.estai2LocacaoX2 && !props.estai2LocacaoX1) estaiMissingProps.push("Coordenadas do estai2 não podem ser nulas");
    if(!props.estai3LocacaoX3 && !props.estai3LocacaoX2 && !props.estai3LocacaoX1) estaiMissingProps.push("Coordenadas do estai3 não podem ser nulas");
    if(!props.estai4LocacaoX3 && !props.estai4LocacaoX2 && !props.estai4LocacaoX1) estaiMissingProps.push("Coordenadas do estai4 não podem ser nulas");
    if(props.estai1LocacaoX3 === undefined || props.estai1LocacaoX2 === undefined || props.estai1LocacaoX1 === undefined) estaiMissingProps.push("Coordenadas do estai1 não podem ser nulas");
    if(props.estai2LocacaoX3 === undefined || props.estai2LocacaoX2 === undefined || props.estai2LocacaoX1 === undefined) estaiMissingProps.push("Coordenadas do estai2 não podem ser nulas");
    if(props.estai3LocacaoX3 === undefined || props.estai3LocacaoX2 === undefined || props.estai3LocacaoX1 === undefined) estaiMissingProps.push("Coordenadas do estai3 não podem ser nulas");
    if(props.estai4LocacaoX3 === undefined || props.estai4LocacaoX2 === undefined || props.estai4LocacaoX1 === undefined) estaiMissingProps.push("Coordenadas do estai4 não podem ser nulas");
  }
  if(props.tipoMastro === "MH") {
    if(!props.estai1LocacaoX3 && !props.estai1LocacaoX2 && !props.estai1LocacaoX1) estaiMissingProps.push("Coordenadas do estai1 não podem ser nulas");
    if(props.estai1LocacaoX3 === undefined || props.estai1LocacaoX2 === undefined || props.estai1LocacaoX1 === undefined) estaiMissingProps.push("Coordenadas do estai1 não podem ser nulas");
  }

  if (!props.esteira) antennaMissingProps.push("Esteira de cabos");

  if(props.tipoMastro !== "MI") {
    if (!props.ancoragemEstaiCentralChumbadoresAco) anchorageMissingProps.push("Aço dos chumbadores do bloco central");
    if (!props.ancoragemEstaiCentralChumbadoresAncoragem) anchorageMissingProps.push("Comprimento da ancoragem do bloco central");
    if (!props.ancoragemEstaiCentralChumbadoresDiametro) anchorageMissingProps.push("Diâmetro dos chumbadores do bloco central");
    if (!props.ancoragemEstaiCentralChumbadoresQtd) anchorageMissingProps.push("Quantidade de chumbadores do bloco central");
    if (!props.ancoragemEstaiCentralConcreto) anchorageMissingProps.push("Concreto do bloco central");
  }

  if (props.ancoragemEstaiCentralTemBloco) {
    if (!props.ancoragemEstaiCentralDistanciaFuros && props.tipoMastro === "MA") anchorageMissingProps.push("Distância dos furos dos ferros do bloco central");
    if (!props.ancoragemEstaiCentralFerrosAncoragem) anchorageMissingProps.push("Ancoragem dos ferros do bloco central");
    if (!props.ancoragemEstaiCentralFerrosDiametro) anchorageMissingProps.push("Diâmetro dos ferros do bloco central");
    if (!props.ancoragemEstaiCentralFerrosQtd) anchorageMissingProps.push("Quantidade dos ferros chumbadores do bloco central");
    if (!props.ancoragemEstaiCentralH || !props.ancoragemEstaiCentralL1 || !props.ancoragemEstaiCentralL2) anchorageMissingProps.push("Dimensões bloco central");
  }

  const arraysToTabs = {
    windMissingProps: "Aba de Vento",
    geometryMissingProps: "Aba de Geometria",
    moduleMissingProps: "Aba de Módulos",
    estaiMissingProps: "Aba de Estais",
    antennaMissingProps: "Aba de Antenas",
    anchorageMissingProps: "Aba de Chumbadores",
  };

  let whatIsMissingMessage = "";

  for (const [arrayName, tabDescription] of Object.entries(arraysToTabs)) {
    const array = eval(arrayName); 

    if (array.length > 0) {
      whatIsMissingMessage += `${tabDescription} -> '${array.join(", ")}';\n`;
    }
  };

  return(whatIsMissingMessage);
};
