import useAsync from "../../useAsync";
import { getHorizontalLockings, postHorizontalLockings,
  updateHorizontalLockings } from "../../../services/tower-services/towerHorizontalLockingsApi";

export const useTowerHorizontalLockings = (action) => {
  const execute = {
    get: getHorizontalLockings,
    post: postHorizontalLockings,
    update: updateHorizontalLockings,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
