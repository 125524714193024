const images = {
  black: {},
  red: {},
  brown: {},
  chartreuse: {},
  crimson: {},
  darkgreen: {},
  darkslategray: {},
  teal: {},
  violet: {},
  blue: {}
};
  
for(let i = -1000; i <= 1000; i++) {
  for (const color of ["red", "black"]) {
    const index = ((Math.round(i))/100);
    images[color][index] = require(`../../../assets/images/numberResponses/${color}/${index.toFixed(2)}.png`);
  }
  images.red["0.00"] = require("../../../assets/images/numberResponses/red/0.00.png");
  images.black["0.00"] = require("../../../assets/images/numberResponses/black/0.00.png");
}
    
for(let i = 0; i <= 800; i++) {
  for (const color of Object.keys(images)) {
    images[color][i] = require(`../../../assets/images/numberResponses/${color}/${i}.png`);
  }
}
  
export default images;
