import { TopBar } from "../components/topMenu-components/top-bar";
import ProjectActionsPage from "../components/project-components/dashboardPage-components/project-actionsPage";
import styled from "styled-components";
import { useState, useEffect, useContext } from "react";
import UserContext from "../contexts/UserContext";
import { ProjectContext } from "../contexts/ProjectContext";
import useGetProject from "../hooks/api/useGetProject";
import ProjectLeftMenu from "../components/project-components/project-leftMenu";
import ProjectMainContainer from "../components/project-components/project-mainContainer";
import WindInformationPage from "../components/project-components/wind-components/project-windData";
import MastGeometryPage from "../components/mast-components/geometry-components/mast-geometryData";
import MastModulePage from "../components/mast-components/module-components/mast-moduleData";
import MastEstaiPage from "../components/mast-components/estai-components/mast-estaiData";
import MastAntennaPage from "../components/mast-components/antenna-components/mast-antennaData";
import MastStrapPage from "../components/mast-components/calculation-components/mast-strapData";
import MastAnchoragePage from "../components/mast-components/anchorage-components/mast-anchorageData";
import useLocalStorage from "../hooks/useLocalStorage";
import MastAnchorageCalculationPage from "../components/mast-components/anchorage-components/mast-anchorageCalculationData";
import StrapResultsPage from "../components/mast-components/calculation-components/mast-strapResults";
import ProjectMemorialPage from "../components/project-components/memorial-components/project-memorialData";
import ProjectReportPage from "../components/project-components/report-components/project-reportData";
import TowerAntennaPage from "../components/mast-components/antenna-components/tower-antennaData";

export function RooftopPage() {
  const { projectSelected, setProjectSelected, mastSelected, setMastSelected, projectNameSelected, setProjectNameSelected, leftMenuOptionSelected, setLeftMenuOptionSelected, reloadProject, projectType } = useContext(ProjectContext);

  const [pageProps, deletePageProps] = useLocalStorage("pageProps", {});

  const { userData } = useContext(UserContext);
  const { getProject } = useGetProject();
  const [opacity, setOpacity] = useState("1");

  useEffect(() => {
    if (pageProps && pageProps.projectSelected?.nomeProjeto) {
      setProjectSelected({ ...pageProps.projectSelected });
      setProjectNameSelected(pageProps.projectSelected?.nomeProjeto);
    }
    if (pageProps && pageProps.mastSelected?.identificador) setMastSelected(pageProps.mastSelected);
    if (pageProps && !!pageProps.leftMenuOptionSelected && pageProps.leftMenuOptionSelected !== "vento") setLeftMenuOptionSelected(pageProps.leftMenuOptionSelected);
  }, [pageProps]);

  useEffect(() => {
    if (projectSelected.nomeProjeto && projectSelected.nomeProjeto !== "  ") {
      async function getCurrentProject() {
        const project = await getProject(
          projectSelected.nomeProjeto,
          userData.token
        );
        setProjectSelected(project);
      }
      getCurrentProject();
    }
    deletePageProps(pageProps);
  }, [projectNameSelected, mastSelected, pageProps, reloadProject]);
  return (
    !projectSelected.nomeProjeto ? (
      <>
        {" "}
        <NoProjectContainer>
          {projectSelected && projectSelected.nomeProjeto ? (
            ""
          ) : (
            <ProjectActionsPage setOpacity={setOpacity} projectType />
          )}
        </NoProjectContainer>
      </>
    ) : (
      <TopBar opacity={opacity}>
        <ProjectPageContainer>
          {leftMenuOptionSelected === "" &&
            <>
              <ProjectLeftMenu />
              <ProjectMainContainer />
            </>}
          {leftMenuOptionSelected === "vento" && <WindInformationPage />}
          {leftMenuOptionSelected === "geometria" && <MastGeometryPage />}
          {leftMenuOptionSelected === "modulos" && <MastModulePage />}
          {leftMenuOptionSelected === "estais" && <MastEstaiPage />}
          {leftMenuOptionSelected === "chumbadores" && <MastAnchoragePage />}
          {(leftMenuOptionSelected === "antenas" && projectType === "Rooftop") && <MastAntennaPage />}
          {(leftMenuOptionSelected === "antenas" && projectType === "Torre") && <TowerAntennaPage />}
          {leftMenuOptionSelected === "calcular" && <MastStrapPage />}
          {leftMenuOptionSelected === "ancoragem" && <MastAnchorageCalculationPage />}
          {leftMenuOptionSelected === "metalica" && <StrapResultsPage />}
          {leftMenuOptionSelected === "memorial" && <ProjectMemorialPage />}
          {leftMenuOptionSelected === "relatorio" && <ProjectReportPage />}
        </ProjectPageContainer>
      </TopBar>
    ));
}

const NoProjectContainer = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
`;

export const ProjectPageContainer = styled.div`
  display: flex;
  width: 100%;
  max-height: calc(100% - 50px);
`;
