import useAsync from "../useAsync";
import * as antennaApi from "../../services/antennaApi";

export default function useGetAllAntennas() {
  const {
    data: allAntennas,
    loading: getAllAntennasLoading,
    error: getAllAntennasError,
    act: getAllAntennas,
  } = useAsync((token) => antennaApi.getAllAntennas(token), true);

  return {
    allAntennas,
    getAllAntennasLoading,
    getAllAntennasError,
    getAllAntennas
  };
}
