import useAsync from "../useAsync";
import * as mastApi from "../../services/mastApi";

export default function usePostDownloadDwg() {
  const {
    data: mastDwg,
    loading: postMastDwgLoading,
    error: postMastDwgError,
    act: postMastDwg,
  } = useAsync((identificador, projectId, token) => mastApi.postDownloadMastDWG(identificador, projectId, token), false);

  return {
    mastDwg: mastDwg,
    postMastDwgLoading: postMastDwgLoading,
    postMastDwgError,
    postMastDwg: postMastDwg
  };
}
