import useAsync from "../../useAsync";
import { getAntennas, postAntennas, updateAntennas } from "../../../services/tower-services/towerAntennasApi";

export const useTowerAntennas = (action) => {
  const execute = {
    get: getAntennas,
    post: postAntennas,
    update: updateAntennas,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
