import styled from "styled-components";
import { useEffect, useState } from "react";
import MastGeometryImg from "./mast-geometryImg";
import MastBuildingImg from "./mast-buildingImg";

export default function MastGeometryRightContainer({ inputValues, estaiPositions, mastProps }) {
  const [mastImgProps, setMastImgProps] = useState({ paddingTop: "100px", imageSize: "50vh" });
  const [mastImgId, setMastImgId] = useState("MA_1");

  useEffect(() => {
    const type = inputValues.tipoMastro;
    const modulesNum = Number(inputValues.qtdModulos) || 1;
    if (type === "MA") {
      setMastImgId(`${type}_${modulesNum}`);
    }
    else if (type === "MC") {
      if (modulesNum == 1) return setMastImgId(`${type}_1`);
      const positionEstai1 = (estaiPositions.estai1ModuloAcoplado && estaiPositions.estai1ModuloAcoplado <= modulesNum ) ? estaiPositions.estai1ModuloAcoplado : 1;
      const positionEstai3 = (estaiPositions.estai3ModuloAcoplado && estaiPositions.estai3ModuloAcoplado <= modulesNum ) ? estaiPositions.estai3ModuloAcoplado : 1;
      if (positionEstai1 > modulesNum || positionEstai3 > modulesNum || positionEstai3 > positionEstai1) {
        if (!mastImgId) setMastImgId(`${type}_1`);
        return;
      } 
      const imageId = `${type}_${modulesNum}_${positionEstai1}_${positionEstai3}` !== `${type}_1_1_1` ? `${type}_${modulesNum}_${positionEstai1}_${positionEstai3}` : `${type}_1`;
      setMastImgId(imageId);
    }
    else if (type === "MI") {
      if (modulesNum == 1) return setMastImgId(`${type}_1_1`);
      const positionEstai1 = (estaiPositions.estai1ModuloAcoplado && estaiPositions.estai1ModuloAcoplado <= modulesNum ) ? estaiPositions.estai1ModuloAcoplado : 1;
      const positionEstai3 = (estaiPositions.estai3ModuloAcoplado && estaiPositions.estai3ModuloAcoplado <= modulesNum ) ? estaiPositions.estai3ModuloAcoplado : 1;
      if (positionEstai1 > modulesNum || positionEstai3 > modulesNum || positionEstai3 > positionEstai1) {
        if (!mastImgId) setMastImgId(`${type}_1_1`);
        return;
      } 
      const imageId = `${type}_${modulesNum}_${positionEstai1}_${positionEstai3}` !== `${type}_1_1_1` ? `${type}_${modulesNum}_${positionEstai1}_${positionEstai3}` : `${type}_1_1`;
      setMastImgId(imageId);
    }
    else {
      const positionEstai1 = (estaiPositions.estai1ModuloAcoplado && estaiPositions.estai1ModuloAcoplado <= modulesNum ) ? estaiPositions.estai1ModuloAcoplado : 1;
      if (positionEstai1 > modulesNum) return;
      const imageId = `${type}_${positionEstai1}_${modulesNum}`;
      setMastImgId(imageId);
    }
  }, [inputValues.tipoMastro, inputValues.qtdModulos, estaiPositions]);

  useEffect(() => {
    const previousMastImgProps = { ...mastImgProps };
    if (Number(inputValues.qtdModulos) === 1) {
      previousMastImgProps.paddingTop = "100px";
      previousMastImgProps.imageSize = "50vh";
      setMastImgProps(previousMastImgProps);
    }
    else if (Number(inputValues.qtdModulos) === 2) {
      previousMastImgProps.paddingTop = "40px";
      previousMastImgProps.imageSize = "55vh";
      setMastImgProps(previousMastImgProps);
    }
    else if (Number(inputValues.qtdModulos) < 5) {
      previousMastImgProps.paddingTop = "0px";
      previousMastImgProps.imageSize = "60vh";
      setMastImgProps(previousMastImgProps);
    }
    else if (Number(inputValues.qtdModulos) < 8) {
      previousMastImgProps.paddingTop = "0px";
      previousMastImgProps.imageSize = "65vh";
      setMastImgProps(previousMastImgProps);
    }
    else {
      previousMastImgProps.paddingTop = "0px";
      previousMastImgProps.imageSize = "70vh";
      setMastImgProps(previousMastImgProps);
    }
  }, [inputValues.tipoMastro, inputValues.qtdModulos]);

  return (
    <MastGeometryImgContainer imageObjectFit="cover" paddingTop={mastImgProps.paddingTop} imageSize={mastImgProps.imageSize}>
      <div>
        <DescriptionContainer>Mastro tipo {inputValues.tipoMastro}</DescriptionContainer>
        <MastGeometryImg type={inputValues.tipoMastro} imgId={mastImgId}/>
      </div>
      <div>
        <DescriptionContainer>Informações do Edifício</DescriptionContainer>
        <MastBuildingContainer buildingHeigth={inputValues.alturaEdificacao}>
          <MastBuildingImg type={inputValues.tipoMastro} />
          {/* <p>{`${inputValues.alturaEdificacao ? inputValues.alturaEdificacao : "Nível da Laje"} `}{`${inputValues.alturaEdificacao ? "m" : "(m)"}`}</p> */}
        </MastBuildingContainer>
      </div>
    </MastGeometryImgContainer>
  );
};

const MastGeometryImgContainer = styled.div`
  display: flex;
  height: calc(100vh - 160px);
  align-items: flex-start;
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 26vw;
    margin: 0 50px;
      img {
      width: 26vw;
      border-radius: 16px;
      height: ${(props) => props.imageSize};
      margin-top: 20px;
      object-fit: contain;
      background-color: #ffffff;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
    }
    img:nth-child(2) {
      padding-top: ${(props) => (props.paddingTop)};
      object-fit: ${(props) => props.imageObjectFit};
    }
  }
`;

const MastBuildingContainer = styled.div`
  position: relative;
  p {
    position: absolute;
    z-index: 3;
    top: ${(props) => props.buildingHeigthDescription};
    left: 13.5vw;
    font-size: ${(props) => (props.buildingHeigth ? "15px" : "15px")};
  }
`;

const DescriptionContainer = styled.div`
    width: 26vw;
    height: 50px;
    background-color: #ffffff;
    color: #000000;
    font-size: 21px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 8px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);
`;
