import useAsync from "../useAsync";
import * as mastApi from "../../services/mastApi";

export default function useGetWindVelocity() {
  const {
    data: windVelocity,
    loading: getWindVelocityLoading,
    error: getWindVelocityError,
    act: getWindVelocity,
  } = useAsync((coordinates, token) => mastApi.getWindVelocity(coordinates, token), false);

  return {
    windVelocity,
    getWindVelocityLoading,
    getWindVelocityError,
    getWindVelocity
  };
}
