import styled from "styled-components";
import React from "react";
import { useContext, useRef, useEffect } from "react";
import { ProjectContext } from "../../contexts/ProjectContext";

export default function LeftMenu({ children, title, isInputModified, isLoading, updateData }) {
  const { leftMenuOptionSelected, setLeftMenuOptionSelected, setTowerLeftMenuWidth } = useContext(ProjectContext);

  const screensOrder = [
    "",
    "geometria",
    "trelicamento",
    "secoes",
    "plataformas",
    "vento",
    "antenas",
    "montantes",
    "diagonais",
    "horizontais",
    "redundantes",
    "travamentos-diagonais",
    "travamentos-horizontais",
    "ancoragem",
    "calcular",
  ];

  const containerRef = useRef(null);

  useEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        setTowerLeftMenuWidth(containerRef.current.offsetWidth);
      }
    };

    updateContainerWidth();
    window.addEventListener("resize", updateContainerWidth);

    return () => {
      window.removeEventListener("resize", updateContainerWidth);
    };
  }, []);

  const handleNavigationBack = () => {
    const prevIndex = screensOrder.indexOf(leftMenuOptionSelected) - 1;
    if (prevIndex < 0) return;
    const prevScreen = screensOrder[prevIndex];
    setLeftMenuOptionSelected(prevScreen);
  };

  const handleNavigationForward = () => {
    const nextIndex = screensOrder.indexOf(leftMenuOptionSelected) + 1;
    if (nextIndex > screensOrder.length - 1) return;
    const nextScreen = screensOrder[nextIndex];
    setLeftMenuOptionSelected(nextScreen);
  };

  async function handleSaveData() {
    if (isInputModified) {
      const success = await updateData();
      if (success) {
        handleNavigationForward();
      }
    }
  }

  return (
    <Container ref={containerRef}>
      <div>
        <MenuHeader>
          <h1>{title}</h1>
          <PreviousScreenButton
            onClick={() => {
              if (isInputModified) {
                if (window.confirm(
                  "Existem alterações que não foram salvas. Tem certeza de que deseja voltar?"))
                  handleNavigationBack();
              }
              else handleNavigationBack();
            }}>
            <ion-icon name="chevron-forward-outline"></ion-icon>
          </PreviousScreenButton>
          <NextScreenButton
            onClick={() => {
              if (isInputModified) {
                if (window.confirm(
                  "Existem alterações que não foram salvas. Tem certeza de que deseja avançar?"))
                  handleNavigationForward();
              }
              else handleNavigationForward();
            }}>
            <ion-icon name="chevron-back-outline"></ion-icon>
          </NextScreenButton>
        </MenuHeader>
        <ChildrenContainer>
          {children}
        </ChildrenContainer>
      </div>
      <BottomButtons>
        <BackButton
          onClick={() => {
            if (isInputModified) {
              if (window.confirm(
                "Existem alterações que não foram salvas. Tem certeza de que deseja voltar?"))
                handleNavigationBack();
            }
            else handleNavigationBack();
          }}>VOLTAR</BackButton>

        {isLoading ?
          <SaveButton>Carregando</SaveButton> :
          <SaveButton
            disabled={!isInputModified}
            onClick={() => handleSaveData()}
          >SALVAR</SaveButton>}
      </BottomButtons>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 420px;
  min-width: 320px;
  padding: 30px;
  background-color: #F2F2F2;

  h2 {
    margin-bottom: 4px;
  }
`;

const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  h1 {
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: center;
    font-size: 24px;
    color: #000000;
  }
`;

const PreviousScreenButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  border-radius: 4px;
  background-color: #DBDBDB;
  cursor: pointer;

  >ion-icon {
    color: #989898;
    transition: color 1500ms ease-in-out;
    font-size: 22px;
    padding-bottom: 2px;
    transform: scaleX(-1);
  }
`;

const NextScreenButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-left: 5px;
  border-radius: 4px;
  background-color: ${(props) => (props.disabled ? "#DBDBDB" : "#FE8F03")};
  cursor: pointer;

  >ion-icon {
    color: ${(props) => (props.disabled ? "#989898" : "#FFFFFF")};
    transition: color 1500ms ease-in-out;
    font-size: 22px;
    padding-bottom: 2px;
    transform: scaleX(-1);
  }
`;

const BottomButtons = styled.div`
  display: flex;
  align-items: center;  
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  margin-top: 30px;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  border-radius: 6px;
  background-color: #C9C9C9;
  color: #626262;
  transition: background-color 1250ms ease-in-out;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  letter-spacing: 0.7px;
  cursor: pointer;
`;

const SaveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  border-radius: 6px;
  background-color: ${(props) => (props.disabled ? "#C9C9C9" : "#FE8F03")};
  color: ${(props) => (props.disabled ? "#626262" : "#FFFFFF")};
  transition: background-color 1250ms ease-in-out;
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  letter-spacing: 0.7px;
  cursor: pointer;
`;

const ChildrenContainer = styled.div`
  overflow-y: auto;
  max-height: calc(100vh - 280px);
  padding-right: 8px;
`;
