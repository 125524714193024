import MA_1 from "./MA-1.jpg";
import MA_2 from "./MA-2.jpg";
import MA_3 from "./MA-3.jpg";
import MA_4 from "./MA-4.jpg";
import MA_5 from "./MA-5.jpg";
import MA_6 from "./MA-6.jpg";
import MA_7 from "./MA-7.jpg";
import MA_8 from "./MA-8.jpg";
import MA_9 from "./MA-9.jpg";
import MA_10 from "./MA-10.jpg";

const MAImg = { MA_1, MA_2, MA_3, MA_4, MA_5, MA_6, MA_7, MA_8, MA_9, MA_10 };
export default MAImg;
