const customToastError = (errors, message) => (
  <div style={{ fontSize: "14px", lineHeight: "1.2" }}>
    <p>{message}</p>
    <ul style={{ listStyleType: "none" }}>
      {errors.map(error => (
        <li key={error}>• {error};</li>
      ))}
    </ul>
  </div>
);

export default customToastError;
