import React, { useState, useLayoutEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { CustomInput } from "../../common/custom-input";
import getTowerImages from "../../../utils/setTowerImages";
import colors from "../../../constants/color-constants";

const TowerSilhouettePlatforms = ({
  images,
  inputValues,
  geometryTotalHeight,
  platformSelectedInMenu,
  setPlatformSelectedInMenu,
  handleInputChange,
  trussesSelected,
  setTowerImages,
  addPlatform,
}) => {
  const trussesContainerRef = useRef(null);

  const [trussesContainerHeight, setTrussesContainerHeight] = useState(0);
  const [isZoomActive, setIsZoomActive] = useState(false);
  const [imagePaths, setImagePaths] = useState(images);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);

  useLayoutEffect(() => {
    getImagePaths();
  }, [images, trussesSelected, setTowerImages, inputValues]);

  useLayoutEffect(() => {
    if (allImagesLoaded) {
      setContainerRef();
    }
  }, [isZoomActive, allImagesLoaded, inputValues]);

  function getImagePaths() {
    if (images?.length === trussesSelected?.length && images?.length !== 0) {
      setImagePaths(images);
    } else {
      const paths = getTowerImages();
      setImagePaths(paths);
      setTowerImages(paths);
    }
  }

  function setContainerRef() {
    if (trussesContainerRef.current) {
      setTrussesContainerHeight(trussesContainerRef.current.clientHeight);
    }
  }

  function handleImageLoad() {
    const imagesInDOM = trussesContainerRef.current.querySelectorAll("img");
    const allLoaded = Array.from(imagesInDOM).every(img => img.complete);
    if (allLoaded) {
      setAllImagesLoaded(true);
    }
  }

  function handleClick(index) {
    if (platformSelectedInMenu === "") return;
    setPlatformSelectedInMenu(index);
  }

  const renderHeightsContainers = useCallback(() => {
    if (trussesContainerHeight === 0) return;

    return (
      <>
        {inputValues.map((platform, index) => {
          const platformHeight = trussesContainerHeight * (geometryTotalHeight - platform?.platformElevation) / (geometryTotalHeight);

          return (
            <InputContainer
              key={index}
              top={platform?.platformElevation === geometryTotalHeight ? platformHeight : platformHeight - 31}
              width={"150px"}
            >
              {platform?.platformElevation === geometryTotalHeight && <div className="line"/>}

              <div className="row">
                <h3>{inputValues[index]?.platformType || ""}</h3>
                <CustomInput
                  readOnly={platformSelectedInMenu === ""}
                  value={inputValues[index]?.platformElevation}
                  onChange={(newValue) => handleInputChange("platformElevation", newValue, index + 1)}
                  onClick={() => handleClick(index + 1)}
                  isNumber={true}
                  unit={"m"}
                />
              </div>

              {platform?.platformElevation !== geometryTotalHeight && <div className="line"/>}
            </InputContainer>
          );
        })}
      </>
    );
  }, [images, inputValues, handleInputChange, imagePaths, trussesContainerHeight]);

  const renderImages = useCallback(() => {
    if (imagePaths?.length === 0) return;

    return imagePaths?.map((imageSrc, index) => {
      return (
        <SegmentImage
          src={imageSrc}
          key={index}
          alt={`Trecho ${index + 1}`}
          isZoomActive={isZoomActive}
          onLoad={handleImageLoad}
        />
      );
    });
  }, [imagePaths]);

  return (
    <SilhouetteContainer>
      <ZoomButtonsContainer isZoomActive={isZoomActive}>
        <button onClick={() => setIsZoomActive(true)}>{" + "}</button>
        <button onClick={() => setIsZoomActive(false)} className="zoomOut">{" - "}</button>
      </ZoomButtonsContainer>

      <PositionalContainer isZoomActive={isZoomActive}>
        <TrussImagesContainer ref={trussesContainerRef} isZoomActive={isZoomActive} imgCount={imagePaths?.length}>
          {renderImages()}
        </TrussImagesContainer>

        <HeightsContainer isZoomActive={isZoomActive}>
          {renderHeightsContainers()}
        </HeightsContainer>
      </PositionalContainer>

      <AddPlatformButton onClick={addPlatform}>
        <p>{"Adicionar plataforma"}</p>
        <div> + </div>
      </AddPlatformButton>
    </SilhouetteContainer>
  );
};

export default TowerSilhouettePlatforms;

const SilhouetteContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
    width: 500px;
    height: min-content;
    max-height: calc(100vh - 300px);
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    border-radius: 6px;
`;

const ZoomButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: min-content;
    gap: 8px !important;
    z-index: 2;

    button {
        width: 40px;
        height: 40px;
        border-radius: 6px;
        background-color: ${props => props.isZoomActive ? colors.kcOrange : colors.kcWhiteColor};
        color: ${props => props.isZoomActive ? colors.kcWhiteColor : colors.kcTextColor};
        font-family: "Roboto", sans-serif;
        font-size: 24px;
        font-weight: 700;

        :hover {
            background-color: ${props => props.isZoomActive ? colors.kcDarkOrange : colors.kcButtonGray};
        }
    }

    .zoomOut {
        background-color: ${props => props.isZoomActive ? colors.kcWhiteColor : colors.kcOrange};
        color: ${props => props.isZoomActive ? colors.kcTextColor : colors.kcWhiteColor};

        :hover {
            background-color: ${props => props.isZoomActive ? colors.kcButtonGray : colors.kcDarkOrange};
        }
    }
`;

const PositionalContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: min-content;
    max-height: calc(100vh - 300px);
    padding-left: 50px;
    box-sizing: border-box;
    overflow-y: ${(props) => (props.isZoomActive ? "auto" : "hidden")} !important;
    overflow-x: hidden;
`;

const TrussImagesContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 130px;
    height: min-content;
    max-height: ${(props) => (props.isZoomActive ? "auto" : "calc(100vh - 320px)")};
    gap: 0;
    padding: 0 50px;
    box-sizing: border-box;
    overflow-x: hidden;

    img {
        height: ${(props) => (props.isZoomActive ? "auto" : "calc(100% / " + props.imgCount + ")")} !important;
    }
`;

const SegmentImage = styled.img`
    width: ${(props) => (props.isZoomActive ? "100px" : "auto")};
    max-width: 100px;
    object-fit: cover;
`;

const HeightsContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    height: 100%;
    max-height: ${(props) => (props.isZoomActive ? "auto" : "calc(100vh - 320px)")};
    box-sizing: border-box;
    position: relative;
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    position: absolute;
    left: 0;
    top: ${(props) => props?.top}px;
    
    .line {
        width: ${(props) => props.width || "150px"};
        height: 1px;
        margin-right: 8px;
        background-color: ${colors.kcBorderGray};
        z-index: 2;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
    }

    h3 {
        display: flex;
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        color: ${colors.kcBlackColor};
        font-family: 'Roboto', sans-serif;
    }
    
    input {
        align-items: center;
        justify-content: center;
        width: 90px;
        height: 30px;
        padding: 3px;
        border-radius: 6px;
        border: 1px solid ${colors.kcBorderGray};
        background-color: ${colors.kcWhiteColor};
        font-family: 'Roboto', sans-serif;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
    }
`;

const AddPlatformButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 120px;
  height: 40px;
  position: absolute;
  bottom: 20px;
  right: 0px;
  padding: 10px 10px;
  background-color: ${colors.kcButtonLightGray};
  border-radius: 6px;
  cursor: pointer;

  :hover {
    background-color: ${colors.kcButtonGray};
  }

  p {
    font-size: 12px;
    font-family: 'Roboto', sans-serif;
  }

  div {
    align-items: center;
    justify-content: center;
    padding: 0 3px;
    border-radius: 30px;
    background-color: ${colors.kcWhiteColor};
    font-size: 18px;
  }
`;
