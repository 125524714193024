import useAsync from "../../useAsync";
import { getWind, postWind, updateWind } from "../../../services/tower-services/towerWindApi";

export const useTowerWind = (action) => {
  const execute = {
    get: getWind,
    post: postWind,
    update: updateWind,
  }[action];

  const {
    data: response,
    loading,
    error,
    act,
  } = useAsync(execute, false);

  return { response, loading, error, act };
};
