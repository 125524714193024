import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";
import { horizontalLockingsInfo } from "../constants/tower-trusses-data";

class HorizontalLockingBarModel {
  constructor(
    barNumber = null,
    isMissingBar = false,
    profileSteel = "",
    profileType = "",
    profileDimensions = "",
    profileDiameter = "",
    profileThickness = "",
    profileFlange = "",
    profileWeb = "",
    boltsSteel = "",
    numConnectionBolts = "",
    diameterConnectionBolts = ""
  ) {
    this.barNumber = barNumber;
    this.isMissingBar = isMissingBar;
    this.profileSteel = profileSteel;
    this.profileType = profileType;
    this.profileDimensions = profileDimensions;
    this.profileDiameter = profileDiameter;
    this.profileThickness = profileThickness;
    this.profileFlange = profileFlange;
    this.profileWeb = profileWeb;
    this.boltsSteel = boltsSteel;
    this.numConnectionBolts = numConnectionBolts;
    this.diameterConnectionBolts = diameterConnectionBolts;
  }

  updateField(field, value) {
    if (value === this[field] || this[field] === undefined) return;

    if (field === "isMissingBar") {
      this[field] = value;
      if (value === true) {
        this.profileType = "";
        this.profileSteel = "";
        this.profileDimensions = "";
        this.profileDiameter = "";
        this.profileThickness = "";
        this.profileFlange = "";
        this.profileWeb = "";
        this.boltsSteel = "";
        this.numConnectionBolts = "";
        this.diameterConnectionBolts = "";
      }
      return;
    }

    if (field === "profileType") {
      this[field] = value;
      this.profileSteel = "";
      this.profileDimensions = "";
      this.profileDiameter = "";
      this.profileThickness = "";
      this.profileFlange = "";
      this.profileWeb = "";
      return;
    }

    if (this.profileType === "T - Tubo Vazado") {
      if (field === "profileSteel") {
        this[field] = value;
        this.profileDimensions = "";
        this.profileDiameter = "";
        this.profileThickness = "";
        this.profileFlange = "";
        this.profileWeb = "";
        return;
      }

      if (field === "profileDiameter") {
        this[field] = value;
        this.profileThickness = "";
        return;
      }
    }

    this[field] = value;
  }
}

class HorizontalLockingDataModel {
  constructor(
    hasHorizontalLocking = "",
    lockingType = "",
    bars = []
  ) {
    this.hasHorizontalLocking = hasHorizontalLocking;
    this.lockingType = lockingType;
    this.bars = bars.map((bar, index) => new HorizontalLockingBarModel(
      bar.barNumber = index + 1,
      bar.isMissingBar,
      bar.profileSteel,
      bar.profileType,
      bar.profileDimensions,
      bar.profileDiameter,
      bar.profileThickness,
      bar.profileFlange,
      bar.profileWeb,
      bar.boltsSteel,
      bar.numConnectionBolts,
      bar.diameterConnectionBolts
    )) || [];
  }

  updateHorizontal(horizontalType, field, value, sectionType) {
    if (value === this[field] || this[field] === undefined) return;

    const numberOfBars = this.findNumberOfBars(sectionType);

    if (field === "hasHorizontalLocking") {
      this[field] = value;
      if (value !== "Sim") {
        this.lockingType = "";
        this.bars = numberOfBars > 0
          ? Array.from({ length: numberOfBars }, (bar, i) => new HorizontalLockingBarModel(
            i + 1,
            false,
            "", "", "", "", "", "", "", "", "", ""
          )) : [];
      }
      return;
    }

    if (field === "lockingType") {
      this[field] = value;
      const newNumberOfBars = this.findNumberOfBars(sectionType);
      this.bars = newNumberOfBars > 0
        ? Array.from({ length: newNumberOfBars }, (bar, i) => new HorizontalLockingBarModel(
          i + 1,
          false,
          "", "", "", "", "", "", "", "", "", ""
        )) : [];
      return;
    }

    this[field] = value;
  }

  updateBar(barIndex, field, value) {
    if (this.bars[barIndex]) {
      this.bars[barIndex].updateField(field, value);
    }
  }

  findNumberOfBars(sectionType) {
    if (this.lockingType === "") return 0;
    return horizontalLockingsInfo[sectionType]
      .find(type => type.lockingType === this.lockingType)?.bars;
  }
}

class HorizontalLockingSegmentModel {
  constructor(
    segmentId = "",
    trussType = "",
    hasSuperiorHorizontal = "",
    mainHorizontalData = {},
    superiorHorizontalData = {}
  ) {
    this.segmentId = segmentId;
    this.trussType = trussType;
    this.hasSuperiorHorizontal = hasSuperiorHorizontal;
    this.mainHorizontalData = this.trussType !== "Treliça V"
      ? new HorizontalLockingDataModel(
        mainHorizontalData.hasHorizontalLocking,
        mainHorizontalData.lockingType,
        mainHorizontalData.bars
      ) : {};
    this.superiorHorizontalData = this.hasSuperiorHorizontal === "Sim"
      ? new HorizontalLockingDataModel(
        superiorHorizontalData.hasHorizontalLocking,
        superiorHorizontalData.lockingType,
        superiorHorizontalData.bars
      ) : {};
  }

  updateHorizontalData(horizontalType, field, value, sectionType) {
    if (horizontalType === "mainHorizontalData") {
      this.mainHorizontalData.updateHorizontal(horizontalType, field, value, sectionType);
    } else if (horizontalType === "superiorHorizontalData") {
      this.superiorHorizontalData.updateHorizontal(horizontalType, field, value, sectionType);
    }
  }

  updateBarData(horizontalType, barIndex, field, value) {
    if (horizontalType === "mainHorizontalData") {
      this.mainHorizontalData.updateBar(barIndex, field, value);
    } else if (horizontalType === "superiorHorizontalData") {
      this.superiorHorizontalData.updateBar(barIndex, field, value);
    }
  }
}

class HorizontalLockingsModel {
  constructor(horizontalLockings = []) {
    this.horizontalLockings = horizontalLockings.map(segment => new HorizontalLockingSegmentModel(
      segment.segmentId,
      segment.trussType,
      segment.hasSuperiorHorizontal,
      segment.mainHorizontalData || {},
      segment.superiorHorizontalData || {}
    ));
  }

  updateHorizontalLocking(segmentId, horizontalType, field, value, sectionType) {
    const segment = this.horizontalLockings.find(segment => segment.segmentId === segmentId);
    if (segment) {
      segment.updateHorizontalData(horizontalType, field, value, sectionType);
    }
  }

  updateHorizontalLockingBar(segmentId, horizontalType, barIndex, field, value) {
    const segment = this.horizontalLockings.find(segment => segment.segmentId === segmentId);
    if (segment) {
      segment.updateBarData(horizontalType, barIndex, field, value);
    }
  }

  validate() {
    let errors = [];

    this.horizontalLockings.forEach((segment, index) => {
      if (segment.trussType !== "Treliça V") {
        if (segment.mainHorizontalData?.hasHorizontalLocking === "")
          errors.push(`Se a existe travamento na horizontal principal no trecho ${index + 1}`);

        if (segment.mainHorizontalData?.hasHorizontalLocking === "Sim") {
          if (!segment.mainHorizontalData?.lockingType)
            errors.push(`Tipo de travamento na horizontal principal no trecho ${index + 1}`);

          Object.values(segment.mainHorizontalData?.bars).forEach((bar, idx) => {
            if (bar?.isMissingBar !== true) {
              if (!bar?.profileType)
                errors.push(`Tipo de Perfil do travamento na barra ${idx + 1} na 
                horizontal principal no trecho ${index + 1}`);
              if (bar?.profileSteel === "")
                errors.push(`Aço do Perfil do travamento na barra ${idx + 1} na 
                horizontal principal no trecho ${index + 1}`);
              if (!bar?.profileDimensions && !bar?.profileDiameter && !bar?.profileThickness
                && !bar?.profileFlange && !bar?.profileWeb) {
                errors.push(`Dimensões do Perfil do travamento na barra ${idx + 1} na 
                horizontal principal no trecho ${index + 1}`);
              } else {
                if (bar.profileType === "T - Tubo Vazado" &&
                  (!bar?.profileDiameter || !bar?.profileThickness))
                  errors.push(`Dimensões do Perfil do travamento na barra ${idx + 1} na 
                  horizontal principal no trecho ${index + 1}`);
                if (bar.profileType === "U - Perfil U" && (!bar?.profileFlange ||
                  !bar?.profileWeb || !bar?.profileThickness))
                  errors.push(`Dimensões do Perfil do travamento na barra ${idx + 1} na 
                  horizontal principal no trecho ${index + 1}`);
              }
              if (!bar?.boltsSteel)
                errors.push(`Aço dos Parafusos do travamento na barra ${idx + 1} na 
                horizontal principal no trecho ${index + 1}`);
              if (!bar?.numConnectionBolts)
                errors.push(`Quantidade de parafusos de conexão do travamento na barra 
                ${idx + 1} na horizontal principal no trecho ${index + 1}`);
              if (!bar?.diameterConnectionBolts)
                errors.push(`Diametro de parafusos de conexão do travamento na barra 
                ${idx + 1} na horizontal principal no trecho ${index + 1}`);
            }
          });
        }

        if (segment.hasSuperiorHorizontal === "Sim") {
          if (segment.superiorHorizontalData?.hasHorizontalLocking === "")
            errors.push(`Se a existe travamento na horizontal superior no trecho ${index + 1}`);
          if (segment.superiorHorizontalData?.hasHorizontalLocking === "Sim") {
            if (!segment.superiorHorizontalData?.lockingType)
              errors.push(`Tipo de travamento na horizontal superior no trecho ${index + 1}`);
            Object.values(segment.superiorHorizontalData?.bars).forEach((bar, idx) => {
              if (bar?.isMissingBar !== true) {
                if (!bar.profileType)
                  errors.push(`Tipo de Perfil do travamento na barra ${idx + 1} na horizontal 
                superior no trecho ${index + 1}`);
                if (bar?.profileSteel === "")
                  errors.push(`Aço do Perfil do travamento na barra ${idx + 1} na horizontal 
                superior no trecho ${index + 1}`);
                if (!bar?.profileDimensions && !bar?.profileDiameter
                  && !bar?.profileThickness && !bar?.profileFlange
                  && !bar?.profileWeb) {
                  errors.push(`Dimensões do Perfil do travamento na barra ${idx + 1} na 
                horizontal superior no trecho ${index + 1}`);
                } else {
                  if (bar.profileType === "T - Tubo Vazado" &&
                    (!bar?.profileDiameter || !bar?.profileThickness))
                    errors.push(`Dimensões do Perfil do travamento na barra ${idx + 1} na 
                  horizontal superior no trecho ${index + 1}`);
                  if (bar.profileType === "U - Perfil U"
                    && (!bar?.profileFlange
                      || !bar?.profileWeb
                      || !bar?.profileThickness))
                    errors.push(`Dimensões do Perfil do travamento na barra ${idx + 1} na 
                  horizontal superior no trecho ${index + 1}`);
                }
                if (!bar?.boltsSteel)
                  errors.push(`Aço dos Parafusos do travamento na barra ${idx + 1} na 
                horizontal superior no trecho ${index + 1}`);
                if (!bar?.numConnectionBolts)
                  errors.push(`Quantidade de parafusos de conexão do travamento na barra 
                ${idx + 1} na horizontal superior no trecho ${index + 1}`);
                if (!bar?.diameterConnectionBolts)
                  errors.push(`Diametro de parafusos de conexão do travamento na barra 
                ${idx + 1} na horizontal superior no trecho ${index + 1}`);
              }
            });
          }
        }
      }
    });

    if (errors.length > 0) {
      const errorMessage = customToastError(
        errors,
        "Preencha todos os valores corretamente para salvar as informações dos travamentos horizontais. Falta(m):"
      );
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  toJSON() {
    return JSON.stringify(this.horizontalLockings);
  }

  static fromJSON(json) {
    const horizontalLockingsArray = JSON.parse(json);
    return new HorizontalLockingsModel(horizontalLockingsArray);
  }
}

export default HorizontalLockingsModel;
