import api from "../apiTower";

const makeHeaders = (token) => ({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const getAnchors = async(towerId, token) => {
  const response = await api
    .get(`/tower/anchors/${encodeURIComponent(towerId)}`, makeHeaders(token));
  return response.data;
};

export const postAnchors = async(body, towerId, token) => {
  const parsedBody = JSON.parse(body);
  const response = await api
    .post(`/tower/anchors/${encodeURIComponent(towerId)}`, parsedBody, makeHeaders(token));
  return response.data;
};

export const updateAnchors = async(body, towerId, token) => {
  const parsedBody = JSON.parse(body);
  const response = await api
    .put(`/tower/anchors/${encodeURIComponent(towerId)}`, parsedBody, makeHeaders(token));
  return response.data;
};
