import ME_1_1 from "./1MOD/ME-1-1.jpg";
import ME_1_2 from "./1MOD/ME-1-2.jpg";
import ME_1_3 from "./1MOD/ME-1-3.jpg";
import ME_1_4 from "./1MOD/ME-1-4.jpg";
import ME_1_5 from "./1MOD/ME-1-5.jpg";
import ME_1_6 from "./1MOD/ME-1-6.jpg";
import ME_1_7 from "./1MOD/ME-1-7.jpg";
import ME_1_8 from "./1MOD/ME-1-8.jpg";
import ME_1_9 from "./1MOD/ME-1-9.jpg";
import ME_1_10 from "./1MOD/ME-1-10.jpg";

import ME_2_2 from "./2MOD/ME-2-2.jpg";
import ME_2_3 from "./2MOD/ME-2-3.jpg";
import ME_2_4 from "./2MOD/ME-2-4.jpg";
import ME_2_5 from "./2MOD/ME-2-5.jpg";
import ME_2_6 from "./2MOD/ME-2-6.jpg";
import ME_2_7 from "./2MOD/ME-2-7.jpg";
import ME_2_8 from "./2MOD/ME-2-8.jpg";
import ME_2_9 from "./2MOD/ME-2-9.jpg";
import ME_2_10 from "./2MOD/ME-2-10.jpg";

import ME_3_3 from "./3MOD/ME-3-3.jpg";
import ME_3_4 from "./3MOD/ME-3-4.jpg";
import ME_3_5 from "./3MOD/ME-3-5.jpg";
import ME_3_6 from "./3MOD/ME-3-6.jpg";
import ME_3_7 from "./3MOD/ME-3-7.jpg";
import ME_3_8 from "./3MOD/ME-3-8.jpg";
import ME_3_9 from "./3MOD/ME-3-9.jpg";
import ME_3_10 from "./3MOD/ME-3-10.jpg";

import ME_4_4 from "./4MOD/ME-4-4.jpg";
import ME_4_5 from "./4MOD/ME-4-5.jpg";
import ME_4_6 from "./4MOD/ME-4-6.jpg";
import ME_4_7 from "./4MOD/ME-4-7.jpg";
import ME_4_8 from "./4MOD/ME-4-8.jpg";
import ME_4_9 from "./4MOD/ME-4-9.jpg";
import ME_4_10 from "./4MOD/ME-4-10.jpg";

import ME_5_5 from "./5MOD/ME-5-5.jpg";
import ME_5_6 from "./5MOD/ME-5-6.jpg";
import ME_5_7 from "./5MOD/ME-5-7.jpg";
import ME_5_8 from "./5MOD/ME-5-8.jpg";
import ME_5_9 from "./5MOD/ME-5-9.jpg";
import ME_5_10 from "./5MOD/ME-5-10.jpg";

import ME_6_6 from "./6MOD/ME-6-6.jpg";
import ME_6_7 from "./6MOD/ME-6-7.jpg";
import ME_6_8 from "./6MOD/ME-6-8.jpg";
import ME_6_9 from "./6MOD/ME-6-9.jpg";
import ME_6_10 from "./6MOD/ME-6-10.jpg";

import ME_7_7 from "./7MOD/ME-7-7.jpg";
import ME_7_8 from "./7MOD/ME-7-8.jpg";
import ME_7_9 from "./7MOD/ME-7-9.jpg";
import ME_7_10 from "./7MOD/ME-7-10.jpg";

import ME_8_8 from "./8MOD/ME-8-8.jpg";
import ME_8_9 from "./8MOD/ME-8-9.jpg";
import ME_8_10 from "./8MOD/ME-8-10.jpg";

import ME_9_9 from "./9MOD/ME-9-9.jpg";
import ME_9_10 from "./9MOD/ME-9-10.jpg";

import ME_10_10 from "./10MOD/ME-10-10.jpg";

const MEImg = { ME_1_1, ME_1_2, ME_1_3, ME_1_4, ME_1_5, ME_1_6, ME_1_7, ME_1_8, ME_1_9, ME_1_10, ME_2_2, ME_2_3, ME_2_4, ME_2_5, ME_2_6, ME_2_7, ME_2_8, ME_2_9, ME_2_10, ME_3_3, ME_3_4, ME_3_5, ME_3_6, ME_3_7, ME_3_8, ME_3_9, ME_3_10, ME_4_4, ME_4_5, ME_4_6, ME_4_7, ME_4_8, ME_4_9, ME_4_10, ME_5_5, ME_5_6, ME_5_7, ME_5_8, ME_5_9, ME_5_10, ME_6_6, ME_6_7, ME_6_8, ME_6_9, ME_6_10, ME_7_7, ME_7_8, ME_7_9, ME_7_10, ME_8_8, ME_8_9, ME_8_10, ME_9_9, ME_9_10, ME_10_10 };

export default MEImg;
