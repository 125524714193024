import useAsync from "../useAsync";
import * as authApi from "../../services/userApi";

export default function useUpdatePasswordWithoutValidationCode() {
  const {
    loading: updatePasswordWithoutValidationCodeLoading,
    error: updatePasswordWithoutValidationCodeError,
    act: updatePasswordWithoutValidationCode,
  } = useAsync(
    (token, password) =>
      authApi.updatePasswordWithoutValidationCode(token, password),
    false
  );

  return {
    updatePasswordWithoutValidationCodeLoading,
    updatePasswordWithoutValidationCodeError,
    updatePasswordWithoutValidationCode,
  };
}
