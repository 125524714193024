import { SessionTitle, } from "../project-components/project-leftMenu";
import { useContext, useState, useEffect, useRef } from "react";
import { ProjectContext } from "../../contexts/ProjectContext";
import SelectMastDialog from "./mast-addAndSelectionDialog";
import styled from "styled-components";
import white_edit_icon from "../../assets/images/white-edit-icon.svg";
import gray_edit_icon from "../../assets/images/gray-edit-icon.svg";
import orange_edit_icon from "../../assets/images/orange-edit-icon.svg";
import black_edit_icon from "../../assets/images/black-edit-icon.svg";

export default function MastNameSelection({ projectMasts }) {
  const { mastSelected } = useContext(ProjectContext);

  const [confirmDialog, setConfirmDialog] = useState({
    type: "",
    isOpen: false,
  });
  const scrollContainerRef = useRef(null);

  function selectMast(mast) {
    setConfirmDialog({ ...confirmDialog, type: "select", isOpen: true, mast: mast });
  }

  function openAddingMastDialog() {
    setConfirmDialog({ ...confirmDialog, type: "new", isOpen: true });
  }

  function openUpdatingMastIdentificatorDialog(mast) {
    setConfirmDialog({ ...confirmDialog, type: "update", isOpen: true, mast: mast });
  }

  async function postDeleteMast(mast) {
    setConfirmDialog({ ...confirmDialog, type: "delete", isOpen: true, mast: mast });
  }

  const selectedMast = projectMasts.find(mast => mastSelected && mastSelected?.identificador === mast.identificador);
  const nonSelectedMasts = projectMasts.filter(mast => mastSelected === 0 || mastSelected?.identificador !== mast.identificador);
  const reorderedMasts = selectedMast
    ? [selectedMast, ...nonSelectedMasts].sort((a, b) => {
      if (a.identificador === selectedMast.identificador) return -1;
      if (b.identificador === selectedMast.identificador) return 1;
      return a.identificador.localeCompare(b.identificador);
    })
    : nonSelectedMasts.sort((a, b) => a.identificador.localeCompare(b.identificador));

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [reorderedMasts]);

  const [addingMastIcon, setAddingMastIcon] = useState("add-circle-outline");

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (!projectMasts?.length) setAddingMastIcon(prevIcon => (prevIcon === "add-circle-outline" ? "add-circle-sharp" : "add-circle-outline"));
    }, 650);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <SessionTitle>
        <h1>Mastros</h1>
        <AddMastIconContainer onClick={openAddingMastDialog}>
          <ion-icon name={addingMastIcon}></ion-icon>
        </AddMastIconContainer>
        {reorderedMasts.length ?
          <SelectingAllMastsContainer ref={scrollContainerRef}>
            {reorderedMasts.map((mast) => <SelectingMast mastName={mast.identificador} key={mast.id} id={mast.id} mast={mast} postDeleteMast={postDeleteMast} openUpdatingMastIdentificatorDialog={openUpdatingMastIdentificatorDialog} selectMast={selectMast} isSelected={mastSelected ? mastSelected?.identificador === mast.identificador : false} />)}
          </SelectingAllMastsContainer>
          :
          ""
        }
      </SessionTitle>
      <SelectMastDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

function SelectingMast({ mastName, postDeleteMast, openUpdatingMastIdentificatorDialog, selectMast, mast, isSelected }) {
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const updateTooltipPosition = (event) => {
    setTooltipPosition({ x: event.clientX + 10, y: event.clientY + 10 });
  };
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [imgSrc, setImgSrc] = useState(!isSelected ? gray_edit_icon : white_edit_icon);
  const handleMouseOver = () => {
    setIsMouseOver(true);
    setImgSrc(!isSelected ? orange_edit_icon : black_edit_icon);
  };

  const handleMouseOut = () => {
    setIsMouseOver(false);
    setImgSrc(!isSelected ? gray_edit_icon : white_edit_icon);
  };

  const handleClick = (event) => {
    event.stopPropagation();
    openUpdatingMastIdentificatorDialog(mast);
  };
  if (!isSelected) {
    return (
      <SelectingMastContainer onClick={() => selectMast(mast)}>
        <h1>{mastName}</h1>
        <img src={imgSrc} alt="editing icon" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={handleClick} />
        <ion-icon name="trash-outline" onClick={(event) => {
          event.stopPropagation();
          postDeleteMast(mast);
        }}></ion-icon>
      </SelectingMastContainer>
    );
  }
  else return (
    <SelectedMastContainer>
      <h1>{mastName}</h1>
      <img src={imgSrc} alt="editing icon" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={handleClick} />
      <ion-icon name="trash-outline" onClick={(event) => {
        event.stopPropagation();
        postDeleteMast(mast);
      }}></ion-icon>
      <MastSelectedTooltip visible={tooltipVisible} style={{ top: tooltipPosition.y, left: tooltipPosition.x }}
        onMouseEnter={(event) => {
          event.stopPropagation();
          setTooltipVisible(true);
          updateTooltipPosition(event);
        }}
        onMouseMove={(event) => {
          event.stopPropagation();
          updateTooltipPosition(event);
        }}
        onMouseLeave={(event) => {
          event.stopPropagation();
          setTooltipVisible(false);
        }}>
        Mastro selecionado
      </MastSelectedTooltip>
    </SelectedMastContainer>
  );
}

const AddMastIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4.5vh;
  right: 6%;
  ion-icon {
    color: #FE8F03;
    font-size: 28px;
    cursor: pointer;
  }
`;

const SelectingAllMastsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 23vh;
  width: 100%;
  overflow-y: scroll;
  margin-bottom: 10px;
  cursor: pointer;
  ::-webkit-scrollbar {
    width: 5px; 
    height: 30px;
    margin-left: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #FE8F03; 
    border-radius: 5px; 
    margin-left: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
  scrollbar {
    width: 5px; 
    height: 30px;
    margin-left: 5px;
  }
  scrollbar-thumb {
    background-color: #FE8F03;
    border-radius: 5px; 
    margin-left: 5px;
  }
  scrollbar-thumb:hover {
    background-color: #555; 
  }
  @media (max-height: 880px) {
    max-height: 20vh;
  }
`;

const SelectingMastContainer = styled.div`
  display: flex;
  width: 98%;
  align-items: center;
  justify-content: space-between;
  min-height: 5vh;
  border-radius: 6px;
  border: 1px solid #C8C8C8;
  background-color: #ffffff;
  margin-bottom: 5px;
  position: relative;
  cursor: pointer;

  h1 {
    color: #565656 !important;
    font-weight: 400 !important;
    padding: 1px 0 0 14px !important;
    font-size: 18px !important;
  }

  ion-icon {
    color: #A8B4B5;
    font-size: 23px;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 10px;
    :hover {
      color: #FE8F03 !important;
    }
  }

  img {
    position: absolute;
    right: 44px;
    cursor: pointer;
    width: 23px;
  }

  :hover {
    background-color: #000000;
    h1 {
      color: #fff !important;
    }
    ion-icon {
      color: #ffffff;
    }
  }
`;

const SelectedMastContainer = styled.div`
  display: flex;
  width: 98%;
  align-items: center;
  justify-content: space-between;
  min-height: 5vh;
  border-radius: 6px;
  border: 1px solid #C8C8C8;
  background-color: #FE8F03;
  margin-bottom: 5px;
  position: relative;
  cursor: initial;
  h1 {
    color: #000000 !important;
    font-weight: 500 !important;
    padding: 1px 0 0 14px !important;
    font-size: 18px !important;
  }

  ion-icon {
    color: #ffffff;
    font-size: 23px;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 12px;
      :hover {
      color: #000;
    }
  }

  img {
    position: absolute;
    right: 44px;
    cursor: pointer;
    width: 23px;
  }
`;

const MastSelectedTooltip = styled.div`
  position: absolute;
  display: ${(props) => (props.visible ? "block" : "none")};
  background-color: #000;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  pointer-events: none;
  z-index: 9999;
`;
