const kcWhiteColor = "#FFFFFF";
const kcLightGray = "#F2F2F2";
const kcBorderGray = "#C8C8C8";
const kcButtonGray = "#C9C9C9";
const kcButtonLightGray = "#DBDBDB";
const kcBgGray = "#B0B0B0";
const kcMediumGray = "#8C8C8C";
const kcDarkGray = "#565656";
const kcTextColor = "#313131";
const kcBlackColor = "#000000";
const kcOrange = "#FE8F03";
const kcDarkOrange = "#F16E00";
const kcRed = "#B90E0A";
const kcGreen = "#2A9F36";
const kcBlue = "#337BD0";

const colors = {
  kcWhiteColor,
  kcLightGray,
  kcBorderGray,
  kcButtonLightGray,
  kcButtonGray,
  kcBgGray,
  kcMediumGray,
  kcDarkGray,
  kcTextColor,
  kcBlackColor,
  kcOrange,
  kcDarkOrange,
  kcRed,
  kcGreen,
  kcBlue,
};

export default colors;
