import MB_1_1 from "./1MOD/MB-1-1.jpg";
import MB_1_2 from "./1MOD/MB-1-2.jpg";
import MB_1_3 from "./1MOD/MB-1-3.jpg";
import MB_1_4 from "./1MOD/MB-1-4.jpg";
import MB_1_5 from "./1MOD/MB-1-5.jpg";
import MB_1_6 from "./1MOD/MB-1-6.jpg";
import MB_1_7 from "./1MOD/MB-1-7.jpg";
import MB_1_8 from "./1MOD/MB-1-8.jpg";
import MB_1_9 from "./1MOD/MB-1-9.jpg";
import MB_1_10 from "./1MOD/MB-1-10.jpg";

import MB_2_2 from "./2MOD/MB-2-2.jpg";
import MB_2_3 from "./2MOD/MB-2-3.jpg";
import MB_2_4 from "./2MOD/MB-2-4.jpg";
import MB_2_5 from "./2MOD/MB-2-5.jpg";
import MB_2_6 from "./2MOD/MB-2-6.jpg";
import MB_2_7 from "./2MOD/MB-2-7.jpg";
import MB_2_8 from "./2MOD/MB-2-8.jpg";
import MB_2_9 from "./2MOD/MB-2-9.jpg";
import MB_2_10 from "./2MOD/MB-2-10.jpg";

import MB_3_3 from "./3MOD/MB-3-3.jpg";
import MB_3_4 from "./3MOD/MB-3-4.jpg";
import MB_3_5 from "./3MOD/MB-3-5.jpg";
import MB_3_6 from "./3MOD/MB-3-6.jpg";
import MB_3_7 from "./3MOD/MB-3-7.jpg";
import MB_3_8 from "./3MOD/MB-3-8.jpg";
import MB_3_9 from "./3MOD/MB-3-9.jpg";
import MB_3_10 from "./3MOD/MB-3-10.jpg";

import MB_4_4 from "./4MOD/MB-4-4.jpg";
import MB_4_5 from "./4MOD/MB-4-5.jpg";
import MB_4_6 from "./4MOD/MB-4-6.jpg";
import MB_4_7 from "./4MOD/MB-4-7.jpg";
import MB_4_8 from "./4MOD/MB-4-8.jpg";
import MB_4_9 from "./4MOD/MB-4-9.jpg";
import MB_4_10 from "./4MOD/MB-4-10.jpg";

import MB_5_5 from "./5MOD/MB-5-5.jpg";
import MB_5_6 from "./5MOD/MB-5-6.jpg";
import MB_5_7 from "./5MOD/MB-5-7.jpg";
import MB_5_8 from "./5MOD/MB-5-8.jpg";
import MB_5_9 from "./5MOD/MB-5-9.jpg";
import MB_5_10 from "./5MOD/MB-5-10.jpg";

import MB_6_6 from "./6MOD/MB-6-6.jpg";
import MB_6_7 from "./6MOD/MB-6-7.jpg";
import MB_6_8 from "./6MOD/MB-6-8.jpg";
import MB_6_9 from "./6MOD/MB-6-9.jpg";
import MB_6_10 from "./6MOD/MB-6-10.jpg";

import MB_7_7 from "./7MOD/MB-7-7.jpg";
import MB_7_8 from "./7MOD/MB-7-8.jpg";
import MB_7_9 from "./7MOD/MB-7-9.jpg";
import MB_7_10 from "./7MOD/MB-7-10.jpg";

import MB_8_8 from "./8MOD/MB-8-8.jpg";
import MB_8_9 from "./8MOD/MB-8-9.jpg";
import MB_8_10 from "./8MOD/MB-8-10.jpg";

import MB_9_9 from "./9MOD/MB-9-9.jpg";
import MB_9_10 from "./9MOD/MB-9-10.jpg";

import MB_10_10 from "./10MOD/MB-10-10.jpg";

const MBImg = { MB_1_1, MB_1_2, MB_1_3, MB_1_4, MB_1_5, MB_1_6, MB_1_7, MB_1_8, MB_1_9, MB_1_10, MB_2_2, MB_2_3, MB_2_4, MB_2_5, MB_2_6, MB_2_7, MB_2_8, MB_2_9, MB_2_10, MB_3_3, MB_3_4, MB_3_5, MB_3_6, MB_3_7, MB_3_8, MB_3_9, MB_3_10, MB_4_4, MB_4_5, MB_4_6, MB_4_7, MB_4_8, MB_4_9, MB_4_10, MB_5_5, MB_5_6, MB_5_7, MB_5_8, MB_5_9, MB_5_10, MB_6_6, MB_6_7, MB_6_8, MB_6_9, MB_6_10, MB_7_7, MB_7_8, MB_7_9, MB_7_10, MB_8_8, MB_8_9, MB_8_10, MB_9_9, MB_9_10, MB_10_10 };

export default MBImg;
