import React, { useState, useRef, useContext, useEffect } from "react";
import styled from "styled-components";
import { LeftTitle, BottomBorderTitle, ButtonPositioner, ReturnButton, ReturnIcon, AdvanceIcon } from "../../project-components/wind-components/project-windData";
import { ProjectContext } from "../../../contexts/ProjectContext";
import UserContext from "../../../contexts/UserContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SavingButton from "../../common/saving-button";
import LoadingButton from "../../common/loading-button";
import useUpdateMast from "../../../hooks/api/useUpdateMast";
import useUnsavedChangesAlert from "../../../hooks/useUnsavedChangesAlert";
import ResizableLeftContainer from "../../common/resizableLeftContainer";
import ResizableRightContainer from "../../common/resizableRightContainer";
import CreatableSelect from "react-select/creatable";
import ModuleRightContainer from "./mast-moduleRightContainer";

export default function MastModulePage() {
  const { setLeftMenuOptionSelected, mastSelected, setMastSelected, reloadProject, setReloadProject, projectSelected, setIsUpdatedWithoutCalculation, setProjectSelected, setProjectNameSelected } = useContext(ProjectContext);
  const { userData } = useContext(UserContext);
  const mastProps = { ...JSON.parse(mastSelected.props), ...JSON.parse(projectSelected.props) };
  const projectProps = JSON.parse(projectSelected.props);
  const qtdModulos = Number(mastProps.qtdModulos);
  const [moduleSelectedInMenu, setModuleSelectedInMenu] = useState(1);
  const { updateMast, updateMastLoading } = useUpdateMast();
  const [moduleSelectedBackgroundColor, setModuleSelectedBackgroundColor] = useState("#F16E00");
  // eslint-disable-next-line quotes
  const parafusosFlange = ['3/8" - 9.5mm', '1/2" - 12.7mm', '5/8" - 15.9mm', '3/4" - 19.05mm', '7/8" - 22.2mm', '1" - 25.4mm', '1 1/4" - 31.8mm', '1 5/16" - 33.3mm', '1 3/8" - 34.9mm', '1 1/2" - 38.1mm', '1 5/8" - 41.3mm', '1 3/4" - 44.45mm', '1 7/8" - 47.6mm', '2" - 50.8mm', '2 1/4" - 57.2mm', '2 5/16" - 58.7mm', '2 3/8" - 60.3mm', '2 1/2" - 63.5mm', '2 5/8" - 66.7mm', '2 3/4" - 69.85mm', '2 7/8" - 73mm'];
  const diametroModuloAstm = ["10.3", "13.7", "17.1", "21.3", "26.7", "33.4", "42.2", "48.3", "60.3", "73", "88.9", "101.6", "114.3", "141.3", "168.3", "219.1", "273", "323.8", "355.6"];
  const espessurasModulo = ["3.2", "4.8", "6.4", "7.9", "9.5", "12.7", "15.9", "19"];
  const tiposDeAçoTubo = ["ASTM A36", "DIN 2440", "SCHEDULE 40", "SCHEDULE 80"];
  const tiposDeAçoParafusos = [
    "ASTM A307",
    "ASTM A394",
    "ASTM A325",
    "ASTM A490",
    "DIN 8.8",
  ];
  const [inputValues, setInputValues] = useState({
    moduleSelected: 1,
    modulo1Altura: mastProps?.modulo1Altura || "",
    modulo1Diametro: mastProps?.modulo1Diametro || "",
    modulo1Espessura: mastProps?.modulo1Espessura || "",
    modulo1FlangeDiametro: mastProps?.modulo1FlangeDiametro || "",
    modulo1FlangeEspessura: mastProps?.modulo1FlangeEspessura || "",
    modulo1ParafusosFlangeDiametro: mastProps?.modulo1ParafusosFlangeDiametro || "",
    modulo1ParafusosFlangeNumero: mastProps?.modulo1ParafusosFlangeNumero || "",
    modulo1ParafusosAco: mastProps?.modulo1ParafusosAco || "",
    modulo1Tubos: mastProps?.modulo1Tubos || "",
  });
  const fieldsToCheck = [[`modulo${moduleSelectedInMenu}Altura`], [`modulo${moduleSelectedInMenu}Diametro`], [`modulo${moduleSelectedInMenu}Espessura`], [`modulo${moduleSelectedInMenu}FlangeDiametro`], [`modulo${moduleSelectedInMenu}FlangeEspessura`], [`modulo${moduleSelectedInMenu}ParafusosFlangeDiametro`], [`modulo${moduleSelectedInMenu}ParafusosFlangeNumero`], [`modulo${moduleSelectedInMenu}ParafusosAco`], [`modulo${moduleSelectedInMenu}Tubos`]];
  const isInputModified = useUnsavedChangesAlert(inputValues, mastProps, reloadProject, fieldsToCheck);

  const [updatedModuleThickness, setUpdatedModuleThickness] = useState(espessurasModulo);

  const dimensoesTubos = [  {
    key: "ASTM A36", values: [
      { "10.3": ["1.7", "2.4"] },
      { "13.7": ["2.2", "3"] },
      { "17.1": ["2.3", "3.2"] },
      { "21.3": ["2.8", "3.7", "4.8", "7.5"] },
      { "26.7": ["2.9", "3.9", "5.6", "7.8"] },
      { "33.4": ["3.4", "4.5", "6.4", "9.1"] },
      { "42.2": ["3.6", "4.8", "6.4", "9.7"] },
      { "48.3": ["3.7", "5.1", "7.1", "10.2"] },
      { "60.3": ["3.9", "5.5", "8.7", "11.1"] },
      { "73": ["5.2", "7", "9.5", "14"] },
      { "88.9": ["3.2", "4", "4.8", "5.5", "6.4", "7.1", "7.6", "11.1", "15.2"] },
      { "101.6": ["8.1", "3.2", "4", "4.8", "5.7", "6.4", "7.1"] },
      { "114.3": ["3.2", "4", "4.8", "5.6", "6", "6.4", "7.1", "7.9", "8.6", "11.1", "13.5", "17.1"] },
      { "141.3": ["4", "4.8", "5.6", "6.6", "7.1", "7.9", "8.7", "9.5", "12.7", "15.9", "19"] },
      { "168.3": ["4.8", "5.6", "6.4", "7.1", "7.9", "8.7", "9.5", "11", "14.3", "18.3", "21.9"] },
      { "219.1": ["4.8", "5.2", "5.6", "6.4", "7", "7.9", "8.2", "8.7", "9.5", "10.3", "11.1", "12.7", "15.1", "18.3", "20.6", "22.2", "23", "26.3", "29.5", "32.6", "33.3"] },
      { "273": ["4.8", "5.2", "5.6", "6.4", "7.1", "7.8", "8.7", "9.3", "11.1", "12.7", "15.1", "18.3", "21.4", "25.4", "28.6"] },
      { "323.8": ["5.2", "5.6", "6.4", "7.1", "7.9", "8.4", "8.7", "9.5", "11.1", "12.7", "14.3", "15.9", "17.5", "19", "20.6", "22.2", "23.8", "26.2", "29.4", "32.5", "33.3"] },
      { "355,6": ["5,3", "5,6", "6,4", "7,1", "7,9", "8,7", "9,5", "11,1", "11,9", "12,7", "15,1", "19", "23,8", "27,8", "31,8", "35,7", "50,8", "54", "55,9", "63,5"] }
    ] },
  {    key: "DIN 2440", values: [
    { "13,5": ["2,35"] },
    { "17,2": ["2, 35"] },
    { "21,3": ["2,25", "2,65", "3"] },
    { "26,9": ["2,25", "2,65", "3"] },
    { "33,7": ["2,65", "3,35", "3,75"] },
    { "42,4": ["2,65", "3,35", "3,75"] },
    { "48,3": ["3", "3,35", "3,75"] },
    { "60,3": ["3", "3,75", "4,5"] },
    { "76,1": ["3,35", "3,75", "4,5"] },
    { "88,9": ["3,35", "4", "4,5"] },
    { "101,6": ["3,75", "4,25", "5"] },
    { "114,3": ["3,75", "4,5", "5,6"] },
    { "139,7": ["4,75", "5,6"] },
    { "165,1": ["5", "5,6"] },
    { "219,1": ["6,3"] },
  ]
  },
  {
    key: "SCHEDULE 40", values: [
      { "21,3": ["2,77"] },
      { "26,7": ["2,87"] },
      { "33,4": ["3,38"] },
      { "42,2": ["3,56"] },
      { "48,3": ["3,68"] },
      { "60,3": ["3,91"] },
      { "73": ["5,16"] },
      { "88,9": ["5,49"] },
      { "101,6": ["5,74"] },
      { "114,3": ["6,02"] },
      { "141,3": ["6,55"] },
      { "168,3": ["7,11"] },
      { "219,1": ["8,18"] },
      { "273,05": ["9,27"] },
      { "323,84": ["10,31"] }
    ]
  },
  {
    key: "SCHEDULE 80", values: [
      { "21,3": ["2,77"] },
      { "26,7": ["2,87"] },
      { "33,4": ["3,38"] },
      { "42,2": ["3,56"] },
      { "48,3": ["3,68"] },
      { "60,3": ["3,91"] },
      { "73": ["5,16"] },
      { "88,9": ["5,49"] },
      { "101,6": ["5,74"] },
      { "114,3": ["6,02"] },
      { "141,3": ["6,55"] },
      { "168,3": ["7,11"] },
      { "219,1": ["8,18"] },
      { "273,05": ["9,27"] },
      { "323,84": ["10,31"] }
    ]
  },
  ];
  const tubosDoMastro = inputValues[`modulo${inputValues.moduleSelected}Tubos`] ? dimensoesTubos.find((tubo) => tubo.key === inputValues[`modulo${inputValues.moduleSelected}Tubos`]).values : [];
  const moduleDiameters = tubosDoMastro.map((obj) => Object.keys(obj)[0]);
  const [menuWidth, setMenuWidth] = useState(25); 
  const startX = useRef(null); 
  const startWidth = useRef(null);
  
  useEffect(() => {
    const newInputValues = {
      moduleSelected: moduleSelectedInMenu,
      [`modulo${moduleSelectedInMenu}Altura`]: mastProps?.[`modulo${moduleSelectedInMenu}Altura`] || "",
      [`modulo${moduleSelectedInMenu}Diametro`]: mastProps?.[`modulo${moduleSelectedInMenu}Diametro`] || mastProps?.[`modulo${moduleSelectedInMenu-1}Diametro`] || mastProps?.["modulo1Diametro"] || "",
      [`modulo${moduleSelectedInMenu}Espessura`]: mastProps?.[`modulo${moduleSelectedInMenu}Espessura`] || mastProps?.[`modulo${moduleSelectedInMenu - 1}Espessura`] || mastProps?.["modulo1Espessura"] || "",
      [`modulo${moduleSelectedInMenu}FlangeDiametro`]: mastProps?.[`modulo${moduleSelectedInMenu}FlangeDiametro`] ||  mastProps?.[`modulo${moduleSelectedInMenu - 1}FlangeDiametro`] || mastProps?.["modulo1FlangeDiametro"] || "",
      [`modulo${moduleSelectedInMenu}FlangeEspessura`]: mastProps?.[`modulo${moduleSelectedInMenu}FlangeEspessura`] || mastProps?.[`modulo${moduleSelectedInMenu - 1}FlangeEspessura`] || mastProps?.["modulo1FlangeEspessura"] || "",
      [`modulo${moduleSelectedInMenu}ParafusosFlangeDiametro`]: mastProps?.[`modulo${moduleSelectedInMenu}ParafusosFlangeDiametro`] || mastProps?.[`modulo${moduleSelectedInMenu - 1}ParafusosFlangeDiametro`] || mastProps?.["modulo1ParafusosFlangeDiametro"] || "",
      [`modulo${moduleSelectedInMenu}ParafusosFlangeNumero`]: mastProps?.[`modulo${moduleSelectedInMenu}ParafusosFlangeNumero`] || mastProps?.[`modulo${moduleSelectedInMenu - 1}ParafusosFlangeNumero`] || mastProps?.["modulo1ParafusosFlangeNumero"] || "",
      [`modulo${moduleSelectedInMenu}Tubos`]: mastProps?.[`modulo${moduleSelectedInMenu}Tubos`] || mastProps?.[`modulo${moduleSelectedInMenu-1}Tubos`] || mastProps?.["modulo1Tubos"] || "",
      [`modulo${moduleSelectedInMenu}ParafusosAco`]: mastProps?.[`modulo${moduleSelectedInMenu}ParafusosAco`] || mastProps?.[`modulo${moduleSelectedInMenu - 1}ParafusosAco`] || mastProps?.["modulo1ParafusosAco"] || "",
    };
    setInputValues(newInputValues);
  }, [moduleSelectedInMenu]);
  
  async function updateMastModuleData() {
    try {
      if(inputValues[`modulo${inputValues.moduleSelected}Altura`] > 100) return toast.error("Preencha os campos corretamente antes de salvar");
      const updatedProps = JSON.stringify({ ...mastProps,
        ...(inputValues[`modulo${inputValues.moduleSelected}Altura`] !== "" && {
          [`modulo${inputValues.moduleSelected}Altura`]: inputValues[`modulo${inputValues.moduleSelected}Altura`]
        }),
        ...(inputValues[`modulo${inputValues.moduleSelected}Diametro`] !== "" && {
          [`modulo${inputValues.moduleSelected}Diametro`]: inputValues[`modulo${inputValues.moduleSelected}Diametro`]
        }),
        ...(inputValues[`modulo${inputValues.moduleSelected}Espessura`] !== "" && {
          [`modulo${inputValues.moduleSelected}Espessura`]: inputValues[`modulo${inputValues.moduleSelected}Espessura`]
        }),
        ...(inputValues[`modulo${inputValues.moduleSelected}FlangeDiametro`] !== "" && {
          [`modulo${inputValues.moduleSelected}FlangeDiametro`]: inputValues[`modulo${inputValues.moduleSelected}FlangeDiametro`]
        }),
        ...(inputValues[`modulo${inputValues.moduleSelected}FlangeEspessura`] !== "" && {
          [`modulo${inputValues.moduleSelected}FlangeEspessura`]: inputValues[`modulo${inputValues.moduleSelected}FlangeEspessura`]
        }),
        ...(inputValues[`modulo${inputValues.moduleSelected}ParafusosFlangeDiametro`] !== "" && {
          [`modulo${inputValues.moduleSelected}ParafusosFlangeDiametro`]: inputValues[`modulo${inputValues.moduleSelected}ParafusosFlangeDiametro`]
        }),
        ...(inputValues[`modulo${inputValues.moduleSelected}ParafusosFlangeNumero`] !== "" && {
          [`modulo${inputValues.moduleSelected}ParafusosFlangeNumero`]: inputValues[`modulo${inputValues.moduleSelected}ParafusosFlangeNumero`]
        }),
        ...(inputValues[`modulo${inputValues.moduleSelected}ParafusosAco`] !== "" && {
          [`modulo${inputValues.moduleSelected}ParafusosAco`]: inputValues[`modulo${inputValues.moduleSelected}ParafusosAco`]
        }),
        ...(inputValues[`modulo${inputValues.moduleSelected}Tubos`] !== "" && {
          [`modulo${inputValues.moduleSelected}Tubos`]: inputValues[`modulo${inputValues.moduleSelected}Tubos`]
        }),
      });
      const toBeUpdatedMast = { ...mastSelected, updatedAt: new Date(), props: updatedProps };
      const updatedMast = await updateMast(toBeUpdatedMast, userData.token);
      setMastSelected(updatedMast);
      setReloadProject(!reloadProject);
      toast.success("Informações atualizadas com sucesso");
      setIsUpdatedWithoutCalculation(true);
      if (Number(inputValues.moduleSelected) !== qtdModulos) {
        setInputValues({ ...inputValues, moduleSelected: Number(moduleSelectedInMenu) + 1 });
        setModuleSelectedInMenu(Number(moduleSelectedInMenu) + 1);
      }
      setModuleSelectedBackgroundColor("#D0CECE");
      setTimeout(() => setModuleSelectedBackgroundColor("#F16E00"), 750);
    } catch (error) {
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
      if (error?.message) return toast.error(error.message);
    }
  }

  function handleDragStart(e) {
    startX.current = e.clientX;
    startWidth.current = menuWidth;
    document.addEventListener("mousemove", handleDrag);
    document.addEventListener("mouseup", handleDragEnd);
  }

  function handleDrag(e) {
    const diff = e.clientX - startX.current;
    const newWidth = startWidth.current + diff;
    if (newWidth >= 25 && newWidth <= 70) {
      setMenuWidth(newWidth);
    }
  }

  function handleDragEnd() {
    document.removeEventListener("mousemove", handleDrag);
    document.removeEventListener("mouseup", handleDragEnd);
  }
  function handleMenuClick(menuType, menuName) {
    if (menuType === "project") {
      setProjectSelected({ });
      setProjectNameSelected("");
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else if (menuType === "projectName") {
      setMastSelected({ });
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else {
      setLeftMenuOptionSelected("");
    }
  }

  return (
    <>
      <ResizableLeftContainer style={{ width: `${menuWidth}%` }}>
        <LeftTitle>Módulos</LeftTitle>
        <ReturnIcon onClick={() => {
          if (isInputModified) {
            if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("geometria");
          }
          else setLeftMenuOptionSelected("geometria");
        }}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ReturnIcon>
        <AdvanceIcon disabled={isInputModified} onClick={() => {
          if (isInputModified) {
            if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja avançar?")) setLeftMenuOptionSelected("estais");
          }
          else setLeftMenuOptionSelected("estais");
        }}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </AdvanceIcon>
        <ModuleMenuOption backgroundColor={moduleSelectedBackgroundColor}>
          <h2>Selecione o Módulo</h2>
          <select value={moduleSelectedInMenu} onChange={(e) => setModuleSelectedInMenu(e.target.value)}>
            {[...Array(qtdModulos).keys()].map((i) => (
              <option value={i + 1} key={i + 1}>
                Modulo {i + 1}
              </option>
            ))}
          </select>
        </ModuleMenuOption>
        <MenuOption>
          <h2>Altura (m)</h2>
          <input autoFocus value={inputValues[`modulo${inputValues.moduleSelected}Altura`]} type="number" placeholder={inputValues[`modulo${inputValues.moduleSelected}Altura`] ? inputValues[`modulo${inputValues.moduleSelected}Altura`] : "Informe a altura do módulo"} onChange={(e) => setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}Altura`]: e.target.value })}>
          </input>
        </MenuOption>
        <MenuOption>
          <h2>Aço Tubos</h2>
          <select onChange={(e) => setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}Tubos`]: e.target.value })}>
            {inputValues[`modulo${inputValues.moduleSelected}Tubos`] ? <option value={inputValues[`modulo${inputValues.moduleSelected}Tubos`]} >{inputValues[`modulo${inputValues.moduleSelected}Tubos`]}</option> :             <option value={""} >Selecione uma opção...</option>}
            {tiposDeAçoTubo.map((tipo) => (inputValues[`modulo${inputValues.moduleSelected}Tubos`] !== tipo && <option value={tipo} key={tipo}>{tipo}</option>))}
          </select>
        </MenuOption>
        <MenuDoubleOption>
          <div>
            <div>
              <h2>Diâmetro (mm)</h2>
              {!mastProps.tubos ? 
                <ModuleDiameterSelect inputValues={inputValues} setInputValues={setInputValues} diametersList={diametroModuloAstm}></ModuleDiameterSelect>
                :
                <ModuleDiameterSelect inputValues={inputValues} setInputValues={setInputValues} diametersList={moduleDiameters}></ModuleDiameterSelect>
              }
            </div>
            <div>
              <h2>Espessura (mm)</h2>
              <ModuleThicknessSelect inputValues={inputValues} setInputValues={setInputValues} thicknessList={updatedModuleThickness} espessurasModulo={espessurasModulo} moduleSelectedInMenu={moduleSelectedInMenu} tubosDoMastro={tubosDoMastro}></ModuleThicknessSelect>
            </div>
          </div>
        </MenuDoubleOption>
        { Number(inputValues.moduleSelected) !== qtdModulos && qtdModulos > 1 &&
          <>
            <MenuDoubleOption>
              <VerticalSpacing />
              <h4>Flange entre Módulos</h4>
              <div>
                <div>
                  <h2>Ø Externo (mm)</h2>
                  <input type="number" min={1} value={inputValues[`modulo${inputValues.moduleSelected}FlangeDiametro`]} placeholder={inputValues[`modulo${inputValues.moduleSelected}FlangeDiametro`] ? inputValues[`modulo${inputValues.moduleSelected}FlangeDiametro`] : "Informe o diâmetro externo da flange"} onChange={(e) => setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}FlangeDiametro`]: e.target.value })}>
                  </input>
                </div>
                <div>
                  <h2>Espessura (mm)</h2>
                  <input type="number" min={1} value={inputValues[`modulo${inputValues.moduleSelected}FlangeEspessura`]} placeholder={inputValues[`modulo${inputValues.moduleSelected}FlangeEspessura`] ? inputValues[`modulo${inputValues.moduleSelected}FlangeEspessura`] : "Informe a espessura da flange"} onChange={(e) => setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}FlangeEspessura`]: e.target.value })}>
                  </input>
                </div>
              </div>
            </MenuDoubleOption>
            <MenuDoubleOption>
              <h2>Parafusos Flange</h2>
              <div>
                <div>
                  <input type="number" min="1" placeholder={inputValues[`modulo${inputValues.moduleSelected}ParafusosFlangeNumero`] || "Quantidade"} value={inputValues[`modulo${inputValues.moduleSelected}ParafusosFlangeNumero`]} onChange={(e) => setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}ParafusosFlangeNumero`]: e.target.value })}>
                  </input>
                </div>
                <ScrewDiameterSelect inputValues={inputValues} setInputValues={setInputValues} diametersList={parafusosFlange}/>
              </div>
            </MenuDoubleOption>
            <MenuOption>
              <h2>Aço Parafusos</h2>
              <select onChange={(e) => setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}ParafusosAco`]: e.target.value })}>
                {inputValues[`modulo${inputValues.moduleSelected}ParafusosAco`] ? <option value={inputValues[`modulo${inputValues.moduleSelected}ParafusosAco`]} >{inputValues[`modulo${inputValues.moduleSelected}ParafusosAco`]}</option> :             <option value={""} >Selecione uma opção...</option>}
                {tiposDeAçoParafusos.map((tipo) => (inputValues[`modulo${inputValues.moduleSelected}ParafusosAco`] !== tipo && <option value={tipo} key={tipo}>{tipo}</option>))}
              </select>
            </MenuOption>
          </>
        }
        <ButtonPositioner >
          <ReturnButton onClick={() => {
            if (isInputModified) {
              if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("");
            }
            else setLeftMenuOptionSelected("");
          }} >{" Voltar "}</ReturnButton>
          {updateMastLoading ?           
            <LoadingButton>{ "Carregando" }</LoadingButton> :
            <SavingButton disabled={!isInputModified} onClick={() => { if(isInputModified) { updateMastModuleData(); }; }}>{ "Salvar" }</SavingButton>}
        </ButtonPositioner>
      </ResizableLeftContainer>
      <DragBar onMouseDown={handleDragStart} />
      <ResizableRightContainer>
        <RigthModulePageContainer>
          <ProjectNameWrapper>
            <h2><span onClick={() => handleMenuClick("project")}>Projetos</span> / <span onClick={() => handleMenuClick("projectName")}>{projectSelected?.nomeProjeto}</span> <span onClick={() => handleMenuClick("")}>{mastSelected?.identificador ? ` / ${mastSelected?.identificador}` : ""}</span></h2>
            <h1><span onClick={() => handleMenuClick("")}>{mastSelected?.identificador ? `${mastSelected?.identificador}` : projectSelected?.nomeProjeto}</span></h1>
          </ProjectNameWrapper>
          <ModuleRightContainer mastProps={mastProps} inputValues={inputValues}/>
        </RigthModulePageContainer>
      </ResizableRightContainer>
    </>
  );
}

function ScrewDiameterSelect({ inputValues, setInputValues, diametersList }) {
  const handleDiameterChange = (selectedOption) => {
    if (selectedOption) {
      const selectedDiameter = diametersList.find((diameter) => diameter === selectedOption.value);
      if (selectedDiameter) {
        setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}ParafusosFlangeDiametro`]: selectedDiameter });
      }
      else setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}ParafusosFlangeDiametro`]: selectedOption.value });
    } else {
      setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}ParafusosFlangeDiametro`]: "" });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      backgroundColor: "#ffffff",
      border: "1px solid #C8C8C8",
      height: "4.5vh",
      minHeight: "4.5vh",
      borderRadius: "8px",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      color: "#000",
      marginBottom: "-10px"
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      marginBottom: "5px",
      width: "100%",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
      marginBottom: "5px",
      position: "absolute",
      right: "-10px",
      size: 2
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
      paddingBottom: "0.5vh", 
    }),
    input: (provided) => ({
      ...provided,
      marginTop: "-0.5vh", 
      width: "100%",
      color: "#000",
    }),
    clearIndicator: (provided) => ({
      display: "none"
    }),
    singleValue: (provided) => ({
      ...provided,
      paddingBottom: "0.5vh", 
      color: "#000",
    }),
  };

  return (
    <StyledSelect
      options={diametersList.map((diameter) => ({ value: diameter, label: diameter }))}
      styles={customStyles}
      value={inputValues[`modulo${inputValues.moduleSelected}ParafusosFlangeDiametro`] ? { value: inputValues[`modulo${inputValues.moduleSelected}ParafusosFlangeDiametro`], label: inputValues[`modulo${inputValues.moduleSelected}ParafusosFlangeDiametro`] } : ""}
      onChange={handleDiameterChange}
      placeholder={inputValues[`modulo${inputValues.moduleSelected}ParafusosFlangeDiametro`] ? inputValues[`modulo${inputValues.moduleSelected}ParafusosFlangeDiametro`] : "Diâmetro"}
      isClearable
      isSearchable
      menuPlacement="auto"
    />
  );
}

function ModuleDiameterSelect({ inputValues, setInputValues, diametersList }) {
  const handleDiameterChange = (selectedOption) => {
    if (selectedOption) {
      const selectedDiameter = diametersList.find((diameter) => diameter === selectedOption.value);
      if (selectedDiameter) {
        setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}Diametro`]: selectedDiameter });
      }
      else setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}Diametro`]: selectedOption.value });
    } else {
      setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}Diametro`]: "" });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      border: "1px solid #C8C8C8",
      height: "4.5vh",
      minHeight: "4.5vh",
      borderRadius: "8px",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      color: "#000",
      marginBottom: "-10px"
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      marginBottom: "5px",
      width: "100%",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
      marginBottom: "5px",
      position: "absolute",
      right: "-10px",
      size: 2
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
      paddingBottom: "0.5vh", 
    }),
    input: (provided) => ({
      ...provided,
      marginTop: "-0.5vh", 
      width: "100%",
      color: "#000",
    }),
    clearIndicator: (provided) => ({
      display: "none"
    }),
    singleValue: (provided) => ({
      ...provided,
      paddingBottom: "0.5vh", 
      color: "#000",
    }),
  };

  return (
    <StyledSelect
      options={diametersList.map((diameter) => ({ value: diameter, label: diameter }))}
      styles={customStyles}
      value={inputValues[`modulo${inputValues.moduleSelected}Diametro`] ? { value: inputValues[`modulo${inputValues.moduleSelected}Diametro`], label: inputValues[`modulo${inputValues.moduleSelected}Diametro`] } : ""}
      onChange={handleDiameterChange}
      placeholder={inputValues[`modulo${inputValues.moduleSelected}Diametro`] ? inputValues[`modulo${inputValues.moduleSelected}Diametro`] : "Diâmetro"}
      isClearable
      isSearchable
      menuPlacement="auto"
    />
  );
}

function ModuleThicknessSelect({ inputValues, setInputValues, moduleSelectedInMenu, espessurasModulo, tubosDoMastro }) {
  const selectedModuleDiameter = inputValues[`modulo${moduleSelectedInMenu}Diametro`];
  let thicknessList = tubosDoMastro.find((obj) => Object.keys(obj)[0] === selectedModuleDiameter) || espessurasModulo;
  if (thicknessList !== espessurasModulo) thicknessList = Object.values(thicknessList)[0];
  const handleThicknessChange = (selectedOption) => {
    if (selectedOption) {
      const selectedThickness = thicknessList.find((thicknes) => thicknes === selectedOption.value);
      if (selectedThickness) {
        setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}Espessura`]: selectedThickness });
      }
      else setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}Espessura`]: selectedOption.value });
    } else {
      setInputValues({ ...inputValues, [`modulo${inputValues.moduleSelected}Espessura`]: "" });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "100%",
      backgroundColor: "#ffffff",
      border: "1px solid #C8C8C8",
      height: "4.5vh",
      minHeight: "4.5vh",
      borderRadius: "8px",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      color: "#000",
      marginBottom: "-10px"
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      marginBottom: "5px",
      width: "100%",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000",
      marginBottom: "5px",
      position: "absolute",
      right: "-10px",
      size: 2
    }),
    option: (provided) => ({
      ...provided,
      color: "#000",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
      paddingBottom: "0.5vh", 
    }),
    input: (provided) => ({
      ...provided,
      marginTop: "-0.5vh", 
      width: "100%",
      color: "#000",
    }),
    clearIndicator: (provided) => ({
      display: "none"
    }),
    singleValue: (provided) => ({
      ...provided,
      paddingBottom: "0.5vh", 
      color: "#000",
    }),
  };

  return (
    <StyledSelect
      options={thicknessList.map((thickness) => ({ value: thickness, label: thickness }))}
      styles={customStyles}
      value={inputValues[`modulo${inputValues.moduleSelected}Espessura`] ? { value: inputValues[`modulo${inputValues.moduleSelected}Espessura`], label: inputValues[`modulo${inputValues.moduleSelected}Espessura`] } : ""}
      onChange={handleThicknessChange}
      placeholder={inputValues[`modulo${inputValues.moduleSelected}Espessura`] ? inputValues[`modulo${inputValues.moduleSelected}Espessura`] : "Espessura"}
      isClearable
      isSearchable
      menuPlacement="auto"
    />
  );
}

const StyledSelect = styled(CreatableSelect)`
  .react-select__control {
    width: 100%;
    background-color: #ffffff;    
    border: 1px solid #C8C8C8;
    height: 4.5vh;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 26px;
    color: #000;
  }
  .react-select__placeholder {
    color: #000;
  }

  .react-select__dropdown-indicator {
    color: #000;
  }

  .react-select__option {
    color: #000;
  }
`;

export const MenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 0.8vh 0;
  position: relative;

  h2 {
    font-weight: 400;
    font-size: 16px;
    color: #313131;
    margin-bottom: 0.8vh;
  }

  input,select {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4.25vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }

  ion-icon {
    position: absolute;
    top: 0;
    right: 0;
    color: #ffffff;
  }

  >div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  >div>div{
    width: 46%;
  }

  h3 {
    font-size: 15px;
    color: #565656;
    font-weight: 300;
    margin-bottom: 1vh;
  }
`;

export const MenuDoubleOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1vh 0;
  position: relative;

  h2 {
    font-weight: 300;
    font-size: 16px;
    color: #313131;
    margin-bottom: 0.8vh;
  }

  input,select {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4.5vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }

  ion-icon {
    position: absolute;
    top: 0;
    right: 0;
    color: #ffffff;
  }

  >div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  >div>div{
    width: 46%;
  }

  h4 {
    margin-bottom: 6px;
  }
`;

const DragBar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 10px;
  cursor: ew-resize;
  z-index: 1;
`;

const ModuleMenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1vh 0;
  position: relative;

  h2 {
    font-weight: 300;
    font-size: 16px;
    color: #313131;
    margin-bottom: 0.8vh;
  }

  input,select {
    width: 100%;
    background-color: ${(props) => (props.backgroundColor)};
    height: 4.5vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }
`;

const VerticalSpacing = styled.div`
  margin-top: 0.8vh;
`;

const RigthModulePageContainer = styled.div`
  display: flex;
  margin-left: 30px;
`;

export const ProjectNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4vh;
  position: absolute;
  left: 30px;
  top: 0px;

  h2 {
    color: #666666;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  h1 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
  }
`;
