import styled from "styled-components";
import { useContext, } from "react";
import { MenuContext } from "../../contexts/MenuContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import gray_arrow_icon from "../../assets/images/gray-arrow-icon.svg";

export default function TowerInitialRightContainer() {
  const { leftMenuWidth } = useContext(MenuContext);
  const { 
    projectSelected, 
    setProjectSelected, 
    setProjectNameSelected, 
    setLeftMenuOptionSelected, 
    reloadProject, 
    setReloadProject, 
  } = useContext(ProjectContext);

  function handleMenuClick(menuType) {
    if (menuType === "project") {
      setProjectSelected({});
      setProjectNameSelected("");
      setReloadProject(!reloadProject);
    }
    else if (menuType === "projectName") {
      setReloadProject(!reloadProject);
    }
    else {
      setLeftMenuOptionSelected("");
    }
  }

  return (
    <Container leftMenuWidth={leftMenuWidth}>
      <ProjectNameWrapper>
        <h2>
          <span onClick={() => handleMenuClick("project")}>Projetos</span> /
          <span onClick={() => handleMenuClick("projectName")}>{projectSelected?.nomeProjeto}</span>
        </h2>
        <h1><span onClick={() => handleMenuClick("")}>{projectSelected?.nomeProjeto}</span></h1>
      </ProjectNameWrapper>

      <ProjectStepsContainer>
        <h1>Para elaborar o seu projeto siga a seguinte sequência de telas e definições</h1>
        <div>
          <ProjectStep isInStage={true}>
            <div>1</div>
            <h2>Dados Gerais</h2>
            <h3>Preencha os dados gerais, referêntes a geometria da torre</h3>
          </ProjectStep>
          <Arrow>
            <img src={gray_arrow_icon} alt="Gray Arrow pointing right" />
          </Arrow>
          
          <ProjectStep isInStage={false}>
            <div>2</div>
            <h2>Parâmetros de Vento</h2>
            <h3>Configure os parâmetros de vento conforme a região do país</h3>
          </ProjectStep>
          <Arrow>
            <img src={gray_arrow_icon} alt="Gray Arrow pointing right" />
          </Arrow>

          <ProjectStep isInStage={false}>
            <div>3</div>
            <h2>Carregamento de Antenas</h2>
            <h3>Indique o carregamento de antenas e equipamentos que será considerado</h3>
          </ProjectStep>
          <Arrow>
            <img src={gray_arrow_icon} alt="Gray Arrow pointing right" />
          </Arrow>

          <ProjectStep isInStage={false}>
            <div>4</div>
            <h2>Combinações</h2>
            <h3>Defina as combinações de cargas que serão consideradas no cálculo</h3>
          </ProjectStep>
          <Arrow>
            <img src={gray_arrow_icon} alt="Gray Arrow pointing right" />
          </Arrow>

          <ProjectStep isInStage={false}>
            <div>5</div>
            <h2>Informações de Geometria</h2>
            <h3>Insira todas as informações dos perfis e fixações</h3>
          </ProjectStep>
          <Arrow>
            <img src={gray_arrow_icon} alt="Gray Arrow pointing right" />
          </Arrow>

          <ProjectStep isInStage={false}>
            <div>6</div>
            <h2>Calcule</h2>
            <h3>Calcule e veja os resultados do seu projeto</h3>
          </ProjectStep>
        </div>
      </ProjectStepsContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  min-width: 1250px;
  height: 100%;
  background-color: #FFFFFF;
  font-family: "Roboto";
 `;
 
const ProjectNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0 24px 18px;
  gap: 10px;

  h2 {
    color: #666666;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.15px;
  }

  h1 {
    color: #000;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.15px;
  }
  
  span {
    transition: 0.0001s all;
    cursor: pointer;
    :hover {
      font-weight: 600 !important;
    }
  }
`;

const ProjectStepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 260px;
  padding: 30px;
  gap: 30px;
  background-color: #F6F6F6;

  h1 {
    font-size: 20px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.15px;
  }

  div {
    display: flex;
    justify-content: flex-start;
  }
`;

const ProjectStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 150px;
  text-align: center;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: ${(props) => (props.isInStage ? "#FE8F03" : "#E1E5E6")};
    color: ${(props) => (props.isInStage ? "#FFFFFF" : "#B6C0C1")};
    margin-bottom: 10px;
  }

  h2 {
    color: #292929;
    font-size: 12px;
    margin-bottom: 5px;
    line-height: 15px;
  }
  
  h3 {
    color: #939393;
    font-size: 12px;
    line-height: 17px;
    line-height: 16px;
  }
`;

const Arrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  img {
    size: 30px;
  }
`;
