import React from "react";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import styled from "styled-components";
import colors from "../../constants/color-constants";

export default function AutocompleteInput({
  inputValue,
  handleInputChange,
  list,
  field,
  placeholder,
  label = "",
  disabled = false,
  isCreatable = false
}) {
  const handleDiameterChange = (selectedOption) => {
    if (selectedOption) {
      const selectedValue = list.find((value) => value === selectedOption.value);
      if (selectedValue) {
        handleInputChange(field, selectedValue);
      }
      else handleInputChange(field, selectedOption.value);
    } else {
      handleInputChange(field, "");
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "40px",
      padding: "0",
      margin: "0",
      outline: "none",
      boxShadow: state.isFocused ? "none" : "none",
      border: state.isFocused ? `1px solid ${colors.kcBorderGray}` : `1px solid ${colors.kcBorderGray}`,
      borderRadius: "6px",
      backgroundColor: colors.kcWhiteColor,
      color: colors.kcTextColor,
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "24px",
      fontFamily: "Roboto, sans-serif",
      "&:hover": {
        border: `1px solid ${colors.kcBorderGray}`,
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      color: colors.kcTextColor,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: colors.kcTextColor,
      width: "27px",
    }),
    option: (provided) => ({
      ...provided,
      width: "100%",
      height: "30px",
      borderRadius: "6px",
      backgroundColor: colors.kcWhiteColor,
      color: colors.kcTextColor,
      fontSize: "12px",
      fontWeight: "500",
      lineHeight: "24px",
      fontFamily: "Roboto, sans-serif",
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
      color: colors.kcTextColor,
    }),
    input: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "30px",
      color: colors.kcTextColor,
    }),
    clearIndicator: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      height: "30px",
      paddingLeft: "2px",
      color: colors.kcTextColor,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontFamily: "Roboto, sans-serif",
      fontSize: "14px",
      fontWeight: "400",
      color: colors.kcDarkGray,
    }),
  };

  return (
    <Container>
      {label && <StyledLabel>{label}</StyledLabel>}
      {isCreatable
        ? <CreatableSelect
          isDisabled={disabled}
          options={list.map((option) => ({ value: option, label: option }))}
          styles={customStyles}
          value={inputValue ? { value: inputValue, label: inputValue } : ""}
          onChange={handleDiameterChange}
          placeholder={placeholder}
          isClearable
          isSearchable
          menuPlacement="auto"
        />
        : <Select
          isDisabled={disabled}
          options={list.map((option) => ({ value: option, label: option }))}
          styles={customStyles}
          value={inputValue ? { value: inputValue, label: inputValue } : ""}
          onChange={handleDiameterChange}
          placeholder={placeholder}
          isClearable
          isSearchable
          menuPlacement="auto"
          noOptionsMessage={() => "Nenhuma opção encontrada"}
        />
      }
    </Container>
  );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const StyledLabel = styled.label`
    color: #313131;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
`;
