import api from "./api";

export async function signIn({ email, password }) {
  const response = await api.post("/users/signin", { email, password });
  return response.data;
}

export async function signUp({ email, name, password, validationNumber }) {
  const response = await api.post("/users/signup", {
    email,
    name,
    password,
    validationNumber,
  });
  return response.data;
}

export async function signInToken(userId, token) {
  const response = await api.post("/users/signinToken", { userId, token });
  return response.data;
}

export async function confirmEmail(email, confirmationType) {
  const response = await api.post("/users/confirmEmail", {
    email,
    confirmationType,
  });
  return response.data;
}

export async function updatePassword({
  email,
  password,
  confirmPassword,
  validationNumber,
}) {
  const response = await api.put("/users/updatePassword", {
    email,
    password,
    confirmPassword,
    validationNumber,
  });
  return response.data;
}

export async function updatePasswordWithoutValidationCode(token, password) {
  const response = await api.put("/users/updatePasswordWithoutValidationCode", { password }, {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
}

export async function postLogout(token) {
  const response = await api.post("/users/logout", { token: token }, {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};

export async function getUserInfo(token) {
  const response = await api.get("/users/userInfo", {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};

export async function updateUserInfo(token, data) {
  const response = await api.put("/users/userInfo", data, {
    headers: {
      Authorization: `Bearer ${token}`,
    } });
  return response.data;
};
