import { useContext } from "react";
import styled from "styled-components";
import { ProjectContext } from "../../contexts/ProjectContext";

export default function RightContainer({ children }) {
  const {
    setLeftMenuOptionSelected,
    projectSelected,
    reloadProject,
    setReloadProject,
    setProjectNameSelected,
    setProjectSelected,
  } = useContext(ProjectContext);

  function handleMenuClick(menuType, menuName) {
    if (menuType === "project") {
      setProjectSelected({});
      setProjectNameSelected("");
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else if (menuType === "projectName") {
      setReloadProject(!reloadProject);
      setLeftMenuOptionSelected("");
    }
    else {
      setLeftMenuOptionSelected("");
    }
  }

  return (
    <Container>
      <h2>
        <span onClick={() => handleMenuClick("project")}>Projetos</span> /
        <span onClick={() => handleMenuClick("projectName")}>{projectSelected?.nomeProjeto}</span>
      </h2>
      <h1><span onClick={() => handleMenuClick("")}>{projectSelected?.nomeProjeto}</span></h1>
      <ChildrenContainer>
        {children}
      </ChildrenContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px;
  background-color: #FFFFFF;

  >h2 {
    color: #666666;
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  >h1 {
    color: #000;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 30px;
  }
    span {
    transition: 0.0001s all;
    cursor: pointer;
    :hover {
      font-weight: 600 !important;
    }
  }
`;

const ChildrenContainer = styled.div`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 110px);
`;
