import styled from "styled-components";
import { useContext } from "react";
import { MenuContext } from "../../../contexts/MenuContext";
import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";
import { CustomSelect } from "../../common/custom-select";
import AutocompleteInput from "../../common/customAutoCompleteInput";
import { profileTypes, tubeProfileData, boltsDiameter, boltsSteel } from "../../../constants/tower-constants";
import { handleProfileSteelTypes, handleProfileDimensions } from "../../../utils/tower_handleProfilesData";
import colors from "../../../constants/color-constants";

export default function TowerSuperiorHorizontalContainer({
  segmentSelectedInMenu,
  inputValues,
  handleSuperiorHorizontalChange,
}) {
  const { leftMenuWidth } = useContext(MenuContext);
  const currentSuperiorHorizontal = inputValues[segmentSelectedInMenu - 1]?.superiorHorizontalData;

  return (
    <>
      <Container width={leftMenuWidth}>
        <h2 className="title">Horizontal Superior</h2>
        <InputMenu>
          <h2>Tipo de Perfil</h2>
          <select
            value={currentSuperiorHorizontal?.profileType ?? ""}
            onChange={(e) => handleSuperiorHorizontalChange("profileType", e.target.value)}
          >
            <option value="" key={"profileType-empty"}>Selecione o tipo de perfil</option>
            {profileTypes.map((type) => (
              <option value={type} key={type}>{type}</option>
            ))}
          </select>
        </InputMenu>

        <InputMenu>
          <h2>Aço do Perfil</h2>
          <select
            value={currentSuperiorHorizontal?.profileSteel ?? ""}
            onChange={(e) => handleSuperiorHorizontalChange("profileSteel", e.target.value)}
          >
            <option value="" key={"profileSteel-empty"}>Selecione o aço do perfil</option>
            {currentSuperiorHorizontal?.profileType &&
              handleProfileSteelTypes(currentSuperiorHorizontal?.profileType)?.map((type) => (
                <option value={type} key={type}>{type}</option>
              ))}
          </select>
        </InputMenu>

        {(currentSuperiorHorizontal?.profileType && currentSuperiorHorizontal?.profileSteel) &&
          <InputMenu>
            <h2>Dimensões do Perfil</h2>
            {currentSuperiorHorizontal?.profileType === "L - Cantoneira" &&
              <AutocompleteInput
                inputValue={currentSuperiorHorizontal?.profileDimensions || ""}
                handleInputChange={(field, value) => handleSuperiorHorizontalChange(field, value)}
                list={handleProfileDimensions(currentSuperiorHorizontal?.profileType) || []}
                field={"profileDimensions"}
                placeholder={"Selecione as dimensões do perfil"}
              />
            }
            {currentSuperiorHorizontal?.profileType === "T - Tubo Vazado" &&
              <div>
                <AutocompleteInput
                  inputValue={currentSuperiorHorizontal?.profileDiameter || ""}
                  handleInputChange={(field, value) => handleSuperiorHorizontalChange(field, value)}
                  list={currentSuperiorHorizontal?.profileSteel
                    ? handleProfileDimensions(currentSuperiorHorizontal.profileType, currentSuperiorHorizontal?.profileSteel) : []}
                  field={"profileDiameter"}
                  placeholder={"Diâmetro"}
                  label={"Diâmetro"}
                />

                <AutocompleteInput
                  inputValue={currentSuperiorHorizontal?.profileThickness || ""}
                  handleInputChange={(field, value) => handleSuperiorHorizontalChange(field, value)}
                  list={currentSuperiorHorizontal?.profileDiameter
                    ? tubeProfileData[currentSuperiorHorizontal?.profileSteel][currentSuperiorHorizontal?.profileDiameter]
                    : []}
                  field={"profileThickness"}
                  placeholder={"Espessura"}
                  label={"Espessura"}
                />
              </div>
            }
            {
              currentSuperiorHorizontal?.profileType === "BR - Barra Maciça" &&
              <AutocompleteInput
                inputValue={currentSuperiorHorizontal?.profileDiameter || ""}
                handleInputChange={(field, value) => handleSuperiorHorizontalChange(field, value)}
                list={handleProfileDimensions(currentSuperiorHorizontal.profileType) || []}
                field={"profileDiameter"}
                placeholder={"Diâmetro"}
              />
            }
            {
              currentSuperiorHorizontal?.profileType === "U - Perfil U" &&
              <>
                <div>
                  <CustomInput
                    placeholder={"Aba (mm)"}
                    label={"Aba"}
                    value={currentSuperiorHorizontal?.profileFlange ?? ""}
                    onChange={(newValue) => handleSuperiorHorizontalChange("profileFlange", newValue)}
                    rawNumber={true}
                    unit={"mm"}
                  />

                  <CustomInput
                    placeholder={"Alma (mm)"}
                    label={"Alma"}
                    value={currentSuperiorHorizontal?.profileWeb ?? ""}
                    onChange={(newValue) => handleSuperiorHorizontalChange("profileWeb", newValue)}
                    rawNumber={true}
                    unit={"mm"}
                  />
                </div>
                <div>
                  <AutocompleteInput
                    inputValue={currentSuperiorHorizontal?.profileThickness || ""}
                    handleInputChange={(field, value) => handleSuperiorHorizontalChange(field, value)}
                    list={handleProfileDimensions(currentSuperiorHorizontal.profileType) || []}
                    field={"profileThickness"}
                    placeholder={"Espessura"}
                    label={"Espessura"}
                  />
                </div>
              </>
            }
          </InputMenu>
        }

        <InputMenu>
          <h2>Aço dos Parafusos</h2>
          <select
            value={currentSuperiorHorizontal?.boltsSteel ?? ""}
            onChange={(e) => handleSuperiorHorizontalChange("boltsSteel", e.target.value)}
          >
            <option value="" key={"boltsSteel-empty"}>Selecione o aço dos parafusos</option>
            {boltsSteel.map((type) => (
              <option value={type} key={type}>{type}</option>
            ))}
          </select>
        </InputMenu>

        <InputMenu>
          <h2>Parafusos de Conexão</h2>
          <div>
            <CustomInput
              placeholder={"Quantidade"}
              label={"Quantidade"}
              value={currentSuperiorHorizontal?.numConnectionBolts ?? ""}
              onChange={(newValue) => handleSuperiorHorizontalChange("numConnectionBolts", newValue)}
              toFixed={0}
            />

            <CustomSelect
              value={currentSuperiorHorizontal?.diameterConnectionBolts ?? ""}
              label={"Diâmetro"}
              onChange={(e) => handleSuperiorHorizontalChange("diameterConnectionBolts", e)}
            >
              <option value="" key={"diameterConnectionBolts-empty"}>Diâmetro</option>
              {boltsDiameter.map((type) => (
                <option value={type} key={type}>{type}</option>
              ))}
            </CustomSelect>
          </div>
        </InputMenu>

        {(currentSuperiorHorizontal?.profileType && currentSuperiorHorizontal?.profileSteel) &&
          <InputMenu>
            <h2>Dimensões do Perfil Reforço</h2>
            {currentSuperiorHorizontal?.profileType === "L - Cantoneira" &&
              <AutocompleteInput
                inputValue={currentSuperiorHorizontal?.reinforcementDimensions || ""}
                handleInputChange={(field, value) => handleSuperiorHorizontalChange(field, value)}
                list={["Não Há Reforço", ...handleProfileDimensions(currentSuperiorHorizontal?.profileType)] || []}
                field={"reinforcementDimensions"}
                placeholder={"Dimensões"}
              />
            }
            {currentSuperiorHorizontal?.profileType === "T - Tubo Vazado" &&
              <div>
                <AutocompleteInput
                  inputValue={currentSuperiorHorizontal?.reinforcementDiameter || ""}
                  handleInputChange={(field, value) => handleSuperiorHorizontalChange(field, value)}
                  list={["Não Há Reforço", ...handleProfileDimensions(currentSuperiorHorizontal.profileType,
                    currentSuperiorHorizontal?.profileSteel)] || []}
                  field={"reinforcementDiameter"}
                  placeholder={"Diâmetro"}
                  label={"Diâmetro"}
                />

                <AutocompleteInput
                  inputValue={currentSuperiorHorizontal?.reinforcementThickness || ""}
                  handleInputChange={(field, value) => handleSuperiorHorizontalChange(field, value)}
                  list={(currentSuperiorHorizontal?.reinforcementDiameter
                    && currentSuperiorHorizontal?.reinforcementDiameter !== "Não Há Reforço")
                    ? ["Não Há Reforço", ...tubeProfileData[currentSuperiorHorizontal?.profileSteel]
                      [currentSuperiorHorizontal?.reinforcementDiameter]]
                    : []}
                  field={"reinforcementThickness"}
                  placeholder={"Espessura"}
                  label={"Espessura"}
                />
              </div>
            }
            {
              currentSuperiorHorizontal?.profileType === "BR - Barra Maciça" &&
              <AutocompleteInput
                inputValue={currentSuperiorHorizontal?.reinforcementDiameter || ""}
                handleInputChange={(field, value) => handleSuperiorHorizontalChange(field, value)}
                list={["Não Há Reforço", ...handleProfileDimensions(currentSuperiorHorizontal.profileType)]}
                field={"reinforcementDiameter"}
                placeholder={"Diâmetro"}
              />
            }
            {
              currentSuperiorHorizontal?.profileType === "U - Perfil U" &&
              <>
                <div>
                  <CustomInput
                    disabled={currentSuperiorHorizontal.reinforcementThickness === "Não Há Reforço"}
                    placeholder={"Aba (mm)"}
                    label={"Aba (mm)"}
                    value={currentSuperiorHorizontal?.reinforcementFlange ?? ""}
                    onChange={(newValue) => handleSuperiorHorizontalChange("reinforcementFlange", newValue)}
                    rawNumber={true}
                    unit={"mm"}
                  />

                  <CustomInput
                    disabled={currentSuperiorHorizontal.reinforcementThickness === "Não Há Reforço"}
                    placeholder={"Alma (mm)"}
                    label={"Alma"}
                    value={currentSuperiorHorizontal?.reinforcementWeb ?? ""}
                    onChange={(newValue) => handleSuperiorHorizontalChange("reinforcementWeb", newValue)}
                    rawNumber={true}
                    unit={"mm"}
                  />
                </div>
                <div>
                  <AutocompleteInput
                    inputValue={currentSuperiorHorizontal?.reinforcementThickness || ""}
                    handleInputChange={(field, value) => handleSuperiorHorizontalChange(field, value)}
                    list={["Não Há Reforço", ...handleProfileDimensions(currentSuperiorHorizontal.profileType)] || []}
                    field={"reinforcementThickness"}
                    placeholder={"Espessura"}
                    label={"Espessura"}
                  />
                </div>
              </>
            }
          </InputMenu>
        }
      </Container>
    </>
  );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: ${(props) => props.leftMenuWidth};
    min-width: 320px;
    max-width: 380px;
    height: min-content;
    padding: 18px 30px 18px;
    background-color: ${colors.kcLightGray};
    color: ${colors.kcTextColor};
    font-family: 'Roboto', sans-serif;

    h2 {
        color: ${colors.kcTextColor};
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        font-family: 'Roboto', sans-serif;
    }

    .title {
        margin-bottom: 12px;
    }
`;
