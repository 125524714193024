import styled from "styled-components";
import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";
import { CustomSelect } from "../../common/custom-select";
import AutocompleteInput from "../../common/customAutoCompleteInput";
import CustomCheckbox from "../../common/custom-checkbox";
import Accordion from "../../common/accordion-input";
import { profileTypes, tubeProfileData, boltsDiameter, boltsSteel } from "../../../constants/tower-constants";
import { handleProfileSteelTypes, handleProfileDimensions } from "../../../utils/tower_handleProfilesData";
import colors from "../../../constants/color-constants";

export default function RedundantLeftMenu({
  nSegments,
  nRedundants,
  inputValues,
  segmentSelectedInMenu,
  setSegmentSelectedInMenu,
  redundantSelectedInMenu,
  setRedundantSelectedInMenu,
  handleInputChange,
  setEqualSegment,
  setEqualRedundant,
  checkedSegments,
  checkedRedundants,
  countSegmentsRedundants,
}) {
  const currentSegmentIndex = segmentSelectedInMenu === ""
    ? segmentSelectedInMenu : Number(segmentSelectedInMenu - 1);
  const currentRedundantIndex = redundantSelectedInMenu === ""
    ? redundantSelectedInMenu : Number(redundantSelectedInMenu - 1);
  
  const currentRedundant = currentSegmentIndex !== "" && currentRedundantIndex !== ""
    ? inputValues[currentSegmentIndex]?.redundants?.[currentRedundantIndex] : null;

  function handleChangeSegment(value) {
    if (value !== "" && countSegmentsRedundants(value - 1) > 0) {
      setSegmentSelectedInMenu(value);
      setRedundantSelectedInMenu(1);
    } else {
      setRedundantSelectedInMenu("");
      setSegmentSelectedInMenu(value);
    }
  }

  return (
    <>
      <InputMenu>
        <div>
          <div>
            <h2>Trecho Atual</h2>
            <select
              className="highlightColor"
              value={segmentSelectedInMenu}
              onChange={(e) => handleChangeSegment(e.target.value)}>
              <option value="" key={"segment-empty"}>Selecione um trecho</option>
              {[...Array(nSegments).keys()].map((i) => (
                <option value={i + 1} key={`segment-${i + 1}`}>
                  Trecho {i + 1}
                </option>
              ))}
            </select>
          </div>

          <div>
            <h2>Redundante</h2>
            <select 
              className={segmentSelectedInMenu === "" || nRedundants[segmentSelectedInMenu - 1] === 0 
                ? null : "highlightColor"}
              disabled={segmentSelectedInMenu === "" || nRedundants[segmentSelectedInMenu - 1] === 0}
              value={redundantSelectedInMenu}
              onChange={(e) => setRedundantSelectedInMenu(
                isNaN(Number(e.target.value)) ? Number(e.target.value) : e.target.value)}>
              <option value="" key={"redundant-empty"}>Redundante</option>
              {nRedundants[segmentSelectedInMenu - 1] !== 0 &&
                [...Array(nRedundants[segmentSelectedInMenu - 1]).keys()].map((i) => (
                  <option value={i + 1} key={`redundant-${i + 1}`}>
                    R{i + 1}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </InputMenu>

      {
        nRedundants[segmentSelectedInMenu - 1] === 0 
          ? <MessageNoRedundants>
            <p>{"Não há redundantes nesse trecho."}</p>
          </MessageNoRedundants>
          : <>
            <InputMenu>
              <h2>Tipo de Perfil</h2>
              <select
                disabled={segmentSelectedInMenu === "" || redundantSelectedInMenu === ""}
                value={segmentSelectedInMenu === "" || redundantSelectedInMenu === "" ? "" : 
                  currentRedundant?.profileType ?? ""}
                onChange={(e) => handleInputChange("profileType", e.target.value)}
              >
                <option value="" key={"profileType-empty"}>Selecione o tipo de perfil</option>
                {profileTypes.map((type) => (
                  <option value={type} key={type}>{type}</option>
                ))}
              </select>
            </InputMenu>

            <InputMenu>
              <h2>Aço do Perfil</h2>
              <select
                disabled={segmentSelectedInMenu === "" || redundantSelectedInMenu === ""}
                value={segmentSelectedInMenu === "" || redundantSelectedInMenu === "" ? "" : 
                  currentRedundant?.profileSteel ?? ""}
                onChange={(e) => handleInputChange("profileSteel", e.target.value)}
              >
                <option value="" key={"profileSteel-empty"}>Selecione o aço do perfil</option>
                {currentRedundant?.profileType && 
                  handleProfileSteelTypes(currentRedundant?.profileType)?.map((type) => (
                    <option value={type} key={type}>{type}</option>
                  ))}
              </select>
            </InputMenu>

            {(segmentSelectedInMenu !== "" && redundantSelectedInMenu !== "" && currentRedundant?.profileSteel) && 
              <InputMenu>
                <h2>Dimensões do Perfil</h2>
                {
                  currentRedundant?.profileType === "L - Cantoneira" &&
                  <AutocompleteInput
                    inputValue={currentRedundant?.profileDimensions || ""}
                    handleInputChange={(field, value) => handleInputChange(field, value)}
                    list={handleProfileDimensions(currentRedundant?.profileType) || []}
                    field={"profileDimensions"}
                    placeholder={"Selecione as dimensões do perfil"}
                  />
                }
                {
                  currentRedundant?.profileType === "T - Tubo Vazado" &&
                  <div>
                    <AutocompleteInput
                      inputValue={currentRedundant?.profileDiameter || ""}
                      handleInputChange={(field, value) => handleInputChange(field, value)}
                      list={currentRedundant?.profileSteel
                        ? handleProfileDimensions(currentRedundant.profileType, currentRedundant?.profileSteel) : []}
                      field={"profileDiameter"}
                      placeholder={"Diâmetro"}
                      label={"Diâmetro"}
                    />

                    <AutocompleteInput
                      inputValue={currentRedundant?.profileThickness || ""}
                      handleInputChange={(field, value) => handleInputChange(field, value)}
                      list={currentRedundant?.profileDiameter
                        ? tubeProfileData[currentRedundant?.profileSteel][currentRedundant?.profileDiameter] : []}
                      field={"profileThickness"}
                      placeholder={"Espessura"}
                      label={"Espessura"}
                    />
                  </div>
                }
                {
                  currentRedundant?.profileType === "BR - Barra Maciça" &&
                  <AutocompleteInput
                    inputValue={currentRedundant?.profileDiameter || ""}
                    handleInputChange={(field, value) => handleInputChange(field, value)}
                    list={handleProfileDimensions(currentRedundant.profileType)}
                    field={"profileDiameter"}
                    placeholder={"Selecione o diâmetro do perfil"}
                  />
                }
                {
                  currentRedundant?.profileType === "U - Perfil U" &&
                  <>
                    <div>
                      <CustomInput
                        placeholder={"Aba (mm)"}
                        label={"Aba"}
                        value={currentRedundant?.profileFlange ?? ""}
                        onChange={(newValue) => handleInputChange("profileFlange", newValue)}
                        rawNumber={true}
                        unit={"mm"}
                      />

                      <CustomInput
                        placeholder={"Alma (mm)"}
                        label={"Alma"}
                        value={currentRedundant?.profileWeb ?? ""}
                        onChange={(newValue) => handleInputChange("profileWeb", newValue)}
                        rawNumber={true}
                        unit={"mm"}
                      />
                    </div>
                    <div>
                      <AutocompleteInput
                        inputValue={currentRedundant?.profileThickness || ""}
                        handleInputChange={(field, value) => handleInputChange(field, value)}
                        list={handleProfileDimensions(currentRedundant.profileType) || []}
                        field={"profileThickness"}
                        placeholder={"Selecione a espessura do perfil"}
                        label={"Espessura"}
                      />
                    </div>
                  </>
                }
              </InputMenu>
            }

            <InputMenu>
              <h2>Aço dos Parafusos</h2>
              <select
                disabled={segmentSelectedInMenu === "" || redundantSelectedInMenu === ""}
                value={segmentSelectedInMenu === "" ? "" :
                  currentRedundant?.boltsSteel ?? ""}
                onChange={(e) => handleInputChange("boltsSteel", e.target.value)}
              >
                <option value="" key={"boltsSteel-empty"}>Selecione o aço dos parafusos</option>
                {boltsSteel.map((type) => (
                  <option value={type} key={type}>{type}</option>
                ))}
              </select>
            </InputMenu>

            <InputMenu>
              <h2>Parafusos de Conexão</h2>
              <div>
                <CustomInput
                  disabled={segmentSelectedInMenu === "" || redundantSelectedInMenu === ""}
                  placeholder={"Quantidade"}
                  label={"Quantidade"}
                  value={segmentSelectedInMenu === "" ? ""
                    : currentRedundant?.numConnectionBolts ?? ""}
                  onChange={(newValue) => handleInputChange("numConnectionBolts", newValue)}
                  toFixed={0}
                />

                <CustomSelect
                  disabled={segmentSelectedInMenu === "" || redundantSelectedInMenu === ""}
                  label={"Diâmetro"}
                  value={segmentSelectedInMenu === "" ? ""
                    : currentRedundant?.diameterConnectionBolts ?? ""}
                  onChange={(e) => handleInputChange("diameterConnectionBolts", e)}
                >
                  <option value="" key={"diameterConnectionBolts-empty"}>Diâmetro</option>
                  {boltsDiameter.map((type) => (
                    <option value={type} key={type}>{type}</option>
                  ))}
                </CustomSelect>
              </div>
            </InputMenu>

            {(segmentSelectedInMenu !== "" && redundantSelectedInMenu !== "" && 
              currentRedundant?.profileSteel) && 
              <InputMenu>
                <h2>Dimensões do Perfil Reforço</h2>
                {
                  currentRedundant?.profileType === "L - Cantoneira" &&
                  <AutocompleteInput
                    inputValue={currentRedundant?.reinforcementDimensions || ""}
                    handleInputChange={(field, value) => handleInputChange(field, value)}
                    list={["Não Há Reforço", ...handleProfileDimensions(currentRedundant?.profileType)] || []}
                    field={"reinforcementDimensions"}
                    placeholder={"Selecione as dimensões do perfil"}
                  />
                }
                {
                  (currentRedundant?.profileType === "T - Tubo Vazado"
                    && currentRedundant?.profileSteel !== "") &&
                  <div>
                    <AutocompleteInput
                      inputValue={currentRedundant?.reinforcementDiameter || ""}
                      handleInputChange={(field, value) => handleInputChange(field, value)}
                      list={["Não Há Reforço", ...handleProfileDimensions(currentRedundant.profileType,
                        currentRedundant?.profileSteel)] || []}
                      field={"reinforcementDiameter"}
                      placeholder={"Diâmetro"}
                      label={"Diâmetro"}
                    />

                    <AutocompleteInput
                      inputValue={currentRedundant?.reinforcementThickness || ""}
                      handleInputChange={(field, value) => handleInputChange(field, value)}
                      list={(currentRedundant?.reinforcementDiameter
                        && currentRedundant?.reinforcementDiameter !== "Não Há Reforço")
                        ? ["Não Há Reforço", ...tubeProfileData[currentRedundant?.profileSteel]
                          [currentRedundant?.reinforcementDiameter]]
                        : []}
                      field={"reinforcementThickness"}
                      placeholder={"Espessura"}
                      label={"Espessura"}
                    />
                  </div>
                }
                {
                  currentRedundant?.profileType === "BR - Barra Maciça" &&
                  <AutocompleteInput
                    inputValue={currentRedundant?.reinforcementDiameter || ""}
                    handleInputChange={(field, value) => handleInputChange(field, value)}
                    list={["Não Há Reforço", ...handleProfileDimensions(currentRedundant.profileType)]}
                    field={"reinforcementDiameter"}
                    placeholder={"Selecione o diâmetro do perfil"}
                  />
                }
                {
                  currentRedundant?.profileType === "U - Perfil U" &&
                  <>
                    <div>
                      <CustomInput
                        disabled={currentRedundant?.reinforcementThickness === "Não Há Reforço"}
                        placeholder={"Aba (mm)"}
                        label={"Aba (mm)"}
                        value={currentRedundant?.reinforcementFlange ?? ""}
                        onChange={(newValue) => handleInputChange("reinforcementFlange", newValue)}
                        rawNumber={true}
                        unit={"mm"}
                      />

                      <CustomInput
                        disabled={currentRedundant?.reinforcementThickness === "Não Há Reforço"}
                        placeholder={"Alma (mm)"}
                        label={"Alma (mm)"}
                        value={currentRedundant?.reinforcementWeb ?? ""}
                        onChange={(newValue) => handleInputChange("reinforcementWeb", newValue)}
                        rawNumber={true}
                        unit={"mm"}
                      />
                    </div>
                    <div>
                      <AutocompleteInput
                        inputValue={currentRedundant?.reinforcementThickness || ""}
                        handleInputChange={(field, value) => handleInputChange(field, value)}
                        list={["Não Há Reforço", ...handleProfileDimensions(currentRedundant.profileType)] || []}
                        field={"reinforcementThickness"}
                        placeholder={"Selecione a espessura do perfil"}
                        label={"Espessura"}
                      />
                    </div>
                  </>
                }
              </InputMenu>
            }

            <Accordion title={"Selecionar Redundantes Iguais"}>
              {(segmentSelectedInMenu !== "" && redundantSelectedInMenu !== ""
                  && nRedundants[segmentSelectedInMenu - 1] > 0) &&
                inputValues[segmentSelectedInMenu - 1]?.redundants.map((_, index) => (
                  Number(redundantSelectedInMenu) !== index + 1 &&
                  <SelectableOptionContainer key={index + 1}>
                    <CustomCheckbox
                      id={`redundant${index + 1}`}
                      label={`Redundante ${index + 1}`}
                      checked={checkedRedundants[segmentSelectedInMenu - 1][redundantSelectedInMenu - 1]?.includes(index)}
                      onChange={(e) => setEqualRedundant(index)}
                      key={`redundant-${index + 1}`}
                    />
                  </SelectableOptionContainer>
                ))}
            </Accordion>

            <Accordion title={"Selecionar Trechos Iguais"}>
              {segmentSelectedInMenu !== "" &&
                inputValues.map((_, index) => (
                  (Number(segmentSelectedInMenu) !== index + 1 && nRedundants[index] !== 0) &&
                  <SelectableOptionContainer key={index + 1}>
                    <CustomCheckbox
                      id={`segment-${segmentSelectedInMenu}-target-${index + 1}`}
                      label={`Trecho ${index + 1}`}
                      checked={checkedSegments[segmentSelectedInMenu - 1]?.includes(index)}
                      onChange={(e) => setEqualSegment(index)}
                      key={`segment-${index + 1}`}
                    />
                  </SelectableOptionContainer >
                ))}
            </Accordion>
          </>
      }
    </>
  );
};

const SelectableOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin-bottom: 10px;
`;

const MessageNoRedundants = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 15px;

  p {
    color: ${colors.kcTextColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }
`;
