import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";

class GeometryModel {
  constructor(
    towerSection = "",
    totalHeight = "",
    topWidth = "",
    baseWidth = "",
    nSegments = "",
    elevation = ""
  ) {
    this.towerSection = towerSection;
    this.totalHeight = totalHeight;
    this.topWidth = topWidth;
    this.baseWidth = baseWidth;
    this.nSegments = nSegments;
    this.elevation = elevation;
  }

  updateField(field, value) {
    if (this[field] === value) return;
    this[field] = value;
  }

  assignData(data) {
    this.towerSection = data.towerSection;
    this.totalHeight = data.totalHeight;
    this.topWidth = data.topWidth;
    this.baseWidth = data.baseWidth;
    this.nSegments = data.nSegments;
    this.elevation = data.elevation;
  }

  validate() {
    let errors = [];

    if (!this.towerSection) errors.push("Seção da Torre");
    if (!this.totalHeight) errors.push("Altura Total");
    if (!this.topWidth) errors.push("Largura no Topo");
    if (!this.baseWidth) errors.push("Largura na Base");
    if (!this.nSegments) errors.push("Nº de Trechos");
    if (this.elevation === "") errors.push("Elevação em relação ao solo");
    if (this.nSegments < 4) errors.push("Deve haver no mínimo 4 trechos");
    if (parseFloat(this.topWidth) > parseFloat(this.baseWidth)) {
      errors.push("A largura do topo não pode ser maior que a largura da base.");
    }

    if (errors.length > 0) {
      const errorMessage = customToastError(errors,
        "Preencha todos os valores para salvar as informações de geometria da Torre. Falta(m):");
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  toJSON() {
    return JSON.stringify({
      towerSection: this.towerSection,
      totalHeight: this.totalHeight,
      topWidth: this.topWidth,
      baseWidth: this.baseWidth,
      nSegments: this.nSegments,
      elevation: this.elevation,
    });
  }

  static fromJSON(json) {
    return new GeometryModel(
      json.towerSection,
      json.totalHeight,
      json.topWidth,
      json.baseWidth,
      json.nSegments,
      json.elevation
    );
  }
}

export default GeometryModel;
