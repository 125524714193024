import styled from "styled-components";
import usePostCalculateStrap from "../../../hooks/api/usePostCalculateStrap";
import { useState, useContext, useEffect } from "react";
import UserContext from "../../../contexts/UserContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProjectLeftMenu from "../../project-components/project-leftMenu";
import LoadingAnimation from "../../common/loading-animation";
import {
  Dialog,
  DialogActions,
} from "@mui/material";
let lastStrapCalculationExecutionTime = 0;
let lastStrapDownloadExecutionTime = 0;

export default function MastStrapPage() {
  const { userData } = useContext(UserContext);
  const { setLeftMenuOptionSelected, mastSelected, setMastSelected, projectSelected, reloadProject, setReloadProject, setIsUpdatedWithoutCalculation } = useContext(ProjectContext);
  const { postCalculateStrap, postCalculateStrapLoading } = usePostCalculateStrap();
  const [isDonwloadingStrap, setIsDownLoadingStrap] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    type: "",
    isOpen: false,
  });
  const [wasAlreadyCalculated, setWasAlreadyCalculated] = useState(false);

  async function calculateStrap(action) {
    const currentTime = Date.now();
    if (action === "CALCULAR" && wasAlreadyCalculated) return;
    if (currentTime - lastStrapCalculationExecutionTime < 1000) return;
    lastStrapCalculationExecutionTime = currentTime;

    const mastProps = JSON.parse(mastSelected.props);
    mastProps.userId = String(mastSelected.userId);
    mastSelected.props = JSON.stringify({ ...mastProps, ...JSON.parse(projectSelected.props) });
    const propsStrap = { ...mastSelected, strapMessage: "" };
    try {
      if(action === "BAIXAR") setIsDownLoadingStrap(true);
      const strap = await postCalculateStrap(propsStrap, action, userData.token);
      if(strap.type !== "application/x-rar-compressed") {
        await blobToString(strap);
        return;
      }
      await blobToDownload(strap);
    } catch (error) {
      console.log(error);
    }
  }

  async function blobToDownload(strap) {
    setIsDownLoadingStrap(true);
    const currentTime = Date.now();
    if (currentTime - lastStrapDownloadExecutionTime < 1000) return;
    lastStrapDownloadExecutionTime = currentTime;
    const file = strap;
    const fileUrl = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = "strapfiles.rar";
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(fileUrl);
    setReloadProject(!reloadProject);
    toast.success("Download realizado com sucesso");
    setIsDownLoadingStrap(false);
    setLeftMenuOptionSelected("");
  }

  useEffect(() => {
    setWasAlreadyCalculated(true);
    calculateStrap("CALCULAR");
  }, []);

  async function blobToString(strap) {
    const data = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        try {
          const jsonData = JSON.parse(reader.result);
          resolve(jsonData);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = reject;
      reader.readAsText(strap);
    });
    if (data?.props) setMastSelected(data);
    if (data.length && data[0] === "Arquivo de resposta não foi gerado pelo STRAP, tente novamente mais tarde...") {
      setLeftMenuOptionSelected("");
      return toast.error(data[0]);
    } 
    setReloadProject(!reloadProject);
    toast.success("Cálculo realizado com sucesso");
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true
    });
    return setIsUpdatedWithoutCalculation(false);
  }

  return (
    <>
      <ProjectLeftMenu isDonwloadingStrap={isDonwloadingStrap}></ProjectLeftMenu>
      <RightContainer>
        {postCalculateStrapLoading ? <LoadingAnimation LoadingMessage={`O mastro está sendo ${isDonwloadingStrap ? "baixado" : "calculado"}!`}></LoadingAnimation> : ""}
        <DownloadStrapDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
          calculateStrap={calculateStrap}
          isDonwloadingStrap={isDonwloadingStrap}
          setLeftMenuOptionSelected={setLeftMenuOptionSelected}
        />
      </RightContainer>
    </>
  );
}

function DownloadStrapDialog({
  confirmDialog,
  setConfirmDialog,
  calculateStrap,
  isDonwloadingStrap,
  setLeftMenuOptionSelected
}) {
  function handleClosingIconClick() {
    setLeftMenuOptionSelected("");
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
  };

  return (
    <Dialog open={confirmDialog.isOpen} fullWidth={true} maxWidth="sm">
      <DiolagContainer dialogType={confirmDialog.type}>
        <h1>Mastro calculado com sucesso!</h1>
        <SpacingLine />
        <ProjectActionContainer>
          <h2>Deseja fazer o download dos arquivos strap?</h2>
          <GreenConfirmDialogButton isDonwloadingStrap={isDonwloadingStrap} onClick={() => {calculateStrap("BAIXAR");}}>{isDonwloadingStrap ? "Aguarde..." : "Download"}</GreenConfirmDialogButton>
        </ProjectActionContainer>
        <DialogActions>
          {" "}
        </DialogActions>
        <ion-icon onClick={handleClosingIconClick} name="close"></ion-icon>
      </DiolagContainer>
    </Dialog>
  );
};

const RightContainer = styled.div`
  position: relative;
  width: calc(75vw);
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-right: #B0b0b0 solid 30px;
  border-top: #B0b0b0 solid 30px;
`;

const DiolagContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 0;
  width: 100%;
  background-color: #ffffff;

  h1 {
    font-size: 20px;
    font-weight: 400;
    color: #000000;
    width: 92%;

    span {
      font-weight: 500;
    }
  }

  ion-icon {
    position: absolute;
    top: 10px;
    right: 16px;
    font-size: 28px;
    color: #AEAEAE;
    cursor: pointer;

    :active {
      color: #000;
    }
  };
`;

export const ProjectActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  padding: 0 34px 0 10px;
  background-color: #ffffff;
  height: 80px;
  width: 540px;

  h2 {
    color: #666666;
    font-size: 16px;
    margin-bottom: 6px;
    margin-top: 6px;
  }

  h3 {
    color: #000000;
    font-weight: 500;
    font-size: 20px;
  }

  input,
  select {
    height: 4vh;
    width: 106%;
    color: #000000 !important;
    background-color: #fff;
    border: 1px solid #C5C5C5;
    font-size: 16px;
    text-align: left;
    padding-left: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
  }
  input::placeholder{
  }
`;

const SpacingLine = styled.div`
  display: flex;
  width: 100%;
  height: 1.35px;
  margin: 12px 0 18px 0;
  background-color: #DDDDDD;
`;

const GreenConfirmDialogButton = styled.div `
  font-size: 15px;
  width: 106%;
  height: 4vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: #5FA037;
  border-radius: 4px;
  color: #fff;
  margin-top: 10px;
  pointer-events: ${(props) => (props.isDonwloadingStrap ? "none" : "inherit")};
  cursor: ${(props) => (props.isDonwloadingStrap ? "progress" : "pointer")};
  &:active {
    background: linear-gradient(#333, #333);
  }
  img {
    margin-left: 12px;
  }
`;
