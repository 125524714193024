/* eslint-disable */
import {
  Dialog,
  DialogActions,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { ProjectContext } from "../../../contexts/ProjectContext";
import UserContext from "../../../contexts/UserContext";
import { defaultError } from "../../../errors/default-error";
import { useTower } from "../../../hooks/api/tower-api/useTowerApi";
import { useTowerProject } from "../../../hooks/api/tower-api/useTowerProjectApi";
import useDeleteProject from "../../../hooks/api/useDeleteProject";
import useGetProject from "../../../hooks/api/useGetProject";
import useMasts from "../../../hooks/api/useMasts";
import usePostMast from "../../../hooks/api/usePostMast";
import usePostProject from "../../../hooks/api/usePostProject";
import useUpdateProjectName from "../../../hooks/api/useUpdateProjectName";
import useAssignTowerData from "../../../hooks/useAssignTowerData";
import useCopyTowerProject from "../../../hooks/api/useCopyTowerProject";
import { postProjectAccessHistory } from "../../../services/projectApi";

export default function NewProjectDialogue({
  confirmDialog,
  setConfirmDialog,
  isCopyingProject,
  setIsCopyingProject,
}) {
  const { userData } = useContext(UserContext);
  const { projectSelected, setProjectSelected, setProjectNameSelected, projectIdSelected, setProjectIdSelected,
    projectType, setMastSelected, setLeftMenuOptionSelected, mastSelected, setIsLoading } = useContext(ProjectContext);
  const { act: getTower } = useTower("get");
  const assignTowerData = useAssignTowerData();
  const { postProject } = usePostProject();
  const { deleteProject } = useDeleteProject();
  const { act: deleteTowerProject } = useTowerProject("delete");
  const { act: editTowerProjectName } = useTowerProject("put");
  const { copyTowerProject } = useCopyTowerProject();
  const { getProject } = useGetProject();
  const { postMast } = usePostMast();
  const { getMasts } = useMasts();
  const { updateProjectName } = useUpdateProjectName();
  const [inputValues, setInputValues] = useState({});
  const [copiedProjectName, setCopiedProjectName] = useState(`${confirmDialog.projectName} - Cópia`);
  const [newProjectName, setNewProjectName] = useState("");

  const fetchTowerData = async (projectId) => {
    setIsLoading(true);
    try {
      const response = await getTower(projectId, userData.token);
      if (response) assignTowerData(response);
      return response;
    } catch (error) {
      toast.error("Erro ao buscar dados da torre.");
      console.error(error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  async function insertProject() {
    if (!inputValues || !inputValues.projectName)
      throw defaultError("Digite um nome para o projeto!");
    if (!userData) throw defaultError("Faça login para registar um projeto!");
    try {
      let projectProps = {};
      if (projectType === "Rooftop") {
        projectProps = {
          nomeProjeto: inputValues.projectName,
          client: inputValues.clientName,
          siteId: inputValues.siteId,
          userId: userData.id,
        };
      } else if (projectType === "Torre") {
        projectProps = {
          name: inputValues.projectName,
          client: inputValues.clientName,
          siteId: inputValues.siteId,
          userId: userData.id,
        };
      }
      const newProject = await postProject(projectProps, userData.token, projectType);
      toast.success("Projeto adicionado com sucesso!");
      setInputValues({});
      setProjectSelected({ ...newProject, Mastro: [] });
      setConfirmDialog({ ...confirmDialog, isOpen: false });
    } catch (error) {
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
      if (error?.message) return toast.error(error.message);
    }
  }

  async function sendUserToAntennaPageIfTowerIsSelected() {
    if (projectType !== "Torre") return null;
    let projectMasts = [];
    if (projectType === "Torre") {
      async function fetchAllMastsNames() {
        const masts = await getMasts(16, userData.token);
        projectMasts = masts;
      }
      try {
        await fetchAllMastsNames();
        await setProjectSelected({
          ...projectSelected,
          nomeProjeto: confirmDialog.projectName,
          projectId: confirmDialog.projectId
        });
        await setMastSelected(projectMasts?.filter((mast) => mast.id === 500)[0]);
        setTimeout(() => { }, 500);
        await setLeftMenuOptionSelected("");
        toast.success("Projeto selecionado com sucesso!");
      } catch (error) {
        console.error(error);
      }
      return 1;
    }
  }

  async function postAccessHistory() {
    try {
      await postProjectAccessHistory(confirmDialog.projectName, userData.token, projectType);
    } catch (error) {
      console.error(error);
    }
  }

  async function openExistingProject() {
    selectExistingProject().then(() => {
      postAccessHistory();
      if (projectType === "Torre") fetchTowerData(confirmDialog.projectId);
    });
  }

  async function selectExistingProject() {
    setInputValues({});
    // if (await sendUserToAntennaPageIfTowerIsSelected()) return;
    setProjectNameSelected(confirmDialog.projectName);
    setProjectIdSelected(confirmDialog.projectId);
    setProjectSelected({
      ...projectSelected,
      nomeProjeto: confirmDialog.projectName,
      projectId: confirmDialog.projectId,
    });
    setConfirmDialog({ ...confirmDialog, isOpen: false });
    toast.success("Projeto selecionado com sucesso!");
  }

  async function copyExistingProject() {
    if (projectType === "Torre") {
      await copyExistingTowerProject();
    } else if (projectType === "Rooftop") {
      await copyExistingRooftopProject();
    }
  }

  async function copyExistingRooftopProject() {
    try {
      const projectToBeCopied = await getProject(confirmDialog.projectName, userData.token);
      projectToBeCopied.nomeProjeto = copiedProjectName || `${confirmDialog.projectName} - Cópia`;
      const filteredProject = Object.entries(projectToBeCopied).filter(([key, value]) => value !== null).reduce((obj, [key, value]) => { obj[key] = value; return obj; }, {});
      delete filteredProject.id;
      const copiedProjectMasts = filteredProject.Mastro;

      delete filteredProject.Mastro;
      const copiedProject = await postProject(filteredProject, userData.token);

      copiedProjectMasts.forEach(async (mast) => {
        delete mast.id;
        delete mast.createdAt;
        delete mast.updatedAt;
        mast.userId = userData.id;
        mast.projectId = copiedProject.id;
        await postMast(mast, userData.token);
      });
      setProjectSelected(copiedProject);

      toast.success("Projeto adicionado com sucesso!");
      setInputValues({});
      setConfirmDialog({ ...confirmDialog, isOpen: false });
    } catch (error) {
      console.error(error.message);
      return toast.error("Erro ao copiar o projeto.");
    }
  }

  async function copyExistingTowerProject(){
    try {
      const newProjectName = { newName: copiedProjectName }
      const projectToBeCopied = await copyTowerProject(confirmDialog.projectId, newProjectName, userData.token);
      setProjectSelected(projectToBeCopied);

      toast.success("Projeto adicionado com sucesso!");
      setInputValues({});
      setConfirmDialog({ ...confirmDialog, isOpen: false });
    } catch (error) {
      console.error(error.message);
      return toast.error("Erro ao copiar o projeto.");
    }
  }

  async function deleteExistingProject() {
    try {
      if (window.confirm(`Tem certeza de que deseja excluir o projeto ${confirmDialog.projectName}?`)) {
        if (projectType === "Rooftop") {
          await deleteProject(confirmDialog.projectName, userData.token);
        } else if (projectType === "Torre") {
          await deleteTowerProject(confirmDialog.projectId, userData.token);
        }
        toast.success("Projeto deletado com sucesso!");
        setInputValues({});
        setConfirmDialog({ ...confirmDialog, isOpen: false });
      }
    } catch (error) {
      console.error(error);
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
      if (error?.message) return toast.error(error.message);
    }
  }

  async function editNameOfExistingProject() {
    try {
      if (projectType === "Rooftop") {
        await updateProjectName({ newName: newProjectName }, userData.token);
      } else if (projectType === "Torre") {
        if (newProjectName === "") return toast.error("Insira um nome para o projeto");
        await editTowerProjectName(newProjectName, confirmDialog.projectId, userData.token);
      }
      toast.success("Nome de projeto atualizado com sucesso!");
      setConfirmDialog({ ...confirmDialog, isOpen: false });
    } catch (error) {
      if (error.response?.data?.message) {
        return toast.error(error.response?.data?.message);
      }
      toast.error("Nome do projeto não atualizado");
      console.error(error);
    }
  }

  useEffect(() => {
    setCopiedProjectName(`${confirmDialog.projectName} - Cópia`);
  }, [confirmDialog]);

  return (
    <Dialog open={confirmDialog.isOpen} fullWidth={true} maxWidth="sm">
      <DialogContainer confirmationType={confirmDialog.type} projectName={inputValues.projectName}>
        {confirmDialog.type === "new" && (
          <>
            <h1>Criar novo projeto</h1>
            <SpacingLine />
            <ProjectActionContainer selectingType={confirmDialog.type}>
              <input type="text" autoFocus placeholder="Nome do Projeto" onChange={(e) => setInputValues({ ...inputValues, projectName: e.target.value, })} />
              <input type="text" placeholder="Nome do Cliente" onChange={(e) => setInputValues({ ...inputValues, clientName: e.target.value, })} />
              <input type="text" placeholder="Site ID" onChange={(e) => setInputValues({ ...inputValues, siteId: e.target.value, })} />
              <BlackConfirmDialogButton onClick={insertProject}>Criar Novo Projeto</BlackConfirmDialogButton>
            </ProjectActionContainer>
            <DialogActions>
              {" "}
            </DialogActions>
          </>
        )}
        {confirmDialog.type === "open" && (
          <>
            <h1>Editar projeto</h1>
            <SpacingLine />
            <ProjectActionContainer selectingType={confirmDialog.type}>
              <h2>Você confirma a edição do projeto ?</h2>
              <h3>{confirmDialog.projectName}</h3>
              <BlackConfirmDialogButton onClick={() => openExistingProject()}
              >Confirmar</BlackConfirmDialogButton>
            </ProjectActionContainer>
            <DialogActions>
              {" "}
            </DialogActions>
          </>
        )}
        {confirmDialog.type === "copy" && (
          <>
            <h1>Copiar projeto</h1>
            <SpacingLine />
            <ProjectActionContainer selectingType={"copy"} isSelected={confirmDialog.projectName}>
              {!isCopyingProject ?
                <>
                  <h2>Você confirma a cópia do projeto ?</h2>
                  <h3>{confirmDialog.projectName}</h3>
                  <BlackConfirmDialogButton onClick={() => setIsCopyingProject(true)}>Confirmar</BlackConfirmDialogButton>
                </>
                :
                <>
                  <h2>Insira o nome do novo projeto</h2>
                  <input
                    placeholder={`${confirmDialog.projectName} - Cópia`}
                    autoFocus onChange={(e) => setCopiedProjectName(e.target.value)}
                    type="text"></input>
                  <BlackConfirmDialogButton onClick={copyExistingProject}>Criar novo projeto</BlackConfirmDialogButton>
                </>}
            </ProjectActionContainer>
            <DialogActions>
            </DialogActions>
          </>
        )}
        {confirmDialog.type === "delete" && (
          <>
            <h1>Deletar projeto</h1>
            <SpacingLine />
            <ProjectActionContainer selectingType={confirmDialog.type}>
              <h2>Você confirma a exclusão do projeto ?</h2>
              <h3>{confirmDialog.projectName}</h3>
              <BlackConfirmDialogButton isDeletionType={true} onClick={deleteExistingProject}>Confirmar</BlackConfirmDialogButton>
            </ProjectActionContainer>
            <DialogActions>
              {" "}
            </DialogActions>
          </>
        )}
        {confirmDialog.type === "edit" ?
          <>
            <h1>Editar nome do projeto</h1>
            <SpacingLine />
            <ProjectActionContainer selectingType={confirmDialog.type}>
              <h3>{confirmDialog.projectName}</h3>
              <input onChange={(e) => { setNewProjectName(e.target.value); }} placeholder="Digite o novo nome do projeto..."></input>
              <BlackConfirmDialogButton onClick={() => editNameOfExistingProject()}>Confirmar</BlackConfirmDialogButton>
            </ProjectActionContainer>
            <DialogActions>
              {" "}
            </DialogActions>
          </>
          : ""}
        <ion-icon onClick={() => { setConfirmDialog({ ...confirmDialog, isOpen: false }); }} name="close"></ion-icon>
      </DialogContainer>
    </Dialog>
  );
}

export const DialogContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    width: 100%;
    background-color: #ffffff;

    h1 {
        font-size: 20px;
        font-weight: 400;
        text-align: start;
        color: #000000;
        width: 92%;
    }

    ion-icon {
        position: absolute;
        top: 10px;
        right: 16px;
        font-size: 28px;
        color: #AEAEAE;
        cursor: pointer;

        :active {
            color: #000;
        }
    };
`;

export const SpacingLine = styled.div`
    display: flex;
    width: 100%;
    height: 1.35px;
    margin: 12px 0;
    background-color: #DDDDDD;
`;

export const ConfirmDialogButton = styled.div`
    transition: all 0.1s;
    width: 116px;
    height: 4vh;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 32px;
    background-color: #F16E00;
    cursor: pointer;
    font-size: 20px;
    font-weight: 300;
    color: #ffffff;
    :hover {
        font-weight: 500;
    }
`;

export const BlackConfirmDialogButton = styled.div`
    font-size: 15px;
    width: 106%;
    height: 4vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: ${(props) => (props.isDeletionType ? "#BF2929" : "#000")};
    color: #fff;
    cursor: pointer;
    &:active {
        background: linear-gradient(#333, #333);
    }
`;

export const ReturnDialogButton = styled.div`
    width: 116px;
    height: 4vh;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #7F7F7F;
    cursor: pointer;
    font-size: 19px;
    font-weight: 300;
    color: #fff;
    padding-right: 12px;
    transition: all 0.1s;
    position: relative;
    :hover {
        font-weight: 400;
        ion-icon {
            font-size: 23px;
        }
    }
    div {
        width: 35%;
        height: 100%;
        background-color: #696969;
        border-radius: 8px;
        ion-icon {
            transition: all 0.5s;
            font-size: 22px;
            position: absolute;
            top: 25%;
            left: 7px;
        }
    }
`;

export const ButtonsContainer = styled.div`
    display: flex;
    width: 280px;
    margin: 1vh 0 6px 250px;
    justify-content: right;
`;

export const ProjectActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: -20px;
    background-color: #ffffff;
    height: ${(props) => (props.selectingType !== "new" ? "150px" : "230px")};
    width: 540px;

    h2 {
        text-align: center;
        color: #666666;
        font-size: 16px;
        width: 100%;
        margin-top: 10px;
    }

    h3 {
        text-align: center;
        color: #000000;
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 10px;
    }

    input, select {
        height: 4.5vh;
        width: 106%;
        color: #000000 !important;
        background-color: #fff;
        border: 1px solid #C5C5C5;
        font-size: 16px;
        text-align: left;
        padding-left: 10px;
        border-radius: 4px;
    }
    select {
        border: 0;
    }
    input::placeholder{
    }
`;
