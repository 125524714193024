/* eslint-disable import/first */
import React, { useEffect } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import imagesCharacteres from "../../../assets/numbersAndLetters/lettersAndNumbers";
import images from "../../../assets/images/numberResponses/responsesImages";

export function DrawPage({  mastImageProps, position, maxOrMin, copyCameraRightMax }) {
  return <MastImage mastImageProps={mastImageProps} position={position} maxOrMin={maxOrMin} copyCameraRightMax={copyCameraRightMax}></MastImage>;
}
  
const MastImage = ({ mastImageProps, position, maxOrMin, copyCameraRightMax }) => {
  let counter = 0;
  const { 
    mastGeometry,
    mastReactions,
    setCameraReactionsRightMax, 
    cameraReactionsRightMax, 
    setCameraReactionsRightMin, 
    cameraReactionsRightMin, 
    setCameraCapacityRight,
    cameraCapacityRight,
    cameraEsbeltezRight,
    setCameraEsbeltezRight,
    setIsRendered, 
    exibitionOption: exibition, 
    leftMenuRef, 
    sizingResultsArray,
    mastType,
  } = mastImageProps;
  let { isRendered } = mastImageProps;
  let mastHeight = 0;
  mastGeometry.filter(geom => { if(geom.x3f > mastHeight) mastHeight = geom.x3f;} );
  useEffect(() => {
    counter++;
    if((counter === 1 && isRendered <= 2)) {
      isRendered++;
      setIsRendered(isRendered);
      let { camera, scene, renderer } = prepareScene(position, mastHeight, "#ffffff", leftMenuRef);
      if(position === "reactions-axes-max" && copyCameraRightMax) {
        camera = cameraReactionsRightMax;
      }
      if(position === "reactions-axes-min" && !copyCameraRightMax) {
        camera = cameraReactionsRightMin;
      }
      if(position === "capacity-axes") camera = cameraCapacityRight;
      if(position === "esbeltez-axes") camera = cameraEsbeltezRight;
      let [axesComponentsLettersAndArrows, reactionsComponentsNumbersAndArrowsArray] = [];
      if(position.indexOf("axes") !== -1) {
        axesComponentsLettersAndArrows = createCoordinateAxes(scene, camera, mastHeight);
      } 
      if(position === "reactions-left" || position === "reactions-right-min" || position === "reactions-right-max") {
        createGeometry(scene, mastGeometry);
        reactionsComponentsNumbersAndArrowsArray = createReactions(scene, camera, mastGeometry, mastReactions, position, maxOrMin, mastType);
      }
      let [geometryComponents, capacityImageComponents] = [];
      if(position === "capacity-left" || position === "capacity-right") {
        const [geometryComponentss, capacityImageComponentss] = createGeometryForCapacity(scene, mastGeometry, sizingResultsArray, camera, position);
        geometryComponents = geometryComponentss;
        capacityImageComponents = capacityImageComponentss;
      }
      let [esbeltezComponents, esbeltezImageComponents] = [];
      if(position === "esbeltez-left" || position === "esbeltez-right") {
        const [esbeltezComponentss, esbeltezImageComponentss] = createGeometryForEsbeltez(scene, mastGeometry, sizingResultsArray, camera, position);
        esbeltezComponents = esbeltezComponentss;
        esbeltezImageComponents = esbeltezImageComponentss;
      } 
      
      const animate = () => {
        requestAnimationFrame(animate);
        if(position === "reactions-right-max" && maxOrMin === "max") setCameraReactionsRightMax(camera);
        if(position === "reactions-right-min" && maxOrMin === "min") setCameraReactionsRightMin(camera);
        if(position === "capacity-right") setCameraCapacityRight(camera);
        if(position === "esbeltez-right") setCameraEsbeltezRight(camera);
        if(position.indexOf("axes") !== -1 && Object.keys(axesComponentsLettersAndArrows || {}).length > 0) {
          axesComponentsLettersAndArrows.componentsLetterX.mesh.rotation.copy(camera.rotation);
          axesComponentsLettersAndArrows.componentsLetterY.mesh.rotation.copy(camera.rotation);
          axesComponentsLettersAndArrows.componentsLetterZ.mesh.rotation.copy(camera.rotation);
        }
        if((position === "reactions-right-max" || position === "reactions-right-min") && reactionsComponentsNumbersAndArrowsArray?.length > 0) {
          for (const numberMesh of reactionsComponentsNumbersAndArrowsArray) {
            numberMesh?.mesh?.rotation.copy(camera.rotation);
          }
        }
        if(position === "capacity-right" && capacityImageComponents?.length > 0) {
          for (const numberMesh of capacityImageComponents) {
            numberMesh?.mesh?.rotation.copy(camera.rotation);
          }
        }
        if(position === "esbeltez-right" && esbeltezImageComponents?.length > 0) {
          for (const numberMesh of esbeltezImageComponents) {
            numberMesh?.mesh?.rotation.copy(camera.rotation);
          }
        }
        renderer.render(scene, camera);
      };
      animate();
    }}, []);
  return <div id={`canvas-container-${position}`}></div>;
};

const prepareScene = (position, mastHeight, backGroundColor, leftMenuRef, windows = { width: window.innerWidth, height: window.innerHeight }) => {
  const renderer = new THREE.WebGLRenderer();
  let camera;
  if(position.indexOf("left") !== -1) camera = new THREE.PerspectiveCamera(95, 1, 0.1, 100);
  if(position.indexOf("right") !== -1 || position.indexOf("axes") !== -1) camera = new THREE.PerspectiveCamera(95,  windows.width*0.74/ windows.height*0.88, 0.1, 100);
  const scene = new THREE.Scene();
  scene.background = new THREE.Color(backGroundColor);

  if(position.indexOf("left") !== -1) renderer.setSize(400, 350); 
  if(position.indexOf("axes") !== -1) renderer.setSize(windows.width*0.20, windows.height*0.30);
  if(position.indexOf("right") !== -1) renderer.setSize(windows.width*0.74, windows.height*0.88);

  document.getElementById(`canvas-container-${position}`).appendChild(renderer.domElement);
  let controls;

  if(position.indexOf("left") === -1) {
    controls = new OrbitControls(camera, renderer.domElement);
    controls.enableZoom = true;
    controls.enablePan = true;
  }

  camera.position.set(mastHeight*(2/3), mastHeight*(2/3), mastHeight*(2/3));
  camera.lookAt(0, (mastHeight*0.8)/2, 0);
  return { camera, scene, renderer };
};

const createGeometry = (scene, mastGeometry) => {
  const geometryComponents = [];
  for (const bar of mastGeometry) {
    const lineStart = new THREE.Vector3(bar.x1i, bar.x2i, bar.x3i);
    const lineEnd = new THREE.Vector3(bar.x1f, bar.x2f, bar.x3f);
    const geometryComponent = createLine(scene, lineStart, lineEnd, "blue");
    geometryComponents.push(geometryComponent);
  }
  return geometryComponents;
};

const createGeometryForCapacity = (scene, mastGeometry, sizingResultsArray, camera, position) => {
  const geometryComponents = [];
  const capacityImageComponents = [];
  let index=0;
  for (const bar of mastGeometry) {
    const color = extractColorFromCapacity(sizingResultsArray[index].completeVerification);
    const lineStart = new THREE.Vector3(bar.x1i, bar.x2i, bar.x3i);
    const lineEnd = new THREE.Vector3(bar.x1f, bar.x2f, bar.x3f);
    const geometryComponent = createLine(scene, lineStart, lineEnd, color);
    geometryComponents.push(geometryComponent);
    const numberCoords = { 
      x: Math.abs((bar.x1f - bar.x1i)/2+(bar.x1i === 0 ? 0 : bar.x1i)+0.2),
      y: Math.abs((bar.x2f-bar.x2i)/2+(bar.x2i === 0 ? 0 : bar.x2i)),
      z: Math.abs(((bar.x3f-bar.x3i)/2)+(bar.x3i === 0 ? 0 : bar.x3i))
    };
    let capacityImageComponent;
    const completeVerification = Math.ceil(sizingResultsArray[index].completeVerification);
    const completeVerificationImg = images[color][completeVerification > 800 ? 800 : completeVerification];
    if(position === "capacity-right") { 
      capacityImageComponent = createNumberOrDotByImg(scene, completeVerificationImg, numberCoords, camera, 0.45, 0.25);
      capacityImageComponents.push(capacityImageComponent);
    }
    if(position === "capacity-left") {
      capacityImageComponent = createNumberOrDotByImg(scene, completeVerificationImg, numberCoords, camera, 0.8, 0.6);
    }
    index++;
  }
  return [geometryComponents, capacityImageComponents];
};

const createGeometryForEsbeltez = (scene, mastGeometry, sizingResultsArray, camera, position) => {
  const geometryComponents = [];
  const capacityImageComponents = [];
  let index=0;
  for (const bar of mastGeometry) {
    const color = (sizingResultsArray[index].esbeltez*100)/200 >= 100 ? "red" : "black";
    const lineStart = new THREE.Vector3(bar.x1i, bar.x2i, bar.x3i);
    const lineEnd = new THREE.Vector3(bar.x1f, bar.x2f, bar.x3f);
    const geometryComponent = createLine(scene, lineStart, lineEnd, color);
    geometryComponents.push(geometryComponent);
    const numberCoords = { 
      x: Math.abs((bar.x1f - bar.x1i)/2+(bar.x1i === 0 ? 0 : bar.x1i)+0.2),
      y: Math.abs((bar.x2f-bar.x2i)/2+(bar.x2i === 0 ? 0 : bar.x2i)),
      z: Math.abs(((bar.x3f-bar.x3i)/2)+(bar.x3i === 0 ? 0 : bar.x3i))
    };
    let capacityImageComponent;
    const esbeltez = Math.ceil(sizingResultsArray[index].esbeltez);
    const esbeltezImg = images[color][esbeltez > 800 ? 800 : esbeltez];
    if(position === "esbeltez-right") {  
      capacityImageComponent = createNumberOrDotByImg(scene, esbeltezImg, numberCoords, camera, 0.45, 0.25);
      capacityImageComponents.push(capacityImageComponent);
    }
    if(position === "esbeltez-left") {
      capacityImageComponent = createNumberOrDotByImg(scene, esbeltezImg, numberCoords, camera, 0.8, 0.6);
    }
    index++;
  }
  return [geometryComponents, capacityImageComponents];
};

const extractColorFromCapacity = (value) => {
  let color = "#572725";
  if(value <= 125) color = "violet"; //"#A74AA8";
  if(value <= 110) color = "crimson"; //"#9A3C3F";
  if(value <= 102) color = "blue"; //"#242480";
  if(value <= 90) color = "teal"; //"#79D3D5";
  if(value <= 80) color = "darkgreen"; //"#3C6C3C";
  if(value <= 70) color = "chartreuse"; //#81D480";
  if(value <= 60) color = "darkslategray"; //"#979797"; 
  return color;
};

const createReactions = (scene, camera, mastGeometry, mastReactions, position, maxOrMin, mastType) => {
  let componentsArray = [];
  for (const bar of mastGeometry) {
    for (const nodeMastReaction of Object.keys(mastReactions[maxOrMin || "max"])) {
      const maxOrMinReaction = mastReactions[maxOrMin || "max"];
      if(bar.initialNode.toString() === maxOrMinReaction[nodeMastReaction].node.toString()) {
        const components = createReaction(scene, camera, maxOrMinReaction[nodeMastReaction], bar, position, mastType);
        componentsArray.push(...components);
      }
    }
  }
  return componentsArray;
};

const createLine = (scene, startPoint, endPoint, color) => {
  startPoint = new THREE.Vector3(startPoint.y, startPoint.z, startPoint.x);
  endPoint = new THREE.Vector3(endPoint.y, endPoint.z, endPoint.x);
  const material = new THREE.LineBasicMaterial({ color: color, linewidth: 2 });
  const geometry = new THREE.BufferGeometry().setFromPoints([startPoint, endPoint]);
  const line = new THREE.Line(geometry, material);
  scene.add(line);
  return { material, geometry, line };
};

const createCoordinateAxes = (scene, camera, mastHeight) => {
  const arrowDirectionX = new THREE.Vector3(1, 0, 0);
  const arrowDirectionY = new THREE.Vector3(0, 1, 0);
  const arrowDirectionZ = new THREE.Vector3(0, 0, 1);
  const arrowLength = mastHeight/2;
  const arrowOrigin = new THREE.Vector3(0, 0, 0);
  const arrowX = createArrow(scene, arrowDirectionX, arrowOrigin, arrowLength, "green", 0.6);
  const arrowY = createArrow(scene, arrowDirectionY, arrowOrigin, arrowLength, "red", 0.6);
  const arrowZ = createArrow(scene, arrowDirectionZ, arrowOrigin, arrowLength, "blue", 0.6);
  const componentsLetterX = createTextByImg(scene, imagesCharacteres.x, { x: mastHeight/2+mastHeight/10, y: 0, z: 0 }, camera, mastHeight/8);
  const componentsLetterZ = createTextByImg(scene, imagesCharacteres.z, { x: 0, y: 0, z: mastHeight/2+mastHeight/10 }, camera, mastHeight/8);
  const componentsLetterY = createTextByImg(scene, imagesCharacteres.y, { x: 0, y: mastHeight/2+mastHeight/10, z: 0 }, camera, mastHeight/8);
  return { componentsLetterX, componentsLetterZ, componentsLetterY, arrowX, arrowY, arrowZ };
};

const createArrow = (scene, arrowDirection, arrowOrigin, arrowLength, color) => {
  const arrowPointSize = (arrowLength)/5;
  arrowDirection = new THREE.Vector3(arrowDirection.y, arrowDirection.z, arrowDirection.x);
  arrowOrigin = new THREE.Vector3(arrowOrigin.y, arrowOrigin.z, arrowOrigin.x);
  const arrow = new THREE.ArrowHelper(arrowDirection, arrowOrigin, arrowLength, color, arrowPointSize, arrowPointSize);
  scene.add(arrow);
  return arrow;
};

const createTextByImg = (scene, img, textCoords, camera, letterSize = 0.6) => {
  const textureLoader = new THREE.TextureLoader();
  const texture = textureLoader.load(img);
  const geometry = new THREE.PlaneGeometry(letterSize, letterSize);
  const material = new THREE.MeshBasicMaterial({ map: texture });
  const mesh = new THREE.Mesh(geometry, material);
  scene.add(mesh);
  mesh.position.set(textCoords.y, textCoords.z, textCoords.x);
  mesh.rotation.copy(camera.rotation);
  return { mesh, material, geometry };
};

const createNumberOrDotByImg = (scene, img, numberCoords, camera, width=0.5, height=0.3) => {
  const textureLoader = new THREE.TextureLoader();
  const texture = textureLoader.load(img);
  const geometry = new THREE.PlaneGeometry(width, height);
  const material = new THREE.MeshBasicMaterial({ map: texture });
  const mesh = new THREE.Mesh(geometry, material);
  scene.add(mesh);
  mesh.position.set(numberCoords.y, numberCoords.z, numberCoords.x);
  mesh.rotation.copy(camera.rotation);
  return { mesh, material, geometry };
};

const createReaction = (scene, camera, mastReaction, bar, position, mastType) => {
  const arrowDirectionZ = mastReaction.x3 > 0 ? new THREE.Vector3(0, 0, 1) : new THREE.Vector3(0, 0, -1);
  let arrowLengthZ;
  arrowLengthZ = Math.pow(Math.abs(mastReaction.x3*5), 1/2)/2 > 1 ? 1 : Math.pow(Math.abs(mastReaction.x3*5), 1/2)/2;
  arrowLengthZ = Math.pow(Math.abs(mastReaction.x3*5), 1/2)/2 < 0.4 ? 0.4 : Math.pow(Math.abs(mastReaction.x3*5), 1/2)/2;
  if(Math.abs(mastReaction.x3) < 0.001) arrowLengthZ = 0;
  const arrowOriginZ = mastReaction.x3 > 0 ? 
    new THREE.Vector3(bar.x1i, bar.x2i, bar.x3i-arrowLengthZ) 
    : new THREE.Vector3(bar.x1i, bar.x2i, bar.x3i);
  let arrowZ = createArrow(scene, arrowDirectionZ, arrowOriginZ, arrowLengthZ, mastReaction.x3 > 0 ? "red" : "black");
  let componentsX, componentsY, componentsZ;
  if(position === "reactions-right-max" || position === "reactions-right-min") {
    let reactionZMI = 0;
    if(mastType === "MI") reactionZMI = bar.x3i;
    let reaction = (Math.ceil(mastReaction.x3*100)/100) > 10 ? 10 : (Math.ceil(mastReaction.x3*100)/100);
    reaction = (Math.ceil(mastReaction.x3*100)/100) < -10 ? -10 : (Math.ceil(mastReaction.x3*100)/100);
    const imgX3 =  mastReaction.x3 > 0 ? images.red[reaction.toFixed(2)] : images.black[reaction.toFixed(2)];
    componentsZ = createNumberOrDotByImg(scene, imgX3, { x: bar.x1i, y: bar.x2i, z: -Math.abs(arrowLengthZ)-0.2+reactionZMI }, camera);
  }

  const arrowDirectionY = mastReaction.x2 > 0 ? new THREE.Vector3(0, 1, 0) : new THREE.Vector3(0, -1, 0);
  let arrowLengthY;
  arrowLengthY = Math.pow(Math.abs(mastReaction.x2*5), 1/2)/2 > 1 ? 1 : Math.pow(Math.abs(mastReaction.x2*5), 1/2)/2;
  arrowLengthY = Math.pow(Math.abs(mastReaction.x2*5), 1/2)/2 < 0.4 ? 0.4 : Math.pow(Math.abs(mastReaction.x2*5), 1/2)/2;
  if(Math.abs(mastReaction.x2) < 0.001) arrowLengthY = 0;
  const arrowOriginY = mastReaction.x2 > 0 ? 
    new THREE.Vector3(bar.x1i, bar.x2i-arrowLengthY, bar.x3i) 
    : new THREE.Vector3(bar.x1i, bar.x2i, bar.x3i);
  let arrowY = createArrow(scene, arrowDirectionY, arrowOriginY, arrowLengthY, mastReaction.x2 > 0 ? "red" : "black");
  if(position === "reactions-right-max" || position === "reactions-right-min") {
    let reaction = (Math.ceil(mastReaction.x2*100)/100) > 10 ? 10 : (Math.ceil(mastReaction.x2*100)/100);
    reaction = (Math.ceil(mastReaction.x2*100)/100) < -10 ? -10 : (Math.ceil(mastReaction.x2*100)/100);
    const imgX2 =  mastReaction.x2 > 0 ? images.red[reaction.toFixed(2)] : images.black[reaction.toFixed(2)];
    componentsY = createNumberOrDotByImg(scene, imgX2, { x: bar.x1i, y: -Math.abs(arrowLengthY)-0.2+bar.x2i, z: bar.x3i }, camera);
  }

  const arrowDirectionX = mastReaction.x1 > 0 ? new THREE.Vector3(1, 0, 0) : new THREE.Vector3(-1, 0, 0);
  let arrowLengthX;
  arrowLengthX = Math.pow(Math.abs(mastReaction.x1*5), 1/2)/2 > 1 ? 1 : Math.pow(Math.abs(mastReaction.x1*5), 1/2)/2;
  arrowLengthX = Math.pow(Math.abs(mastReaction.x1*5), 1/2)/2 < 0.4 ? 0.4 : Math.pow(Math.abs(mastReaction.x1*5), 1/2)/2;
  if(Math.abs(mastReaction.x1) < 0.001) arrowLengthX = 0;
  const arrowOriginX = mastReaction.x1 > 0 ? 
    new THREE.Vector3(bar.x1i-arrowLengthX, bar.x2i, bar.x3i) 
    : new THREE.Vector3(bar.x1i, bar.x2i, bar.x3i);
  let arrowX = createArrow(scene, arrowDirectionX, arrowOriginX, arrowLengthX, mastReaction.x1 > 0 ? "red" : "black");
  if((position === "reactions-right-max" || position === "reactions-right-min") && mastReaction.x1 !== 0) {
    let reaction = (Math.ceil(mastReaction.x1*100)/100) > 10 ? 10 : (Math.ceil(mastReaction.x1*100)/100);
    reaction = (Math.ceil(mastReaction.x1*100)/100) < -10 ? -10 : (Math.ceil(mastReaction.x1*100)/100);
    const imgX1 =  mastReaction.x1 > 0 ? images.red[reaction.toFixed(2)] : images.black[reaction.toFixed(2)];
    componentsX = createNumberOrDotByImg(scene, imgX1, { x: -Math.abs(arrowLengthX)-0.2+bar.x1i, y: bar.x2i, z: bar.x3i }, camera);
  }

  return [componentsX, componentsY, componentsZ, arrowX, arrowY, arrowZ];
};

const setDrawComponents = (reactionsComponentsNumbersAndArrowsArray, axesComponentsLettersAndArrows, geometryComponentsArray, setThreeDrawComponents, threeDrawComponents) => {
  const drawComponentsObject = {
    axes: [],
    reactions: [],
    geometry: []
  };
  if(axesComponentsLettersAndArrows) {
    drawComponentsObject.axes = Object.values(axesComponentsLettersAndArrows);
  } else {
    drawComponentsObject.axes = threeDrawComponents.axes;
  }
  if(reactionsComponentsNumbersAndArrowsArray) { 
    drawComponentsObject.reactions = reactionsComponentsNumbersAndArrowsArray;
  } else {
    drawComponentsObject.reactions = threeDrawComponents.reactions;
  }
  if(geometryComponentsArray) {
    drawComponentsObject.geometry = geometryComponentsArray;
  } else {
    drawComponentsObject.geometry = threeDrawComponents.geometry;
  }
  setThreeDrawComponents({ ...drawComponentsObject });
};

const unsetDrawComponents = (threeDrawComponents, scene) => {
  const geometryArray = threeDrawComponents.geometry;
  for (const geometry of geometryArray) {
    geometry.material.dispose();
    geometry.geometry.dispose();
    scene.remove(geometry.line);
  }

  const reactionArray = threeDrawComponents.reactions;
  for (const reaction of reactionArray) {
    if(reaction.isObject3D) {
      scene.remove(reaction);
    } else {
      reaction.mesh.geometry.dispose();
      reaction.mesh.material.dispose();
      reaction.mesh.material?.map.dispose();
      reaction.mesh = null;
    }
  }

  const axesArray = threeDrawComponents.axes;
  for (const axes of axesArray) {
    if(axes.isObject3D) {
      scene.remove(axes);
    } else {
      axes.mesh.geometry.dispose();
      axes.mesh.material.dispose();
      axes.mesh.material?.map.dispose();
      axes.mesh = null;
    }
  }
};  

export default MastImage;
