import styled from "styled-components";
import { TopBar } from "../components/topMenu-components/top-bar";
import { useContext, useEffect, useRef, useState } from "react";
import UserContext from "../contexts/UserContext";
import useGetUserInfo from "../hooks/api/useGetUserInfo";
import useUpdateUserInfo from "../hooks/api/useUpdateUserInfo";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UpdatePasswordDialog from "../components/common/updatePasswordDialog";

export default function UserInfoPage() {
  const { userData, userInfo, setUserInfo } = useContext(UserContext);
  const [image, setImage] = useState("");
  const { getUserInfo } = useGetUserInfo();
  const { updateUserInfo, updateUserInfoLoading } = useUpdateUserInfo();
  const [userName, setUserName] = useState({ name: userInfo.name || "", lastName: userInfo.lastName || "" });
  const [reload, setReload] = useState(0);
  const [inputValues, setInputValues] = useState({
    name: userData.name || "",
    lastName: "",
    email: userData.email || "",
    profession: "",
    company: "",
    profilePic: {},
  });
  const [confirmDialog, setConfirmDialog] = useState({
    type: "",
    isOpen: false,
  });

  useEffect(() => {
    async function fetchData() {
      const response = await getUserInfo(userData.token);
      delete response?.id;
      delete response?.password;
      delete response?.createdAt;
      delete response?.updatedAt;
      setUserInfo(response);
      const updatedValues = {};
      if (response.lastName) updatedValues.lastName = response.lastName;
      if (response.profession) updatedValues.profession = response.profession;
      if (response.company) updatedValues.company = response.company;
      if (response.profilePic) {
        updatedValues.profilePic = response.profilePic;
        setImage(response.profilePic);
      };
      setInputValues({ ...inputValues, ...updatedValues });
    }
    fetchData();
  }, [reload]);
  useEffect(() => {
    setUserName({ name: userInfo.name, lastName: userInfo.lastName });
  }, [userInfo]);

  function verifyUpdatedInfo() {
    const inputKeys = Object.keys(inputValues);
    const userKeys = Object.keys(userInfo);
    if (inputKeys.length !== userKeys.length) return true;
    for (let key of inputKeys) {
      if (inputValues[key] !== userInfo[key]) return true; 
    }
    return false;
  }

  async function putUpdateUserInfo() {
    if (!verifyUpdatedInfo()) return;
    let areMissingMessage = "";
    if (!inputValues.lastName) areMissingMessage += "Sobrenome; ";
    if (!inputValues.profession) areMissingMessage += "Profissão; ";
    if (!inputValues.company) areMissingMessage += "Empresa/Instituição; ";
    if (areMissingMessage.length) return toast.error(`Faltam serem preenchidos: ${areMissingMessage}`);
    try {
      await updateUserInfo(userData.token, inputValues);
      setReload(reload + 1);
      return toast.success("Informações atualizadas com sucesso!");
    } catch (error) {
      return error;
    }
  }
  return(
    <TopBar>
      <PageContainer>
        <InfoContainer>
          <Title>Editar Informações Pessoais</Title>
          {!image ? 
            <UserNameCircle>
              <ion-icon name="camera-outline"></ion-icon>
              <ProfilePictureUpload inputValues={inputValues} setInputValues={setInputValues} setImage={setImage}/>
            </UserNameCircle>
            : 
            <UserNameCircle>
              <img src={image} alt=""/>
              <ProfilePictureUpload inputValues={inputValues} setInputValues={setInputValues} setImage={setImage}/>
            </UserNameCircle>
          }
          <UserInfoFormContainer>
            <DoubleInputMenu>
              <div>
                <h2>Nome</h2>
                <input  autoFocus onChange={(e) => setInputValues({ ...inputValues, name: e.target.value })} placeholder={userInfo?.name}></input>
              </div>
              <div>
                <h2>Sobrenome</h2>
                <input onChange={(e) => setInputValues({ ...inputValues, lastName: e.target.value })} placeholder={userInfo?.lastName}></input>
              </div>
            </DoubleInputMenu>
            <DoubleInputMenu>
              <div>
                <h2>E-mail</h2>
                <input onChange={(e) => setInputValues({ ...inputValues, email: e.target.value })} placeholder={userInfo?.email}></input>
              </div>
              <div>
                <h2>Profissão</h2>
                <input onChange={(e) => setInputValues({ ...inputValues, profession: e.target.value })} placeholder={userInfo?.profession}></input>
              </div>
            </DoubleInputMenu>
            <SingleInputMenu>
              <h2>Empresa/Instituição</h2>
              <input onChange={(e) => setInputValues({ ...inputValues, company: e.target.value })} placeholder={userInfo?.company}></input>
            </SingleInputMenu>
            <DoubleInputMenu>
              <ChangePasswordButton onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: true })}>ALTERAR SENHA</ChangePasswordButton>
              {updateUserInfoLoading ? <SavingButton isLoading={true}>CARREGANDO</SavingButton> : <SavingButton isLoading={false} onClick={putUpdateUserInfo}>SALVAR</SavingButton> }
            </DoubleInputMenu>
          </UserInfoFormContainer>
        </InfoContainer>
        <UpdatePasswordDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </PageContainer>
    </TopBar>
  );
}

async function uploadProfilePicture(file, token) {
  const formData = new FormData();
  formData.append("file", file);
  const url = "http://18.218.23.19/api/users/profilePic"; 
  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.ok) {
      const fileName = await response.text();
      return fileName;
    } else {
      console.log("Erro na solicitação: ", response.status);
    }
  } catch (error) {
    return error;
  }
};

function ProfilePictureUpload({ setImage, inputValues, setInputValues }) {
  const fileInputRef = useRef(null);
  const { userData } = useContext(UserContext);

  const handleFileInputChange = async(event) => {
    const file = event.target.files[0];
    const imageURL = URL.createObjectURL(file);
    setImage(imageURL);
    const uploadedImageResponse  = await uploadProfilePicture(file, userData.token);
    const uploadedImageUrl = `http://18.218.23.19/api/image/${uploadedImageResponse}`;
    setInputValues({ ...inputValues, profilePic: uploadedImageUrl });
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div>
      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={handleFileInputChange}
      />
      <div onClick={handleClick}>
        <SmallIonIconWrapper>
          <ion-icon name="cloud-upload-sharp"></ion-icon>
        </SmallIonIconWrapper>
      </div>
    </div>
  );
}

const PageContainer = styled.div`
  width: 100vw;
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif !important;
  border: 30px solid #b0b0b0;
  border-bottom: none !important;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 53vw;
  height: 62vh;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  position: relative;
`;

const DoubleInputMenu = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  width: 100%;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    width: 49%;
  }
  h2 {
    color: #333333;
  }
`;

const SingleInputMenu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 8px 0;
  width: 100%;
  h2 {
    color: #333333;
  }
`;
const Title = styled.h1`
  position: fixed;
  top: 120px;
  left: 80px;
  font-size: 26px;
  font-weight: 400;
`;

const UserNameCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: #D9D9D9;
  /* border: 6px solid #F16E00; */
  font-weight: 500;
  font-size: 64px;
  color: #F16E00;
  position: relative;
  margin: 0 78% 50px 0;
  >ion-icon {
    color: #888888;
  }

  div {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 48px;
    height: 48px;
    background-color: gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    color: #000;
    cursor: pointer;
  }

  img {
    position: absolute;
    width: 170px;
    height: 170px;
    border-radius: 50%;
    object-fit: cover;
  }
`;

const UserInfoFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  width: calc(100% - 44px);

 input,select {
    width: 100%;
    background-color: #fff;
    border: 0.5px solid #B3B3B3;
    margin: 5px 0;
    height: 4vh;
    padding: 6px;
    border-radius: 8px;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: gray;
  }
`;

const SavingButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 44px);
  height: 4vh;
  border-radius: 6px;
  color: #ffffff;
  background-color: ${(props) => (props.isLoading ? "lightgray" : " #FE8F03")} ;;
  cursor: ${(props) => (props.isLoading ? "wait" : "pointer")} ;
  pointer-events: ${(props) => (props.isLoading ? "none" : "inherit")} ;;
  font-weight: 500;
  margin-top: 20px;
`;
const ChangePasswordButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: lightgray;
    height: 4vh;
    padding: 6px;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    color: #626262;
    font-weight: 500;
    margin-top: 20px;
`;

const SmallIonIconWrapper = styled.div`
  ion-icon {
    color: #fff;
  }
`;
