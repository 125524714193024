import DIPLEXER from "./DIPLEX.png";
import TRIPLEXER from "./TRIPLEX.png";
import QUADRIPLEXER from "./QUADRIPLEX.png";
import GPS from "./GPS.png";
import HELICOIDAL_1 from "./HELICOIDAL 1.png";
import HELICOIDAL_2 from "./HELICOIDAL 2.png";
import HELICOIDAL_4 from "./HELICOIDAL 4.png";
import HELICOIDAL_7 from "./HELICOIDAL 7.png";
import MW_CHEIA from "./MW CHEIA.png";
import MW_VAZADA from "./MW VAZADA.png";
import ODU_QUADRADA from "./ODU QUADRADA.png";
import ODU from "./ODU REDONDA.png";
import OMNI from "./OMNI MENOR.png";
import PAINEL_VAZADO from "./PAINEL VAZADO.png";
import RF from "./RF.png";
import RRU from "./RRU.png";
import TMA from "./TMA.png";
import YAGI_1 from "./YAGI 1.png";
import YAGI_2 from "./YAGI 2.png";
import YAGI_4 from "./YAGI 4.png";
import YAGI_7 from "./YAGI 7.png";
import YAGI_8 from "./YAGI 8.png";

const antennasImages = {
  DIPLEXER, TRIPLEXER, QUADRIPLEXER, GPS, HELICOIDAL_1, HELICOIDAL_2, HELICOIDAL_4, HELICOIDAL_7, MW_CHEIA,
  MW_VAZADA, ODU_QUADRADA, ODU, OMNI, PAINEL_VAZADO, RF, RRU, TMA, YAGI_1, YAGI_2, YAGI_4, YAGI_7, YAGI_8
};

export default antennasImages;
