const NotFoundPage = () => {
  return (
    <div>
      <h1>Oops! A página que você está procurando não foi encontrada.</h1>
      <p>A URL está incorreta. Clique <a href="/">aqui</a> para voltar para o site.</p>
    </div>
  );
};

export default NotFoundPage;
