import styled from "styled-components";
import { useContext } from "react";
import { MenuContext } from "../../../contexts/MenuContext";
import { InputMenu } from "../../common/input-menu";
import { CustomInput } from "../../common/custom-input";
import { CustomSelect } from "../../common/custom-select";
import AutocompleteInput from "../../common/customAutoCompleteInput";
import { profileTypes, tubeProfileData, boltsDiameter, boltsSteel } from "../../../constants/tower-constants";
import { handleProfileSteelTypes, handleProfileDimensions } from "../../../utils/tower_handleProfilesData";
import colors from "../../../constants/color-constants";

export default function TowerInferiorDiagonalContainer({
  segmentSelectedInMenu,
  inputValues,
  handleInferiorDiagonalChange,
}) {
  const { leftMenuWidth } = useContext(MenuContext);
  const currentInferiorDiagonal = inputValues[segmentSelectedInMenu - 1]?.inferiorDiagonalData;

  return (
    <>
      {
        inputValues[segmentSelectedInMenu - 1]?.isInferiorDiagonalDifferent === "Sim" &&
          <Container width={leftMenuWidth}>
            <h2 className="title">Diagonal Inferior</h2>
            <InputMenu>
              <h2>Tipo de Perfil</h2>
              <select
                value={currentInferiorDiagonal?.profileType ?? ""}
                onChange={(e) => handleInferiorDiagonalChange("profileType", e.target.value)}
              >
                <option value="" key={"profileType-empty"}>Selecione o tipo de perfil</option>
                {profileTypes.map((type) => (
                  <option value={type} key={type}>{type}</option>
                ))}
              </select>
            </InputMenu>

            <InputMenu>
              <h2>Aço do Perfil</h2>
              <select
                value={currentInferiorDiagonal?.profileSteel ?? ""}
                onChange={(e) => handleInferiorDiagonalChange("profileSteel", e.target.value)}
              >
                <option value="" key={"profileSteel-empty"}>Selecione o aço do perfil</option>
                {currentInferiorDiagonal?.profileType &&
                  handleProfileSteelTypes(currentInferiorDiagonal?.profileType)?.map((type) => (
                    <option value={type} key={type}>{type}</option>
                  ))}
              </select>
            </InputMenu>

            {(currentInferiorDiagonal?.profileType && currentInferiorDiagonal?.profileSteel) && 
              <InputMenu>
                <h2>Dimensões do Perfil</h2>
                {currentInferiorDiagonal?.profileType === "L - Cantoneira" &&
                  <AutocompleteInput
                    inputValue={currentInferiorDiagonal?.profileDimensions || ""}
                    handleInputChange={(field, value) => handleInferiorDiagonalChange(field, value)}
                    list={handleProfileDimensions(currentInferiorDiagonal?.profileType) || []}
                    field={"profileDimensions"}
                    placeholder={"Selecione as dimensões do perfil"}
                  />
                }
                {currentInferiorDiagonal?.profileType === "T - Tubo Vazado" && 
                  <div>
                    <AutocompleteInput
                      inputValue={currentInferiorDiagonal?.profileDiameter || ""}
                      handleInputChange={(field, value) => handleInferiorDiagonalChange(field, value)}
                      list={currentInferiorDiagonal?.profileSteel
                        ? handleProfileDimensions(currentInferiorDiagonal.profileType, currentInferiorDiagonal?.profileSteel) : []}
                      field={"profileDiameter"}
                      placeholder={"Diâmetro"}
                      label={"Diâmetro"}
                    />

                    <AutocompleteInput
                      inputValue={currentInferiorDiagonal?.profileThickness || ""}
                      handleInputChange={(field, value) => handleInferiorDiagonalChange(field, value)}
                      list={currentInferiorDiagonal?.profileDiameter
                        ? tubeProfileData[currentInferiorDiagonal?.profileSteel][currentInferiorDiagonal?.profileDiameter]
                        : []}
                      field={"profileThickness"}
                      placeholder={"Espessura"}
                      label={"Espessura"}
                    />
                  </div>
                }
                {
                  currentInferiorDiagonal?.profileType === "BR - Barra Maciça" &&
                    <AutocompleteInput
                      inputValue={currentInferiorDiagonal?.profileDiameter || ""}
                      handleInputChange={(field, value) => handleInferiorDiagonalChange(field, value)}
                      list={handleProfileDimensions(currentInferiorDiagonal.profileType) || []}
                      field={"profileDiameter"}
                      placeholder={"Selecione o diâmetro do perfil"}
                    />
                }
                {
                  currentInferiorDiagonal?.profileType === "U - Perfil U" &&
                  <>
                    <div>
                      <CustomInput
                        placeholder={"Aba (mm)"}
                        label={"Aba"}
                        value={currentInferiorDiagonal?.profileFlange ?? ""}
                        onChange={(newValue) => handleInferiorDiagonalChange("profileFlange", newValue)}
                        rawNumber={true}
                        unit={"mm"}
                      />

                      <CustomInput
                        placeholder={"Alma (mm)"}
                        label={"Alma"}
                        value={currentInferiorDiagonal?.profileWeb ?? ""}
                        onChange={(newValue) => handleInferiorDiagonalChange("profileWeb", newValue)}
                        rawNumber={true}
                        unit={"mm"}
                      />
                    </div>
                    <div>
                      <AutocompleteInput
                        inputValue={currentInferiorDiagonal?.profileThickness || ""}
                        handleInputChange={(field, value) => handleInferiorDiagonalChange(field, value)}
                        list={handleProfileDimensions(currentInferiorDiagonal.profileType) || []}
                        field={"profileThickness"}
                        placeholder={"Selecione a espessura do perfil"}
                        label={"Espessura"}
                      />
                    </div>
                  </>
                }
              </InputMenu>
            }

            <InputMenu>
              <h2>Aço dos Parafusos</h2>
              <select
                value={currentInferiorDiagonal?.boltsSteel ?? ""}
                onChange={(e) => handleInferiorDiagonalChange("boltsSteel", e.target.value)}
              >
                <option value="" key={"boltsSteel-empty"}>Selecione o aço dos parafusos</option>
                {boltsSteel.map((type) => (
                  <option value={type} key={type}>{type}</option>
                ))}
              </select>
            </InputMenu>

            <InputMenu>
              <h2>Parafusos de Conexão</h2>
              <div>
                <CustomInput
                  placeholder={"Quantidade"}
                  label={"Quantidade"}
                  value={currentInferiorDiagonal?.numConnectionBolts ?? ""}
                  onChange={(newValue) => handleInferiorDiagonalChange("numConnectionBolts", newValue)}
                  toFixed={0}
                />

                <CustomSelect
                  value={currentInferiorDiagonal?.diameterConnectionBolts ?? ""}
                  label={"Diâmetro"}
                  onChange={(e) => handleInferiorDiagonalChange("diameterConnectionBolts", e)}
                >
                  <option value="" key={"diameterConnectionBolts-empty"}>Diâmetro</option>
                  {boltsDiameter.map((type) => (
                    <option value={type} key={type}>{type}</option>
                  ))}
                </CustomSelect>
              </div>
            </InputMenu>

            {(currentInferiorDiagonal?.profileType && currentInferiorDiagonal?.profileSteel) && 
              <InputMenu>
                <h2>Dimensões do Perfil Reforço</h2>
                {currentInferiorDiagonal?.profileType === "L - Cantoneira" &&
                  <AutocompleteInput
                    inputValue={currentInferiorDiagonal?.reinforcementDimensions || ""}
                    handleInputChange={(field, value) => handleInferiorDiagonalChange(field, value)}
                    list={["Não Há Reforço", ...handleProfileDimensions(currentInferiorDiagonal?.profileType)] || []}
                    field={"reinforcementDimensions"}
                    placeholder={"Selecione as dimensões do perfil"}
                  />
                }
                {currentInferiorDiagonal?.profileType === "T - Tubo Vazado" &&
                  <div>
                    <AutocompleteInput
                      inputValue={currentInferiorDiagonal?.reinforcementDiameter || ""}
                      handleInputChange={(field, value) => handleInferiorDiagonalChange(field, value)}
                      list={["Não Há Reforço", ...handleProfileDimensions(currentInferiorDiagonal.profileType,
                        currentInferiorDiagonal?.profileSteel)] || []}
                      field={"reinforcementDiameter"}
                      placeholder={"Diâmetro"}
                      label={"Diâmetro"}
                    />

                    <AutocompleteInput
                      inputValue={currentInferiorDiagonal?.reinforcementThickness || ""}
                      handleInputChange={(field, value) => handleInferiorDiagonalChange(field, value)}
                      list={(currentInferiorDiagonal?.reinforcementDiameter
                        && currentInferiorDiagonal?.reinforcementDiameter !== "Não Há Reforço")
                        ? ["Não Há Reforço", ...tubeProfileData[currentInferiorDiagonal?.profileSteel]
                          [currentInferiorDiagonal?.reinforcementDiameter]]
                        : []}
                      field={"reinforcementThickness"}
                      placeholder={"Espessura"}
                      label={"Espessura"}
                    />
                  </div>
                }
                {
                  currentInferiorDiagonal?.profileType === "BR - Barra Maciça" &&
                    <AutocompleteInput
                      inputValue={currentInferiorDiagonal?.reinforcementDiameter || ""}
                      handleInputChange={(field, value) => handleInferiorDiagonalChange(field, value)}
                      list={["Não Há Reforço", ...handleProfileDimensions(currentInferiorDiagonal.profileType)]}
                      field={"reinforcementDiameter"}
                      placeholder={"Selecione o diâmetro do perfil"}
                    />
                }
                {
                  currentInferiorDiagonal?.profileType === "U - Perfil U" &&
                  <>
                    <div>
                      <CustomInput
                        disabled={currentInferiorDiagonal.reinforcementThickness === "Não Há Reforço"}
                        placeholder={"Aba (mm)"}
                        label={"Aba"}
                        value={currentInferiorDiagonal?.reinforcementFlange ?? ""}
                        onChange={(newValue) => handleInferiorDiagonalChange("reinforcementFlange", newValue)}
                        rawNumber={true}
                        unit={"mm"}
                      />

                      <CustomInput
                        disabled={currentInferiorDiagonal.reinforcementThickness === "Não Há Reforço"}
                        placeholder={"Alma (mm)"}
                        label={"Alma"}
                        value={currentInferiorDiagonal?.reinforcementWeb ?? ""}
                        onChange={(newValue) => handleInferiorDiagonalChange("reinforcementWeb", newValue)}
                        rawNumber={true}
                        unit={"mm"}
                      />
                    </div>
                    <div>
                      <AutocompleteInput
                        inputValue={currentInferiorDiagonal?.reinforcementThickness || ""}
                        handleInputChange={(field, value) => handleInferiorDiagonalChange(field, value)}
                        list={["Não Há Reforço", ...handleProfileDimensions(currentInferiorDiagonal.profileType)] || []}
                        field={"reinforcementThickness"}
                        placeholder={"Selecione a espessura do perfil"}
                        label={"Espessura"}
                      />
                    </div>
                  </>
                }
              </InputMenu>
            }
          </Container>
      }
    </>
  );
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: ${(props) => props.leftMenuWidth};
    min-width: 320px;
    max-width: 380px;
    height: min-content;
    padding: 18px 30px 18px;
    background-color: ${colors.kcLightGray};
    color: ${colors.kcTextColor};
    font-family: 'Roboto', sans-serif;

    h2 {
        color: ${colors.kcTextColor};
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        font-family: 'Roboto', sans-serif;
    }

    .title {
        margin-bottom: 12px;
    }
`;
