import useAsync from "../useAsync";
import * as projectApi from "../../services/projectApi";

export default function usePostDownloadMemorial() {
  const {
    data: memorial,
    loading: postMemorialLoading,
    error: postMemorialError,
    act: postMemorial,
  } = useAsync((nomeProjeto, printableStructures, documentType, token) => projectApi.postMemorial(nomeProjeto, printableStructures, documentType, token), false);

  return {
    memorial,
    postMemorialLoading,
    postMemorialError,
    postMemorial
  };
}
