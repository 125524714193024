import styled from "styled-components";

const ButtonContainer = styled.div`
  width: 48%;
  height: 3.5vh;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c9c9c9;
  cursor: wait;
  font-weight: 500;
  color: #626262;
`;

export default ButtonContainer;
