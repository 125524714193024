import React, { useState } from "react";
import styled from "styled-components";

export default function Accordion({ title, children }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <AccordionHeader onClick={toggleAccordion} isOpen={isOpen}>
        <h3>{title}</h3>
        <ion-icon name="chevron-forward-outline"></ion-icon>
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>
        {children}
      </AccordionContent>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  border-radius: 6px;
  border: 1px solid #C8C8C8;
  overflow: hidden;
  margin-bottom: 1em;
  background: #FFFFFF;
  font-family: 'Roboto', sans-serif;
`;

const AccordionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 6px 6px 6px 15px;
  border-radius: 6px;
  background-color: #FFF;
  cursor: pointer;
  
  h3 {
    color: #313131;
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
  }

  ion-icon {
    width: 16px;
    font-size: 16px;
    transform: ${(props) => (props.isOpen ? "rotate(-90deg)" : "rotate(90deg)")};
    transition: transform 0.3s ease;
    color: #969696;
  }
`;

const AccordionContent = styled.div`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  width: 100%;
  padding: 16px;
`;
