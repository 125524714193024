import useAsync from "../useAsync";
import * as projectApi from "../../services/projectApi";

export default function useUpdateProject() {
  const {
    data: project,
    loading: updateProjectLoading,
    error: updateProjectError,
    act: updateProject,
  } = useAsync((project, nomeProjeto, token) => projectApi.updateProject(project, encodeURIComponent(nomeProjeto), token), false);

  return {
    project,
    updateProjectLoading,
    updateProjectError,
    updateProject
  };
}
