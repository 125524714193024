import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customToastError from "../errors/customToastError";

class ReinforcementDataModel {
  constructor(
    profileType = "",
    profileSteel = "",
    profileDimensions = "",
    profileDiameter = "",
    profileThickness = "",
    profileFlange = "",
    profileWeb = "",
    mountConnection = "",
    numConnectionBolts = "",
    diameterConnectionBolts = "",
    boltsSteel = ""
  ) {
    this.profileType = profileType;
    this.profileSteel = profileSteel;
    this.profileDimensions = profileDimensions;
    this.profileDiameter = profileDiameter;
    this.profileThickness = profileThickness;
    this.profileFlange = profileFlange;
    this.profileWeb = profileWeb;
    this.mountConnection = mountConnection;
    this.numConnectionBolts = numConnectionBolts;
    this.diameterConnectionBolts = diameterConnectionBolts;
    this.boltsSteel = boltsSteel;
  }

  updateField(field, value) {
    if (field === "profileType") {
      this.profileType = value;
      this.profileSteel = "";
      this.profileDimensions = "";
      this.profileDiameter = "";
      this.profileThickness = "";
      this.profileFlange = "";
      this.profileWeb = "";
      return;
    }

    if (this.profileType === "T - Tubo Vazado" && value !== this[field]) {
      if (field === "profileSteel") {
        this.profileSteel = value;
        this.profileDimensions = "";
        this.profileDiameter = "";
        this.profileThickness = "";
        this.profileFlange = "";
        this.profileWeb = "";
        return;
      }

      if (field === "profileDiameter") {
        this.profileDiameter = value;
        this.profileThickness = "";
        return;
      }
    }

    if (field === "mountConnection" && value === "Não há") {
      this.mountConnection = value;
      this.boltsSteel = "";
      this.numConnectionBolts = "";
      this.diameterConnectionBolts = "";
      return;
    }

    this[field] = value;
  }
}

class MountModel {
  constructor(
    segmentId = "",
    profileType = "",
    profileSteel = "",
    profileDimensions = "",
    profileDiameter = "",
    profileThickness = "",
    profileFlange = "",
    profileWeb = "",
    mountConnection = "",
    numConnectionBolts = "",
    diameterConnectionBolts = "",
    boltsSteel = "",
    hasReinforcement = "",
    reinforcementData = {}
  ) {
    this.segmentId = segmentId;
    this.profileType = profileType;
    this.profileSteel = profileSteel;
    this.profileDimensions = profileDimensions;
    this.profileDiameter = profileDiameter;
    this.profileThickness = profileThickness;
    this.profileFlange = profileFlange;
    this.profileWeb = profileWeb;
    this.mountConnection = mountConnection;
    this.numConnectionBolts = numConnectionBolts;
    this.diameterConnectionBolts = diameterConnectionBolts;
    this.boltsSteel = boltsSteel;
    this.hasReinforcement = hasReinforcement;
    this.reinforcementData = this.hasReinforcement === "Sim" ? new ReinforcementDataModel(
      reinforcementData.profileType,
      reinforcementData.profileSteel,
      reinforcementData.profileDimensions,
      reinforcementData.profileDiameter,
      reinforcementData.profileThickness,
      reinforcementData.profileFlange,
      reinforcementData.profileWeb,
      reinforcementData.mountConnection,
      reinforcementData.numConnectionBolts,
      reinforcementData.diameterConnectionBolts,
      reinforcementData.boltsSteel
    ) : {};
  }

  updateField(field, value) {
    if (field === "profileType" && this[field] && value !== this[field]) {
      this[field] = value;
      this.profileSteel = "";
      this.profileDimensions = "";
      this.profileDiameter = "";
      this.profileThickness = "";
      this.profileFlange = "";
      this.profileWeb = "";
      return;
    }

    if (field === "profileSteel" && value !== this[field]) {
      this[field] = value;
      this.profileDimensions = "";
      this.profileDiameter = "";
      this.profileThickness = "";
      this.profileFlange = "";
      this.profileWeb = "";
      return;
    }

    if (field === "profileDiameter" && this.profileType === "T - Tubo Vazado" && value !== this[field]) {
      this[field] = value;
      this.profileThickness = "";
      return;
    }

    if (field === "mountConnection" && value === "Não há") {
      this[field] = value;
      this.boltsSteel = "";
      this.numConnectionBolts = "";
      this.diameterConnectionBolts = "";
      return;
    }

    if (field === "hasReinforcement") {
      this[field] = value;
      if (value === "Sim") {
        this.reinforcementData = new ReinforcementDataModel();
      } else {
        this.reinforcementData = {};
      }
      return;
    }

    if (this[field] !== undefined) {
      this[field] = value;
    }
  }

  updateReinforcementField(field, value) {
    this.reinforcementData.updateField(field, value);
  }
}

class MountsModel {
  constructor(mounts = []) {
    this.mounts = mounts.map(mount => new MountModel(
      mount.segmentId,
      mount.profileType,
      mount.profileSteel,
      mount.profileDimensions,
      mount.profileDiameter,
      mount.profileThickness,
      mount.profileFlange,
      mount.profileWeb,
      mount.mountConnection,
      mount.numConnectionBolts,
      mount.diameterConnectionBolts,
      mount.boltsSteel,
      mount.hasReinforcement,
      mount.reinforcementData
    ));
  }

  updateMount(segmentId, field, value) {
    const mount = this.mounts.find(mount => mount.segmentId === segmentId);
    if (mount) {
      mount.updateField(field, value);
    }
  }

  updateReinforcementField(segmentId, field, value) {
    const mount = this.mounts.find(mount => mount.segmentId === segmentId);
    if (mount) {
      mount.updateReinforcementField(field, value);
    }
  }

  validate() {
    let errors = [];

    this.mounts.forEach((segment, index) => {
      if (!segment.profileType) errors.push(`Tipo de Perfil no trecho ${index + 1}`);
      if (segment.profileSteel === "") errors.push(`Aço do Perfil no trecho ${index + 1}`);
      if (!segment.profileDimensions && !segment.profileDiameter && !segment.profileThickness &&
        !segment.profileFlange && !segment.profileWeb) {
        errors.push(`Dimensões do Perfil no trecho ${index + 1}`);
      } else {
        if (segment.profileType === "T - Tubo Vazado" && (!segment.profileDiameter || !segment.profileThickness))
          errors.push(`Dimensões completas do Perfil no trecho ${index + 1}`);
        if (segment.profileType === "O - Ômega" &&
          (!segment.profileFlange || !segment.profileWeb || !segment.profileThickness))
          errors.push(`Dimensões completas do Perfil Ômega no trecho ${index + 1}`);
      }
      if (!segment.mountConnection) errors.push(`Conexão no Montante no trecho ${index + 1}`);
      if (segment.mountConnection !== "Não há") {
        if (!segment.boltsSteel) errors.push(`Aço dos Parafusos no trecho ${index + 1}`);
        if (!segment.numConnectionBolts) errors.push(`Quantidade de parafusos de conexão no trecho ${index + 1}`);
        if (!segment.diameterConnectionBolts) errors.push(`Diâmetro de parafusos de conexão no trecho ${index + 1}`);
      }
      if (!segment.hasReinforcement) errors.push(`Se existe reforço no Montante no trecho ${index + 1}`);

      if (segment.hasReinforcement === "Sim") {
        if (!segment.reinforcementData.profileType)
          errors.push(`Tipo de Perfil do reforço no trecho ${index + 1}`);
        if (!segment.reinforcementData.profileDimensions && !segment.reinforcementData.profileDiameter &&
          !segment.reinforcementData.profileThickness && !segment.reinforcementData.profileFlange &&
          !segment.reinforcementData.profileWeb) {
          errors.push(`Dimensões do Perfil do reforço no trecho ${index + 1}`);
        } else {
          if (segment.reinforcementData.profileType === "T - Tubo Vazado" &&
            (!segment.reinforcementData.profileDiameter || !segment.reinforcementData.profileThickness))
            errors.push(`Dimensões completas do Perfil do reforço no trecho ${index + 1}`);
          if (segment.reinforcementData.profileType === "O - Ômega" && (!segment.reinforcementData.profileFlange ||
            !segment.reinforcementData.profileWeb || !segment.reinforcementData.profileThickness))
            errors.push(`Dimensões completas do Perfil do reforço Ômega no trecho ${index + 1}`);
        }
        if (segment.reinforcementData.profileSteel === "")
          errors.push(`Aço do Perfil do reforço no trecho ${index + 1}`);
        if (segment.reinforcementData.mountConnection !== "Não há") {
          if (!segment.reinforcementData.mountConnection)
            errors.push(`Conexão no Montante do reforço no trecho ${index + 1}`);
          if (!segment.reinforcementData.numConnectionBolts)
            errors.push(`Quantidade de parafusos de conexão do reforço no trecho ${index + 1}`);
          if (!segment.reinforcementData.diameterConnectionBolts)
            errors.push(`Diâmetro de parafusos de conexão do reforço no trecho ${index + 1}`);
          if (!segment.reinforcementData.boltsSteel)
            errors.push(`Aço dos Parafusos do reforço no trecho ${index + 1}`);
        }
      }
    });

    if (errors.length > 0) {
      const errorMessage = customToastError(
        errors,
        "Preencha todos os valores corretamente para salvar as informações dos montantes. Falta(m):"
      );
      toast.error(errorMessage);
      return false;
    }
    return true;
  }

  toJSON() {
    return JSON.stringify(this.mounts);
  }

  static fromJSON(json) {
    const mountsArray = JSON.parse(json);
    return new MountsModel(mountsArray);
  }
}

export default MountsModel;
