/* eslint-disable */
import { useContext, useState, useRef, useCallback, useEffect } from "react";
import styled from "styled-components";
import { ProjectContext } from "../../../contexts/ProjectContext";
import UserContext from "../../../contexts/UserContext";
import { MenuContext } from "../../../contexts/MenuContext";
import { DebounceInput } from "react-debounce-input";
import SavingButton from "../../common/saving-button";
import LoadingButton from "../../common/loading-button";
/* global google */
import { GoogleMap, Marker, useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import useUpdateProject from "../../../hooks/api/useUpdateProject";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useGetWindVelocity from "../../../hooks/api/useGetWindVelocity";
import useUnsavedChangesAlert from "../../../hooks/useUnsavedChangesAlert";
import ResizableLeftContainer from "../../common/resizableLeftContainer";
import ResizableRightContainer from "../../common/resizableRightContainer";

export default function WindInformationPage() {
  const { setLeftMenuOptionSelected, mastSelected, projectSelected, reloadProject, setReloadProject, googleMapsApiKey, setIsUpdatedWithoutCalculation } = useContext(ProjectContext);
  const { userData } = useContext(UserContext);
  const projectProps = (typeof projectSelected.props === "object" ? projectSelected.props : JSON.parse(projectSelected.props));
  const { updateProject, updateProjectLoading } = useUpdateProject();
  const { getWindVelocity } = useGetWindVelocity();
  const [windVelocities, setWindVelocities] = useState([]);
  const [coordenadasLocal, setCoordenadasLocal] = useState(projectProps?.coordenadasLocal ? projectProps?.coordenadasLocal : "");
  const [local, setLocal] = useState(projectProps?.local ? projectProps?.local : "");
  const [inputValues, setInputValues] = useState({
    coordenadasLocal: projectProps?.coordenadasLocal || "",
    local: projectProps?.local || "",
    v0: projectProps?.v0 || "",
    s1: projectProps?.s1 || "",
    s2: projectProps?.s2 || "",
    s3: projectProps?.s3 || "",
  });
  const [showInfo, setShowInfo] = useState({ isShown: false, infoType: "" });
  const [isopletaMaxWind, setIsopletaMaxWind] = useState("");
  const S2types = ["I-A", "II-A", "III-A", "IV-A", "V-A", "I-B", "II-B", "III-B", "IV-B", "V-B", "I-C", "II-C", "III-C", "IV-C", "V-C"];
  const S3types = ["0.83", "0.95", "1.00", "1.10"];
  const fieldsToCheck = ["local", "v0", "s1", "s2", "s3", "coordenadasLocal"];
  const isInputModified = useUnsavedChangesAlert({ ...inputValues, local, coordenadasLocal }, projectProps, reloadProject, fieldsToCheck);
  const [updateMapWithCoordinates, setUpdateMapWithCoordinates] = useState({
    shouldUpdate: false,
    lat: "",
    long: ""
  });
  function generateGoogleMapsImages(updatedProps) {
    const roadMapImageURL = `https://maps.googleapis.com/maps/api/staticmap?center=${coordenadasLocal?.replace(/\s/g, "")}&zoom=15&size=600x380&maptype=roadmap&markers=size:mid%7Ccolor:red%7C${coordenadasLocal?.replace(/\s/g, "")}&key=${googleMapsApiKey}`;
    const satelliteMapImageURL = `https://maps.googleapis.com/maps/api/staticmap?center=${coordenadasLocal?.replace(/\s/g, "")}&zoom=17&size=600x380&maptype=hybrid&markers=size:mid%7Ccolor:red%7C${coordenadasLocal?.replace(/\s/g, "")}&key=${googleMapsApiKey}`;
    updatedProps.roadMapImageURL = roadMapImageURL;
    updatedProps.satelliteMapImageURL = satelliteMapImageURL;
  }
  async function updateWindData() {
    const isWindValueInValid = verifyWindValue(inputValues.v0);
    if (isWindValueInValid) return toast.error(isWindValueInValid);
    let whatIsMissingMessage = "";
    if (!inputValues.v0 || !inputValues.s1 || !inputValues.s2 || !inputValues.s3) {
      if (!inputValues.v0) whatIsMissingMessage += "V0; ";
      if (!inputValues.s1) whatIsMissingMessage += "Fator S1; ";
      if (!inputValues.s2) whatIsMissingMessage += "Fator S2; ";
      if (!inputValues.s3) whatIsMissingMessage += "Fator S3; ";
      return toast.error(`Preencha todos os valores para adicionar a antena. Falta(m) ${whatIsMissingMessage}`);
    }
    try {
      if (inputValues.v0 === projectProps?.v0 && inputValues.s1 === projectProps?.s1 && inputValues.s2 === projectProps?.s2 && inputValues?.s3 === projectProps?.s3 && local === projectProps?.local && coordenadasLocal === projectProps.coordenadasLocal) return;
      const updatedProps = {
        ...projectProps, local: local || "",
        coordenadasLocal: coordenadasLocal || "",
        v0: inputValues.v0 || "",
        s1: inputValues.s1 || "",
        s2: inputValues.s2 || "",
        s3: inputValues.s3 || "",
      };
      generateGoogleMapsImages(updatedProps);
      const updatedProject = { ...projectSelected, props: JSON.stringify(updatedProps), userId: userData.id, updatedAt: new Date() };
      delete updatedProject.id;
      delete updatedProject.Mastro;
      await updateProject(updatedProject, projectSelected.nomeProjeto, userData.token);
      setReloadProject(!reloadProject);
      setIsUpdatedWithoutCalculation(true);
      toast.success("Informações atualizadas com sucesso");
    } catch (error) {
      if (error?.response?.data?.message) {
        console.log(error);
        return toast.error(error.response?.data?.message);
      }
      if (error?.message) {
        console.log(error);
        return toast.error(error.message);
      }
    }
  }
  function verifyWindValue(v0) {
    if (Number(v0) <= 0) return "V0 deve ser um valor positivo";
    if (Number(v0) > 100) return "V0 deve ser um valor menor que 100 m/s";
    return "";
  }

  function handleInfoHover(event, infoType) {
    if (event.type === "mouseleave") setShowInfo({ ...showInfo, infoType: "" });
    else setShowInfo({ infoType: infoType, isShown: event.type === "mouseenter" });
  };

  function verifyCoordinates() {
    const coordinatesLocalRegex = /^-?\d{1,2}\.\d{0,8}, -?\d{1,2}\.\d{0,8}$/;
    const areCoordinatesValid = coordinatesLocalRegex.test(coordenadasLocal);
    if (!areCoordinatesValid) toast.error("Insira um valor válido de coordenadas [-21.123456,-43.123456] ou selecione o local no mapa");
    return areCoordinatesValid;
  }
  async function getWind() {
    setInputValues({
      ...inputValues,
      v0: "",
    });
    const testingCoordinates = coordenadasLocal.split(", ");
    try {
      if (!isopletaMaxWind) {
        const velocities = await getWindVelocity({ latitude: testingCoordinates[0], longitude: testingCoordinates[1] }, userData.token);
        setInputValues({
          ...inputValues,
          v0: velocities[0].vel
        });
        setWindVelocities(velocities);
      }
      else setInputValues({ ...inputValues, v0: isopletaMaxWind });
      return toast.success("Vento Operacional calculado e atualizado com sucesso");
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message)
        return toast.error(error.response?.data?.message);
      if (error?.message) return toast.error(error.message);
    }
  }

  useEffect(() => {
  }, [inputValues]);

  function handleCoordinatesPlaceholder() {
    if (coordenadasLocal) return coordenadasLocal;
    if (projectProps?.coordenadasLocal) return projectProps.coordenadasLocal;
    if (projectProps?.local) return projectProps.local;
    return "Informe as coordenadas";
  }

  function handleWindPlaceholder() {
    if (inputValues.v0) return inputValues.v0;
    if (windVelocities.length !== 0) return windVelocities[0].vel;
    if (projectProps?.v0) return projectProps.v0;
    return "V0";
  }

  return (
    <>
      <ResizableLeftContainer>
        <LeftTitle>Vento</LeftTitle>
        <ReturnIcon onClick={() => {
          if (isInputModified) {
            if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("");
          }
          else setLeftMenuOptionSelected("");
        }}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </ReturnIcon>
        <AdvanceIcon disabled={isInputModified} onClick={() => {
          if (!mastSelected.identificador) return toast.error("Selecione o mastro que será editado");
          if (isInputModified) {
            if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja avançar?")) setLeftMenuOptionSelected("geometria");
          }
          else setLeftMenuOptionSelected("geometria");
        }}>
          <ion-icon name="chevron-back-outline"></ion-icon>
        </AdvanceIcon>
        <MenuOption>
          <h2>Local</h2>
          <HiddenInput></HiddenInput>
        </MenuOption>
        <Spacing />
        <MenuOption>
          <h2>Coordenadas</h2>
          <DebounceInput type="text" placeholder={handleCoordinatesPlaceholder()} value={coordenadasLocal} onChange={(e) => {
            setInputValues({ ...inputValues, coordenadasLocal: e.target.value });
            setCoordenadasLocal(e.target.value);
          }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && verifyCoordinates()) {
                setUpdateMapWithCoordinates({
                  lat: coordenadasLocal.split(", ")[0],
                  long: coordenadasLocal.split(", ")[1],
                  shouldUpdate: true,
                });
              }
            }}
          />
          <InformationIconContainer onMouseEnter={(e) => handleInfoHover(e, "local")} onMouseLeave={handleInfoHover}>
            <ion-icon name="information-circle"></ion-icon>
          </InformationIconContainer>
          {showInfo.isShown && showInfo.infoType === "local" && (
            <InfoDiv>
              Informação Local: Insira as coordenadas (ex: -21.20958,-43.755555), clique na região do projeto no mapa à direita ou digite o endereço no campo acima.
            </InfoDiv>
          )}
        </MenuOption>
        <Spacing />
        <MenuOption>
          <h2>V0 - Vento Operacional (m/s)</h2>
          <input type="number" placeholder={handleWindPlaceholder()} value={inputValues.v0} onChange={(e) => setInputValues({ ...inputValues, v0: e.target.value })}></input>
          <InformationIconContainer onMouseEnter={(e) => handleInfoHover(e, "v0")} onMouseLeave={handleInfoHover}>
            <ion-icon name="information-circle"></ion-icon>
          </InformationIconContainer>
          {showInfo.isShown && showInfo.infoType === "v0" && (
            <InfoDiv>
              V0: O vento deve ser interpolado entre 30m/s e 50m/s de acordo com o mapa de isopletas segundo a ABNT NBR 6123
            </InfoDiv>
          )}
          {/* <SearchWindButton onClick={() => { if(!getWindVelocityLoading && verifyCoordinates()) getWind(); } }>{getWindVelocityLoading ? "Carregando" : "Calcular"}</SearchWindButton> */}
        </MenuOption>
        <Spacing />
        {/* <>
          <SelectInput placeholder={inputValues.s1 ? inputValues.s1 : "Selecione uma opção..."} optionsIn={S1types} setProps={setInputValues} props={inputValues} propValue={inputValues.s1} propKey={"s1"} title={"S1 - Fator Topográfico"}/>
        </> */}
        <MenuOption>
          <h2>S1 - Fator Topográfico</h2>
          <input value={inputValues.s1.replace(",", ".")} placeholder={inputValues?.s1 ? inputValues.s1.replace(",", ".") : ""} type="number" onChange={(e) => setInputValues({ ...inputValues, s1: e.target.value })} />
        </MenuOption>
        <MenuOption>
          <h2>S2 - Fator de Rugosidade</h2>
          <select onChange={(e) => setInputValues({ ...inputValues, s2: e.target.value })}>
            {projectProps?.s2 ? <option value={projectProps.s2} >{projectProps.s2}</option> : <option value={""} >Selecione uma opção...</option>}
            {S2types.map((tipo) => (Number(inputValues?.s2) !== Number(tipo) && <option value={tipo} key={tipo}>{tipo}</option>))}
          </select>
        </MenuOption>
        <Spacing />
        <MenuOption>
          <h2>S3 - Fator Estatístico</h2>
          <select onChange={(e) => setInputValues({ ...inputValues, s3: e.target.value })}>
            {projectProps?.s3 ? <option value={projectProps.s3} >{projectProps.s3}</option> : <option value={""} >Selecione uma opção...</option>}
            {S3types.map((tipo) => (Number(projectProps?.s3) !== Number(tipo) && <option value={tipo} key={tipo}>{tipo}</option>))}
          </select>
        </MenuOption>
        <Spacing />
        <ButtonPositioner>
          <ReturnButton onClick={() => {
            if (isInputModified) {
              if (window.confirm("Existem alterações que não foram salvas. Tem certeza de que deseja voltar?")) setLeftMenuOptionSelected("");
            }
            else setLeftMenuOptionSelected("");
          }} >{" Voltar "}</ReturnButton>
          {updateProjectLoading ?
            <LoadingButton>{"Carregando"}</LoadingButton> :
            <SavingButton disabled={!isInputModified} onClick={() => { if (!updateProjectLoading) updateWindData(); }}>{"Salvar"}</SavingButton>}
        </ButtonPositioner>
      </ResizableLeftContainer>
      <WindRightContainer setIsopletaMaxWind={setIsopletaMaxWind} googleMapsApiKey={googleMapsApiKey} coordenadasLocal={coordenadasLocal} setCoordenadasLocal={setCoordenadasLocal} inputValues={inputValues} local={local} setLocal={setLocal} updateMapWithCoordinates={updateMapWithCoordinates} setUpdateMapWithCoordinates={setUpdateMapWithCoordinates} />
    </>
  );
};

const libraries = ["places"];

export function WindRightContainer({ coordenadasLocal, setCoordenadasLocal, inputValues, googleMapsApiKey, setIsopletaMaxWind, local, setLocal, updateMapWithCoordinates, setUpdateMapWithCoordinates }) {
  const [map, setMap] = useState(null);
  const mapRef = useRef(null);
  const [mapType, setMapType] = useState("Mapa");
  const [isKmlLayerVisible, setIsKmlLayerVisible] = useState(false);
  const DEFAULT_LATITUDE = Number(coordenadasLocal?.split(" ")[0]) || -14.235004;
  const DEFAULT_LONGITUDE = Number(coordenadasLocal?.split(" ")[1]) || -51.925280;
  const DEFAULT_ZOOM_LEVEL = 5;
  const { leftMenuWidth } = useContext(MenuContext);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [mapCenter, setMapCenter] = useState({ lat: DEFAULT_LATITUDE, lng: DEFAULT_LONGITUDE });
  const [shouldUpdateCenter, setShouldUpdateCenter] = useState(true);
  const autocompleteInputRef = useRef(null);
  const kmlLayerRef = useRef(null);
  const kml2LayerRef = useRef(null);
  const [localPlaceholder, setLocalPlaceholder] = useState("");
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    if (isInitialRender && local) {
      setLocalPlaceholder(local);
      setIsInitialRender(false);
    }
  }, [isInitialRender, local]);

  const initMap = useCallback(() => {
    setShouldUpdateCenter(true);
  }, []);

  const onLoad = useCallback((map) => {
    mapRef.current = map;
    setMap(map);
  }, []);

  useEffect(() => {
    if (updateMapWithCoordinates.shouldUpdate) {
      setMapCenter({ lat: Number(updateMapWithCoordinates.lat), lng: Number(updateMapWithCoordinates.long) });
      setMarkerPosition({ lat: Number(updateMapWithCoordinates.lat), lng: Number(updateMapWithCoordinates.long) });
      setUpdateMapWithCoordinates({
        ...updateMapWithCoordinates,
        shouldUpdate: false
      });
    };
  }, [updateMapWithCoordinates]);

  useEffect(() => {
    if (map && markerPosition && shouldUpdateCenter) {
      map.panTo(markerPosition);
      map.setZoom(19);
      setShouldUpdateCenter(false);
    }
  }, [map, markerPosition]);

  useEffect(() => {
    setShouldUpdateCenter(true);
  }, [local, coordenadasLocal]);

  const onMapClick = useCallback((event) => {
    const latLng = event.latLng;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ "location": latLng }, function (results, status) {
      if (status === "OK") {
        if (results[1] && results[1].formatted_address) {
          setLocal(results[1].formatted_address);
          setLocalPlaceholder(results[1].formatted_address);
        } else {
          console.log("Nenhum resultado encontrado");
        }
      } else {
        console.log("Geocoder falhou devido a: " + status);
      }
    });
    setMarkerPosition({
      lat: latLng.lat(),
      lng: latLng.lng(),
    });
    setCoordenadasLocal(`${latLng.lat().toFixed(5)}, ${latLng.lng().toFixed(5)}`);

    if (autocompleteInputRef.current) {
      autocompleteInputRef.current.placeholder = `${latLng.lat().toFixed(5)}, ${latLng.lng().toFixed(5)}`;
    }
    setShouldUpdateCenter(false);
  }, []);

  const onAutocompleteLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
    autocomplete.setBounds({ east: -30, west: -70, north: 5, south: -35 });
  };

  const onPlaceChanged = (e) => {
    if (markerPosition) {
      setMarkerPosition(null);
    }

    if (!autocomplete) {
      console.error("Local inválido");
      return toast.error("O local digitado é inválido");
    } else {
      const place = autocomplete.getPlace();
      if (place?.formatted_address) {
        setLocal(place.formatted_address);
        setLocalPlaceholder(place.formatted_address);
      };
      if (!place.geometry || !place.geometry.location) {
        console.error("Local inválido");
        return;
      }

      const latLng = place.geometry.location;
      setMapCenter({ lat: latLng.lat(), lng: latLng.lng() });
      setMarkerPosition({ lat: latLng.lat(), lng: latLng.lng() });
      setCoordenadasLocal(`${latLng.lat().toFixed(5)}, ${latLng.lng().toFixed(5)}`);
      if (!local) setCoordenadasLocal(`${latLng.lat().toFixed(5)}, ${latLng.lng().toFixed(5)}`);
    }
  };

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleMapsApiKey,
    libraries
  });

  useEffect(() => {
    initMap();
  }, [initMap]);

  const loadKmlLayer = useCallback(() => {
    if (!map || !window.google) {
      return;
    }
    const kmlUrl = "https://raw.githubusercontent.com/KKEngenharia/google-earth-isopletas/main/ISOPLETAS_GEO_WGS84_v8.kml";
    const kmlStatesUrl = "https://raw.githubusercontent.com/KKEngenharia/google-earth-isopletas/main/brazilian-states_v2.kml";

    const kmlLayer = new window.google.maps.KmlLayer({
      url: kmlUrl,
      map: map,
      preserveViewport: true,
      suppressInfoWindows: false,
      clickable: true
    });

    const kmlLayer2 = new window.google.maps.KmlLayer({
      url: kmlStatesUrl,
      map: map,
      preserveViewport: true,
      suppressInfoWindows: false,
      clickable: false
    });

    kmlLayer.addListener("click", (event) => {
      const latLng = event.latLng;
      if (event.featureData.name) {
        setIsopletaMaxWind(event.featureData.name.slice(-2));
      }
      map.panTo(latLng);
      setShouldUpdateCenter(false);
      setMarkerPosition({
        lat: latLng.lat(),
        lng: latLng.lng(),
      });
      setCoordenadasLocal(`${latLng.lat().toFixed(5)}, ${latLng.lng().toFixed(5)}`);
      if (autocompleteInputRef.current) {
        autocompleteInputRef.current.placeholder = `${latLng.lat().toFixed(5)}, ${latLng.lng().toFixed(5)}`;
      }
      setShouldUpdateCenter(false); // Impede a atualização do centro do mapa
    });
    kmlLayerRef.current = kmlLayer;
    kml2LayerRef.current = kmlLayer2;
  }, [map, setCoordenadasLocal, mapType]);

  useEffect(() => {
    if (mapType === "Isopletas" && isKmlLayerVisible) {
      loadKmlLayer();
    } else {
      if (kmlLayerRef.current) {
        kmlLayerRef.current.setMap(null);
        kmlLayerRef.current = null;
      }
      if (kml2LayerRef.current) {
        kml2LayerRef.current.setMap(null);
        kml2LayerRef.current = null;
      }
    }
  }, [mapType, isKmlLayerVisible]);

  return (
    <ResizableRightContainer>
      {isLoaded && (
        <GoogleMap
          onLoad={onLoad}
          mapContainerStyle={{ width: "100%", height: "100%", zIndex: "3" }}
          center={mapCenter}
          zoom={DEFAULT_ZOOM_LEVEL}
          onClick={onMapClick}
          options={{
            mapTypeControl: true,
            minZoom: 4,
            mapTypeControlOptions: {
              style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: window.google.maps.ControlPosition.TOP_LEFT,
            },
            zoomControl: true,
            zoomControlOptions: {
              position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            streetViewControl: false,
            gestureHandling: "greedy"
          }}
        >
          {markerPosition && <Marker position={markerPosition} />}
          <GoogleMapsMenuOption>
            <Autocomplete onLoad={onAutocompleteLoad} onPlaceChanged={onPlaceChanged}>
              <input
                type="text"
                placeholder={local || "Digite o local do projeto"}
                value={localPlaceholder}
                onChange={(e) => setLocalPlaceholder(e.target.value)}
                ref={autocompleteInputRef}
                autoFocus
                style={{
                  boxSizing: "border-box",
                  border: "1px solid #C8C8C8",
                  width: `calc(${leftMenuWidth.replace("%", "vw")} - 64px - 30px)`,
                  height: "4.5vh",
                  padding: "6px",
                  // boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                  fontSize: "16px",
                  outline: "none",
                  textOverflow: "ellipses",
                  zIndex: 11,
                }}
              />
            </Autocomplete>
          </GoogleMapsMenuOption>
        </GoogleMap>
      )}
      <MapOptionContainer mapType={mapType}>
        <div
          onClick={() => {
            setMapType("Mapa");
            setIsopletaMaxWind("");
            setIsKmlLayerVisible(false);
            if (kmlLayerRef.current) {
              kmlLayerRef.current.setMap(null);
              kmlLayerRef.current = null;
            }
          }}
          className={mapType === "Mapa" ? "selected" : ""}
        >
          Mapa
        </div>
        <div
          onClick={() => {
            setMapType("Isopletas");
            setIsKmlLayerVisible(true);
          }}
          className={mapType === "Isopletas" ? "selected" : ""}
        >
          Isopletas
        </div>
      </MapOptionContainer>
    </ResizableRightContainer>
  );
}

export function ButtonPositioner({ children }) {
  const { leftMenuWidth } = useContext(MenuContext);
  return (
    <ButtonPositionerContainer width={leftMenuWidth}>
      {children}
    </ButtonPositionerContainer>
  );
}

export const MenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin: 1vh 0;
  position: relative;

  h2 {
    font-weight: 300;
    font-size: 18px;
    color: #313131;
    margin-bottom: 0.8vh;
  }

  h3 {
    font-weight: 300;
    font-size: 16px;
    color: #313131;
    margin-top: -4px;
    margin-bottom: 0.8vh;
  }

  input,select {
    width: 100%;
    border: 1px solid #C8C8C8;
    background-color: #ffffff;
    height: 4.3vh;
    padding: 5px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }
`;

const GoogleMapsMenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: fixed;
  top: 23vh;
  left: calc(32px + 30px);

  @media (max-height: 500px) {
    top: 33.5vh;
  }

  @media (min-height: 500px) and (max-height: 550px) {
    top: 31.5vh;
  }

  @media (min-height: 550px) and (max-height: 600px) {
    top: 29.3vh;
  }

  @media (min-height: 600px) and (max-height: 650px) {
    top: 27vh;
  }

  @media (min-height: 650px) and (max-height: 700px) {
    top: 25.2vh;
  }
  @media (min-height: 700px) and (max-height: 750px) {
    top: 23.8vh;
  }
  @media (min-height: 750px) and (max-height: 800px) {
    top: 22.5vh;
  }
  @media (min-height: 800px) and (max-height: 850px) {
    top: 21.2vh;
  }
  @media (min-height: 850px) and (max-height: 900px) {
    top: 20vh;
  }
  @media (min-height: 900px) and (max-height: 950px) {
    top: 19vh;
  }
  @media (min-height: 950px) and (max-height: 1000px) {
    top: 18.3vh;
  }
  @media (min-height: 1000px) and (max-height: 1050px) {
    top: 17.3vh;
  }
  @media (min-height: 1050px) and (max-height: 1100px) {
    top: 16.6vh;
  }
  @media (min-height: 1100px){
    top: 16vh;
  }

  input,select {
    width: 100%;
    background-color: #ffffff;
    height: 5vh;
    padding: 6px;
    border-radius: 8px;
    font-family: 'Roboto', sans-serif;
    border: 1px solid #C8C8C8;
    font-size: 16px;
  }

  input::placeholder ,select::placeholder {
    color: #000;
  }
`;

export const InformationIconContainer = styled.div`
  ion-icon {
    font-size: 23px;
    position: absolute;
    top: 0;
    right: 0;
    color: #FE8F03;
  }
`;

export const LeftTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: left;
  font-size: 24px;
  color: #000000;
  margin-bottom: 0.5vh;
`;

const HiddenInput = styled.input`
  opacity: 0;
  pointer-events: none;
`;

export const BottomBorderTitle = styled.div`
  width: 100%;
  height: 0.22vh;
  background-color: #F16E00;
  margin: 1vh 0;
`;

export const ButtonPositionerContainer = styled.div`
  position: absolute;
  display: flex;
  width: ${(props) => `calc(${props.width.replace("%", "vw")} - 64px)`};
  justify-content: space-between;
  bottom: 2.5vh;
  pointer-events: ${(props) => (props.disabled ? "none" : "inherit")};
`;

export const InfoDiv = styled.div`
  position: absolute;
  width: 260px;
  top: 0;
  left: 22vw;
  background-color: #ffffff;
  padding: 8px;
  font-size: 16px;
  color: #000000;
  border: 1px dashed #FF0000;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  z-index: 5;
`;

const SearchWindButton = styled.div`
  top: 5vh;
  right: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 2.8vh;
  border-radius: 6px;
  background-color: #F16E00;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  margin: 6px 0 -10px calc(100% - 80px);
`;

export const ReturnButton = styled.div`
  width: 48%;
  height: 3.5vh;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #C9C9C9;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  color: #626262;
  letter-spacing: 0.7px;
`;

const MapOptionContainer = styled.div`
  z-index: 10;
  position: absolute;
  z-index: 6;
  top: 50px;
  left: 80px;
  display: flex;
  div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 300;
  &:hover {
    background-color: #f2f2f2;
  }
  }
  .selected {
    font-weight: 500 !important;
  }
`;
export const ReturnIcon = styled.div`
  position: absolute;
  top: 2.8vh;
  right: 80px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  >ion-icon {
    color: #989898;
    font-size: 22px;
    padding-bottom: 2px;
  }
`;

export const AdvanceIcon = styled.div`
  position: absolute;
  top: 2.8vh;
  right: 40px;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  background-color: ${(props) => (props.disabled ? "#dbdbdb" : "#FE8F03")};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  >ion-icon {
    color: ${(props) => (props.disabled ? "#989898" : "#ffffff")};
    transition: color 1500ms ease-in-out;
    font-size: 22px;
    padding-bottom: 2px;
    transform: scaleX(-1);
  }
`;

const Spacing = styled.div`
  margin-top: 0.85vh;
`;
